import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import "firebase/auth";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import "antd/dist/antd.css";
import PageHeader from "./components/PageHeader";
import ScrollToTop from "./scrollToTop";
import PageFooter from "./components/PageFooter";
import "./index.css";
import { loadUser } from "./ducks/Auth/actions";
import { getScheduledNotifications } from "./ducks/Global/actions";
import SideBar from "./components/SideBar";
import { AuthProvider } from "./components/AuthContext";
import { Layout, Button } from "antd";
// import HttpsRedirect from "react-https-redirect";
import ReactGA from "react-ga";
import IndexRoute from "./IndexRoute";
import MetasForPage from "./MetasForPage";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import { hotjar } from 'react-hotjar';
import MenuFooter from "./components/MenuFooter";
import { createUserSetting } from "./ducks/Setting/actions";
import { TopBanner } from './components/TopBanner';
import { AchievementModal } from "./components/AchievementModal";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const browserHistory = createBrowserHistory({ forceRefresh: true });

browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

document.addEventListener("wheel", () => {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

const App = () => {
  const [authVisible, setAuthVisible] = useState(false);
  const [authInfo, setAuthInfo] = useState({});
  const dispatch = useDispatch();
  const auth = useSelector(store => store.auth);
  const showTopHeader = useSelector((state) => state.global.showTopHeader);
  const topHeaderContent = useSelector((state) => state.global.topHeaderContent);
  const achievementProps = useSelector((state) => state.global.achievementProps);
  const [windowWidth, setWindowWidth] = useState(null);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    const OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', (isSubscribed) => {
        dispatch(createUserSetting({ active: isSubscribed }))
      });
    });
    return () => {
      window.removeEventListener("resize", updateDimensions);
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    hotjar.initialize(2211809, 6);
  }, []);

  useEffect(() => {
    if (!auth) {
      dispatch(loadUser())
    }
  }, []);

  useEffect(() => {
    if (auth) {
      setAuthInfo(auth)
    }
  }, [auth]);

  useEffect(() => {
    dispatch(getScheduledNotifications('sunday-progress-reminder'));
  }, [])

  return (
    <AuthProvider
      isRegistration={store.getState().auth.isRegistration}
      isAuthenticated={store.getState().auth.isAuthenticated}
      loadUser={() => store.dispatch(loadUser())}
    >
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <MetasForPage loc={window.location.pathname} />
        {/* <MessengerCustomerChat
          className='fb-messenger'
          pageId="109614298226626"
          appId="318535060090618"
          htmlRef={window.location.pathname}
        /> */}
        <div className="app">
          <ScrollToTop />
          <div className="content-wrap">
            <Layout style={{ background: "white", minHeight: "100vh" }}>
              {
                // !(windowWidth < 769) ?
                  <SideBar />
                  // : (authInfo && authInfo.user && authInfo.user.role === 'admin' && <SideBar />)
              }

              <Layout>
                {achievementProps && achievementProps.isOpen && <AchievementModal achievementProps={achievementProps}/>}
                {showTopHeader && <TopBanner content={topHeaderContent}/>}
                <PageHeader
                  authVisible={authVisible}
                  setAuthVisible={setAuthVisible}
                />
                <Switch>
                  <IndexRoute setAuthVisible={setAuthVisible} />
                </Switch>
                <PageFooter />
                <Button className='sticky-btn' />
                  {/* // (windowWidth < 769) && authInfo && authInfo.user && authInfo.user.role !== 'admin' && */}
                  {/* {(windowWidth < 769) &&
                  <MenuFooter />} */}
              </Layout>
            </Layout>
          </div>
        </div>
      </Router>
    </AuthProvider>

  );
};

export default App;