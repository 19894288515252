import React from 'react';
import { Switch, Divider, Tooltip } from 'antd';
import './index.scss';

class NutritionRestrictionsCard extends React.Component {
  render(){
    return(
      this.props.open &&
      <>
        <div className='flex flex-center-aligned space-between'>
          <div>
            <p className='home-card-text'>Avoid {this.props.data.node.name}</p>
          </div>
          <Tooltip  placement="topRight" title={this.props.data.node.message && this.props.data.node.message}>
            <Switch 
              checked={this.props.data.node.isOnProgram}
              onChange={(checked) => this.props.handleChange(checked, this.props.data.node)}
              disabled={this.props.data.node.disabled}
            />
          </Tooltip>
        </div>
        <Divider/>
      </>
    )
  }
}

export default NutritionRestrictionsCard;