const dietTypes = [
  { key: 1, type: 'health', label: 'Vegetarian' ,value: 'vegetarian'},
  { key: 2, type: 'health', label: 'Vegan', value: 'vegan'},
  { key: 3, type: 'health', label: 'Paleo', value: 'paleo'},
  { key: 4, type: 'health', label: 'Keto', value: 'keto-friendly'},
  { key: 5, type: 'diet', label: 'High-protein', value: 'high-protein'},
  { key: 7, type: 'diet', label: 'High-fiber', value: 'high-fiber'},
  { key: 8, type: 'diet', label: 'Low-carb', value: 'low-carb'},
  { key: 9, type: 'diet', label: 'Low-fat', value: 'low-fat'},
  { key: 10, type: 'diet', label: 'Low-sodium', value: 'low-sodium'},
  { key: 11, type: 'health', label: 'Low-sugar', value: 'low-sugar'},
  { key: 12, type: 'health', label: 'Alcohol-free', value: 'alcohol-free'},
  { key: 13, type: 'diet', label: 'Balanced', value: 'balanced'},
  { key: 14, type: 'health', label: 'Immunity', value: 'immunity'},
  { key: 15, type: 'health', label: 'Kosher', value: 'kosher'},
  { key: 16, type: 'health', label: 'Pescatarian', value: 'pescatarian'},

]

const allergies = [
  { key: 1, type: 'health', label: 'Gluten', value: 'gluten-free'},
  { key: 2, type: 'health', label: 'Dairy', value: 'dairy-free'},
  { key: 3, type: 'health', label: 'Eggs', value: 'egg-free'},
  { key: 4, type: 'health', label: 'Soy', value: 'soy-free'},
  { key: 5, type: 'health', label: 'Wheat', value: 'wheat-free'},
  { key: 6, type: 'health', label: 'Fish', value: 'fish-free'},
  { key: 7, type: 'health', label: 'Shellfish', value: 'shellfish-free'},
  { key: 8, type: 'health', label: 'Tree Nuts', value: 'tree-nut-free'},
  { key: 9, type: 'health', label: 'Peanuts', value: 'peanut-free'},
  { key: 10, type: 'health', label: 'Lupine-free', value: 'lupine-free'},
  { key: 11, type: 'health', label: 'Pork-free', value: 'pork-free'},
  { key: 12, type: 'health', label: 'Red meat-free', value: 'red-meat-free'},
  { key: 13, type: 'health', label: 'Sesame-free', value: 'sesame-free'},
  { key: 14, type: 'health', label: 'Wheat-free', value: 'wheat-free'}
]

const mealTypes = [
  { key: 1, type: 'mealType', label: 'Breakfast', value: 'Breakfast'},
  { key: 2, type: 'mealType', label: 'Lunch', value: 'Lunch'},
  { key: 3, type: 'mealType', label: 'Dinner', value: 'Dinner'},
  { key: 4, type: 'mealType', label: 'Snack', value: 'Snack'}
]

 
export { dietTypes, allergies, mealTypes};