export const GET_LIBRARY_WORKOUTS = 'workout/GET_LIBRARY_WORKOUTS';
export const GET_LIBRARY_WORKOUTS_SUCCESS = 'workout/GET_LIBRARY_WORKOUTS_SUCCESS';
export const GET_LIBRARY_WORKOUTS_ERROR = 'workout/GET_LIBRARY_WORKOUTS_ERROR';

export const CREATE_WORKOUT = 'workout/CREATE_WORKOUT';
export const CREATE_WORKOUT_SUCCESS = 'workout/CREATE_WORKOUT_SUCCESS';
export const CREATE_WORKOUT_ERROR = 'workout/CREATE_WORKOUT_ERROR';

export const DELETE_LIBRARY_WORKOUT = 'workout/DELETE_LIBRARY_WORKOUT';
export const DELETE_LIBRARY_WORKOUT_SUCCESS = 'workout/DELETE_LIBRARY_WORKOUT_SUCCESS';

export const UPDATE_LIBRARY_WORKOUT = 'workout/UPDATE_LIBRARY_WORKOUT';
export const UPDATE_LIBRARY_WORKOUT_SUCCESS = 'workout/UPDATE_LIBRARY_WORKOUT_SUCCESS';
export const UPDATE_LIBRARY_WORKOUT_ERROR = 'workout/UPDATE_LIBRARY_WORKOUT_ERROR';

export const GET_USER_WORKOUTS = 'workout/GET_USER_WORKOUTS';
export const GET_USER_WORKOUTS_SUCCESS = 'workout/GET_USER_WORKOUTS_SUCCESS';
export const GET_USER_WORKOUTS_ERROR = 'workout/GET_USER_WORKOUTS_ERROR';
export const CLEAR_WORKOUT = 'workout/CLEAR_WORKOUT';

export const SET_IS_WORKOUT_EDIT = 'workout/SET_IS_WORKOUT_EDIT';
export const CLEAR_EDIT_WORKOUT = 'workout/CLEAR_EDIT_WORKOUT';

export const CREATE_USER_GENERATED_WORKOUT = 'workout/CREATE_USER_GENERATED_WORKOUT';
export const CREATE_USER_GENERATED_WORKOUT_SUCCESS = 'workout/CREATE_USER_GENERATED_WORKOUT_SUCCESS';
export const CREATE_USER_GENERATED_WORKOUT_ERROR = 'workout/CREATE_USER_GENERATED_WORKOUT_ERROR';

export const SHARE_MY_WORKOUT = 'workout/SHARE_MY_WORKOUT';
export const SHARE_MY_WORKOUT_SUCCESS = 'workout/SHARE_MY_WORKOUT_SUCCESS';
export const SHARE_MY_WORKOUT_ERROR = 'workout/SHARE_MY_WORKOUT_ERROR';