import React from 'react';
import moment from 'moment';
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import './index.scss';

//This needs to be refactored. Save difference compared to last on each measurement so I don't repeat code in front end
//First time user signs up, they won't have a measurement. Need to use profile weight if user doesn't have a measurement
export const HomeStats = ({profile, userBodyFatMeasurements}) => {

  let measurements = [...userBodyFatMeasurements].reverse();

  const renderBfOrLbmOrWeight = (data) => {
    if(profile.isMetric && data === 'weight'){
      return (
        <div>
          <div style={{marginTop: '5px'}} className='flex baseline'>
            <p className='card-main-text'>{Number(measurements[0].weight.kg).toFixed(1)}</p>
            <span className='text-suffix'>kg</span>
          </div>
          {measurements.length >= 2 && measurements[0].weight.kg - measurements[1].weight.kg !== 0 &&
          <div className='flex flex-center-aligned'>
            {Math.sign(measurements[0].weight.kg - measurements[1].weight.kg) === 1 ? <MdArrowDropUp className='arrow'/> :  Math.sign(measurements[0].weight.kg - measurements[1].weight.kg) === -1 ? <MdArrowDropDown className='arrow'/> : null}
            <p className='difference-text'>{Math.abs(measurements[0].weight.kg - measurements[1].weight.kg).toFixed(1)}</p>
          </div>}
        </div>
      )
    } else if(!profile.isMetric && data === 'weight'){
      return (
        <div>
          <div style={{marginTop: '5px'}} className='flex baseline'>
            <p className='card-main-text'>{Number(measurements[0].weight.lb).toFixed(1)}</p>
            <span className='text-suffix'>lb</span>
          </div>
          {measurements.length >= 2 && measurements[0].weight.lb - measurements[1].weight.lb !== 0 &&
          <div className='flex flex-center-aligned'>
            {Math.sign(measurements[0].weight.lb - measurements[1].weight.lb) === 1 ? <MdArrowDropUp className='arrow'/> : Math.sign(measurements[0].weight.lb - measurements[1].weight.lb) === -1 ? <MdArrowDropDown className='arrow'/> : null}
            <p className='difference-text'>{Math.abs(measurements[0].weight.lb - measurements[1].weight.lb).toFixed(1)}</p>
          </div>}
        </div>
      )
    } else if(profile.isMetric && data === 'lbm'){
      return (
        <div>
          {measurements[0].bfPercentage !== 0 ?
          <div style={{marginTop: '5px'}} className='flex baseline'>
            <p className='card-main-text'>{Number(measurements[0].leanBodyMass.kg).toFixed(1)}</p> 
            <span className='text-suffix'>kg</span>
          </div> : <p className='card-main-text'>-</p>}
          {measurements.length >= 2 && measurements[0].bfPercentage !== 0 &&
          <div className='flex flex-center-aligned'>
            {Math.sign(measurements[0].leanBodyMass.kg - measurements[1].leanBodyMass.kg) === 1 ? <MdArrowDropUp className='arrow'/> : Math.sign(measurements[0].leanBodyMass.kg - measurements[1].leanBodyMass.kg) === -1 ? <MdArrowDropDown className='arrow'/> : null}
            <p className='difference-text'>{Math.abs(measurements[0].leanBodyMass.kg - measurements[1].leanBodyMass.kg).toFixed(1)}</p>
          </div>}
        </div>
      )
    } else if(!profile.isMetric && data === 'lbm'){
      return (
        <div>
        {measurements[0].bfPercentage !== 0 ?
        <div style={{marginTop: '5px'}} className='flex baseline'>
          <p className='card-main-text'>{Number(measurements[0].leanBodyMass.lb).toFixed(1)}</p>
          <span className='text-suffix'>lb</span>
        </div> : <p className='card-main-text'>-</p>}
        {measurements.length >= 2 && measurements[0].bfPercentage !== 0 &&
        <div className='flex flex-center-aligned'>
          {Math.sign(measurements[0].leanBodyMass.lb - measurements[1].leanBodyMass.lb) === 1 ? <MdArrowDropUp className='arrow'/> : Math.sign(measurements[0].leanBodyMass.lb - measurements[1].leanBodyMass.lb) === -1 ? <MdArrowDropDown className='arrow'/> : null}
          <p className='difference-text'>{Math.abs(measurements[0].leanBodyMass.lb - measurements[1].leanBodyMass.lb).toFixed(1)}</p>
        </div>}
      </div>
      )
    } else if(data === 'bf'){
      return (
        <div>
          {measurements[0].bfPercentage !== 0 ?
          <div style={{marginTop: '5px'}} className='flex baseline'>
            <p className='card-main-text'>{Number(measurements[0].bfPercentage).toFixed(1)}</p>
            <span className='text-suffix'>%</span>
          </div> : <p className='card-main-text'>-</p>}
          {measurements.length >= 2 && measurements[0].bfPercentage !== 0 &&
          <div className='flex flex-center-aligned'>
            {Math.sign(measurements[0].bfPercentage - measurements[1].bfPercentage) === 1 ? <MdArrowDropUp className='arrow'/> : Math.sign(measurements[0].bfPercentage - measurements[1].bfPercentage) === -1 ? <MdArrowDropDown className='arrow'/> : null}
            <p className='difference-text'>{measurements[0].bfPercentage !== 0 ? Math.abs(measurements[0].bfPercentage - measurements[1].bfPercentage).toFixed(1) : ''}</p>
          </div>}
        </div>
      )
    }
  }

  return(
    <div className='home-stats mb-4'>
      <div className='home-card'>
        <div className='flex space-between'>
          <div>
            <p className='blog-card-category home-card-label'>Weight</p>
            {renderBfOrLbmOrWeight('weight')}
          </div>
          <p className='home-card-text'>{moment(measurements[0].date).format('ll')}</p>
        </div>
      </div>
      <div className='home-card'>
        <div className='flex space-between'>
          <div>
            <p className='blog-card-category home-card-label'>Body Fat</p>
            {renderBfOrLbmOrWeight('bf')}
          </div>
          <p className='home-card-text'>{measurements[0].bfPercentage !== 0 && moment(measurements[0].date).format('ll')}</p>
        </div>
      </div>
      <div className='home-card'>
        <div className='flex space-between'>
          <div>
            <p className='blog-card-category home-card-label'>Lean Body Mass</p>
            {renderBfOrLbmOrWeight('lbm')}
          </div>
          <p className='home-card-text'>{measurements[0].bfPercentage !== 0 && moment(measurements[0].date).format('ll')}</p>
        </div>
      </div>
    </div>
  )
}
