import React from 'react';
import { capitalize } from '../../helpers/utils';
import './index.css';

class ProgramsPage extends React.Component {

  renderMacros = () => {
    const { nutrition } = this.props;
    return Object.values(nutrition).map(value => {
      if(typeof(value) === 'object'){
        return(
          <div style={{marginRight: '25px'}}>
            <p className='main-text'>{value.grams}g</p>
            <p className='macro-label-bottom'>{value.displayName === 'Carbohydrates' ? 'Carbs' : value.displayName}</p>
          </div>
        )
      }
    })  
  }

  render(){
    return(
      this.props.profile && this.props.nutrition && 
      <div className='programs-page'>
        <img src={''} style={{height: '400px'}}/>
        <div className='preferences-section'>
          <div className='preferences-card'>
            <p className='blog-card-category macro-label plan'>Macronutrients</p>
            <div className='flex'>
              <div style={{marginRight: '25px'}}>
                <p className='main-text'>{this.props.nutrition.goalCalories}</p>
                <p className='macro-label-bottom'>Calories</p>
              </div>
             {this.renderMacros()}
            </div>
          </div>
          {/* <div className='preferences-card'>
            <p className='blog-card-category macro-label stats'>Protein</p>
            <p className='main-text'>{this.props.nutrition.protein.grams}g</p>
          </div>
          <div className='preferences-card'>
            <p className='blog-card-category macro-label stats'>Carbs</p>
            <p className='main-text'>{this.props.nutrition.carbs.grams}g</p>
          </div>
          <div className='preferences-card'>
            <p className='blog-card-category macro-label stats'>Fats</p>
            <p className='main-text'>{this.props.nutrition.fats.grams}g</p>
          </div> */}
          <div className='flex'>
            <div className='preferences-card'>
              <p className='blog-card-category macro-label plan'>Diet type</p>
              <p className='main-text'>{capitalize(this.props.profile.foodPreference)}</p>
            </div>
            <div className='preferences-card'>
              <p className='blog-card-category macro-label plan'>Meals per day</p>
              <p className='main-text'>{this.props.profile.mealsPerDay}</p>
            </div>
          </div>
         
          <div className='preferences-card'>
            <p className='blog-card-category macro-label plan'>Goal</p>
            <p className='main-text'>{this.props.profile.goal.text}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ProgramsPage;