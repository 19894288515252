import { put, takeLatest, call } from "redux-saga/effects";
import {  CREATE_OR_UPDATE_TODO, GET_TODO } from "./constants";
import { createOrUpdateTodoSuccess, createOrUpdateTodoError, getTodoSuccess, getTodoError } from "./actions";
import { fetchData } from "../api";

export function* createOrUpdateTodo({todo}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/todo`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(todo)
    };
    let payload = yield call(fetchData, url, config);
    yield put(createOrUpdateTodoSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(createOrUpdateTodoError(error));
  }
}

export function* getTodo(){
  const url = `${process.env.REACT_APP_API_URL}/api/todo/me`;
  try {
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getTodoSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getTodoError(error));
  }
}

export default function* todoSaga() {
  yield takeLatest(CREATE_OR_UPDATE_TODO, createOrUpdateTodo);
  yield takeLatest(GET_TODO, getTodo);
}