import React from 'react';
import { BiEditAlt } from "react-icons/bi";
import { Tag } from 'antd';

export const ExerciseCard = ({
  exercise, 
  toggleCreateExerciseModal, 
  setEditExercise, 
  setIsExerciseEdit,
  isUserExercisePage,
  toggleExerciseHistoryModal,
  setSingleUserExerciseHistory
}) => {

  const gradientStyle = {
    background: 'linear-gradient(to right, #2761f1 0%, #FF0066 100%)',
    color: 'white', 
    fontWeight: '500',
    border: 'none'
  };

  return (
    <div 
      className='exercise-card' 
      onClick={() => {isUserExercisePage && toggleExerciseHistoryModal(true); setSingleUserExerciseHistory && setSingleUserExerciseHistory(exercise)}}
      style={{cursor: isUserExercisePage && 'pointer'}}
    >
      <div className='flex space-between flex-center-aligned'>
        <div>
          <p className='home-card-text mb-1'>{isUserExercisePage ? exercise?.exerciseId?.name : exercise.name}</p>
          {exercise.categories?.map(category => <Tag className='mt-1' style={gradientStyle}>{category.name}</Tag>)}
        </div>
        {!isUserExercisePage &&
        <BiEditAlt style={{cursor: 'pointer'}} onClick={() => {setEditExercise(exercise); toggleCreateExerciseModal(true); setIsExerciseEdit(true)}}/>}
      </div>
    </div>
  )
}