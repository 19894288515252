import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  CLEAR_PROFILE,
  SAVE_PROFILE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_ERROR,
  GET_NUTRITION,
  GET_NUTRITION_SUCCESS,
  GET_NUTRITION_ERROR,
  SAVE_NUTRITION,
  SAVE_NUTRITION_LOCAL,
  SAVE_NUTRITION_SUCCESS,
  SAVE_NUTRITION_ERROR,
  SAVE_PROFILE_IGNORE_NUTRITION,
  CLEAR_ALERT,
  ADD_WEIGHT_TO_PROFILE,
  ADD_WEIGHT_TO_PROFILE_SUCCESS,
  ADD_WEIGHT_TO_PROFILE_ERROR,
  GET_ALL_ONSITE_CLIENTS,
  GET_ALL_ONSITE_CLIENTS_SUCCESS,
  GET_ALL_ONSITE_CLIENTS_ERROR,
  GET_FITBIT_NUTRITION,
  GET_FITBIT_NUTRITION_SUCCESS,
  GET_ALL_NUTRITION,
  GET_ALL_NUTRITION_SUCCESS,
  GET_ALL_NUTRITION_ERROR,
  GET_USER_FITBIT_LOGS,
  GET_USER_FITBIT_LOGS_SUCCESS,
  GET_USER_FITBIT_LOGS_ERROR,
  CREATE_USER_MEAL_PLAN,
  CREATE_USER_MEAL_PLAN_SUCCESS,
  CREATE_USER_MEAL_PLAN_ERROR,
  CREATE_WEEKLY_MEAL_PLAN,
  CREATE_WEEKLY_MEAL_PLAN_SUCCESS,
  CREATE_WEEKLY_MEAL_PLAN_ERROR,
  GET_EQUIVALENT_RECIPES,
  GET_EQUIVALENT_RECIPES_SUCCESS,
  GET_EQUIVALENT_RECIPES_ERROR,
  SWAP_MEAL_PLAN_RECIPE,
  SWAP_MEAL_PLAN_RECIPE_SUCCESS,
  SWAP_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_FOOD_IN_MEAL_PLAN,
  UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS,
  UPDATE_FOOD_IN_MEAL_PLAN_ERROR,
  GET_ALTERNATIVE_FOODS,
  GET_ALTERNATIVE_FOODS_SUCCESS,
  GET_ALTERNATIVE_FOODS_ERROR,
  UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS,
  UPDATE_NUTRITION_PREFERENCES,
  UPDATE_NUTRITION_PREFERENCES_SUCCESS,
  UPDATE_NUTRITION_PREFERENCES_ERROR,
  GET_NUTRITION_PREFERENCES,
  GET_NUTRITION_PREFERENCES_SUCCESS,
  GET_NUTRITION_PREFERENCES_ERROR,
  UPDATE_ASSESSMENT_PROFILE,
  UPDATE_ASSESSMENT_PROFILE_SUCCESS,
  UPDATE_ASSESSMENT_PROFILE_ERROR,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_ERROR
} from "./constants";
import { GET_USER_MEALPLAN_BY_ID_SUCCESS } from "../Admin/constants";
import { UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_SUCCESS } from "./constants";

export function getCurrentProfile() {
  return {
    type: GET_PROFILE,
  };
}

export function getCurrentProfileSuccess(payload) {
  return {
    type: GET_PROFILE_SUCCESS,
    payload,
  };
}

export function getCurrentProfileError(payload) {
  return {
    type: GET_PROFILE_ERROR,
    payload,
  };
}

export function clearProfile() {
  return {
    type: CLEAR_PROFILE,
  };
}

export function saveProfile(profileData, cb) {
  return {
    type: SAVE_PROFILE,
    profileData,
    // callback: cb,
  };
}

export function saveProfileIgnoreNutrition(profileData) {
  return {
    type: SAVE_PROFILE_IGNORE_NUTRITION,
    profileData,
  };
}

export function saveProfileSuccess(payload) {
  return {
    type: SAVE_PROFILE_SUCCESS,
    payload,
  };
}

export function saveProfileError(payload) {
  return {
    type: SAVE_PROFILE_ERROR,
    payload,
  };
}

export function saveNutrition(nutrition) {
  return {
    type: SAVE_NUTRITION,
    nutrition,
  };
}

export function saveNutritionLocal(payload) {
  return {
    type: SAVE_NUTRITION_LOCAL,
    payload,
  };
}

export function saveNutritionSuccess(payload) {
  return {
    type: SAVE_NUTRITION_SUCCESS,
    payload,
  };
}

export function saveNutritionError(payload) {
  return {
    type: SAVE_NUTRITION_ERROR,
    payload,
  };
}

export function getNutrition() {
  return {
    type: GET_NUTRITION,
  };
}

export function getNutritionSuccess(payload) {
  return {
    type: GET_NUTRITION_SUCCESS,
    payload,
  };
}

export function getNutritionError(payload) {
  return {
    type: GET_NUTRITION_ERROR,
    payload,
  };
}

export function addWeightToProfile() {
  return {
    type: ADD_WEIGHT_TO_PROFILE,
  };
}

export function addWeightToProfileSuccess(payload) {
  return {
    type: ADD_WEIGHT_TO_PROFILE_SUCCESS,
    payload,
  };
}

export function addWeightToProfileError(payload) {
  return {
    type: ADD_WEIGHT_TO_PROFILE_ERROR,
    payload,
  };
}


export function getAllOnsiteClients() {
  return {
    type: GET_ALL_ONSITE_CLIENTS
  };
}

export function getAllOnsiteClientsSuccess(payload) {
  return {
    type: GET_ALL_ONSITE_CLIENTS_SUCCESS,
    payload,
  };
}

export function getAllOnsiteClientsError(payload) {
  return {
    type: GET_ALL_ONSITE_CLIENTS_ERROR,
    payload,
  };
}

export function getUserFitbitNutrition() {
  return {
    type: GET_FITBIT_NUTRITION
  };
}

export function getUserFitbitNutritionSuccess(payload) {
  return {
    type: GET_FITBIT_NUTRITION_SUCCESS,
    payload
  };
}

export function getAllNutritionForUser() {
  return {
    type: GET_ALL_NUTRITION
  };
}

export function getAllNutritionSuccess(payload) {
  return {
    type: GET_ALL_NUTRITION_SUCCESS,
    payload
  };
}

export function getAllNutritionError(error) {
  return {
    type: GET_ALL_NUTRITION_ERROR,
    error
  };
}

export function getUserFitbitLogs() {
  return {
    type: GET_USER_FITBIT_LOGS
  };
}

export function getUserFitbitLogsSuccess(payload) {
  return {
    type: GET_USER_FITBIT_LOGS_SUCCESS,
    payload
  };
}

export function getUserFitbitLogsError(error) {
  return {
    type: GET_USER_FITBIT_LOGS_ERROR,
    error
  };
}

export function createUserMealPlan(mealPlanData) {
  return {
    type: CREATE_USER_MEAL_PLAN,
    mealPlanData
  };
}

export function createUserMealPlanSuccess(payload) {
  return {
    type: CREATE_USER_MEAL_PLAN_SUCCESS,
    payload
  };
}

export function createUserMealPlanError(error) {
  return {
    type: CREATE_USER_MEAL_PLAN_ERROR,
    error
  };
}

export function createWeeklyMealPlan(mealPlanData) {
  return {
    type: CREATE_WEEKLY_MEAL_PLAN,
    mealPlanData
  };
}

export function createWeeklyMealPlanSuccess(payload) {
  return {
    type: CREATE_WEEKLY_MEAL_PLAN_SUCCESS,
    payload
  };
}

export function createWeeklyMealPlanError(error) {
  return {
    type: CREATE_WEEKLY_MEAL_PLAN_ERROR,
    error
  };
}

export function getEquivalentRecipes(recipeId, status) {
  return {
    type: GET_EQUIVALENT_RECIPES,
    recipeId,
    status
  };
}

export function getEquivalentRecipesSuccess(payload, recipeId) {
  return {
    type: GET_EQUIVALENT_RECIPES_SUCCESS,
    payload,
    recipeId
  };
}

export function getEquivalentRecipesError(error, recipeId) {
  return {
    type: GET_EQUIVALENT_RECIPES_ERROR,
    error,
    recipeId
  };
}

export function swapMealPlanRecipe(mealId, newRecipeId, status) {
  return {
    type: SWAP_MEAL_PLAN_RECIPE,
    mealId,
    newRecipeId,
    status
  };
}

export function swapMealPlanRecipeSuccess(payload, newRecipeId) {
  return {
    type: SWAP_MEAL_PLAN_RECIPE_SUCCESS,
    payload,
    newRecipeId
  };
}

export function swapMealPlanRecipeError(error, newRecipeId) {
  return {
    type: SWAP_MEAL_PLAN_RECIPE_ERROR,
    error,
    newRecipeId
  };
}

export function updateFoodInMealPlan(foodData, isProgramPage) {
  return {
    type: UPDATE_FOOD_IN_MEAL_PLAN,
    foodData: {
      foodData,
      isProgramPage
    }
  };
}

export function updateFoodInMealPlanSuccess(payload, isProgramPage) {
  if (isProgramPage) {
    return {
      type: UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS,
      payload,
    };
  } else {
    return {
      type: GET_USER_MEALPLAN_BY_ID_SUCCESS,
      payload: { customMealPlan: payload && payload.mealPlan || {} },
    };
  }
}

export function updateFoodInMealPlanLoadingSuccess() {
  return {
    type: UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS
  };
}

export function updateFoodInMealPlanError(error) {
  return {
    type: UPDATE_FOOD_IN_MEAL_PLAN_ERROR,
    error,
  };
}

export function getAlternativeFoods(foodsString) {
  return {
    type: GET_ALTERNATIVE_FOODS,
    foodsString
  };
}

export function getAlternativeFoodsSuccess(payload) {
  return {
    type: GET_ALTERNATIVE_FOODS_SUCCESS,
    payload,
  };
}

export function getAlternativeFoodsError(error) {
  return {
    type: GET_ALTERNATIVE_FOODS_ERROR,
    error,
  };
}

export function updateUserProfile(userInfo) {
  return {
    type: UPDATE_USER_PROFILE,
    userInfo
  };
}

export function updateUserProfileSuccess(payload) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload
  };
}

export function updateUserProfileError(err) {
  return {
    type: UPDATE_USER_PROFILE_ERROR,
    err
  };
}

export function updateAssessmentProfile(assessmentData) {
  return {
    type: UPDATE_ASSESSMENT_PROFILE,
    assessmentData
  };
}

export function createNewUser(userData) {
  return {
    type: CREATE_NEW_USER,
    userData
  };
}

export function createNewUserSuccess(payload) {
  return {
    type: CREATE_NEW_USER_SUCCESS,
    payload
  };
}

export function createNewUserError(error) {
  return {
    type: CREATE_NEW_USER_ERROR,
    error
  };
}