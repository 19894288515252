import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_EXERCISES,
  CREATE_EXERCISE,
  UPDATE_EXERCISE,
  SAVE_EXERCISE_HISTORY,
  GET_EXERCISE_HISTORY_BY_IDS,
  GET_EXERCISES_HISTORY_BY_IDS_ARR,
  GET_EXERCISE_CATEGORIES,
  GET_MUSCLES
} from "./constants";
import {
  getExercisesSuccess,
  getExercisesError,
  createExerciseSuccess,
  createExerciseError,
  updateExerciseSuccess,
  updateExerciseError,
  saveExerciseHistorySuccess,
  saveExerciseHistoryError,
  getExerciseHistoryByIdsSuccess,
  getExerciseHistoryByIdsError,
  getExercisesHistoryByIdsArrSuccess,
  getExercisesHistoryByIdsArrError,
  getAllExerciseHistoryByIdsSuccess,
  getAllExerciseHistoryByIdsError,
  getExerciseCategoriesSuccess,
  getExerciseCategoriesError,
  getMusclesSuccess,
  getMusclesError
} from "./actions";
import { fetchData } from "../api";

export function* getExercises(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let exercises = yield call(fetchData, url, config);
    yield put(getExercisesSuccess(exercises));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getExercisesError(error));
  }
}

export function* createExercise({exercise}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exercise)
    }
   
    const createdExercise = yield call(fetchData, url, config);
    yield put(createExerciseSuccess(createdExercise)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createExerciseError(error));
  }
}

export function* updateExercise({exercise}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise/${exercise._id}`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exercise)
    }
   
    const createdExercise = yield call(fetchData, url, config);
    yield put(updateExerciseSuccess(createdExercise)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(updateExerciseError(error));
  }
}

export function* saveExerciseHistory({dataArr, workoutId}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise-history?workoutId=${workoutId}`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataArr)
    }
    const exerciseHistoriesArr = yield call(fetchData, url, config);
    yield put(saveExerciseHistorySuccess(exerciseHistoriesArr)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(saveExerciseHistoryError(error));
  }
}

export function* getExerciseHistoryByIds({clientId, exerciseId, allHistory, callback}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise-history/${clientId}/${exerciseId}?allHistory=${allHistory}`;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    const exerciseHistory = yield call(fetchData, url, config);
    if (allHistory) {
      yield put(getAllExerciseHistoryByIdsSuccess(exerciseHistory));
    } else {
      yield put(getExerciseHistoryByIdsSuccess(exerciseHistory));
    }
    if (callback) {
      if (Object.keys(exerciseHistory).length !== 0) {
        callback(exerciseHistory);
      } else {
        callback({});
      }
    }
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     if (allHistory) {
       yield put(getExerciseHistoryByIdsError(error));
     } else {
       yield put(getAllExerciseHistoryByIdsError(error));
     }
     if (callback) {
      callback({}); // Call the callback with an empty object when there is no history
    }
  }
}

//Get exercise history by user id and array of exercise ids or 'all' for all exercises
export function* getExercisesHistoryByIdsArr({clientId, exerciseIdsArr}) {
  
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise-history/${clientId}?exerciseIds=${exerciseIdsArr === 'all' ? 'all' : exerciseIdsArr.join(',')}`;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    const exercisesHistory = yield call(fetchData, url, config);
  
    yield put(getExercisesHistoryByIdsArrSuccess(exercisesHistory)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(getExercisesHistoryByIdsArrError(error));
  }
}

export function* getExerciseCategories() {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/exercise-category/all`;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    const exerciseCategories = yield call(fetchData, url, config);

    yield put(getExerciseCategoriesSuccess(exerciseCategories)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(getExerciseCategoriesError(error));
  }
}

export function* getMuscles() {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/muscle/all`;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    const muscles = yield call(fetchData, url, config);

    yield put(getMusclesSuccess(muscles)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(getMusclesError(error));
  }
}



export default function* exercisesSaga() {
  yield takeLatest(GET_EXERCISES, getExercises);
  yield takeLatest(CREATE_EXERCISE, createExercise);
  yield takeLatest(UPDATE_EXERCISE, updateExercise);
  yield takeLatest(SAVE_EXERCISE_HISTORY, saveExerciseHistory);
  yield takeLatest(GET_EXERCISE_HISTORY_BY_IDS, getExerciseHistoryByIds);
  yield takeLatest(GET_EXERCISES_HISTORY_BY_IDS_ARR, getExercisesHistoryByIdsArr);
  yield takeLatest(GET_EXERCISE_CATEGORIES, getExerciseCategories);
  yield takeLatest(GET_MUSCLES, getMuscles);
}