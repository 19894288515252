import React, { useState } from 'react';
import { Tag } from 'antd';
import { capitalize } from '../../helpers/utils';
import { WorkoutProgramCard } from '../AdminWorkoutProgramLibraryPage/workoutProgramCard';
import './index.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const WorkoutPlanCard = ({
  identifier,
  workoutPlan,
  setCurrentWorkoutProgram,
  toggleWorkoutProgramModal,
  isUserWorkoutProgram
}) => {

  const gradientStyle = {
    background: 'linear-gradient(to right, #2761f1 0%, #FF0066 100%)',
    color: 'white', 
    fontWeight: '500',
    border: 'none'
  };

  return (
    <>
      <div className='workout-plan-card mb-3' key={identifier}>
        {/* <div className='card-image'>
          <img src={workoutPlan.imageUrl} alt="Workout plan" />
        </div> */}
        <div className='card-content'>
          <div className='title-section mt-1 mb-1'>
            <p className='main-page-title'>{workoutPlan.name}</p>
            <p className='home-card-text' style={{lineHeight: '18px'}}>{workoutPlan.description}</p>
          </div>
          <div className='labels mb-3 mt-1'>
            {workoutPlan.genderFocus !== 'all' && <Tag style={gradientStyle}>{capitalize(workoutPlan.genderFocus)} focused</Tag>}
            {workoutPlan.duration && <Tag style={gradientStyle}>{workoutPlan.duration} weeks</Tag>}
            {workoutPlan.level && <Tag style={gradientStyle}>{capitalize(workoutPlan.level)}</Tag>}
            {workoutPlan.goal && <Tag style={gradientStyle}>{capitalize(workoutPlan.goal)}</Tag>}
            {workoutPlan.equipment && <Tag style={gradientStyle}>{capitalize(workoutPlan.equipment)}</Tag>}
          </div>
          {workoutPlan.workoutPrograms.map(program => 
          <div className='mb-2'>
            <WorkoutProgramCard 
              toggleWorkoutProgramModal={toggleWorkoutProgramModal}
              workoutProgram={program} 
              setCurrentWorkoutProgram={setCurrentWorkoutProgram}
              isUserWorkoutProgram={isUserWorkoutProgram}
              style={{background: 'white'}}
            />
          </div>
          )}
        </div>
      </div>
    </>
  );
};