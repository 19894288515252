import {
  GET_USER_FITBIT_LOGS_BY_ID,
  GET_USER_FITBIT_LOGS_BY_ID_SUCCESS,
  GET_USER_FITBIT_LOGS_BY_ID_ERROR,
  GET_USER_NUTRITION_BY_ID,
  GET_USER_NUTRITION_BY_ID_SUCCESS,
  GET_USER_NUTRITION_BY_ID_ERROR,
  GET_USER_NUTRITION_PREFERENCE_BY_ID,
  GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS,
  GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR,
  DELETE_MEALPLAN_TEMPLATE_SUCCESS,
  UPDATE_MEALPLAN_BY_ID_SUCCESS,
  GET_MEALPLAN_BY_ID,
  GET_MEALPLAN_BY_ID_SUCCESS,
  GET_COACH_MEALPLANS,
  GET_COACH_MEALPLANS_SUCCESS,
  GET_USER_MEALPLAN_BY_ID,
  GET_USER_MEALPLAN_BY_ID_SUCCESS,
  GET_USER_MEALPLAN_BY_ID_ERROR,
  GET_CLIENT_PROFILE_BY_ID,
  GET_CLIENT_PROFILE_BY_ID_SUCCESS,
  GET_CLIENT_PROFILE_BY_ID_ERROR,
  SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE,
  DELETE_CUSTOM_MEAL_PLAN,
  DELETE_CUSTOM_MEAL_PLAN_SUCCESS
} from "./constants";
import { restrictions } from '../../data/restrictions';

const initialState = {
  fitbitLogs: null,
  fitbitLogsLoading: false,
  fitbitLogsError: null,
  allUserNutrition: null,
  allUserNutritionLoading: false,
  allUserNutritionError: null,
  userNutritionPreference: null,
  userNutritionPreferenceLoading: false,
  userNutritionPreferenceError: null,
  coachMealplans: {templates: []},
  coachMealplansLoading: false,
  mealplan: null,
  mealplanLoading: false,
  userMealplan: null,
  userMealplanLoading: true,
  userMealplanError: null,
  clientProfile: null,
  clientProfileLoading: false,
  clientProfileError: null,
  nutrition: null,
  foodRestrictions: restrictions
};

function admin(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_FITBIT_LOGS_BY_ID:
      return {
        ...state,
        fitbitLogs: null,
        fitbitLogsLoading: true,
        fitbitLogsError: null
      };
    case GET_USER_FITBIT_LOGS_BY_ID_SUCCESS:
      return {
        ...state,
        fitbitLogs: payload,
        fitbitLogsError: false,
        fitbitLogsLoading: false,
      };
    case GET_USER_FITBIT_LOGS_BY_ID_ERROR:
      return {
        ...state,
        fitbitLogsError: action.err,
        fitbitLogsLoading: false,
      };
    case GET_USER_NUTRITION_BY_ID:
      return {
        ...state,
        allUserNutrition: null,
        allUserNutritionLoading: true,
        allUserNutritionError: null
      };
    case GET_USER_NUTRITION_BY_ID_SUCCESS:
      return {
        ...state,
        allUserNutrition: payload,
        allUserNutritionError: false,
        allUserNutritionLoading: false,
      };
    case GET_USER_NUTRITION_BY_ID_ERROR:
      return {
        ...state,
        allUserNutritionError: action.err,
        allUserNutritionLoading: false,
      };
    case GET_USER_NUTRITION_PREFERENCE_BY_ID:
      return {
        ...state,
        userNutritionPreferenceErr: false,
        userNutritionPreferenceLoading: true
      };
    case GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS:
      return {
        ...state,
        userNutritionPreference: payload,
        userNutritionPreferenceLoading: false
      };
    case GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR:
      return {
        ...state,
        userNutritionPreferenceLoading: false,
        userNutritionPreferenceErr: action.err
      };
    case DELETE_MEALPLAN_TEMPLATE_SUCCESS:
      return {
        ...state,
        coachMealplans: {
          templates: (state.coachMealplans.templates || []).filter(i => i._id !== payload)
        }
      }
    case UPDATE_MEALPLAN_BY_ID_SUCCESS:
      return {
        ...state,
        mealplan: payload
      }
    case GET_MEALPLAN_BY_ID:
      return {
        ...state,
        mealplanLoading: true
      }
    case GET_MEALPLAN_BY_ID_SUCCESS:
      return {
        ...state,
        mealplan: payload,
        mealplanLoading: false
      }
    case GET_COACH_MEALPLANS:
      return {
        ...state,
        coachMealplans: {templates: []},
        coachMealplansLoading: true
      };
    case GET_COACH_MEALPLANS_SUCCESS:
      return {
        ...state,
        coachMealplans: payload,
        coachMealplansLoading: false
      }
    case GET_USER_MEALPLAN_BY_ID:
      return {
        ...state,
        userMealplan: null,
        userMealplanLoading: true,
        userMealplanError: null
      };
    case GET_USER_MEALPLAN_BY_ID_SUCCESS:
      return {
        ...state,
        userMealplan: {
          ...state.userMealplan,
          ...payload,
        },
        userMealplanLoading: false,
        userMealplanError: null
      }
    case GET_USER_MEALPLAN_BY_ID_ERROR:
      return {
        ...state,
        userMealplanError: action.err,
        allUserNutritionLoading: false
      }
    case GET_CLIENT_PROFILE_BY_ID:
      return {
        ...state,
        clientProfile: null,
        clientProfileLoading: true,
        clientProfileError: null
      };
    case GET_CLIENT_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        clientProfile: payload,
        clientProfileLoading: false,
        clientProfileError: false,
      };
    case GET_CLIENT_PROFILE_BY_ID_ERROR:
      return {
        ...state,
        clientProfileLoading: false,
        clientProfileError: action.err
      };
    case SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE:
      return {
        ...state,
        nutrition: payload,
        allUserNutrition: [{...payload.nutritionResults}, ...state.allUserNutrition]
      }
    case DELETE_CUSTOM_MEAL_PLAN:
      return {
        ...state,
        userMealplanLoading: true,
        userMealplanError: null
      };
    case DELETE_CUSTOM_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        userMealplan: {...state.userMealplan, customMealPlan: null},
        userMealplanLoading: false,
        userMealplanError: null
      };
    default:
      return state;
  }
}

export default admin;