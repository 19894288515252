import {
  GET_CARB_CYCLING_PLAN,
  GET_CARB_CYCLING_PLAN_SUCCESS,
  GET_CARB_CYCLING_PLAN_ERROR,
  CREATE_CARB_CYCLING_PLAN,
  CREATE_CARB_CYCLING_PLAN_SUCCESS,
  CREATE_CARB_CYCLING_PLAN_ERROR,
  HANDLE_CARB_CLYCLING_MODAL_OPEN
} from './constants';

export function getCarbCyclingPlan(userId) {
  return {
    type: GET_CARB_CYCLING_PLAN,
    userId
  };
}

export function getCarbCyclingPlanSuccess(payload) {
  return {
    type: GET_CARB_CYCLING_PLAN_SUCCESS,
    payload
  };
}

export function getCarbCyclingPlanErr(err) {
  return {
    type: GET_CARB_CYCLING_PLAN_ERROR,
    err
  };
}

export function createCarbCyclingPlan(carbSensitivity, dayDistribution, selectedDays) {
  return {
    type: CREATE_CARB_CYCLING_PLAN,
    carbSensitivity,
    dayDistribution,
    selectedDays
  };
}

export function createCarbCyclingPlanSuccess(payload) {
  return {
    type: CREATE_CARB_CYCLING_PLAN_SUCCESS,
    payload
  };
}

export function createCarbCyclingPlanErr(err) {
  return {
    type: CREATE_CARB_CYCLING_PLAN_ERROR,
    err
  };
}

export function handleCarbCyclingModalOpen(isOpen) {
  return {
    type: HANDLE_CARB_CLYCLING_MODAL_OPEN,
    isOpen
  };
}