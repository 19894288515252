import { put, takeLatest, delay, call, all, select } from "redux-saga/effects";
import {
  GET_PROFILE,
  SAVE_PROFILE,
  GET_NUTRITION,
  SAVE_NUTRITION,
  SAVE_PROFILE_IGNORE_NUTRITION,
  ADD_WEIGHT_TO_PROFILE,
  GET_ALL_ONSITE_CLIENTS,
  GET_FITBIT_NUTRITION,
  GET_ALL_NUTRITION,
  GET_USER_FITBIT_LOGS,
  CREATE_USER_MEAL_PLAN,
  CREATE_WEEKLY_MEAL_PLAN,
  GET_EQUIVALENT_RECIPES,
  SWAP_MEAL_PLAN_RECIPE,
  UPDATE_FOOD_IN_MEAL_PLAN,
  GET_ALTERNATIVE_FOODS,
  UPDATE_ASSESSMENT_PROFILE,
  CREATE_NEW_USER
} from "./constants";
import {
  getCurrentProfileSuccess,
  getCurrentProfileError,
  saveProfileSuccess,
  saveProfileError,
  getNutritionSuccess,
  getNutritionError,
  saveNutritionSuccess,
  saveNutritionError,
  addWeightToProfileSuccess,
  addWeightToProfileError,
  getAllOnsiteClientsSuccess,
  getAllOnsiteClientsError,
  getUserFitbitNutritionSuccess,
  getAllNutritionSuccess,
  getAllNutritionError,
  getUserFitbitLogsSuccess,
  getUserFitbitLogsError,
  createUserMealPlanSuccess,
  createUserMealPlanError,
  createWeeklyMealPlanSuccess,
  createWeeklyMealPlanError,
  getEquivalentRecipesSuccess,
  getEquivalentRecipesError,
  swapMealPlanRecipeSuccess,
  swapMealPlanRecipeError,
  updateFoodInMealPlanSuccess,
  updateFoodInMealPlanError,
  getAlternativeFoodsSuccess,
  getAlternativeFoodsError,
  updateFoodInMealPlanLoadingSuccess,
  createNewUser,
  createNewUserSuccess,
  createNewUserError
} from "./actions";
import { loadUser } from "../Auth/saga";
import { fetchData, postData } from "../api";
import { poundsToKgs, kgsToPounds } from '../../helpers/utils';
import { store } from "../../App";
import { getAllUserNotifications } from "../Onesignal/actions";
import { updateUserProfileError, updateUserProfileSuccess } from "./actions";
import { UPDATE_USER_PROFILE } from "./constants";
import { toast } from "react-toastify";

export function* getCurrentProfile() {
  const url = `${process.env.REACT_APP_API_URL}/api/profile/me`;
  try {
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    if (payload.withings) {
      fetch(`${process.env.REACT_APP_API_URL}/api/withings/updateUserWeight`, {
        method: 'post',
        headers: {
          "x-auth-token": window.localStorage.token
        }
      });
    }
    yield put(getCurrentProfileSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getCurrentProfileError(error));
    yield delay(2000);
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getCurrentProfileSuccess(payload));
    yield call(loadUser);
  }
}

export function* saveProfileIgnoreNutrition(payload) {
  const token = localStorage.token;
  // const isFromSettings = payload.profileData.isFromSettings; /account-settings not being used any more
  const ignoreNutrition = payload.profileData.ignoreNutrition;
  const body = payload.profileData;
  // const body = isFromSettings ? payload.profileData.profileData : payload.profileData;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/profile`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }
    const payload = yield call(fetchData, url, config);
    yield put(saveProfileSuccess({
      profile: payload
      // isFromSettings
    }));
    showToasterForProfileUpdate('🎉 Your macronutrients data have been updated!');

    if (!ignoreNutrition) {
      const nutrition = yield select((state) => state.profile.nutrition);
      yield all([
        call(saveNutrition, nutrition),
        call(addWeightToProfile, body),
      ]);
    }

  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(saveProfileError(error));
  }
}

export function* saveProfile(payload) {
  const token = localStorage.token;
  let { profileData } = payload;
  try {
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/api/profile`;
    const responseData = yield call(() => postData(url, config, profileData.profileData));
    yield put(saveProfileSuccess(responseData.data));
    showToasterForProfileUpdate();
    yield call(saveNutrition, profileData);
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(saveProfileError(error));
  }
}

export function* getAllNutritionForUser() {
  const token = localStorage.token;

  try {
    let payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/nutrition/me/all`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    yield put(getAllNutritionSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield delay(2000);
    let payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/nutrition/me/all`,
      {
        headers: {
          "x-auth-token": window.localStorage.token,
        },
      }
    );
    yield put(getAllNutritionError(payload));
  }
}

export function* getNutrition() {
  const token = localStorage.token;

  try {
    let payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/nutrition/me`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    yield put(getNutritionSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield delay(2000);
    let payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/nutrition/me`,
      {
        headers: {
          "x-auth-token": window.localStorage.token,
        },
      }
    );
    yield put(getNutritionSuccess(payload));
    // yield put(getNutritionError(error));
  }
}

export function* saveNutrition(nutrition) {
  const token = localStorage.token;
  try {
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/api/nutrition`;
    yield call(() => postData(url, config, nutrition.nutritionResults));
    yield put(saveNutritionSuccess(nutrition.nutritionResults));
    nutrition.hasOwnProperty('callback') && nutrition.callback(); //Redirects to dashboard only called when creating an account
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(saveNutritionError(error));
  }
}

export function* addWeightToProfile(profileData) {
  let weightKg = profileData.isMetric ? profileData.currentWeight : Number(poundsToKgs(profileData.currentWeight));
  let weightLb = !profileData.isMetric ? profileData.currentWeight : Number(kgsToPounds(profileData.currentWeight));
  let weight = { weightKg: weightKg, weightLb: weightLb };
  if (!profileData.weight.length || ((+weightKg !== profileData.weight[0].weightKg) && (weightLb !== profileData.weight[0].weightLb))) {
    const token = localStorage.token;
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/profile/weight`;
      const config = {
        method: "PUT",
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(weight)
      }
      const payload = yield call(fetchData, url, config);
      yield put(addWeightToProfileSuccess(payload));

    } catch (err) {
      const error = yield err.json();
      console.log(error);
      yield put(addWeightToProfileError(error));
    }
  }
}

export function* getAllOnsiteClients() {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/profile/onsite-clients`;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }

    const payload = yield call(fetchData, url, config);
    yield put(getAllOnsiteClientsSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getAllOnsiteClientsError(error));
  }
}

export function* getUserFitbitNutrition() {
  try {
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let url = `${process.env.REACT_APP_API_URL}/auth/fitbit/getUserFitbitActivity`;
    let payload = yield call(fetchData, url, config);
    //modify date so it can be an iterable array in order to display as a card
    yield put(getUserFitbitNutritionSuccess(payload));
  } catch (err) {
    console.log(err)
  }
}

export function* getUserFitbitLogs() {
  try {
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let url = `${process.env.REACT_APP_API_URL}/auth/fitbit/me/all`;
    let payload = yield call(fetchData, url, config);
    yield put(getUserFitbitLogsSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(getUserFitbitLogsError(error));
  }
}

export function* createUserMealPlan({ mealPlanData }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/getUserMealPlan`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(
        {
          calories: mealPlanData.calories,
          days: mealPlanData.days,
          format: mealPlanData.format
        }
      )
    };
    let payload = yield call(fetchData, url, config);
    yield put(createUserMealPlanSuccess(payload));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(createUserMealPlanError(error));
  }
}

export function* createWeeklyMealPlan({ mealPlanData }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/createWeeklyMealPlan`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(
        {
          calories: mealPlanData.calories,
          format: mealPlanData.format,
          // kcalLimit: 0.4,
          // maxNumOfServings: 2,
          // maxServingWeight: 700,
          // minServingWeight: 100,
          // breakfastDistribution: 0.3
          // lunchDistribution: 0.3
          // dinnerDistribution: 0.3
          // snackDistribution: 0.2
        }
      )
    };
    let payload = yield call(fetchData, url, config);
    yield put(createWeeklyMealPlanSuccess(payload));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(createWeeklyMealPlanError(error));
  }
}

export function* getEquivalentRecipes({ recipeId, status }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/getEquivalentRecipes`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ recipeId: recipeId })
    };
    let payload = yield call(fetchData, url, config);
    const profile = yield select((state) => state.profile.profile);
    //adds equivalent recipes object to mealPlan recipe
    profile.currentMealPlan[status].map(meal => {
      return meal.meals.map(recipe => {
        if (recipe.recipe.id === recipeId) {
          recipe.alternativeRecipes = payload;
        }
      })
    })
    yield put(getEquivalentRecipesSuccess(profile, recipeId));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getEquivalentRecipesError(error, recipeId));
  }
}

export function* swapMealPlanRecipe({ mealId, newRecipeId }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/swapMealPlanRecipe`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ mealId: mealId, newRecipeId: newRecipeId })
    };
    let payload = yield call(fetchData, url, config);
    yield put(swapMealPlanRecipeSuccess(payload, newRecipeId));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(swapMealPlanRecipeError(error, newRecipeId));
  }
}

export function* updateFoodInMealPlan({ foodData }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/mealplan/updateFood`;
    const token = window.localStorage.token;
    const email = store.getState().auth.user && store.getState().auth.user.email;
    const config = {
      method: 'PUT',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(foodData.foodData)
    };
    let payload = yield call(fetchData, url, config);
    yield all(
      [
        put(updateFoodInMealPlanSuccess(payload, foodData.isProgramPage)),
        put(updateFoodInMealPlanLoadingSuccess()),
        put(getAllUserNotifications(email))
      ]
    );
  } catch (err) {
    console.log(err);
    const error = yield err.json();
    console.log(error);
    yield put(updateFoodInMealPlanError(error));
  }
}

export function* getAlternativeFoods({ foodsString }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/mealplan/alternatives?foods=${foodsString}`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getAlternativeFoodsSuccess(payload));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getAlternativeFoodsError(error));
  }
}

export function* updateUserProfile({ userInfo }) {
  const url = `${process.env.REACT_APP_API_URL}/api/users`;
  const config = {
    method: "PUT",
    headers: {
      "x-auth-token": window.localStorage.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ profileImage: userInfo && userInfo.file })
  };
  try {
    let payload = yield call(fetchData, url, config);
    yield put(updateUserProfileSuccess(payload));
    // yield call(loadUser());
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(updateUserProfileError(error));
  }
}

export function* updateAssessmentProfile(payload) {
  const token = localStorage.token;
  const body = payload.assessmentData;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/profile/assessment`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }
    const payload = yield call(fetchData, url, config);
    yield put(saveProfileSuccess({
      profile: payload
    }));
    showToasterForProfileUpdate('🎉 Assessment data have been updated!');
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(saveProfileError(error));
  }
}

export function* createNewClientUser({ userData }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/users/createNewUser`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(
        {
          user: userData
        }
      )
    };
    let payload = yield call(fetchData, url, config);
    yield put(createNewUserSuccess(payload));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(createNewUserError(error));
  }
}

const showToasterForProfileUpdate = (msg) => {
  toast.success(msg ? msg : '🎉 Your assessment data have been updated!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export default function* authSaga() {
  yield takeLatest(GET_PROFILE, getCurrentProfile);
  yield takeLatest(SAVE_PROFILE, saveProfile);
  yield takeLatest(SAVE_PROFILE_IGNORE_NUTRITION, saveProfileIgnoreNutrition);
  yield takeLatest(GET_NUTRITION, getNutrition);
  yield takeLatest(SAVE_NUTRITION, saveNutrition);
  yield takeLatest(ADD_WEIGHT_TO_PROFILE, addWeightToProfile);
  yield takeLatest(GET_ALL_ONSITE_CLIENTS, getAllOnsiteClients);
  yield takeLatest(GET_FITBIT_NUTRITION, getUserFitbitNutrition);
  yield takeLatest(GET_ALL_NUTRITION, getAllNutritionForUser);
  yield takeLatest(GET_USER_FITBIT_LOGS, getUserFitbitLogs);
  yield takeLatest(CREATE_USER_MEAL_PLAN, createUserMealPlan);
  yield takeLatest(CREATE_WEEKLY_MEAL_PLAN, createWeeklyMealPlan);
  yield takeLatest(GET_EQUIVALENT_RECIPES, getEquivalentRecipes);
  yield takeLatest(SWAP_MEAL_PLAN_RECIPE, swapMealPlanRecipe);
  yield takeLatest(UPDATE_FOOD_IN_MEAL_PLAN, updateFoodInMealPlan);
  yield takeLatest(GET_ALTERNATIVE_FOODS, getAlternativeFoods);
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeLatest(UPDATE_ASSESSMENT_PROFILE, updateAssessmentProfile);
  yield takeLatest(CREATE_NEW_USER, createNewClientUser);
}
