import React from 'react';
import { connect } from 'react-redux';
import UserWorkoutsPage from '../../presentational/UserWorkoutsPage';
import { getUserWorkouts } from '../../ducks/Workout/actions';
import { TOGGLE_CREATE_WORKOUT_MODAL, TOGGLE_EXERCISE_HISTORY_MODAL} from '../../ducks/Global/constants'


class AdminClientWorkouts extends React.Component {

  componentDidMount(){
    this.props.getUserWorkouts(this.props.match.params.id);
  }

  render(){
    const { userWorkouts } = this.props;
    return(
      <div className='site-layout-no-background admin'>
        {userWorkouts ? <UserWorkoutsPage {...this.props} /> : 
        <div style={{backgroundColor: '#e4e7eb', borderRadius: '10px'}}>
          <p className='home-card-text' style={{padding: '24px'}}>This client doesn't have any workouts.</p>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userWorkouts: state.workouts.userWorkouts,
    exerciseHistoryModalVisible: state.global.exerciseHistoryModalVisible,
    allExerciseHistory: state.exercises.allExerciseHistory
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getUserWorkouts: (clientId, currentWeek, averageMonth) => dispatch(getUserWorkouts(clientId, currentWeek, averageMonth)),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleExerciseHistoryModal: (value) => dispatch({
      type: TOGGLE_EXERCISE_HISTORY_MODAL,
      value
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminClientWorkouts);