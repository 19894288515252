import React from 'react';
import { Switch , Divider } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { SET_NUTRITION_FROM_PROFILE_UPDATE } from '../../ducks/Profile/constants';
import { saveProfileIgnoreNutrition } from "../../ducks/Profile/actions";
import {
  cmToFeet,
  feetToCm,
  kgsToPounds,
  poundsToKgs
} from "../../helpers/utils";
import './index.css';

const calculateResultsFromProfile = (props, profileData) => {
  let nutrition = [];

  let cm = profileData.isMetric ? profileData.height : Number(profileData.heightFt) * 30.48 + Number(profileData.heightIn) * 2.54;
  let kg = !profileData.isMetric ? (Number(profileData.currentWeight) / 2.205).toFixed(2) : Number(profileData.currentWeight);

  let bmr = Math.round(
      profileData.gender === "male"
      ? 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) + 5
      : 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) - 161
  );
  
  let maintenanceCal = Math.round(bmr * Number(profileData.activityLevel.value));
  let calculatedGoalCalories;
  let days;

  //when goal is to lose weight or gain muscle
  if (profileData.byWhen !== null) {
    let goalDate = moment(profileData.byWhen).format("MM/DD/YYYY");
    let today = moment(new Date()).toDate();
    let formattedToday = moment(today).format("MM/DD/YYYY");
    days = Math.abs(
      moment(formattedToday, "MM/DD/YYYY")
        .startOf("day")
        .diff(moment(goalDate, "MM/DD/YYYY").startOf("day"), "days")
    );
    let currentWeight = Number(profileData.currentWeight);
    let goalWeight = Number(profileData.goalWeight);
    let goalPoundsOrKg =
      profileData.goal.value === "Lose weight"
        ? Number(currentWeight - goalWeight)
        : Number(goalWeight - currentWeight);
    let goalPoundsOrKgCalories = profileData.isMetric
      ? goalPoundsOrKg * 3850 //making 3850 the calorie needed to gain or lose 0.5kg
      : goalPoundsOrKg * 3500; //making 2500 the calorie needed to gain or lose 1lb
    if (profileData.goal.value === "Lose weight") {
      let deficitPerDay = Math.round(goalPoundsOrKgCalories / days);
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (profileData.goal.value === "Build muscle") {
      let surplusPerDay = Math.round(goalPoundsOrKgCalories / days);
      calculatedGoalCalories = maintenanceCal + surplusPerDay;
    }
  }

  //When goal is other than losing weight or gaining muscle
  let deficitPercentage = 0.1;
  if(profileData.deficit) {
    deficitPercentage = (profileData.deficit / 100);
  }
  /* if (profileData.goal.value === "Athletic performance") {
    let surplusPerDay = maintenanceCal * 0.1; //10% extra from maintenance
    calculatedGoalCalories = maintenanceCal + surplusPerDay;
  } */
  if (profileData.goal.value === "Lose body fat") {
    let deficitPerDay = maintenanceCal * deficitPercentage;
    calculatedGoalCalories = maintenanceCal - deficitPerDay;
  } else if (profileData.goal.value === "Gain muscle") {
    let deficitPerDay = maintenanceCal * deficitPercentage;
    calculatedGoalCalories = maintenanceCal + deficitPerDay;
  } else if (profileData.goal.value === "Body recomposition") {
    let deficitPerDay = maintenanceCal * 0.1; //10% below maintenance
    calculatedGoalCalories = maintenanceCal - deficitPerDay;
  } else if (profileData.goal.value === "Improve overall health") {
    calculatedGoalCalories = maintenanceCal;
  }

  let nutritionResults = {
    goalCalories: Math.round(calculatedGoalCalories),
    maintenanceCalories: Math.round(maintenanceCal),
    protein: {
      grams: Math.round((calculatedGoalCalories * (profileData.macros.protein / 100)) / 4),
      displayName: "Protein",
    },
    carbs: {
      grams: Math.round((calculatedGoalCalories * (profileData.macros.carb / 100)) / 4),
      displayName: "Carbohydrates",
    },
    fats: {
      grams: Math.round((calculatedGoalCalories * (profileData.macros.fat / 100)) / 9),
      displayName: "Fats"
    },
  };
  nutritionResults.carbs.percentage = (nutritionResults.carbs.grams * 4 / nutritionResults.goalCalories) * 100;
  nutritionResults.protein.percentage = (nutritionResults.protein.grams * 4 / nutritionResults.goalCalories) * 100;
  nutritionResults.fats.percentage = (nutritionResults.fats.grams * 9 / nutritionResults.goalCalories) * 100;

  nutrition.push({nutritionResults: nutritionResults});
  props.setNutritionFromProfileUpdate(nutrition[0]);
  const finalObject = {
    profileData,
    isFromSettings: true
  };
  props.saveProfileIgnoreNutrition(finalObject)
};

class ProfileSettingsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      profileData: null,
    };
  }

  componentDidMount() {
    if (this.props.profile) {
      this.setState({ profileData: this.props.profile });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (!this.state.profileData && this.props.profile) {
      this.setState({ profileData: this.props.profile });
    }
  }

  switchUnits = () => {
    let profileData = { ...this.state.profileData };
    const isMetric = profileData.isMetric ? !profileData.isMetric : true;
    if (profileData.isMetric) {
      let heightObj = cmToFeet(profileData.height);
      profileData.heightFt = heightObj.feet;
      profileData.heightIn = heightObj.inches;
      profileData.currentWeight = kgsToPounds(profileData.currentWeight);
      if (profileData.goalWeight) {
        profileData.goalWeight = kgsToPounds(profileData.goalWeight);
      }
      if (profileData.startWeight) {
        profileData.startWeight = kgsToPounds(profileData.startWeight);
      }
    } else {
      profileData.height = feetToCm(profileData.heightFt, profileData.heightIn);
      profileData.currentWeight = poundsToKgs(profileData.currentWeight);
      if (profileData.goalWeight) {
        profileData.goalWeight = poundsToKgs(profileData.goalWeight);
      }
      if (profileData.startWeight) {
        profileData.startWeight = poundsToKgs(profileData.startWeight);
      }
    }
    profileData.isMetric = isMetric;
    this.setState({ profileData: profileData });
    calculateResultsFromProfile(this.props, profileData);
  };

  render(){
    return(
      <div className='settings-page site-layout-background sub-menu'>
        <div className='account-settings'>
          <div className='measure-system flex flex-center-aligned space-between'>
            <div>
              <p className='title'>Measurement system</p>
              <p className='subtitle'>Select your measurement system.</p>
            </div>
            <Switch
              checkedChildren="Metric"
              unCheckedChildren="Imperial"
              checked={
                this.state.profileData && this.state.profileData.isMetric
                  ? this.state.profileData.isMetric
                  : false
              }
              onChange={this.switchUnits}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfileIgnoreNutrition: (payload) => dispatch(saveProfileIgnoreNutrition(payload)),
    setNutritionFromProfileUpdate: (payload) => {
			return dispatch({
          type: SET_NUTRITION_FROM_PROFILE_UPDATE,
          payload
			 });
		}
  };
};

export default connect(null, mapDispatchToProps)(ProfileSettingsPage);