export const restrictions = {
  "data": {
    "restrictions": {
      "edges": [
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MjhlNTY0OGItYTk3ZS00NWNjLWE2ZDEtNmYxZWY2ZjI1YjE0",
            "name": "Mushrooms",
            "subcategory": "Fruits and Vegetables",
            "slugname": "mushrooms",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NWE2NjFhYjktZTE2MS00YjU2LTlhOTktZjJhYTExOTEzZWJj",
            "name": "Soy",
            "subcategory": "Most Common",
            "slugname": "soy",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OTQwYmQ1YTctYjU0ZC00YzZmLWFlOGItMmE1MDAwY2E4MjA4",
            "name": "Fish",
            "subcategory": "Most Common",
            "slugname": "fish",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDNkNzU4ODYtNTNkMi00NTg5LThkNGEtMDBjMDMyZTRjZGRh",
            "name": "Cottage cheese",
            "subcategory": "Animal Origin",
            "slugname": "cottage-cheese",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTg0YzUwNDctMGU0OS00OTRhLTlhMzQtOWRiNWVhOThlNGFm",
            "name": "Kiwi",
            "subcategory": "Fruits and Vegetables",
            "slugname": "kiwi",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246N2U5Y2EzYzYtYWM5My00ZDMzLWFkNTUtZWEwOGQxZGE3NjQ2",
            "name": "Nightshades",
            "subcategory": "Fruits and Vegetables",
            "slugname": "nightshades",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZDNmYTFlNjMtZjE0OS00MTNhLTlhNmItYTc1ZDliYWUxMmI4",
            "name": "Rye",
            "subcategory": "Nuts and Seeds",
            "slugname": "rye",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDk5MTkzMGItMmMyZC00YTU5LTg0MTItOTQ3Yzk4NjIyZjVj",
            "name": "Pineapple",
            "subcategory": "Fruits and Vegetables",
            "slugname": "pineapple",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NWQ3ODg4YjctOWY5OC00NjAzLWE5MDUtZTYxNGQzOGYwMTUw",
            "name": "Potatoes",
            "subcategory": "Fruits and Vegetables",
            "slugname": "potatoes",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MGFjMWY0MDItZjdmMi00YWYyLWI5NzMtYzUxOGE1NDA2OWNk",
            "name": "Yogurt",
            "subcategory": "Animal Origin",
            "slugname": "yogurt",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTc0YTY4YTItYzBhYy00MTFmLThmNTQtYTQxM2I2ZTQ0ZDc2",
            "name": "Raw foods",
            "subcategory": "General",
            "slugname": "raw-foods",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGM0NWQxYzMtZmM0MC00MzliLWE1M2QtMzJiNDk5YTYxMTVl",
            "name": "Anchovies",
            "subcategory": "Animal Origin",
            "slugname": "anchovies",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NDMxOWRhMTktZjBkMy00YzYxLWFjMzctZjA5MjhhMjg0ZmFl",
            "name": "Artichoke",
            "subcategory": "Fruits and Vegetables",
            "slugname": "artichoke",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NDI3MDM0MTEtNDA3OC00NTkyLWE1YjctNDgxM2I2NzMwYjg3",
            "name": "Avocado",
            "subcategory": "Fruits and Vegetables",
            "slugname": "avocado",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Y2Y5NGE1ZTItY2ZjZC00YzhkLWFlNTMtYzgyZWE3YjZhMmM1",
            "name": "Beets",
            "subcategory": "Fruits and Vegetables",
            "slugname": "beets",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OWJjOWQ2MDUtMDhlYS00ZjkzLWJlMWEtNTI0NGQ3YWQ4MzI2",
            "name": "Berries",
            "subcategory": "Fruits and Vegetables",
            "slugname": "berries",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTNiMThkNzctMmFhYy00ZjFjLWJhNDQtYWQ1YTQwMzM0N2Zj",
            "name": "Celery",
            "subcategory": "Fruits and Vegetables",
            "slugname": "celery",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Zjc1ZGRjMDItYTcwZi00Y2RiLTg1NmUtMjJmYzM3MWI3ZTk3",
            "name": "Coconut",
            "subcategory": "Nuts and Seeds",
            "slugname": "coconut",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODQxNjNiN2MtNjEzNC00NzhjLTljYWMtOWJkMWE2NGVmOTEx",
            "name": "Corn",
            "subcategory": "Fruits and Vegetables",
            "slugname": "corn",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Y2Q0YWM2MzctZDgzNy00NmYxLWI2ZGMtNjFiYmQyYTNiYjEw",
            "name": "Cucumber",
            "subcategory": "Fruits and Vegetables",
            "slugname": "cucumber",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODU1ZjkzZjYtODQzOC00MTIzLTk3YzktM2U2YzU2MzllZjli",
            "name": "Dairy",
            "subcategory": "Most Common",
            "slugname": "dairy",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NjIxNTZlNjMtODIzMi00OGZiLWEzNzgtZDkyZDBiOTM4N2Nh",
            "name": "Egg",
            "subcategory": "Most Common",
            "slugname": "egg",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246M2ZmZDQ1OGUtMjczZi00MTZmLWE5NjYtNmFkYzliYjQ3OWE1",
            "name": "Gluten",
            "subcategory": "Most Common",
            "slugname": "gluten",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTJmNDM1NmQtZjc0Yi00MmVmLThkYTctODliYTgwNWM5OTkx",
            "name": "Pecan",
            "subcategory": "Nuts and Seeds",
            "slugname": "pecan",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODlhMzU4OTEtMWNmZC00N2E1LTgwOWItNjEzMWZhODhjMjEz",
            "name": "Pistachio",
            "subcategory": "Nuts and Seeds",
            "slugname": "pistachio",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MTEzNWM1NjUtYTI5ZC00NGVlLWIzZjItODExM2QzZWM3ZDZk",
            "name": "Poultry",
            "subcategory": "Animal Origin",
            "slugname": "poultry",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OGI5NTcxYzItNTE2OC00YTJhLWI3MDItOTgwYWRjYjM4ODQz",
            "name": "Red meat",
            "subcategory": "Most Common",
            "slugname": "red-meat",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZjE1ZWNkMWMtMDlkYy00YmVjLWExNDctNmRiMDRhMzliNDNl",
            "name": "Sardines",
            "subcategory": "Animal Origin",
            "slugname": "sardines",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Mzk0ZGIyZTYtMGNiMy00MmQzLTkxYTUtNjU1NDQ5MzhmYzdj",
            "name": "Sesame",
            "subcategory": "Nuts and Seeds",
            "slugname": "sesame",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZDJkYmVjMzgtZTBiNi00NWIxLWFjOWMtMTlhZDRmOTRlZWM0",
            "name": "Shellfish",
            "subcategory": "Most Common",
            "slugname": "shellfish",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTUzN2E2NWEtZjA5NS00ODZmLWE4ZWYtYmJmOWQ3OTAwZGZi",
            "name": "Spinach",
            "subcategory": "Fruits and Vegetables",
            "slugname": "spinach",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTlmY2JjNTgtYWMwOC00YTZiLTk0NjAtNWVkZDY1OTU1MTU3",
            "name": "Strawberry",
            "subcategory": "Fruits and Vegetables",
            "slugname": "strawberry",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTM0MzE5ZmItZTY2NC00NTExLTgxOTQtMjZmYzcxZWI4NDZm",
            "name": "Tree nuts",
            "subcategory": "Most Common",
            "slugname": "tree-nuts",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTUwNWU1YzQtNGIzOS00MDI2LTg5NTYtM2E2ZWQ4ZGY0ZTdi",
            "name": "Egg whites",
            "subcategory": "Animal Origin",
            "slugname": "egg-whites",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NzJlMWFkYzItYTAyYi00ZTVlLTk0MjQtOTM1ZTZkN2E4NDJm",
            "name": "Barley",
            "subcategory": "Cereals and Grains",
            "slugname": "barley",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZjUxMjU2YmQtODQ4ZS00NGU5LTlkNWUtNGU0OGY4MDI0MTEz",
            "name": "Mustard",
            "subcategory": "Herbs and Spices",
            "slugname": "mustard",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZmM0MWUzNTYtZmQ5ZC00Yjg4LWI0MDUtYjZhYjMxYTcyYTVl",
            "name": "Ginger",
            "subcategory": "Herbs and Spices",
            "slugname": "ginger",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTE4ZmJjMDQtOWU4MC00NzFkLWIzNGEtODBkYzQwYTA3MDky",
            "name": "Cinnamon",
            "subcategory": "Herbs and Spices",
            "slugname": "cinnamon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YjMxMWI2ZjEtNjY0Yy00YzliLWIxNGQtY2RhNTM0OWRjZWVm",
            "name": "Garlic",
            "subcategory": "Herbs and Spices",
            "slugname": "garlic",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MWY3MTgzZjktNmQ0NS00OTQ1LTkwMzEtNTRkYTI5ZGIyZGVh",
            "name": "Cilantro",
            "subcategory": "Herbs and Spices",
            "slugname": "cilantro",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MTc1NzdhNmYtZDQxMy00MzM0LWEwNTgtMzIyNjI5YTI1NTMx",
            "name": "Oregano",
            "subcategory": "Herbs and Spices",
            "slugname": "oregano",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YWVjNGYwNjAtZmQ3YS00YWZlLWE2MDYtNDViZWQwODFkMmNl",
            "name": "Tofu",
            "subcategory": "General",
            "slugname": "tofu",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODU1YzEzMDQtNTEyMS00MGY5LTgwYmMtNzIwOTU0ZGI4YWE4",
            "name": "Vegetables",
            "subcategory": "General",
            "slugname": "vegetables",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZmI5ZGQ2ZmItMjgxYy00Y2FkLWJhZGItZDBkMGNkNDFhNjEz",
            "name": "Peanut",
            "subcategory": "Most Common",
            "slugname": "peanut",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NDViMWUxOGUtMDcxNS00MGM4LWE1OGMtNTY0OTJlZmFkZGY3",
            "name": "Apple",
            "subcategory": "Fruits and Vegetables",
            "slugname": "apple",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTVhMzM0ZjItNTZjYi00MmU3LTg3NmYtM2JiZTgzNDM4ZjBh",
            "name": "Banana",
            "subcategory": "Fruits and Vegetables",
            "slugname": "banana",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YjM2NGUyZmQtMDMyMS00MWIxLTkzMmYtY2NlMmUwMDZmNmY0",
            "name": "Broccoli",
            "subcategory": "Fruits and Vegetables",
            "slugname": "broccoli",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YzliZmNmNTMtODQ2ZC00Y2Q2LThmMDEtZDU3ODZhNjk2MTJm",
            "name": "Carrot",
            "subcategory": "Fruits and Vegetables",
            "slugname": "carrot",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YjVlNWE3ZWUtM2ZhYi00ODQ5LTkwZTAtZTg5YTFkMWIxZDMw",
            "name": "Cauliflower",
            "subcategory": "Fruits and Vegetables",
            "slugname": "cauliflower",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDEzZmUzMDgtNGVkMC00M2ExLWJhZWYtODZmYmQwMDgwMGU1",
            "name": "Fish high in mercury",
            "subcategory": "Animal Origin",
            "slugname": "fish-high-in-mercury",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YThjODczYzctNDgyZC00ZGE0LWE1MTgtYjc5NzljNmIyZmYw",
            "name": "Melon",
            "subcategory": "Fruits and Vegetables",
            "slugname": "melon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NzRjNWI2MzMtNGUyYS00MjA4LTk3NDEtN2U4NDNmZGYwOWVh",
            "name": "Onion",
            "subcategory": "Fruits and Vegetables",
            "slugname": "onion",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MmM2MmFmODEtMDZmNC00YjBiLTg1ZGEtMzE1OWQ2OWIwNzQ0",
            "name": "Pear",
            "subcategory": "Fruits and Vegetables",
            "slugname": "pear",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YmQ0NTBhMjItODEyNC00MmY2LTk2MGEtNmQxYmRlYjkxMjll",
            "name": "Pork",
            "subcategory": "Animal Origin",
            "slugname": "pork",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YzhhOTNmOWItZGJmZC00NGFhLTlhNjYtYzA0OGY2ZTEwNWNh",
            "name": "Shrimp",
            "subcategory": "Animal Origin",
            "slugname": "shrimp",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YzlhMmVjZGMtMWFmOS00MzRjLWE1MTktMGQ3ODg4MGQ5N2Yw",
            "name": "Sugar",
            "subcategory": "Most Common",
            "slugname": "sugar",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Y2FkZGZhY2MtZjc1OS00Y2RiLTg2NGQtM2IxYjZlZjc5ODNh",
            "name": "Sunflower seeds",
            "subcategory": "Nuts and Seeds",
            "slugname": "sunflower-seeds",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YWVmMmI1ODUtZDU4OC00YzU3LThlNmQtMjFiNDExNmUxNTA3",
            "name": "Tomato",
            "subcategory": "Fruits and Vegetables",
            "slugname": "tomato",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODIxNmQ2ODEtNjJkZS00NGQ1LWE1ZDMtNmRkNDg1MDEzZTA2",
            "name": "Cantaloupe",
            "subcategory": "Fruits and Vegetables",
            "slugname": "cantaloupe",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NWRkMTcwZDctOWM2MS00YmQ1LTkxZDUtNTFlNTU4MzVmNjFh",
            "name": "Kelp",
            "subcategory": "Fruits and Vegetables",
            "slugname": "kelp",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTA0OTEzZTgtNzFlNi00ODVlLTkzZDctMWIyOWI2OGRlMmVh",
            "name": "Lobster",
            "subcategory": "Animal Origin",
            "slugname": "lobster",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGU2Mzg2NjAtOTQ3My00NzEwLTk3YzItZTEwMjY4YTVmODBk",
            "name": "Cow milk",
            "subcategory": "Animal Origin",
            "slugname": "cow-milk",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MzI0MmZhZWUtMTJjZi00YTZlLWE5MGMtMjZhNjU1MWEwYmI1",
            "name": "Grapefruit",
            "subcategory": "Fruits and Vegetables",
            "slugname": "grapefruit",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MjZkZTFiYjktMTdiYy00N2JmLWFmNGItOTM1MTk1ODdkNjk1",
            "name": "Basil",
            "subcategory": "Fruits and Vegetables",
            "slugname": "basil",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MjRiMWI3N2YtYjEyZS00Yzc4LWFkYjYtMWYyNDBiZmQxZTdm",
            "name": "Swordfish",
            "subcategory": "Animal Origin",
            "slugname": "swordfish",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MWVjNDliM2EtYjFjNy00ZmJhLWFjNDAtYjc2YjdkYWIxMGI4",
            "name": "Cabbage",
            "subcategory": "Fruits and Vegetables",
            "slugname": "cabbage",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MTFhYzU3ODAtMjdhNi00ZGIyLWIyYWYtMWUxMzNjYmU1MmZl",
            "name": "Egg yolk",
            "subcategory": "Animal Origin",
            "slugname": "egg-yolk",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OTM1N2MzMmQtMzViYy00Nzg4LWE2M2YtZDYxMDg3ODhjZDlj",
            "name": "Malt",
            "subcategory": "Cereals and Grains",
            "slugname": "malt",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OTFiNjE5MWEtM2EzZC00M2MzLWFlN2EtNWJmOGQ0ZmExOTgy",
            "name": "Bran",
            "subcategory": "Cereals and Grains",
            "slugname": "bran",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Njg3NWRmZDUtNzg0Ni00MmVkLWE4ZWUtZDE4OWUzZWNhNGJm",
            "name": "Brown rice",
            "subcategory": "Cereals and Grains",
            "slugname": "brown-rice",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Mzk5MDMzNGMtMTg0MC00ZWQ3LWFmYjQtMDI1ZDk0Mjk3Yzlh",
            "name": "Wheat",
            "subcategory": "Cereals and Grains",
            "slugname": "wheat",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTk2NzNhNmUtNjRmNS00OWZlLTlhZWUtMWQzMWY1MWRkNThk",
            "name": "Oils and fats",
            "subcategory": "General",
            "slugname": "oils-and-fats",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NDdkYmE2MGUtNjc4MS00MjZmLTgwZmYtMmExYTdlYzY5ZmVk",
            "name": "Lectins",
            "subcategory": "General",
            "slugname": "lectins",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZWJhMTYwZDgtZWU3Ni00YmVkLWEzYmQtMzgyMTE0OTlmM2Ez",
            "name": "Codfish",
            "subcategory": "Animal Origin",
            "slugname": "codfish",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTgzOWU4MjMtM2U1Yy00MWNlLWI0YzktMzNiMzQ3YTQ2ODA3",
            "name": "Chia seeds",
            "subcategory": "Nuts and Seeds",
            "slugname": "chia-seeds",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZThiMmVkMzQtMDYxNS00MjgyLThmMmQtMDhhNjM2OWRlNmM1",
            "name": "Green olives",
            "subcategory": "Fruits and Vegetables",
            "slugname": "green-olives",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZDJkNDhlMjYtNzIwNC00YmUzLWE2ZDMtNzIyM2IyZmE0MDY1",
            "name": "Ground pepper",
            "subcategory": "Fruits and Vegetables",
            "slugname": "ground-pepper",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZDAyOWI5NGItYmI5OS00Nzc0LTk1ODAtNWIxMDNhZjA3Mjdi",
            "name": "Beef",
            "subcategory": "Animal Origin",
            "slugname": "beef",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YmVlMGM2OWEtYmQ4MC00ODQ0LWEzZGEtNzdlNmIxNjIzZjNj",
            "name": "Lima beans",
            "subcategory": "Fruits and Vegetables",
            "slugname": "lima-beans",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YmNkZGJhMjUtZjY2OC00OTFmLWFiYzUtMWRjZGIwNTQ4N2Uz",
            "name": "Soybean",
            "subcategory": "Fruits and Vegetables",
            "slugname": "soybean",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YjI2ZjI0ZGEtZmM4OC00MmMwLWEwMjMtZjA4OGNlZTM0NTVi",
            "name": "Green peas",
            "subcategory": "Fruits and Vegetables",
            "slugname": "green-peas",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YWI4OWZhZjYtYjgwNi00MDRlLTgzZjctNjNjMjc2YTk3MGM2",
            "name": "Almonds",
            "subcategory": "Nuts and Seeds",
            "slugname": "almonds",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTg2YzlhZGUtMTdkMi00NmFmLTgxNzUtZGJmYzk5NmU2MDM1",
            "name": "Bell pepper",
            "subcategory": "Fruits and Vegetables",
            "slugname": "bell-pepper",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OTJiZDlkOWUtNDExNS00MmI0LTk5ZjctMDE0NzRiNzJkNGZj",
            "name": "Grape",
            "subcategory": "Fruits and Vegetables",
            "slugname": "grape",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OGNmMTgwZTktNTBiNi00YWRlLTk4ZWQtMjhkODI5M2JkM2E2",
            "name": "Scallops",
            "subcategory": "Animal Origin",
            "slugname": "scallops",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTg5OGQwOTktZmU2OC00NWUyLWFiYzEtZDUyODIxODU2MTVm",
            "name": "Crab",
            "subcategory": "Animal Origin",
            "slugname": "crab",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ODliZmMyNDMtZDQ2YS00NDU3LWEyYTYtMDVkNGIxZWYzNDkx",
            "name": "Cheddar cheese",
            "subcategory": "Animal Origin",
            "slugname": "cheddar-cheese",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NjU5ZDJhNTYtYjRjZS00Zjg4LThhNmItMWY3OWNhN2NiMTg1",
            "name": "Mushroom",
            "subcategory": "Fruits and Vegetables",
            "slugname": "mushroom",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NjNhNzRmOWQtZTU4NS00MzA4LTk4ZjItNWNlNzFlYjNkN2I4",
            "name": "Chicken",
            "subcategory": "Animal Origin",
            "slugname": "chicken",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTc3OGUxMmEtNjhjMS00MzhhLTkyNjItNmI0NjJlZTJkNDA5",
            "name": "Bay leaf",
            "subcategory": "Fruits and Vegetables",
            "slugname": "bay-leaf",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NTRhYzBkMTEtOTk3OC00OTA3LWEyNTktNWY0M2RmODg5ODA2",
            "name": "Tarragon",
            "subcategory": "Fruits and Vegetables",
            "slugname": "tarragon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGRhZGQzMzMtY2QzYi00MTk3LWI1NzAtNDdlYjU1NzRkOGNm",
            "name": "Squash",
            "subcategory": "Fruits and Vegetables",
            "slugname": "squash",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGNjZDlmY2YtMDEyNC00ODlhLTlkODYtM2M1M2ViMjYxMDg1",
            "name": "White potato",
            "subcategory": "Fruits and Vegetables",
            "slugname": "white-potato",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGM1ZmY3MDQtMjhlOS00MDYxLTgyMWYtNDJiMmJhMzUwNzhm",
            "name": "Clam",
            "subcategory": "Animal Origin",
            "slugname": "clam",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NDk1N2Y1MDUtYTczYi00ZTM4LWI1OTEtZjRhODc1Y2Q3Nzhl",
            "name": "Kale",
            "subcategory": "Fruits and Vegetables",
            "slugname": "kale",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246M2ExOTUyNjEtYTk2NC00YjQwLTg1ZTUtYzJlNTY1ZjBmOTAz",
            "name": "Turkey",
            "subcategory": "Animal Origin",
            "slugname": "turkey",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MmUyYjc2Y2EtZTNmMy00MDA0LTk4MWQtYjEwNGE3ZGMzMWQy",
            "name": "Eggplant",
            "subcategory": "Fruits and Vegetables",
            "slugname": "eggplant",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MWI2NDlhZTUtZWMyZC00YjgyLTg1YmYtZWZiZDNlM2I3MzUy",
            "name": "Sole",
            "subcategory": "Animal Origin",
            "slugname": "sole",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MWI1MjAyNTYtNDBmOC00YThjLTk0NmItYWI3ZDA1YWZhNTgz",
            "name": "Haddock",
            "subcategory": "Animal Origin",
            "slugname": "haddock",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MTQ1OWI0NjAtMWY0My00ZDZiLWFjZTAtNTY3M2M0ZWNlZmY1",
            "name": "Cashew",
            "subcategory": "Nuts and Seeds",
            "slugname": "cashew",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDcxNTE4OGEtMDY2NS00MzUzLWI1OTYtM2MzZTVlN2RmNjBk",
            "name": "Green bean",
            "subcategory": "Fruits and Vegetables",
            "slugname": "green-bean",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDVlYmI1ZTItOWQ1Mi00YzhjLTg5ODQtZTZkZDA3NTNiMDE5",
            "name": "Black pepper",
            "subcategory": "Herbs and Spices",
            "slugname": "black-pepper",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MDRhZmJlZWEtZTg4MS00ZDIzLTk0ODItOTA5NDMzMGNkNTVj",
            "name": "Blueberry",
            "subcategory": "Fruits and Vegetables",
            "slugname": "blueberry",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NWQ0YzkxYjktOGE0ZS00OTdjLWE2MmQtYjNlYzg1Y2MxYTJh",
            "name": "Oats",
            "subcategory": "Cereals and Grains",
            "slugname": "oats",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZWIxZDM4MDctNjY3YS00ZDliLTg1OTktYzNjYjEyODk5ODBj",
            "name": "Coffee",
            "subcategory": "General",
            "slugname": "coffee",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTQ1ZDgzODItNzZhYi00MzkxLTlkYWQtYTUzYjRiOGU1MTli",
            "name": "Safflower",
            "subcategory": "General",
            "slugname": "safflower",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YzhhNjYwZWYtZjgzMS00YTIyLTkzMmMtOTEwZDhlNTIxMjc0",
            "name": "Cocoa",
            "subcategory": "General",
            "slugname": "cocoa",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTFhODAzNWMtNjYwOC00NzU3LTg0NGYtZDNmYWEzZTRiMzMz",
            "name": "Cola",
            "subcategory": "General",
            "slugname": "cola",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246N2YyNjc3ZTMtYzMxMy00MTMwLTljYTgtOGQ2YWRjOWMwYzZh",
            "name": "Black tea",
            "subcategory": "General",
            "slugname": "black-tea",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246NGZjNzZkNDQtMjZmMS00OWY1LTkxOWEtNDI3YjM1YzQ0MzY5",
            "name": "Histamines",
            "subcategory": "General",
            "slugname": "histamines",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246MWNmODk0ZDYtZDFiMi00YjllLTg2Y2ItMWQ1YzUwZjY4Y2M5",
            "name": "Yeast bakers",
            "subcategory": "General",
            "slugname": "yeast-bakers",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZGMzZTRjNmMtMzRiYS00ZDlhLTllYzYtZjIwZjJiYjE5Y2Y1",
            "name": "Yeast brewers",
            "subcategory": "General",
            "slugname": "yeast-brewers",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZmQ0MzcwMGEtZjE3Mi00YTZiLThlMzctMjQ0ZTIxNzY0MWFj",
            "name": "Honey",
            "subcategory": "Animal Origin",
            "slugname": "honey",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTdhYTU1NTAtOWQ3Ni00ZDZiLWI5MDgtNWUwY2M0ZjM1Mjc4",
            "name": "Tuna",
            "subcategory": "Animal Origin",
            "slugname": "tuna",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTRkNjBhZTAtYjJkZS00ODM3LTkxZDYtNmNmMjU4MDlhMDYw",
            "name": "Orange",
            "subcategory": "Fruits and Vegetables",
            "slugname": "orange",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTJkYjNmZWUtNjhlYy00NTU0LWJiMDktZGE5ZGYwMGYwM2Q0",
            "name": "Black walnut",
            "subcategory": "Nuts and Seeds",
            "slugname": "black-walnut",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTJiMjg3OGQtMDFlZC00YzY0LTg5NjAtYjEyNWY2ZjY5NjIy",
            "name": "Salmon",
            "subcategory": "Animal Origin",
            "slugname": "salmon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZTI2ZmFhZmEtNDZhNC00MDE2LTk0MzEtNmU0ZmVjOWNjNWMz",
            "name": "Mozzarella cheese",
            "subcategory": "Animal Origin",
            "slugname": "mozzarella-cheese",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246ZGM5NThkZTUtM2MyMC00YjIyLWI4NTQtY2ZlODEwMTQxMTg0",
            "name": "Lemon",
            "subcategory": "Fruits and Vegetables",
            "slugname": "lemon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246Y2YxODI0YjctMWU0OC00ZmZkLTg4ZDItZDUwMjQ4MTA0MmRj",
            "name": "Watermelon",
            "subcategory": "Fruits and Vegetables",
            "slugname": "watermelon",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YmIwNGE4YjQtOTRmMC00NTM4LThiY2YtNTJkZjdkMjcyYzJk",
            "name": "Asparagus",
            "subcategory": "Fruits and Vegetables",
            "slugname": "asparagus",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTA5MGIzZTUtMGJhMi00NDFhLWExNjMtZDgxM2ZkM2YyYTc4",
            "name": "Lettuce",
            "subcategory": "Fruits and Vegetables",
            "slugname": "lettuce",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246YTA1Njk2NDAtNDAyNC00ZmI5LWE0NGEtNmEyODRlMzI3ZDY0",
            "name": "Dill",
            "subcategory": "Fruits and Vegetables",
            "slugname": "dill",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OWMyZDgxMTEtZDY1Ny00NDkxLTliODgtM2NjNTNiOWQ2MDFj",
            "name": "Lamb",
            "subcategory": "Animal Origin",
            "slugname": "lamb",
            "isOnProgram": false
          }
        },
        {
          "node": {
            "id": "UmVzdHJpY3Rpb246OTYyOWUwY2UtZjVkZi00YmNjLWJmYTUtZDFlZmJjZjQ3NDBi",
            "name": "Sweet potato",
            "subcategory": "Fruits and Vegetables",
            "slugname": "sweet-potato",
            "isOnProgram": false
          }
        }
      ]
    }
  }
}