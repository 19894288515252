import { put, takeLatest, call } from "redux-saga/effects";
import { GET_TABLE_DATA } from "./constants";
import {
  getTableDataSuccess,
  getTableDataError
} from "./actions";
import { fetchData } from "../api";

export function* getTableData({path}){
  try {
    const url = `${process.env.REACT_APP_API_URL}${path}`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getTableDataSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getTableDataError(error));
  }
}

export default function* tableSaga() {
  yield takeLatest(GET_TABLE_DATA, getTableData);
}
