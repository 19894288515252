import React from 'react';
import CustomMealPlanPage from '../../presentational/CustomMealPlanPage';
import { connect } from 'react-redux'; 
import { 
  getNutrition, 
  getCurrentProfile, 
  getEquivalentRecipes, 
  swapMealPlanRecipe, 
  createWeeklyMealPlan, 
  updateFoodInMealPlan,
  getAlternativeFoods,
} from '../../ducks/Profile/actions';
import { addToShoppingList, getShoppingList, clearAddToShoppingListError} from '../../ducks/Suggestic/actions';
import { getRecipes } from '../../ducks/Recipe/actions';
import { Loading } from '../../components/Loading';
import {getUserMealPlanById, getUserNutritionById} from "../../ducks/Admin/actions";
import { HANDLE_PRICING_MODAL } from '../../ducks/Global/constants';

class CustomMealPlan extends React.Component {
  
  componentDidMount(){
    if(this.props.match.params.id) {
      this.props.getCurrentProfile(); //added this so coach can see client's meal plan when clicking on notification
      this.props.getUserNutritionById(this.props.match.params.id);
      this.props.getUserMealPlanById(this.props.match.params.id);
    } else {
      this.props.getCurrentProfile();
      this.props.getNutrition();
      this.props.getShoppingList();
    }
  }

  componentWillUnmount(){
    this.props.clearAddToShoppingListError(null)
  }

  render(){
    const notLoading = (this.props.match.params.id ?
      (this.props.userMealplan && this.props.allUserNutrition)
      : (this.props.profile && this.props.nutrition));
    return(
      <div className='home-page sub-menu'>
       {notLoading && this.props.profile
        ? <CustomMealPlanPage {...this.props} /> 
        : <Loading /> }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    nutrition: state.profile.nutrition,
    profile: state.profile.profile,
    equivalentRecipesLoading: state.profile.equivalentRecipesLoading,
    swapMealPlanLoading: state.profile.swapMealPlanLoading,
    suggesticMealPlanLoading: state.profile.suggesticMealPlanLoading,
    addToShoppingListLoading: state.suggestic.addToShoppingListLoading,
    addToShoppingListError: state.suggestic.addToShoppingListError,
    addToShoppingListSuccess: state.suggestic.addToShoppingListSuccess,
    shoppingList: state.suggestic.shoppingList,
    alternativeFoods: state.profile.alternativeFoods,
    userMealplan: state.admin.userMealplan,
    userMealplanLoading: state.admin.userMealplanLoading,
    allUserNutrition: state.admin.allUserNutrition,
    pricingModalOpen: state.global.pricingModalOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNutrition: () => dispatch(getNutrition()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getEquivalentRecipes: (recipeId, status) => dispatch(getEquivalentRecipes(recipeId, status)),
    swapMealPlanRecipe: (mealId, newRecipeId, status) => dispatch(swapMealPlanRecipe(mealId, newRecipeId, status)),
    addToShoppingList: (foodItem) => dispatch(addToShoppingList(foodItem)),
    getShoppingList: () => dispatch(getShoppingList()),
    clearAddToShoppingListError: (val) => dispatch(clearAddToShoppingListError(val)),
    createWeeklyMealPlan: (mealPlanData) => dispatch(createWeeklyMealPlan(mealPlanData)),
    updateFoodInMealPlan: (foodData, isProgramPage) => dispatch(updateFoodInMealPlan(foodData, isProgramPage)),
    getAlternativeFoods: (foodsString) => dispatch(getAlternativeFoods(foodsString)),
    getUserMealPlanById: (userId) => dispatch(getUserMealPlanById(userId)),
    getUserNutritionById: (userId) => dispatch(getUserNutritionById(userId)),
    getRecipes: (queryObj, fromMealPlanParams) => dispatch(getRecipes(queryObj, fromMealPlanParams)),
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen
      });
		},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMealPlan);