import React from 'react';
import moment from 'moment';
import './index.css';

export const MacroHistoryCard = ({nutrition}) => {
  return (
    <div className='macro-history-card'>
      <p className='home-card-text date'>{moment(nutrition.date).format("MMM D, YYYY")}</p>
      <div className='macro-section'>
        <div className='text-block'>
          <p className='blog-card-category mb-1'>Goal Calories</p>
          <p className='home-card-text'>{nutrition.goalCalories}</p>
        </div>
        <div className='text-block'>
          <p className='blog-card-category mb-1'>Maintenance Calories</p>
          <p className='home-card-text'>{nutrition.maintenanceCalories}</p>
        </div>
        <div className='text-block'>
          <p className='blog-card-category mb-1'>Protein</p>
          <p className='home-card-text'>{nutrition.protein && nutrition.protein.grams}g / {Math.round(nutrition.protein && nutrition.protein.percentage)}%</p>
        </div>
        <div className='text-block'>
          <p className='blog-card-category mb-1'>Carbs</p>
          <p className='home-card-text'>{nutrition.carbs && nutrition.carbs.grams}g / {Math.round(nutrition.carbs && nutrition.carbs.percentage)}%</p>
        </div>
        <div className='text-block'>
          <p className='blog-card-category mb-1'>Fats</p>
          <p className='home-card-text'>{nutrition.fats && nutrition.fats.grams}g / {Math.round(nutrition.fats && nutrition.fats.percentage)}%</p>
        </div>
      </div> 
    </div>
  )
}