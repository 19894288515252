import React, {useState} from "react";
import { withRouter } from "react-router";
import "../CustomMealPlanPage/index.css";
import {Button, Collapse, Tooltip, Modal, Spin, Progress, Popconfirm} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CameraOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled, LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MealElement from "./MealElement";
import Dish from "../../assets/images/dish-gray.svg";
import DatePickerModal from "./DatePickerModal";
import { MdAddShoppingCart } from "react-icons/md";
import { capitalize } from '../../helpers/utils';
import { SyncOutlined } from '@ant-design/icons';
import { BsArrowRight } from "react-icons/bs";
import { MacrosProgress } from '../../components/MacrosProgress/index';

const { Panel } = Collapse;

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "rgb(40, 239, 182)" }} spin />;

class ManualMealPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedDay: "",
      scrolledWeeks: 0,
      targetInfo: {
        calories: "",
        protein: "",
        carb: "",
        fats: "",
      },
      plan: {},
      nutritionHomeData: [],
      keys: [],
      datePickerModalVisible: false,
      imgModalVisible: false,
      activeImg: null,
      planNote: '',
      pdfGenerated: false
    };
  }

  componentDidMount() {
    const { nutrition, allUserNutrition} = this.props;
    let finalNutrition = {};
    if(window.location.pathname.split('/').includes('clients')) {
      finalNutrition = allUserNutrition && allUserNutrition[0];
    } else if (window.location.pathname.split('/').includes('nutrition')) {
      finalNutrition = nutrition;
      if (nutrition && nutrition.nutritionResults) {
        finalNutrition = nutrition.nutritionResults;
      }
    }

    if(finalNutrition) {
      const nutritionHomeData = [
        { key: 1, value: finalNutrition.goalCalories },
        { key: 2, value: finalNutrition.protein },
        { key: 3, value: finalNutrition.carbs },
        { key: 4, value: finalNutrition.fats },
      ];
      this.setState({ nutritionHomeData });
    }

    if(window.location.pathname.split('/').includes('clients')) {
      if (this.props.userMealplan && this.props.userMealplan.customMealPlan) {
        let currentDate = moment.utc().format('YYYY-MM-DD');
        const dateArr = this.props.userMealplan.customMealPlan &&
          Object.keys(this.props.userMealplan.customMealPlan.plan);
        let selectedDay = dateArr && (dateArr.filter(date => date === currentDate)[0] || dateArr[0]) ;

        this.setState({
          targetInfo: this.props.userMealplan.customMealPlan.targetInfo,
          plan: this.props.userMealplan.customMealPlan.plan,
          selectedDay: selectedDay,
          planNote: this.props.userMealplan.customMealPlan.notes
          // selectedDay: Object.keys(this.props.userMealplan.customMealPlan.plan)[0]
        });
      }
    } else if (window.location.pathname.split('/').includes('nutrition')) {
      let currentDate = moment.utc().format('YYYY-MM-DD');
      const dateArr = this.props.profile.customMealPlan && Object.keys(this.props.profile.customMealPlan.plan);
      const plan = this.props.profile.customMealPlan && Object.keys(this.props.profile.customMealPlan.plan);
      let scrolledWeeks = this.state.scrolledWeeks;
      let matched = false;
      for(let i=0; i<=plan.length; i++){
        plan.slice(
          scrolledWeeks * 7,
          (scrolledWeeks + 1) * 7
        ).forEach((item)=>{
          if(currentDate == item){
            matched = true;
          } 
        })
        if(!matched){ 
          scrolledWeeks+=1
          this.setState({
            scrolledWeeks: scrolledWeeks,
          });
        }else if(matched){
          break;
        }   
      }
      let selectedDay = dateArr && (dateArr.filter(date => date === currentDate)[0] || dateArr[0]);
      if (this.props.profile && this.props.profile.customMealPlan) {
        this.setState({
          targetInfo: this.props.profile.customMealPlan.targetInfo,
          plan: this.props.profile.customMealPlan.plan,
          selectedDay: selectedDay,
          planNote: this.props.profile.customMealPlan.notes
          // selectedDay: Object.keys(this.props.profile.customMealPlan.plan)[0],
        });
      }
    } else {
      const plan = {};
      const monday = new Date();
      monday.setDate(monday.getDate() - ((monday.getDay() + 6) % 7));
      for (let i = 0; i < 7; i++) {
        const nextDate = new Date();
        nextDate.setDate(monday.getDate() + i);
        const ISOString = moment(nextDate).format("YYYY-MM-DD");
        plan[ISOString] = [];
      }
      this.setState({
        selectedDay: moment().format("YYYY-MM-DD"),
        plan,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(window.location.pathname.split('/').includes('clients')) {
      if (prevProps.allUserNutrition && prevProps.allUserNutrition[0] && this.props.allUserNutrition && this.props.allUserNutrition[0] && (
         prevProps.allUserNutrition[0]._id !== this.props.allUserNutrition[0]._id
      )
      ) {
        const nutritionHomeData = [
          { key: 1, value: this.props.allUserNutrition[0] && this.props.allUserNutrition[0].goalCalories },
          { key: 2, value:  this.props.allUserNutrition[0] && this.props.allUserNutrition[0].protein },
          { key: 3, value:  this.props.allUserNutrition[0] && this.props.allUserNutrition[0].carbs },
          { key: 4, value:  this.props.allUserNutrition[0] && this.props.allUserNutrition[0].fats },
        ];
        this.setState({ nutritionHomeData });
      }
    } else if(window.location.pathname.split('/').includes('nutrition')) {
      if (prevProps.nutrition && this.props.nutrition && prevProps.nutrition._id !== this.props.nutrition._id) {
        const { nutrition } = this.props;
        const nutritionHomeData = [
          { key: 1, value: nutrition.goalCalories },
          { key: 2, value: nutrition.protein },
          { key: 3, value: nutrition.carbs },
          { key: 4, value: nutrition.fats },
        ];
        this.setState({ nutritionHomeData });
      }
    }

    if(window.location.pathname.split('/').includes('clients')) {
      if (prevProps.userMealplan && this.props.userMealplan && (prevProps.userMealplan.customMealPlan !== this.props.userMealplan.customMealPlan)) {
        this.setState({
          targetInfo: this.props.userMealplan.customMealPlan.targetInfo,
          plan: this.props.userMealplan.customMealPlan.plan,
          planNotes: this.props.userMealplan.customMealPlan.notes,
        });
      }
    } else if (window.location.pathname.split('/').includes('nutrition')) {
      if (prevProps.profile && this.props.profile && (prevProps.profile.customMealPlan !== this.props.profile.customMealPlan)) {
        this.setState({
          targetInfo: this.props.profile.customMealPlan.targetInfo,
          plan: this.props.profile.customMealPlan.plan,
          planNotes:  this.props.profile.customMealPlan.notes
        });
      }
    }

    if(prevProps.addToShoppingListSuccess !== this.props.addToShoppingListSuccess && this.props.addToShoppingListSuccess) {
      toast.success('Items were added to the shopping list.');
    }
  }

  nextWeek = () => {
    this.setState({
      scrolledWeeks: this.state.scrolledWeeks + 1,
    });
  };

  previousWeek = () => {
    const { scrolledWeeks } = this.state;
    if (scrolledWeeks) {
      this.setState({
        scrolledWeeks: scrolledWeeks - 1,
      });
    }
  };

  addToShoppingList = (startDate, endDate) => {
    this.toggleDatePickerModal();
    const formattedStartDate = moment(new Date(startDate)).utc();
    const formattedEndDate = moment(new Date(endDate)).utc().add(1, 'day');
    const planObj = this.state.plan;
    let foodArr = [];
    Object.keys(planObj).map((date) => {
      const formattedDate = moment(new Date(date)).utc();

      if(formattedDate < formattedStartDate || formattedDate > formattedEndDate) {
        return;
      }

      planObj[date].map((mealObj) => {
        mealObj.food.map((food) => {
          foodArr.push(food);
        })
      })
    })

    const newFoodArr = [];
    foodArr.map((foodObj) => {
      const filteredFood = foodArr.filter((food) => food.foodId === foodObj.foodId) || []
      if(filteredFood.length > 0) {
        newFoodArr.push({
          ...foodObj,
          quantity: filteredFood.length * foodObj.quantity
        })
      }
      foodArr = foodArr.filter(ar => !filteredFood.find(rm => (rm.foodId === ar.foodId)))
    });
    this.props.addToShoppingList({foods: [...(newFoodArr || [])]})
  };

  getAlternativeFoods = (id) => {
    this.props.getAlternativeFoods(id)
  };

  handleAddPhoto = (e, mealIndex) => {
    const { selectedDay } = this.state;
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.updateMeal({
          date: selectedDay,
          mealIndex: mealIndex,
          photo: reader.result,
        })
      }
    }
    e.target.value = "";
  };

  handleDeletePhoto = (mealIndex, photoIndex) => {
    const { selectedDay } = this.state;
    this.updateMeal({
      date: selectedDay,
      mealIndex,
      photoIndex
    })
  };

  handleDone = (mealIndex) => {
    const { selectedDay } = this.state;
    this.updateMeal({
      date: selectedDay,
      mealIndex
    });

  };

  updateMeal = (foodData) => {
    let isProgramPage = false; // For clients page this will be false
    if(window.location.pathname.split('/').includes('nutrition')) {
      isProgramPage = true;
    }
    this.props.updateFoodInMealPlan(foodData, isProgramPage);
  };

  calculateTotalMacros = (meal) => {
    let mealCalories = 0, mealCarbs = 0, mealProteins = 0, mealFats = 0;
    meal.food.map((item) => {
      mealCalories += item.info.calories
      mealCarbs += item.info.carbs
      mealProteins += item.info.proteins
      mealFats += item.info.fats
    });
    return { mealCalories, mealCarbs, mealProteins, mealFats };
  }

  handlePanel = (keys) => {
    this.setState({ keys })
  }

  handleEditClick = () => {
    const { userMealplan } = this.props;
    if(userMealplan && userMealplan.user) {
      this.props.history.push({
        pathname: `/dashboard/admin/clients/${userMealplan.user._id}/meal-plan`,
        state: { userId: userMealplan.user._id }
      })
    }
  }

  toggleDatePickerModal = () => {
    this.setState({
      datePickerModalVisible: !this.state.datePickerModalVisible
    })
  }

  findEquivalentRecipes = (e, totalMacros, item) => {
    e.stopPropagation();
    const { mealProteins, mealCarbs, mealFats} = totalMacros;
    //Allowing 3g less or more than recommended macros
    let nutrientsRange = `&nutrients%5BPROCNT%5D=${Math.round(mealProteins - 3)}-${Math.round(mealProteins + 3)}&nutrients%5BCHOCDF%5D=${Math.round(mealCarbs - 3)}-${Math.round(mealCarbs + 3)}&nutrients%5BFAT%5D=${Math.round(mealFats - 5)}-${Math.round(mealFats + 5)}`;
    let mealTitle = capitalize(item.mealTitle)
  
    let queryObj = {
      searchTerm: '',
      nutrientsRange: nutrientsRange,
      fromTo: '&from=0&to=30',
      healthFilter: null,
      dietFilter: null,
      calorieFilter: null,
      mealTypeFilter: `&mealType=${mealTitle}`
    }
    let fromMealPlanParams = {
      history: this.props.history,
      meal: item
    }
    this.props.setRecipeQueryObj(queryObj, item);
    this.props.getRecipes(queryObj, fromMealPlanParams);
  }

  render() {
    const {
      selectedDay,
      plan,
      scrolledWeeks,
      keys,
      datePickerModalVisible,
      targetInfo,
      planNote
    } = this.state;

    return (
      <div>
        {targetInfo &&
        <>
          <p className="home-card-title">Program Macros</p>
          <div className="macros-container progress-padding no-grid-gap">
            <MacrosProgress targetInfo={targetInfo}/>
          </div>
        </>}
        {planNote &&
        <div className='mt-3'>
          <p className="home-card-title">Coach Notes</p>
          <div className='home-card' dangerouslySetInnerHTML={{__html: planNote}}>
          </div>
        </div>}
        {
          window.location.pathname.split('/').includes('nutrition') &&
          <button
            type="button"
            className="ant-btn ant-btn-primary w-100"
            onClick={this.toggleDatePickerModal}
            style={{marginTop: "20px"}}
          >
            <div className='flex flex-center-aligned justify-center'>
              <MdAddShoppingCart style={{fontSize: '22px', marginRight: '5px'}} />
              <p>Add items to shopping list</p>
            </div> 
          </button>
        }
        {
          datePickerModalVisible && 
          <DatePickerModal
            planStartDate={Object.keys(plan)[0]}
            planEndDate={Object.keys(plan).slice(-1)[0]}
            toggleDatePickerModal={this.toggleDatePickerModal}
            visible={datePickerModalVisible}
            handleAddToShoppingList={this.addToShoppingList}
          />
        }
        {this.props.profile.user && ((this.props.profile.user.hasOwnProperty('userType') && this.props.profile.user.userType !== 'fixed') || !this.props.profile.user.hasOwnProperty('userType')) &&
          <div className="flex week-buttons flex-center-aligned w-100">
            {
              scrolledWeeks > 0 &&
              <ArrowLeftOutlined
                className="pointer"
                onClick={() => this.previousWeek()}
              />
            }
            <div className="mx-1 flex week-buttons-flex w-100">
              {(plan
                ? Object.keys(plan).slice(
                    scrolledWeeks * 7,
                    (scrolledWeeks + 1) * 7
                  )
                : []
              ).map((item) => (
                <span
                  className={`day-button ${selectedDay === item ? "day-selected" : ""}`}
                  style={{whiteSpace: "pre-wrap"}}
                  onClick={() => this.setState({ selectedDay: item })}
                  key={item}
                >
                  {moment(item).format("ddd, MMM DD") }
                </span>
              ))}
            </div>
            {
              plan && Object.keys(plan).length > (scrolledWeeks + 1) * 7 &&
              <ArrowRightOutlined
                className="pointer"
                onClick={() => this.nextWeek()}
              />
            }
          </div>
        }
        <div style={{marginBottom: '50px'}} className={"week-setup"}>
          <Collapse
            defaultActiveKey={keys}
            onChange={(key) => this.handlePanel(key)}
          >
            {(plan && plan[selectedDay] || []).map((item, mealIndex) => {
              const totalMacros = this.calculateTotalMacros(item);
              return (
                <Panel
                  header={
                    <div className='collapsed-content-wrap'>
                      {
                        !keys.includes(mealIndex.toString()) &&
                        <img className="meal-card-img" src={item && item.photos && item.photos[0] || Dish}  alt=""/>
                      }
                      <div className='meal-title-wrap'>
                        <div>
                          <p style={{lineHeight: '16px', marginRight: '15px'}} className='blog-card-category stats macro-label-bottom'>{item.mealTitle.toUpperCase()}</p>
                          {keys.includes(mealIndex.toString()) && 
                            <div onClick={(e) => this.findEquivalentRecipes(e, totalMacros, item)} className='flex flex-center-aligned'>
                              <div 
                                className='flex flex-center-aligned blue-link'  
                                style={{fontSize: '16px'}}
                              >
                                {this.props.recipesLoading &&
                                <SyncOutlined 
                                  key={mealIndex}
                                  spin={this.props.recipesLoading}
                                  className='mr-1'
                                /> 
                                }
                              </div>
                              <p className='blue-link'>Find recipes that match this meal's nutrition!</p>
                            </div>
                          }
                        </div>
                        {
                          !keys.includes(mealIndex.toString()) &&
                          <p className='macro-label-bottom'>
                          {
                            `Calories: ${Math.round(totalMacros.mealCalories)} | Protein: ${Math.round(totalMacros.mealProteins)}g | Carbs: ${Math.round(totalMacros.mealCarbs)}g | Fats: ${Math.round(totalMacros.mealFats)}g `
                          }
                          </p>
                        }
                        </div>
                      </div>
                  }
                  key={mealIndex}
                  style={item.isEated ? {borderLeft: '10px solid #2bffcc'} : {borderLeft: '10px solid transparent'}}
                >
                  <div className="week-item" key={`${selectedDay}-${mealIndex}`}>
                    {(item.food || []).map((food, foodIndex) => (
                      <MealElement
                        customMealPlan={this.props.customMealPlan}
                        updateFoodLoading={this.props.updateFoodLoading}
                        updateMeal={this.updateMeal}
                        handleAddToShoppingList={this.addToShoppingList}
                        handleGetAlternativeFoods={this.getAlternativeFoods}
                        alternativeFoods={this.props.alternativeFoods}
                        item={food}
                        key={foodIndex}
                        isEated={item.isEated}
                        location={[selectedDay, mealIndex, foodIndex]}
                      />
                    ))}
                    <div className='total-main-wrap'>
                    <div className='flex flex-center-aligned'>
                      <div className='flex flex-center-aligned'>
                        <img src={Dish} className="meal-card-img" alt=""/>
                        <div>
                          <p className='home-card-text'>
                            Total
                          </p>
                          <p className='macro-label-bottom'>
                            {
                              `${Math.round(totalMacros.mealCalories)} Calories | ${Math.round(totalMacros.mealProteins)} Protein | ${Math.round(totalMacros.mealCarbs)} Carbs | ${Math.round(totalMacros.mealFats)} Fat`
                            }
                          </p>
                        </div>
                      </div>
                    </div>

                    {this.props.profile.user && this.props.profile.user.hasOwnProperty('userType') && this.props.profile.user.userType !== 'fixed' && 
                    <div  className='icon-wrap'>
                      <div>
                        <input
                          style={{display: "none"}}
                          type="file"
                          accept="image/*"
                          id={`${selectedDay} ${mealIndex}`}
                          name="upload"
                          role="button"
                          onChange={(e) => this.handleAddPhoto(e, mealIndex)}
                        />
                        <label
                          htmlFor={`${selectedDay} ${mealIndex}`}
                        >
                          {
                            (this.props.updateFoodLoading
                                && this.props.updateFoodLoading.key
                                && (mealIndex === this.props.updateFoodLoading.key.mealIndex)
                                && this.props.updateFoodLoading.isPhoto
                                && this.props.updateFoodLoading.loading) ?
                                <Spin indicator={antIcon}/>
                                :
                                <Tooltip title="Add photo">
                                  <div className="food-icon">
                                    <CameraOutlined style={{fontSize: '1.4rem'}}/>
                                  </div>
                                </Tooltip>
                          }
                        </label>
                      </div>
                      <p className='done-text home-card-text'>Done</p>
                      <div>
                        <Tooltip title="Done">
                          <div
                            className="food-icon"
                            onClick={() => this.handleDone(mealIndex)}
                          >
                            {
                              (this.props.updateFoodLoading
                              && this.props.updateFoodLoading.key
                              && (mealIndex === this.props.updateFoodLoading.key.mealIndex)
                              && !this.props.updateFoodLoading.isPhoto
                              && this.props.updateFoodLoading.loading) ?
                                <Spin indicator={antIcon} />
                                  :
                                  (item.isEated ?
                                  <CheckCircleFilled style={{ fontSize: '1.4rem', color: '#2bffcc' }} /> :
                                  <CheckCircleOutlined style={{ fontSize: '1.4rem' }} />)
                            }
                          </div>
                        </Tooltip>
                      </div>
                    </div>}

                    </div>
                    <div className="flex flex-wrap flex-center-aligned">
                      <div className="flex flex-wrap flex-end-aligned space-between">
                        {item.photos && item.photos.length > 0 && item.photos.map((photo, photoIndex) => (
                          <div className="food-image mt-2">
                            <img
                              onClick={() => this.setState({activeImg: photo, imgModalVisible: true})}
                              className="image-standard"
                              src={photo}
                              alt="Food"
                            />
                            <Tooltip title="Delete photo">
                              <span
                                className="delete-button"
                                onClick={() => this.handleDeletePhoto(mealIndex, photoIndex)}
                              >
                              <CloseCircleFilled style={{ fontSize: '1.2rem' }} />
                              </span>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Modal
                      className='food-img-modal'
                      visible={this.state.imgModalVisible}
                      onCancel={() => this.setState({imgModalVisible: false})}
                      footer={null}
                      closable={true}
                      maskClosable={this.props.isAssessment ? false : this.props.maskClosable}
                      width="500px"
                    >
                      <img
                        className="image-standard-modal"
                        src={this.state.activeImg}
                        alt="Food"
                      />
                    </Modal>
                  </div>
                </Panel>
              )
            })}
          </Collapse>
        </div>
        {
          window.location.pathname.split('/').includes('clients') &&
          <div className='text-right'>
            <Popconfirm placement="top" title={'Are you sure you want to delete this client\'s meal plan?'} onConfirm={() => this.props.deleteCustomMealPlan(this.props.clientProfile.customMealPlan._id)} okText="Delete" cancelText="Cancel">
              <Button
                className='btn-danger'
                style={{marginRight: '5px'}}
              >
                Delete Meal Plan
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={() => this.handleEditClick()}
            >
              Edit
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(ManualMealPlanPage);