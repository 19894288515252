import React from "react";
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const renderFaq = (faqArr, style, isProductLanding) => {
  return (
    <div style={style}>
      <p className={!isProductLanding ? 'tools-section-title' : 'landing-page title center-aligned mb-5'}>
        Frequently Asked <span className='text-gradient underlined-title'>Questions</span>
      </p>
      <Collapse 
        bordered={false} 
      >
        {faqArr.map(item => 
          <Panel header={item.header} key={item.key}>
            {item.text}
          </Panel>)}
      </Collapse>
    </div>
  )
}