import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import CircleProgressBar from '../../components/CircleProgressBar';
import { connect } from 'react-redux';
import AuthModal from '../../components/AuthModal';
import { saveProfile } from "../../ducks/Profile/actions";
import { setIsLogin } from '../../ducks/Global/actions';
import { registerUser } from "../../ducks/Auth/actions";
import { FcSmartphoneTablet } from "react-icons/fc";
import { GiMagickTrick } from "react-icons/gi";
import { MdPlaylistAddCheck } from "react-icons/md";
import { SiFitbit } from "react-icons/si";

import '../../components/AuthModal/index.css'

class AssessmentCompleted extends React.Component {

  renderModalResults = () => {
    return (
      this.props.assessmentResults &&
      <div className='modal-macro-results'>
        <CircleProgressBar
          trailStrokeColor="#f0f0f0"
          strokeColor="#2bffcc"
          textColor='#5c5c5c'
          percentage={100}
          innerText={'Calories'}
          numberToDisplay={this.props.assessmentResults[0].goalCalories}
        />
        <CircleProgressBar
          trailStrokeColor="#f0f0f0"
          strokeColor="#2bffcc"
          textColor='#5c5c5c'
          percentage={Math.round(this.props.assessmentResults[0].protein.percentage)}
          innerText={this.props.assessmentResults[0].protein.displayName}
          numberToDisplay={this.props.assessmentResults[0].protein.grams}
        />
        <CircleProgressBar
          trailStrokeColor="#f0f0f0"
          strokeColor="#2bffcc"
          textColor='#5c5c5c'
          percentage={Math.round(this.props.assessmentResults[0].carbs.percentage)}
          innerText={'Carbs'}
          numberToDisplay={this.props.assessmentResults[0].carbs.grams}
        />
        <CircleProgressBar
          trailStrokeColor="#f0f0f0"
          strokeColor="#2bffcc"
          textColor='#5c5c5c'
          percentage={Math.round(this.props.assessmentResults[0].fats.percentage)}
          innerText={this.props.assessmentResults[0].fats.displayName}
          numberToDisplay={this.props.assessmentResults[0].fats.grams}
        />
      </div>
    )
  }

  render() {
    // if (!this.props.assessmentResults) {
    //   return window.open("https://www.getnubod.com", "_self")
    // }
    const benefits = [
      { icon: <MdPlaylistAddCheck style={{ fontSize: '60px', color: '#FF0066' }} />, text: <span className='benefit-text'><span style={{ fontWeight: 700, color: '#34f5c5', fontStyle: 'italic' }}>Unlock unlimited calculations</span> and keep them all stored in one place.</span> },
      { icon: <GiMagickTrick style={{ fontSize: '50px', color: '#FF0066' }} />, text: <span className='benefit-text'><span style={{ fontWeight: 700, color: '#34f5c5', fontStyle: 'italic' }}>Access the most advanced macronutrient calculator</span> and never guess your macros again.</span> },
      { icon: <SiFitbit style={{ fontSize: '40px', color: '#FF0066' }} />, text: <span className='benefit-text'><span style={{ fontWeight: 700, color: '#34f5c5', fontStyle: 'italic' }}>Connect with Fitbit</span> and reach your goals faster.</span> },
    ]

    return (
      <div className='login-page'>
        <div className='login-container'>
          <div className='info-section signup'>
            <p className='title'>Your macronutrients</p>
            {this.renderModalResults()}
            {benefits.map(benefit =>
              <div className='benefits'>
                {benefit.icon}
                <p className='home-card-text'>{benefit.text}</p>
              </div>
            )}
            <div className="assessment-questions">
              <div className="btn-wrap ">
                <div className='next-prev-btn text-center'>
                  <Link to="/dashboard/home"
                    className="assessment-button ml-auto"
                    style={{ padding: '10px 30px' }}
                  >Go To Home</Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='form-section'>
            <AuthModal
              {...this.props}
              isAssessment={this.props.history.location.state && this.props.history.location.state.isAssessment || false}
              authRedirectRoute={"/dashboard/home"}
              isLogin={this.props.isLogin}
              setIsLogin={this.props.setIsLogin(false)}
              saveProfile={this.props.saveProfile}
              registerUser={this.props.registerUser}
            />
          </div> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authRedirectRoute: state.auth.authRedirectRoute,
    isLogin: state.global.isLogin,
    assessmentResults: state.assessment.assessmentResults,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveProfile: (payload) => dispatch(saveProfile(payload)),
    setIsLogin: (value) => dispatch(setIsLogin(value)),
    registerUser: (formData, cb) => dispatch(registerUser(formData, cb)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCompleted);