import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Nubod from "../../assets/logos/nubod-black.png";
import "./index.css";
import { Layout } from "antd";
import { setCurrentPage } from "../../ducks/Global/actions";
import { RiVipCrownLine } from "react-icons/ri";
import { HANDLE_SIDEBAR, SET_PROGRAM_VISIBILITY } from "../../ducks/Global/constants";
import ProgressW from '../../assets/images/progressW.svg';
import ProgressB from '../../assets/images/progressB.svg';
import HomeW from '../../assets/images/homeW.svg';
import HomeB from '../../assets/images/homeB.svg';
import MacrosW from '../../assets/images/macrosW.svg';
import MacrosB from '../../assets/images/macrosB.svg';
import ClientsW from '../../assets/images/userW.svg';
import ClientsB from '../../assets/images/userB.svg';
import TemplatesW from '../../assets/images/templates-w.svg';
import TemplatesB from '../../assets/images/templates-b.svg';
import LearnB from '../../assets/images/bookW.svg';
import LearnW from '../../assets/images/bookB.svg';
import Crown from '../../assets/images/crown1.svg';
import FoodB from '../../assets/images/food-black.svg';
import FoodW from '../../assets/images/food-white.svg';
import WorkoutB from '../../assets/images/workout-b.svg';
import WorkoutW from '../../assets/images/workout-w.svg';
import { IoNutritionOutline } from "react-icons/io5";


const { Sider } = Layout;

class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      currentLink: "",
    };
  }

  componentDidMount() {
    this.setState({ sidebarLinks: this.renderLinks() });
  }

  componentWillReceiveProps(nextProps) {
    //Gets current sidebar page
    let isAdminPage = nextProps.location.pathname.split("/")[2] === "admin";
    setTimeout(() => {
      let currentPage =
        this.props.auth &&
        this.renderLinks().find(
          (link) =>
            nextProps.location.pathname.split("/")[`${isAdminPage ? 3 : 2}`] ===
            link.name.toLowerCase()
        );
      //Sets name and menu items to store
      currentPage &&
        this.props.setCurrentPage({
          name: currentPage.name,
          subMenuItems: currentPage.subMenuItems,
        });
    }, 100);
  }

  renderLinks = () => {
    if (
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user.role === "admin"
    ) {
      return [
        // {
        //   name: "Coach",
        //   icon: (
        //     <RiVipCrownLine style={{ color: "#fba120", fontSize: "16px" }} />
        //   ),
        //   path: "/dashboard/coach/meal-plan",
        //   subMenuItems: [
        //     { name: "Meal Plan", path: "/dashboard/coach/meal-plan" },
        //     // {name: 'Workout Plan', path: '/dashboard/smart-coach/workout'},
        //     { name: "Shopping List", path: "/dashboard/coach/shopping-list" },
        //     {
        //       name: "Food Preferences",
        //       path: "/dashboard/coach/food-preferences",
        //     },
        //   ],
        // },
        {
          name: "Home",
          icon: {white: HomeW, black: HomeB},
          path: "/dashboard/home",
          subMenuItems: [],
        },
        {
          name: "Nutrition",
          icon: {white: FoodW, black: FoodB },
          path: "/dashboard/nutrition/meal-plan",
          subMenuItems: [
            { name: "Meal Plan", path: "/dashboard/nutrition/meal-plan" },
            {name: 'Recipes', path: '/dashboard/nutrition/recipes'},
            { name: "Shopping List", path: "/dashboard/nutrition/shopping-list" },
            { name: "Preferences", path: "/dashboard/nutrition/food-preferences" }
          ].filter(item => item !== null)
          // style: !this.props.isProgramVisible && (this.props.profile && !this.props.profile.isCustomMealExist) ? {display: 'none'} : null
        },
        {
          name: "Workout",
          icon: {white: WorkoutW, black: WorkoutB},
          path: "/dashboard/workout/workout-plans",
          subMenuItems: [
            { name: "Workout Plan", path: "/dashboard/workout/workout-plans" },          
            { name: "Workout Program", path: "/dashboard/workout/workout-program" },
            { name: "Workouts", path: "/dashboard/workout/workouts" },
          ].filter(item => item !== null)
          // style: !this.props.isProgramVisible && (this.props.profile && !this.props.profile.isCustomMealExist) ? {display: 'none'} : null
        },
        {
          name: "Clients",
          icon: {white: ClientsW, black: ClientsB},
          path: "/dashboard/admin/clients",
          subMenuItems: [],
        },
        {
          name: "Library",
          icon: {white: TemplatesW, black: TemplatesB},
          path: "/dashboard/admin/library/workouts",
          subMenuItems: [
            { name: "Workouts", path: "/dashboard/admin/library/workouts" },
            { name: "Workout Plans", path: "/dashboard/admin/library/workout-plans" },
            { name: "Workout Programs", path: "/dashboard/admin/library/workout-program" },
            { name: "Exercises", path: "/dashboard/admin/library/exercises" },
            { name: "Meal Plans", path: "/dashboard/admin/library/meal-plan" },
            {
              name: "Drafts",
              path: "/dashboard/admin/library/meal-plan?drafts=true",
            },
            // {name: 'Workouts', path: '/dashboard/library/workout'}
          ],
        },
        {
          name: "Tools",
          icon: {white: MacrosW, black: MacrosB},
          path: "/dashboard/tools/macro-calculator",
          subMenuItems: [
            { name: "Macro Calculator", path: "/dashboard/tools/macro-calculator" },
            { name: "One Rep Max", path: "/dashboard/tools/one-rep-max" },
            { name: "Carb Cycling", path: "/dashboard/tools/carb-cycling" },
            { name: "Macro History", path: "/dashboard/tools/macro-history" },
            { name: "Macro Compliance", path: "/dashboard/tools/compliance" },
          ],
        },
        {
          name: 'Learn', 
          icon: {white: LearnW, black: LearnB},
          path: `/dashboard/learn/nutrition`,
            subMenuItems: [
              {name: 'Nutrition', path: '/dashboard/learn/nutrition'},
            // {name: 'Diets', path: '/dashboard/guides/diet'},
            // {name: 'Eating Methods', path: '/dashboard/guides/eating-methods'}
            ]
        },
        {
          name: "Stats",
          icon: {white: ProgressW, black: ProgressB},
          path: "/dashboard/stats/progress",
          subMenuItems: [
            { name: "Progress", path: "/dashboard/stats/progress" },
            { name: "Exercise History", path: "/dashboard/stats/exercise-history" }
          ],
        },
        {
          name: "Settings",
          // icon: <AiOutlineSetting style={{fontSize: '16px'}}/>,
          path: "/dashboard/settings/account",
          subMenuItems: [
            { name: "Account", path: "/dashboard/settings/account" },
            { name: "Integrations", path: "/dashboard/settings/integrations" },
            this.props.auth && this.props.auth.user && !this.props.auth.user.userType === 'fixed' ?
            { name: "Billing", path: "/dashboard/settings/billing" } : null
          ].filter(item => item !== null),
          style: { marginTop: "auto" },
        },
      ];
    } else {
      return [
        // {
        //   name: "Coach",
        //   icon: (
        //     <RiVipCrownLine style={{ color: "#fba120", fontSize: "16px" }} />
        //   ),
        //   path: "/dashboard/coach/meal-plan",
        //   subMenuItems: [
        //     { name: "Meal Plan", path: "/dashboard/coach/meal-plan" },
        //     { name: "Shopping List", path: "/dashboard/coach/shopping-list" },
        //     // {name: 'Workout Plan', path: '/dashboard/smart-coach/workout'},
        //     {
        //       name: "Food Preferences",
        //       path: "/dashboard/coach/food-preferences",
        //     },
        //   ],
        // },
        {
          name: "Home",
          icon:  {white: HomeW, black: HomeB},
          path: "/dashboard/home",
          subMenuItems: [],
        },
        {
          name: "Nutrition",
          icon: {white: FoodW, black: FoodB},
          path: "/dashboard/nutrition/meal-plan",
          subMenuItems: [
            { name: "Meal Plan", path: "/dashboard/nutrition/meal-plan" },
            { name: 'Recipes', path: '/dashboard/nutrition/recipes'},
            { name: "Shopping List", path: "/dashboard/nutrition/shopping-list" },
            { name: "Food Preferences", path: "/dashboard/nutrition/food-preferences" }
          ].filter(item => item !== null),
          // style: this.props.profile && !this.props.profile.isCustomMealExist ? {display: 'none'} : null
        },
        {
          name: "Workout",
          icon: {white: WorkoutW, black: WorkoutB},
          path: "/dashboard/workout/workout-plans",
          subMenuItems: [
            { name: "Workout Plan", path: "/dashboard/workout/workout-plans" },
            this.props.auth && this.props.auth.user && this.props.auth.user.createdBy === "admin" ?
            { name: "Workout Program", path: "/dashboard/workout/workout-program" } : null,
            { name: "Workouts", path: "/dashboard/workout/workouts" }
          ].filter(item => item !== null),
          
          
          // style: !this.props.isProgramVisible && (this.props.profile && !this.props.profile.isCustomMealExist) ? {display: 'none'} : null
        },
        {
          name: "Tools",
          icon: {white: MacrosW, black: MacrosB},
          path: "/dashboard/tools/macro-calculator",
          subMenuItems: [
            { name: "Macro Calculator", path: "/dashboard/tools/macro-calculator" },
            { name: "One Rep Max", path: "/dashboard/tools/one-rep-max" },
            { name: "Carb Cycling", path: "/dashboard/tools/carb-cycling" },
            { name: "Macro History", path: "/dashboard/tools/macro-history" },
            { name: "Macro Compliance", path: "/dashboard/tools/compliance" },
          ],
        },
        {
          name: 'Learn', 
          icon: {white: LearnW, black: LearnB},
          path: `/dashboard/learn/nutrition`,
            subMenuItems: [
              {name: 'Nutrition', path: '/dashboard/learn/nutrition'},
            // {name: 'Diets', path: '/dashboard/guides/diet'},
            // {name: 'Eating Methods', path: '/dashboard/guides/eating-methods'}
            ]
        },
        // {name: 'Workouts', icon: <AiOutlineFire style={{fontSize: '16px'}}/>, path: '/dashboard/workouts', subMenuItems: []},
        // {name: 'Lifestyle', icon: <GiBerriesBowl style={{fontSize: '16px'}}/>, path: '/dashboard/lifestyle', subMenuItems: []},
        {
          name: "Stats",
          icon: {white: ProgressW, black: ProgressB},
          path: "/dashboard/stats/progress",
          subMenuItems: [
            { name: "Progress", path: "/dashboard/stats/progress" },
            { name: "Exercise History", path: "/dashboard/stats/exercise-history" }
          ],
        },
        {
          name: "Settings",
          path: "/dashboard/settings/account",
          subMenuItems: [
            { name: "Account", path: "/dashboard/settings/account" },
            { name: "Integrations", path: "/dashboard/settings/integrations" },
            this.props.auth && this.props.auth.user && !this.props.auth.user.userType ?
            { name: "Billing", path: "/dashboard/settings/billing" } : null
          ].filter(item => item !== null),
          style: { marginTop: "auto" },
        },
      ];
    }
  };

  closeSideBar = () => {
    window.innerWidth <= 768 && this.props.handleSideBar(true);
  };

  renderMenuLinkClassName = (link) => {
    let isAdminPage = this.props.location.pathname.split("/")[2] === "admin";
    if (
      (!isAdminPage &&
        this.props.location.pathname.split("/")[2] ===
          link.name.toLowerCase()) ||
      (isAdminPage &&
        this.props.location.pathname.split("/")[3] === link.name.toLowerCase())
    ) {
      return "menu-link sidebar-active";
    } else {
      return "menu-link";
    }
  };

  getImageSource = (link) => {
    let isAdminPage = this.props.location.pathname.split("/")[2] === "admin";
    if (
      (!isAdminPage &&
        this.props.location.pathname.split("/")[2] ===
          link.name.toLowerCase()) ||
      (isAdminPage &&
        this.props.location.pathname.split("/")[3] === link.name.toLowerCase())
    ) {
      return link.icon.white;
    } else {
      return  link.icon.black;
    }

  };

  renderSidebarLinks = () => {
    return this.renderLinks().map((link) => {
      if (link.icon) {
        return (
          <>
            <Link
              key={link.path}
              className={this.renderMenuLinkClassName(link)}
              to={{ pathname: link.path }}
              onClick={() => {
                this.setState({ currentLink: link.name.toLowerCase() });
                this.closeSideBar();
              }}
              style={link.style}
            >
              <div className='flex flex-center-aligned'>
                <img className='icon-img' src={this.getImageSource(link)} />
                <p>{link.name}</p>
              </div>
            </Link>
            {/* {link.menuItems && link.menuItems.length > 0 && (
              <div className='sub-menu'>
                {link.menuItems.map((menuItem) => (
                  <Link
                    className='side-bar-sub-items'
                    key={menuItem.path}
                    to={{ pathname: menuItem.path }}
                    onClick={() => {
                      this.setState({ currentLink: menuItem.name.toLowerCase() });
                      this.closeSideBar();
                    }}
                  >
                    {menuItem.name}
                  </Link>
                ))}
              </div>
            )} */}
          </>);
      } else if (link.path === "/dashboard/settings/account") {
        return null;  
      }
    });
  };

  render() {
    return (
      this.props.auth.isAuthenticated &&
      this.props.history.location.pathname.split("/").includes("dashboard") &&
      this.props.auth.user && (
        <Sider
          breakpoint="lg"
          width="250px"
          collapsible
          collapsedWidth={0}
          collapsed={this.props.collapsed}
          onCollapse={() => this.props.handleSideBar(!this.props.collapsed)}
        >
          <div className="sidebar">
            {/* <Link to="/"> */}
              <div  onClick={() => window.open("https://app.getnubod.com","_self")} className="logo">
                <img src={Nubod} />
              </div>
            {/* </Link> */}
            <div className="menu-items">{this.renderSidebarLinks()}</div>
          </div>
        </Sider>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    collapsed: state.global.collapsed,
    isProgramVisible: state.global.isProgramVisible,
    profile: state.profile.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    handleSideBar: (collapsed) => {
      return dispatch({
        type: HANDLE_SIDEBAR,
        collapsed,
      });
    },
    setProgramVisibility: () => dispatch({
      type: SET_PROGRAM_VISIBILITY,
      payload: true
    })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideBar));
