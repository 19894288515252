import { put, takeLatest } from 'redux-saga/effects';
import { SEND_RESULTS_TO_EMAIL, SAVE_RESULTS_TO_GOOGLE_SHEET } from './constants';
import { sendResultsToEmailSuccess, sendResultsToEmailError } from './actions';

export function* sendResultsToEmail({macros, goalCalorie, responses, email}){
    const templateParams = {
      'protein': macros[0].protein.grams,
      'carbs': macros[1].carbs.grams,
      'fats': macros[2].fats.grams,
      'email': email,
      'goalCalorie': goalCalorie,
      'goal': responses.currentShape.goal,
      'bodyType': responses.bodyType.key,
      'proteinPercentage': responses.bodyType.protein,
      'carbPercentage': responses.bodyType.carbs,
      'fatPercentage': responses.bodyType.fats,
      'goalWeight': responses.currentShape.type,
      'weight': responses.currentShape.weight
    }

    window.emailjs.send('gmail','results', templateParams)
    .then((response) => {
       sendResultsToEmailSuccess(response);
    }, (err) => {
       sendResultsToEmailError(err);
    });
}

export function* saveResultsToGoogleSheet ({nutrition, responseObj, unit}) {
  try {
    yield fetch(`https://hooks.zapier.com/hooks/catch/${process.env.REACT_APP_ZAPIER_SAVE_RESULTS}/ofz207e`, {
    method: 'POST',
      body: JSON.stringify({
        nutrition: nutrition[0],
        responseObj: responseObj,
        unit: unit
      })
    })
  } catch (err) {
    console.log(err)
  }
}

export default function* assessmentSaga() {
  yield takeLatest(SEND_RESULTS_TO_EMAIL, sendResultsToEmail);
  yield takeLatest(SAVE_RESULTS_TO_GOOGLE_SHEET, saveResultsToGoogleSheet);
}