import {
  GET_WORKOUT_PLAN_LIBRARY,
  GET_WORKOUT_PLAN_LIBRARY_SUCCESS,
  GET_WORKOUT_PLAN_LIBRARY_ERROR,
  GET_USER_WORKOUT_PLANS,
  GET_USER_WORKOUT_PLANS_SUCCESS,
  GET_USER_WORKOUT_PLANS_ERROR
} from "./constants";

const initialState = {
  workoutPlans: [],
  userWorkoutPlans: [],
  workoutPlansLoading: false,
  workoutPlansError: null,
  createWorkoutPlanSuccess: false
};

function workoutPlan(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_WORKOUT_PLAN_LIBRARY:
      return {
        ...state,
        workoutPlansError: false,
        workoutPlansLoading: true
      };
    case GET_WORKOUT_PLAN_LIBRARY_SUCCESS:
      return {
        ...state,
        workoutPlans: payload,
        workoutPlansLoading: false
      };
    case GET_WORKOUT_PLAN_LIBRARY_ERROR:
      return {
        ...state,
        workoutPlansLoading: false,
        workoutPlansError: action.err
      };
    case GET_USER_WORKOUT_PLANS:
      return {
        ...state,
        workoutPlansError: false,
        workoutPlansLoading: true
      };
    case GET_USER_WORKOUT_PLANS_SUCCESS:
      return {
        ...state,
        userWorkoutPlans: payload,
        workoutPlansLoading: false
      };
    case GET_USER_WORKOUT_PLANS_ERROR:
      return {
        ...state,
        workoutPlansLoading: false,
        workoutPlansError: action.err
      };
    default:
      return state;
  }
}

export default workoutPlan;