import React from 'react';
import { splashPage } from '../../data/splashPage';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { UpgradeButton } from '../../components/UpgradeButton';
import { PricingModal } from '../../components/PricingModal';
import './index.css';

export const SplashPage = ({
  page,
  style, 
  layoutBackground, 
  handlePricingModal, 
  pricingModalOpen, 
  profile 
}) => 
  <>
    <PricingModal 
      handlePricingModal={handlePricingModal} 
      pricingModalOpen={pricingModalOpen} 
      profile={profile}
    /> 
    <div style={style} className={`splash-page ${layoutBackground ? 'site-layout-background' : ''}`}>
      <img className='img' src={splashPage[page].img}/>
      <div className='splash-page-content'>
        <div className='title-section'>
          <div className='title flex flex-center-aligned'>
            <h1 className='main-page-title'>{splashPage[page].title}</h1>
            {splashPage[page].pro && <div className='pro-label'>PRO</div>}
          </div>
          <p className='main-page-subtitle'>{splashPage[page].description}</p>
        </div>
        {/* <p className='home-card-title'>Benefits</p> */}
        <div className='benefits'>
        {splashPage[page].list && splashPage[page].list.map(item => 
          <div key={item.title} className='flex flex-center-aligned benefits-card'>
            <IoMdCheckmarkCircle style={{fontSize: '30px', color: '#34f5c5'}} />
            <p>{item}</p>
          </div>)}
        </div>
        {splashPage[page].benefits && <p className='home-card-title'>Includes</p>}
        <div className='list'>
          {splashPage[page].benefits && splashPage[page].benefits.map(benefit => 
          <div className='list-item'>
            <FaStar style={{color: 'rgb(255, 178, 41)', marginRight: '5px'}}/>
            <p>{benefit}</p>
          </div>)}
        </div>
        {/* <UpgradeButton action={handlePricingModal}/> */}
      </div>
    </div>
  </>