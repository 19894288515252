import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_NUTRITION_PREFERENCES,
  UPDATE_NUTRITION_PREFERENCES
} from "./constants";
import {
  updateNutritionPreferencesSuccess,
  updateNutritionPreferencesError,
  getNutritionPreferencesSuccess,
  getNutritionPreferencesError
} from "./actions";
import { fetchData } from "../api";

export function* getNutritionPreferences(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/nutrition-preference/me`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    let nutritionPreferences = yield call(fetchData, url, config);
    yield put(getNutritionPreferencesSuccess(nutritionPreferences));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getNutritionPreferencesError(error));
  }
}

export function* updateNutritionPreferences({restrictionsArr}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/nutrition-preference`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(restrictionsArr)
    }
    const restrictions = yield call(fetchData, url, config);
    yield put(updateNutritionPreferencesSuccess(restrictions)); 

  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(updateNutritionPreferencesError(error));
  }
}

export default function* nutritionPreferenceSaga() {
  yield takeLatest(UPDATE_NUTRITION_PREFERENCES, updateNutritionPreferences);
  yield takeLatest(GET_NUTRITION_PREFERENCES, getNutritionPreferences);
}