import {
  CREATE_OR_UPDATE_TODO,
  CREATE_OR_UPDATE_TODO_SUCCESS,
  CREATE_OR_UPDATE_TODO_ERROR,
  GET_TODO,
  GET_TODO_SUCCESS,
  GET_TODO_ERROR
} from "./constants";

export function createOrUpdateTodo(todo) {
  return {
    type: CREATE_OR_UPDATE_TODO,
    todo
  };
}

export function createOrUpdateTodoSuccess(payload) {
  return {
    type: CREATE_OR_UPDATE_TODO_SUCCESS,
    payload
  };
}

export function createOrUpdateTodoError(error) {
  return {
    type: CREATE_OR_UPDATE_TODO_ERROR,
    error
  };
}

export function getTodo() {
  return {
    type: GET_TODO
  };
}

export function getTodoSuccess(payload) {
  return {
    type: GET_TODO_SUCCESS,
    payload
  };
}

export function getTodoError(error) {
  return {
    type: GET_TODO_ERROR,
    error
  };
}