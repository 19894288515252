import React from 'react';
import { Link } from 'react-router-dom';
// import { IoMdCheckmarkCircle } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import './index.scss';
import { CloseOutlined } from '@ant-design/icons';
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { toast } from 'react-toastify';
import Axios from 'axios';
import { getTodo } from '../../ducks/Todo/actions';
import { connect } from 'react-redux';

class ToDo extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showClose: false,
    }
  }

  componentDidMount() {
    this.checkCompleted();
  }

  checkCompleted = async() => {
    let totalCompleted = [];
    this.props.todo.todo.map((todo) => {
      if(todo.completed) 
        totalCompleted.push(todo.key);
    });
    this.setState({
      showClose: totalCompleted.length === this.props.todo.todo.length,
    });
  }

  handleCloseTodoSectionClick = () => {
    try{
      const urlForUpdate = `${process.env.REACT_APP_API_URL}/api/todo/completeAll`;
      const headers = {
        headers: {
          'x-auth-token': localStorage.token
        }
      };
      Axios .get(urlForUpdate,headers)
      .then( (response) => {
        this.props.getTodo();
        toast.success("Todo has been completed.");
      })
      .catch(function (error) {
        console.log(error);
      });
    }catch(e){
      console.log(e);
    }
  } 

  renderToDoList = () => {
    return(
      <div>
        {
          this.state.showClose ?  
          <div style={{ float: 'right' }}> 
            <CloseOutlined 
              onClick={this.handleCloseTodoSectionClick} 
              title="Close" 
            /> 
          </div> : null 
        }
        {this.props.todo && this.props.todo.todo.map(toDo => {
        return(
          <div key={toDo.key} className='step todo-list'>
            {toDo.completed ? 
            <CheckCircleFilled style={{color: '#2bffcc', fontSize: '24px'}}/> :
            <CheckCircleOutlined style={{color: 'rgb(240, 242, 245)', fontSize: '24px'}}/>
            }
            <div className='list-text flex flex-center-aligned'>
              <p className='text'>{toDo.text}</p>
              {!toDo.completed && 
              <Link to={toDo.link} onClick={toDo.link === "/dashboard/home/add-measurement" ? this.props.openDrawer : null}>
                <div className='flex flex-center-aligned blue-link'>
                  <p>Go</p>
                  <BsArrowRight/>
                </div>
              </Link>}
            </div>
          </div> )
        })}
      </div>
    )
  }

  render(){
    return(
      <div className='mb-4'>
        <p className='home-card-title'>To do</p>
        <div className='diet-card'>
          {this.renderToDoList()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    todo: state.todo.todo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTodo: () => dispatch(getTodo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToDo);