import React from 'react';
import { connect } from 'react-redux';
import { updateMealplanById } from '../../ducks/Admin/actions';
import './index.css';
import moment from 'moment';
import { DatePicker, Select, Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getRandomizeFood} from "../../helpers/utils";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SaveMealPlanModal extends React.Component {
  constructor(){
    super();
    this.state = {
      foodPreference: 'Anything',
      foodPreferenceOptions: [
        'Anything',
        'Keto',
        'Paleo',
        'Vegetarian',
        'Vegan'
      ],
      dateStart: '',
      dateEnd: '',
      mealplan: {},
      mealplanEmpty: true
    }
  }

  setNewMealPlanRange = (date) => {
    // date.mealPlanStart = moment(new Date(date.mealPlanStart)).format('YYYY-MM-DD')
    // date.mealPlanEnd = moment(new Date(date.mealPlanEnd)).format('YYYY-MM-DD')

    // let mealPlanStartIndex = arrayPlan.findIndex(i => i[0] === date.mealPlanStart)
    // if (mealPlanStartIndex === -1) {
    //   const firstPlanDate = arrayPlan[0][0];
    //   mealPlanStartIndex = new Date(date.mealPlanStart) < new Date(firstPlanDate) ? 0 : arrayPlan.length
    // }
    // let mealPlanEndIndex = arrayPlan.findIndex(i => i[0] === date.mealPlanEnd);
    //
    // debugger
    // if (mealPlanEndIndex === -1) {
    //   const firstPlanDate = arrayPlan[0][0]
    //   mealPlanEndIndex = new Date(date.mealPlanEnd) < new Date(firstPlanDate) ? 0 : arrayPlan.length
    // }

    // const mealplan = Object.fromEntries(arrayPlan.slice(mealPlanStartIndex, mealPlanEndIndex + 1));

    const arrayPlan = Object.entries(this.props.plan);

    let start = new Date(date.mealPlanStart);
    let end = new Date(date.mealPlanEnd);
    const mealplan = {};
    while(start <= end){
      const index = moment(start).format('YYYY-MM-DD');
      mealplan[index] = [];
      let newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);
    }

    mealplan[moment(new Date(date.mealPlanStart)).format('YYYY-MM-DD')] = arrayPlan[0][1];

    if (Object.keys(mealplan).length) {
      this.setState({
        mealplan,
        mealplanEmpty: false
      })
    } else {
      this.setState({
        mealplanEmpty: true
      })
    }
  }

  save = (type) => {
    try {
      const { mealplan } = this.state;
      const newPlan = getRandomizeFood(mealplan);
      this.setState({mealplan: newPlan}, async () => {
        const createFromTemplate = window.location.href.includes('mode=create')
        if (this.props.template && !createFromTemplate) {
          await this.props.updateMealplanById(this.props.fullMealplan._id, {
            preference: this.state.foodPreference,
            draft: type === 'draft',
            target: this.props.target,
            mealplan: newPlan,
            notes: this.props.notes
          })
        } else {
          await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/template`, {
            method: 'POST',
            body: JSON.stringify({
              preference: this.state.foodPreference,
              draft: type === 'draft',
              target: this.props.target,
              mealplan: newPlan,
              notes: this.props.notes
            }),
            headers: {
              'content-type': 'application/json',
              'x-auth-token': localStorage.token
            }
          })
          if (type === 'assign' && !createFromTemplate) {
            if (
              + this.props.target.proteinPercent +
                + this.props.target.carbPercent +
                + this.props.target.fatsPercent !==
              100
            ) {
              return toast.error("Sum of protein / carb / fats percent must be 100%");
            }
            await this.props.assign(newPlan)
          } else {
            toast.success(`Meal plan template has been successfully ${this.props.template && !createFromTemplate ? 'edited' : 'created'}`)
          }
        }
        if(this.props.template && !createFromTemplate) {
          toast.success(`Meal plan template has been successfully ${this.props.template && !createFromTemplate ? 'edited' : 'created'}`)
        }
        this.props.toggleSaveMealPlanModal(false);
        if(this.props.user) {
          this.props.history.push(`/dashboard/admin/clients/${this.props.user._id}/edit-meal-plan`)
        }
      })
    } catch (err) {
      toast.error(err)
    }
  }

  render(){
    return (
      <Modal
        title={this.props.redirect ? 'Create New Meal Plan' : 'Save Meal Plan'}
        visible={this.props.visible}
        onCancel={() => this.props.toggleSaveMealPlanModal(false)}
        footer={null}
        centered={true}
        className="custom-food-modal"
      >
        <div className='modal'>
          <p className='label'>
            Food preference
          </p>
          <Select
            className="w-100"
            value={this.state.foodPreference}
            onChange={(preference) => this.setState({ foodPreference: preference })}
          >
            {this.state.foodPreferenceOptions.map((preference) => (
              <Option key={preference} value={preference}>
                {preference}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mt-4">
          <RangePicker format={'MMM DD, YYYY'} onChange={(date, dateString) => {
            this.setState({
              dateStart: dateString[0],
              dateEnd: dateString[1]
            })
            if (!this.props.redirect) {
              this.setNewMealPlanRange({
                mealPlanStart: dateString[0],
                mealPlanEnd: dateString[1]
              })
            }
          }} />
        </div>
        <div className="week-buttons text-right">
          {!this.props.redirect && (
            <Button
              type="primary"
              className="save-btn-modal"
              disabled={this.state.mealplanEmpty}
              onClick={() => this.save('draft')}
            >
              Save as draft
            </Button>
          )}
          {this.props.redirect ? (
            <Button
              type="primary"
              className="save-btn-modal"
              disabled={!this.state.dateStart && !this.state.dateEnd}
              onClick={() => this.props.history.push(`/dashboard/admin/library/meal-plan/new?mode=create&preference=${this.state.foodPreference}&startDate=${moment(this.state.dateStart).format('YYYY-MM-DD')}&endDate=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`)}
            >
              Create
            </Button>
          ) : (
            <Button
              type="primary"
              className="save-btn-modal"
              disabled={this.state.mealplanEmpty}
              onClick={() => this.save('assign')}
            >
              Save {!this.props.template && ('and assign')}
            </Button>
          )}
          <Button type="secondary" className="save-btn-modal" onClick={() => this.props.toggleSaveMealPlanModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateMealplanById: (id, plan) => dispatch(updateMealplanById(id, plan))
  }
}

export default connect(null, mapDispatchToProps)(SaveMealPlanModal);
