import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { starRating } from '../../helpers/utils';
import { BsFillChatQuoteFill } from "react-icons/bs";
import './index.css';

export const ReviewModal = ({reviewModalOpen, openReviewModal, colorBasedOnGender, review}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      className='landing-page review-card'
      visible={reviewModalOpen}
      footer={null}
      closable={false}
      centered
      onCancel={() => dispatch(openReviewModal(false, null))}
    >
      <div style={{textAlign: 'center'}} key={review && review.author_name}>
        <BsFillChatQuoteFill className='mb-3' style={{fontSize: '50px', color: colorBasedOnGender}}/>
        <div className='mb-3'>
          <p className='mb-2'>{starRating(review && review.rating)}</p>
          <p className='subtitle mb-1' style={{lineHeight: '28px'}}>{review && review.text}</p>
        </div>
        <p className='title' style={{fontSize: '18px', fontWeight: 900, color: 'black'}}>{review && review.author_name}</p>
      </div>
    </Modal>
  ) 
}