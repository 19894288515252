import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';

class TablePage extends Component {
    shouldComponentUpdate(nextProps) {
      return this.props.location.pathname === nextProps.location.pathname;
    }

    tableRowClick = (name, record) => {
      if (name === "clients") {
        this.props.history.push({
          pathname: `/dashboard/admin/clients/${record.user._id}/profile/macros`,
          state: { userId: record.user._id }
        })
      };
      return null;
    };

    render() {

      const tableLoading = {
        spinning: this.props.tableDataLoading,
        indicator: <LoadingOutlined />,
      }

        return (
          <Fragment>
            <div>
              {this.props.tableHeader}
              <Table
                rowKey={record => record._id}
                className='table-clear-row-hover'
                scroll={this.props.scroll}
                loading={tableLoading}
                dataSource={this.props.tableData}
                columns={this.props.columns}
                expandedRowRender={false}
                pagination={{ pageSize: 25 }}
              //   onRow={(record) => {
              //     return {
              //         onClick: () => this.tableRowClick(this.props.tableName, record),
              //     };
              // }}
              />
            </div>
          </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {

    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TablePage))