import React from 'react';
import { connect } from 'react-redux';
import { getAllBlogs } from '../../ducks/Blog/actions';
import { BlogCard } from '../../components/BlogCard';
import BlogSideBar from '../../components/BlogSideBar';
import './index.css';

class Blog extends React.Component {
  componentDidMount(){
    if(!this.props.allBlogs){
      this.props.getAllBlogs();
    }
  }

  render(){
    return(
      <div className="content blog-page">
        <div className="blog-grid">
          {this.props.allBlogs && this.props.allBlogs.posts.map(blog => <BlogCard blog={blog} key={blog.id}/>)}
        </div>
        <BlogSideBar {...this.props}/>
      </div>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    allBlogs: state.blog.allBlogs,
    filteredBlogs: state.blog.filteredBlogs,
    subscribeStatus: state.blog.subscribeStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllBlogs: () => dispatch(getAllBlogs())
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Blog);