import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BsFillPlayCircleFill, BsFillPauseCircleFill, BsFillStopCircleFill } from 'react-icons/bs';
import { TOGGLE_WORKOUT_TIMER_RUNNING, TOGGLE_CONFIRM_MODAL, SET_WORKOUT_IN_PROGRESS } from '../../ducks/Global/constants';

export const WorkoutTimer = ({
  toggleConfirmModal, 
  timerRunning, 
  toggleWorkoutTimerRunning, 
  stopWorkout, 
  setWorkoutToState, 
  shouldResetTimer, 
  onTimerReset, 
  updateTime
}) => {
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (timerRunning) {
      const id = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime + 1;
          updateTime(newTime);
          return newTime;
        });
      }, 1000);
      setIntervalId(id);
      setShowButtons(true);
    } else {
      clearInterval(intervalId);
    }
  }, [timerRunning, updateTime]);
  
  useEffect(() => {
    if (shouldResetTimer) {
      setTime(0);
      setShowButtons(false);
      onTimerReset(false);
    }
  }, [shouldResetTimer, onTimerReset]);

  const startTimer = () => {
    if (!timerRunning) {
      toggleWorkoutTimerRunning(true);
    }
  };

  const pauseTimer = () => {
    toggleWorkoutTimerRunning(!timerRunning);
    setIsPaused(true);
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className='grid grid-justify-center'>
      <div>
        {!timerRunning && !showButtons && (
          <BsFillPlayCircleFill
            style={{ fontSize: '35px', cursor: 'pointer', color: '#2bffcc' }}
            onClick={() => toggleConfirmModal(true)}
          />
        )}
        {showButtons && (
          <>
            {timerRunning ? (
              <BsFillPauseCircleFill
                style={{ fontSize: '35px', cursor: 'pointer' }}
                onClick={pauseTimer}
              />
            ) : (
              <BsFillPlayCircleFill
                style={{ fontSize: '35px', cursor: 'pointer', color: '#2bffcc' }}
                onClick={startTimer}
              />
            )}
            {(time > 0 || isPaused) && (
              <BsFillStopCircleFill
                style={{ fontSize: '35px', cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  stopWorkout(true);
                  toggleConfirmModal(true);
                  setWorkoutToState();
                }}
              />
            )}
          </>
        )}
      </div>
      {/* {time > 0 && <div>{formatTime(time)}</div>} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    timerRunning: state.global.timerRunning,
    workoutInProgress: state.global.workoutInProgress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleWorkoutTimerRunning: (value) => dispatch({
      type: TOGGLE_WORKOUT_TIMER_RUNNING,
      value
    }),
    toggleConfirmModal: (value) => dispatch({
      type: TOGGLE_CONFIRM_MODAL,
      value
    }),
    setWorkoutInProgress: (value) => dispatch({
      type: SET_WORKOUT_IN_PROGRESS,
      value
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutTimer);