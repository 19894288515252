import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import './index.css';


class SubMenu extends React.Component {

  isActiveLink = (linkPath) => {
    const { location } = this.props;
    return location.pathname.startsWith(linkPath);
  }

  render() {
    const { currentPage } = this.props;
    const subMenuItems = currentPage ? currentPage.subMenuItems : [];
    return subMenuItems.length > 0 ? (
      <div className="profile-menu">
        {subMenuItems.map((link) => (
          // Check for other conditions before rendering the submenu items
          !link.condition || (link.condition && this.isActiveLink(link.path)) ? (
            <Link
              key={link.path}
              to={link.path}
              className={`menu-link ${this.isActiveLink(link.path) ? 'active' : ''}`}
            >
              {link.icon}
              <p>{link.name}</p>
            </Link>
          ) : null
        ))}
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    currentPage: state.global.currentPage,
    user: state.auth.user,
    profile: state.profile.profile
  }
}

export default connect(mapStateToProps, null)(withRouter(SubMenu));