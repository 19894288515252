import React from 'react';
import moment from 'moment';
import './index.css';

class StatCard extends React.Component {
  
  render(){
    const { 
      data, 
      renderWeight,
      renderBf,
      renderLbm,
      renderAction
    } = this.props;
    return(
      <>        
        <div className='home-card'>
          <p className='home-card-text mb-3'>{moment(data.date).format('ll')}</p>
          <div className='flex flex-center-aligned space-between'>
            <div>
              <p className='blog-card-category mb-1'>Weight</p>
              {renderWeight(data.weight)}
            </div>
          
            <div>
              <p className='blog-card-category mb-1'>Body Fat</p>
              {renderBf(data.bfPercentage)}
            </div>
          </div>
          <div style={{paddingTop: '20px'}} className='flex flex-center-aligned space-between'>
            <div>
              <p className='blog-card-category mb-1'>Lean Body Mass</p>
              {renderLbm(null, data)}
            </div>
          
            <div>
              <p className='blog-card-category mb-1'>Pictures</p>
              {renderAction(data.pictures)}
            </div>
            {/* <p className='home-card-text'>{moment(tableData && tableData[0].date).format('ll')}</p> */}
          </div>
        </div>
      </>
    )
  }
}

export default StatCard;