import React from 'react';
import { Menu, Dropdown, Popover } from 'antd';
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline, MdAdd, MdAddCircleOutline, MdOutlineRemoveCircleOutline} from "react-icons/md";
import { AiOutlineUserAdd, AiFillStar, AiOutlineEye } from "react-icons/ai";
import { EllipsisOutlined } from '@ant-design/icons';
import { secondsToDuration } from '../../helpers/utils';
import moment from 'moment';

import './index.css';

export const WorkoutCard = ({
  index,
  workout, 
  setCurrentWorkout, 
  toggleCreateWorkoutModal, 
  deleteLibraryWorkout, 
  deleteWorkoutLoading,
  isUserWorkout,
  setIsWorkoutEdit,
  workoutProgramDisplay,
  showWorkoutPreview,
  isCreateEditView,
  onSelected,
  workoutInProgramColumn,
  workoutInProgress
}) => {

const handleWorkoutItemClick = (action) => {
  if (!isUserWorkout && !workoutProgramDisplay) {
    if(action.key === '1'){
      console.log('assign')
    } else if(action.key === '2'){
      console.log('create new from template')
      toggleCreateWorkoutModal(true);
      setCurrentWorkout(workout);
      setIsWorkoutEdit(false);
    } else if(action.key === '4'){
      toggleCreateWorkoutModal(true);
      setCurrentWorkout(workout);
      setIsWorkoutEdit(true);
    } else if(action.key === '5'){
      deleteLibraryWorkout(workout._id)
    }
  } else if(!isUserWorkout && workoutProgramDisplay){
    if(action.key === '1'){
      showWorkoutPreview(workout)
    } 
  }
}

const getMenuItems = () => 
!workoutProgramDisplay
  ? [
      { key: "1", icon: <AiOutlineUserAdd style={{ marginRight: "5px" }} />, text: "Assign" },
      { key: "2", icon: <MdAdd style={{ marginRight: "5px" }} />, text: "Create new from template" },
      { key: "4", icon: <BiEditAlt style={{ marginRight: "5px" }} />, text: "Edit" },
      workout && !workout.isMasterTemplate ?
      { key: "5", icon: <MdDeleteOutline style={{ marginRight: "5px" }} />, text: "Delete" } : null,
    ].filter(Boolean)
  : [
      { key: "1", icon: <AiOutlineEye style={{ marginRight: "5px" }} />, text: "Preview" }
    ];

  const menu = () => (
    <Menu onClick={(e) => handleWorkoutItemClick(e)}>
      {getMenuItems().map(item => {
        return (
          <Menu.Item key={item.key}>
            <div className='flex flex-center-aligned'>
              {item.icon}
              <p>{item.text}</p>
            </div> 
          </Menu.Item>
      )
      })}
    </Menu>
  );

  return (
    <div 
      key={workout._id}
      style={{cursor: isUserWorkout && 'pointer', position: 'relative'}} 
      className='flex space-between exercise-card flex-center-aligned' 
      onClick={() => isUserWorkout && toggleCreateWorkoutModal(true) && setCurrentWorkout(workout)}
    >
      <div className='flex flex-center-aligned space-between'>
        {workoutProgramDisplay &&
        <div className='superset-code mr-1'>
          <p>{index}</p>
        </div>}
        <div>
          <div className='flex flex-center-aligned'>
            {!workoutProgramDisplay &&
            <p className='card-date' style={{letterSpacing: '0px'}}>{isUserWorkout && 'Completed on'} {moment(workout && workout.date).format("MM/DD/YY")}</p>}
           {workout.duration ? <p className='blog-card-category'>-{secondsToDuration(workout.duration)}</p> : ''}
          </div>
          <p className='home-card-text'>{workout && workout.name} {!isUserWorkout && workout && workout.draft ? '- Draft': null}</p>
          {workout && workout.isMasterTemplate && !isUserWorkout &&
          <Popover content={'Master Template'} trigger="click">
            <AiFillStar style={{position: 'absolute', right: 5, top: 5, color: 'gold', fontSize: '18px'}}/>
          </Popover>}
        </div>
      </div>
      {workoutInProgress && <p className='blue-link'>In Progress...</p>}
      {!isUserWorkout && !isCreateEditView &&
      <Dropdown overlay={menu()} trigger={['click']} className="pointer">
        <EllipsisOutlined style={{fontSize: '24px'}}/>
      </Dropdown>}
      {isCreateEditView && 
      <div className='flex flex-center-aligned'>
        {!workoutInProgramColumn && 
        <AiOutlineEye 
          style={{fontSize: '28px', color: '#d3d3d3', cursor: 'pointer', marginRight: '5px'}}
          onClick={() => showWorkoutPreview(workout)}
        />}
        {workoutInProgramColumn ?
        <MdOutlineRemoveCircleOutline
          style={{fontSize: '28px', color: '#d3d3d3', cursor: 'pointer'}}
          onClick={() => onSelected(workout)}
        /> :
        <MdAddCircleOutline
          style={{fontSize: '28px', color: '#2bffcc', cursor: 'pointer'}}
          onClick={() => onSelected(workout)}
        />}
      </div>}
    </div>
  )}