export const GET_RECIPES = "recipe/GET_RECIPES";
export const GET_RECIPES_SUCCESS = "recipe/GET_RECIPES_SUCCESS";
export const GET_RECIPES_ERROR = "recipe/GET_RECIPES_ERROR";
export const CLEAR_ALERT = "recipe/CLEAR_ALERT";
export const CLEAR_RECIPES = "recipe/CLEAR_RECIPES";

export const SAVE_GROCERY = "recipe/SAVE_GROCERY";
export const SAVE_GROCERY_SUCCESS = "recipe/SAVE_GROCERY_SUCCESS";
export const SAVE_GROCERY_ERROR = "recipe/SAVE_GROCERY_ERROR";

export const GET_GROCERIES = "recipe/GET_GROCERIES";
export const GET_GROCERIES_SUCCESS = "recipe/GET_GROCERIES_SUCCESS";
export const GET_GROCERIES_ERROR = "recipe/GET_GROCERIES_ERROR";
export const CLEAR_GET_GROCERIES_ERROR = "recipe/CLEAR_GET_GROCERIES_ERROR";

export const DELETE_OR_UPDATE_GROCERY = "recipe/DELETE_OR_UPDATE_GROCERY";
export const DELETE_GROCERY_SUCCESS = "recipe/DELETE_GROCERY_SUCCESS";
export const UPDATE_GROCERY_SUCCESS = "recipe/UPDATE_GROCERY_SUCCESS";
export const DELETE_GROCERY_ERROR = "recipe/DELETE_GROCERY_ERROR";

export const HANDLE_RECIPE_SEARCH_TERM = "recipe/HANDLE_RECIPE_SEARCH_TERM";

export const SAVE_RECIPE_TO_FAVORITES = "recipe/SAVE_RECIPE_TO_FAVORITES";
export const SAVE_RECIPE_TO_FAVORITES_SUCCESS = "recipe/SAVE_RECIPE_TO_FAVORITES_SUCCESS";
export const SAVE_RECIPE_TO_FAVORITES_ERROR = "recipe/SAVE_RECIPE_TO_FAVORITES_ERROR";

export const GET_FAVORITE_RECIPES = "recipe/GET_FAVORITE_RECIPES";
export const GET_FAVORITE_RECIPES_SUCCESS = "recipe/GET_FAVORITE_RECIPES_SUCCESS";
export const GET_FAVORITE_RECIPES_ERROR = "recipe/GET_FAVORITE_RECIPES_ERROR";

export const FETCH_FAVORITE_RECIPES_FROM_EDEMAM = 'recipe/FETCH_FAVORITE_RECIPES_FROM_EDEMAM';
export const FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS = 'recipe/FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS';
export const FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR = 'recipe/FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR';