import React from "react";
import { capitalize } from '../../helpers/utils';
import { Progress } from 'antd';

export const MacrosProgress = ({targetInfo}) => {

  const renderStat = (targetInfo, label) => {
    return (
      <div>
        <p style={{ fontWeight: "500", marginBottom: '3px' }}>
          {label === 'calories' ? targetInfo : `${targetInfo} g`}
        </p>
      <p className='blog-card-category macro-label'>{capitalize(label)}</p>
      </div>
    );
  };

  return (
    <>
      <div className='justify-center home-macro-card flex card-content flex-center-aligned'>
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          status="active"
          percent={100}
          format={() => renderStat(targetInfo.calories, 'calories')}
        />
      </div>
      <div className='justify-center home-macro-card flex card-content flex-center-aligned'>
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          status="active"
          percent={targetInfo.proteinPercent}
          format={() => renderStat(targetInfo.protein, 'protein')}
        />
      </div>
      <div className='justify-center home-macro-card flex card-content flex-center-aligned'>
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          status="active"
          percent={targetInfo.carbPercent}
          format={() => renderStat(targetInfo.carb, 'carbs')}
        />
      </div>
      <div className='justify-center home-macro-card flex card-content flex-center-aligned'>
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          status="active"
          percent={targetInfo.fatsPercent}
          format={() => renderStat(targetInfo.fats, 'fats')}
        />
      </div>
    </>
  )
}