import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);

export const getGoogleAuthProvider = () =>
  new firebase.auth.GoogleAuthProvider();
  
export const getFacebookAuthProvider = () =>
  new firebase.auth.FacebookAuthProvider();

export const getAuth = () => firebase.auth();

export const signInWithEmailAndPassword = async (email, password) => {
  await getAuth()
    .signInWithEmailAndPassword(email, password)
    .catch((error) => {
      // ToDo: Handle login errors
      throw error;
    });
};

export const createUserWithEmailAndPassword = async (email, password) =>
  await getAuth().createUserWithEmailAndPassword(email, password);

export const getIdToken = () => {
  if (getAuth().currentUser) {
    setInterval(() => {
      if (getAuth().currentUser) {
        getAuth().currentUser.getIdToken(true).then((token) => {
          console.log('updated token');
          localStorage.token = token
        })
      }
    }, 55 * 60000);
    return getAuth().currentUser.getIdToken(true);
  }
  throw Error("");
};

export const signOut = () => getAuth().signOut();

export const sendPasswordResetEmail = (emailAddress) =>
  getAuth().sendPasswordResetEmail(emailAddress);

export const extractUserName = (displayName, email) => {
  if (displayName) {
    const parts = displayName.split(" ");
    if (parts.length === 1) {
      return { name: displayName, lastName: "-" };
    }
    return {
      name: parts.slice(0, -1).join(" "),
      lastName: parts.pop(),
    };
  }
  // fallback
  return {
    name: email.split("@")[0],
    lastName: "-",
  };
};

export default app;
