import React from 'react';
import { Modal, Button } from 'antd';
import './index.css';

export const ConfirmModal = ({ 
  icon, 
  title, 
  subtitle, 
  action, 
  btn, 
  btnStyle, 
  cancel, 
  isVisible, 
  closable, 
  isMaskCloseable, 
  isEndWorkout, 
  abort
}) => {
  return(
    <Modal
      className='confirm-modal'
      centered
      visible={isVisible}
      closable={closable}
      maskClosable={isMaskCloseable}
      title={
        <div className='flex flex-center-aligned justify-center'>
          {icon}
          <p>{title}</p>
        </div>
      }
      onCancel={() => cancel(false)}
      footer={[
        <div className={isEndWorkout ? 'grid' : 'flex justify-center'}>
          <Button 
            type={isEndWorkout ? 'danger' : 'primary'} 
            style={{
              ...btnStyle,
              marginBottom: isEndWorkout ? '5px' : undefined,
            }}
            onClick={() => action()}
          >
            {btn.confirm}
          </Button>
          {isEndWorkout ?
          <Button 
            type='danger' 
            style={{
              ...btnStyle,
              marginBottom: isEndWorkout ? '5px' : undefined,
              marginLeft: isEndWorkout && 0,
            }}
            onClick={() => abort()}
          > 
            {btn.abort}
          </Button> : null}
          <Button 
            type='ghost' 
            style={{
              ...btnStyle,
              marginLeft: isEndWorkout && 0
            }} 
            onClick={() => cancel(false)}
          >
            {btn.cancel}
          </Button>
        </div>
      ]}
    >
      <div className='flex justify-center'>
        <p>{subtitle}</p>
      </div>
    </Modal>
  )
}