import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyWorkout } from '../../ducks/Workout/actions';
import moment from 'moment';
import { Loading } from '../../components/Loading';
import 'react-loading-skeleton/dist/skeleton.css'
import './index.css';

export const SharedWorkout = ({match}) => {
  const dispatch = useDispatch();
  const { workoutToShare, getWorkoutsLoading } = useSelector(state => state.workouts);
  const { token } = match.params;

  useEffect(() => {
    dispatch(shareMyWorkout(token));
  }, [dispatch, token]);

  const displayExerciseData = (exercise) => {
    const totalSets = exercise.data.length;
    const totalReps = exercise.data.reduce((acc, curr) => {
      return acc + curr.reps;
    }, 0);
    const heaviestLoad = exercise.data.reduce((maxLoad, curr) => {
      return Math.max(maxLoad, curr.load);
    }, 0);
    return (
      <div className='three-column-grid home-card-text'>
        <div>
          <p className='mb-1' style={{fontSize: '16px', fontWeight: '600', fontStyle: 'italic', color: 'black'}}>{totalSets}</p>
          <p style={{fontSize: '12px'}}>Total Sets</p>
        </div>
       <div>
        <p className='mb-1' style={{fontSize: '16px', fontWeight: '600', fontStyle: 'italic', color: 'black'}}>{totalReps}</p>
        <p style={{fontSize: '12px'}}>Total Reps</p>
       </div>
        <div>
          <p className='mb-1' style={{fontSize: '16px', fontWeight: '600', fontStyle: 'italic', color: 'black'}}>{heaviestLoad} lbs.</p>
          <p style={{fontSize: '12px'}}>Heaviest Set</p>
        </div>
      </div>
    )
  }

  const renderExercises = () => {
    return(
      workoutToShare && workoutToShare.exercises.map(exercise => {
        return (
          <div className='workout-share-card mb-1' key={exercise._id}>
            <p className='mb-2' style={{fontSize: '16px', fontWeight: '700', fontStyle: 'italic', color: 'black'}}>{exercise.name.toUpperCase()}</p>
            {displayExerciseData(exercise)}
          </div>
        )
      })
    )
  }

  return (
    <div className='workout-share'>
      <div className='workout-share-container'>
        {getWorkoutsLoading ? <Loading /> : (
          <>
            <div className='workout-share-header'>
              <p className='killed-text'>Killed</p>
              <p className='main-page-title mb-1' style={{ textAlign: 'center' }}>{workoutToShare && workoutToShare.name}</p>
              <p style={{fontSize: '14px', color:'#5A6474'}}>{`on ${moment(workoutToShare && workoutToShare.date).format('MMMM DD, YYYY')}`}</p>
            </div>
            {renderExercises()}
            <div className='bottom-div'>
              <p className='blue-link' onClick={() => window.location.assign('https://tools.alvarofitness.com/program-recommendation')}>Powered By Nubod</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}