import React from 'react';
import { capitalize } from '../../helpers/utils';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';

export const FitbitMacroCard = (props) => {
  return(
    props.fitbitNutrition && !props.fitbitNutritionLoading ? 
    <>
      <div className="fitbit-macros-card">
        <p className='macro-number'>{props.fitbitNutrition.summary.calories}</p>
        <p className='macro-label-bottom'>{capitalize(`${Object.keys(props.fitbitNutrition.summary)[0]}`)}</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className='macro-number'>{props.fitbitNutrition.summary.protein.toFixed(1)}</p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>{capitalize(`${Object.keys(props.fitbitNutrition.summary)[4]}`)}</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className='macro-number'>{props.fitbitNutrition.summary.carbs.toFixed(1)}</p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>{capitalize(`${Object.keys(props.fitbitNutrition.summary)[1]}`)}</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className='macro-number'>{props.fitbitNutrition.summary.fat.toFixed(1)}</p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>
          {capitalize(`${Object.keys(props.fitbitNutrition.summary)[2]}s`)} 
        </p>
      </div>
    </> :
    <LoadingOutlined spin />
  )
}