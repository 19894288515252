import React from 'react';
import { WorkoutProgramCard } from './workoutProgramCard';
import WorkoutProgramModal from './workoutProgramModal';
import CreateWorkoutModal from '../AdminWorkoutLibrary/createWorkoutModal';
import './index.css';

class AdminWorkoutProgramLibraryPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkoutProgram: {},
      currentWorkout: {exercises: []},
    };
  }

  setCurrentWorkoutProgram = (workoutProgram) => {
    this.setState({ currentWorkoutProgram: workoutProgram });
  }

  showWorkoutPreview = (workout) => {
    this.setState({ currentWorkout: workout });
    this.props.toggleCreateWorkoutModal(true);
  }

  render(){
    return (
      <div className="admin-workout">
        <CreateWorkoutModal 
          history={this.props.history}
          visible={this.props.createWorkoutModalVisible}
          workout={this.state.currentWorkout}
          toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
          isView={true}
        />
        {this.state.currentWorkoutProgram &&
        <WorkoutProgramModal 
          updateWorkoutProgram={this.props.updateWorkoutProgram}
          history={this.props.history}
          visible={this.props.workoutProgramModalVisible}
          workoutProgram={this.state.currentWorkoutProgram}
          toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
          showWorkoutPreview={this.showWorkoutPreview}
          isView={false}
          workouts={this.props.workouts}
          createWorkoutProgram={this.props.createWorkoutProgram}
          createWorkoutProgramSuccess={this.props.createWorkoutProgramSuccess}
          createUserWorkoutProgram={this.props.createUserWorkoutProgram}
          getAllOnsiteClients={this.props.getAllOnsiteClients}
          clients={this.props.clients}
          clientsLoading={this.props.clientsLoading}
          workoutProgramsLoading={this.props.workoutProgramsLoading}
        />}
        <div style={{cursor: 'pointer'}} className='exercise-card-container'>
          {this.props.workoutPrograms.map(workoutProgram => 
          <WorkoutProgramCard 
            key={workoutProgram._id}
            toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
            workoutProgram={workoutProgram} 
            setCurrentWorkoutProgram={this.setCurrentWorkoutProgram}
          />)}
        </div>
      </div>
    )
  }
}

export default AdminWorkoutProgramLibraryPage;