import React, { useState, useEffect } from 'react';
import { Input, Select, Table } from 'antd';
import './index.css';

const { Option } = Select;

export const OneRepMaxTable = ({oneRepMaxLoad, oneRepMaxReps}) => {
  const [form, setForm] = useState({ 
    weight: oneRepMaxLoad || 120, 
    reps: oneRepMaxReps || 8, 
    unit: 'lb', 
    oneRepMax: 0 
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const initialOneRepMax = calculateOneRepMax(form.weight, form.reps);
    setForm({ ...form, oneRepMax: initialOneRepMax });
  }, []);

  useEffect(() => {
    const newWeight = oneRepMaxLoad || 120;
    const newReps = oneRepMaxReps || 8;
    const newOneRepMax = calculateOneRepMax(newWeight, newReps);

    setForm({ ...form, weight: newWeight, reps: newReps, oneRepMax: newOneRepMax });
  }, [oneRepMaxLoad, oneRepMaxReps]);

  useEffect(() => {
    const { tableData } = calculateChartDataAndTableData(form.oneRepMax);
    setTableData(tableData);
  }, [form.oneRepMax]);

  const calculateOneRepMax = (weight, reps) => {
    const oneRepMax = weight / (1.0278 - (0.0278 * reps));
    return Number(oneRepMax.toFixed(1));
  };

  const calculateEstimatedRepsForPercentage = (oneRepMax, weight) => {
    if(weight === oneRepMax) {
      return 1;
    } else {
      const estimatedReps = Math.round((oneRepMax / weight - 1) / 0.0333);
      return estimatedReps;
    }
  };

  const calculateChartDataAndTableData = (oneRepMax) => {
    const percentages = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45];
    const tableData = [];
  
    percentages.forEach((percentage) => {
      const weight = Math.ceil(oneRepMax * (percentage / 100) * 100) / 100;
      const estimatedReps = calculateEstimatedRepsForPercentage(oneRepMax, weight);
  
      tableData.push({
        percentage: `${percentage}%`,
        weight: weight.toFixed(1),
        estimated_reps: estimatedReps.toFixed(0),
      });
    });
  
    return { tableData };
  };

  const convertWeight = (weight, unit) => {
    if (unit === 'kg') {
      return weight * 0.45359237;
    } else if (unit === 'lb') {
      return weight * 2.20462262185;
    }
  };

  const handleInputChange = (name, value) => {
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);
  
    if (name === 'weight' || name === 'reps') {
      const oneRepMax = calculateOneRepMax(updatedForm.weight, updatedForm.reps);
      updatedForm.oneRepMax = oneRepMax;
      const { tableData } = calculateChartDataAndTableData(oneRepMax);
      setTableData(tableData);
    }
  }

  const handleUnitChange = (value) => {
    const convertedWeight = convertWeight(form.weight, value);
    const updatedForm = {
      ...form,
      weight: convertedWeight,
      unit: value
    };
    
    const oneRepMax = calculateOneRepMax(updatedForm.weight, form.reps);
    updatedForm.oneRepMax = oneRepMax;
    setForm(updatedForm);
  };

  const renderInputField = (label, name) => {
    return (
      <div>
        <div className='form form-section mb-2'>
        <p className='label'>{label}</p>
        <Input 
          className='one-rep-max-input'
          onChange={(e) => handleInputChange(name, e.target.value)} 
          type='number' 
          addonAfter={name === 'weight' && (
            <Select value={form.unit} onChange={handleUnitChange}>
              <Option value="lb">lb</Option>
              {/* <Option value="kg">kg</Option> */}
            </Select>
          )}
          value={form[name]}
        />
      </div>
      </div>
      
    )
  };

  const renderTable = () => {
    const columns = [
      {
        title: '% of 1RM',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'center',
        width: '33.33%'
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        align: 'center',
        width: '33.33%'
      },
      {
        title: 'Estimated Reps',
        dataIndex: 'estimated_reps',
        key: 'estimated_reps',
        align: 'center',
        width: '33.33%'
      }
    ]

    return (
      <Table 
        columns={columns} 
        dataSource={tableData} 
        pagination={false}
      />
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <div className='two-col-input mb-4'>
        {renderInputField('Weight', 'weight')}
        {renderInputField('Reps', 'reps')}
      </div>
      <div className='global-card mb-4' style={{backgroundColor: '#F0F2FF'}}>
        <p className='exercise-name-history' style={{marginBottom: '0'}}>{`Your one rep max is ${form.oneRepMax} ${form.unit}`}</p>
      </div>
      <div>
        <p className='home-card-text mb-3'>Percentage Chart</p>
        {renderTable()}
      </div>
    </div>
  )
}