import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { DiscussionEmbed } from "disqus-react";
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
} from "react-share";
import { IoIosArrowBack } from "react-icons/io";
import { getSinglePost } from "../../ducks/Blog/actions";
import './index.scss';
import MetasForPage from "../../MetasForPage";

const getPostSlug = () => window.location.pathname.split("/").pop();

class BlogPost extends React.Component {
	componentWillMount() {
		if (!this.props.location.state) {
			this.props.getSinglePost(getPostSlug());
		}
	}

	render() {
		const data = this.props.location.state || this.props.currentPost;
		const disqusShortname = "nubod";
		const disqusConfig = data && {
			url: `${process.env.REACT_APP_APP_URL}/blog/${data.slug}`,
			identifier: data.slug,
			title: data.title,
		};
		return (
			data && (
				<React.Fragment>
					<MetasForPage
						loc=""
						config={{
							img: data.feature_image,
							twitterImage: data.twitter_image,
							altImg: "",
							title: data.meta_title,
							description: data.meta_description,
							url: data.url,
						}}
					/>
					<Link
						className="solid-link back-to-posts flex flex-center-aligned"
						to="/blog"
					>
						<IoIosArrowBack />
						<p>Back to all posts</p>
					</Link>
					<div className="blog-title-section">
						<h1 className="title">{data.title}</h1>
						<div className="author">
							<div className="flex flex-center-aligned">
								<img
									alt='author-img'
									className="author-image"
									src={data.authors[0].profile_image}
								/>
								<div className="author-name">
									<p className="name">{data.authors[0].name}</p>
									<p className="date">
										{moment(data.published_at).format("MMM D, YYYY")}
									</p>
								</div>
							</div>
							<div className="share-buttons">
								<EmailShareButton
									url={`https://www.alvarofitness.com${this.props.location.pathname}`}
								>
									<EmailIcon size={40} round={true} />
								</EmailShareButton>
								<FacebookShareButton
									quote={data.excerpt}
									url={`https://www.alvarofitness.com${this.props.location.pathname}`}
								>
									<FacebookIcon size={40} round={true} />
								</FacebookShareButton>
								<LinkedinShareButton
									title={data.title}
									summary={data.excerpt}
									url={`https://www.alvarofitness.com${this.props.location.pathname}`}
								>
									<LinkedinIcon size={40} round={true} />
								</LinkedinShareButton>
								<TwitterShareButton
									title={data.title}
									url={`https://www.alvarofitness.com${this.props.location.pathname}`}
								>
									<TwitterIcon size={40} round={true} />
								</TwitterShareButton>
								<WhatsappShareButton
									url={`https://www.alvarofitness.com${this.props.location.pathname}`}
								>
									<WhatsappIcon size={40} round={true} />
								</WhatsappShareButton>
							</div>
						</div>
					</div>
					<div className="blog-post-container">
						<div
							className="header-image"
							style={{ backgroundImage: `url(${data.feature_image})` }}
						></div>
						<div
							className="blog-body"
							dangerouslySetInnerHTML={{ __html: data.html }}
						/>
						<DiscussionEmbed
							shortname={disqusShortname}
							config={disqusConfig}
						/>
						{/* <div className='related-posts'>
          <h1>Related posts</h1>
          <div className='cards'>
            {relatedPosts()}
          </div>
        </div> */}
					</div>
				</React.Fragment>
			)
		);
	}

	// const relatedPosts = () => {
	//   let posts = []
	//   let postExcludingCurrent = props.allBlogs && props.allBlogs.data.filter(blog => blog.slug !== data.slug);
	//   return postExcludingCurrent && postExcludingCurrent.map(blog => {
	//     blog.categories.map(category => {
	//       data.categories.map(dataCategory => {
	//         if(category.name === dataCategory.name){
	//           posts.push(blog)
	//         }
	//       })
	//     })
	//     return posts.slice(0,2).map(blog => <BlogCardHome {...blog} style={pathname === 'blog' ? 'feature-card' : ''} key={blog.slug}/>)
	//   })
	// }
}

const mapStateToProps = (state) => {
	return {
		currentPost: state.blog.currentPost,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSinglePost: (slug) => dispatch(getSinglePost(slug)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);
