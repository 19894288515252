import {
  GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_ERROR,
  CLEAR_ALERT,
  CLEAR_RECIPES,
  SAVE_GROCERY,
  SAVE_GROCERY_SUCCESS,
  SAVE_GROCERY_ERROR,
  GET_GROCERIES,
  GET_GROCERIES_SUCCESS,
  GET_GROCERIES_ERROR,
  DELETE_OR_UPDATE_GROCERY,
  DELETE_GROCERY_SUCCESS,
  UPDATE_GROCERY_SUCCESS,
  DELETE_GROCERY_ERROR,
  CLEAR_GET_GROCERIES_ERROR,
  SAVE_RECIPE_TO_FAVORITES,
  SAVE_RECIPE_TO_FAVORITES_SUCCESS,
  SAVE_RECIPE_TO_FAVORITES_ERROR,
  GET_FAVORITE_RECIPES,
  GET_FAVORITE_RECIPES_SUCCESS,
  GET_FAVORITE_RECIPES_ERROR,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR
} from "./constants";

export function getRecipes(queryObj, fromMealPlanParams) {
  return {
    type: GET_RECIPES,
    queryObj, 
    fromMealPlanParams
  };
}

export function getRecipesSuccess(payload) {
  return {
    type: GET_RECIPES_SUCCESS,
    payload
  };
}

export function getRecipesError(error) {
  return {
    type: GET_RECIPES_ERROR,
    error
  };
}

export function clearRecipes() {
  return {
    type: CLEAR_RECIPES
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT
  };
}

export function saveGrocery(dish, ingredients, image) {
  return {
    type: SAVE_GROCERY,
    dish,
    ingredients,
    image
  };
}

export function saveGrocerySuccess(payload) {
  return {
    type: SAVE_GROCERY_SUCCESS,
    payload
  };
}

export function saveGroceryError(payload) {
  return {
    type: SAVE_GROCERY_ERROR,
    payload
  };
}

export function getGroceries() {
  return {
    type: GET_GROCERIES
  };
}

export function getGroceriesSuccess(payload) {
  return {
    type: GET_GROCERIES_SUCCESS,
    payload
  };
}

export function getGroceriesError(payload) {
  return {
    type: GET_GROCERIES_ERROR,
    payload
  };
}


export function clearGetGroceriesError() {
  return {
    type: CLEAR_GET_GROCERIES_ERROR
  };
}

export function deleteOrUpdateGrocery(id, action, ingredients) {
  return {
    type: DELETE_OR_UPDATE_GROCERY,
    id,
    action,
    ingredients
  };
}

export function deleteGrocerySuccess(id) {
  return {
    type: DELETE_GROCERY_SUCCESS,
    id,
  };
}

export function updateGrocerySuccess(payload) {
  return {
    type: UPDATE_GROCERY_SUCCESS,
    payload
  };
}

export function deleteOrUpdateGroceryError(payload) {
  return {
    type: DELETE_GROCERY_ERROR,
    payload
  };
}

export function saveRecipeToFavorites(ids) {
  return {
    type: SAVE_RECIPE_TO_FAVORITES,
    ids
  };
}

export function saveRecipeToFavoritesSuccess(payload) {
  return {
    type: SAVE_RECIPE_TO_FAVORITES_SUCCESS,
    payload
  };
}

export function saveRecipeToFavoritesError(err) {
  return {
    type: SAVE_RECIPE_TO_FAVORITES_ERROR,
    err
  };
}

export function getFavoriteRecipes() {
  return {
    type: GET_FAVORITE_RECIPES
  };
}

export function getFavoriteRecipesSuccess(payload) {
  return {
    type: GET_FAVORITE_RECIPES_SUCCESS,
    payload
  };
}

export function getFavoriteRecipesError(err) {
  return {
    type: GET_FAVORITE_RECIPES_ERROR,
    err
  };
}

export function fetchFavoriteRecipesFromEdemam(ids) {
  return {
    type: FETCH_FAVORITE_RECIPES_FROM_EDEMAM,
    ids
  };
}

export function fetchFavoriteRecipesFromEdemamSuccess(payload) {
  return {
    type: FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS,
    payload
  };
}

export function fetchFavoriteRecipesFromEdemamError(err) {
  return {
    type: FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR,
    err
  };
}