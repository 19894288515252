import React from "react";
import "../CustomMealPlanPage/index.css";
import debounce from "lodash/debounce";
import { Select, Modal, Spin, Tooltip, Divider } from "antd";
import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from "react-icons/md";

import "./index.css";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class ShoppingItemElement extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      nutritionVisible: false,
      nutritionLoading: false,
      allNutrients: {},
      caloriesPerServing: 0,
      carbsPerServing: 0,
      proteinsPerServing: 0,
      fatsPerServing: 0,
      isVisibleAlternative: false
    };
  }

  componentDidMount() {
    if (this.props.item.quantity && this.props.item.measure) {
      this.setState({
        caloriesPerServing:
          this.props.item.info.calories / this.props.item.quantity,
        carbsPerServing: this.props.item.info.carbs / this.props.item.quantity,
        proteinsPerServing:
          this.props.item.info.proteins / this.props.item.quantity,
        fatsPerServing: this.props.item.info.fats / this.props.item.quantity,
      });
    }
  }

  handleChangeNutrients = debounce(async (modal) => {
    if (modal) {
      this.setState({
        nutritionLoading: true,
      });
    }

    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/mealplan/nutrients`,
      {
        method: "POST",
        body: JSON.stringify({
          _id: this.props.item.food._id,
          quantity: this.props.item.food.quantity,
          measureUrl: this.props.item.food.measureUrl,
          foodId: this.props.item.food.foodId,
        }),
        headers: {
          "x-auth-token": localStorage.token,
          "content-type": "application/json",
        },
      }
    );
    const info = await req.json();
    this.setState({
      loading: false,
      nutritionLoading: false,
      allNutrients: info.totalNutrients,
    });
  }, 400);

  showModal = async () => {
    this.setState({
      nutritionVisible: true,
    });
    if (
      !this.state.nutritionLoading &&
      !(this.state.allNutrients.ENERC_KCAL || this.state.allNutrients.calories)
    ) {
      await this.handleChangeNutrients(true);
    }
  };

  handleCancel = (key) => {
    this.setState({
      [key]: false,
    });
  };

  handleShowAlternative = () => {
    this.setState({
      isVisibleAlternative: !this.state.isVisibleAlternative
    });
  }

  render() {
    const { item, itemIndex } = this.props;
    const { nutritionVisible, allNutrients, isVisibleAlternative } = this.state;
    return (
      <div>
        <div className="shopping-list-item">
          <div>
            <p className="item-text">
              {item.food && item.food.title && item.food.title
                  .split(/\s+/)
                  .map((word) => word[0].toUpperCase() + word.substring(1))
                  .join(" ")}
            </p>
            <p className="macro-label-bottom">
              {item.food && item.food.quantity || 0} {item.food && item.food.measure || ""}
            </p>
          </div>
          {/* <MdCheckCircle style={{fontSize: '22px', color: '#2bffcc'}}/>  */}
          {/*<span*/}
          {/*  // className="cross"*/}
          {/*  onClick={() => this.props.handleDeleteFromShoppingList(item._id)}*/}
          {/*>*/}
          {/*  &times;*/}
          {/*</span>*/}
          {/*<span>{item.food && item.food.totalQuantity}</span>*/}
          {item.isDone ?
          <MdRadioButtonChecked 
            onClick={() => this.props.updateCustomShoppingListItem({...item, isDone: !item.isDone})}
            style={{fontSize: '22px', color: '#2bffcc', cursor: 'pointer'}}
          /> :
          <MdRadioButtonUnchecked 
            onClick={() => this.props.updateCustomShoppingListItem({...item, isDone: !item.isDone})}
            style={{fontSize: '22px', color: '#2bffcc', cursor: 'pointer'}}
          /> }
        </div>
        {item.food && item.food.alternatives && <p className='blue-link' onClick={this.handleShowAlternative}>Alternatives</p>}
        <div>
          <ul className='alternative-list'>
            {
              isVisibleAlternative && item.food && item.food.alternatives && item.food.alternatives.map((alternative, index) => (
                <li key={index}>
                  {
                    alternative.title
                      .split(/\s+/)
                      .map((word) => word[0].toUpperCase() + word.substring(1))
                      .join(" ")
                  }
                </li>
              ))
            }
          </ul>
        </div>
        <div className="flex flex-wrap flex-center-aligned">
          <Modal
              title={`${item.food && item.food.title} nutrition details`}
              visible={nutritionVisible}
              onCancel={() => this.handleCancel("nutritionVisible")}
              footer={null}
              centered={true}
            >
              {this.state.nutritionLoading && <Spin indicator={antIcon} />}
              {!this.state.nutritionLoading &&
                Object.entries(allNutrients).map((i) => (
                  <div key={i[1].label}>
                    {i[1].label}: {(+i[1].quantity).toFixed(1)} {i[1].unit}
                  </div>
                ))}
          </Modal>
          {/* <div className="mr-3">
            <p className="home-card-text">
              {(item.food.info && Number(item.food.info.calories).toFixed(1)) || 0}kcal
            </p>
            <span className="macro-label-bottom">Calories</span>
          </div> */}
          {/* <div className="mr-3">
            <p className="home-card-text">
              {(item.food.info && Number(item.food.info.proteins).toFixed(1)) || 0}g
            </p>
            <span className="macro-label-bottom">Proteins</span>
          </div>
          <div className="mr-3">
            <p className="home-card-text">
              {(item.food.info && Number(item.food.info.carbs).toFixed(1)) || 0}g
            </p>
            <span className="macro-label-bottom">Carbs</span>
          </div> */}
          {/* <div className="mr-3">
            <p className="home-card-text">
              {(item.food.info && Number(item.food.info.fats).toFixed(1)) || 0}g
            </p>
            <span className="macro-label-bottom">Fats</span>
          </div>
          <div className="blue-link" onClick={this.showModal}>
            Show more
          </div>           */}
        </div> 
      </div>
    );
  }
}

export default ShoppingItemElement;
