import React from "react";
import { connect } from "react-redux";
import ProfilePage from "../../presentational/ProfilePage";
import { Loading } from '../../components/Loading';
import { getCurrentProfile, getNutrition } from "../../ducks/Profile/actions";
import { getUserBfMeasurements } from "../../ducks/Measurements/actions";
import { MacrosProgress } from '../../components/MacrosProgress';
import { Divider } from 'antd';
import "../../presentational/ProfilePage/index.css";

class Profile extends React.Component {

  componentDidMount(){
    this.props.getCurrentProfile();
    if(!this.props.nutrition){
      this.props.getNutrition();
    }
  }

  returnTargetInfo = () => {
    const { nutrition } = this.props;

    if(nutrition){
      const isNutritionResults = nutrition && Object.keys(nutrition)[0] === 'nutritionResults';
   
      return {
        calories: isNutritionResults ? nutrition.nutritionResults.goalCalories : nutrition.goalCalories,
        protein: isNutritionResults ? nutrition.nutritionResults.protein.grams : nutrition.protein.grams,
        proteinPercent: isNutritionResults ? nutrition.nutritionResults.protein.percentage : nutrition.protein.percentage,
        carb: isNutritionResults ? nutrition.nutritionResults.carbs.grams : nutrition.carbs.grams,
        carbPercent: isNutritionResults ? nutrition.nutritionResults.carbs.percentage : nutrition.carbs.percentage,
        fats: isNutritionResults ? nutrition.nutritionResults.fats.grams : nutrition.fats.grams,
        fatsPercent: isNutritionResults ? nutrition.nutritionResults.fats.percentage : nutrition.fats.percentage
      }
    }
  }

  render() {
    return (
      <>
        <div className="site-layout-background macros-page">
          {this.props.profile && this.props.nutrition ? 
          <> 
            <div className='flex space-around' style={{gridGap: '5px'}}>
              <MacrosProgress targetInfo={this.returnTargetInfo()}/>
            </div>
            <Divider />
            <ProfilePage {...this.props}/> 
          </> : <Loading />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile.profile,
    error: state.profile.error,
    loading: state.profile.loading,
    successMessage: state.profile.successMessage,
    nutrition: state.profile.nutrition
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
    getNutrition: () => dispatch(getNutrition())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
