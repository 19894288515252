import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Button } from 'antd';
import { MacroCard } from './MacroCard';
import { FitbitMacroCard } from './FitbitMacroCard';
import { RemainingMacros } from './RemainingMacros';
import { HomeStats } from './HomeStats';
import { MdCheckCircle } from "react-icons/md";
import { PlusOutlined } from '@ant-design/icons';
import SideDrawer from '../../components/SideDrawer';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { HomeSummary } from './HomeSummary';
import { HomeProgram } from './HomeProgram';
import ToDo from './ToDo';
import './index.scss';
import WorkoutStatsPage from '../WorkoutStatsPage';
import { ScheduleNotification } from '../../components/ScheduledNotification';
// import AllWorkouts from '../../presentational/WorkoutsPage/allWorkouts';
// import LiveWorkouts from '../../presentational/WorkoutsPage/liveWorkouts';

class HomeDashboard extends React.Component {
  constructor(){
    super();
    this.state={
      nutritionHomeData: [],
      showFitbitList: false,
      autoAdjust: false
    }
  }

  componentDidMount(){
    if(this.props.history.location.pathname === "/dashboard/home/add-measurement"){
      this.props.openDrawer()
    }

    const { nutrition, profile } = this.props;
    let finalNutrition = nutrition;
    if (nutrition.nutritionResults) {
      finalNutrition = nutrition.nutritionResults
    }
    let nutritionHomeData = [
      {key: 1, value: finalNutrition.goalCalories}, 
      {key: 2, value: finalNutrition.protein},
      {key: 3, value: finalNutrition.carbs}, 
      {key: 4, value: finalNutrition.fats}
    ];
    if(profile) this.props.storeIsLastTwoDays(profile);
    this.setState({ nutritionHomeData: nutritionHomeData });
  }

  componentDidUpdate(previousProps){
    if (previousProps.nutrition._id !== this.props.nutrition._id) {
      const { nutrition } = this.props;
      let nutritionHomeData = [
        {key: 1, value: nutrition.goalCalories}, 
        {key: 2, value: nutrition.protein},
        {key: 3, value: nutrition.carbs}, 
        {key: 4, value: nutrition.fats}
      ];
      this.setState({ nutritionHomeData });
    }
  }

  componentWillUnmount() {
    if(this.props.isUserVerified) this.props.resetUserVerified();
  }

  componentWillReceiveProps(nextProps){
    if(this.props.showDrawer !== nextProps.showDrawer && !this.props.showDrawer){
      this.props.history.push('/dashboard/home')
    }
  }

  renderFitbitFoods = () => {
    if(this.props.fitbitNutrition.foods.length > 0){
      return this.props.fitbitNutrition.foods.map(food => 
        <>
          <Divider />
          <div className='grid-list'>
            <MdCheckCircle style={{marginRight: '5px', minWidth: '16px', color: '#2bffcc'}}/> 
            <div className='fitbit-list-text home-card-text'>
              <div style={{display: 'flex', flexWrap: 'none', marginBottom: '3px'}}>
                <p style={{marginRight: '3px'}}>{food.loggedFood.amount}</p>
                <p>{food.loggedFood.amount === 1 ? food.loggedFood.unit.name : food.loggedFood.unit.plural}</p>
              </div>
              <p>{food.loggedFood.name} {food.loggedFood.brand}</p>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <Divider />
          <p className='home-card-text'>You haven't logged any foods yet</p>
        </>
      )
    }
  }

  handleMacroCard = (card) => {
    this.setState({[card]: !this.state[card]});
  }

  renderFitbitData = () => {
    return(
      this.props.profile.fitbitToken && this.props.fitbitNutrition && this.state.nutritionHomeData.length !== 0 &&
      <>
        <div className='home-macros-card fitbit'>
          <div onClick={() => this.handleMacroCard('showFitbitList')}>
            <div className='flex flex-center-aligned space-between'>
              <p className='blog-card-category macro-label'>Fitbit Today</p>
              {this.state.showFitbitList ? <IoIosArrowUp className='icon'/> : <IoIosArrowDown className='icon'/> }
            </div>
            <div className='fitbit-macros-container'>
              <FitbitMacroCard {...this.props} />
            </div>
          </div>
          {this.state.showFitbitList && this.renderFitbitFoods()}
        </div>
        <div className='home-macros-card'>
          <p className='blog-card-category macro-label'>Remaining Today</p>
          <div className='fitbit-macros-container'>
            <RemainingMacros {...this.props} nutritionHomeData={this.state.nutritionHomeData}/>
          </div>
        </div>
      </>
    )
  }

  handleChange = (e) => {
    if(e.key === '0') {
      this.props.openDrawer();
    } else if(e.key === '1'){
      this.props.history.push('/dashboard/stats/progress');
    }
  }

  // autoAdjust = () => {
  //   const { nutritionHomeData, autoAdjust } = this.state;
  //   const { fitbitNutrition } = this.props;
  //   let remainingFat = nutritionHomeData[3].value.grams - fitbitNutrition.summary.fat;
  //   let remainingCarbs = nutritionHomeData[2].value.grams - fitbitNutrition.summary.carbs;
  //   let carbKal = remainingCarbs * 4;
  //   let fatKal = remainingCarbs * 9;

  //   if(autoAdjust && Math.sign(remainingCarbs) === -1 && Math.sign(remainingFat) === 1){ //if carb went negative
  //     let difference = fatKal - carbKal;
  //     let newFatGram = difference / 9;
  //     nutritionHomeData[3].value.grams = newFatGram;
  //     this.setState({nutritionHomeData: nutritionHomeData})
  //   } else if(autoAdjust && Math.sign(remainingFat) === -1 && Math.sign(remainingCarbs) === 1){
  //     let difference = carbKal - fatKal;
  //     let newCarbGram = difference / 4;
  //     nutritionHomeData[2].value.grams = newCarbGram;
  //     this.setState({nutritionHomeData: nutritionHomeData});
  //   }
  // }
  
  render(){
    const getTooltipTitle = (
      <div>
        <p>Your calories are too low. We recommend adjusting your goal time frame so your target is at least 1200 calories daily. <Link to={'/dashboard/tools/macro-calculator'}>Adjust</Link></p>
      </div>
    )

    return(
      <div style={{marginBottom: '50px'}}>
        <SideDrawer 
          userBodyFatMeasurements={this.props.userBodyFatMeasurements}
          data={this.props.profile} 
          todo={this.props.todo}
          createOrUpdateTodo={this.props.createOrUpdateTodo}
        />
        {/* {this.state.nutritionHomeData.length !== 0 && this.autoAdjust()} */}
        {
          <div style={{marginBottom: '20px'}} className='flex justify-center'>
            <Button 
              onClick={() => {this.props.openDrawer(); this.props.history.push('/dashboard/home/add-measurement')}}  
              className='add-measurement-btn' 
              size='large' 
              shape="circle">
              <PlusOutlined 
              className='plus-icon'/>
            </Button>
          </div>
        }
         {/* <AllWorkouts />
         <LiveWorkouts /> */}
         <div className='mb-3'>
          <ScheduleNotification openDrawer={this.props.openDrawer}/>
         </div>
         {
          this.props.todo && !this.props.todo.completed ? <ToDo todo={this.props.todo} openDrawer={this.props.openDrawer}  /> : null
         }
        <WorkoutStatsPage profile={this.props.profile}/>
        <div className='flex flex-center-aligned space-between'>
          <div className='flex space-between'>
            <p className='home-card-title'>Macronutrients</p>
            {/* {this.state.nutritionHomeData.length && this.state.nutritionHomeData[0].value < 1200 &&
            <Tooltip title={getTooltipTitle}>
              <TiWarning style={{color: 'red', margin: '0 0 12px 3px'}} />
            </Tooltip>} */}
          </div>
        </div>
        
        <div className={this.props.profile.fitbitToken ? 'home-macros' : ''}>
          <div className='home-macros-card mb-4'>
            <p className='blog-card-category macro-label'>{this.props.profile.fitbitToken && 'Goal'}</p>
            <div className={`macros-container ${!this.props.profile.fitbitToken ? 'progress-padding no-grid-gap' : ''}`}>
              {this.state.nutritionHomeData && this.state.nutritionHomeData.length !== 0 && this.state.nutritionHomeData.map(data => <MacroCard {...this.props} key={data.key} data={data} />)}
            </div>
          </div>
          {this.renderFitbitData()}
        </div>
        <HomeProgram history={this.props.history} profile={this.props.profile} />
        <HomeSummary {...this.props} profile={this.props.profile} />       
        {this.props.userBodyFatMeasurements.length > 0 &&
        <div>
          <div className='flex flex-center-aligned space-between'>
            <p className='home-card-title'>Progress</p>
            <p className='blue-link' onClick={() => this.props.history.push('/dashboard/stats/progress')}>View all</p>
          </div>
          <HomeStats {...this.props} />
        </div>}

        {/* <div className='flex' style={{flexDirection: 'column'}}> */}
          {/* <div className='flex flex-center-aligned' style={{marginBottom: '10px'}}>
            <p className='home-card-title' style={{marginBottom: '0px'}}>Nubod Coach</p>
            <div className='pro-label'>PRO</div>
          </div> */}
          {/* <div className='home-card smart-coach'> */}
            {/* <img className='img' src={SmartCoach} /> */}
            {/* <div className='text-content'> */}
              {/* <p className='title'>Smart 7-day meal plans that adapt to you progress week by week.</p>
              <p className='card-subtitle'>Like having a nutrition coach next to you at all times.</p> */}
              {/* <Button 
                type='primary' 
                loading={this.props.suggesticMealPlanLoading}
                onClick={() => this.props.handlePricingModal(true)}
                // onClick={() => this.props.createWeeklyMealPlan({calories: this.props.nutrition.goalCalories, format: ["BREAKFAST", "SNACK", "LUNCH", "DINNER"]})}
              >
                Upgrade
              </Button> */}
              {/* <Payments /> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
      </div>
    )
  }
}

export default HomeDashboard;