import {
  SEND_RESULTS_TO_EMAIL,
  SEND_RESULTS_TO_EMAIL_SUCCESS,
  SEND_RESULTS_TO_EMAIL_ERROR,
  SET_ASSESSMENT_RESULTS,
  SET_ASSESSMENT_RESULTS_SUCCESS,
  SET_ASSESSMENT_RESULTS_ERROR,
} from "./constants";

const initialState = {
  sendEmailLoading: false,
  sendResponse: null,
  responseObj: null,
  assessmentResults: null,
  profile: null,
  setAssessmentLoading: false,
  setAssessmentData: null
};

function assessment(state = initialState, action) {
  switch (action.type) {
    case SEND_RESULTS_TO_EMAIL:
      return {
        ...state,
        sendEmailLoading: true,
        sendResponse: null,
      };
    case SEND_RESULTS_TO_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailLoading: false,
        sendResponse: action.response,
      };
    case SEND_RESULTS_TO_EMAIL_ERROR:
      return {
        ...state,
        sendEmailLoading: false,
        sendResponse: action.err,
      };
      case SET_ASSESSMENT_RESULTS:
        return {
          ...state,
          assessmentResults: action.assessmentResults,
          responseObj: action.responseObj,
          profile: action.profile,
        };
    default:
      return state;
  }
}

export default assessment;
