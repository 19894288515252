import React from 'react';
import { connect } from 'react-redux'; 
import AdminClientsPage from '../../presentational/AdminClientsPage';
import { getAllOnsiteClients, getCurrentProfile } from '../../ducks/Profile/actions';
import { deleteUserAndRelatedData } from '../../ducks/Global/actions';
import { Loading } from '../../components/Loading';

class AdminClients extends React.Component {

  componentDidMount(){
    this.props.getAllOnsiteClients();
    this.props.getCurrentProfile();
  }

  render(){
    const notLoading = this.props.onsiteClients && this.props.profile;
    return(
      <div className='site-layout-no-background admin'>
        {notLoading ? <AdminClientsPage {...this.props}/> : <Loading />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onsiteClients: state.profile.onsiteClients,
    profile: state.profile.profile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllOnsiteClients: () => dispatch(getAllOnsiteClients()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    deleteUserAndRelatedData: (userId) => dispatch(deleteUserAndRelatedData(userId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminClients);