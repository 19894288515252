import React from 'react';
import { WorkoutPlanCard } from './workoutPlanCard';
import WorkoutProgramModal from '../AdminWorkoutProgramLibraryPage/workoutProgramModal';
import CreateWorkoutModal from '../AdminWorkoutLibrary/createWorkoutModal';
import './index.css';

class AdminWorkoutPlanPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkoutProgram: {},
      currentWorkout: {exercises: []}
    };
  }

  setCurrentWorkoutProgram = (workoutProgram) => {
    this.setState({ currentWorkoutProgram: workoutProgram });
  }

  showWorkoutPreview = (workout) => {
    this.setState({ currentWorkout: workout });
    this.props.toggleCreateWorkoutModal(true);
  }

  render(){
    return (
      <div>
        {this.props.workoutPlans.map(workoutPlan => 
        <WorkoutPlanCard 
          identifier={workoutPlan._id}
          workoutPlan={workoutPlan}
          setCurrentWorkoutProgram={this.setCurrentWorkoutProgram}
          toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
        />)}
        <WorkoutProgramModal 
          visible={this.props.workoutProgramModalVisible}
          workoutProgram={this.state.currentWorkoutProgram}
          toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
          toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
          showWorkoutPreview={this.showWorkoutPreview}
        />
        <CreateWorkoutModal 
          history={this.props.history}
          visible={this.props.createWorkoutModalVisible}
          workout={this.state.currentWorkout}
          toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
          isView={true}
          showWorkoutPreview={true}
        />
      </div>
    )
  }
}

export default AdminWorkoutPlanPage;