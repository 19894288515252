import React from 'react';
import { connect } from 'react-redux';
import StatsPage from '../../pages/dashboard/stat';
import { getUserBfMeasurements } from '../../ducks/Measurements/actions';

class AdminClientProgress extends React.Component {

  componentDidMount(){
    this.props.getUserBfMeasurements(this.props.clientProfile.user._id)
  }

  render(){
    return(
      <div className='site-layout-no-background admin'>
        <StatsPage 
          {...this.props} 
          isAdmin={true} 
          profile={this.props.clientProfile}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userBodyFatMeasurements: state.measurements.userBodyFatMeasurements
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
  };  
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminClientProgress);