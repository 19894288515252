import React, { useEffect, useState } from "react";
import firebaseApp, { getIdToken } from "./../../helpers/auth.js";
import { fetchAPI } from "../../ducks/api.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({
  children,
  isRegistration,
  loadUser,
  isAuthenticated,
}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  // const dispatch = useDispatch();

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      console.log(`Registration: ${isRegistration}`);
      if (user && isRegistration) {
        const token = await getIdToken();
        window.localStorage.setItem("token", token);
      }
      if (user && !isRegistration) {
        try {
          console.log("Will fetch api");
          const token = await getIdToken();
          window.localStorage.setItem("token", token);
          const userResponse = await fetchAPI("/auth/");
          setCurrentUser(userResponse);
          loadUser();
          setPending(false);
        } catch (error) {
          setCurrentUser(null);
          setPending(false);
        }
      } else {
        console.log(`Hitting else`);
        setCurrentUser(null);
        setPending(false);

      }
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ currentUser, pending }}>
      {children}
    </AuthContext.Provider>
  );
};
