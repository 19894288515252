import {
  SEND_CONTACT_TO_ZAPIER_REQUEST,
  SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS,
  SEND_CONTACT_TO_ZAPIER_REQUEST_ERROR,
  HANDLE_PROFILE_SUBMENU,
  SET_CURRENT_PAGE,
  SET_IS_LOGIN,
  STORE_IS_LAST_TWO_DAYS,
  SCROLL_TO_REF,
  SCROLL_TO_TOP,
  DELETE_USER_AND_RELATED_DATA,
  GET_SCHEDULED_NOTIFICATIONS,
  GET_SCHEDULED_NOTIFICATIONS_SUCCESS,
  GET_SCHEDULED_NOTIFICATIONS_ERROR,
  OPEN_REVIEW_MODAL,
  HANDLE_ACHIEVEMENT_MODAL
} from './constants';
import moment from "moment";

export function zapierEmail(data) {
  return {
    type: SEND_CONTACT_TO_ZAPIER_REQUEST,
    data
  };
}

export function zapierEmailSuccess(response) {
  return {
    type: SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS,
    response
  };
}

export function zapierEmailError(err) {
  return {
    type: SEND_CONTACT_TO_ZAPIER_REQUEST_ERROR,
    err
  };
}

export function handleProfileSubmenu(value){
  return {
    type: HANDLE_PROFILE_SUBMENU,
    value
  }
}

export function setCurrentPage(currentPage){
  return {
    type: SET_CURRENT_PAGE,
    currentPage
  }
}

export function setIsLogin(value){
  return {
    type: SET_IS_LOGIN,
    value
  }
}

export function storeIsLastTwoDays(profile){
  let isLastTwoDaysOfPlan = false;
  if(profile) {
    if(profile.user && profile.user.role !== 'admin') {
      if(profile.customMealPlan && profile.customMealPlan.plan) {
        const datesArr = Object.keys(profile.customMealPlan.plan);
        const currentDate = moment().utc();
        if(datesArr.length <= 2 || currentDate >= moment(datesArr[datesArr.length - 2]).utc() && currentDate <= moment(datesArr[datesArr.length - 1]).utc()){
          isLastTwoDaysOfPlan = true;
        } else {
          isLastTwoDaysOfPlan = false;
        }
      } else {
        isLastTwoDaysOfPlan = false;
      }
    } else {
      isLastTwoDaysOfPlan = true;
    }
  }

  return ({
    type: STORE_IS_LAST_TWO_DAYS,
    isLastTwoDaysOfPlan
  });
}

export function scrollToRef(ref){
  return {
    type: SCROLL_TO_REF,
    ref
  }
}

export function scrollToTop(){
  return {
   type: SCROLL_TO_TOP
  }
}

export function deleteUserAndRelatedData(userId){
  return {
   type: DELETE_USER_AND_RELATED_DATA,
   userId
  }
}

export function getScheduledNotifications(){
  return {
    type: GET_SCHEDULED_NOTIFICATIONS
  }
}

export function getScheduledNotificationsSuccess(payload){
  return {
    type: GET_SCHEDULED_NOTIFICATIONS_SUCCESS,
    payload
  }
}

export function getScheduledNotificationsError(err){
  return {
    type: GET_SCHEDULED_NOTIFICATIONS_ERROR,
    err
  }
}

export function openReviewModal(reviewModalOpen, review) {
  return {
    type: OPEN_REVIEW_MODAL,
    reviewModalOpen,
    review
  };
}

export function handleAchievementModal(achievementProps) {
  return {
    type: HANDLE_ACHIEVEMENT_MODAL,
    achievementProps
  };
}