import {
  GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_ERROR,
  CLEAR_RECIPES,
  CLEAR_ALERT,
  SAVE_GROCERY,
  SAVE_GROCERY_SUCCESS,
  SAVE_GROCERY_ERROR,
  GET_GROCERIES,
  GET_GROCERIES_SUCCESS,
  GET_GROCERIES_ERROR,
  DELETE_OR_UPDATE_GROCERY,
  DELETE_GROCERY_SUCCESS,
  UPDATE_GROCERY_SUCCESS,
  DELETE_GROCERY_ERROR,
  CLEAR_GET_GROCERIES_ERROR,
  HANDLE_RECIPE_SEARCH_TERM,
  GET_FAVORITE_RECIPES,
  GET_FAVORITE_RECIPES_SUCCESS,
  GET_FAVORITE_RECIPES_ERROR,
  SAVE_RECIPE_TO_FAVORITES,
  SAVE_RECIPE_TO_FAVORITES_SUCCESS,
  SAVE_RECIPE_TO_FAVORITES_ERROR,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS,
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR
} from "./constants";

const initialState = {
  recipes: null,
  loading: null,
  saveGroceryListError: null,
  groceryList: [],
  groceryListLoading: false,
  groceryListItemLoading: {},
  error: null,
  recipeSearchTerm: '',
  favoriteRecipes: [],
  favoriteRecipesFromEdemam: [],
  favoriteRecipesFromEdemamLoading: false,
  favoriteRecipesFromEdemamError: null
};

function recipes(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_RECIPES:
      return {
        ...state,
        recipes: null,
        loading: true,
        error: null
      };
    case GET_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: payload,
        loading: false
      };
    case GET_RECIPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAR_RECIPES:
      return {
        ...state,
      recipes: null
      };
    case CLEAR_ALERT:
      return {
        ...state,
        error: null
      };
    case SAVE_GROCERY:
      return {
        ...state,
        groceryList: state.groceryList
      }
    case SAVE_GROCERY_SUCCESS:
      return {
        ...state,
        groceryList: [...state.groceryList, payload]
      };
    case SAVE_GROCERY_ERROR:
      return {
        ...state,
        saveGroceryListError: action.payload
      };
    case GET_GROCERIES:
      return {
        ...state,
        groceryListLoading: true
      };
    case GET_GROCERIES_SUCCESS:
      return {
        ...state,
        groceryList: payload,
        groceryListLoading: false
      };
    case GET_GROCERIES_ERROR:
      return {
        ...state,
        error: payload,
        groceryListLoading: false
      };
    case CLEAR_GET_GROCERIES_ERROR:
      return {
        ...state,
        saveGroceryListError: null
      };
    case DELETE_OR_UPDATE_GROCERY:
      return {
        ...state,
        groceryList: [...state.groceryList],
        groceryListItemLoading: {...state.groceryListItemLoading, id: action.id, loading: true}
      };
    case UPDATE_GROCERY_SUCCESS:
      return {
        ...state,
        groceryListItemLoading: {...state.groceryListItemLoading, id: action.id, loading: false}
      };
    case DELETE_GROCERY_SUCCESS:
      return {
        ...state,
        groceryList: state.groceryList.filter(list => list._id !==  action.id),
        groceryListItemLoading: {...state.groceryListItemLoading, id: action.id, loading: false}
      };
    case DELETE_GROCERY_ERROR:
      return {
        ...state,
        error: payload,
        groceryListItemLoading: {...state.groceryListItemLoading, id: action.id, loading: false}
      };
    case HANDLE_RECIPE_SEARCH_TERM:
      return {
        ...state,
        recipeSearchTerm: action.recipeSearchTerm
      };
    case GET_FAVORITE_RECIPES:
      return {
        ...state,
        favoriteRecipes: [...state.favoriteRecipes]
      };
    case GET_FAVORITE_RECIPES_SUCCESS:
      return {
        ...state,
        favoriteRecipes: action.payload.favoriteRecipes.reverse()
      };
    case GET_FAVORITE_RECIPES_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SAVE_RECIPE_TO_FAVORITES:
      return {
        ...state,
        favoriteRecipes: [...state.favoriteRecipes]
      };
    case SAVE_RECIPE_TO_FAVORITES_SUCCESS:
      return {
        ...state,
        favoriteRecipes: action.payload.favoriteRecipes
      };
    case SAVE_RECIPE_TO_FAVORITES_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case FETCH_FAVORITE_RECIPES_FROM_EDEMAM:
      return {
        ...state,
        favoriteRecipesFromEdemam: [...state.favoriteRecipesFromEdemam],
        favoriteRecipesFromEdemamLoading: true,
      };
    case FETCH_FAVORITE_RECIPES_FROM_EDEMAM_SUCCESS:
      return {
        ...state,
        favoriteRecipesFromEdemam: action.payload,
        favoriteRecipesFromEdemamLoading: false
      };
    case FETCH_FAVORITE_RECIPES_FROM_EDEMAM_ERROR:
      return {
        ...state,
        error: action.payload,
        favoriteRecipesFromEdemamLoading: false
      };
    default:
      return state;
  }
}

export default recipes;