import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_WORKOUT_PLAN_LIBRARY,
  GET_USER_WORKOUT_PLANS
} from "./constants";
import {
  getWorkoutPlanLibrarySuccess,
  getWorkoutPlanLibraryError,
  getUserWorkoutPlansSuccess,
  getUserWorkoutPlansError
} from "./actions";
import { fetchData } from "../api";

export function* getWorkoutPlanLibrary(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-plan/library`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let workoutPlans = yield call(fetchData, url, config);
    yield put(getWorkoutPlanLibrarySuccess(workoutPlans));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getWorkoutPlanLibraryError(error));
  }
}

export function* getUserWorkoutPlans({clientId}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-plan/user/${clientId}`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let userWorkoutPlans = yield call(fetchData, url, config);
    yield put(getUserWorkoutPlansSuccess(userWorkoutPlans));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getUserWorkoutPlansError(error));
  }
}



export default function* workoutPlanSaga() {
  yield takeLatest(GET_WORKOUT_PLAN_LIBRARY, getWorkoutPlanLibrary);
  yield takeLatest(GET_USER_WORKOUT_PLANS, getUserWorkoutPlans);
}