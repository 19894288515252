import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import Mail from '../../assets/images/mail2.svg';

const VerifyEmailMessage = () => {
  const { isMailSent } = useSelector(store => store.auth);
  const history = useHistory();

  useEffect(() => {
    if(!isMailSent) {
      history.push('/');
    }
  }, [isMailSent]);

  return(
    <div className='dashboard-content user-verification-page'>
      <div className='user-verification-card'>
        <img style={{height: '80px', marginBottom: '20px'}} src={Mail} />
        <p className='form-title'>We've sent you an activation email</p>
        <p className='main-page-subtitle'>We’ve sent an email with a link to activate your account. Follow the instructions to complete the account registration.</p>
      </div>
    </div>
  )
};

export default VerifyEmailMessage;