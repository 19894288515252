import React, { useState, useEffect } from 'react';
import { Select, Button } from 'antd';
import { questionnaire } from '../../data/questionnaire';
import { recommendations } from '../../data/recommendations';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';
import './index.css';

const { Option } = Select;

const isProduction = process.env.NODE_ENV === 'production';

function ProgramRecommendationPage({numberOfWorkouts}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ answers, setAnswers ] = useState({
    goal: 'lose', 
    equipment: 'gym', 
    help: 'workout', 
    gender: 'male', 
    age: 'thirties',
    experience: 'intermediate', 
  });

  useEffect(() => {
    if (isProduction) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }
  }, []);

  const handleChange = (e, key) => {
    setAnswers({ ...answers, [key]: e });
  }

  const renderSelectInput = (data) => {
    return(
      <div className='input-section mb-2'>
        <p className='input-label'>{data.label}</p>
        <Select 
          className='custom-select'
          value={answers[data.key]}
          bordered={false}
          size="large" 
          style={{ width: '100%' }}
          onChange={(e) => handleChange(e, data.key)}
          // open={true}
        >
          {data.options.map((option, index) => 
            <Option 
              className='custom-option'
              key={index} 
              value={option.key}
            >
              <div className='option-text' dangerouslySetInnerHTML={{ __html: option.text }} />
            </Option>
          )}
        </Select>
      </div>
    )
  }

  const generateRecommendations = () => {

    setLoading(true);

    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Generate Recommendation'
    });

    const selectedRecommendation = recommendations.find((recommendation) => {
      return (
        recommendation.goal === answers.goal &&
        recommendation.gender === answers.gender &&
        recommendation.equipment === answers.equipment &&
        recommendation.experience === answers.experience
      );
    });
    

    setTimeout(() => {
      setLoading(false);
      history.push('/program-recommendation/results', 
      { selectedRecommendation, numberOfWorkouts },
      );
    }, 2000);
  };

  const buttonStyle = {
    height: '50px',
    // width: '200px',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'all 1s ease',
  };
  
  return (
    <div>
      <div className='input-container'>
        {renderSelectInput(questionnaire['goal'])}
        {renderSelectInput(questionnaire['access_to_equipment'])}
        {/* {renderSelectInput(questionnaire['help_needed'])} */}
        {renderSelectInput(questionnaire['gender'])}
        {renderSelectInput(questionnaire['age_group'])}
        {renderSelectInput(questionnaire['experience_level'])}
        <Button 
          onClick={generateRecommendations}
          type='primary' 
          style={buttonStyle}
          className='call-to-action-btn full-width-button mb-3'
        >
          {loading ? <ReactLoading type={'balls'} color={'white'} height={'25px'} width={'25px'} /> : 'Get My Recommendation'}
        </Button>
        <p 
          onClick={() => window.open('https://www.alvarofitness.com/#contact', '_blank')}
          style={{fontSize:'14px', fontWeight: '500', cursor: 'pointer', textAlign: 'center', color: 'black'}}
        >
          I Need Private Coaching
        </p>
      </div>
    </div>
  )
}

export default withRouter(ProgramRecommendationPage);