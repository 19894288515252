import React from 'react';
import { connect } from 'react-redux';
import { TOGGLE_CREATE_WORKOUT_MODAL, TOGGLE_CREATE_EXERCISE_MODAL, TOGGLE_EXERCISE_HISTORY_MODAL } from '../../ducks/Global/constants';
import { getLibraryWorkouts } from '../../ducks/Workout/actions';
import { getExercises, getExerciseCategories, getMuscles } from '../../ducks/Exercise/actions';
import AdminWorkoutLibraryPage from '../../presentational/AdminWorkoutLibrary';
import { deleteLibraryWorkout } from '../../ducks/Workout/actions';
import { SET_IS_WORKOUT_EDIT } from '../../ducks/Workout/constants';
import { Loading } from '../../components/Loading';

class AdminWorkoutLibrary extends React.Component {

  componentDidMount(){
    this.props.workouts.length === 0 && this.props.getLibraryWorkouts();
    this.props.exercises.length === 0 && this.props.getExercises();
    !this.props.exerciseCategories && this.props.getExerciseCategories();
    this.props.muscles.length === 0 && this.props.getMuscles();
  }

  render(){
    const { getWorkoutsLoading } = this.props;
    return(
     <div className='workouts-page'>
      {!getWorkoutsLoading ?
      <AdminWorkoutLibraryPage {...this.props} /> :
      <Loading />}
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    workouts: state.workouts.workouts,
    exercises: state.exercises.exercises,
    deleteWorkoutLoading: state.workouts.deleteWorkoutLoading,
    highestLoadSet: state.exercises.highestLoadSet,
    exerciseHistoryModalVisible: state.global.exerciseHistoryModalVisible,
    getWorkoutsLoading: state.workouts.getWorkoutsLoading,
    exerciseCategories: state.exercises.exerciseCategories,
    muscles: state.exercises.muscles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLibraryWorkouts: () => dispatch(getLibraryWorkouts()),
    deleteLibraryWorkout: (id) => dispatch(deleteLibraryWorkout(id)),
    getExercises: () => dispatch(getExercises()),
    // deleteExercise: (id) => dispatch(deleteExercise(id)),
    setIsWorkoutEdit: (value) => dispatch({
      type: SET_IS_WORKOUT_EDIT,
      value
    }),
    toggleCreateExerciseModal: (value) => dispatch({
      type: TOGGLE_CREATE_EXERCISE_MODAL,
      value
    }),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleExerciseHistoryModal: (value) => dispatch({
      type: TOGGLE_EXERCISE_HISTORY_MODAL,
      value
    }),
    getExerciseCategories: () => dispatch(getExerciseCategories()),
    getMuscles: () => dispatch(getMuscles())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminWorkoutLibrary);