import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Progress, Modal, Button, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { sendResultsToEmail } from "../../ducks/Assessment/actions";
import { showAuthModal } from "../../ducks/Auth/actions";
import { saveNutritionLocal } from "../../ducks/Profile/actions";
import { validateEmail } from "../../helpers/utils";
import "./index.css";

class AssessmentResults extends React.Component {
  constructor() {
    super();
    this.state = {
      resultCardInfo: null,
      programs: [
        {
          title: "1-on-1 Elite Online Personal Training",
          subtitle:
            "The most personalized and results driven training program you can do at home or at the gym.",
          description: [
            {
              backgroundImage: "",
              duration: "4, 8, or 12 weeks",
              equipment: "",
            },
          ],
        },
      ],
      modalVisible: false,
      emailValue: "",
      validEmail: true,
      emailError: "",
      alertCalorieModal: false,
    };
  }

  componentDidMount() {
    if (
      this.props.assessmentResults &&
      this.props.assessmentResults[0].goalCalories < 1200
    ) {
      this.setState({
        alertCalorieModal: true,
      });
    }

    // this.props.showAuthModal({
    //   authRedirectRoute: "/dashboard/home",
    // });

    if (this.props.assessmentResults && this.props.assessmentResults[0]) {
      const assessmentResults = this.props.assessmentResults[0];
      this.props.saveNutritionLocal(assessmentResults);
    }
  }

  handleSubmit = () => {
    if (this.state.validEmail === true) {
      this.props.sendResultsToEmail(
        this.props.macros,
        this.props.goalCalories,
        this.props.responseObj,
        "alvarodltp@gmail.com"
      );
      this.setState({ emailError: "", modalVisible: false });
    } else {
      this.setState({ emailError: "Please enter a valid email." });
    }
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  componentWillMount() {
    if (!this.props.assessmentResults) {
      this.props.history.push("/assessment");
    } else {
      this.setState({
        resultCardInfo: [
          // {
          //   title: 'Summary',
          //   subtitle: 'The Mifflin St. Jeor equation was used to calculate your BMR (Basal metabolic rate). The number of calories to achieve your goal was calculated based on your activity level, and your macronutrient needs based on your body type.'
          // },
          {
            title: "Summary",
            subtitle: this.renderCalorieParagraph(),
          },
          // {
          //   title: 'Protein, Carbs, and Fats',
          //   subtitle: this.renderMacrosParagraph(),
          // },
          {
            title: "What's next",
            subtitle: this.renderMoreInfo(),
          },
        ],
      });
    }
  }

  renderMoreInfo = () => {
    return (
      <div>
        <p className="text">
          Ready to put your numbers into action? Get in touch and I'll will
          guide you through the next steps.
        </p>
        <Link to="/contact">
          <Button type="primary" style={{ marginTop: "10px" }}>
            Get in touch
          </Button>
        </Link>
      </div>
    );
  };

  renderStat = (macro, label) => {
    return (
      <div>
        <p style={{ fontWeight: "700" }}>{macro}g</p>
        <p className="blog-card-category macro-label">{label}</p>
      </div>
    );
  };

  renderCalorie = () => {
    return (
      <div>
        <p style={{ fontWeight: "700" }}>
          {this.props.assessmentResults[0].goalCalories}
        </p>
        <p className="blog-card-category macro-label">calories</p>
      </div>
    );
  };

  renderCalorieParagraph = () => {
    const { responseObj, assessmentResults } = this.props;
    if (
      responseObj.goal.text === "Lose weight" ||
      responseObj.goal.text === "Build muscle"
    ) {
      return (
        <p className="text">{`Based on your current weight, and activity level, you need ${assessmentResults[0].goalCalories} calories daily to reach your goal of ${responseObj.goalWeight.value} lbs in ${assessmentResults[0].days} days. Note that results are better achieved with a comprehensive program which includes resistance training, cardiovascular activity, among others.`}</p>
      );
    } else {
      return (
        <p className="text">{`Based on your current weight, and activity level, you need ${assessmentResults[0].goalCalories} calories daily to reach your goal of ${responseObj.goal.goal}. Note that results are better achieved with a comprehensive program which includes resistance training, cardiovascular activity, among others.`}</p>
      );
    }
  };

  renderMacrosParagraph = () => {
    const { responseObj } = this.props;
    return (
      <p></p>
      // <p>Your macronutrient split for your body type has been set to {responseObj.bodyType.protein}% of calories coming from protein, {responseObj.bodyType.carbs}% of calories coming from carbs, and {responseObj.bodyType.fats}% of calories coming from fats for optimal results.</p>
    );
  };

  renderMacros = () => {
    return (
      <React.Fragment>
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          percent={
            (this.props.assessmentResults[0].protein.grams * 4 * 100) /
            this.props.assessmentResults[0].goalCalories
          }
          format={() =>
            this.renderStat(
              this.props.assessmentResults[0].protein.grams,
              "protein"
            )
          }
        />
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          percent={
            (this.props.assessmentResults[0].carbs.grams * 4 * 100) /
            this.props.assessmentResults[0].goalCalories
          }
          format={() =>
            this.renderStat(
              this.props.assessmentResults[0].carbs.grams,
              "carbs"
            )
          }
        />
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          percent={
            (this.props.assessmentResults[0].fats.grams * 4 * 100) /
            this.props.assessmentResults[0].goalCalories
          }
          format={() =>
            this.renderStat(this.props.assessmentResults[0].fats.grams, "fat")
          }
        />
      </React.Fragment>
    );
  };

  handleChange = (e) => {
    const validEmail = validateEmail(e.target.value);
    this.setState({ emailValue: e.target.value, validEmail: validEmail });
  };

  calorieAlertModal = () => {
    return (
      <Modal
        visible={
          this.props.responseObj &&
          this.props.responseObj.hasOwnProperty("goalWeight") &&
          this.state.alertCalorieModal
        }
        onOk={() => this.setState({ alertCalorieModal: false })}
        closable={false}
        centered
        footer={[
          <Button
            type="primary"
            onClick={() => this.setState({ alertCalorieModal: false })}
          >
            Got it
          </Button>,
        ]}
      >
        <div className="modal-content">
          <ExclamationCircleOutlined className="danger-icon" />
          <p>{`Your goal requires a number of calories which goes under my recommended minimum of 1200 calories daily. Try giving yourself more time, or reduce the amount of weight for the chosen date. If you ignore this advice, you should do so under the supervision of a physician.`}</p>
        </div>
      </Modal>
    );
  };

  render() {
    return (
      this.props.assessmentResults && (
        <div className="content assessment-results">
          {this.calorieAlertModal()}
          <div className="training-header-title">
            <h1 className="large-header-title" style={{ marginBottom: "0px" }}>
              Your Results
            </h1>
          </div>
          <Modal
            title="Where should we send your results?"
            visible={this.state.modalVisible}
            onOk={this.handleOk}
            onCancel={() => this.setState({ modalVisible: false })}
            centered
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => this.handleSubmit()}
              >
                Send Results
              </Button>,
            ]}
          >
            <Input
              placeholder={"Email..."}
              onChange={(e) => this.handleChange(e)}
              value={this.state.emailValue}
            />
            {this.state.emailError && (
              <p style={{ fontSize: "12px", color: "red" }}>
                {this.state.emailError}
              </p>
            )}
          </Modal>
          <div className="results-info">
            <Progress
              type="circle"
              strokeColor={{
                '0%': '#2761f1', 
                '100%': '#FF0066',
              }}
              percent={100}
              format={() => this.renderCalorie()}
              // width={80}
            />
            {this.renderMacros()}
          </div>
          <div className="results-cards">
            {this.state.resultCardInfo &&
              this.state.resultCardInfo.map((info) => (
                <div className="card">
                  <p className="blog-card-category">{info.title}</p>
                  {info.subtitle}
                </div>
              ))}
          </div>
          {/* <div className='next-steps'>
          <div className='result-page-programs'>
            {this.state.programs.map(program => 
            <div className='program' style={{backgroundImage: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5))`}}>
              <div>
                <h1 className='title'>{program.title}</h1>
                <p className='subtitle'>{program.subtitle}</p>
              </div>
            </div>)}
          </div>
        </div> */}
        </div>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendResultsToEmail: (macros, goalCalorie, responses, email) =>
      dispatch(sendResultsToEmail(macros, goalCalorie, responses, email)),
    showAuthModal: (payload) => dispatch(showAuthModal(payload)),
    saveNutritionLocal: (nutrition) => dispatch(saveNutritionLocal(nutrition)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    assessmentResults: state.assessment.assessmentResults,
    responseObj: state.assessment.responseObj,
    sendEmailLoading: state.assessment.sendEmailLoading,
    sendResponse: state.assessment.sendResponse,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssessmentResults));
