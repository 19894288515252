import React from 'react';
import { Redirect } from 'react-router-dom';
import { Header } from './header';
import { HowItWorks } from './howItWorks';
import { Features } from './features';
import { Pricing } from './pricing';
import { Testimonials } from './testimonials';
// import { Faq } from './features';
import './index.css';

export const LandingPage = () => {
 
  return (
    <Redirect to='/login' />
    // <div className='landing'>
    //   <Header />
    //   <HowItWorks />
    //   <Features />
    //   {/* <WhyNubod /> */}
    //   <Testimonials />
    //   <Pricing />
    //   {/* <Faq /> */}
    // </div>
  )
}

export default LandingPage;