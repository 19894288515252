import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const AssessmentLoader = (props) => {
    return (
        <>
            <div className="fixed-progress-bar">
                <div className="back text-left">
                    <Skeleton width='10%' />
                </div>
                <Skeleton height={10} />
            </div>
            <div className="assessment-questions">
                <p className='assessment-question-title'>
                    <Skeleton height={30} />
                </p>
                <Skeleton height={300} />
                <div className="btn-wrap ">
                    <div className='flex space-between next-prev-btn'>
                        <Skeleton width={120} height={40} />
                        <Skeleton width={120} height={40} />
                    </div>
                </div>
            </div>
        </>
    );
};
