import React from 'react';
import { connect } from 'react-redux';
import { Modal, Tabs, Divider, Tag } from 'antd';
import moment from 'moment';
import ExerciseHistoryChart from './ExerciseHistoryChart';
import './index.css';
import Skeleton from 'react-loading-skeleton';
import { OneRepMaxTable } from './OneRepMaxTable';
import { formatNumber } from '../../helpers/utils';
const { TabPane } = Tabs;

class ExerciseHistoryModal extends React.Component {
  state = {
    activeTab: '1',
    highestOneRepMax: 0,
    highestOneRepMaxDate: null,
    oneRepMaxLoad: 0,
    oneRepMaxReps: 0
  }

  componentDidMount() {
    this.updateOneRepMaxState();
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.allExerciseHistory !== prevProps.allExerciseHistory) {
      this.updateOneRepMaxState();
    }
  }

  renderHistoryInfo = () => {
    const { currentExercise } = this.props;
  
    const gradientStyle = {
      background: 'linear-gradient(to right, #2761f1 0%, #FF0066 100%)',
      color: 'white', 
      fontWeight: '500',
      border: 'none'
    };
  
    return (
     <>
        <div className='history-info-container'>
          <p className='exercise-name-history'>{currentExercise?.name ? currentExercise.name : currentExercise?.exerciseId && currentExercise.exerciseId.name}</p>
          <Divider style={{margin: '15px'}}/>
          {/* {currentExercise.categories?.map(category => <Tag className='mt-1' style={gradientStyle}>{category.name}</Tag>)} */}
          <div className='history-info'>
            <div className='history-info-item'>
              <div className='big-bold-text'>{this.calculateTotalSets()}</div>
              <div className='blog-card-category stats mb-1'>Total Sets</div>
            </div>
            <div className='history-info-item'>
              <div className='big-bold-text'>{this.calculateTotalReps()}</div>
              <div className='blog-card-category stats mb-1'>Total Reps</div>
            </div>
            <div className='history-info-item'>
              <div className='big-bold-text'>{this.getHighestVolume()}</div>
              <div className='blog-card-category stats mb-1'>Highest Volume</div>
            </div>
            <div className='history-info-item'>
              <div className='big-bold-text'>{this.returnHighestLoadSet()}</div>
              <div className='blog-card-category stats mb-1'>{this.returnHighestLoadSetDate()}</div>
            </div>
          </div>
        </div>
        <div className='history-info'>
          <div className='history-info-item'>
            <div className='big-bold-text'>{formatNumber(this.state.highestOneRepMax)}</div>
            <div className='blog-card-category stats mb-1' style={{textAlign: 'center'}}>1 Rep Max <br/> Estimated for {this.state.highestOneRepMaxDate}</div>
          </div>
        </div>
      </>
    )
  }

  calculateTotalReps = () => {
    const { allExerciseHistory } = this.props; 
    let totalReps = 0;
    allExerciseHistory.history.forEach(history => {
     history.sets.forEach(set => {
        totalReps += set.reps;
      })
    })
    return totalReps;
  }

  getHighestVolume = () => {
    const { allExerciseHistory } = this.props;
    let highestVolume = 0;
    allExerciseHistory.history.forEach(history => {
      let volume = history.sets.reduce((acc, set) => acc + set.reps * set.load, 0);
      if(volume > highestVolume){
        highestVolume = volume;
      }
    })
    return highestVolume;
  }

  returnHighestLoadSetDate = () => {
    const { highestLoadSet } = this.props.allExerciseHistory;
    return highestLoadSet ? (
      <p className='blog-card-category stats'>PR - {moment(highestLoadSet.date).format('MM/DD/YYYY')}</p>
    ) : 'N/A';
  }

  returnHighestLoadSet = () => {
    const { highestLoadSet } = this.props.allExerciseHistory;
    return highestLoadSet ? `${highestLoadSet.load}` : 'N/A';
  }

  calculateTotalSets = () => {
    const { allExerciseHistory } = this.props;
    let totalSets = 0;
    allExerciseHistory.history.forEach(history => {
      totalSets += history.sets.length;
    })
    return totalSets;
  }

  calculateOneRepMax = (weight, reps) => {
    const oneRepMax = weight / (1.0278 - (0.0278 * reps));
    return oneRepMax;
  };

  // calculateEstimatedOneRepMax = () => {
  //   const { allExerciseHistory } = this.props;
  //   let highestOneRepMax = 0;
  //   let highestOneRepMaxDate = null;
  //   let highestLoad = 0;
  //   let highestReps = 0;
  
  //   allExerciseHistory && allExerciseHistory.history.forEach(history => {
  //     history.sets.forEach(set => {
  //       if (set.reps > 1) {
  //         const oneRepMax = this.calculateOneRepMax(set.load, set.reps);
  //         if (oneRepMax > highestOneRepMax) {
  //           highestOneRepMax = oneRepMax;
  //           highestOneRepMaxDate = new Date(history.date).toLocaleDateString();
  //           highestLoad = set.load;
  //           highestReps = set.reps;
  //         }
  //       }
  //     });
  //   });
  
  //   return { highestOneRepMax, highestOneRepMaxDate, highestLoad, highestReps };
  // };

  calculateEstimatedOneRepMax = () => {
    const { allExerciseHistory } = this.props;
    let highestOneRepMax = 0;
    let highestOneRepMaxDate = null;
    let highestLoad = 0;
    let lowestReps = Number.MAX_SAFE_INTEGER;
  
    allExerciseHistory?.history.forEach(history => {
      history.sets.forEach(set => {
        if (set.load > highestLoad || (set.load === highestLoad && set.reps < lowestReps)) {
          const oneRepMax = this.calculateOneRepMax(set.load, set.reps);
          highestOneRepMax = oneRepMax;
          highestLoad = set.load;
          lowestReps = set.reps;
          highestOneRepMaxDate = new Date(history.date).toLocaleDateString();
        }
      });
    });
  
    return { highestOneRepMax, highestLoad, lowestReps, highestOneRepMaxDate };
  };

  updateOneRepMaxState = () => {
    const oneRepMaxData = this.calculateEstimatedOneRepMax();
    this.setState({
      highestOneRepMax: oneRepMaxData.highestOneRepMax,
      highestOneRepMaxDate: oneRepMaxData.highestOneRepMaxDate,
      oneRepMaxLoad: oneRepMaxData.highestLoad,
      oneRepMaxReps: oneRepMaxData.lowestReps
    });
  };

  renderSetHistory = (allExerciseHistory) => {
    const { history } = allExerciseHistory;
    return (
      <div className='set-history-container'>
        {history.slice().reverse().map(historyItem => {
          return (
            <div>
              <div className='set-history-date'>{moment(historyItem.date).format('MM/DD/YYYY')}</div>
              <table className='set-history'>
                <thead>
                  <tr>
                    <th className='home-card-text'>Set</th>
                    <th className='home-card-text'>Reps</th>
                    <th className='home-card-text'>Load</th>
                    <th className='home-card-text'>RIR</th>
                  </tr>
                </thead>
                <tbody>
                  {historyItem.sets.map((set, index) => {
                    return (
                      <tr key={index}>
                        <td className='home-card-text'>{index + 1}</td>
                        <td className='home-card-text'>{set.reps}</td>
                        <td className='home-card-text'>{set.load}</td>
                        <td className='home-card-text'>{set.rir}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
      </div>
    )
  }

  changeTab = (key) => {
    this.setState({activeTab: key})
  }
  
  renderTabsAndContent = (allExerciseHistory, exerciseHistoryLoading) => {
    return (
      <Tabs className='exercise-history-tabs' animated={false} defaultActiveKey={this.state.activeTab} onChange={this.changeTab}>
        <TabPane tab="Volume" key="1">
          <div className='exercise-history-chart'>
            {allExerciseHistory && typeof exerciseHistoryLoading !== 'object' && !exerciseHistoryLoading ?
            <ExerciseHistoryChart 
              allExerciseHistory={allExerciseHistory}
            /> 
            : <Skeleton height={'330px'}/> 
           }
          </div>
        </TabPane>
        <TabPane tab="Sets" key="2">
          {this.renderSetHistory(allExerciseHistory)}
        </TabPane>
        <TabPane tab="1RM" key="3">
          <OneRepMaxTable 
            oneRepMaxLoad={this.state.oneRepMaxLoad} 
            oneRepMaxReps={this.state.oneRepMaxReps} 
          />
        </TabPane>
      </Tabs>
    )
  }

  render(){
    const { allExerciseHistory, exerciseHistoryModalVisible, exerciseHistoryLoading } = this.props;
    //exerciseHistoryLoading could be an object for single exercise history so checking type first
    return (
      <Modal
        title={''}
        width={'800px'}
        visible={exerciseHistoryModalVisible}
        onCancel={() => this.props.toggleExerciseHistoryModal(false)}
        footer={null}
        centered={true}
        className='create-workout-modal'
      >
        {allExerciseHistory ? (
          <>
            {this.renderHistoryInfo(allExerciseHistory)}
            {this.renderTabsAndContent(allExerciseHistory, exerciseHistoryLoading)}
          </>
        ) : (
          <p className='home-card-text'>There isn't exercise history for this exercise yet.</p>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    allExerciseHistory: state.exercises.allExerciseHistory,
    exerciseHistoryLoading: state.exercises.exerciseHistoryLoading,
    exerciseHistoryError: state.exercises.exerciseHistoryError
  }
}

export default connect(mapStateToProps, null)(ExerciseHistoryModal);