import React from 'react';
import moment from 'moment';
import { Divider, Progress, Tooltip } from 'antd';
import { ExclamationOutlined, CheckOutlined } from '@ant-design/icons';

class FoodLogCard extends React.Component {

  renderLog = () => {
    let newObject= {
      'Calories': this.props.data.info.summary.calories,
      'Protein': `${this.props.data.info.summary.protein.toFixed(1)}g`,
      'Carbs': `${this.props.data.info.summary.carbs.toFixed(1)}g`,
      'Fats': `${this.props.data.info.summary.fat.toFixed(1)}g`
  };
   return Object.entries(newObject).map(([key, value]) => {
      return(
        <div>
          <p className='macro-number' style={{fontSize: '16px', lineHeight: '16px'}}>{value}</p>
          <p className='macro-label-bottom'>{key}</p>
        </div>
      )
    })
  }

  renderUserNutrition = () => {
    let newObject= {
      'Calories': this.props.data.userNutrition[0].goalCalories,
      'Protein': `${this.props.data.userNutrition[0].protein.grams.toFixed(1)}g`,
      'Carbs': `${this.props.data.userNutrition[0].carbs.grams.toFixed(1)}g`,
      'Fats': `${this.props.data.userNutrition[0].fats.grams.toFixed(1)}g`
    };
    return Object.entries(newObject).map(([key, value]) => {
      return(
        <div>
          <p className='macro-number' style={{fontSize: '16px', lineHeight: '16px'}}>{value}</p>
          <p className='macro-label-bottom'>{key}</p>
        </div>
      )
    })
  }

  renderCompliance = () => {
    let proteinCaloriesGoal = Number(this.props.data.userNutrition[0].protein.grams) * 4;
    let carbsCaloriesGoal = Number(this.props.data.userNutrition[0].carbs.grams) * 4;
    let fatCaloriesGoal = Number(this.props.data.userNutrition[0].fats.grams) * 9;
    let totalCaloriesGoal = proteinCaloriesGoal + carbsCaloriesGoal + fatCaloriesGoal;

    let proteinCalories = Number(this.props.data.info.summary.protein) * 4;
    let carbsCalories = Number(this.props.data.info.summary.carbs) * 4;
    let fatCalories = Number(this.props.data.info.summary.fat) * 9;
    let totalCaloriesFitbit = proteinCalories + carbsCalories + fatCalories;

    let average =  Math.round(Number((totalCaloriesFitbit * 100) / totalCaloriesGoal));
    
    let message;
    let color;
    if(average >= 95 && average <= 105) {
      message = `Great job, your compliance is excellent! - ${Number(average)}%`;
      color = '#2bffcc';
    } else if(average > 105){
      message = `You went over your goal numbers by ${Number(average) - 100}%.`
      color = 'rgb(255, 178, 41)';
      
    } else {
      color = '#2761f1'
    }

    const renderLabel = () => {
      if(average > 105){
        return <ExclamationOutlined style={{color: 'rgb(255, 178, 41)', fontSize: '20px'}}/>; 
      } else if(average >= 95 && average <= 105){
        return <CheckOutlined style={{color: 'rgb(40, 239, 182)', fontSize: '20px'}}/>;
      } else {
        return `${average}%`;
      }
    }

    return (
      <Tooltip title={message}>
        <Progress
          className='compliance-progress-circle'
          type="circle"
          percent={average} 
          strokeWidth={10}
          strokeColor={color}
          format={() => renderLabel()}
        />
      </Tooltip>
    )
  }

  render(){
    const { data } = this.props;
    return(
      <div className='food-log-card'>
        <div className='log-card-content'>
          <div className='flex flex-center-aligned space-between'>
            <p className='home-card-text'>{moment(data.date).format('ll')}</p>
            {this.renderCompliance()}
          </div>
          <Divider/>
          <div className='macro-content flex'>
            <p className='blog-card-category'>Goal</p>
            <div className='food-log-row'>
              {this.renderUserNutrition()}
            </div>
          </div>
          <Divider/>
         <div className='macro-content flex'>
          <p className='blog-card-category'>Fitbit</p>
            <div className='food-log-row'>
              {this.renderLog()}
            </div> 
         </div>
        </div>
      </div>
    )
  }
}

export default FoodLogCard;