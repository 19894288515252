import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  SET_DEVICE_ID
} from "./constants";

const initialState = {
  deviceId: null,
  userNotifications: [],
  notificationLoading: false,
  error: null
};

function onesignal(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_DEVICE_ID: {
      return {
        ...state,
        deviceId: payload,
      };
    }

    case GET_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notificationLoading: true,
      };
    }

    case GET_ALL_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        userNotifications: payload,
        notificationLoading: false,
      };
    }

    case GET_ALL_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        error: payload,
        notificationLoading: false,
      };
    }

    default:
      return { ...state };
  }
};

export default onesignal;
