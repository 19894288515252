import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { RiTimerLine } from "react-icons/ri";
import './index.css';

export const CountdownTimer = ({ duration }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [timerOn, setTimerOn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    setTimeLeft(duration);
  }, [duration]);

  const handleReset = () => {
    setTimerOn(false);
    setTimeLeft(duration);
    clearInterval(intervalRef.current);
  };

  const handlePlayPause = () => {
    if (!timerOn) {
      setTimerOn(true);
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(intervalRef.current);
            setModalVisible(true);
            setTimeout(() => {
              setModalVisible(false);
              handleReset();
            }, 3000);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    } else {
      setTimerOn(false);
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (modalVisible === false && timeLeft === 0) {
      handleReset();
    }
  }, [modalVisible]);

  return (
    <>
      <div
        className="add-exercise-button"
        onClick={handlePlayPause}
        onDoubleClick={handleReset}
      >
        {!timerOn && timeLeft === duration ? (
          <RiTimerLine className='icon' />
        ) : (
          <div className="icon home-card-text">
            {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
          </div>
        )}
      </div>
      <Modal
        visible={modalVisible}
        centered
        footer={null}
        onCancel={() => setModalVisible(false)}
        className="timer-modal"
        closable={false}
      >
        <div className="modal-content">
          <i className="modal-icon fas fa-clock fa-5x"></i>
          <h2 className="modal-title">Time's Up!</h2>
        </div>
      </Modal>
    </>
  );
};