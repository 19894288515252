import { all } from 'redux-saga/effects';
import blogSaga from './ducks/Blog/saga';
import assessmentSaga from './ducks/Assessment/saga';
import globalSaga from './ducks/Global/saga';
import authSaga from './ducks/Auth/saga';
import profileSaga from './ducks/Profile/saga';
import recipeSaga from './ducks/Recipe/saga';
import integrationsSaga from './ducks/Integrations/saga';
import measurementsSaga from './ducks/Measurements/saga';
import tableSaga from './ducks/Table/saga';
import adminSaga from './ducks/Admin/saga';
import suggesticSaga from './ducks/Suggestic/saga';
import settingsSaga from './ducks/Setting/saga';
import onesignalSaga from "./ducks/Onesignal/saga";
import todoSaga from "./ducks/Todo/saga";
import nutritionPreferenceSaga from "./ducks/NutritionPreference/saga"
import guidesSaga from "./ducks/Guide/saga"
import exercisesSaga from "./ducks/Exercise/saga";
import workoutsSaga from "./ducks/Workout/saga";
import workoutProgramSaga from './ducks/WorkoutProgram/saga';
import workoutPlanSaga from './ducks/WorkoutPlan/saga';
import carbCyclingSaga from "./ducks/CarbCycling/saga";

export default function* rootSaga() {
  yield all([
    blogSaga(),
    assessmentSaga(),
    globalSaga(),
    authSaga(),
    profileSaga(),
    recipeSaga(),
    integrationsSaga(),
    measurementsSaga(),
    tableSaga(),
    adminSaga(),
    onesignalSaga(),
    suggesticSaga(),
    settingsSaga(),
    todoSaga(),
    nutritionPreferenceSaga(),
    guidesSaga(),
    exercisesSaga(),
    workoutsSaga(),
    workoutProgramSaga(),
    workoutPlanSaga(),
    carbCyclingSaga()
  ])
}