import React from 'react';
import { Modal, Button } from 'antd';
import './index.css';
import { loadStripe } from '@stripe/stripe-js';
import { BiCheck } from "react-icons/bi";
import './index.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const PricingModal = ({profile, pricingModalOpen, handlePricingModal}) => {
  const plans = [
    {
      name: 'Free',
      price: {amount: '0', label: '', type: '', billed: '/month'},
      description: 'Nubod\'s advanced Macronutrient calculator, free forever.',
      includes: [
        'Unlimited macro calculations', 
        'Macronutrient result history',
        'Fitbit food log integration and compliance tracking'
      ],
      buttonText: 'Current Plan',
      action: () => null,
      color: '#0065ff'
    },
    {
      name: 'Nubod Pro-Coaching',
      className: 'pro-coach',
      price: {amount: '297.00', label: '', type: '', billed: '/month'},
      description: 'A 1-on-1 personalized and adaptable coaching experience to tackle your toughest fitness goals.',
      plus: 'All of Free plus:',
      includes: [
        'A personalized and adaptable program',
        'Unlimited updates to your program so you never plateau',
        'Personal guidance any time',
        'Chat with your coach',
        'Record and keep track of your progress',
        'Access to our smart shopping list',
        'Recipe database access powered by Edemam',
        // 'Private support group'
      ],
      buttonText: 'Sign Up Now',
      color: '#FF0066',
      action: () => async () => {
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            { price: process.env.REACT_APP_PRO_COACHING, quantity: 1 }
          ],
          clientReferenceId: profile.user._id,
          mode: 'subscription',
          successUrl: `${process.env.REACT_APP_APP_URL}/dashboard/home`,
          cancelUrl: `${process.env.REACT_APP_APP_URL}/dashboard/settings/billing`,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        console.log(error);
      }
    }
  ]
  return (
    <Modal
      className='pricing-modal dashboard-content'
      centered
      visible={pricingModalOpen}
      onCancel={() => handlePricingModal(false)}
      footer={null}
      closable={true}
      maskClosable={false}
      width='auto'
    >
      <div className='pricing-modal-container'>
        {plans.map(plan => 
          <div className='pricing-card flex flex-column'>
            <div className='modal-content'>
              <p className={`plan-name ${plan.className}`}>{plan.name}</p>
              <p className='form-subtitle'>{plan.description}</p>
              <div className='pricing'>
                <p style={{marginBottom: '20px'}} className='macro-label-bottom'>{plan.price.label}</p>
                <div className='flex'>
                  {plan.name !== '1-on-1 Coaching' &&
                  <p style={{fontSize: '16px', fontWeight: '600', marginRight: '5px'}}>$</p>}
                  <p className='plan-amount'>{plan.price.amount}</p>
                  <p className='plan-billed'>{plan.price.billed}</p>
                </div>
              </div>
              <p className='form-subtitle'>{plan.plus}</p>
              <div className='plan-includes'>
                {plan.includes.map(include => 
                <div className='list'>
                  <BiCheck style={{fontSize: '15px', color: plan.color}} />
                  <p className='plan-include-item'>{include}</p>
                </div>)}
              </div>
            </div>
            <Button 
              type='primary' 
              className={`plan-button ${plan.className}`}
              onClick={plan.action()}
            >
              {plan.buttonText}
            </Button>
          </div>)}
      </div>
    </Modal>
  )
}