import React from "react";
import { connect } from 'react-redux';
import ManualMealPlanPage from "../ManualMealPlanPage";
import { SET_RECIPE_QUERY_OBJ } from '../../ducks/Global/constants';
import { deleteCustomMealPlan } from '../../ducks/Admin/actions';
import { SplashAdvanced } from '../../presentational/SplashPage/splashAdvanced';
import "./index.css";

class CustomMealPlanPage extends React.Component {
  state = { nutritionHomeData: [] };

  componentDidMount() {
    const { nutrition, allUserNutrition } = this.props;
    let finalNutrition = nutrition || (allUserNutrition && allUserNutrition[0]);
    if (nutrition && nutrition.nutritionResults) {
      finalNutrition = nutrition.nutritionResults;
    }
    if(finalNutrition) {
      let nutritionHomeData = [
        { key: 1, value: finalNutrition.goalCalories },
        { key: 2, value: finalNutrition.protein },
        { key: 3, value: finalNutrition.carbs },
        { key: 4, value: finalNutrition.fats },
      ];
      this.setState({
        nutritionHomeData: nutritionHomeData ,
      })
    }
  }

  render() {
    const { profile } = this.props;
    return (
      profile && profile.customMealPlan && profile.user.isPro ? 
      <ManualMealPlanPage {...this.props} /> :
      profile && profile.user.isPro ?
      <div style={{backgroundColor: '#e4e7eb', borderRadius: '10px'}}>
         <p className='home-card-text' style={{padding: '22px'}}>Your plan will display here when ready.</p>
      </div> :
      <SplashAdvanced page={'programLanding'} profile={profile} pricingModalOpen={this.props.pricingModalOpen} handlePricingModal={this.props.handlePricingModal} />
    );
  }
}

const mapStateToProps = state => {
  return {
    updateFoodLoading: state.profile.updateFoodLoading,
    loading: state.profile.loading,
    customMealPlan: state.profile.profile ? state.profile.profile.customMealPlan : state.admin.userMealplan.customMealPlan,
    userMealplan: state.admin.userMealplan,
    nutrition: state.profile.nutrition || state.nutrition,
    allUserNutrition: state.admin.allUserNutrition,
    recipesLoading: state.recipes.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRecipeQueryObj: (recipeQueryObj, meal) => {
      return dispatch({
        type: SET_RECIPE_QUERY_OBJ,
        recipeQueryObj,
        meal
      });
		},
    deleteCustomMealPlan: (mealPlanId) => dispatch(deleteCustomMealPlan(mealPlanId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMealPlanPage);