import React from 'react';
import { connect } from 'react-redux'; 
import { getUserShoppingListAggregate, getShoppingListSug } from "../../ducks/Suggestic/actions";
import SuggesticShoppingPage from '../../presentational/SuggesticShoppingPage';
import { toggleShoppingListItem } from '../../ducks/Suggestic/actions';
import { Loading } from '../../components/Loading';
import EmptyShopping from '../../assets/images/empty-cart.svg';

class SuggesticShopping extends React.Component {
  state={activeTab: "1"};

  componentDidMount(){
    this.props.getShoppingList();
    this.props.getUserShoppingListAggregate();
  }

  changeActiveTab = (tab) => {
    this.setState({activeTab: tab});
    this.props.getShoppingList();
  }

  renderSplash = () => {
    return(
      <div style={{padding: '50px 0 50px 0'}} className='flex flex-column flex-center-aligned'>
        <img src={EmptyShopping} alt="Empty" style={{height: '200px', marginBottom: '15px'}}/>
        <p>Your shopping list is empty.</p>
      </div>
    )
  }

  renderShoppingPage = () => {
    const { shoppingList, shoppingListLoading } = this.props;
    if(shoppingListLoading){
      return <Loading />;
    } else if(!shoppingListLoading && shoppingList && shoppingList.edges.length > 0){
      return <SuggesticShoppingPage {...this.props} activeTab={this.state.activeTab} changeActiveTab={this.changeActiveTab} />;
    } else if(!shoppingList || shoppingList && shoppingList.edges.length === 0){
      return this.renderSplash();
    }
  }

  render(){
    return(
      <div className='site-layout-background shopping-list sub-menu'>
        {this.renderShoppingPage()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shoppingList: state.suggestic.shoppingListSug,
    shoppingListLoading: state.suggestic.shoppingListLoadingSug,
    shoppingListAggregate: state.suggestic.shoppingListAggregate,
    shoppingListAggregateLoading: state.suggestic.shoppingListAggregateLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getShoppingList: () => dispatch(getShoppingListSug()),
    getUserShoppingListAggregate: () => dispatch(getUserShoppingListAggregate()),
    toggleShoppingListItem: (itemId, isAggregate) => dispatch(toggleShoppingListItem(itemId, isAggregate))
  };  
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggesticShopping);