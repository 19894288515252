import React from 'react'
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';


const AdminRoute = ({ component: Component, auth: {isAuthenticated, loading, user}, ...rest }) => {
  return  (
    <Route 
      {...rest} 
      render={props => !isAuthenticated && !loading || isAuthenticated && user.role !== 'admin' ? 
      window.location.href = '/' : 
      <Component {...props} />} 
    />
  )
}
 
const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AdminRoute);