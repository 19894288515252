import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveProfileIgnoreNutrition,
  updateAssessmentProfile,
} from "../../ducks/Profile/actions";
import { updateClientMacros } from "../../ducks/Admin/actions";
import { LocationSearchInput } from "../LocationSearchInput";
import {
  Input,
  Select,
  Divider,
  Alert,
  DatePicker,
  Button,
  Slider,
  Checkbox,
} from "antd";
import moment from "moment";
import {
  formatPhoneNumber,
  cmToFeet,
  feetToCm,
  kgsToPounds,
  poundsToKgs,
  getFoodPreferenceOptions,
  getMacroOptions,
  getMacroLabelFromMacroDivision,
  getMacrosWithPreferenceAndOptionsName,
  getMealsPerDayOptions,
  getWorkoutLocationOptions,
  getSelectOptions,
  getSelectedOption,
} from "../../helpers/utils";
import { SET_NUTRITION_FROM_PROFILE_UPDATE } from "../../ducks/Profile/constants";
import { SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE } from "../../ducks/Admin/constants";
import "../../presentational/ProfilePage/index.css";
import './index.css';
import LocationInput from "../../presentational/ShortAssessment/LocationInput";

const { Option } = Select;
const { TextArea } = Input;

class ProfileForm extends React.Component {
  constructor() {
    super();
    this.state = {
      profileData: null,
      minCaloriesDay: 0,
      errors: [],
      assessmentErrors: [],
    };
  }

  componentDidMount() {
    this.setState({
      profileData: this.props.profile,
      minCaloriesDay: this.calculateResultsFromProfile(
        this.props.profile,
        true
      ),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.currentWeight !== this.props.profile.currentWeight) {
      this.setState({
        profileData: this.props.profile,
        minCaloriesDay: this.calculateResultsFromProfile(
          this.props.profile,
          true
        ),
      });
    }
    //if coming from carb cycling update request redirect to carb cycling if nutrition changes
    if(prevProps.nutrition !== this.props.nutrition && !this.props.loading && this.props.location.state?.from === 'carb-cycling-update'){
      this.props.history.push('/dashboard/tools/carb-cycling');
    }
  }

  handleChange = (e, field) => {
    let profileData = { ...this.state.profileData };
    switch (field) {
      case "goals":
        Object.assign(profileData, { goals: e });
        break;
      case "goal":
        profileData[field] = JSON.parse(e);
        // delete byWhen & goalWeight
        delete profileData.byWhen;
        delete profileData.goalWeight;
        break;
      case "activityLevel":
      case "bodyType":
      case "rate":
        profileData[field] = JSON.parse(e);
        break;
      case "foodPreference":
        profileData[field] = e;
        profileData.macros = getMacrosWithPreferenceAndOptionsName(
          e,
          "Recommended"
        );
        break;
      case "mealsPerDay":
      case "gender":
      case "workoutLocation":
        profileData[field] = e;
        break;
      case "phoneNumber":
        profileData[field] = formatPhoneNumber(e.target.value);
        break;
      case "heightFt":
      case "heightIn":
      case "age":
        if (e.target.value) {
          profileData[field] = parseInt(e.target.value);
        } else {
          profileData[field] = "";
        }
        break;
      case "protein":
      case "fat":
      case "carb":
        if (e.target.value) {
          profileData.macros[field] = parseInt(e.target.value);
        } else {
          profileData.macros[field] = "";
        }
        break;
      case "goalWeight":
      case "currentWeight":
      case "height":
        if (e.target.value) {
          profileData[field] = parseFloat(e.target.value);
        } else {
          profileData[field] = "";
        }
        break;
      case "byWhen":
        profileData[field] = e._d;
        break;
      case "macros":
        profileData[field] = getMacrosWithPreferenceAndOptionsName(
          profileData.foodPreference,
          e
        );
        break;
      default:
        profileData[field] = e.target.value;
    }
    const errors = [];
    if (
      String(profileData.goalWeight).split(".")[1] &&
      String(profileData.goalWeight).split(".")[1].length > 1
    ) {
      errors.push("Goal weight: only 1 number after dot allowed");
    }
    if (
      String(profileData.currentWeight).split(".")[1] &&
      String(profileData.currentWeight).split(".")[1].length > 1
    ) {
      errors.push("Current weight: only 1 number after dot allowed");
    }
    //Not sure what this is doing and not sure if necessary - Alvaro
    // if (field !== 'byWhen') {
    //   delete profileData.byWhen
    // }
    this.setState({
      profileData: profileData,
      minCaloriesDay: this.calculateResultsFromProfile(profileData, true),
      errors,
    });
  };

  handleProfileChange = (e, field) => {
    let profileData = { ...this.state.profileData };
    switch (field) {
      case "riskFollowing":
      case "cardioType":
      case "equipment":
        let { selectedOptions = [], otherDescription = null } = e;
        selectedOptions = selectedOptions.filter((v) => v != "");
        if (!selectedOptions.includes("Other")) {
          otherDescription = null;
        }
        profileData[field] = { selectedOptions, otherDescription };
        break;
      case "travel":
      case "mealsPrefer":
      case "happyEatingFoods":
      case "doStrengthTraining":
      case "weightTraningexprience":
      case "significantInjuries":
      case "timeForWorkout":
      case "workoutFrequency":
        profileData[field] = getSelectedOption(e, field);
        break;
      case "currentLocation":
        profileData[field] = e;
        break;
      default:
        profileData[field] = e.target.value;
    }
    const assessmentErrors = [];
    if (
      String(profileData.currentWeight).split(".")[1] &&
      String(profileData.currentWeight).split(".")[1].length > 1
    ) {
      assessmentErrors.push("Current weight: only 1 number after dot allowed");
    }
    if (!profileData.secondaryGoals) {
      assessmentErrors.push("Please enter secondary goals");
    }
    if (!profileData.explainReachGoals) {
      assessmentErrors.push("Please enter why you want to reach these goals");
    }
    if (!profileData.trainingActivity) {
      assessmentErrors.push(
        "Please enter any activity outside of the training"
      );
    }
    if (!profileData.supplements) {
      assessmentErrors.push("Please enter supplements if you take any");
    }
    if (!profileData.obstacleReachingFitnessGoals) {
      assessmentErrors.push(
        "Please enter obstacle holding you back from reaching your goals"
      );
    }
    if (
      profileData &&
      profileData.riskFollowing &&
      !profileData.riskFollowing.selectedOptions.length
    ) {
      assessmentErrors.push("Please select option for risk following");
    } else if (
      profileData &&
      profileData.riskFollowing &&
      profileData.riskFollowing.selectedOptions &&
      profileData.riskFollowing.selectedOptions.includes("Other") &&
      (!profileData.riskFollowing.otherDescription ||
        (profileData.riskFollowing.otherDescription &&
          !profileData.riskFollowing.otherDescription.trim()))
    ) {
      assessmentErrors.push(
        "Please enter other description for risk following"
      );
    }
    if (!profileData.averageStepCount) {
      assessmentErrors.push("Please enter your average daily step count");
    }
    if (
      profileData.cardioType &&
      !profileData.cardioType.selectedOptions.length
    ) {
      assessmentErrors.push("Please select cardio type");
    } else if (
      profileData.cardioType &&
      profileData.cardioType.selectedOptions &&
      profileData.cardioType.selectedOptions.includes("Other") &&
      (!profileData.cardioType.otherDescription ||
        (profileData.cardioType.otherDescription &&
          !profileData.cardioType.otherDescription.trim()))
    ) {
      assessmentErrors.push("Please enter other description for cardio type");
    }
    if (!profileData.equipment.selectedOptions.length) {
      assessmentErrors.push("Please select equipment type");
    } else if (
      profileData.equipment &&
      profileData.equipment.selectedOptions &&
      profileData.equipment.selectedOptions.includes("Other") &&
      (!profileData.equipment.otherDescription ||
        (profileData.equipment.otherDescription &&
          !profileData.equipment.otherDescription.trim()))
    ) {
      assessmentErrors.push("Please enter other description for equipment");
    }
    if (!profileData.currentLocation) {
      assessmentErrors.push("Please enter your current location");
    }
    this.setState({
      profileData: profileData,
      assessmentErrors,
    });
  };

  handleSaveBtnClick = async (profileData) => {
    this.props.updateAssessmentProfile({ ...profileData });
  };

  switchUnits = () => {
    let profileData = { ...this.state.profileData };
    const isMetric = profileData.isMetric ? !profileData.isMetric : true;
    if (profileData.isMetric) {
      let heightObj = cmToFeet(profileData.height);
      profileData.heightFt = heightObj.feet;
      profileData.heightIn = heightObj.inches;
      profileData.currentWeight = kgsToPounds(profileData.currentWeight);
      if (profileData.goalWeight) {
        profileData.goalWeight = kgsToPounds(profileData.goalWeight);
      }
      if (profileData.startWeight) {
        profileData.startWeight = kgsToPounds(profileData.startWeight);
      }
    } else {
      profileData.height = feetToCm(profileData.heightFt, profileData.heightIn);
      profileData.currentWeight = poundsToKgs(profileData.currentWeight);
      if (profileData.goalWeight) {
        profileData.goalWeight = poundsToKgs(profileData.goalWeight);
      }
      if (profileData.startWeight) {
        profileData.startWeight = poundsToKgs(profileData.startWeight);
      }
    }
    profileData.isMetric = isMetric;
    this.setState({ profileData: profileData });
  };

  renderMacroOptions = (foodPreference) => {
    if (foodPreference) {
      const options = getMacroOptions(foodPreference);
      const renderedOptions = options.map((item, index) => (
        <Option className="select-option" key={index} value={item.value}>
          {item.label}
        </Option>
      ));
      return renderedOptions;
    }
  };

  renderFoodPreferenceOptions = () => {
    const options = getFoodPreferenceOptions();
    const renderedOptions = options.map((item, index) => (
      <Option className="select-option" key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return renderedOptions;
  };

  renderWorkoutLocationOptions = () => {
    const options = getWorkoutLocationOptions();
    const renderedOptions = options.map((item, index) => (
      <Option className="select-option" key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return renderedOptions;
  };

  renderMealsPerDayOptions = () => {
    const options = getMealsPerDayOptions();
    const renderedOptions = options.map((item, index) => (
      <Option className="select-option" key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return renderedOptions;
  };

  renderGoalDepdentField = (profileData) => {
    const rates = [
      {
        key: 1,
        text: "Slow",
        value: "slow",
        infoMetric: "~.2kg",
        infoImperial: "~.5lb",
        // info: Units.imperial ? '~.5lb' : '~.2kg',
      },
      {
        key: 2,
        text: "Moderate",
        value: "moderate",
        infoMetric: "~.5kg",
        infoImperial: "~1lb",
        // info: Units.imperial ? '~1lb' : '~.5kg',
        active: true,
      },
      {
        key: 3,
        text: "Aggressive",
        value: "aggressive",
        infoMetric: "~0.5kg",
        infoImperial: "~2lb",
        // info: Units.imperial ? '~2lb' : '~0.5kg',
      },
    ];
    if (profileData.goal && profileData.goal.key) {
      if (profileData.goal.key === 1 || profileData.goal.key === 2) {
        return (
          <div>
            <div className="form-row flex space-between">
              {profileData.isMetric
                ? this.renderMetricGoalWeight(profileData)
                : this.renderImperialGoalWeight(profileData)}
              <div className="form-section">
                <p className="label">*By When</p>
                <DatePicker
                  style={{ width: "100%" }}
                  format={"MMMM Do, YYYY"}
                  disabledDate={(current) => {
                    // const minCaloriesDay = this.state.minCaloriesDay === 1 ? 0 : this.state.minCaloriesDay;
                    return current && current < moment().endOf("day"); // moment().add(minCaloriesDay, 'days')
                  }}
                  value={
                    profileData && profileData.byWhen
                      ? moment(profileData.byWhen)
                      : null
                  }
                  onChange={(e) => this.handleChange(e, "byWhen")}
                  allowClear={false}
                  showToday={false}
                />
              </div>
            </div>
            {/* <div className="form-section">
              <p className="label">*At what rate</p>
              <Select
                value={
                  profileData && profileData.rate
                    ? profileData.rate.text
                    : null
                }
                style={{ width: "100%" }}
                onChange={(e) => this.handleChange(e, "rate")}
              >
                {rates.map((rate) => (
                  <Option key={rate.key} value={JSON.stringify(rate)}>
                    {rate.text}
                  </Option>
                ))}
              </Select>
            </div> */}
          </div>
        );
      }
    }
  };

  renderMetricGoalWeight = (profileData) => {
    return (
      <div className="form-section">
        <p className="label">*Goal Weight (Kg)</p>
        <Input
          type="number"
          onChange={(e) => this.handleChange(e, "goalWeight")}
          value={
            profileData && profileData.goalWeight ? profileData.goalWeight : ""
          }
        />
      </div>
    );
  };

  renderImperialGoalWeight = (profileData) => {
    return (
      <div className="form-section">
        <p className="label">*Goal Weight(lb)</p>
        <Input
          type="number"
          onChange={(e) => this.handleChange(e, "goalWeight")}
          value={
            profileData && profileData.goalWeight ? profileData.goalWeight : ""
          }
        />
      </div>
    );
  };

  renderImperialHeight = (profileData) => {
    return (
      <div className="form-row flex space-between">
        <div className="form-section">
          <p className="label">*Height(ft)</p>
          <Input
            onChange={(e) => this.handleChange(e, "heightFt")}
            value={profileData ? profileData.heightFt : ""}
            type="number"
          />
        </div>
        <div className="form-section">
          <p className="label">*Height(In)</p>
          <Input
            onChange={(e) => this.handleChange(e, "heightIn")}
            value={profileData ? profileData.heightIn : ""}
            type="number"
          />
        </div>
      </div>
    );
  };

  renderMetricHeight = (profileData) => {
    return (
      <div className="form-row flex space-between">
        <div className="form-section">
          <p className="label">*Height(cm)</p>
          <Input
            onChange={(e) => this.handleChange(e, "height")}
            value={profileData ? profileData.height : ""}
            type="number"
          />
        </div>
      </div>
    );
  };

  renderImperialWeight = (profileData) => {
    return (
      <div className="form-section">
        <p className="label">*Current weight lb.</p>
        <Input
          type="number"
          onChange={(e) => this.handleChange(e, "currentWeight")}
          value={
            profileData && profileData.currentWeight
              ? profileData.currentWeight
              : ""
          }
        />
      </div>
    );
  };

  renderGoalDeficit = (profileData) => {
    return (
      <div className="form-section">
        <p className="label">
          *{profileData.goal.key === 1 ? "Deficit" : "Surplus"}
        </p>
        <Input
          type="number"
          addonAfter="%"
          onChange={(e) => this.handleChange(e, "deficit")}
          value={profileData && profileData.deficit ? profileData.deficit : ""}
        />
      </div>
    );
  };

  renderMetricWeight = (profileData) => {
    return (
      <div className="form-section">
        <p className="label">*Current weight kg.</p>
        <Input
          type="number"
          onChange={(e) => this.handleChange(e, "currentWeight")}
          value={
            profileData && profileData.currentWeight
              ? profileData.currentWeight
              : ""
          }
        />
      </div>
    );
  };

  renderWeightField = (profileData) => {
    if (profileData && profileData.isMetric) {
      return this.renderMetricWeight(profileData);
    } else {
      return this.renderImperialWeight(profileData);
    }
  };

  renderHeightField = (profileData) => {
    if (profileData && profileData.isMetric) {
      return this.renderMetricHeight(profileData);
    } else {
      return this.renderImperialHeight(profileData);
    }
  };

  renderCustomMacrosForm = (profileData) => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <div style={{ marginBottom: "20px" }}>
          <p className="label">*Protein</p>
          <Input
            onChange={(e) => this.handleChange(e, "protein")}
            addonAfter="%"
            type="number"
            value={profileData.macros.protein}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <p className="label">*Carb</p>
          <Input
            onChange={(e) => this.handleChange(e, "carb")}
            addonAfter="%"
            type="number"
            value={profileData.macros.carb}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <p className="label">*Fat</p>
          <Input
            onChange={(e) => this.handleChange(e, "fat")}
            addonAfter="%"
            type="number"
            value={profileData.macros.fat}
          />
        </div>
      </div>
    );
  };

  renderProfileForm = () => {
    const activityLevel = [
      {
        key: "1.2",
        text: "Sedentary (little or no exercise)",
        value: "1.2",
      },
      {
        key: "1.375",
        text: "Lightly active (light exercise/sports 1-3 days/week)",
        value: "1.375",
      },
      {
        key: "1.55",
        text: "Moderately active (moderate exercise/sports 3-5 days/week)",
        value: "1.55",
      },
      {
        key: "1.725",
        text: "Very active (hard exercise/sports 6-7 days a week)",
        value: "1.725",
      },
      {
        key: "1.9",
        text: "Extra active (very hard exercise/sports & physical job or 2x training)",
        value: "1.9",
      },
    ];

    const goal = [
      {
        key: 1,
        text: "Lose body fat",
        goal: "lose body fat",
        value: "Lose body fat",
        info: "If you are trying to lose 10 lbs or more.",
      },
      {
        key: 2,
        text: "Gain muscle",
        goal: "gain",
        value: "Gain muscle",
        info: "Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.",
      },
      // {
      //   key: 3,
      //   text: "Athletic performance",
      //   value: "Athletic performance",
      //   goal: "athletic performance",
      //   info:
      //     "A surplus of calories that will help you sustain the energy needed for strenuous athletic training.",
      // },
      {
        key: 3,
        text: "Body recomposition",
        value: "Body recomposition",
        goal: "body recomposition",
        info: "A small deficit of calories that will allow you to build muscle while burning fat. Choose this option if you need to lose less than 10 lbs.",
      },
      {
        key: 4,
        text: "Improve overall health",
        value: "Improve overall health",
        goal: "improving overall health",
        info: "You will get the number of calories needed to maintain your current weight.",
      },
    ];

    const { profileData } = this.state;
   
    return (
      profileData && (
        <>
          <div className="edit-profile">
            <div className="form">
              <div className="grid">
                <div className="form-heading">
                  <p className="form-title">Goal</p>
                  <p className="form-subtitle">Let's set a goal!</p>
                </div>
                <div>
                  <div className="form-row flex space-between">
                    {this.renderWeightField(profileData)}
                    <div className="form-section">
                      <p className="label">*Goal</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "goal")}
                        value={
                          profileData && profileData.goal
                            ? profileData.goal.value
                            : ""
                        }
                        className="select-input"
                      >
                        {goal.map((goal) => (
                          <Option
                            className="select-option"
                            key={goal.key}
                            value={JSON.stringify(goal)}
                          >
                            <p style={{ fontWeight: "500" }}>{goal.value}</p>
                            <p style={{ fontSize: "14px" }}>{goal.info}</p>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {
                    profileData && profileData.goal && (profileData.goal.key === 1 || profileData.goal.key === 2)
                      ? 
                      <>{this.renderGoalDeficit(profileData)}</>
                      : ""
                  }
                  {/* {this.renderGoalDepdentField(profileData)} */}
                </div>
              </div>

              <Divider />

              <div className="grid">
                <div className="form-heading">
                  <p className="form-title">Nutrition</p>
                  <p className="form-subtitle">
                    Complete your food and macro preferences. If you are not
                    sure, choose our recommended option.
                  </p>
                </div>
                <div>
                  <div className="form-row flex space-between">
                    <div className="form-section">
                      <p className="label">*Food Preference</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "foodPreference")}
                        defaultValue={
                          profileData && profileData.foodPreference
                            ? profileData.foodPreference
                            : ""
                        }
                        className="select-input"
                      >
                        {this.renderFoodPreferenceOptions()}
                      </Select>
                    </div>
                    {/* <div className="form-section">
                      <p className="label">*Meals per Day</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "mealsPerDay")}
                        defaultValue={
                          profileData && profileData.mealsPerDay
                            ? profileData.mealsPerDay
                            : ""
                        }
                        className="select-input"
                      >
                        {this.renderMealsPerDayOptions()}
                      </Select>
                    </div> */}
                  </div>

                  <div className="form-row flex space-between">
                    <div className="form-section">
                      <p className="label">*Macro Ratio</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "macros")}
                        value={
                          profileData &&
                          profileData.foodPreference &&
                          profileData.macros
                            ? getMacroLabelFromMacroDivision(
                                profileData.foodPreference,
                                profileData.macros
                              )
                            : ""
                        }
                        className="select-input"
                      >
                        {this.renderMacroOptions(profileData.foodPreference)}
                      </Select>
                    </div>
                    {profileData.macros.hasOwnProperty("custom") && (
                      <div className="form-section">
                        {this.renderCustomMacrosForm(profileData)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Divider />
              <div className="grid">
                <div className="form-heading">
                  <p className="form-title">Exercise</p>
                  <p className="form-subtitle">
                    Only choose very active and extra active if you do very
                    intense training for extended periods of time.
                  </p>
                </div>
                <div>
                  <div className="form-row flex space-between">
                    <div className="form-section">
                      <p className="label">*Activity level</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "activityLevel")}
                        defaultValue={
                          profileData && profileData.activityLevel
                            ? profileData.activityLevel.text
                            : ""
                        }
                        className="select-input"
                      >
                        {activityLevel.map((activity) => (
                          <Option
                            className="select-option"
                            key={activity.key}
                            value={JSON.stringify(activity)}
                          >
                            {activity.text}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    {/* <div className="form-section">
                      <p className="label">*Workout Location</p>
                      <Select
                        onChange={(e) => this.handleChange(e, "workoutLocation")}
                        defaultValue={
                          profileData && profileData.workoutLocation
                            ? profileData.workoutLocation
                            : ""
                        }
                        className="select-input"
                      >
                        {this.renderWorkoutLocationOptions()}
                      </Select>
                    </div> */}
                  </div>
                </div>
              </div>

              <Divider />

              <div className="form-section button-section">
                <div></div>
                <Button
                  onClick={() =>
                    this.calculateResultsFromProfile(
                      { ...this.state.profileData },
                      false,
                      true
                    )
                  }
                  type="primary"
                  className="button"
                  loading={this.props.loading}
                  disabled={this.props.loading || this.state.errors.length}
                >
                  Calculate Macronutrients
                </Button>
              </div>
              {this.state.errors.length > 0 && (
                <Alert
                  type="error"
                  message={this.state.errors.map((err) => (
                    <p>{err}</p>
                  ))}
                />
              )}
              {this.props.error && !this.state.errors.length && (
                <Alert
                  type="error"
                  message={
                    this.props.error.errors &&
                    Object.values(this.props.error.errors).map((err) => (
                      <p>{err.msg}</p>
                    ))
                  }
                />
              )}
              {/* {this.props.successMessage && (
                <Alert type="success" message={this.props.successMessage} />
              )} */}
            </div>
          </div>
        </>
      )
    );
  };

  calculateResultsFromProfile = (
    profileData,
    onlyGetCalories,
    fromSaveBtn = false
  ) => {
    let nutrition = [];

    let cm = profileData.isMetric
      ? profileData.height
      : Number(profileData.heightFt) * 30.48 +
        Number(profileData.heightIn) * 2.54;
    let kg = !profileData.isMetric
      ? (Number(profileData.currentWeight) / 2.205).toFixed(2)
      : Number(profileData.currentWeight);

    let bmr = Math.round(
      profileData.gender === "male"
        ? 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) + 5
        : 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) - 161
    );

    let maintenanceCal = Math.round(
      bmr * Number(profileData.activityLevel.value)
    );
    let calculatedGoalCalories = 0;
    let days = 0;

    //when goal is to lose weight or gain muscle
    // if (profileData.goal.value === 'Lose weight' || profileData.goal.value === 'Build muscle') {
    //   if (onlyGetCalories) {
    //     while (calculatedGoalCalories < 1200) {
    //       let currentWeight = Number(profileData.currentWeight);
    //       let goalWeight = Number(profileData.goalWeight);
    //       if (goalWeight && (currentWeight > goalWeight)) {
    //         profileData.goal = {
    //           goal: "lose",
    //           info: "If you are trying to lose 10 lbs or more.",
    //           key: 2,
    //           text: "Lose weight",
    //           value: "Lose weight"
    //         }
    //         this.setState({ profileData })
    //       } else if (goalWeight && (currentWeight < goalWeight)) {
    //         profileData.goal = {
    //           goal: "gain",
    //           info: "Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.",
    //           key: 1,
    //           text: "Build muscle",
    //           value: "Build muscle"
    //         }
    //         this.setState({ profileData })
    //       }
    //       let goalPoundsOrKg =
    //         profileData.goal.value === "Lose weight"
    //           ? Number(currentWeight - goalWeight)
    //           : Number(goalWeight - currentWeight);
    //       // let goalPoundsOrKgCalories = profileData.isMetric
    //       //   ? goalPoundsOrKg * 3850 //making 7000 the calorie needed to gain or lose 1kg
    //       //   : goalPoundsOrKg * 3500; //making 2500 the calorie needed to gain or lose 1lb
    //       let goalPoundsOrKgCalories;
    //       if (!profileData.rate) {
    //         profileData.rate = {
    //           key: 2,
    //           text: 'Moderate',
    //           value: 'moderate',
    //           infoMetric: '~.5kg',
    //           infoImperial: '~1lb',
    //           // info: Units.imperial ? '~1lb' : '~.5kg',
    //         }
    //       }
    //       if (profileData.rate) {
    //         switch (profileData.rate.value) {
    //           case 'slow':
    //             goalPoundsOrKgCalories = !profileData.isMetric
    //               ? goalPoundsOrKg * 1750
    //               : goalPoundsOrKg * 1925;
    //             break;
    //           case 'moderate':
    //             goalPoundsOrKgCalories = !profileData.isMetric
    //               ? goalPoundsOrKg * 3500
    //               : goalPoundsOrKg * 3850;
    //             break;
    //           case 'aggressive':
    //             goalPoundsOrKgCalories = !profileData.isMetric
    //               ? goalPoundsOrKg * 7000
    //               : goalPoundsOrKg * 7700;
    //             break;
    //         }
    //       }
    //       if (profileData.goal.value === "Lose weight") {
    //         let deficitPerDay = Math.round(goalPoundsOrKgCalories / days);
    //         calculatedGoalCalories = maintenanceCal - deficitPerDay;
    //       } else if (profileData.goal.value === "Build muscle") {
    //         let surplusPerDay = Math.round(goalPoundsOrKgCalories / days);
    //         calculatedGoalCalories = maintenanceCal + surplusPerDay;
    //       }
    //       days++
    //     }
    //     return days
    //   }

    //   if (profileData.byWhen !== null) {
    //     let goalDate = moment(profileData.byWhen).format("MM/DD/YYYY");
    //     let today = moment(new Date()).toDate();
    //     let formattedToday = moment(today).format("MM/DD/YYYY");
    //     days = Math.abs(
    //       moment(formattedToday, "MM/DD/YYYY")
    //         .startOf("day")
    //         .diff(moment(goalDate, "MM/DD/YYYY").startOf("day"), "days")
    //     );
    //   }

    //   let currentWeight = Number(profileData.currentWeight);
    //   let goalWeight = Number(profileData.goalWeight);
    //   if (goalWeight && (currentWeight > goalWeight)) {
    //     profileData.goal = {
    //       goal: "lose",
    //       info: "If you are trying to lose 10 lbs or more.",
    //       key: 2,
    //       text: "Lose weight",
    //       value: "Lose weight"
    //     }
    //     this.setState({ profileData })
    //   } else if (goalWeight && (currentWeight < goalWeight)) {
    //     profileData.goal = {
    //       goal: "gain",
    //       info: "Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.",
    //       key: 1,
    //       text: "Build muscle",
    //       value: "Build muscle"
    //     }
    //     this.setState({ profileData })
    //   }
    //   let goalPoundsOrKg =
    //     profileData.goal.value === "Lose weight"
    //       ? Number(currentWeight - goalWeight)
    //       : Number(goalWeight - currentWeight);
    //   // let goalPoundsOrKgCalories = profileData.isMetric
    //   //   ? goalPoundsOrKg * 3850 //making 7000 the calorie needed to gain or lose 1kg
    //   //   : goalPoundsOrKg * 3500; //making 2500 the calorie needed to gain or lose 1lb
    //   let goalPoundsOrKgCalories;
    //   switch (profileData.rate.value) {
    //     case 'slow':
    //       goalPoundsOrKgCalories = !profileData.isMetric
    //         ? goalPoundsOrKg * 1750
    //         : goalPoundsOrKg * 1925;
    //       break;
    //     case 'moderate':
    //       goalPoundsOrKgCalories = !profileData.isMetric
    //         ? goalPoundsOrKg * 3500
    //         : goalPoundsOrKg * 3850;
    //       break;
    //     case 'aggressive':
    //       goalPoundsOrKgCalories = !profileData.isMetric
    //         ? goalPoundsOrKg * 7000
    //         : goalPoundsOrKg * 7700;
    //       break;
    //   }
    //   if (profileData.goal.value === "Lose weight") {
    //     let deficitPerDay = Math.round(goalPoundsOrKgCalories / days);
    //     calculatedGoalCalories = maintenanceCal - deficitPerDay;
    //   } else if (profileData.goal.value === "Build muscle") {
    //     let surplusPerDay = Math.round(goalPoundsOrKgCalories / days);
    //     calculatedGoalCalories = maintenanceCal + surplusPerDay;
    //   }
    // }

    if (onlyGetCalories && !profileData.byWhen) {
      return days;
    }

    //When goal is other than losing weight or gaining muscle
    let deficitPercentage = 0.1;
    if (profileData.deficit) {
      deficitPercentage = profileData.deficit / 100;
    }
    // if (profileData.goal.value === "Athletic performance") {
    //   let surplusPerDay = maintenanceCal * 0.1; //10% extra from maintenance
    //   calculatedGoalCalories = maintenanceCal + surplusPerDay;
    // } else
    if (profileData.goal.value === "Lose body fat") {
      let deficitPerDay = maintenanceCal * deficitPercentage;
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (profileData.goal.value === "Gain muscle") {
      let deficitPerDay = maintenanceCal * deficitPercentage;
      calculatedGoalCalories = maintenanceCal + deficitPerDay;
    } else if (profileData.goal.value === "Body recomposition") {
      let deficitPerDay = maintenanceCal * 0.1; //10% below maintenance
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
      profileData.deficit = 10;
    } else if (profileData.goal.value === "Improve overall health") {
      calculatedGoalCalories = maintenanceCal;
      profileData.deficit = 10;
    }
    let nutritionResults = {
      goalCalories: Math.round(calculatedGoalCalories),
      maintenanceCalories: Math.round(maintenanceCal),
      protein: {
        grams: Math.round(
          (calculatedGoalCalories * (profileData.macros.protein / 100)) / 4
        ),
        displayName: "Protein",
      },
      carbs: {
        grams: Math.round(
          (calculatedGoalCalories * (profileData.macros.carb / 100)) / 4
        ),
        displayName: "Carbohydrates",
      },
      fats: {
        grams: Math.round(
          (calculatedGoalCalories * (profileData.macros.fat / 100)) / 9
        ),
        displayName: "Fats",
      },
    };
    const totalIntake =
      nutritionResults.carbs.grams +
      nutritionResults.protein.grams +
      nutritionResults.fats.grams;
    nutritionResults.carbs.percentage =
      ((nutritionResults.carbs.grams * 4) / nutritionResults.goalCalories) *
      100;
    nutritionResults.protein.percentage =
      ((nutritionResults.protein.grams * 4) / nutritionResults.goalCalories) *
      100;
    nutritionResults.fats.percentage =
      ((nutritionResults.fats.grams * 9) / nutritionResults.goalCalories) * 100;
    nutrition.push({ nutritionResults: nutritionResults });
    // call API only if save button triggered
    if (fromSaveBtn) {
      !this.props.isAdminUpdate
        ? this.props.setNutritionFromProfileUpdate(nutrition[0])
        : this.props.setClientNutritionFromProfileUpdate(nutrition[0]);
      !this.props.isAdminUpdate
        ? this.props.saveProfileIgnoreNutrition({ ...profileData })
        : this.props.updateClientMacros({ ...profileData });
    }
  };

  onCkChange = (checkedValues, fieldName) => {
    this.handleProfileChange(
      {
        selectedOptions: checkedValues,
        otherDescription: this.state.profileData[fieldName]
          ? this.state.profileData[fieldName].otherDescription
          : null,
      },
      fieldName
    );
  };

  handleTXChange = (e, fieldName) => {
    this.handleProfileChange(
      {
        selectedOptions: this.state.profileData[fieldName].selectedOptions,
        otherDescription: e.target.value,
      },
      fieldName
    );
  };

  renderCustomCheckbox = (profileData, fieldName) => {
    const options = getSelectOptions(fieldName);
    let [none, ...restOptions] = options;
    const checkbox =
      fieldName === "riskFollowing" ? (
        <>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(e) => this.onCkChange(e, fieldName)}
            value={
              this.state.profileData[fieldName]
                ? this.state.profileData[fieldName].selectedOptions
                : ""
            }
          >
            <Checkbox
              className="custom-checkbox"
              key={none.key}
              value={none.value}
            >
              {none.text}
            </Checkbox>
          </Checkbox.Group>
          <hr style={{ borderColor: "#E0E0E0", marginBottom: "15px" }}></hr>
          {this.state.profileData[fieldName] &&
          this.state.profileData[fieldName].selectedOptions.includes(
            none.value
          ) ? (
            ""
          ) : (
            <>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(e) => this.onCkChange(e, fieldName)}
                value={
                  this.state.profileData[fieldName]
                    ? this.state.profileData[fieldName].selectedOptions
                    : ""
                }
              >
                {restOptions.map((op) => (
                  <Checkbox
                    className="custom-checkbox"
                    key={op.key}
                    value={op.value}
                  >
                    {op.text}
                  </Checkbox>
                ))}
              </Checkbox.Group>
              {this.state.profileData[fieldName] &&
                this.state.profileData[fieldName].selectedOptions.includes(
                  "Other"
                ) && (
                  <TextArea
                    rows={3}
                    placeholder="Type here..."
                    className="ant-textarea"
                    onChange={(e) => this.handleTXChange(e, fieldName)}
                    value={this.state.profileData[fieldName].otherDescription}
                  />
                )}
            </>
          )}
        </>
      ) : (
        <>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(e) => this.onCkChange(e, fieldName)}
            value={
              this.state.profileData[fieldName]
                ? this.state.profileData[fieldName].selectedOptions
                : ""
            }
          >
            {options.map((op) => (
              <Checkbox
                className="custom-checkbox"
                key={op.key}
                value={op.value}
              >
                {op.text}
              </Checkbox>
            ))}
          </Checkbox.Group>
          {this.state.profileData[fieldName] &&
            this.state.profileData[fieldName].selectedOptions.includes(
              "Other"
            ) && (
              <TextArea
                rows={3}
                placeholder="Type here..."
                className="ant-textarea"
                onChange={(e) => this.handleTXChange(e, fieldName)}
                value={this.state.profileData[fieldName].otherDescription}
              />
            )}
        </>
      );
    return checkbox;
  };

  renderInputField = (profileData, fieldName, label, type = "select") => {
    return profileData ? (
      <div className="form-section">
        <p className="label">*{label}</p>
        {fieldName === "currentLocation" ? (
          <LocationInput
            name="currentLocation"
            id="location"
            placeholder="Enter Location"
            type={type}
            onChange={(e) => this.handleProfileChange(e, fieldName)}
            defaultValue={
              profileData[fieldName] && profileData[fieldName].location
            }
          />
        ) : type === "text" ? (
          <Input
            type={type}
            onChange={(e) => this.handleProfileChange(e, fieldName)}
            value={
              profileData && profileData[fieldName]
                ? profileData[fieldName]
                : ""
            }
          />
        ) : type === "checkbox" ? (
          this.renderCustomCheckbox(profileData, fieldName)
        ) : (
          <Select
            onChange={(e) => this.handleProfileChange(e, fieldName)}
            defaultValue={
              profileData && profileData[fieldName]
                ? profileData[fieldName].value
                : ""
            }
            className="select-input"
          >
            {this.renderOptions(fieldName)}
          </Select>
        )}
      </div>
    ) : null;
  };

  renderTextAreaField = (assessmentData, fieldName, label, type = "text") => {
    return assessmentData ? (
      <div className="form-section" style={{ width: "100%" }}>
        <p className="label">*{label}</p>
        <TextArea
          style={{ borderRadius: "10px" }}
          autoSize
          onChange={(e) => this.handleChange(e, fieldName)}
          value={
            assessmentData && assessmentData[fieldName]
              ? assessmentData[fieldName]
              : ""
          }
        />
      </div>
    ) : null;
  };

  renderOptions = (fieldName) => {
    const options = getSelectOptions(fieldName);
    const renderedOptions = options.map((item, index) => (
      // <Option className='select-option' key={index} value={JSON.stringify(item)}>
      <Option className="select-option" key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return renderedOptions;
  };

  renderAssessmentQuestions = () => {
    const { profileData } = this.state;
    return (
      <div className="edit-profile">
        <div className="form">
          <div className="grid1">
            <div className="form-heading">
              <p className="form-title">Assessment Questions</p>
              {/* <p className="form-subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
            </div>
            <div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  profileData,
                  "secondaryGoals",
                  "What other secondary goals would you like to accomplish?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  profileData,
                  "explainReachGoals",
                  "If you had to think of the deep underlying reason as to why you want to reach these goals, how would you explain it to me?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(profileData, 'trainingActivity', 'Is there any activity that you would like to keep or add-in alongside your program? (Please explain why)')}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  profileData,
                  "supplements",
                  "Do you currently take any supplements? If so, please list them."
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  profileData,
                  "obstacleReachingFitnessGoals",
                  "What would you say is the biggest obstacle currently holding you back from reaching your fitness goals? This is very important, please give a detailed answer about what it is that you feel is holding you back."
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "travel",
                  "How much do you travel, either for work or pleasure?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "mealsPrefer",
                  "Do you prefer eating fewer, larger meals or more frequent, smaller meals?"
                )}
                {this.renderInputField(
                  profileData,
                  "happyEatingFoods",
                  "Are you happy eating the same foods frequently or do you like a lot of variety?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "riskFollowing",
                  "Are you at risk of any of the following?",
                  "checkbox"
                )}
                {this.renderInputField(
                  profileData,
                  "averageStepCount",
                  "What's your average daily step count?",
                  "text"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "doStrengthTraining",
                  "Do you currently do any type of strength training?"
                )}
                {this.renderInputField(
                  profileData,
                  "weightTraningexprience",
                  "How would you classify yourself in terms of weight training experience?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "significantInjuries",
                  "Do you have any significant injuries?"
                )}
                {this.renderInputField(
                  profileData,
                  "cardioType",
                  "Do you currently do any of the following types of cardio?",
                  "checkbox"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "equipment",
                  "What kind of equipment will you have access to? If you don’t have any equipment, that’s OK. We can create customized training programs based solely on body weight. Just select ‘No equipment’.",
                  "checkbox"
                )}
                {this.renderInputField(
                  profileData,
                  "timeForWorkout",
                  "How much time do you have for each workout?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  profileData,
                  "workoutFrequency",
                  "How frequently are you able to workout out?"
                )}
                {this.renderInputField(
                  profileData,
                  "currentLocation",
                  "What location are you currently in?"
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className="form-section button-section">
            <div></div>
            <Button
              onClick={() =>
                this.handleSaveBtnClick({ ...this.state.profileData })
              }
              type="primary"
              className="button"
              loading={this.props.loading}
              disabled={
                this.props.loading || this.state.assessmentErrors.length
              }
            >
              Save
            </Button>
          </div>
          {!this.props.loading ? (
            <>
              {this.state.assessmentErrors.length > 0 && (
                <Alert
                  type="error"
                  message={this.state.assessmentErrors.map((err) => (
                    <p>{err}</p>
                  ))}
                />
              )}
              {this.props.error &&
                this.props.error.length &&
                !this.state.assessmentErrors.length && (
                  <Alert
                    type="error"
                    message={
                      this.props.error.assessmentErrors &&
                      Object.values(this.props.error.assessmentErrors).map(
                        (err) => <p>{err.msg}</p>
                      )
                    }
                  />
                )}
              {/* {this.props.successMessage && (
                <Alert type="success" message={this.props.successMessage} />
              )} */}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderProfileForm()}
        {this.props.isAdminUpdate ? (
          <>
            <Divider /> {this.renderAssessmentQuestions()}
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserVerified: state.auth.isUserVerified,
    // nutrition: state.profile.nutrition
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfileIgnoreNutrition: (payload) =>
      dispatch(saveProfileIgnoreNutrition(payload)),
    updateClientMacros: (clientProfile) =>
      dispatch(updateClientMacros(clientProfile)),
    updateAssessmentProfile: (payload) =>
      dispatch(updateAssessmentProfile(payload)),
    setNutritionFromProfileUpdate: (payload) => {
      return dispatch({
        type: SET_NUTRITION_FROM_PROFILE_UPDATE,
        payload,
      });
    },
    setClientNutritionFromProfileUpdate: (payload) => {
      return dispatch({
        type: SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE,
        payload,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileForm));