import React from 'react';
import { Button } from 'antd';
import './index.css';
import moment from 'moment';

class SubscriptionPage extends React.Component {

  customerPortal = async () => {
    const req = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/create-customer-portal-session`, {
      method: 'POST',
      headers: {
        'x-auth-token': localStorage.token
      }
    });
    window.open(await req.text());
  }

  render(){
    return(
      <div className='site-layout-background'>
        <div>
        {(this.props.user && this.props.user.createdBy !== "admin") && (
          <div>
            <div className='billing-section flex space-between'>
              <div className='title'>
                <p className='form-title' style={{marginBottom: '5px'}}>Current plan</p>
                {(this.props.user && this.props.user.isSubscriptionActive) && (
                  <>
                    {(this.props.user && this.props.user.plan) && (
                      <p className='form-subtitle'><strong>Name:</strong> {this.props.user.plan}</p>
                    )}
                    {(this.props.user && this.props.user.planType) && (
                      <div className='flex flex-center-aligned'>
                        <p className='form-subtitle'><strong>Type:</strong></p> 
                        <div className={this.props.user && this.props.user.planType === "pro" ? 'pro-label': 'fixed-label'}>{this.props.user && this.props.user.planType === "pro" ? 'Pro': 'Fixed'}</div>
                      </div>
                    )}
                    {(this.props.user && this.props.user.expiryDate) && (
                      <p className='form-subtitle'><strong>Expires on:</strong> {moment(this.props.user.expiryDate).format("MM/DD/YYYY")}</p>
                    )}
                  </>
                )}
              </div>
              {(this.props.user && this.props.user.planType === "pro") && (
              <Button 
                type='primary' 
                // onClick={() => this.props.user && this.props.user.plan === "pro-coaching" ? null : this.props.handlePricingModal(true)}
                onClick={this.customerPortal}
              >
                {this.props.user && this.props.user.isSubscriptionActive ? 'Manage Plan': 'Renew Plan'}
              </Button>
              )}
            </div>
            {/* <Divider /> */}
          </div>
        )}
          
          {/* <div className='billing-section flex flex-center-aligned space-between'>
            <div className='title'>
              <p className='form-title'>Manage plan</p>
              <p className='form-subtitle'>Manage your plan and see billing history</p>
            </div>
            <Button type='primary' onClick={this.customerPortal}>{this.props.user && this.props.user.isSubscriptionActive ? 'Manage Plan': 'Renew Plan'}</Button>
          </div> */}
        </div>
      </div>
    )
  }
}

export default SubscriptionPage;