import React from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Calculator from '../../assets/images/calculator.png';
import Male from '../../assets/images/man-gender.svg';
import Female from '../../assets/images/woman-gender.svg';
import MacrosImg from '../../assets/images/macros-img.png';
import { Divider, Button } from 'antd';
import Crown from '../../assets/images/crown1.svg';
import { ConfirmModal } from '../../components/Modal/ConfirmModal';
import {IoMdCheckmarkCircle} from 'react-icons/io';
import './index.css';

class Nutrition extends React.Component {
  state={
    calculator: false,
    terms: false,
    lossAndGain: false,
    caloriesAndMacros: false,
    mealPrepping: false,
    yourProgram: false,
    guide: ''
  }

  componentWillReceiveProps(nextProps){
    let guidesCompleted = this.props.guides && Object.values(this.props.guides.nutrition).every(
      value => value.completed === true
    );
    let nextPropsGuides = nextProps.guides && Object.values(nextProps.guides.nutrition).every(
      value => value.completed === true
    );
    if(this.props.guides && nextPropsGuides && !guidesCompleted){
      let todo = this.props.todo;
      todo.todo[2].completed = true;
      this.props.createOrUpdateTodo(todo);
      console.log('updated todo')
    }
  }

  renderCardIcon = (card) => {
    if(!this.props.auth.user.isPro){
      return <img src={Crown} style={{height: '16px'}} />;
    }
    if(this.props.auth.user.isPro && this.state[card]){
      return <IoIosArrowUp className='icon'/> 
    } else {
      return <IoIosArrowDown className='icon'/>
    }
  }

  handleGuidesCompleted = () => {
    let guide = {...this.props.guides};
    // guide.nutrition.guides.map(guide => {
      guide.nutrition.map(guide => {
      if(guide.text === this.state.guide){
        guide.completed = true
      }
    })
    this.props.updateGuides(guide);
    this.props.handleModalVisible(false)
  }

  handleRead = (guideName) => {
    let guides = {...this.props.guides};
    // let guide = guides.nutrition.guides.find(guide => guide.text === guideName);
    let guide = guides.nutrition.find(guide => guide.text === guideName);
    return guide.completed === true ? 'completed' : '';
  }

  render(){
    const { isPro } = this.props.auth.user;

    const terms = [
      {
        name: 'BMR/RMR',
        text: (
          <div>
            <p className='text mb-3'>
              Your BMR (basal metabolic rate) is the number of calories your body needs for essential life functions like breathing, cell production, circulation, and organ function. Think of it as the calories you'd need if you were asleep all day.
            </p>
            <p className='text mb-3'>
              RMR (resting metabolic rate) or REE (resting energy expenditure) are often used interchangeably with BMR, but there's a slight difference. RMR is the energy you burn when your body is at rest.
            </p>
            <p className='text'>
              For tracking your nutrition and calories, it's okay to consider them as the same thing.
            </p>
          </div>
        ),
      },
      {
        name: 'TDEE',
        text: (
          <div>
            <p className='text mb-3'>
              Your TDEE (total daily energy expenditure) is the total energy your body burns during a typical day of activity.
            </p>
            <p className='text mb-3'>
              We calculate your TDEE using your BMR and adding adjustments for your daily activity level, like exercise or the physical demands of your job. TDEE represents the realistic calorie intake you need to maintain your weight. Once we have your TDEE, we can create a calorie surplus or deficit to help you reach your weight goals.
            </p>
          </div>
        ),
      },
      {
        name: 'NEAT',
        text: (
          <div>
            <p className='text mb-3'>
              NEAT (Non-Exercise Activity Thermogenesis) is the energy you burn during physical activities that aren't considered eating, sleeping, or exercising. By incorporating small energy-burning activities into your day, we can boost your fat loss.
            </p>
            <p className='text'>
              Examples include fidgeting, pacing, or standing instead of sitting.
            </p>
          </div>
        ),
      },
      {
        name: 'Calorie Surplus',
        text: (
          <div>
            <p className='text mb-3'>
              A calorie surplus means you're consuming more calories than you need to maintain your weight. For example, if you require 2,000 calories a day to maintain your current weight, consuming 2,200 calories creates a surplus.
            </p>
            <p className='text'>
              Eating a calorie surplus is important for weight gain goals, like building muscle. It's also possible to gain muscle while losing fat.
            </p>
          </div>
        ),
      },
      {
        name: 'Calorie Deficit',
        text: (
          <div>
            <p className='text mb-3'>
              A calorie deficit occurs when you consume fewer calories than required to maintain your weight. For example, if you need 2,000 calories a day to maintain your current weight, consuming 1,800 calories creates a deficit.
            </p>
            <p className='text'>
              A calorie deficit is a key component of most weight loss plans.
            </p>
          </div>
        ),
      },
    ];
    const macros = [
      {
        name: 'Protein',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              Protein, made up of amino acids, has about 4 calories per gram. It's crucial for building and maintaining muscle and provides the most satiety compared to other macros. Ideally, your diet should consist of 10%-35% protein or about 0.36 grams of protein per pound of body weight per day. However, this can vary depending on your lifestyle and goals. If you're trying to build muscle, aim for 0.5 to 0.8 grams per body weight.
            </p>
            <p className='text mb-3'>
              It's important not to overdo protein. Your body can only absorb about 10 grams of protein per hour. So, if you eat more than about 25 grams per meal, it'll be broken down for energy, not muscle building. To build muscle, try spreading out your protein intake evenly throughout the day to maximize absorption.
            </p>
            <p className='text'>
              Common protein sources include meat, fish, poultry, and eggs. Cheese, nuts, beans, and legumes are also high in protein.
            </p>
          </div>
        ),
      },
      {
        name: 'Carbohydrates',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              Carbohydrates, containing 4 calories per gram, are the essential macro your body uses to create energy. They're also important for synthesizing certain amino acids, and fiber is crucial for regular bowel function. There are two main types of carbohydrates:
            </p>
            <p className='bold-text mb-3'>Simple</p>
            <p className='text mb-3'>
              Simple carbs include sweet things like sugar, honey, and fruit. They break down quickly and may cause blood sugar spikes. Eat simple carbs 30-60 minutes before a workout for a quick energy boost.
            </p>
            <p className='bold-text mb-3'>Complex</p>
            <p className='text'>
              Complex carbs include whole grains and starchy vegetables. These take longer for your body to break down, providing more sustained energy. Consume complex carbs 2-3 hours before you workout for long-lasting energy.
            </p>
          </div>
        ),
      },
      {
        name: 'Fat',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              Fat has 9 calories per gram and is essential for brain function and the absorption of certain fat-soluble vitamins such as vitamin A, D, E, and K. Aim for 20%-30% of your daily calories from fat, but this estimation can vary greatly depending on your diet or goals. For example, those on a keto diet aim to eat 70% or more of their daily calories from fat. Fat can be split up into 3 main categories:
            </p>
            <div>
              <div>
                <p className='bold-text mb-3'>Saturated Fat</p>
                <p className='text mb-3'>
                  Primarily from animal sources, saturated fat should be eaten in moderation.
                </p>
              </div>
              <div>
                <p className='bold-text mb-3'>Trans Fat</p>
                <p className='text mb-3'>
                  Artificially created and the most unhealthy. Avoid these fats.
                </p>
              </div>
              <div>
                <p className='bold-text mb-3'>Unssaturated Fat</p>
            <p className='text mb-3'>
              The healthiest type of fats, unsaturated fats can be further split up into polyunsaturated and monounsaturated. Both can lower bad cholesterol and are found in fish and plant sources.
            </p>
          </div>
        </div>
        <p className='text mb-3'>
          Sources of fat include butter, fish and fish oil, coconut oil, other oils, avocados, and nuts.
        </p>
      </div>
        ),
      },
      {
        name: 'Alcohol',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              Although not essential for life, alcohol is often considered a macronutrient because it still contains energy that your body uses and doesn't fall under the category of being a fat, protein, or carbohydrate. Alcohol has 7 calories per gram and is relevant because its relatively high calorie content affects your calorie count and macro ratio. It's contained in alcoholic drinks and should be consumed in moderation.
            </p>
          </div>
        ),
      },
    ];

    const mealPreppingSteps = [
      {
        name: 'Planning',
        img: '',
        className: 'with-card',
        text: (
          <div>
            <p className='text mb-3'>
              Start your meal prep by planning out the foods you'll eat over the next week, 2 weeks, or any time frame you choose. Keep in mind your target macro ratio, calorie requirements, and other nutritional needs established by your coach.
            </p>
            <p className='text mb-3'>
              Make sure you have a food scale, containers, measuring tools, and space available in your refrigerator or freezer to store your premade meals. Set aside a day to prepare your meals for the upcoming week.
            </p>
          </div>
        ),
      },
      {
        name: 'Shopping',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              After you have your meals planned, create a shopping list with all the ingredients you'll need. Use the "Add to shopping list" button in your program page to select how many days you want to shop for, and we'll calculate the amounts you need. Setting aside time to shop for your food is key so you have everything you need when it's time to prepare your meals.
            </p>
          </div>
        ),
      },
      {
        name: 'Food Scale & Measuring Cups',
        img: '',
        className: 'with-card',
        text: (
          <div>
            <p className='text mb-3'>
              A great and easy way to measure calories and macros is to buy a food scale to weigh your food portions. Calorie and macro counts that rely on weight are a lot more accurate than estimations like '1 apple' or a 'handful of almonds'.
            </p>
          </div>
        ),
      },
      {
        name: 'Measuring Food',
        img: '',
        text: (
          <div>
            <p className='text mb-3'>
              To get the most accurate measurements, weigh out each of your individual food items as assigned by your coach. Make sure to measure them in the state listed on your plan, as this will be important for consistency. For example, 90% Lean Ground Beef raw will have a different weight than 90% Lean Ground Beef cooked.
            </p>
          </div>
        ),
      },
      {
        name: 'Seasoning and Sauces Do\'s and Don\'ts',
        img: '',
        className: 'with-card',
        text: (
          <div>
            <p className='text mb-3'>
              When preparing or eating your meals, be cautious with sauces and seasonings. Some may heavily skew your total calories and macros. For instance, just a tablespoon of mayonnaise is 94 calories.
            </p>
            <p className='text mb-3'>
              As a general rule, if you're unsure about the calorie content of a certain sauce, seasoning, or salad dressing, it's better to exclude it from your plan. Over time, you'll learn which ones have minimal impact and which ones pack a heavier punch.
            </p>
            <p className='text mb-3'>
              Here are some guidelines to help you get started.
            </p>
            <div className='flex mb-3'>
              <div>
                <p className='subtitle'>Do</p>
                <ul>
                  <li className='text green'>Add dried spices like cumin, pepper, paprika, garlic, etc., especially if they're under 1 tablespoon</li>
                  <li className='text green'>Not worry about miniscule amounts such as a sprinkle, or a teaspoon or less</li>
                  <li className='text green'>Not agonize over small amounts of seasonings that have less than 5 calories per serving</li>
                </ul>
              </div>
              <div>
                <p className='subtitle'>Don't</p>
                <ul>
                  <li className='text red'>Add sauces, salad dressings, oils, butters, and dips—anything rich, heavy, or oily that is not listed in your plan</li>
                  <li className='text red'>Add things you're unsure of</li>
                </ul>
              </div>
            </div>
            <div>
              <p className='bold-text mb-3'>Diet-Friendly Sauces and Seasonings</p>
              <p className='text mb-3'>The following sauces and seasonings are relatively low calorie and won't significantly impact your macros or calories.</p>
              <ul className='text'>
                <li className='green'>Simple hot sauces like Tabasco, Cholula, or Sriracha</li>
                <li className='green'>Dried spices (pepper, garlic, cayenne, cumin, turmeric, red pepper flakes, etc)</li>
                <li className='green'>Mustard</li>
                <li className='green'>Unsweetened Salsa</li>
                <li className='green'>Vinegars like apple cider, rice, or balsamic vinegar</li>
                <li className='green'>Nutritional yeast</li>
                <li className='green'>Soy sauce</li>
                <li className='green'>Oyster and fish sauce</li>
                <li className='green'>Worcestershire sauce</li>
                <li className='green'>Salt (if sodium is not a concern)</li>
                <li className='green'>Green chile sauce</li>
                <li className='green'>Non-sugar natural sweeteners like stevia</li>
              </ul>
            </div>
          </div>
        ),
      },
    ]

    const whyItWorks = [
      {
        name: 'Flexibility', img: '', text: (
          <div>
            <p className='text mb-3'>
              Rigid, unrealistic diet and fitness plans often lead to failure. They create a mentality where you feel like you should give up entirely just because you made a small mistake. Our program offers flexibility, allowing you to build a routine that works for you and take it day by day.
            </p>
          </div>
        ),
      },
      {
        name: 'Personal Coaching', img: '', text: (
          <div>
            <p className='text mb-3'>
              Our personal coaches monitor your progress, so you don't have to worry about doing it yourself. Instead of relying solely on a computer to tell you what to eat, we use a combination of technology and personal coaching to ensure all your needs are met. If you encounter a problem, you have someone to talk to who can help you work through it.
            </p>
          </div>
        ),
      },
      {
        name: 'Personalization', img: '', text: (
          <div>
            <p className='text mb-3'>
              Customize your program to fit your needs, from adjusting goals to choosing food preferences.
            </p>
          </div>
        ),
      },
      {
        name: 'Regular Updates', img: '', text: (
          <div>
            <p className='text mb-3'>
              Our program adapts to your body and lifestyle. Your calories and macros are regularly updated as your weight changes, preventing plateaus and ensuring continuous progress.
            </p>
          </div>
        ),
      },
    ]
    const moreAbout = [
      {
        name: 'Alternative Foods', img: '', text: (
          <div>
            <p className='text mb-3'>
              One great feature of our program is that we offer the option to swap your daily foods. If there’s a food option on your plan that you don’t have available that day, you can swap it with one of the other options.
            </p>
            <p className='text'>
              Just remember to fill in all your favorite foods into your profile, and your coach will do the rest for you—including portion calculations and when it’s applicable to swap.
            </p>
          </div>
        ),
      },
      {
        name: 'Recipes', img: '', text: (
          <div>
            <p className='text mb-3'>
              Our program has access to a database of online tasty recipes you can make that fulfill your calorie and macro requirements, all while using your favorite foods.
            </p>
          </div>
        ),
      },
      {
        name: 'Meal Timing', img: '', text: (
          <div>
            <p className='text mb-3'>
              Counting what goes into your meals is just as important as counting when you eat your meal, especially when you consider adding workouts to your schedule.
            </p>
            <p className='text mb-3'>
              When you exercise, glycogen gets partially depleted from your muscles. In order to replenish these stores and help increase muscle growth and repair, eat a post-workout meal emphasizing protein and carbohydrates within 45 minutes after you exercise. Protein helps repair muscle, while carbohydrates help replenish energy.
            </p>
            <p className='text'>
              As for your pre-workout meal, a well-balanced meal 2-3 hours before you plan to exercise is a great idea. This will help give your body the fuel it needs to work hard. If you cannot fit a full meal into this time window, try eating a light, easy to digest snack 30 minutes to an hour before your workout.
            </p>
          </div>
        ),
      },
      {
        name: 'Supplementation', img: '', text: (
          <div>
            <p className='text mb-3'>
              While supplements may be important for people who have deficiencies or are trying to hit certain nutritional benchmarks, they aren’t absolutely necessary. Most people can usually get everything they need from a whole, varied diet, and don’t need to take supplements.
            </p>
            <p className='text'>
              That said, some supplements can be helpful, and whether or not you decide to take them is ultimately your choice.
            </p>
          </div>
        ),
      },
      {
        name: 'Supplement Timing', img: '', text: (
          <div>
            <p className='text mb-3'>
              If you do choose to take supplements, timing them is important. Certain supplements should be taken with certain foods or other supplements to maximize absorption or prevent blocking absorption. Other supplements are simply more effective when taken at certain times in the day, or certain times in relation to your workout.
            </p>
            <p className='text mb-3'>
              Here are some basic tips:
            </p>
            <ul className='text'>
              <li className='green'>Fish oil and other oil-based supplements are best absorbed with meals</li>
              <li className='green'>Fat burners should be taken either in the morning before breakfast or before your workout</li>
              <li className='green'>Workout drinks are best when taken before your workout</li>
              <li className='green'>Protein drinks are best taken rightafter your workout</li>
              <li className='green'>Creatine is best when taken shortly before or after you exercise</li>
              <li className='green'>Glutamine is best when taken post-workout</li>
              <li className='green'>Amino acids are best taken shortly after your workout, but can also be taken shortly before or during</li>
            </ul>
            <p className='text mb-3'>
              That said, some supplements can be helpful, and whether or not you decide to take them is ultimately your choice.
            </p>
          </div>
        ),
      },
      {
        name: 'Preparing for the unexpected', img: '', text: (
          <div>
            <p className='text mb-3'>
              Sometimes, unexpected hiccups can cause you to fall off track. It could be something small, like not having a certain food that’s on your plan one day, or it can be something big, like going on vacation to a hotel with an all-inclusive buffet.
            </p>
            <p className='text mb-3'>
              Whatever happens, remember that mess-ups happen to everyone, and it’s okay to make mistakes here and there. What’s most important is getting back on track as soon as possible and keeping sight of your goals. Don’t let one bad day derail you for the next 3 months. Think of your progress in terms of weeks or months, not days.
            </p>
            <p className='text mb-3'>
              Luckily, when you have a flexible program like ours, your coach can adapt your daily food and exercise to fit whatever happens in your schedule. You can easily swap foods with ones you have more readily available, and you can adjust your plan when your schedule or goals change. You can also talk to your coach if you have any problems or questions, and we can help you tweak your plan.
            </p>
          </div>
        ),
      },
      {
        name: 'Water intake', img: '', text: (
          <div>
            <p className='text mb-3'>
              Water intake is an often overlooked part of staying fit and healthy. Water is necessary for carrying out all kinds of bodily functions, including:
            </p>
            <ul className='text'>
              <li className='green'>Normalizing blood pressure</li>
              <li className='green'>Maintaining electrolyte balance</li>
              <li className='green'>Flushing bacteria from your bladder</li>
              <li className='green'>Regulating body temperature</li>
              <li className='green'>And more</li>
            </ul>
            <p className='text mb-3'>
              It's recommended that you drink at least 4-6 cups of water daily, although these needs can vary depending on your exercise and activity level, whether it’s hot out, medical conditions, and other factors.
            </p>
            <p className='text mb-3'>
              Try to have a glass of water with each meal, and remember to stay hydrated while you’re exercising.
            </p>
            <p className='text mb-3'>
              While other beverages, such as juice, coffee, and shakes do contain water as well, they can come with other properties that may not be desired. For instance, many drinks have extra calories you may not need, and caffeinated beverages can actually make you more dehydrated. That’s why it’s important to try to get a few glasses of water every day.
            </p>
          </div>
        ),
      },
    ]
    

    return(
      <>
        <ConfirmModal 
          title={`Are you done reading about ${this.state.guide}?`} 
          subtitle={'You can come back to this section at any time.'} 
          action={() => this.handleGuidesCompleted()} 
          btn={{cancel: 'Cancel', confirm: 'Done'}}
          btnStyle={{background: '#2761f1', color: 'white'}}
          cancel={() => this.props.handleModalVisible(false)}
          isVisible={this.props.confirmModalVisible}
        />
      <div className='card-container'>
        <div className={`expandable-card ${this.handleRead('Nubod Macro Calculator')} ${this.state.calculator ? 'open' : ''}`} onClick={() => this.setState({calculator: !this.state.calculator})}>
          <div className='flex space-between'>
            <p className='bold-text'>Nubod Macro Calculator</p>
            {this.state.calculator ? <IoIosArrowUp className='icon'/> : <IoIosArrowDown className='icon'/> }
          </div>
        </div>
        <div className={`${this.state.calculator ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Nubod Macro Calculator')}`}>
          <div className='section'>
            <p className='text mb-3'>Discover the Nubod Macro Calculator, designed to help you track your macronutrient intake based on your preferences and body's fluctuations. Although not 100% accurate, it's a valuable starting point when combined with expert coaching.</p>
            <img src={Calculator} className='img' />
          </div>
          
          <div>
            <p className='section-title highlight'>How it works</p>
            <p className='text mb-3 mt-4'>
              The calculator uses the Mifflin St. Jeor equation to estimate your resting metabolic rate (RMR/BMR). It then calculates maintenance calories by factoring in your activity level, and adjusts macro percentages based on your chosen plan (low carb, high carb, etc.).
            </p>
            <div className='column-container'>
              <div className='global-card flex flex-column justify-center'>
                <img src={Male} className='img-small'/>
                <p className='subtitle'>Men</p>
                <p className='bold-text'>(10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) + 5</p>
              </div>
              <div className='global-card flex flex-column justify-center'>
                <img src={Female} className='img-small'/>
                <p className='subtitle'>Women</p>
                <p className='bold-text'>(10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) - 161</p>
              </div>
            </div>
            <p className='text mb-3'>While this formula provides a starting point, it doesn't account for factors such as lean body mass percentage, genetics, sleep, or lifestyle habits. That's why having a coach to oversee and adjust your macros as needed is crucial for accuracy.</p>
          </div>
          <Divider style={{margin: '20px 0 20px 0'}}/>
          <Button 
            className='completed-btn'
            onClick={() => {this.setState({guide: 'Nubod Macro Calculator'}); this.props.handleModalVisible(true)}} 
            type='ghost'
            disabled={this.handleRead('Nubod Macro Calculator') === 'completed'}
          >
            {this.handleRead('Nubod Macro Calculator') === 'completed' ? 
            <div className='flex flex-center-aligned'>
              <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
              <p>Done</p>
              </div> 
              : 'Mark as completed'}
          </Button>
        </div>
      </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.handleRead('Important Terms To Know')} ${this.state.terms ? 'open' : ''}`} onClick={() => isPro && this.setState({terms: !this.state.terms})}>
            <div className='flex space-between'>
              <p className='bold-text'>Important Terms To Know</p>
              {this.renderCardIcon('terms')}
            </div>
          </div>
          <div className={`${this.state.terms ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Important Terms To Know')}`}>
            <p className='text mb-3'>The following are some common terms you’ll encounter with nutrition and weight management that are useful to know.</p>
            {terms.map(t => 
            <>
              <div >
                <p className='subtitle mb-3'>{t.name}</p>
                {t.text}
              </div>
              <Divider style={{margin: '20px 0'}}/>
            </>)}
            <Button 
              className='completed-btn'
              onClick={() => {this.setState({ guide: 'Important Terms To Know'}); this.props.handleModalVisible(true)}} 
              type='ghost'
              disabled={this.handleRead('Important Terms To Know') === 'completed'}
            >
               {this.handleRead('Important Terms To Know') === 'completed' ? 
               <div className='flex flex-center-aligned'>
                 <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
                 <p>Done</p>
                </div> 
                : 'Mark as completed'}
            </Button>
          </div>
        </div>
        
        <div className='card-container'>
          <div className={`expandable-card ${this.handleRead('Weight Loss and Weight Gain')} ${this.state.lossAndGain ? 'open' : ''} ${this.handleRead('Weight Loss and Weight Gain')}`} onClick={() => isPro && this.setState({lossAndGain: !this.state.lossAndGain})}>
            <div className='flex space-between'>
              <p className='bold-text'>Weight Loss and Weight Gain</p>
              {this.renderCardIcon('lossAndGain')}
            </div>
          </div>
          <div className={`${this.state.lossAndGain ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Weight Loss and Weight Gain')}`}>
            <div className='section'>
              <p className='text mb-3'>Weight loss and weight gained is determined by how much energy you consume vs how much energy your body burns.</p>
              <p className='text mb-3'>You should aim to stay in a calorie deficit for weight loss, and a calorie surplus for weight gain. For maintaining your current weight, eat just enough food to cover what your body burns. You can determine what these calorie amounts are by using our Nubod Macro Calculator.</p>
              <p className='text mb-3'>For healthy and sustainable change, it is generally recommended that you plan to lose no more than 2 pounds a week, although aiming for 1 or less is ideal. To do this, it is recommended to only reduce your daily calories by 500 or fewer. Eating too little can result in unnecessary hunger and stress, and is not sustainable in the long run.</p>
              <p className='text mb-3'>For weight gain, the recommendations are more loose, but it is still a good idea to take it slowly as you don’t want to add too much body fat in the process of gaining weight and adding muscle mass.</p>
              <p className='text mb-3'>Another thing you may want to consider while losing or gaining weight is body composition. Exercising more, especially with resistance training, and eating a higher-protein diet will result in building more muscle.</p>
              <p className='text'>However, you shouldn’t worry about any of this too much—your coach will design a plan to fit your goals that takes all these factors into account.</p>
            </div>
            <p className='section-title highlight'>Why Eating Less Is Not Always Better for Weight Loss</p>
            <div>
              <p className='text mb-3 mt-4'>Many people make the reasonable mistake of assuming that if a regular calorie deficit results in weight loss, then a big calorie deficit will result in faster weight loss. While this may make sense intuitively, it is important that you stick to the recommended calorie intake and macros your coach gives you. </p>
              <p className='text mb-3'>Eating too little calories can have many adverse effects, including:</p>
              <ul className='text'>
                <li>Feelings of weakness, fatigue, and irritation</li>
                <li>Decline in physical strength</li>
                <li>Moodiness, depression, and decline in mental sharpness</li>
                <li>Weakened immunity</li>
                <li>Vitamin deficiencies, which can lead to a host of other effects</li>
                <li>Decline in muscle mass, or loss of muscle instead of fat</li>
                <li>Slower metabolism, which makes it so your body burns calories less efficiently</li>
              </ul>
              <p className='text'>Most importantly, eating too little can cause your body to overcompensate and make you hungrier, which leads to eating more later on. Over weeks or months, this can cause an overall result of eating a calorie surplus rather than a deficit, or getting trapped in a cycle of binge eating, then over-restricting to get back on track. In the end, this type of system will usually make you maintain or gain weight. For sustained, permanent weight loss, it is much better to slowly eat a modest calorie deficit every day over a longer period of time.</p>
            </div>
            <Divider style={{margin: '20px 0 20px 0'}}/>
            <Button 
              className='completed-btn'
              onClick={() => {this.setState({guide: 'Weight Loss and Weight Gain'}); this.props.handleModalVisible(true)}} 
              type='ghost'
              disabled={this.handleRead('Weight Loss and Weight Gain') === 'completed'}
            >
              {this.handleRead('Weight Loss and Weight Gain') === 'completed' ? 
              <div className='flex flex-center-aligned'>
                <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
                <p>Done</p>
              </div> 
              : 'Mark as completed'}
            </Button>
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.caloriesAndMacros ? 'open' : ''} ${this.handleRead('Calories and Macronutrients')}`} onClick={() => isPro && this.setState({caloriesAndMacros: !this.state.caloriesAndMacros})}>
            <div className='flex space-between'>
              <p className='bold-text'>Calories and Macronutrients</p>
              {this.renderCardIcon('caloriesAndMacros')}
            </div>
          </div>

          <div className={`${this.state.caloriesAndMacros ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Calories and Macronutrients')}`}>
            <div className='section'>
              <p className='text mb-3'>Calories and macronutrients are important for reaching any type of fitness goal. Read on to find out more about them.</p>
            </div>
            <div className='section'>
              <p className='section-title highlight'>Calories</p>
              <p className='text mb-3 mt-4'>A calorie is a unit of energy defined as the amount of heat that is needed to raise the temperature of a specific quantity of water by one degree. In terms of nutrition, the type of calorie most often used is called a Kilocalorie (Kcal or just Cal), and refers to the amount of heat needed to raise a kilogram of water by one degree.</p>
              <p className='text mb-3'>However, for the purposes of nutrition and fitness, you don’t have to worry about any of that. What you need to know is this:</p>
              <ul className='text'>
                <li>Every food contains a certain amount of calories</li>
                <li>Every 24 hours, your body burns a certain amount of calories</li>
                <li>You can control your weight and body composition by consuming more or less calories in food than your body needs to maintain your current weight</li>
              </ul>
            </div>
            <div className='section'>
              <img src={MacrosImg} style={{borderRadius: '38px', width: '100%'}}/>
            </div>
            <p className='section-title highlight'>Macronutrients</p>
            <div className='section'>
              <p className='text mb-3 mt-4'>Macronutrients are what food is made out of. Your body relies on the energy that macronutrients provide in order to survive. Each macronutrient has different properties and health effects on your body. The three primary macronutrients are carbs, protein, and fat. Alcohol is also considered a macro.</p>
              <p className='text'>You can manipulate your macronutrient ratio to achieve different goals. For instance, eating a high-protein diet results in easier muscle gain. Eating a high-fat diet may help reduce inflammation.</p>
            </div>
            {macros.map(macro => 
            <>
              <div>
                <p className='subtitle'>{macro.name}</p>
                {macro.text}
              </div>
              <Divider style={{margin: '20px 0 20px 0'}}/>
            </>)}
            <Button 
              className='completed-btn'
              onClick={() => {this.setState({guide: 'Calories and Macronutrients'}); this.props.handleModalVisible(true)}} 
              type='ghost'
              disabled={this.handleRead('Calories and Macronutrients') === 'completed'}
            >
              {this.handleRead('Calories and Macronutrients') === 'completed' ? 
              <div className='flex flex-center-aligned'>
                <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
                <p>Done</p>
              </div> 
              : 'Mark as completed'}
            </Button>
          </div>
        </div>
 
        <div className='card-container'>
          <div className={`expandable-card ${this.state.mealPrepping ? 'open' : ''} ${this.handleRead('Meal Prepping')}`} onClick={() => isPro && this.setState({mealPrepping: !this.state.mealPrepping})}>
            <div className='flex space-between'>
              <p className='bold-text'>Meal Prepping</p>
              {this.renderCardIcon('mealPrepping')}
            </div>
          </div>

          <div className={`${this.state.mealPrepping ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Meal Prepping')}`}>
            <div className='section'>
              <p className='text mb-3'>Meal prepping is essential to the success of any program or fitness plan. Here are some suggestions on how to meal prep successfully.</p>
            </div>
            {mealPreppingSteps.map(step => 
            <>
              <div>
                <p className='subtitle'>{step.name}</p>
                {step.text}
              </div>
              <Divider style={{margin: '20px 0 20px 0'}}/>
            </>)}
            <Button 
              className='completed-btn'
              onClick={() => {this.setState({guide: 'Meal Prepping'}); this.props.handleModalVisible(true)}} 
              type='ghost'
              disabled={this.handleRead('Meal Prepping') === 'completed'}
            >
              {this.handleRead('Meal Prepping') === 'completed' ? 
              <div className='flex flex-center-aligned'>
                <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
                <p>Done</p>
              </div> 
              : 'Mark as completed'}
            </Button>
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.yourProgram ? 'open' : ''} ${this.handleRead('Your Nutrition Program')}`} onClick={() => isPro && this.setState({yourProgram: !this.state.yourProgram})}>
            <div className='flex space-between'>
              <p className='bold-text'>Your Nutrition Plan</p>
              {this.renderCardIcon('yourProgram')}
            </div>
          </div>

          <div className={`${this.state.yourProgram ? 'open-card-content' : 'open-card-content none'} ${this.handleRead('Your Nutrition Program')}`}>
            <div className='section'>
              <p className='text mb-3'>Your nutrition program is the main way through which we help you succeed in your goals. We strive to create a nutrition program that is flexible with your lifestyle, preferences, and the fluctuations of your body.</p>
            </div>

            <div className='section'>
              <p className='section-title highlight'>How it works</p>
              <p className='text mb-3 mt-4'>Our program is designed to be customizable and to work with the resources available to you. Through our assessment, we determine your calories and macronutrient needs.</p>
              <p className='text mb-3'>Then, your coach looks through the information you’ve entered and the results calculated by our macro calculator. Adjustments are made if necessary, and your coach starts designing a custom program that fits with your needs, goals, lifestyle, and preferences. The program comes complete with calorie counts, macros, and food options that are easy to understand and follow. Everything is reviewed and finalized by your coach before being given to you, to ensure your unique goals and situation are being fully considered. </p>
              <p className='text mb-3'>As you progress in your journey, you will get notified when it is time to enter a new weight, usually every two weeks. Then your coach will adjust your calories and macros if necessary so you can continue to make progress towards your goals. You can choose what foods to include, what meals to focus on, and pretty much everything else—our coaches just make sure you are eating and exercising in a way that works toward your goals!</p>
            </div>

            <div className='section'>
              <p className='section-title highlight'>Why it works</p>
              <p className='text mb-3 mt-4'>The main reasons our program works so well are as follows.</p>
            </div>
            
            {whyItWorks.map(why => 
            <>
              <div>
                <p className='subtitle'>{why.name}</p>
                {why.text}
              </div>  
              <Divider style={{margin: '20px 0 20px 0'}}/>
            </>)}

            <div className='section'>
              <p className='section-title highlight'>More about your program</p>
              <p className='text mb-3 mt-4'>Our program is designed to be customizable and to work with the resources available to you. Through our assessment, we determine your calories and macronutrient needs.</p>
              <p className='text mb-3'>Then, your coach looks through the information you’ve entered and the results calculated by our macro calculator. Adjustments are made if necessary, and your coach starts designing a custom program that fits with your needs, goals, lifestyle, and preferences. The program comes complete with calorie counts, macros, and food options that are easy to understand and follow. Everything is reviewed and finalized by your coach before being given to you, to ensure your unique goals and situation are being fully considered.</p>
              <p className='text mb-3'>As you progress in your journey, you will get notified when it is time to enter a new weight, usually every two weeks. Then your coach will adjust your calories and macros if necessary so you can continue to make progress towards your goals. You can choose what foods to include, what meals to focus on, and pretty much everything else—our coaches just make sure you are eating and exercising in a way that works toward your goals!</p>
            </div>
            
            {moreAbout.map(about => 
            <>
              <div>
                <p className='subtitle'>{about.name}</p>
                {about.text}
              </div>  
              <Divider style={{margin: '20px 0 20px 0'}}/>
            </>)}

            <Button 
              className='completed-btn'
              onClick={() => {this.setState({guide: 'Your Nutrition Program'}); this.props.handleModalVisible(true)}} 
              type='ghost'
              disabled={this.handleRead('Your Nutrition Program') === 'completed'}
            >
              {this.handleRead('Your Nutrition Program') === 'completed' ? 
              <div className='flex flex-center-aligned'>
                <IoMdCheckmarkCircle style={{fontSize: '22px', color: 'white', marginRight: '5px'}} />
                <p>Done</p>
              </div> 
              : 'Mark as completed'}
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default Nutrition;