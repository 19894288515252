import React from 'react';
import { connect } from 'react-redux'; 
import { getUserShoppingListAggregate, getShoppingList, deleteFromShoppingList, toggleShoppingListItem, updateCustomShoppingListItem } from "../../ducks/Suggestic/actions";
import CustomShoppingPage from '../../presentational/CustomShoppingPage';
import { Loading } from '../../components/Loading';
import EmptyShopping from '../../assets/images/empty-cart.svg';
import Shopping from '../../assets/images/shoppingList.png'

class CustomShopping extends React.Component {

  componentDidMount(){
    this.props.getShoppingList();
  }

  renderSplash = () => {
    return(
      <div style={{padding: '50px 0 50px 0'}} className='flex flex-column flex-center-aligned'>
        <img src={Shopping} alt="Empty" style={{height: '200px', marginBottom: '15px'}}/>
        <p>Your shopping list is empty.</p>
      </div>
    )
  }

  renderShoppingPage = () => {
    const { shoppingList, shoppingListLoading } = this.props;
    if(shoppingListLoading){
      return <Loading />;
    } else if(!shoppingListLoading && shoppingList && shoppingList.length > 0){
      return <CustomShoppingPage {...this.props} />;
    } else if(!shoppingListLoading && shoppingList && shoppingList.length === 0){
      return this.renderSplash();
    }
  }

  render(){
    return(
      <div className='site-layout-background shopping-list sub-menu'>
        {this.renderShoppingPage()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shoppingList: state.suggestic.shoppingList,
    shoppingListLoading: state.suggestic.shoppingListLoading,
    shoppingListAggregate: state.suggestic.shoppingListAggregate,
    shoppingListAggregateLoading: state.suggestic.shoppingListAggregateLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getShoppingList: () => dispatch(getShoppingList()),
    deleteFromShoppingList: (id) => dispatch(deleteFromShoppingList(id)),
    getUserShoppingListAggregate: () => dispatch(getUserShoppingListAggregate()),
    toggleShoppingListItem: (itemId, isAggregate) => dispatch(toggleShoppingListItem(itemId, isAggregate)),
    updateCustomShoppingListItem: (item) => dispatch(updateCustomShoppingListItem(item))
  };  
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomShopping);