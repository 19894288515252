import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { Button, Modal, Input, Alert, Select } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordField from '../../components/AuthModal/PasswordField';
import { createNewUser } from '../../ducks/Profile/actions';

const { Option } = Select;

class CreateUserModal extends React.Component {
  constructor(){
    super();
    this.state = {
      userData: null,
      errors: [],
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.createNewUserSuccess !== this.props.createNewUserSuccess && this.props.createNewUserSuccess) {
      this.props.toggleCreateUserModal(false)
      this.setState({ userData: null });
      
      toast.success('User has been successfully created!');
    }
  }
  
  handleUserChange = (e, field) => {
    let userData = { ...this.state.userData };
    userData[field] = field === 'userType' ? e : e.target.value;

    const errors = [];
    if (!userData.name || !userData.lastName || !userData.email || !userData.userType || !userData.password) {
      errors.push('Please fill the required fields')
    } else {
      if (!userData.password || (userData.password && userData.password.length === 0)) {
        errors.push('Please enter password')
      } else if (userData.password && userData.password.length < 6) {
        errors.push('Password length must be greater than or qual to 6')
      }
    }  
    this.setState({
      userData: userData,
      errors
    });
  };

  handleSaveBtnClick = async(userData) => {
    if (Object.keys(userData).length === 0) {
      const errors = [];
      if (!userData.name || !userData.lastName || !userData.email || !userData.userType || !userData.password) {
        errors.push('Please fill the required fields')
      }
      this.setState({
        errors
      });
    } else {
      this.props.createNewUser({ ...userData });
    }
  }

  renderInputField = (userData, fieldName, label, type = 'text') => {
    return (
        <div className="form-section">
          <p className="label">*{label}</p>
          {
            <Input
              type={type}
              onChange={(e) => this.handleUserChange(e, fieldName)}
              value={
                userData && userData[fieldName]
                  ? userData[fieldName]
                  : ""
              }
            />
          }
        </div>
    );
  }

  renderSelectField = (userData, fieldName, label, type = 'text') => {

    const options = [
      {key: 1, value: 'online', text: 'Online Coaching'},
      {key: 2, value: 'onsite', text: 'On-site Coaching'},
      {key: 3, value: 'fixed', text: 'Fixed Program'}
    ]

    return (
        <div className="form-section">
          <p className="label">*{label}</p>
          {
            <Select
              placeholder='Select the program type'
              style={{ width: '100%' }}
              type={type}
              onChange={(e) => this.handleUserChange(e, fieldName)}
              value={
                userData && userData[fieldName]
                  ? userData[fieldName]
                  : ""
              }
            >
              {options.map((option) => (
                <Option key={option.key} value={option.value}>
                  {option.text}
                </Option>
              ))}
            </Select>
          }
        </div>
    );
  }

  generateRandomPassword = (length = 8) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  

  render(){
    const { userData } = this.state;

    return (
      <Modal
        title={this.props.redirect ? 'Create New User' : ''}
        visible={this.props.visible}
        onCancel={() => this.props.toggleCreateUserModal(false)}
        footer={null}
        centered={true}
      >
        <div className='modal'>
            {this.renderInputField(userData, 'name', 'First name')}
            {this.renderInputField(userData, 'lastName', 'Last name')}
            {this.renderInputField(userData, 'email', 'Email')}
            {this.renderSelectField(userData, 'userType', 'User type')}
            <div className="form-section">
              <p className="label">*Password</p>
              {
                <div className='flex'>
                  <PasswordField
                    key={"password"}
                    onChange={(e) => this.handleUserChange(e, "password")}
                    value={
                      userData && userData["password"]
                        ? userData["password"]
                        : ""
                      }
                  />
                  <Button
                    type="primary"
                    className="generate-password-btn"
                    onClick={() => {
                      const newPassword = this.generateRandomPassword();
                      this.handleUserChange({ target: { value: newPassword } }, "password");
                    }}
                  >
                  Generate
                </Button>
              </div>
              }
            </div>
        </div>
        <div className="week-buttons text-right">
            <Button
              onClick={() => this.handleSaveBtnClick({ ...this.state.userData })}
              type="primary"
              className="save-btn-modal"
              loading={this.props.createNewUserLoading}
              disabled={this.props.createNewUserLoading || this.state.errors.length}
            >
              Create
            </Button>
            <Button type="secondary" className="save-btn-modal" onClick={() => this.props.toggleCreateUserModal(false)}>Cancel</Button>
        </div>
        {this.state.errors.length > 0 && (
          <Alert
            type="error"
            message={
              this.state.errors.map((err, key) => (
                <p key={key}>{err}</p>
              ))
            }
          />
        )}
        {this.props.error && !this.state.errors.length && (
          <Alert
            type="error"
            message={
              this.props.error.errors &&
              Object.values(this.props.error.errors).map((err, key) => (
                <p key={key}>{err.msg}</p>
              ))
            }
          />
        )}
        {this.props.createNewUserError && (
          <Alert
            type="error"
            message={
              this.props.createNewUserError.errors &&
              Object.values(this.props.createNewUserError.errors).map((err, key) => (
                <p key={key}>{err.msg}</p>
              ))
            }
          />
        )}
        {this.props.successMessage && (
          <Alert
            type="success"
            message={this.props.successMessage}
          />
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    createNewUserLoading: state.profile.createNewUserLoading,
    createNewUserSuccess: state.profile.createNewUserSuccess,
    createNewUserError: state.profile.createNewUserError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createNewUser: (userData) => dispatch(createNewUser(userData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
