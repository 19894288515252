export const GET_EXERCISES = 'exercise/GET_EXERCISES';
export const GET_EXERCISES_SUCCESS = 'exercise/GET_EXERCISES_SUCCESS';
export const GET_EXERCISES_ERROR = 'exercise/GET_EXERCISES_ERROR';

export const CREATE_EXERCISE = 'exercise/CREATE_EXERCISE';
export const CREATE_EXERCISE_SUCCESS = 'exercise/CREATE_EXERCISE_SUCCESS';
export const CREATE_EXERCISE_ERROR = 'exercise/CREATE_EXERCISE_ERROR';

export const UPDATE_EXERCISE = 'exercise/UPDATE_EXERCISE';
export const UPDATE_EXERCISE_SUCCESS = 'exercise/UPDATE_EXERCISE_SUCCESS';
export const UPDATE_EXERCISE_ERROR = 'exercise/UPDATE_EXERCISE_ERROR';

export const DELETE_EXERCISE = 'exercise/DELETE_EXERCISE';
export const DELETE_EXERCISE_SUCCESS = 'exercise/DELETE_EXERCISE_SUCCESS';
export const DELETE_EXERCISE_ERROR = 'exercise/DELETE_EXERCISE_ERROR';

export const SET_EDIT_EXERCISE  = 'exercise/SET_EDIT_EXERCISE';
export const HANDLE_CREATE_OR_EDIT_EXERCISE  = 'exercise/HANDLE_CREATE_OR_EDIT_EXERCISE';
export const CLEAR_EDIT_EXERCISE  = 'exercise/CLEAR_EDIT_EXERCISE';
export const SET_IS_EXERCISE_EDIT  = 'exercise/SET_IS_EXERCISE_EDIT';

export const SAVE_EXERCISE_HISTORY = 'exercise/SAVE_EXERCISE_HISTORY';
export const SAVE_EXERCISE_HISTORY_SUCCESS = 'exercise/SAVE_EXERCISE_HISTORY_SUCCESS';
export const SAVE_EXERCISE_HISTORY_ERROR = 'exercise/SAVE_EXERCISE_HISTORY_ERROR';

export const GET_EXERCISE_HISTORY_BY_IDS = 'exercise/GET_EXERCISE_HISTORY_BY_IDS';
export const GET_EXERCISE_HISTORY_BY_IDS_SUCCESS = 'exercise/GET_EXERCISE_HISTORY_BY_IDS_SUCCESS';
export const GET_EXERCISE_HISTORY_BY_IDS_ERROR = 'exercise/GET_EXERCISE_HISTORY_BY_IDS_ERROR';

export const GET_EXERCISES_HISTORY_BY_IDS_ARR = 'exercise/GET_EXERCISES_HISTORY_BY_IDS_ARR';
export const GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS = 'exercise/GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS';
export const GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR = 'exercise/GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR';

export const GET_ALL_EXERCISE_HISTORY_BY_IDS = 'exercise/GET_ALL_EXERCISE_HISTORY_BY_IDS';
export const GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS = 'exercise/GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS';
export const GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR = 'exercise/GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR';

export const GET_EXERCISE_CATEGORIES = 'exercise/GET_EXERCISE_CATEGORIES';
export const GET_EXERCISE_CATEGORIES_SUCCESS = 'exercise/GET_EXERCISE_CATEGORIES_SUCCESS';
export const GET_EXERCISE_CATEGORIES_ERROR = 'exercise/GET_EXERCISE_CATEGORIES_ERROR';

export const GET_MUSCLES = 'exercise/GET_MUSCLES';
export const GET_MUSCLES_SUCCESS = 'exercise/GET_MUSCLES_SUCCESS';
export const GET_MUSCLES_ERROR = 'exercise/GET_MUSCLES_ERROR';

export const SET_SINGLE_USER_EXERCISE_HISTORY = 'exercise/SET_SINGLE_USER_EXERCISE_HISTORY';