import React from 'react';
import './index.css';
import {getEmptyPlanInRange, getRandomizeFood} from '../../helpers/utils'
import { Link } from 'react-router-dom';
import { Select, Input, Button, Row, Col } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from "moment";
import MealElement from "./MealElement";
import SaveMealPlanModal from "./SaveMealPlanModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import WysiwygField from '../../components/WysiwygField';
const axios = require('axios').default;

const { Option } = Select;
const { TextArea } = Input;

class AdminMealPlanPage extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      selectedDay: "",
      scrolledWeeks: 0,
      saveMealPlanModalVisible: false,
      targetInfo: {
        calories: 0,
        protein: 0,
        proteinPercent: 0,
        carb: 0,
        carbPercent: 0,
        fats: 0,
        fatsPercent: 0,
      },
      currentDayNutrients: {
        calories: 0,
        protein: 0,
        carb: 0,
        fats: 0,
        proteinPercent: 0,
        carbPercent: 0,
        fatsPercent: 0
      },
      plan: {},
      planEmpty: false,
      planHealthLabels: [],
      healthLabels: [
        "alcohol-free",
        "celery-free",
        "crustacean-free",
        "dairy-free",
        "egg-free",
        "fish-free",
        "gluten-free",
        "keto-friendly",
        "kosher",
        "low-sugar",
        "lupine-free",
        "mustard-free",
        "no-oil-added",
        "paleo",
        "peanut-free",
        "pescatarian",
        "pork-free",
        "red-meat-free",
        "sesame-free",
        "shellfish-free",
        "soy-free",
        "tree-nut-free",
        "vegan",
        "vegetarian",
        "wheat-free",
      ],
      isSaveModal: false,
      planNote: "",
      isDuplicate: true,
      isDuplicateLoading: false,
    };
  }

  async componentDidMount () {

    const {mode} = Object.fromEntries( new URLSearchParams(window.location.search).entries() );
    if(mode == 'create') {
      this.setState({
        isDuplicate: false,
      })
    }

    
    if (window.location.search.includes('mealplan')) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const { mealplan, startDate, endDate } = Object.fromEntries(urlSearchParams.entries())
      const { plan } = await (await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/template/${mealplan}`, {
        headers: {
          'x-auth-token': localStorage.token
        }
      })).json()
      const newPlan = {}
      const emptyPlan = getEmptyPlanInRange(new Date(startDate), new Date(endDate))
      const mealplanArray = Object.entries(plan.mealplan)
      for (let i = 0; i < emptyPlan.length; i++) {
        newPlan[emptyPlan[i]] = mealplanArray[i] ? mealplanArray[i][1] : []
      }
      this.setState({
        targetInfo: plan.target,
        plan: newPlan,
        planHealthLabels: plan.healthLabels,
        selectedDay: Object.keys(newPlan)[0],
        // planNote: this.props.mealPlan.notes ? this.props.mealPlan.notes : ' ',
        // planNote: ' ',
        planNote: this.props.adminMealPlan.notes ? this.props.adminMealPlan.notes : ' ',
      })
      this.calculateCurrentDayNutrients(plan.mealplan[Object.keys(plan.mealplan)[0]])
    } else if (this.props.mealPlan) {
      if (this.props.template) {
        this.setState({
          targetInfo: this.props.mealPlan.target,
          plan: this.props.mealPlan.mealplan,
          planHealthLabels: this.props.mealPlan.healthLabels,
          selectedDay: Object.keys(this.props.mealPlan.mealplan)[0],
          planNote: this.props.mealPlan.notes
        });
        this.calculateCurrentDayNutrients(
          this.props.mealPlan.mealplan[
            Object.keys(this.props.mealPlan.mealplan)[0]
          ]
        );
      } else {
        this.setState({
          targetInfo: this.props.mealPlan.targetInfo,
          plan: this.props.mealPlan.plan,
          planHealthLabels: this.props.mealPlan.healthLabels,
          selectedDay: Object.keys(this.props.mealPlan.plan)[0],
          planNote: this.props.mealPlan.notes
        });
        this.calculateCurrentDayNutrients(
          this.props.mealPlan.plan[Object.keys(this.props.mealPlan.plan)[0]]
        );
      }
    } else {
      const { clientNutrition } = this.props;
      if(clientNutrition) {
        this.setState({
          targetInfo: {
            ...this.state.targetInfo,
            calories: clientNutrition.goalCalories,
            protein: Math.round(clientNutrition.protein.grams),
            proteinPercent:  Math.round(clientNutrition.protein.percentage),
            carb:  Math.round(clientNutrition.carbs.percentage),
            carbPercent:  Math.round(clientNutrition.carbs.percentage),
            fats:  Math.round(clientNutrition.fats.percentage),
            fatsPercent:  Math.round(clientNutrition.fats.percentage)
          }
        })
      }
      this.setState({
        planEmpty: true
      })
    }
  }

  initWeek = () => {
    const plan = {};
    const ISOString = moment().utc().format('YYYY-MM-DD')
    plan[ISOString] = [];
    this.setState({
      selectedDay: moment().utc().format('YYYY-MM-DD'),
      plan,
      planEmpty: false,
      isSaveModal: true
    });
  }

  nextWeek = () => {
    this.setState({
      scrolledWeeks: this.state.scrolledWeeks + 1,
    });
  };

  previousWeek = () => {
    if (this.state.scrolledWeeks) {
      this.setState({
        scrolledWeeks: this.state.scrolledWeeks - 1,
      });
    }
  };

  addNewMeal = () => {
    this.setState({
      plan: {
        ...this.state.plan,
        [this.state.selectedDay]: [
          ...this.state.plan[this.state.selectedDay],
          {
            mealTitle: "",
            food: [
              {
                title: "",
                info: {},
              },
            ],
          },
        ],
      },
    });
  };

  addNewFood = (index) => {
    const plan = this.state.plan;
    plan[this.state.selectedDay][index].food.push({
      title: "",
      info: {},
    });
    this.setState({ plan });
  };

  calculateCurrentDayNutrients = (dayPlan) => {
    this.setState({
      currentDayNutrients: {
        calories: Math.round(
          dayPlan.reduce(
            (acc, curr) =>
              acc +
              curr.food.reduce(
                (prev, next) =>
                  prev + +((next.info && next.info.calories) || 0),
                0
              ),
            0
          )
        ),
        protein: Math.round(
          dayPlan.reduce(
            (acc, curr) =>
              acc +
              curr.food.reduce(
                (prev, next) =>
                  prev + +((next.info && next.info.proteins) || 0),
                0
              ),
            0
          )
        ),
        carb: Math.round(
          dayPlan.reduce(
            (acc, curr) =>
              acc +
              curr.food.reduce(
                (prev, next) => prev + +((next.info && next.info.carbs) || 0),
                0
              ),
            0
          )
        ),
        fats: Math.round(
          dayPlan.reduce(
            (acc, curr) =>
              acc +
              curr.food.reduce(
                (prev, next) => prev + +((next.info && next.info.fats) || 0),
                0
              ),
            0
          )
        )
      },
    });
  };

  updateMeal = (day, mealIndex, foodIndex, altFoodIndex, item) => {
    let updatedPlan = this.state.plan;
    if (altFoodIndex !== undefined) {
      delete item.alternatives;
      updatedPlan[day][mealIndex].food[foodIndex].alternatives[altFoodIndex] = item;
    } else {
      if(item.alternatives) {
        const alternatives = item.alternatives.map((alternative) => {
          if(alternative.alternatives) {
            delete alternative.alternatives;
          }
          return alternative;
        })
        item.alternatives = alternatives;
      }
      updatedPlan[day][mealIndex].food[foodIndex] = item;
    }
    this.setState({ plan: updatedPlan });
    if (!altFoodIndex) {
      this.calculateCurrentDayNutrients(updatedPlan[day]);
    }
  };

  removeMeal = (index) => {
    let updatedPlan = this.state.plan;
    updatedPlan[this.state.selectedDay].splice(index, 1);
    this.setState({ plan: updatedPlan });
    this.calculateCurrentDayNutrients(updatedPlan[this.state.selectedDay]);
  };

  removeFood = (index, foodIndex, altFoodIndex) => {
    let updatedPlan = this.state.plan;
    if (altFoodIndex !== undefined) {
      updatedPlan[this.state.selectedDay][index].food[foodIndex].alternatives.splice(altFoodIndex, 1);
    } else {
      updatedPlan[this.state.selectedDay][index].food.splice(foodIndex, 1);
    }
    this.setState({ plan: updatedPlan });
    if (!altFoodIndex) {
      this.calculateCurrentDayNutrients(updatedPlan[this.state.selectedDay]);
    }
  };

  setMealTitle = (value, index) => {
    const { plan, selectedDay } = this.state;
    plan[selectedDay][index].mealTitle = value;
    this.setState({ plan });
  };

  save = async (mealplan, saveBtn) => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const { plan, targetInfo, planHealthLabels, currentDayNutrients, planNote } = this.state;
      console.log("planNote", planNote);
      const { mode, preference } = Object.fromEntries(urlSearchParams.entries())

      const nutrientsWithPercentage = {
        ...currentDayNutrients, 
        proteinPercent: Math.round(((currentDayNutrients.protein * 4) * 100) / this.state.currentDayNutrients.calories),
        carbPercent: Math.round(((currentDayNutrients.carb * 4) * 100) / this.state.currentDayNutrients.calories),
        fatsPercent: Math.round(((currentDayNutrients.fats * 9) * 100) / this.state.currentDayNutrients.calories)
      };
      
      if (
        + targetInfo.proteinPercent +
          + targetInfo.carbPercent +
          + targetInfo.fatsPercent !==
        100
      ) {
        return toast.error("Sum of protein / carb / fats percent must be 100%");
      }

      const newPlan = getRandomizeFood(plan);
      this.setState({plan: newPlan});

      const config = {
        method: 'POST',
        body: JSON.stringify({
          preference: preference || 'Anything',
          target: nutrientsWithPercentage,
          mealplan: mealplan || newPlan,
          healthLabels: planHealthLabels,
          notes: planNote
        }),
        headers: {
          'content-type': 'application/json',
          'x-auth-token': localStorage.token
        }
      }

      if (this.props.saveWithoutModal && saveBtn) {
        this.setState({ loadingSaveBtn: true });
        if(this.props.user) {
          await Promise.all([
            // fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/template`, config), //for stop cloning of meal plan while assign to client.
            fetch(
              `${process.env.REACT_APP_API_URL}/api/profile/update-meal-plan/${this.props.user._id}`,
              {
                ...config,
                body: JSON.stringify({
                  customMealPlan: {
                    targetInfo: nutrientsWithPercentage,
                    plan: mealplan || newPlan,
                    healthLabels: planHealthLabels,
                    notes: planNote
                  },
                })
              }
            ).then(() => {
              if(this.props.user) {
                this.props.getAllUserNotifications(this.props.user.email)
              }
            }),
          ]);
          toast.success("User meal plan has been successfully updated and saved!");
          this.props.setProgramVisibility();
        }
      } else if (mode === 'create') {
        this.setState({ loading: true });
        await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/template`, config);
        toast.success("Meal plan template has been successfully created");
      } else {
        this.setState({ loading: true });
        // const sure = window.confirm('Are you sure you just want to send a meal plan?')
        // if (sure) {
        if(this.props.user) {
          await fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/update-meal-plan/${this.props.user._id}`,
            {
              ...config,
              body: JSON.stringify({
                customMealPlan: {
                  targetInfo: nutrientsWithPercentage,
                  plan: mealplan || newPlan,
                  healthLabels: planHealthLabels,
                  notes: planNote
                },
              })
            }
          ).then(() => {
            if(this.props.user) {
              this.props.getAllUserNotifications(this.props.user.email)
            }
          });
          this.props.setProgramVisibility();
          toast.success("User meal plan has been successfully updated!");
        }
        // }
      }
      this.setState({
        loading: false,
        loadingSaveBtn: false
      });
      if(this.props.user) {
        this.props.history.push(`/dashboard/admin/clients/${this.props.user._id}/edit-meal-plan`)
      }
    } catch (e) {
      toast.error("Something went wrong. Please try again later");
      console.log(e);
      this.setState({
        loading: false,
        loadingSaveBtn: false
      });
    }
  };

  duplicate = async() => {
    try{
      const urlSearchParams = new URLSearchParams(window.location.search)
      const { plan, targetInfo, planHealthLabels, currentDayNutrients, planNote } = this.state;
      const { mode, preference } = Object.fromEntries(urlSearchParams.entries())

      const nutrientsWithPercentage = {
        ...currentDayNutrients, 
        proteinPercent: Math.round(((currentDayNutrients.protein * 4) * 100) / this.state.currentDayNutrients.calories),
        carbPercent: Math.round(((currentDayNutrients.carb * 4) * 100) / this.state.currentDayNutrients.calories),
        fatsPercent: Math.round(((currentDayNutrients.fats * 9) * 100) / this.state.currentDayNutrients.calories)
      };
      
      if (
        + targetInfo.proteinPercent +
          + targetInfo.carbPercent +
          + targetInfo.fatsPercent !==
        100
      ) {
        return toast.error("Sum of protein / carb / fats percent must be 100%");
      }

      const newPlan = getRandomizeFood(plan);
      this.setState({plan: newPlan});
      const urlForDuplicate = `${process.env.REACT_APP_API_URL}/api/mealplan/duplicate-template`;
      const headers = {
        headers: {
          'x-auth-token': localStorage.token
        }
      };
      this.setState({
        isDuplicateLoading: true,
      });

     await axios.post(`${process.env.REACT_APP_API_URL}/api/profile/update-meal-plan/${this.props.user._id}`,{
        customMealPlan: {
          targetInfo: nutrientsWithPercentage,
          plan: newPlan,
          healthLabels: planHealthLabels,
          notes: planNote || this.state.notes,
        },
      },headers).then(async ()=>{
        await axios.post(urlForDuplicate,{
          preference: preference || 'Anything',
          // target: this.state.targetInfo,
          target: nutrientsWithPercentage,
          // mealplan:  this.state.plan,
          mealplan:  newPlan,
          // mealplanLength: Object.keys(this.state.plan).length,
          mealplanLength: Object.keys(newPlan).length,
          notes: this.state.notes
        },headers)
        .then( (response) => {
          console.log("response",response);
          this.setState({
            isDuplicateLoading: false,
          });
          toast.success("Meal plan template has been successfully duplicated.");
        })
        .catch(function (error) {
          console.log(error);
          this.setState({
            isDuplicateLoading: false,
          });
        });
      }).catch(function (error) {
        console.log(error);
        this.setState({
          isDuplicateLoading: false,
        });
      });
      

    }catch(e){
      console.log(e);
    }
  }

  toggleSaveMealPlanModal = (visible) => {
    this.setState({
      saveMealPlanModalVisible: visible,
    });
  };

  recalculateMacro = (value, name) => {
    if (value === 'all') {
      this.setState({
        targetInfo: {
          ...this.state.targetInfo,
          protein: Math.round(this.state.targetInfo.calories * (this.state.targetInfo.proteinPercent / 100) / 4),
          carb: Math.round(this.state.targetInfo.calories * (this.state.targetInfo.carbPercent / 100) / 4),
          fats: Math.round(this.state.targetInfo.calories * (this.state.targetInfo.fatsPercent / 100) / 9)
        }
      })
    } else {
      this.setState({
        targetInfo: {
          ...this.state.targetInfo,
          [name]: name === 'fats' ? Math.round((this.state.targetInfo.calories * (value / 100)) / 9) : Math.round((this.state.targetInfo.calories * (value / 100) / 4)),
          [`${name}Percent`]: value < 100 ? value : 100,
        },
      })
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const {plan, selectedDay} = this.state;
    const newPlan = {...plan};
    const [removed] = newPlan[selectedDay].splice(result.source.index, 1);
    newPlan[selectedDay].splice(result.destination.index, 0, removed);
    this.setState({ plan: newPlan });
  };
  
  onEditorChange = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue })
  }

  render() {
    const {
      targetInfo,
      planHealthLabels,
      healthLabels,
      currentDayNutrients,
      planEmpty,
      scrolledWeeks,
      plan,
      selectedDay,
      saveMealPlanModalVisible,
      loadingSaveBtn,
      loading,
      isSaveModal,
      planNote,
    } = this.state;

    const {
      template,
      history,
    } = this.props;
    return (
      <div className='meal-plan-page'>
        <Row gutter={[8, 8]} className="form">
          <Col xl={5}>
            <p className="label">Target calories per day (kcal):</p>
            <Input
              type="number"
              value={targetInfo.calories}
              onChange={(e) => {
                this.setState({
                  targetInfo: {
                    ...targetInfo,
                    calories: e.target.value
                  }
                })
                setTimeout(() => {
                  this.recalculateMacro('all')
                }, 50)
              }}
            />
          </Col>
          <Col xl={5}>
            <p className="label">Protein:</p>
            <div className="flex wrap">
              <Input
                type="text"                
                value={`${targetInfo.protein || 0} grams`}
                disabled
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Input
                type="number"
                addonAfter="%"
                value={targetInfo.proteinPercent}
                onChange={(e) => this.recalculateMacro(e.target.value, "protein")}
                className="input-group"
              />
            </div>
          </Col>
          <Col xl={5}>
            <p className="label">Carb:</p>
            <div className="flex wrap">
              <Input
                type="text"
                value={`${targetInfo.carb || 0} grams`}
                disabled
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Input
                type="number"
                addonAfter="%"
                value={targetInfo.carbPercent}
                onChange={(e) => this.recalculateMacro(e.target.value, "carb")}
                className="input-group"
              />
            </div>
          </Col>
          <Col xl={5}>
            <p className="label">Fats:</p>
            <div className="flex wrap">
              <Input
                type="text"
                value={`${targetInfo.fats || 0} grams`}
                disabled
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Input
                type="number"
                addonAfter="%"
                value={targetInfo.fatsPercent}
                onChange={(e) => this.recalculateMacro(e.target.value, "fats")}
                className="input-group"
              />
            </div>
          </Col>
          <Col xl={4}>
            <p className="label">Health labels:</p>
            <Select
              className="w-100"
              mode="multiple"
              value={planHealthLabels}
              defaultActiveFirstOption={false}
              onChange={(planHealthLabels) =>
                this.setState({ planHealthLabels })
              }
              showSearch
            >
              {healthLabels.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div className="mt-2 mb-1">
          <span className="blog-card-category">Current</span>
        </div>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <p className="home-card-text">
              {currentDayNutrients.calories}kcal
            </p>
            <span className="macro-label-bottom">Calories</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {currentDayNutrients.protein}g
            </p>
            <span className="macro-label-bottom">Proteins</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {currentDayNutrients.carb}g
            </p>
            <span className="macro-label-bottom">Carbs</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {currentDayNutrients.fats}g
            </p>
            <span className="macro-label-bottom">Fats</span>
          </Col>
        </Row>
        <div className="mt-2 mb-1">
          <span className="blog-card-category">Remaining</span>
        </div>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <p className="home-card-text">
              {targetInfo.calories -
                currentDayNutrients.calories >=
              0
                ? targetInfo.calories -
                  currentDayNutrients.calories
                : 0}
                kcal
            </p>
            <span className="macro-label-bottom">Calories</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {targetInfo.protein -
                currentDayNutrients.protein >=
              0
                ? targetInfo.protein -
                  currentDayNutrients.protein
                : 0}
              g
            </p>
            <span className="macro-label-bottom">Proteins</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {targetInfo.carb -
                currentDayNutrients.carb >=
              0
                ? targetInfo.carb -
                  currentDayNutrients.carb
                : 0}
              g
            </p>
            <span className="macro-label-bottom">Carbs</span>
          </Col>
          <Col span={6}>
            <p className="home-card-text">
              {targetInfo.fats -
                currentDayNutrients.fats >=
              0
                ? targetInfo.fats -
                  currentDayNutrients.fats
                : 0}
              g
            </p>
            <span className="macro-label-bottom">Fats</span>
          </Col>
        </Row>
        <div className="mt-2 mb-1">
          <span className="blog-card-category">Plan notes</span>
        </div>
        <div>
          <WysiwygField
            {...this.props}
            fieldName="planNote"
            fieldValue={this.state.planNote}
            onEditorChange={this.onEditorChange}
          />
        </div>
        {!planEmpty && (
          <div>
            <div className="flex week-buttons flex-center-aligned w-100">
              {scrolledWeeks > 0 && <ArrowLeftOutlined className="pointer" onClick={() => this.previousWeek()} />}
              <div className="mx-1 flex week-buttons-flex w-100">
                {(plan ? Object.keys(plan).slice(scrolledWeeks * 7 , (scrolledWeeks + 1) * 7) : []).map((item) =>
                  <Button
                    className={selectedDay === item ? "ant-btn-primary" : ""}
                    style={{whiteSpace: "pre-wrap"}}
                    onClick={() => {
                      this.setState({selectedDay: item})
                      this.calculateCurrentDayNutrients(plan[item])
                    }}
                    key={item}
                  >
                    {moment(item).format("ddd, MMM DD") }
                  </Button>
                )}
              </div>
              {plan && Object.keys(plan).length > (scrolledWeeks + 1) * 7 && <ArrowRightOutlined className="pointer" onClick={() => this.nextWeek()} />}
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <div className="week-setup mb-4">
                      {(plan[selectedDay] || []).map((item, index) =>{
                        console.log('item', item)
                        return (
                          <Draggable draggableId={`key-${index}`} index={index}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="week-item" key={`${selectedDay}-${index}`}>
                                  <div className="mealplan-title form">
                                    <Input
                                      defaultValue={item.mealTitle || `Meal ${index + 1}`}
                                      value={item.mealTitle || `Meal ${index + 1}`}
                                      placeholder="Enter meal title"
                                      onChange={e => this.setMealTitle(e.target.value, index)}
                                    />
                                  </div>
                                  <span className="cross" onClick={() => this.removeMeal(index)}>&times;</span>
                                  {(item.food || []).map((food, foodIndex) =>
                                      <div className="top-line mt-2">
                                        <MealElement
                                          updateMeal={this.updateMeal}
                                          removeFood={this.removeFood}
                                          healthLabels={planHealthLabels}
                                          item={food}
                                          key={foodIndex}
                                          location={[selectedDay, index, foodIndex]}
                                          loading={this.props.loading}
                                        />
                                        {(food.alternatives || []).map((altFood, altFoodIndex) =>
                                          <MealElement
                                            isAlternative={true}
                                            updateMeal={this.updateMeal}
                                            removeFood={this.removeFood}
                                            healthLabels={planHealthLabels}
                                            item={altFood}
                                            key={altFoodIndex}
                                            location={[selectedDay, index, foodIndex, altFoodIndex]}
                                            loading={this.props.loading}
                                          />
                                        )}
                                        <span
                                          className="flex mt-1 blue-link fsize-14"
                                          onClick={() => this.updateMeal(
                                            selectedDay, index, foodIndex, undefined,
                                            {
                                              ...plan[selectedDay][index].food[foodIndex],
                                              alternatives: [
                                                ...(plan[selectedDay][index].food[foodIndex].alternatives || []),
                                                {}
                                              ]
                                            })
                                          }
                                        >
                                + Add alternative food
                              </span>
                                      </div>
                                  )}
                                  <div className="mt-2">
                            <span className="blue-link fsize-14" onClick={() => this.addNewFood(index)}>
                              + Add food
                            </span>
                                  </div>
                                  {(item.food || []).length > 1 && (
                                    <div className="top-line">
                                      <p className="blog-card-category mb-1">
                                        Total
                                      </p>
                                      <div className="flex flex-wrap">
                                        <div className="mr-3">
                                          <p className="home-card-text">
                                            {item.food.reduce((acc, curr) =>
                                              acc + (+(curr.info && curr.info.calories) || 0), 0).toFixed(1)
                                            }kcal
                                          </p>
                                          <span className="macro-label-bottom">Calories</span>
                                        </div>
                                        <div className="mr-3">
                                          <p className="home-card-text">
                                            {item.food.reduce((acc, curr) =>
                                              acc + (+(curr.info && curr.info.proteins) || 0), 0).toFixed(1)
                                            }g
                                          </p>
                                          <span className="macro-label-bottom">Proteins</span>
                                        </div>
                                        <div className="mr-3">
                                          <p className="home-card-text">
                                            {item.food.reduce((acc, curr) =>
                                              acc + (+(curr.info && curr.info.carbs) || 0), 0).toFixed(1)
                                            }g
                                          </p>
                                          <span className="macro-label-bottom">Carbs</span>
                                        </div>
                                        <div className="mr-3">
                                          <p className="home-card-text">
                                            {item.food.reduce((acc, curr) =>
                                              acc + (+(curr.info && curr.info.fats) || 0), 0).toFixed(1)
                                            }g
                                          </p>
                                          <span className="macro-label-bottom">Fats</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      }

                      )}
                      <Button className="addMealBtn" onClick={() => this.addNewMeal()}>
                        <span className="font-weight-bold plus">+</span>
                        Add new meal
                      </Button>
                      <div className="week-buttons text-right">
                        <SaveMealPlanModal
                          plan={plan}
                          fullMealplan={this.props.mealPlan}
                          target={targetInfo}
                          template={template}
                          assign={this.save}
                          toggleSaveMealPlanModal={this.toggleSaveMealPlanModal}
                          visible={saveMealPlanModalVisible}
                          history={history}
                          user={this.props.user}
                          notes={planNote}
                        />
                        <Button
                          type="primary"
                          onClick={() => this.toggleSaveMealPlanModal(true)}
                          loading={loadingSaveBtn}
                        >
                          Save
                        </Button>
                        {/* <Button
                          type="primary"
                          onClick={() => (window.location.search.includes('mode=create') || !isSaveModal) ? this.save(undefined, true) : this.toggleSaveMealPlanModal(true)}
                          loading={loadingSaveBtn}
                        >
                          Save
                        </Button> */}
                        {/* {!template && (
                          <Button type="primary" onClick={() => this.save()} loading={!isSaveModal && loading}>
                            Send meal plan
                          </Button>
                        )} */}
                        {
                          this.state.isDuplicate 
                          ? <Button type="primary" onClick={() => this.duplicate()} loading={this.state.isDuplicateLoading}>Save & Duplicate</Button> 
                          : null
                        }
                        {
                          !template &&
                          <Button
                            type="primary"
                            onClick={() => {
                              if(this.props.user) {
                                this.props.history.push(`/dashboard/admin/clients/${this.props.user._id}/edit-meal-plan`)
                              }
                            }}
                          >
                            Cancel
                          </Button>
                        }
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {planEmpty && (
          <div className="mt-4">
            <Link to="/dashboard/admin/library/meal-plan">
              <div>
                <Button className="w-100 py-2">
                  Assign existing
                </Button>
              </div>
            </Link>
            <div className="mt-1">
              <Button className="w-100 py-2" onClick={() => this.initWeek()}>
                Create new meal plan
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdminMealPlanPage;
