import React from 'react';
import { connect } from 'react-redux';
import {Input, Button, Alert, DatePicker, Divider} from 'antd';
import { poundsToKgs, kgsToPounds} from '../../helpers/utils';
import { saveBodyFat, clearBodyFatErrors } from '../../ducks/Measurements/actions';
import ProgressUpload from '../../components/ProgressUpload';
import { CLOSE_DRAWER } from '../../ducks/Global/constants';
import './index.css';
import moment from "moment";

const dateFormat = 'MM/DD/YYYY';

class MeasurementForm extends React.Component {
  constructor(){
    super();
    this.state={
      measurementObj: {},
      bodyFatObj: {},
      progressPictures: []
    }
  }

  componentDidMount () {
    this.props.clearBodyFatErrors();
  }

  componentWillReceiveProps(nextProps){
    if(this.props.userBodyFatMeasurements.length !== nextProps.userBodyFatMeasurements.length){
      this.props.closeDrawer();
      this.setState({bodyFatObj: {}, measurementObj: {}, progressPictures: []});
    }
  }

  renderBodyFatMeasurementForm = (data) => {
    const measurementOptionsFat = [
      {key: 'weight', label: 'Weight', gender: 'male', suffix: `${data.isMetric ? 'KG' : 'LB'}`},
      {key: 'weight', label: 'Weight', gender: 'female', suffix: `${data.isMetric ? 'KG' : 'LB'}`},
      {key: 'chest', area: 'chest', label: 'Chest', gender: 'male'},
      {key: 'abdominal', area: 'abdominal', label: 'Abdominal', gender: 'male'},
      {key: 'thigh', area: 'thigh', label: 'Thigh', gender: 'male'},
      {key: 'thigh', area: 'thigh', label: 'Thigh', gender: 'female'},
      {key: 'tricep', area: 'tricep', label: 'Tricep', gender: 'female'},
      {key: 'suprailiac', area: 'suprailiac', label: 'Suprailiac', gender: 'female'}
    ];
    const filteredOptions = measurementOptionsFat.filter(option => option.gender === data.gender);
    return (
      <div className='measurement-form'>
        {filteredOptions.map(option => {
        return (
          <div className='form body-fat-form'>
            <p className='label'>{option.label}</p>
            <Input 
              suffix={option.suffix ? option.suffix : 'MM'}
              onChange={(e) => this.handleInputChange(e.target.value, option.key)}
              value={this.state.measurementObj[option.key]}
              type='number'
            />
          </div>)
        })}
        <div>
          <div className='uploader'>
            <ProgressUpload handler = {this.handleImageInputChange} location = "site" photos = {this.state.progressPictures} />
          </div>
          {
            this.props.user
            && this.props.user.role === 'admin'
            && <div className='form body-fat-form'>
              <p className='label'>Date Taken</p>
              <DatePicker
                defaultValue={moment()}
                format={dateFormat}
                onChange={this.onDateChange}
              />
            </div>
          }
          <div className='flex flex-center-aligned'>
            <p className='reset-btn' onClick={() => this.setState({measurementObj: {}})}>Reset</p>
            <Button loading={this.props.saveBodyFatLoading} type='primary' onClick={() => this.calculate()}>Calculate</Button>
          </div>
        </div>
        {(this.props.saveBodyFatError && this.props.saveBodyFatError.type !== 'Manual') && (
          <Alert
            message={
              this.props.saveBodyFatError.errors.length > 0 &&
              Object.values(this.props.saveBodyFatError.errors).map((err) => (
                <p>{err.msg}</p>
              ))
            }
            type="error"
            // closable
          />
        )}
        {this.props.error && (
          <Alert
            type="error"
            message={this.props.error.status === 413 ? 'Photos size limit is 50mb' : 'An error happened. Try again later'}
          />
        )}
        {this.props.error === false && (
          <Alert
            type="success"
            message={'Success'}
          />
        )}
      </div>
    )
  }

  onDateChange = (date, dateString) => {
    let measurementObj = {...this.state.measurementObj};
    measurementObj.date = new Date(date);
    this.setState({measurementObj});
  };

  handleInputChange = (value, key) => {
    let measurementObj = {...this.state.measurementObj};
    measurementObj[key] = value;
    this.setState({measurementObj: measurementObj})
  }

  handleImageInputChange = (value) => {
    this.setState({
      progressPictures: value
    });
  }

  //calculate body fat and lean body mass
  calculate = () => {
    const { data, user } = this.props;
    let measurementObj = {...this.state.measurementObj};
    if(data.gender === 'male'){
      let mmTotal = Number(measurementObj.chest) + Number(measurementObj.thigh) + Number(measurementObj.abdominal);
      // Body Density = 1.10938 – (0.0008267 x sum of skinfolds) + (0.0000016 x square of the sum of skinfolds) – (0.0002574 x age)
      let bodyDensity = 1.10938 - (0.0008267 * mmTotal) + (0.0000016 * (mmTotal ** 2)) - (0.0002574 * data.age);
      // Body Fat Percentage (%) = (495 / Body Density) – 450
      let bfPercentage = ((495 / bodyDensity) - 450).toFixed(2);
      let leanBodyMass = (measurementObj.weight - ((bfPercentage / 100) * measurementObj.weight)).toFixed(2);
      this.buildMeasurementObj(measurementObj, bfPercentage, leanBodyMass, data, user);
      
    } else if(data.gender === 'female'){
      let mmTotal = Number(measurementObj.tricep) + Number(measurementObj.thigh) + Number(measurementObj.suprailiac);
      // Body Density = 1.0994921 – (0.0009929 x sum of skinfolds) + (0.0000023 x square of the sum of skinfolds) – (0.0001392 x age)
      let bodyDensity = 1.0994921 - (0.0009929 * mmTotal) + (0.0000023 * (mmTotal ** 2)) - (0.0001392 * data.age);
      // Body Fat Percentage (%) = (495 / Body Density) – 4.5 * 100;
      let bfPercentage = ((495 / bodyDensity) - 450).toFixed(2);
      let leanBodyMass = (measurementObj.weight - ((bfPercentage / 100) * measurementObj.weight)).toFixed(2);
      this.buildMeasurementObj(measurementObj, bfPercentage, leanBodyMass, data, user);
    }
  }

  buildMeasurementObj = (measurementObj, bfPercentage, leanBodyMass, data, user) => {
    let bodyFatObj = {};
    const base64images = [];
    bodyFatObj.userId = data.user._id;
    bodyFatObj.source = user.role === 'admin' ? 'Coach' : 'Manual Measurement';
    bodyFatObj.pictures = this.state.progressPictures;
    bodyFatObj.type = '3-Site Skinfold (Jackson & Pollock)';
    bodyFatObj.weight = {kg: data.isMetric ? measurementObj.weight : poundsToKgs(measurementObj.weight), lb: !data.isMetric ? measurementObj.weight : kgsToPounds(measurementObj.weight)};
    bodyFatObj.leanBodyMass =  data.isMetric ? {kg: leanBodyMass, lb: kgsToPounds(leanBodyMass)} : {kg: poundsToKgs(leanBodyMass), lb: leanBodyMass}
    bodyFatObj.bfPercentage = bfPercentage;
    bodyFatObj.date = measurementObj.date;
    delete measurementObj.weight;
    bodyFatObj.measurements = measurementObj;
    this.setState({bodyFatObj: bodyFatObj});
    this.props.saveBodyFat({
      bodyFatObj,
      progressPictures: this.state.progressPictures
    });
  }

  render(){
    const { data, user } = this.props;
    return(
      <div className='measurement-form-container'>
        {this.renderBodyFatMeasurementForm(data, user)}
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveBodyFat: (obj) => dispatch(saveBodyFat(obj)),
    clearBodyFatErrors: () => dispatch(clearBodyFatErrors()),
    closeDrawer: () => {
			return dispatch({
					type: CLOSE_DRAWER
			 });
		},
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.measurements.bodyFatError,
    saveBodyFatError: state.measurements.saveBodyFatError,
    saveBodyFatLoading: state.measurements.saveBodyFatLoading,
    userBodyFatMeasurements: state.measurements.userBodyFatMeasurements,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementForm);