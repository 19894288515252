import React from 'react';
import { connect } from 'react-redux'; 
import { getAllNutritionForUser, getUserFitbitLogs, getUserFitbitNutrition, getCurrentProfile } from '../../ducks/Profile/actions';
import { getFitbitAccessToken } from '../../ducks/Integrations/actions';
import FoodLogHistoryPage from '../../presentational/FoodLogHistoryPage';
import { Loading } from '../../components/Loading';
import ConnectApp from '../../assets/images/Connect.png';

class FoodLogHistory extends React.Component {

  componentDidMount(){
    this.props.getCurrentProfile();
    this.props.getAllNutritionForUser();
    this.props.getUserFitbitLogs();
    this.props.getUserFitbitNutrition();
  }

  renderSlashFoodLogText = (profile, fitbitLogs, allUserNutrition) => {
    if(profile && !profile.fitbitToken){
      return (
        <p style={{maxWidth: '500px', textAlign: 'center'}}>Nothing to see here yet. <a className='blue-link' style={{fontSize: '16px'}} onClick={() => this.props.getFitbitAccessToken()}>Connect with Fitbit</a> and get access to your food log history and compliance for each day.</p>
      )
    } else if(profile && profile.fitbitToken && fitbitLogs.length === 0 && allUserNutrition){
      return (
        <p className='home-card-text' style={{maxWidth: '500px', textAlign: 'center', lineHeight: '16px'}}>Start logging in your foods on the Fitbit app. Come back here to see how you are measuring against your macronutrient goals.</p>
      )
    }
  }

  renderPage = () => {
    const { profile, fitbitLogs, allUserNutrition, fitbitLogsLoading, allUserNutritionLoading } = this.props;
    if(profile, fitbitLogs && fitbitLogs.length === 0){
      return(
        <div className='no-data'>
          <img src={ConnectApp} className='food-log-splash-img' />
          {this.renderSlashFoodLogText(profile, fitbitLogs, allUserNutrition)}
        </div>
      )
    } else if(profile && fitbitLogs && allUserNutrition){
      return <FoodLogHistoryPage {...this.props} />
    } else if(fitbitLogsLoading && allUserNutritionLoading){
      return <Loading /> 
    }
  }

  render(){
    const { fitbitLogs, allUserNutrition } = this.props;
    const notLoading = fitbitLogs && allUserNutrition;
    return(
      <div className={`home-page ${notLoading ? '' : 'loading'}`}>
        {this.renderPage()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile.profile,
    fitbitLogs: state.profile.fitbitLogs,
    fitbitLogsLoading: state.profile.fitbitLogsLoading,
    fitbitLogsError: state.profile.fitbitLogsError,
    allUserNutrition: state.profile.allUserNutrition,
    allUserNutritionLoading: state.profile.allUserNutritionLoading,
    allUserNutritionError: state.profile.allUserNutritionError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllNutritionForUser: () => dispatch(getAllNutritionForUser()),
    getUserFitbitLogs: () => dispatch(getUserFitbitLogs()),
    getUserFitbitNutrition: () => dispatch(getUserFitbitNutrition()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getFitbitAccessToken: () => dispatch(getFitbitAccessToken()) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodLogHistory);