import React from 'react';
import { Tabs, Divider } from 'antd';
import './index.css';
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
import { capitalize } from '../../helpers/utils';

const { TabPane } = Tabs;

class SuggesticShoppingPage extends React.Component {
  state={groupedByAisle: null, groupedByRecipe: null};

  componentDidMount(){
    this.groupByAisle();
    this.groupByRecipe();
  }

  changeTab = activeKey => {
    this.props.changeActiveTab(activeKey)
  }

  renderTabs = () => {
    return (
      <Tabs animated={false} defaultActiveKey={this.props.activeTab} onChange={this.changeTab}>
        <TabPane tab="By Aisle" key="1">
          {this.renderGroceriesByAisle()}
        </TabPane>
        <TabPane tab="By Recipe" key="2">
          {this.renderGroceriesByRecipe()}
        </TabPane>
      </Tabs>
    )
  }

  groupByAisle = () => {
    let grouped = this.props.shoppingListAggregate && this.props.shoppingListAggregate.edges.reduce(function (r, a) {
      r[a.node.aisleName] = r[a.node.aisleName] || [];
      r[a.node.aisleName].push(a);
      return r;
    }, Object.create(null));
    this.setState({groupedByAisle: grouped});
  }

  groupByRecipe = () => {
    let grouped = this.props.shoppingList && this.props.shoppingList.edges.reduce(function (r, a) {
      r[a.node.recipeName] = r[a.node.recipeName] || [];
      r[a.node.recipeName].push(a);
      return r;
    }, Object.create(null));
    this.setState({groupedByRecipe: grouped});
  }

  renderGroceriesByAisle = () => {
    return this.state.groupedByAisle && Object.entries(this.state.groupedByAisle).map(([key, value]) => {
      return(
        <div>
          <p className='aisle-section'>{key}</p>
          <Divider />
          {value.map(node => 
          <div onClick={() => this.props.toggleShoppingListItem(node.node.databaseId, true)} className='shopping-list-item'>
            <div>
              <p className='item-text'>{capitalize(node.node.ingredient)}</p> 
              <p className='item-qty'>{node.node.quantity} {node.node.unit}</p>
            </div>
            {node.node.isDone ? <MdCheckCircle style={{fontSize: '22px', color: '#2bffcc'}}/> : <MdRadioButtonUnchecked style={{fontSize: '22px', color: '#2bffcc'}}/>}
          </div>)}
        </div>
      )
    });
  }

  renderGroceriesByRecipe = () => {
    return this.state.groupedByRecipe && Object.entries(this.state.groupedByRecipe).map(([key, value]) => {
      return(
        <div>
          <p className='aisle-section'>{key}</p>
          <Divider />
          {value.map(node => 
          <div onClick={() => this.props.toggleShoppingListItem(node.node.databaseId, false)} className='shopping-list-item'>
            {node.node.isDone ? <MdCheckCircle style={{fontSize: '22px', color: '#2bffcc'}}/> : <MdRadioButtonUnchecked style={{fontSize: '22px', color: '#2bffcc'}}/>}
            <div>
              <p className='item-text'>{node.node.ingredient}</p> 
              <p className='item-qty'>{node.node.floatQuantity} {node.node.unit}</p>
            </div>
          </div>)}
        </div>
      )
    });
  }

  render(){
    return(
      <div className='suggestic-shopping-list'>
       {this.renderTabs()}
      </div>
    )
  }
}

export default SuggesticShoppingPage;