import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Popover, Spin, Avatar as AvatarIcon, Badge, Menu, Dropdown } from "antd";
import {
  registerUser,
  loginUser,
  logOut,
  showAuthModal,
  hideAuthModal,
} from "../../ducks/Auth/actions";
import { saveProfile } from "../../ducks/Profile/actions";
import Nubod from "../../assets/logos/Nubod-cropped.png";
import { clearProfile } from "../../ducks/Profile/actions";
import Avatar from "../../assets/images/avatar.png";
import { LoadingOutlined } from "@ant-design/icons";
// import NubodWhite from '../../assets/logos/nubod-white.png';
import NubodBlack from '../../assets/logos/nubod-black.png';
// import AuthModal from "../AuthModal";
// import AuthModal from "../AuthModal";
// import Payments from '../Payments';
import { clearRecipes } from "../../ducks/Recipe/actions";
import { signOut } from "./../../helpers/auth";
import { setIsLogin } from "../../ducks/Global/actions";
import { OPEN_DRAWER } from "../../ducks/Global/constants";
// import SideDrawer from '../../components/SideDrawer';
import { AiOutlineSetting } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { scrollToRef } from "../../ducks/Global/actions";
import './index.scss';
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  getAllUserNotifications,
  updateAllNotificationReadStatus,
  updateNotificationReadStatus,
} from "../../ducks/Onesignal/actions";
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";
import moment from "moment";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
const { Header } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class PageHeader extends Component {
  constructor() {
    super();
    this.state = {
      isTop: true,
      active: "/",
      mobileMenu: false,
      isMobileWidth: false,
      showNotification: false,
      isDeleteId: null,
      isMarkAllRead: false,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
    window.innerWidth <= 1050
      ? this.setState({ isMobileWidth: true })
      : this.setState({ isMobileWidth: false });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        active: nextProps.location.pathname.split("/").pop() || "/",
      });
    }
    window.addEventListener("resize", () => {
      window.innerWidth <= 1050
        ? this.setState({ isMobileWidth: true })
        : this.setState({ isMobileWidth: false });
    });
    if (window.location.href.indexOf("assessment") > -1) {
      this.setState({ isLogin: false });
    } else {
      this.setState({ isLogin: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.auth &&
      this.props.auth &&
      this.props.auth.user &&
      prevProps.auth.user !== this.props.auth.user
    ) {
      this.props.getAllUserNotifications(this.props.auth.user.email);
    }
  }

  componentWillMount() {
    this.setState({
      active: this.props.location.pathname.split("/").pop() || "/",
    });
  }

  setActive = (e, active) => {
    window.location.pathname.split("/");
    this.setState({ active: active, mobileMenu: false });
  };

  onScroll = (isTop) => {
    this.setState({ isTop });
  };

  closeAuthModal = () => {
    this.props.hideAuthModal();
  };

  hideNotification = () => {
    this.setState({
      showNotification: false,
    });
  };

  handleShowNotification = (showNotification) => {
    this.setState({ showNotification });
  };

  handleDeleteNotification = (notificationObj) => {
    this.setState({ isDeleteId: notificationObj.id, isMarkAllRead: false });
    this.props.updateNotificationReadStatus({
      userId: this.props.auth && this.props.auth.user._id,
      userEmail: this.props.auth && this.props.auth.user.email,
      notificationId: notificationObj.id,
      isDelete: true,
    });
  };

  handleMarkAsRead = (notificationObj) => {
    this.setState({ isDeleteId: null, isMarkAllRead: false });
    this.props.updateNotificationReadStatus({
      userId: this.props.auth && this.props.auth.user._id,
      userEmail: this.props.auth && this.props.auth.user.email,
      notificationId: notificationObj.id,
      isRead: true,
    });
  };

  handleMarkAllAsRead = (userNotifications) => {
    this.setState({ isDeleteId: null, isMarkAllRead: true });
    const nonReadNotifications = userNotifications.reduce(
      (acc, notificationObj) => {
        if (!notificationObj.isRead) {
          acc.push({
            userId: this.props.auth && this.props.auth.user._id,
            userEmail: this.props.auth && this.props.auth.user.email,
            notificationId: notificationObj.id,
            isRead: true,
          });
        }
        return acc;
      },
      []
    );
    if (nonReadNotifications && nonReadNotifications.length > 0) {
      this.props.updateAllNotificationReadStatus(nonReadNotifications);
    }
  };

  isLastMsgToday = (date) => {
    const lastMsgDate = moment(date).format("D MMM YYYY");
    const today = moment().format("D MMM YYYY");
    if (today === lastMsgDate) return true;
    else return false;
  };

  lastMsgDateNotToday = (date) => {
    if (!date) return moment().format("D MMM YYYY");
    const lastMsgDate = moment(date).format("D MMM YYYY");
    return lastMsgDate;
  };

  handleNotificationClick = (notificationObj) => {
    this.handleMarkAsRead(notificationObj);
    if (notificationObj.url) {
      const url =
        notificationObj.url &&
        notificationObj.url.split(`${process.env.REACT_APP_APP_URL}`);
      this.props.history.push(url && url[1]);
    }
  };

  getNotifications = () => {
    return this.props.userNotifications &&
      this.props.userNotifications.length > 0 ? (
      <div className="notification-section">
        <ul>
          {this.props.userNotifications &&
            this.props.userNotifications.map((notificationObj) => {
              const messageArr =
                notificationObj.message && notificationObj.message.split(" ");
              const isEmoji = messageArr && messageArr.pop() === "&#x1F389;";
              const message = isEmoji
                ? messageArr && messageArr.join(" ")
                : notificationObj.message;
              return (
                <li key={notificationObj.id}>
                  {/*<div onClick={() => this.handleMarkAsRead(notificationObj)}>*/}
                  <div className="notification-profile">
                    <div className="notification-profile-wrap">
                      <div
                        className="flex flex-center-aligned"
                        onClick={() =>
                          this.handleNotificationClick(notificationObj)
                        }
                      >
                        <div className="notification-profile-img">
                          <AvatarIcon
                            size={40}
                            src={require("../../assets/logos/nubod-logo.png")}
                          />
                          <span>
                            {!notificationObj.isRead && (
                              <div className="notification-status read-notification" />
                            )}
                          </span>
                        </div>
                        <div className="notification-info-wrap">
                          <div className="flex">
                            <h5>{notificationObj.title}</h5>
                            <span className="notification-date">
                              {this.isLastMsgToday(
                                notificationObj.data &&
                                  notificationObj.data.fullDate
                              )
                                ? notificationObj.data &&
                                  notificationObj.data.timeSet
                                : this.lastMsgDateNotToday(
                                    notificationObj.data &&
                                      notificationObj.data.fullDate
                                  )}
                            </span>
                          </div>
                          {
                            <p className="mt-1">
                              {message}
                              {isEmoji && <span>&#x1F389;</span>}
                            </p>
                          }
                        </div>
                      </div>
                      <div className="delete-btn">
                        {this.props.notificationLoading &&
                        this.state.isDeleteId === notificationObj.id ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <DeleteOutlined
                            onClick={() =>
                              this.handleDeleteNotification(notificationObj)
                            }
                          />
                        )}
                      </div>
                    </div>
                    {
                      <div>
                        {notificationObj.data &&
                          notificationObj.data.files &&
                          notificationObj.data.files.map((file) => (
                            <div>
                              {file.fileType === "image" ? (
                                <img
                                  className="image-size"
                                  src={`${process.env.REACT_APP_API_URL}${file.filePath}`}
                                />
                              ) : (
                                <div className="pdf-file-icon">
                                  <FileTextOutlined size={40} />
                                  <div>
                                    <p
                                      onClick={() => {
                                        window.open(
                                          `${process.env.REACT_APP_API_URL}${file.filePath}`,
                                          "_blank",
                                          "fullscreen=yes"
                                        );
                                      }}
                                    >
                                      {file.fileName}
                                    </p>
                                    <p>{file.size}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    }
                  </div>
                </li>
              );
            })}
        </ul>
        {/*<a onClick={this.hideNotification}>Close</a>*/}
      </div>
    ) : (
      <div style={{ padding: "5px 0 10px 0" }}>You don't have any notifications.</div>
    );
  };

  getUnreadMsgCount = (notifications) => {
    if (!notifications) {
      return 0;
    }
    return notifications.filter((notif) => !notif.isRead).length;
  };

  renderDashboardHeader = () => {
    // <a href="javascript:$zopim.livechat.window.show()"><img src="https://my_cdn.com/open_chat.png"/></a>
    // <a href="javascript:$zopim.livechat.window.hide()"><img src="https://my_cdn.com/close_chat.png"/></a>

    const badgeCount = this.getUnreadMsgCount(this.props.userNotifications);

    return (
      this.props.history.location.pathname.split("/").includes("dashboard") && (
        <Header className="dashboard-header">
          <div className="user-dropdown">
            {/* <Link
              to={{
                pathname: "/",
                state: {landing: true}
              }}
            > */}
            <div
              onClick={() => window.open("https://www.getnubod.com", "_self")}
              style={{ backgroundImage: `url(${Nubod})` }}
            />
            {/* </Link> */}
            {this.props.auth && this.props.auth.isAuthenticated && (
              <div className="menu-right">
                <>
                  <Dropdown
                    overlay={this.renderSettingsMenu()}
                    trigger={["click"]}
                  >
                    <div className="page-header-menu-item flex flex-center-aligned">
                      <AiOutlineSetting style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "12px", fontWeight: "600", color: '#000000' }}>
                        Settings
                      </p>
                      <MdArrowDropDown style={{ fontSize: "18px" }} />
                    </div>
                  </Dropdown>
                </>
                <div className="bell flex flex-center-aligned">
                  <Popover
                    placement="bottom"
                    overlayClassName="popover-wrap"
                    content={
                      this.props.notificationLoading &&
                      (this.state.isDeleteId
                        ? false
                        : this.props.userNotifications.length === 0) ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        this.getNotifications()
                      )
                    }
                    title={
                      <div className="notification-wrap">
                        <p>Notifications</p>
                        {this.props.userNotifications &&
                        this.props.userNotifications.length > 0 &&
                        !this.state.isDeleteId &&
                        this.state.isMarkAllRead &&
                        this.props.notificationLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          this.props.userNotifications &&
                          this.props.userNotifications.length > 0 && (
                            <p
                              className="blue-link"
                              onClick={() =>
                                this.handleMarkAllAsRead(
                                  this.props.userNotifications
                                )
                              }
                            >
                              Mark all as read
                            </p>
                          )
                        )}
                      </div>
                    }
                    trigger="click"
                    visible={this.state.showNotification}
                    onVisibleChange={this.handleShowNotification}
                  >
                    {badgeCount > 0 ? (
                      <Badge count={badgeCount}>
                        <IoMdNotificationsOutline />
                      </Badge>
                    ) : (
                      <IoMdNotificationsOutline />
                    )}
                  </Popover>
                </div>
                <Dropdown
                  overlay={this.renderMenu()}
                  trigger={["click"]}
                  style={{ cursor: "pointer" }}
                >
                  <div className="dropdown flex flex-center-aligned">
                    <img
                      alt="Avatar"
                      className="img"
                      src={this.props.auth.user.avatar || Avatar}
                    />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        </Header>
      )
    );
  };

  handleMenuClick = (e, tab) => {
    if (e.key === "1" && tab === "user") {
      signOut();
      this.props.clearRecipes();
      this.props.clearProfile();
      this.props.logOut();
      this.props.history.push("/");
    } else if (e.key === "1" && tab === "settings") {
      this.props.history.push("/dashboard/settings/account");
    } else if (e.key === "2" && tab === "settings") {
      this.props.history.push("/dashboard/settings/notifications");
    } else if (e.key === "3" && tab === "settings") {
      this.props.history.push("/dashboard/settings/integrations");
    } else if (e.key === "4" && tab === "settings") {
      this.props.history.push("/dashboard/settings/billing");
    } else if (e.key === "5" && tab === "user") {
      this.props.history.push("/dashboard/profile");
    }
  };

  renderMenu = () => {
    return (
      <Menu className='nav-bar-menu' onClick={(e) => this.handleMenuClick(e, 'user')}>
        <div className='menu-item'>
          <p style={{fontSize: '16px', fontWeight: '600', color: 'black'}}>{this.props.auth.user.name} {this.props.auth.user.lastName}</p>
          <p style={{fontSize: '12px', lineHeight: '12px'}}>{this.props.auth.user.email}</p>
        </div>
        <Menu.Item key="5">Profile</Menu.Item>
        <Menu.Item key="1">Logout</Menu.Item>
      </Menu>
    );
  };

  renderSettingsMenu = () => {
    return (
      <Menu
        className="nav-bar-menu"
        onClick={(e) => this.handleMenuClick(e, "settings")}
      >
        <Menu.Item key="1">Account</Menu.Item>
        {/* <Menu.Item key="2">Notifications</Menu.Item> */}
        <Menu.Item key="3">Integrations</Menu.Item>
        {!this.props.auth.user.userType === 'fixed' && <Menu.Item key="4">Billing</Menu.Item>}
      </Menu>
    );
  };

  renderLandingHeader = () => {
    const isAssessment = window.location.href.indexOf("assessment") > -1;  
    const isSharablePage = window.location.href.includes("share");  
    if(isSharablePage) return null;
    return (
      !this.props.history.location.pathname
        .split("/")
        .includes("dashboard") && (
        <Header
          className={
            isAssessment 
              ? "header-navigation header-shadow"
              : "header-navigation header-shadow login-header"
          }
          style={{ boxShadow: isAssessment && "none" }}
        >
          <div className="header-content">
            <div
              className="header-logo"
            >
              <img
                src={NubodBlack}
                className="alvaro-fitness-logo"
                alt={"Nubod"}
              />
            </div>
          </div>
        </Header>
      )
    );
  };

  render() { 
    return (
      <React.Fragment>
        {this.renderDashboardHeader()}
        {this.renderLandingHeader()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    shouldShowAuthModal: state.auth.showAuthModal,
    authRedirectRoute: state.auth.authRedirectRoute,
    isLogin: state.global.isLogin,
    notificationLoading: state.onesignal.notificationLoading,
    userNotifications: state.onesignal.userNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (formData, cb) => dispatch(registerUser(formData, cb)),
    logOut: () => dispatch(logOut()),
    loginUser: (formData, cb) => dispatch(loginUser(formData, cb)),
    clearProfile: () => dispatch(clearProfile()),
    clearRecipes: () => dispatch(clearRecipes()),
    showAuthModal: () => dispatch(showAuthModal()),
    hideAuthModal: () => dispatch(hideAuthModal()),
    saveProfile: (payload) => dispatch(saveProfile(payload)),
    setIsLogin: (value) => dispatch(setIsLogin(value)),
    scrollToRef: (ref) => dispatch(scrollToRef(ref)),
    openDrawer: () => {
      return dispatch({
        type: OPEN_DRAWER,
      });
    },
    getAllUserNotifications: (userEmail) =>
      dispatch(getAllUserNotifications(userEmail)),
    updateNotificationReadStatus: (payload) =>
      dispatch(updateNotificationReadStatus(payload)),
    updateAllNotificationReadStatus: (payload) =>
      dispatch(updateAllNotificationReadStatus(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageHeader));