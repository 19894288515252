import React from 'react';
import { connect } from 'react-redux';
import { TOGGLE_CREATE_WORKOUT_MODAL } from '../../ducks/Global/constants';
import { getUserWorkouts } from '../../ducks/Workout/actions';
import UserWorkoutsPage from '../../presentational/UserWorkoutsPage';
import { TOGGLE_EXERCISE_HISTORY_MODAL } from '../../ducks/Global/constants';
import { Loading } from '../../components/Loading';

class UserWorkouts extends React.Component {

  componentDidMount(){
    !this.props.userWorkouts.length && this.props.getUserWorkouts(this.props.user._id);
  }

  render(){
    return(
     <div className='workouts-page'>
      {this.props.getWorkoutsLoading ? (
        <Loading />
      ) : this.props.userWorkouts.length > 0 ? (
        <UserWorkoutsPage {...this.props} />
      ) : (
        <div style={{ backgroundColor: '#e4e7eb', borderRadius: '10px' }}>
          <p className='home-card-text' style={{ padding: '22px' }}>
            You haven't completed any workouts yet.
          </p>
        </div>
      )}
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userWorkouts: state.workouts.userWorkouts,
    exerciseHistoryModalVisible: state.global.exerciseHistoryModalVisible,
    allExerciseHistory: state.exercises.allExerciseHistory,
    getWorkoutsLoading: state.workouts.getWorkoutsLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserWorkouts: (clientId, currentWeek, averageMonth) => dispatch(getUserWorkouts(clientId, currentWeek, averageMonth)),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleExerciseHistoryModal: (value) => dispatch({
      type: TOGGLE_EXERCISE_HISTORY_MODAL,
      value
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWorkouts);