import {
  GET_WORKOUT_PLAN_LIBRARY,
  GET_WORKOUT_PLAN_LIBRARY_SUCCESS,
  GET_WORKOUT_PLAN_LIBRARY_ERROR,
  GET_USER_WORKOUT_PLANS,
  GET_USER_WORKOUT_PLANS_SUCCESS,
  GET_USER_WORKOUT_PLANS_ERROR
} from "./constants";

export function getWorkoutPlanLibrary() {
  return {
    type: GET_WORKOUT_PLAN_LIBRARY
  };
}

export function getWorkoutPlanLibrarySuccess(payload) {
  return {
    type: GET_WORKOUT_PLAN_LIBRARY_SUCCESS,
    payload
  };
}

export function getWorkoutPlanLibraryError(err) {
  return {
    type: GET_WORKOUT_PLAN_LIBRARY_ERROR,
    err
  };
}

export function getUserWorkoutPlans(clientId) {
  return {
    type: GET_USER_WORKOUT_PLANS,
    clientId
  };
}

export function getUserWorkoutPlansSuccess(payload) {
  return {
    type: GET_USER_WORKOUT_PLANS_SUCCESS,
    payload
  };
}

export function getUserWorkoutPlansError(err) {
  return {
    type: GET_USER_WORKOUT_PLANS_ERROR,
    err
  };
}