import React from 'react';
import { connect } from 'react-redux';
import { IntegrationsCard } from './IntegrationsCard';
import { IntegrationsData } from '../../data/IntegrationsData';
import { REMOVE_FITBIT_ACCESS_TOKEN } from '../../ducks/Integrations/constants';
import './index.css';

class IntegrationsPage extends React.Component {
  render(){
    return(
      <div className='integrations'>
        {this.props.profile && IntegrationsData(this.props.profile).map(integration => 
        <IntegrationsCard {...this.props} key={integration.name} integration={integration} />)}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeFitbitAccessToken: () => {
			return dispatch({
					type: REMOVE_FITBIT_ACCESS_TOKEN
			 });
		},
  };
}


export default connect(null, mapDispatchToProps)(IntegrationsPage);