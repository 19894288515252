import {
  GET_SUGGESTIC_PROFILE,
  GET_SUGGESTIC_PROFILE_SUCCESS,
  GET_SUGGESTIC_PROFILE_ERROR,
  UPDATE_SUGGESTIC_PROFILE,
  UPDATE_SUGGESTIC_PROFILE_SUCCESS,
  UPDATE_SUGGESTIC_PROFILE_ERROR,
  GET_RESTRICTIONS,
  GET_RESTRICTIONS_SUCCESS,
  GET_RESTRICTIONS_ERROR,
  UPDATE_RESTRICTIONS,
  UPDATE_RESTRICTIONS_SUCCESS,
  UPDATE_RESTRICTIONS_ERROR,
  GET_SHOPPING_LIST,
  GET_SHOPPING_LIST_SUCCESS,
  GET_SHOPPING_LIST_ERROR,
  GET_SHOPPING_LIST_SUG,
  GET_SHOPPING_LIST_SUG_SUCCESS,
  GET_SHOPPING_LIST_SUG_ERROR,
  TOGGLE_SHOPPING_LIST_ITEM,
  TOGGLE_SHOPPING_LIST_ITEM_SUCCESS,
  TOGGLE_SHOPPING_LIST_ITEM_ERROR,
  ADD_TO_SHOPPING_LIST,
  ADD_TO_SHOPPING_LIST_SUCCESS,
  ADD_TO_SHOPPING_LIST_ERROR,
  ADD_TO_SHOPPING_LIST_SUG,
  ADD_TO_SHOPPING_LIST_SUG_SUCCESS,
  ADD_TO_SHOPPING_LIST_SUG_ERROR,
  DELETE_FROM_SHOPPING_LIST,
  DELETE_FROM_SHOPPING_LIST_SUCCESS,
  DELETE_FROM_SHOPPING_LIST_ERROR,
  CLEAR_SHOPPING_LIST,
  CLEAR_SHOPPING_LIST_SUCCESS,
  CLEAR_SHOPPING_LIST_ERROR,
  GET_USER_SHOPPING_LIST_AGGREGATE,
  GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS,
  GET_USER_SHOPPING_LIST_AGGREGATE_ERROR,
  CLEAR_ADD_TO_SHOPPING_LIST_ERROR,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR,
  CLEAR_CUSTOM_SHOPPING_LIST,
  CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS,
  CLEAR_CUSTOM_SHOPPING_LIST_ERROR,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR
} from "./constants";

export function getSuggesticProfile() {
  return {
    type: GET_SUGGESTIC_PROFILE
  };
}

export function getSuggesticProfileSuccess(payload) {
  return {
    type: GET_SUGGESTIC_PROFILE_SUCCESS,
    payload
  };
}

export function getSuggesticProfileError(error) {
  return {
    type: GET_SUGGESTIC_PROFILE_ERROR,
    error
  };
}

export function updateSuggesticProfile() {
  return {
    type: UPDATE_SUGGESTIC_PROFILE
  };
}

export function updateSuggesticProfileSuccess(payload) {
  return {
    type: UPDATE_SUGGESTIC_PROFILE_SUCCESS,
    payload
  };
}

export function updateSuggesticProfileError(error) {
  return {
    type: UPDATE_SUGGESTIC_PROFILE_ERROR,
    error
  };
}

export function getRestrictions() {
  return {
    type: GET_RESTRICTIONS
  };
}

export function getRestrictionsSuccess(payload) {
  return {
    type: GET_RESTRICTIONS_SUCCESS,
    payload
  };
}

export function getRestrictionsError(error) {
  return {
    type: GET_RESTRICTIONS_ERROR,
    error
  };
}

export function updateRestrictions(restrictionsArr) {
  return {
    type: UPDATE_RESTRICTIONS,
    restrictionsArr
  };
}

export function updateRestrictionsSuccess(payload) {
  return {
    type: UPDATE_RESTRICTIONS_SUCCESS,
    payload
  };
}

export function updateRestrictionsError(error) {
  return {
    type: UPDATE_RESTRICTIONS_ERROR,
    error
  };
}

export function getShoppingList() {
  return {
    type: GET_SHOPPING_LIST
  };
}

export function getShoppingListSuccess(payload) {
  return {
    type: GET_SHOPPING_LIST_SUCCESS,
    payload
  };
}

export function getShoppingListError(error) {
  return {
    type: GET_SHOPPING_LIST_ERROR,
    error
  };
}

export function getShoppingListSug() {
  return {
    type: GET_SHOPPING_LIST_SUG
  };
}

export function getShoppingListSugSuccess(payload) {
  return {
    type: GET_SHOPPING_LIST_SUG_SUCCESS,
    payload
  };
}

export function getShoppingListSugError(error) {
  return {
    type: GET_SHOPPING_LIST_SUG_ERROR,
    error
  };
}

export function getUserShoppingListAggregate() {
  return {
    type: GET_USER_SHOPPING_LIST_AGGREGATE
  };
}

export function getUserShoppingListAggregateSuccess(payload) {
  return {
    type: GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS,
    payload
  };
}

export function getUserShoppingListAggregateError(error) {
  return {
    type: GET_USER_SHOPPING_LIST_AGGREGATE_ERROR,
    error
  };
}

export function toggleShoppingListItem(itemId, isAggregate) {
  return {
    type: TOGGLE_SHOPPING_LIST_ITEM,
    itemId,
    isAggregate
  };
}

export function toggleShoppingListItemSuccess(shoppingList, isDone, itemId, isAggregate) {
  return {
    type: TOGGLE_SHOPPING_LIST_ITEM_SUCCESS,
    shoppingList,
    isDone,
    itemId,
    isAggregate
  };
}

export function toggleShoppingListItemError(error) {
  return {
    type: TOGGLE_SHOPPING_LIST_ITEM_ERROR,
    error
  };
}

export function addToShoppingList(foodItem) {
  return {
    type: ADD_TO_SHOPPING_LIST,
    foodItem
  };
}

export function addToShoppingListSuccess(payload) {
  return {
    type: ADD_TO_SHOPPING_LIST_SUCCESS,
    payload
  };
}

export function addToShoppingListError(error) {
  return {
    type: ADD_TO_SHOPPING_LIST_ERROR,
    error
  };
}

export function addToShoppingListSug(ids, allRecipes) {
  return {
    type: ADD_TO_SHOPPING_LIST_SUG,
    ids,
    allRecipes
  };
}

export function addToShoppingListSugSuccess(payload) {
  return {
    type: ADD_TO_SHOPPING_LIST_SUG_SUCCESS,
    payload
  };
}

export function addToShoppingListSugError(error) {
  return {
    type: ADD_TO_SHOPPING_LIST_SUG_ERROR,
    error
  };
}


export function deleteFromShoppingList(id) {
  return {
    type: DELETE_FROM_SHOPPING_LIST,
    id
  };
}

export function deleteFromShoppingListSuccess(id) {
  return {
    type: DELETE_FROM_SHOPPING_LIST_SUCCESS,
    id
  };
}

export function deleteFromShoppingListError(error) {
  return {
    type: DELETE_FROM_SHOPPING_LIST_ERROR,
    error
  };
}

export function clearShoppingList() {
  return {
    type: CLEAR_SHOPPING_LIST
  };
}

export function clearShoppingListSuccess() {
  return {
    type: CLEAR_SHOPPING_LIST_SUCCESS
  };
}

export function clearShoppingListError(error) {
  return {
    type: CLEAR_SHOPPING_LIST_ERROR,
    error
  };
}

export function clearAddToShoppingListError(val) {
  return {
    type: CLEAR_ADD_TO_SHOPPING_LIST_ERROR,
    val
  };
}

export function updateCustomShoppingListItem(item){
  return {
    type: UPDATE_CUSTOM_SHOPPING_LIST_ITEM,
    item
  }
}

export function updateCustomShoppingListItemSuccess(payload){
  return {
    type: UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS,
    payload
  }
}


export function updateCustomShoppingListItemError(err){
  return {
    type: UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR,
    err
  };
}

export function clearCustomShoppingList() {
  return {
    type: CLEAR_CUSTOM_SHOPPING_LIST
  };
}

export function clearCustomShoppingListSuccess() {
  return {
    type: CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS
  };
}

export function clearCustomShoppingListError(error) {
  return {
    type: CLEAR_CUSTOM_SHOPPING_LIST_ERROR,
    error
  };
}

export function clearCustomCheckedFoodItems() {
  return {
    type: CLEAR_CUSTOM_CHECKED_FOOD_ITEMS
  };
}

export function clearCustomCheckedFoodItemsSuccess() {
  return {
    type: CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS
  };
}

export function clearCustomCheckedFoodItemsError(error) {
  return {
    type: CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR,
    error
  };
}