import {
  SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS,
  CLEAR_CONTACT_SUCCESS,
  HANDLE_PROFILE_SUBMENU,
  SET_CURRENT_PAGE,
  SET_STAT_VIEW_MODE,
  CLOSE_DRAWER,
  OPEN_DRAWER,
  HANDLE_SIDEBAR,
  SET_IS_LOGIN,
  HANDLE_PRICING_MODAL,
  STORE_IS_LAST_TWO_DAYS, 
  SET_PROGRAM_VISIBILITY,
  HANDLE_LEGAL_MODAL,
  SET_RECIPE_QUERY_OBJ,
  TOGGLE_CREATE_EXERCISE_MODAL,
  TOGGLE_CREATE_USER_MODAL,
  TOGGLE_CREATE_WORKOUT_MODAL,
  TOGGLE_EXERCISE_HISTORY_MODAL,
  TOGGLE_WORKOUT_PROGRAM_MODAL,
  TOGGLE_CONFIRM_MODAL,
  TOGGLE_WORKOUT_TIMER_RUNNING,
  SET_WORKOUT_IN_PROGRESS,
  RESET_TIMER,
  SET_WORKOUT_TO_GLOBAL_STATE,
  GET_SCHEDULED_NOTIFICATIONS,
  GET_SCHEDULED_NOTIFICATIONS_SUCCESS,
  GET_SCHEDULED_NOTIFICATIONS_ERROR,
  OPEN_REVIEW_MODAL,
  SHOW_TOP_HEADER,
  HANDLE_ACHIEVEMENT_MODAL
} from './constants';

const initialState = {
  contactSuccess: '',
  activeProfileSubmenu: 'Macronutrients',
  currentPage: { name: 'Home', icon: '', path: '/dashboard/home', subMenuItems: [] },
  tableMode: true,
  showDrawer: false,
  collapsed: false,
  isLogin: false,
  pricingModalOpen: false,
  isLastTwoDaysOfPlan: false,
  isProgramVisible: false,
  isLegalModalVisible: false,
  legalPage: null,
  recipeQueryObj: null,
  mealToMatchRecipe: null,
  createExerciseModalVisible: false,
  createUserModalVisible: false,
  createWorkoutModalVisible: false,
  exerciseHistoryModalVisible: false,
  workoutProgramModalVisible: false,
  confirmModalVisible: false,
  timerRunning: false,
  workoutInProgress: false,
  shouldResetTimer: false,
  userGeneratedWorkout: null,
  scheduledNotifications: null,
  reviewModalOpen: false,
  review: null,
  showTopHeader: false,
  topHeaderContent: null,
  achievementProps: {}
};

function global(state = initialState, action) {
  switch (action.type) {
    case SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS:
      return {
        ...state,
        contactSuccess: action.response
      };
    case CLEAR_CONTACT_SUCCESS:
      return {
        ...state,
        contactSuccess: ''
      };
    case HANDLE_PROFILE_SUBMENU:
      return {
        ...state,
        activeProfileSubmenu: action.value
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case SET_STAT_VIEW_MODE:
      return {
        ...state,
        tableMode: action.tableMode
      };
    case OPEN_DRAWER:
      return {
        ...state,
        showDrawer: true
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        showDrawer: false
      };
    case HANDLE_SIDEBAR:
      return {
        ...state,
        collapsed: action.collapsed
      };
    case SET_IS_LOGIN:
      return {
        ...state,
        isLogin: action.value
      };
    case HANDLE_PRICING_MODAL:
      return {
        ...state,
        pricingModalOpen: action.pricingModalOpen
      }

    case STORE_IS_LAST_TWO_DAYS:
      return {
        ...state,
        isLastTwoDaysOfPlan: action.isLastTwoDaysOfPlan
      }
    case SET_PROGRAM_VISIBILITY:
      return {
        ...state,
        isProgramVisible: action.payload
      };
    case HANDLE_LEGAL_MODAL:
      return {
        ...state,
        isLegalModalVisible: action.visible,
        legalPage: action.legalPage
      };
    case SET_RECIPE_QUERY_OBJ:
      return {
        ...state,
        recipeQueryObj: action.recipeQueryObj,
        mealToMatchRecipe: action.meal
      };
    case TOGGLE_CREATE_EXERCISE_MODAL:
      return {
        ...state,
        createExerciseModalVisible: action.value
      };
    case TOGGLE_CREATE_USER_MODAL:
      return {
        ...state,
        createUserModalVisible: action.value
      };
    case TOGGLE_CREATE_WORKOUT_MODAL:
      return {
        ...state,
        createWorkoutModalVisible: action.value
      };
    case TOGGLE_EXERCISE_HISTORY_MODAL:
      return {
        ...state,
        exerciseHistoryModalVisible: action.value
      };
    case TOGGLE_WORKOUT_PROGRAM_MODAL:
      return {
        ...state,
        workoutProgramModalVisible: action.value
      };
    case TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModalVisible: action.value
      };
    case TOGGLE_WORKOUT_TIMER_RUNNING:
      return {
        ...state,
        timerRunning: action.value
      };
    case SET_WORKOUT_IN_PROGRESS:
      return {
        ...state,
        workoutInProgress: action.value
      };
    case RESET_TIMER:
      return {
        ...state,
        shouldResetTimer: action.value
      }
    case SET_WORKOUT_TO_GLOBAL_STATE:
      return {
        ...state,
        userGeneratedWorkout: action.workout
      };
    case GET_SCHEDULED_NOTIFICATIONS:
      return {
        ...state,
        scheduledNotifications: state.scheduledNotifications,
        scheduledNotificationsLoading: true
      }
    case GET_SCHEDULED_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        scheduledNotifications: action.payload,
        scheduledNotificationsLoading: false
      }
    case GET_SCHEDULED_NOTIFICATIONS_ERROR:
      return {
        ...state,
        scheduledNotifications: action.payload,
        scheduledNotificationsErr: action.payload,
        scheduledNotificationsLoading: false
      }
    case OPEN_REVIEW_MODAL:
      return {
        ...state,
        reviewModalOpen: action.reviewModalOpen,
        review: action.review
      };
    case SHOW_TOP_HEADER:
      return {
        ...state,
        showTopHeader: action.showTopHeader,
        topHeaderContent: action.content
      };
    case HANDLE_ACHIEVEMENT_MODAL:
      return {
        ...state,
        achievementProps: action.achievementProps
      }
    default:
      return state;
  }
}

export default global;