import React from "react";
import { connect } from "react-redux";
import { getNutrition, getCurrentProfile } from "../../ducks/Profile/actions";
import HomeDashboard from "../../presentational/Home";
import {
  getGroceries,
  deleteOrUpdateGrocery,
  saveGrocery,
  clearGetGroceriesError,
} from "../../ducks/Recipe/actions";
import {
  getUserFitbitNutrition,
  createUserMealPlan,
  createWeeklyMealPlan,
} from "../../ducks/Profile/actions";
import { getUserBfMeasurements } from "../../ducks/Measurements/actions";
import { Loading } from "../../components/Loading";
import {
  HANDLE_PRICING_MODAL,
  OPEN_DRAWER,
} from "../../ducks/Global/constants";
import { PricingModal } from "../../components/PricingModal";
import { storeIsLastTwoDays } from "../../ducks/Global/actions";
import {
  checkOneSignal,
  createNotification,
} from "../../ducks/Onesignal/actions";
import ReactCanvasConfetti from "react-canvas-confetti";
import { RESET_USER_VERIFIED } from "../../ducks/Auth/constants";
import { getTodo } from "../../ducks/Todo/actions";
import firebaseApp from "../../helpers/auth";
import { createOrUpdateTodo } from '../../ducks/Todo/actions';
import {getUserSettings, createUserSetting} from '../../ducks/Setting/actions';

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      confettiRun: true,
      animationInstance: null,
      userSettings: {},
    };
  }

  componentDidMount(){
    this.props.getUserSettings();
    this.props.getNutrition();
    this.props.getGroceries();
    this.props.getCurrentProfile();
    this.props.getUserBfMeasurements();
    this.props.getUserFitbitNutrition();
    this.props.getTodo();
    this.setState({
      userSettings: this.props.userSettings,
    });
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.props.checkOneSignal(user.email);
      }
    });
    setTimeout(this.handlerFire, 400);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.userSettings !== this.props.userSettings && !this.props.userSettings?.showNotificationPrompt) {
      this.showNotificationTab()
    }
  }

  showNotificationTab = async () => {
    let isNotificationEnabled;
    const OneSignal = window.OneSignal || [];
   await OneSignal.isPushNotificationsEnabled(function(isEnabled) {
      isNotificationEnabled=isEnabled  
    });
    if (!this.state.userSettings.showNotificationPrompt && !isNotificationEnabled) {
      OneSignal.push(function () {
        OneSignal.showNativePrompt();
      });
      if (this.state.userSettings) {
        this.props.createUserSetting({
          ...this.state.userSettings,
          showNotificationPrompt: true,
        });
      }
     OneSignal.on('subscriptionChange', (isSubscribed) => {
        this.props.createUserSetting({ ...this.state.userSettings, active: isSubscribed });
      });

    }else{
      this.props.createUserSetting({ ...this.state.userSettings, active: isNotificationEnabled, showNativePrompt:true });
    }

  };
  
  getInstance = (instance) => {
    this.state.animationInstance = instance;
  };

  makeShot = (particleRatio, opts) => {
    this.state.animationInstance &&
      this.state.animationInstance({
        ...opts,
        origin: { x: 0.5, y: 0.5 },
        particleCount: 1000,
      });
  };

  fire = () => {
    this.makeShot(0.25, {
      startVelocity: 55,
      spread: 120,
      ticks: 400,
    });
  };

  handlerFire = () => {
    this.fire();
  };

  render() {
    const { nutrition, profile, userBodyFatMeasurements, isUserVerified } =
      this.props;
    const notLoading = profile && userBodyFatMeasurements && nutrition;

    return (
      <div className={`home-page ${notLoading ? "" : "loading"}`}>
        {isUserVerified && (
          <ReactCanvasConfetti
            refConfetti={this.getInstance}
            style={canvasStyles}
            fire={true}
          />
        )}
        {notLoading ? <HomeDashboard {...this.props} /> : <Loading />}
        <PricingModal
          {...this.props}
          handlePricingModal={this.props.handlePricingModal}
          pricingModalOpen={this.props.pricingModalOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    nutrition: state.profile.nutrition,
    recipes: state.recipes.recipes,
    groceryList: state.recipes.groceryList,
    fitbitNutrition: state.profile.fitbitNutrition,
    userBodyFatMeasurements: state.measurements.userBodyFatMeasurements,
    fitbitNutritionLoading: state.profile.fitbitNutritionLoading,
    suggesticMealPlanLoading: state.profile.suggesticMealPlanLoading,
    pricingModalOpen: state.global.pricingModalOpen,
    isLastTwoDaysOfPlan: state.global.isLastTwoDaysOfPlan,
    isUserVerified: state.auth.isUserVerified,
    showDrawer: state.global.showDrawer,
    todo: state.todo.todo,
    userSettings: state.settings.userSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSettings: () => dispatch(getUserSettings()),
    createUserSetting: (setting) => dispatch(createUserSetting(setting)),
    getNutrition: () => dispatch(getNutrition()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getGroceries: () => dispatch(getGroceries()),
    deleteOrUpdateGrocery: (id, action, ingredients) =>
      dispatch(deleteOrUpdateGrocery(id, action, ingredients)),
    saveGrocery: (dish, ingredients, image) =>
      dispatch(saveGrocery(dish, ingredients, image)),
    clearGetGroceriesError: () => dispatch(clearGetGroceriesError()),
    getUserFitbitNutrition: () => dispatch(getUserFitbitNutrition()),
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
    createUserMealPlan: (mealPlanData) =>
      dispatch(createUserMealPlan(mealPlanData)),
    createWeeklyMealPlan: (mealPlanData) =>
      dispatch(createWeeklyMealPlan(mealPlanData)),
    createNotification: (input) => dispatch(createNotification(input)),
    openDrawer: () => {
      return dispatch({
        type: OPEN_DRAWER,
      });
    },
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen,
      });
    },
    resetUserVerified: () => {
      return dispatch({
        type: RESET_USER_VERIFIED,
      });
    },
    storeIsLastTwoDays: (profile) => dispatch(storeIsLastTwoDays(profile)),
    checkOneSignal: (email) => dispatch(checkOneSignal(email)),
    getTodo: () => dispatch(getTodo()),
    createOrUpdateTodo: (todo) => dispatch(createOrUpdateTodo(todo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
