import React from 'react';
import ProfileForm from '../../components/ProfileForm';
import './index.css';

class ProfilePage extends React.Component {
  render(){
    return(
      <ProfileForm {...this.props} />     
    )
  }
}

export default ProfilePage;