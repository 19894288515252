import React from 'react';
import './index.scss';
import NutritionPreferencesCard from './card';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { Checkbox, Popconfirm } from 'antd';

class NutritionPreferencesPage extends React.Component {
  constructor(){
    super();
    this.state={
      fruitsAndVegetables: false,
      mostCommon: false,
      animalOrigin: false,
      nutsAndSeeds: false,
      herbsAndSpices: false,
      general: false,
      cerealsAndGrains: false,
      data: [],
      restrictionsNodes: []
    }
  }

  componentDidMount(){
    this.setState({
      data: this.props.foodRestrictions && JSON.parse(JSON.stringify(this.props.foodRestrictions.data.restrictions.edges))
    }, () =>  this.setRestrictionsOnList());
  }

  setRestrictionsOnList = () => {
    let isCustomPlan = this.props.isCustomPlan;
    let nutritionPreferences = isCustomPlan ? JSON.parse(JSON.stringify(this.props.nutritionPreferences)) : this.props.suggesticProfile;
    let restrictionsNodes = [...this.state.restrictionsNodes];
    // let foodRestrictions = [...this.props.foodRestrictions.restrictions.edges];
    let foodRestrictions = [...this.state.data];
    foodRestrictions && foodRestrictions.forEach(restriction => {
      //Alvaro - Removed temporarily. Needs to be commented back in when incorporating suggestic feature
      // if(restriction.node.isOnProgram === true){
      //   restriction.node.disabled = true;
      //   restriction.node.message = 'This food is already avoided in your diet type.'
      // }
      nutritionPreferences && nutritionPreferences.restrictions.map(profileRestriction => {
        if(!isCustomPlan && restriction.node.name === profileRestriction.name){
          restriction.node.isOnProgram = true;
          restrictionsNodes.push(restriction.node);
        } else if(isCustomPlan && restriction.node.id === profileRestriction.id){
          restriction.node.isOnProgram = true;
          restrictionsNodes.push(restriction.node);
        }
      })
    })
    this.setState({restrictionsNodes: restrictionsNodes})
  }

  handleChange = (checked, foodNode) => {
    let { isCustomPlan } = this.props;
    let arr = [...this.state.data];
    let restrictionsNodes = [...this.state.restrictionsNodes];

    arr.find(food => {
      if(food.node.id === foodNode.id){
        food.node.isOnProgram = checked
      }
    })

    let modifiedRestrictions;
    if(checked){
      modifiedRestrictions = restrictionsNodes.concat(foodNode);
    } else {
      modifiedRestrictions = restrictionsNodes.filter(node => node.id !== foodNode.id);
    }

    isCustomPlan ? this.props.updateNutritionPreferences(modifiedRestrictions) : this.props.updateRestrictions(modifiedRestrictions);
    this.setState({restrictionsNodes: modifiedRestrictions, data: arr})
  }

  filterBySubcategory = (subcategory) => {
    return this.state.data.filter((value) => value.node.subcategory === subcategory);
  };

  renderCardIcon = (card) => {
    if(this.state[card]){
      return <IoIosArrowUp className='icon'/> 
    } else {
      return <IoIosArrowDown className='icon'/>
    }
  }

  renderFoodNodesChecked = () => {
    let foodNodesChecked = [...this.state.restrictionsNodes];
    return foodNodesChecked.map(foodNodeChecked => {
      return (
        <Popconfirm
          title={`Remove ${foodNodeChecked.name} from restrictions?`}
          onConfirm={(e) => this.handleChange(e.target.checked, foodNodeChecked)}
          okText="Yes"
          cancelText="No"
        >
          <Checkbox 
            className="custom-checkbox" 
            key={foodNodeChecked.id} 
            value={foodNodeChecked}
          >
            Avoid {foodNodeChecked.name}
          </Checkbox>
        </Popconfirm>
      )
    })
  }

  render(){
    //If there is a property clientProfile it means data comes from admin side.
    return(
      <>
      {this.state.restrictionsNodes.length !== 0 &&
      <Checkbox.Group style={{ width: '100%' }} value={this.state.restrictionsNodes}>
        {this.renderFoodNodesChecked()}
      </Checkbox.Group>}
       <div className='card-container'>
          <div className={`expandable-card ${this.state.mostCommon ? 'open' : ''}`} onClick={() => this.setState({mostCommon: !this.state.mostCommon})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Most Common</p>
              {this.renderCardIcon('mostCommon')}
            </div>
          </div>
          <div className={`${this.state.mostCommon ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Most Common').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.mostCommon} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.fruitsAndVegetables ? 'open' : ''}`} onClick={() => this.setState({fruitsAndVegetables: !this.state.fruitsAndVegetables})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Fruits and Vegetables</p>
              {this.renderCardIcon('fruitsAndVegetables')}
            </div>
          </div>
          <div className={`${this.state.fruitsAndVegetables ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Fruits and Vegetables').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.fruitsAndVegetables} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.animalOrigin ? 'open' : ''}`} onClick={() => this.setState({animalOrigin: !this.state.animalOrigin})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Animal Origin</p>
              {this.renderCardIcon('animalOrigin')}
            </div>
          </div>
          <div className={`${this.state.animalOrigin ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Animal Origin').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.animalOrigin} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.nutsAndSeeds ? 'open' : ''}`} onClick={() => this.setState({nutsAndSeeds: !this.state.nutsAndSeeds})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Nuts and Seeds</p>
              {this.renderCardIcon('nutsAndSeeds')}
            </div>
          </div>
          <div className={`${this.state.nutsAndSeeds ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Nuts and Seeds').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.nutsAndSeeds} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.herbsAndSpices ? 'open' : ''}`} onClick={() => this.setState({herbsAndSpices: !this.state.herbsAndSpices})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Herbs and Spices</p>
              {this.renderCardIcon('herbsAndSpices')}
            </div>
          </div>
          <div className={`${this.state.herbsAndSpices ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Herbs and Spices').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.herbsAndSpices} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.cerealsAndGrains ? 'open' : ''}`} onClick={() => this.setState({cerealsAndGrains: !this.state.cerealsAndGrains})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>Cereals and Grains</p>
              {this.renderCardIcon('cerealsAndGrains')}
            </div>
          </div>
          <div className={`${this.state.cerealsAndGrains ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('Cereals and Grains').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.cerealsAndGrains} data={food}/>)}
          </div>
        </div>

        <div className='card-container'>
          <div className={`expandable-card ${this.state.general ? 'open' : ''}`} onClick={() => this.setState({general: !this.state.general})}>
            <div className='category flex flex-center-aligned space-between'>
              <p className='bold-text'>General</p>
              {this.renderCardIcon('general')}
            </div>
          </div>
          <div className={`${this.state.general ? 'open-card-content' : ''}`}>
            {this.filterBySubcategory('General').map(food => <NutritionPreferencesCard loading={this.props.loading} handleChange={this.handleChange} open={this.state.general} data={food}/>)}
          </div>
        </div>
      </>
    )
  }
}

export default NutritionPreferencesPage;