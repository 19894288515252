import React from 'react';
import Nutrition from './nutrition';

class LearnPage extends React.Component {
  state={
    confirmModalVisible: false,
    guides: null
  }

  componentDidMount(){
    this.setState({guides: this.props.guides})
  }

  handleModalVisible = (isVisible) => {
    this.setState({confirmModalVisible:  isVisible})
  }

  render(){
    return(
      this.state.guides && <Nutrition {...this.props} handleModalVisible={this.handleModalVisible} guides={this.state.guides} confirmModalVisible={this.state.confirmModalVisible} />
    ) 
  }
}

export default LearnPage;