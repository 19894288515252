import React from 'react';
import './index.css';

class StatSummaryCard extends React.Component {
  render(){
    const { difference, differenceSinceStart, profile, data } = this.props;
    return(
      <div className='stat-section mb-4'>
        <div className=' flex space-around stat-card'>
          <div>
            <p className='blog-card-category stats standard-color'>Weight</p>
            <div className='flex'>
              <p className='main-text'>{Number(data.weight).toFixed(1)}</p>
              <p className='label'>{profile.isMetric ? 'kg' : 'lb'}</p>
            </div>
          </div>
          {Object.values(difference).length >= 2 &&
          <div>
            <p className='blog-card-category stats'>Since Last</p>
            <div className='flex'>
              <p className='main-text color'>{Math.sign(difference.weightDiff) === 1 ? `+${difference.weightDiff}` : difference.weightDiff}</p>
              <p className='label color'>{profile.isMetric ? 'kg' : 'lb'}</p>
            </div>
          </div>}
          {Object.values(differenceSinceStart).length >= 2 &&
          <div>
            <p className='blog-card-category stats'>Since Start</p>
            <div className='flex'>
              <p className='main-text color'>{Math.sign(differenceSinceStart.weightDiff) === 1 ? `+${differenceSinceStart.weightDiff}` : differenceSinceStart.weightDiff}</p>
              <p className='label color'>{profile.isMetric ? 'kg' : 'lb'}</p>
            </div>
          </div>}
        </div>

        <div className='flex space-around stat-card'>
          <div>
            <p className='blog-card-category stats standard-color'>Body Fat</p>
            <div className='flex'>
              <p className='main-text'>{data.bfPercentage === 0 ? '-' : Number(data.bfPercentage).toFixed(1)}</p>
              <p className='label'>{data.bfPercentage !== 0  && '%'}</p>
            </div>
          </div>
          {Object.values(difference).length >= 2 &&
          <div>
            <p className='blog-card-category stats'>Since Last</p>
            <div className='flex'>
              <p className='main-text color'>
                {data.bfPercentage === 0 ? '-' : Math.sign(difference.bfDiff) === 1 ? `+${difference.bfDiff}` : difference.bfDiff}
              </p>
              <p className='label color'>{data.bfPercentage !== 0  && '%'}</p>
            </div>
          </div>}
          {Object.values(differenceSinceStart).length >= 2 &&
          <div>
            <p className='blog-card-category stats'>Since Start</p>
            <div className='flex'>
              <p className='main-text color'>
                {data.bfPercentage === 0 ? '-' : Math.sign(differenceSinceStart.bfDiff) === 1 ? `+${differenceSinceStart.bfDiff}` : differenceSinceStart.bfDiff}
              </p>
              <p className='label color'>{data.bfPercentage !== 0  && '%'}</p>
            </div>
          </div>}
        </div>

        <div className='flex space-around stat-card'>
          <div>
            <p className='blog-card-category stats standard-color'>Lean Mass</p>
            <div className='flex'>
              <p className='main-text'>{data.bfPercentage === 0 ? '-' : Number(data.leanBodyMass).toFixed(1)}</p>
              <p className='label'>{data.bfPercentage === 0 ? '' : profile.isMetric ? 'kg' : 'lb'}</p>
            </div>
          </div>
          {Object.values(difference).length >= 2 &&
         <div>
          <p className='blog-card-category stats'>Since Last</p>
          <div className='flex'>
            <p className='main-text color'>
              {data.bfPercentage === 0 ? '-' : Math.sign(difference.leanBodyMassDiff) === 1 ? `+${difference.leanBodyMassDiff}` : difference.leanBodyMassDiff}
            </p>
            <p className='label color'>{data.bfPercentage === 0 ? '' : profile.isMetric ? 'kg' : 'lb'}</p>
          </div>
         </div>}
         {Object.values(differenceSinceStart).length >= 2 &&
         <div>
          <p className='blog-card-category stats'>Since Start</p>
          <div className='flex'>
            <p className='main-text color'>
              {data.bfPercentage === 0 ? '-' : Math.sign(differenceSinceStart.leanBodyMassDiff) === 1 ? `+${differenceSinceStart.leanBodyMassDiff}` : differenceSinceStart.leanBodyMassDiff}
            </p>
            <p className='label color'>{data.bfPercentage === 0 ? '' : profile.isMetric ? 'kg' : 'lb'}</p>
          </div>
         </div>}
        </div>
      </div>
    )
  }
}

export default StatSummaryCard;