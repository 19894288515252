import React from 'react'
import { Modal } from 'antd';
import './index.css';

export const Legal = ({data, isLegalModalVisible, handleLegalModal}) => 
 data ?
  <Modal
    bodyStyle={{overflowX: 'scroll', height: '400px'}}
    centered
    visible={isLegalModalVisible}
    title={
      <div className='flex flex-center-aligned'>
        <p>{data.title}</p>
      </div>}
    onCancel={() => handleLegalModal(false, '')}
    footer={null}
    closable={true}
    maskClosable={true}
    height={'500px'}
    >
    <div className='disclaimer'>
      <p className='text'>
        {data.text}
      </p>
    </div>
  </Modal> : null