import {
  CREATE_USER_SETTING,
  CREATE_USER_SETTING_SUCCESS,
  CREATE_USER_SETTING_ERROR,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_ERROR,
} from "./constants";

export function createUserSetting(setting) {
  return {
    type: CREATE_USER_SETTING,
    setting
  };
}

export function createUserSettingSuccess(payload) {
  return {
    type: CREATE_USER_SETTING_SUCCESS,
    payload
  };
}

export function createUserSettingError(err) {
  return {
    type: CREATE_USER_SETTING_ERROR,
    err
  };
}

export function getUserSettings() {
  return {
    type: GET_USER_SETTINGS
  };
}

export function getUserSettingsSuccess(payload) {
  return {
    type: GET_USER_SETTINGS_SUCCESS,
    payload
  };
}

export function getUserSettingsError(err) {
  return {
    type: GET_USER_SETTINGS_ERROR,
    err
  };
}