import React from "react";
import { connect } from "react-redux";
import RecipesPage from "../../presentational/RecipesPage";
import { SplashPage } from '../../presentational/SplashPage';
import { Loading } from '../../components/Loading';
import { 
  getCurrentProfile, 
  getNutrition 
} from "../../ducks/Profile/actions";
import { 
  getRecipes, 
  clearAlert, 
  saveGrocery, 
  saveRecipeToFavorites, 
  getFavoriteRecipes,
  fetchFavoriteRecipesFromEdemam
} from "../../ducks/Recipe/actions";
import { HANDLE_RECIPE_SEARCH_TERM } from "../../ducks/Recipe/constants";
import { 
  SET_RECIPE_QUERY_OBJ, 
  HANDLE_PRICING_MODAL 
} from "../../ducks/Global/constants";

class Recipes extends React.Component {
 
  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getNutrition();
    this.props.getFavoriteRecipes();
    if(this.props.location.state && this.props.location.state.queryObj){
      this.props.getRecipes(this.props.location.state.queryObj);
      this.props.setRecipeQueryObj(this.props.location.state.queryObj, this.props.location.state.meal.mealTitle);
    }
  }

  componentDidUpdate(prevProps) {
    //if there are favoriteRecipes in the store, get the recipes from Edemam
    if(this.props.favoriteRecipes.length > 0 && 
      this.props.favoriteRecipes !== prevProps.favoriteRecipes &&
      this.props.favoriteRecipes.length !== this.props.favoriteRecipesFromEdemam.length
    ){
      this.props.fetchFavoriteRecipesFromEdemam(this.props.favoriteRecipes);
    }
  }

  render() {
    const { profile, pricingModalOpen, user } = this.props;
    return (
      <div className="recipe-page">
        {user && user.isPro && profile ?
        <RecipesPage {...this.props}/> : !profile ?
        <Loading /> :
        <SplashPage 
          page={'recipes'} 
          layoutBackground={true} 
          profile={profile} 
          pricingModalOpen={pricingModalOpen} 
          handlePricingModal={this.props.handlePricingModal} 
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    profile: state.profile.profile,
    nutrition: state.profile.nutrition,
    recipes: state.recipes.recipes,
    loading: state.recipes.loading,
    error: state.recipes.error,
    groceryList: state.recipes.groceryList,
    recipeSearchTerm: state.recipes.recipeSearchTerm,
    recipeQueryObj: state.global.recipeQueryObj,
    mealToMatchRecipe: state.global.mealToMatchRecipe,
    pricingModalOpen: state.global.pricingModalOpen,
    favoriteRecipes: state.recipes.favoriteRecipes,
    favoriteRecipesFromEdemam: state.recipes.favoriteRecipesFromEdemam
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getNutrition: () => dispatch(getNutrition()),
    getRecipes: (queryObj, fromMealPlanParams) => dispatch(getRecipes(queryObj, fromMealPlanParams)),
    clearAlert: () => dispatch(clearAlert()),
    saveGrocery: (dish, ingredients, image) => dispatch(saveGrocery(dish, ingredients, image)),
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen
      });
		},
    handleRecipeSearchTerm: (recipeSearchTerm) => {
      return dispatch({
        type: HANDLE_RECIPE_SEARCH_TERM,
        recipeSearchTerm
      });
		},
    setRecipeQueryObj: (recipeQueryObj, meal) => {
      return dispatch({
        type: SET_RECIPE_QUERY_OBJ,
        recipeQueryObj,
        meal
      });
		},
    saveRecipeToFavorites: (ids) => dispatch(saveRecipeToFavorites(ids)),
    getFavoriteRecipes: () => dispatch(getFavoriteRecipes()),
    fetchFavoriteRecipesFromEdemam: (ids) => dispatch(fetchFavoriteRecipesFromEdemam(ids))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);