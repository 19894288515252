import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_ERROR,
  FILTER_BLOGS,
  SUBSCRIBE_USER,
  SUBSCRIBE_USER_SUCCESS,
  GET_SINGLE_POST,
  GET_SINGLE_POST_SUCCESS
} from './constants';

export function getAllBlogs() {
  return {
    type: GET_ALL_BLOGS
  };
}

export function getAllBlogsSuccess(allBlogs) {
  return {
    type: GET_ALL_BLOGS_SUCCESS,
    allBlogs
  };
}

export function getSinglePost(slug) {
  return {
    type: GET_SINGLE_POST,
    slug
  };
}

export function getSinglePostSuccess(currentPost) {
  return {
    type: GET_SINGLE_POST_SUCCESS,
    currentPost
  };
}

export function getAllBlogsError(error) {
  return {
    type: GET_ALL_BLOGS_ERROR,
    error
  };
}

export function filterBlogs(category) {
  return {
    type: FILTER_BLOGS,
    category
  };
}

export function subscribeUser(formData) {
  return {
    type: SUBSCRIBE_USER,
    formData
  };
}

export function subscribeUserSuccess(response) {
  return {
    type: SUBSCRIBE_USER_SUCCESS,
    response
  };
}