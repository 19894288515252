import React from 'react';
import { BsHeart, BsHeartFill} from 'react-icons/bs';
import '../SuggesticMealPlanPage/index.css';

export const RecipeCard = ({recipe, openRecipeModal, handleAddOrRemoveFavoriteRecipe, favoriteRecipe, style}) => {
  return(
  <>
    <div className="daily-meals-wrapper">
      <div>
        <div style={style} key={recipe.label} className='meal-card' onClick={() => openRecipeModal(recipe)}>
          <div style={{width: '100%'}} className='flex flex-center-aligned space-between'>
            <div className='meal-card-content'>
              <img src={recipe.image} className='meal-card-img'/>
              <div>
                <div className='flex'>
                  {recipe.mealType.map(type => <p className='dashboard-content home-card-title' style={{margin: '0 5px 5px 0'}}>{type}</p>)}
                </div>
                <p style={{width: '100%', maxWidth: '80%'}} className='home-card-text'>{recipe.label}</p>
                <div className='label-group flex flex-center-aligned flex-wrap mb-1 mt-1'>
                  {recipe.dietLabels.map(label => <div className='labels'>{label}</div>)}
                </div>
                <p className='macro-label-bottom mb-1'>{recipe.yield} servings | {recipe.totalTime} min</p>
                <p className='macro-label-bottom'>{`${Math.round(recipe.calories/recipe.yield)} calories | ${Math.round(recipe.totalNutrients.PROCNT.quantity/recipe.yield)} Protein | ${Math.round(recipe.totalNutrients.CHOCDF.quantity/recipe.yield)} Carbs | ${Math.round(recipe.totalNutrients.FAT.quantity/recipe.yield)} Fat`}</p>
              </div>
            </div>
            {favoriteRecipe ? 
            <BsHeartFill onClick={(e) => {handleAddOrRemoveFavoriteRecipe(recipe.uri, 'remove'); e.stopPropagation()}} style={{fontSize: '20px', color: 'red'}} /> : 
            <BsHeart onClick={(e) => {handleAddOrRemoveFavoriteRecipe(recipe.uri, 'add'); e.stopPropagation()}} style={{fontSize: '20px', color: 'red'}} />
            }
            {/* <div className='flex flex-column flex-center-aligned'>
              <SyncOutlined spin={false}/>
              <p className='macro-label-bottom'>Swap</p>  
            </div> */}
           </div>
        </div>
      </div>
    </div>
  </>
  )
}