export const REGISTER_USER = "auth/REGISTER_USER";
export const REGISTER_USER_SUCCESS = "auth/REGISTER_USER_SUCCESS";
export const REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP = "auth/REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP";
export const REGISTER_USER_FAIL = "auth/REGISTER_USER_FAIL";
export const USER_LOADED = "auth/USER_LOADED";
export const USER_LOADED_SUCCESS = "auth/USER_LOADED_SUCCESS";
export const USER_LOADED_ERROR = "auth/USER_LOADED_ERROR";
export const LOGIN_USER = "auth/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "auth/LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "auth/LOGIN_USER_ERROR";
export const LOGOUT = "auth/LOGOUT";
export const SHOW_AUTH_MODAL = "auth/SHOW_AUTH_MODAL";
export const HIDE_AUTH_MODAL = "auth/HIDE_AUTH_MODAL";

export const HANDLE_TOKEN = "auth/HANDLE_TOKEN";
export const HANDLE_TOKEN_SUCCESS = "auth/HANDLE_TOKEN_SUCCESS";
export const HANDLE_TOKEN_ERROR = "auth/HANDLE_TOKEN_ERROR";

export const VERIFY_USER = "auth/VERIFY_USER";
export const VERIFY_USER_SUCCESS = "auth/VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERROR = "auth/VERIFY_USER_ERROR";
export const RESET_USER_VERIFIED = "auth/RESET_USER_VERIFIED";

export const ASSESSMENT_USER = "auth/ASSESSMENT_USER";
export const ASSESSMENT_USER_SUCCESS = "auth/ASSESSMENT_USER_SUCCESS";
export const ASSESSMENT_USER_SUCCESS_WITH_GOOGLE_SIGNUP = "auth/ASSESSMENT_USER_SUCCESS_WITH_GOOGLE_SIGNUP";
export const ASSESSMENT_USER_FAIL = "auth/ASSESSMENT_USER_FAIL";

export const GET_ASSESSMENT_PROGRESS_USER = "auth/GET_ASSESSMENT_PROGRESS_USER";
export const GET_ASSESSMENT_PROGRESS_USER_SUCCESS = "profile/GET_ASSESSMENT_PROGRESS_USER_SUCCESS";
export const GET_ASSESSMENT_PROGRESS_USER_ERROR = "profile/GET_ASSESSMENT_PROGRESS_USER_ERROR";