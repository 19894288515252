import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_ALL_BLOGS, SUBSCRIBE_USER, GET_SINGLE_POST } from './constants';
import { getAllBlogsSuccess, getAllBlogsError, subscribeUserSuccess, getSinglePostSuccess } from './actions';
import { fetchData } from '../api';

const GHOST_API_BASE = 'https://alvarodelatorre.ghost.io';

export function* getAllBlogs() {
  const url = `${GHOST_API_BASE}/ghost/api/v3/content/posts/?key=${process.env.REACT_APP_GHOST_API}&include=tags,authors`;
  try {
    const data = yield call(fetchData, url);
    yield put(getAllBlogsSuccess(data));
   
  } catch (err) {
    yield put(getAllBlogsError(err));
  }
}

export function* getSinglePost({slug}) {
  const url = `${GHOST_API_BASE}/ghost/api/v3/content/posts/slug/${slug}/?key=${process.env.REACT_APP_GHOST_API}&include=tags,authors`;
  try {
    const data = yield call(fetchData, url);
    yield put(getSinglePostSuccess(data));
  } catch (err) {
    console.log(err);
  }
}



export function* subscribeUser({formData}) {
  try {
    const response = yield fetch(`https://hooks.zapier.com/hooks/catch/${process.env.REACT_APP_ZAPIER_CONTACT}/osy6t0g/silent/`, {
      method: 'POST',
      body: JSON.stringify(formData)
    })
    yield put(subscribeUserSuccess(response));
  } catch (err) {
    console.log(err)
  }
}

export default function* blogSaga() {
  yield takeLatest(GET_ALL_BLOGS, getAllBlogs);
  yield takeLatest(SUBSCRIBE_USER, subscribeUser);
  yield takeLatest(GET_SINGLE_POST, getSinglePost);
}