import React from 'react';
import { Link } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { GiChefToque } from "react-icons/gi";
import { IoMdTime, IoIosArrowRoundForward } from "react-icons/io";
import { Modal, Divider, Badge, Button } from 'antd';
import { capitalize } from '../../helpers/utils';
import moment from 'moment';
import { Loading } from '../../components/Loading';
import { MdAddShoppingCart, MdHistory, MdCheckCircle, MdRadioButtonUnchecked, MdAddCircleOutline } from "react-icons/md";
import { GoPlusSmall } from "react-icons/go";
import Toast from '../../components/Toast/Toast';
import './index.css';

class MealPlan extends React.Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state={
      historyMealPlanActive: false,
      currentMeal: null,
      open: true,
      showEquivalent: null,
      addToShoppingListActive: false,
      recipesIds: []
    }
  }

  handleScroll = () => {
    return this.myRef.current && this.myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  componentDidMount () {
    if(document.querySelector('.pro-label')){
      document.querySelector('.pro-label').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
    
  renderMacrosInfo = (nutrients) => {
    return(
      <>
        <div>
          <Badge
            color={'#21d0b2'}
            text={`${nutrients.calories} Calories`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${nutrients.protein}g Protein`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${nutrients.carbs}g Carb`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${nutrients.fat}g Fat`}
          />
        </div>
      </>
    )
  }

  getRecipesIds = (recipe) => {
    let recipesIds = [...this.state.recipesIds];
    if(recipesIds.includes(recipe.recipe.databaseId)){
      const index = recipesIds.indexOf(recipe.recipe.databaseId);
      if (index > -1) {
        recipesIds.splice(index, 1);
      }
    } else {
      recipesIds.push(recipe.recipe.databaseId);
    }
    this.setState({recipesIds: recipesIds});
  }

  renderMealsForActivePlan = () => {
    const reversedMealPlan = JSON.parse(JSON.stringify(this.props.currentMealPlan.active)).reverse();
    return !this.state.historyMealPlanActive && reversedMealPlan.map(meal => {
      const isDayActive = moment.utc(meal.date).format('l') === moment.utc().format('l');
      return (
        <>
          <div className="day-container">
            <div ref={this.myRef} className='flex flex-center-aligned'>
              <p className="day-text">
                Day {meal.day} 
              </p>
              {isDayActive && (
                <span className="pro-label">
                  TODAY
                </span>
              )}
            </div>
            <div className="daily-meals-wrapper">
              {meal.meals.map(recipe =>
                <div>
                  <div key={recipe.recipe.id} className='meal-card' onClick={() => this.setState({currentMeal: recipe, open: true})}>
                    <div style={{width: '100%'}} className='flex flex-center-aligned space-between'>
                      <div className='meal-card-content'>
                        <img src={recipe.recipe.mainImage} className='meal-card-img'/>
                        <div>
                          <p className='meal-type-text'>{recipe.meal}</p>
                          <p className='home-card-text'>{recipe.recipe.name}</p>
                          <p className='main-page-subtitle'>{recipe.recipe.numberOfServings} servings | {recipe.recipe.totalTime}</p>
                          <p className='macro-label-bottom'>{recipe.recipe.nutrientsPerServing.calories * recipe.recipe.numberOfServings} calories | {recipe.recipe.nutrientsPerServing.protein * recipe.recipe.numberOfServings} Protein | {recipe.recipe.nutrientsPerServing.carbs * recipe.recipe.numberOfServings} Carbs | {recipe.recipe.nutrientsPerServing.fat * recipe.recipe.numberOfServings} Fat</p>
                        </div>
                      </div>
                      {this.state.addToShoppingListActive ? 
                      <div onClick={(e) => {e.stopPropagation(); this.getRecipesIds(recipe)}}>
                        {this.state.recipesIds.includes(recipe.recipe.databaseId) ? 
                          <MdCheckCircle style={{fontSize: '26px', color: '#2bffcc'}}/> :
                          <MdRadioButtonUnchecked style={{fontSize: '26px', color: '#2bffcc'}}/>
                        }
                      </div> :
                      <div onClick={(e) => {e.stopPropagation(); this.props.getEquivalentRecipes(recipe.recipe.id, 'active'); this.setState({showEquivalent: recipe.id})}} className='flex flex-column flex-center-aligned'>
                        <SyncOutlined 
                          spin={
                            this.props.equivalentRecipesLoading && recipe.recipe.id === this.props.equivalentRecipesLoading.id 
                            ? this.props.equivalentRecipesLoading.loading 
                            : false
                          }
                        />
                        <p className='macro-label-bottom'>Swap</p>  
                      </div>}
                    </div>
                    {this.renderAlternativeRecipes(recipe.id, recipe, 'active')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )
    })
  }

  renderMealsForHistoryPlan = () => {
    return this.state.historyMealPlanActive && this.props.currentMealPlan.history.map(meal => {
      return (
        <div className="day-container">
          <h3 className="day-text">
            Day {meal.day}
          </h3>
          <div className="daily-meals-wrapper">
            {meal.meals.map(recipe =>
              <div>
                <div key={recipe.recipe.id} className='meal-card' onClick={() => this.setState({currentMeal: recipe, open: true})}>
                  <div style={{width: '100%'}} className='flex flex-center-aligned space-between'>
                    <div className='meal-card-content'>
                      <img src={recipe.recipe.mainImage} className='meal-card-img'/>
                      <div>
                        <p className='meal-type-text'>{recipe.meal}</p>
                        <p className='home-card-text'>{recipe.recipe.name}</p>
                        <p className='main-page-subtitle'>{recipe.recipe.numberOfServings} servings | {recipe.recipe.totalTime}</p>
                        <p className='main-page-subtitle'>{recipe.recipe.nutrientsPerServing.calories * recipe.recipe.numberOfServings} calories | {recipe.recipe.nutrientsPerServing.protein * recipe.recipe.numberOfServings} Protein | {recipe.recipe.nutrientsPerServing.carbs * recipe.recipe.numberOfServings} Carbs | {recipe.recipe.nutrientsPerServing.fat * recipe.recipe.numberOfServings} Fat</p>
                      </div>
                    </div>
                    <div onClick={(e) => {e.stopPropagation(); this.props.getEquivalentRecipes(recipe.recipe.id, 'history'); this.setState({showEquivalent: recipe.id})}} className='flex flex-column flex-center-aligned'>
                      <SyncOutlined 
                        spin={
                          this.props.equivalentRecipesLoading && recipe.recipe.id === this.props.equivalentRecipesLoading.id 
                          ? this.props.equivalentRecipesLoading.loading 
                          : false
                        }
                      />
                      <p className='macro-label-bottom'>Swap</p>  
                    </div>
                  </div>
                  {this.renderAlternativeRecipes(recipe.id, recipe, 'history')}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  renderAlternativeRecipes = (mealId, recipe, status) => {
    if(recipe.hasOwnProperty('alternativeRecipes') && this.state.showEquivalent === recipe.id){
      if(recipe.alternativeRecipes.length > 0){
        return (
          <>
            <Divider/>
            <div>
              {recipe.alternativeRecipes.map(recipe => 
              <div onClick={(e) => e.stopPropagation()} className='meal-card-content' style={{marginBottom: '10px'}}>
                <img src={recipe.mainImage} className='meal-card-img'/>
                <div className='flex flex-center-aligned space-between'>
                  <div>
                    <p className='home-card-text'>{recipe.name}</p>
                    <p className='main-page-subtitle'>{recipe.numberOfServings} servings | {recipe.totalTime}</p>
                  </div>
                  <p 
                    onClick={() => this.props.swapMealPlanRecipe(mealId, recipe.id, status)} 
                    className='solid-link' 
                    style={{paddingLeft: '10px'}}
                  >
                    {this.props.swapMealPlanLoading.id === recipe.id ? <Loading /> : 'Select'}
                  </p>
                </div>
              </div>)}
            </div>
          </>
        )
      } else {
        return (
          <>
            <Divider/>
            <div>
              <div></div>
              <p className='home-card-text alternative-recipe'>No recipes found</p>
            </div>
          </>
        )
      }
    }  
  }

  recipeModal = () => {
    let { currentMeal, open } = this.state;
    return(
      currentMeal && 
      <Modal
        className='recipe-modal'
        visible={open}
        closable
        maskClosable
        onCancel={() => this.setState({open: false})}
        footer={null}
        centered
        width={'500px'}
      >
        <div className='meal-modal'>
          <div className='recipe-img' style={{backgroundImage: `url(${currentMeal.recipe.mainImage})`}}>
          </div>
          <div className='recipe-content'>
            <div className='grid'>
              <p className='title'>{currentMeal.recipe.name}</p>
              {currentMeal.recipe.source &&
              <p style={{color: '#FF0066'}}>{`by ${currentMeal.recipe.source.displayName || currentMeal.recipe.source.siteUrl}`}</p>}
            </div>
            <div style={{margin: '20px 0 20px 0'}} className='flex flex-center-aligned'>
              <div className='details'>
                <GiChefToque style={{fontSize: '40px', color: '#2bffcc', marginBottom: '5px'}}/>
                <p className='home-card-text'>{`Serves ${currentMeal.recipe.numberOfServings}`}</p>
              </div>
              <div className='details'>
                <IoMdTime style={{fontSize: '40px', color: '#2bffcc', marginBottom: '5px'}}/>
                <p className='home-card-text'>{currentMeal.recipe.totalTime}</p>
              </div>
            </div>

            <div className='recipe-nutrients'>
              <p className='meal-card-title'>{`NUTRIENTS PER SERVING ${currentMeal.recipe.servingWeight ? `(${currentMeal.recipe.servingWeight.toFixed(2)}g)` : ''}`}</p>
              {this.renderMacrosInfo(currentMeal.recipe.nutrientsPerServing)}
            </div>

            <div className={`recipe-ingredients ${currentMeal.recipe.instructions.length > 0 ? 'margin-bottom' : ''}`}>
              <p className='meal-card-title'>{`INGREDIENTS - ${currentMeal.recipe.ingredientLines.length}`}</p>
              {currentMeal.recipe.ingredientLines.map(ingredient => 
              <>
                <div className='ingredients grid'>
                  <Badge color={'#2bffcc'}/>
                  <p className='meal-card-text'>{capitalize(ingredient)}</p>
                </div>
                <Divider />
              </>)}
            </div>
            
            {currentMeal.recipe.instructions.length > 0 ?
            <div className='recipe-instructions'>
              <p className='meal-card-title meal-type-text'>INSTRUCTIONS</p>
              {currentMeal.recipe.instructions.map(instruction => 
              <>
                <p style={{fontSize: '14px', lineHeight: '16px', marginBottom: '10px', color: '#2bffcc'}}>{capitalize(instruction)}</p>
                <Divider />
              </>)}
            </div> :
             currentMeal.recipe.source &&
            <>
              <p className='meal-card-title meal-type-text'>INSTRUCTIONS</p>
              <Link to={{ pathname: currentMeal.recipe.source.recipeUrl }} target="_blank">
                <Button className='recipe-modal-btn'>
                  <div className='flex flex-center-aligned justify-center'>
                    {`View instructions on ${currentMeal.recipe.source.displayName}`}
                    <IoIosArrowRoundForward style={{fontSize: '30px', marginLeft: '5px'}}/>
                  </div>
                </Button>
              </Link>
            </>}
          </div>
        </div>
      </Modal>
    )
  }

  addAllRecipesToShopping = () => {
    let recipeIds = [];
    for(let meal of this.props.currentMealPlan.active){
      for(let recipe of meal.meals){
        recipeIds.push(recipe.recipe.databaseId)
      }
    }
    this.props.addToShoppingList(recipeIds, true);
  }

  render(){
    return(
      <div className='meal-plan'>
        {this.recipeModal()}
        {this.props.addToShoppingListError && <Toast type={'warning'} message={this.props.addToShoppingListError && this.props.addToShoppingListError.msg} />}
        {!this.state.historyMealPlanActive  &&
        <Button className='previous-week-badge'>
          <div onClick={() => this.setState({addToShoppingListActive: !this.state.addToShoppingListActive})} className='flex flex-center-aligned justify-center'>
            <MdAddShoppingCart style={{fontSize: '22px', marginRight: '5px'}} />
            <p>Add to shopping list</p>
          </div> 
        </Button>}
       
        {this.state.addToShoppingListActive && !this.state.historyMealPlanActive &&
        <div className='flex flex-column flex-center-aligned'>
          <Button
            className='add-to-list'
            disabled={this.props.addToShoppingListLoading.allRecipes === true && this.props.addToShoppingListLoading.loading} 
            onClick={() => this.addAllRecipesToShopping()}
          >
            <div className='flex flex-center-aligned justify-center'>
              {this.props.addToShoppingListLoading.allRecipes === true && this.props.addToShoppingListLoading.loading ? <Loading style={{fontSize: '20px', marginRight: '10px'}}/> : <GoPlusSmall style={{fontSize: '20px', marginRight: '5px'}} />}
              <p>Add all recipes</p>
            </div>
          </Button>
          <p className='home-card-text' style={{padding: '10px 0 10px 0'}}>or</p>
          <Button
            className='add-to-list'
            disabled={this.props.addToShoppingListLoading.allRecipes === false && this.props.addToShoppingListLoading.loading} 
            onClick={() => this.props.addToShoppingList(this.state.recipesIds, false)} 
          >
            <div className='flex flex-center-aligned justify-center'>
              {this.props.addToShoppingListLoading.allRecipes === false && this.props.addToShoppingListLoading.loading ? <Loading style={{marginRight: '10px', fontSize: '20px'}}/> : <GoPlusSmall style={{fontSize: '20px', marginRight: '5px'}} />}
              <p>Add selected recipes</p>
            </div>
          </Button>
        </div>
        }
        {this.renderMealsForActivePlan()}
        {this.renderMealsForHistoryPlan()}
        <Button 
          style={{marginBottom: '10px'}} 
          onClick={() => this.setState({ historyMealPlanActive: !this.state.historyMealPlanActive })} 
          className="previous-week-badge"
          onClick={() => this.props.createWeeklyMealPlan({calories: this.props.nutrition.goalCalories, format: ["BREAKFAST", "SNACK", "LUNCH", "SNACK", "DINNER"]})}
        >
          <div className='flex flex-center-aligned justify-center'>
            {this.props.suggesticMealPlanLoading ? <Loading style={{fontSize: '22px', marginRight: '5px'}}/> : <MdAddCircleOutline style={{fontSize: '22px', marginRight: '5px'}} />}
            <p>Create a new plan</p>
          </div>
        </Button>
        <Button onClick={() => this.setState({ historyMealPlanActive: !this.state.historyMealPlanActive })} className="previous-week-badge">
          {!this.state.historyMealPlanActive ? 
          <div className='flex flex-center-aligned justify-center'>
            <MdHistory style={{fontSize: '22px', marginRight: '5px'}} />
            <p>View previous week</p>
          </div> : 
          <p>Back to this week</p>}
        </Button>
      </div>
    )
  }
}

export default MealPlan;