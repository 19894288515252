import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import './index.css';
import moment from 'moment';
import { DatePicker, Button, Modal, AutoComplete } from 'antd';
import { getAllOnsiteClients } from '../../ducks/Profile/actions';

const { Option } = AutoComplete;
const { RangePicker } = DatePicker;

class AssignModal extends React.Component {
  constructor(){
    super();
    this.state = {
      dateStart: '',
      dateEnd: '',
      selectedClient: null,
      clients: null,
      filteredClients: [],
      notFound: [{key:'not found', name: "No client found"}],
      clientIdSelected: null
    }
  }

  componentDidMount(){
    !this.props.clients && this.props.getAllOnsiteClients();
    this.setState({
      clients: this.props.clients,
      filteredClients: this.props.clients || []
    })
  }

  handleSearch = (e) => {
    const { clients } = this.props;
    let filtered;
    e === "" 
    ? filtered = clients 
    : filtered = clients.filter(client => client.user && client.user.name.toLowerCase().includes(e.toLowerCase()) || client.user && client.user.lastName && client.user.lastName.toLowerCase().includes(e.toLowerCase()));
    this.setState({filteredClients: filtered})
  }

  renderNotFound = (item) => {
    return (
      <Option key={item.key}>
        {item.name}
      </Option>
    );
  }

  renderOption = (client) => {
    return (
      <Option key={client.user && client.user._id} name={`${client.user && client.user.name} ${client.user && client.user.lastName  ? client.user.lastName : ''}`}>
        {client.user && client.user.name} {client.user && client.user.lastName ? client.user.lastName : ''}
      </Option>
    );
  }

  onSelect = (value) => {
    this.setState({
      clientIdSelected: value
    })
  }

  renderClients = () => {
    const { clients } = this.props;
    return (
      clients &&
      <>
        <p className='label'>Assign to</p>
        <AutoComplete 
          dataSource={this.state.filteredClients && this.state.filteredClients.length >= 1 ? this.state.filteredClients.map(this.renderOption) : this.state.notFound.map(this.renderNotFound)}
          style={{ width: '100%' }} 
          onSearch={this.handleSearch} 
          onSelect={(value) => this.onSelect(value)}
          onChange={(value) => this.setState({clientIdSelected: value})}
          optionLabelProp="name"
        />
      </>
    )
  }

  render(){
    return (
      <Modal
        title='Select Date Range and Client'
        visible={this.props.visible}
        onCancel={() => this.props.toggleModal(false)}
        footer={null}
        centered={true}
      >
        <div className='form'>
          <p className='label'>Date range</p>
          <RangePicker style={{paddingBottom: '20px', width: '100%'}} format={'MMM DD, YYYY'} onChange={(date) => this.setState({ dateStart: date[0], dateEnd: date[1] })} />
          {this.renderClients()}
        </div>
        <div className="week-buttons text-right">
          <Button type="secondary" className="save-btn-modal" onClick={() => this.props.toggleModal(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="save-btn-modal"
            disabled={!this.state.dateStart || !this.state.clientIdSelected}
            // onClick={() => this.props.history.push(`/dashboard/admin/clients?mealplan=${this.props.id}&startDate=${moment(this.state.dateStart).format('YYYY-MM-DD')}&endDate=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`)}
            onClick={() => this.props.history.push(`/dashboard/admin/clients/${this.state.clientIdSelected}/meal-plan?mealplan=${this.props.id}&startDate=${moment(this.state.dateStart).format('YYYY-MM-DD')}&endDate=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`)}
          >
            Assign
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return{
    clients: state.profile.onsiteClients
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllOnsiteClients: () => dispatch(getAllOnsiteClients())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignModal));
