import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Button } from 'antd';
import { MdCheckCircle } from "react-icons/md";
import { GET_FIT_BIT_ACCESS_TOKEN } from '../../ducks/Integrations/constants';
import './index.css';

 export const IntegrationsCard = (props) => {
  const [ visible ] = useState(true);
  
  const dispatch = useDispatch();

  const renderAction = (name) => {
    if(name === 'Withings'){
      return props.integration.action();
    } else if(name === 'Fitbit'){
      return dispatch({type: GET_FIT_BIT_ACCESS_TOKEN});
    }
  }

  return (
    <>
      <div className='integration-card'>
        <div className='integration-content flex flex-center-aligned'>
          <div className='img-container'>
            <img className='img' style={props.integration.image.styles} src={props.integration.image.img}/>
          </div>
          <div className='integration-content'>
            <div className='title-container flex flex-center-aligned'>
              <p className='title'>{props.integration.name}</p>
              {props.integration.active && <div className='pro-label'>Active</div>}
            </div>
            <p className='description'>{props.integration.description}</p>
            {visible && 
            <div style={{marginTop: '30px'}}>
              {props.integration.list.map(item => 
                <div className='integration-list'>
                  <MdCheckCircle style={{color: '#34f5c5'}}/>
                  <p className='description'>{item}</p>
                </div>
              )}
              
              <Button
                type='primary' 
                className={props.integration.active ? 'button disabled-blue' : 'button'}
                onClick={() => renderAction(props.integration.name)}
                disabled={props.integration.active}
              >
                {props.integration.active ? 'Connected' : 'Connect'}
              </Button>
            </div>}
          </div>
        </div> 
      </div>
    </>
  )
}