export const GET_PROFILE = "profile/GET_PROFILE";
export const GET_PROFILE_SUCCESS = "profile/GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "profile/GET_PROFILE_ERROR";
export const CLEAR_PROFILE = "profile/CLEAR_PROFILE";
export const SAVE_PROFILE = "profile/SAVE_PROFILE";
export const SAVE_PROFILE_IGNORE_NUTRITION = "profile/SAVE_PROFILE_IGNORE_NUTRITION";
export const SAVE_PROFILE_SUCCESS = "profile/SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_ERROR = "profile/SAVE_PROFILE_ERROR";

export const SET_NUTRITION_FROM_PROFILE_UPDATE = "profile/SET_NUTRITION_FROM_PROFILE_UPDATE";

export const GET_NUTRITION = "profile/GET_NUTRITION";
export const GET_NUTRITION_SUCCESS = "profile/GET_NUTRITION_SUCCESS";
export const GET_NUTRITION_ERROR = "profile/GET_NUTRITION_ERROR";
export const GET_ALL_NUTRITION = "profile/GET_ALL_NUTRITION";
export const GET_ALL_NUTRITION_SUCCESS = "profile/GET_ALL_NUTRITION_SUCCESS";
export const GET_ALL_NUTRITION_ERROR = "profile/GET_ALL_NUTRITION_ERROR";

export const SAVE_NUTRITION = "profile/SAVE_NUTRITION";
export const SAVE_NUTRITION_LOCAL = "profile/SAVE_NUTRITION_LOCAL";
export const SAVE_NUTRITION_SUCCESS = "profile/SAVE_NUTRITION_SUCCESS";
export const SAVE_NUTRITION_ERROR = "profile/SAVE_NUTRITION_ERROR";

export const CALCULATE_RESULTS = "profile/CALCULATE_RESULTS";

export const ADD_WEIGHT_TO_PROFILE = "profile/ADD_WEIGHT_TO_PROFILE";
export const ADD_WEIGHT_TO_PROFILE_SUCCESS = "profile/ADD_WEIGHT_TO_PROFILE_SUCCESS";
export const ADD_WEIGHT_TO_PROFILE_ERROR = "profile/ADD_WEIGHT_TO_PROFILE_ERROR";

export const GET_ALL_ONSITE_CLIENTS = "profile/GET_ALL_ONSITE_CLIENTS";
export const GET_ALL_ONSITE_CLIENTS_SUCCESS = "profile/GET_ALL_ONSITE_CLIENTS_SUCCESS";
export const GET_ALL_ONSITE_CLIENTS_ERROR = "profile/GET_ALL_ONSITE_CLIENTS_ERROR";

export const GET_FITBIT_NUTRITION = 'profile/GET_FITBIT_NUTRITION';
export const GET_FITBIT_NUTRITION_SUCCESS = 'profile/GET_FITBIT_NUTRITION_SUCCESS';

export const GET_USER_FITBIT_LOGS = 'profile/GET_USER_FITBIT_LOGS';
export const GET_USER_FITBIT_LOGS_SUCCESS = 'profile/GET_USER_FITBIT_LOGS_SUCCESS';
export const GET_USER_FITBIT_LOGS_ERROR = 'profile/GET_USER_FITBIT_LOGS_ERROR';

export const CREATE_USER_MEAL_PLAN = "profile/CREATE_USER_MEALPLAN";
export const CREATE_USER_MEAL_PLAN_SUCCESS = "profile/CREATE_USER_MEALPLAN_SUCCESS";
export const CREATE_USER_MEAL_PLAN_ERROR = "profile/CREATE_USER_MEALPLAN_ERROR";

export const CREATE_WEEKLY_MEAL_PLAN = "profile/CREATE_WEEKLY_MEAL_PLAN";
export const CREATE_WEEKLY_MEAL_PLAN_SUCCESS = "profile/CREATE_WEEKLY_MEAL_PLAN_SUCCESS";
export const CREATE_WEEKLY_MEAL_PLAN_ERROR = "profile/CREATE_WEEKLY_MEAL_PLAN_ERROR";

export const GET_EQUIVALENT_RECIPES = "profile/GET_EQUIVALENT_RECIPES";
export const GET_EQUIVALENT_RECIPES_SUCCESS = "profile/GET_EQUIVALENT_RECIPES_SUCCESS";
export const GET_EQUIVALENT_RECIPES_ERROR = "profile/GET_EQUIVALENT_RECIPES_ERROR";

export const SWAP_MEAL_PLAN_RECIPE = "profile/SWAP_MEAL_PLAN_RECIPE";
export const SWAP_MEAL_PLAN_RECIPE_SUCCESS = "profile/SWAP_MEAL_PLAN_RECIPE_SUCCESS";
export const SWAP_MEAL_PLAN_RECIPE_ERROR = "profile/SWAP_MEAL_PLAN_RECIPE_ERROR";

export const UPDATE_FOOD_IN_MEAL_PLAN = "profile/UPDATE_FOOD_IN_MEAL_PLAN";
export const UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS = "profile/UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS";
export const UPDATE_FOOD_IN_MEAL_PLAN_ERROR = "profile/UPDATE_FOOD_IN_MEAL_PLAN_ERROR";
export const UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS = "profile/UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS";


export const GET_ALTERNATIVE_FOODS = "profile/GET_ALTERNATIVE_FOODS";
export const GET_ALTERNATIVE_FOODS_SUCCESS = "profile/GET_ALTERNATIVE_FOODS_SUCCESS";
export const GET_ALTERNATIVE_FOODS_ERROR = "profile/GET_ALTERNATIVE_FOODS_ERROR";

export const UPDATE_USER_PROFILE = "setting/UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "setting/UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "setting/UPDATE_USER_PROFILE_ERROR";

export const UPDATE_ASSESSMENT_PROFILE = "profile/UPDATE_ASSESSMENT_PROFILE";

export const CREATE_NEW_USER = "profile/CREATE_NEW_USER";
export const CREATE_NEW_USER_SUCCESS = "profile/CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_ERROR = "profile/CREATE_NEW_USER_ERROR";