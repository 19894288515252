import React from 'react';
import { connect } from 'react-redux';
import { getMealplanById } from '../../ducks/Admin/actions';
import MealPlan from '../../presentational/AdminMealPlanPage/index';

class AdminMealPlanTemplate extends React.Component {

  componentDidMount() {
    if(this.props.item){
      this.props.getMealplanById(this.props.item._id);
    } else {
      this.props.getMealplanById(this.props.location.pathname.split('/meal-plan/')[1]);
    }
  }

  render(){
    return(
      <div className='meal-plan-library mt-1'>
        {this.props.mealplan && !this.props.mealplanLoading && (
          <div className="w-100">
            <MealPlan template={true} mealPlan={this.props.mealplan.plan} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    mealplan: state.admin.mealplan,
    mealplanLoading: state.admin.mealplanLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMealplanById: (id) => dispatch(getMealplanById(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMealPlanTemplate);