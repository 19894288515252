import {
  GET_WORKOUT_PROGRAM_LIBRARY,
  GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS,
  GET_WORKOUT_PROGRAM_LIBRARY_ERROR,
  CREATE_WORKOUT_PROGRAM,
  CREATE_WORKOUT_PROGRAM_SUCCESS,
  CREATE_WORKOUT_PROGRAM_ERROR,
  UPDATE_WORKOUT_PROGRAM,
  UPDATE_WORKOUT_PROGRAM_SUCCESS,
  UPDATE_WORKOUT_PROGRAM_ERROR,
  GET_USER_WORKOUT_PROGRAMS,
  GET_USER_WORKOUT_PROGRAMS_SUCCESS,
  GET_USER_WORKOUT_PROGRAMS_ERROR,
  CREATE_USER_WORKOUT_PROGRAM,
  CREATE_USER_WORKOUT_PROGRAM_SUCCESS,
  CREATE_USER_WORKOUT_PROGRAM_ERROR
} from "./constants";

const initialState = {
  workoutPrograms: [],
  userWorkoutPrograms: [],
  workoutProgramsLoading: false,
  workoutProgramsError: null,
  createWorkoutProgramSuccess: false
};

function workoutProgram(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_WORKOUT_PROGRAM_LIBRARY:
      return {
        ...state,
        workoutProgramsError: false,
        workoutProgramsLoading: true
      };
    case GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS:
      return {
        ...state,
        workoutPrograms: payload,
        workoutProgramsLoading: false
      };
    case GET_WORKOUT_PROGRAM_LIBRARY_ERROR:
      return {
        ...state,
        workoutProgramsLoading: false,
        workoutProgramsError: action.err
      };
    case CREATE_WORKOUT_PROGRAM:
    case CREATE_USER_WORKOUT_PROGRAM:
      return {
        ...state,
        workoutProgramsLoading: true,
        workoutProgramsError: null,
        createWorkoutProgramSuccess: false
      };
    case CREATE_USER_WORKOUT_PROGRAM_SUCCESS:
      return {
        ...state,
        userWorkoutPrograms: [...state.userWorkoutPrograms, payload],
        workoutProgramsError: null,
        createWorkoutProgramSuccess: true,
        workoutProgramsLoading: false
      };
    case CREATE_WORKOUT_PROGRAM_SUCCESS:
      return {
        ...state,
        workoutPrograms: [...state.workoutPrograms, payload],
        workoutProgramsError: null,
        createWorkoutProgramSuccess: true,
        workoutProgramsLoading: false
      };
    case CREATE_WORKOUT_PROGRAM_ERROR:
    case CREATE_USER_WORKOUT_PROGRAM_ERROR:
      return {
        ...state,
        workoutProgramsLoading: false,
        workoutProgramsError: action.err,
        createWorkoutProgramSuccess: false
      };
    case UPDATE_WORKOUT_PROGRAM:
      return {
        ...state,
        workoutProgramsLoading: true,
        workoutProgramsError: null,
        createWorkoutProgramSuccess: false
      };
    case UPDATE_WORKOUT_PROGRAM_SUCCESS:
      return {
        ...state, 
        workoutPrograms: state.workoutPrograms.map(workoutProgram => (workoutProgram._id === payload._id) ? payload : workoutProgram),
        workoutProgramsError: null,
        createWorkoutProgramSuccess: true,
        workoutProgramsLoading: false
      };
    case UPDATE_WORKOUT_PROGRAM_ERROR:
      return {
        ...state,
        workoutProgramsLoading: false,
        workoutProgramsError: action.err,
        createWorkoutProgramSuccess: false
      }
    case GET_USER_WORKOUT_PROGRAMS:
      return {
        ...state,
        userWorkoutPrograms: [],
        workoutProgramsError: false,
        workoutProgramsLoading: true
      };
    case GET_USER_WORKOUT_PROGRAMS_SUCCESS:
      return {
        ...state,
        userWorkoutPrograms: payload,
        workoutProgramsLoading: false
      };
    case GET_USER_WORKOUT_PROGRAMS_ERROR:
      return {
        ...state,
        workoutProgramsLoading: false,
        workoutProgramsError: action.err
      };
    default:
      return state;
  }
}

export default workoutProgram;