import React from 'react';
import NutritionPreferencesPage from '../../presentational/NutritionPreferencesPage';
import { Loading } from '../../components/Loading';

class AdminClientPreferences extends React.Component {
  render(){
    return(
      <div className='site-layout-no-background admin'>
        {this.props.clientProfile ?
        <NutritionPreferencesPage 
          {...this.props}
          isCustomPlan={true}
          foodRestrictions={this.props.foodRestrictions}
          profile={this.props.clientProfile}
        /> :
        <Loading />}
      </div>
    )
  }
}

export default AdminClientPreferences;