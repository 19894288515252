import React from 'react'
import './index.css'
import { Row, Col, Input, Select, Button, Modal } from 'antd'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Option } = Select;

class CustomFoodModal extends React.Component {
  constructor(){
    super();
    this.state = {
      title: '',
      brand: '',
      servingSize: '',
      servingType: '',
      calories: '',
      fats: '',
      saturatedFat: '',
      transFat: '',
      cholesterol: '',
      sodium: '',
      potassium: '',
      carbs: '',
      dietaryFiber: '',
      sugars: '',
      proteins: '',
      servingTypes: [
        'Gram',
        'Kilogram',
        'Cup',
        'Ounce',
        'Package',
        'Bar'
      ]
    }
  }

  addCustomFood = async () => {
    const info = this.state
    await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/addFood`, {
      method: 'POST',
      body: JSON.stringify({
        title: info.title,
        brand: info.brand,
        measure: `${info.servingType} (${info.servingSize}g)`,
        info: {
          calories: info.calories,
          fats: info.fats,
          saturatedFat: info.saturatedFat,
          transFat: info.transFat,
          cholesterol: info.cholesterol,
          sodium: info.sodium,
          potassium: info.potassium,
          carbs: info.carbs,
          dietaryFiber: info.dietaryFiber,
          sugars: info.sugars,
          proteins: info.proteins
        }
      }),
      headers: {
        'x-auth-token': localStorage.token,
        'content-type': 'application/json'
      }
    })
    toast.success('Food has been successfully created!')
    this.props.handleCancel('customFoodVisible')
    this.setState({
      title: '',
      brand: '',
      quantity: '',
      servingType: '',
      calories: '',
      fats: '',
      saturatedFat: '',
      transFat: '',
      cholesterol: '',
      sodium: '',
      potassium: '',
      carbs: '',
      dietaryFiber: '',
      sugars: '',
      proteins: ''
    })
  }

  render(){
    return (
      <Modal
        title='Add custom food'
        visible={this.props.customFoodVisible}
        onCancel={() => this.props.handleCancel('customFoodVisible')}
        footer={null}
        centered={true}
        className="custom-food-modal"
      >
        <form onSubmit={(e) => {
          e.preventDefault()
          this.addCustomFood()
        }}>
          <Row className="flex-lg">
            <Col lg={11}>
              <span>
                Name
              </span>
              <div>
                <Input value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} required />
              </div>
            </Col>
            <Col className="ml-auto" lg={11}>
              <span>
                Brand
              </span>
              <div>
                <Input value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })} required />
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <Row className="w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Serving Size
              </Col>
              <Col span={12}>
                <Input value={this.state.servingSize} placeholder='grams' type="number" onChange={(e) => this.setState({ servingSize: e.target.value })} />
              </Col>
              <Col lg={6} xs={12}>
                <Select
                  value={this.state.servingType}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onChange={(servingType) => { this.setState({ servingType }) }}
                >
                  {this.state.servingTypes.map((item, index) =>
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  )}
                </Select>
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Calories
              </Col>
              <Col lg={18}>
                <Input value={this.state.calories} type="number" onChange={(e) => this.setState({ calories: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Total fat (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.fats} type="number" onChange={(e) => this.setState({ fats: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Saturated fat (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.saturatedFat} type="number" onChange={(e) => this.setState({ saturatedFat: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Trans fat (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.transFat} type="number" onChange={(e) => this.setState({ transFat: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Cholesterol (mg)
              </Col>
              <Col lg={18}>
                <Input value={this.state.cholesterol} type="number" onChange={(e) => this.setState({ cholesterol: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Sodium (mg)
              </Col>
              <Col lg={18}>
                <Input value={this.state.sodium} type="number" onChange={(e) => this.setState({ sodium: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Potassium (mg)
              </Col>
              <Col lg={18}>
                <Input value={this.state.potassium} type="number" onChange={(e) => this.setState({ potassium: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Total carbs (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.carbs} type="number" onChange={(e) => this.setState({ carbs: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Dietary fiber (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.dietaryFiber} type="number" onChange={(e) => this.setState({ dietaryFiber: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Sugars (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.sugars} type="number" onChange={(e) => this.setState({ sugars: e.target.value })} />
              </Col>
            </Row>
            <Row className="mt-2 w-100 flex-lg flex-center-aligned">
              <Col lg={6}>
                Protein (g)
              </Col>
              <Col lg={18}>
                <Input value={this.state.proteins} type="number" onChange={(e) => this.setState({ proteins: e.target.value })} />
              </Col>
            </Row>
            <Button type="primary" htmlType="submit" className="save-btn-modal mt-4">
              Save custom food
            </Button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default CustomFoodModal;
