import React from 'react';
import { connect } from 'react-redux';
import LeanBodyMass from './leanBodyMassChart';
import StatSummary from './statSummary';
import StatCard from './statCard';
import { Pagination, Switch, Select, Modal } from 'antd';
import { SET_STAT_VIEW_MODE } from '../../ducks/Global/constants';
import { getTableData } from '../../ducks/Table/actions';
import moment from 'moment';
import TablePage from '../../components/TablePage';
import { MdCompareArrows } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import './index.css';

const { Option } = Select;

class StatsPage extends React.Component {
  state={
    userBodyFatMeasurements: null, 
    currentShowing: null, 
    postsPerPage: 12, 
    page: 1,
    openModal: false,
    currentPictures: null,
    currentData: null,
    compareData: null,
    compare: false,
    pictureIndex: 0
  }

  componentDidMount(){
    this.setState({
      userBodyFatMeasurements: this.props.userBodyFatMeasurements,
      currentShowing: [...this.props.userBodyFatMeasurements].splice(0, 12)
    })
    !this.props.isAdmin ? this.props.getTableData('/api/table/stat-table-data/me') : this.props.getTableData(`/api/table/stat-table-data/${this.props.clientProfile.user._id}`)
  }

  paginate = (page) => {
    let userBodyFatMeasurements = [...this.state.userBodyFatMeasurements];
    let indexOfLastPost = page * this.state.postsPerPage;
    let indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    let newArr = userBodyFatMeasurements.slice(indexOfFirstPost, indexOfLastPost);
    this.setState({currentShowing: newArr, page: page});
  }

  handleSort = (statsData) => {
    if(statsData && statsData.length > 0) {
      return statsData.sort((a, b) => {
        return new Date(b['date']) - new Date(a['date']);
      });
    }
    return [];
  };

  columnsStat = () => {
    return [
      {title: 'Date', dataIndex: "date", key: 'date', render: date => <p style={{whiteSpace: 'nowrap'}}>{moment(date).format("MMM D, YYYY")}</p>},
      {title: 'Source', dataIndex: "source", key: 'source'},
      {title: 'Weight', dataIndex: "weight", key: 'weight', width: '120px', render: weight => this.renderWeight(weight)},
      {title: 'Lean Body Mass', dataIndex: "leanBodyMass", key: 'leanBodyMass', width: '160px', render: (lbm, data) => this.renderLbm(lbm, data)},
      {title: 'Body Fat', dataIndex: "bfPercentage", key: 'bfPercentage', render: (bf) => this.renderBf(bf)},
      {title: 'Pictures', dataIndex: "pictures", key: 'pictures', render: (pictures, data) => this.renderAction(pictures, data)}
    ];
  }

    // tableHeader = () => {
  //   return(
  //     <p className='table-count'>Showing {this.props.onsiteClients && this.props.onsiteClients.length} clients</p>
  //   )
  // }


  renderWeight = (weight) => {
    return this.props.profile.isMetric ? <p className='home-card-text'>{`${Number(weight.kg).toFixed(1)} kg`}</p> : <p className='home-card-text'>{`${Number(weight.lb).toFixed(1)} lb`}</p>;
  }

  renderLbm = (lbm, data) => {
    if(data.bfPercentage === 0 || !data.bfPercentage){
      return <p className='home-card-text'>-</p>
    } else {
      return this.props.profile.isMetric ? <p className='home-card-text'>{`${Number(data.leanBodyMass.kg).toFixed(1)} kg`}</p> : <p className='home-card-text'>{`${Number(data.leanBodyMass.lb).toFixed(1)} lb`}</p>
    }
  }

  renderBf = (bf) => {
    if(!bf || bf === 0){
      return <p className='home-card-text'>-</p>
    } else {
      return <p className='home-card-text'>{`${bf}%`}</p>
    }
  }

  openPictures = (data) => {
    this.setState({
      openModal: !this.state.openModal,
      currentPictures: data,
      currentData: data
    })
  }

  renderAction = (data) => {
    if(data.length === 0){
      return <p className='home-card-text'>-</p>
    } else {
      return (
        <AiOutlineEye 
          style={{fontSize: '26px', color: '#2761f1', cursor: 'pointer'}}
          onClick={() => this.openPictures(data)}
        />
      )
    }
  }

  handleCancel = () => {
    this.setState({
      openModal: false,
      compareData: null
    })
  }

  renderDatepicker = () => {
    const { pictureIndex } = this.state;

    return(
      <div>
        <div className='compare-content flex flex-center-aligned'>
          <p style={{color: '#000000'}}>{moment(this.state.currentData.date).format('lll')}</p>
          <MdCompareArrows style={{fontSize: '28px'}}/>
          <Select 
            className='compare-date-select'
            placeholder="Compare date..."
            onChange={(e, data) => this.setState({compareData: data.props.data})}
            bordered={false}
          >
            {this.props.userBodyFatMeasurements.map(data => data.pictures.length > 0 && 
            <Option 
              key={data._id} 
              data={data} 
              value={data.date}
            >
              {moment(data.date).format('lll')}
            </Option>
            )}
          </Select>
        </div>
        {this.state.compareData &&
        <div className='select-side'>
          <p className={`text ${pictureIndex === 0 ? 'active' : ''}`} onClick={() => this.setState({pictureIndex: 0})}>Front</p>
          <p className={`text ${pictureIndex === 1 ? 'active' : ''}`} onClick={() => this.setState({pictureIndex: 1})}>Side</p>
          <p className={`text ${pictureIndex === 2 ? 'active' : ''}`} onClick={() => this.setState({pictureIndex: 2})}>Back</p>
        </div>}
      </div>
     )
  }

  renderPictures = () => {
    if(!this.state.compareData){
      return(
        <div className='progress-picture-container'>
          {this.state.currentPictures.map(picture => 
          <img className='img' src={picture} />
          )}
        </div>
      )
    } else {
      return(
        <div className='progress-picture-container'>
          <img className='img' src={this.state.currentData[this.state.pictureIndex]} /> 
          <img className='img' src={this.state.compareData.pictures[this.state.pictureIndex]} /> 
        </div>
      )
    }
  }

  render(){
    const { currentShowing } = this.state;
    return(
      <div className='stat-page'>
        <div>
          <p className='home-card-title'>Summary</p>
          <StatSummary {...this.props}/>
        </div>
        <div>
          <p className='home-card-title'>Weight</p>
          <LeanBodyMass {...this.props}/>
        </div>

        <div>
          <div className='flex flex-center-aligned space-between'>
            <p className='home-card-title'>Progress history</p>
            <Switch
              className='mb-2'
              onChange={() => this.props.setStatMode(!this.props.tableMode)}
              checked={this.props.tableMode}
              checkedChildren="Cards"
              unCheckedChildren="Table"
            />
          </div>
          {this.props.tableMode ? 
          <>
            <div className='stat-card-container'>
              {currentShowing && this.handleSort(currentShowing).map(data => 
              <StatCard 
                key={data._id} 
                data={data} 
                profile={this.props.profile} 
                userBodyFatMeasurements={this.props.userBodyFatMeasurements}
                renderWeight={this.renderWeight}
                renderBf={this.renderBf}
                renderLbm={this.renderLbm}
                renderAction={this.renderAction}
                renderDatepicker={this.renderDatepicker}
                renderPictures={this.renderPictures}
                />)
              }
            </div>
            <div className='flex justify-center'>
              <Pagination 
                total={this.state.userBodyFatMeasurements && this.state.userBodyFatMeasurements.length} 
                onChange={(page) => this.paginate(page)} 
                current={this.state.page} 
                defaultPageSize={this.state.postsPerPage}
              />
            </div> 
          </> :
          <TablePage 
            {...this.props}
            loading={false}
            columns={this.columnsStat()}
            tableData={this.props.tableData}
            isDisabledRowHover
            className="stats"
            // tableUrl={`${process.env.APP_API_URL}accounts/${this.props.group.id}/campaigns`}
            scroll={{x: true}}
            // tableHeader={this.tableHeader()}
          />}
        </div>
        {this.state.currentData &&
        <Modal
          // title={<p className='gradient-text'>{moment(this.state.currentData.date).format('lll')}</p>}
          visible={this.state.openModal}
          closable
          maskClosable
          onCancel={() => this.handleCancel()}
          footer={null}
          width='auto'
          centered
        >
          <div className='image-modal-content'>
            <p className='main-page-title-small'style={{fontSize: '18px', fontWeight: '600', color: '#000000'}}>Compare Progress Pictures</p>
            <div>
              {this.renderDatepicker()}
            </div>
            {this.renderPictures()}
          </div>
        </Modal>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tableMode: state.global.tableMode,
    tableData: state.table.tableData,
    tableDataLoading: state.table.tableDataLoading,
    tableDataError:  state.table.tableDataError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStatMode: (tableMode) => {
      return dispatch({
        type: SET_STAT_VIEW_MODE,
        tableMode,
      });
    },
    getTableData: (path) => dispatch(getTableData(path))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsPage);