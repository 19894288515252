  
import React, { useState } from 'react';
import { Input, Button, Alert } from 'antd';
import { sendPasswordResetEmail } from '../../helpers/auth';

export default function PasswordResetForm(props) {
  const [email, setEmail] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [formStatus, setFormStatus] = useState({});
  return (
    <>
      <p className='auth-modal-title'>Reset Password</p>
      <form className="form">
      <p className='label'>Enter your email</p>
        <Input
          className='mb-2'
          // placeholder="Enter email"
          onChange={e => setEmail(e.target.value)}
        />
        {
          formStatus.message &&  <Alert
            style={{ marginBottom: 15 }}
            message={formStatus.message}
            type={formStatus.type}
          />
        }
        <Button
          block
          htmlType={'submit'}
          loading={formLoading}
          onClick={async e => {
            e.preventDefault();
            setFormLoading(true);
            try {
              await sendPasswordResetEmail(email);
              setFormStatus({
                type: 'success',
                message: 'Password reset email has been sent. Please follow the instructions in the email.',
              });
              setFormLoading(false);
            } catch(error) {
              setFormStatus({
                type: 'error',
                message: error.message,
              });
              setFormLoading(false);
            }
          }}
          type='primary'
          style={{height: '40px', marginBottom: '10px', marginTop: "10px"}}
        >
          {'Reset Password'}
        </Button>
        <a
          href={'#'}
          onClick={e => {
            e.preventDefault();
            props.onCancel();
          }}
          className={'text'}
          >
            &lt; Back to Login
          </a>
      </form>
    </>
  );
}

PasswordResetForm.defaultProps = {};
