import React from "react";
import { connect } from "react-redux";
import UserProfilePage from "../../presentational/UserProfilePage";
import {getCurrentProfile, updateUserProfile} from "../../ducks/Profile/actions";
import { getUserBfMeasurements } from "../../ducks/Measurements/actions";
import {getUserSettings, createUserSetting} from '../../ducks/Setting/actions';
import "../../presentational/ProfilePage/index.css";
import { loadUser } from "../../ducks/Auth/actions";

class UserProfile extends React.Component {
  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getUserSettings();
  }

  render() {
    return (
      <div className="site-layout-background user-profile">
        <UserProfilePage userSettings={this.props.userSettings} {...this.props}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile.profile,
    error: state.profile.error,
    loading: state.profile.loading,
    updatingUserInfo: state.profile.updatingUserInfo,
    successMessage: state.profile.successMessage,
    userSettings: state.settings.userSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
    getUserSettings: () => dispatch(getUserSettings()),
    createUserSetting: (setting) => dispatch(createUserSetting(setting)),
    updateUserProfile: (userInfo) => dispatch(updateUserProfile(userInfo)),
    loadUser:() =>  dispatch(loadUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);