import React, { useState, useEffect } from 'react';
import { shortenText, starRating } from '../../helpers/utils';
import { BsFillChatQuoteFill } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { openReviewModal } from '../../ducks/Global/actions';
import './index.css';

export const GoogleReviews = ({color}) => {
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/api/google/places`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.result && data.result.reviews) {
          const newReview = {
            author_name: 'Simi R.',
            rating: 5,
            text: 'Hands down the best personal trainer I\'ve ever had, and I\'ve had several over the years! I\'ve struggled with my weight ever since being diagnosed with hypothyroidism. I thought there was nothing I could do to be slim again until a friend recommended me to Alvaro. He helped me optimize both my diet and exercise routines, and in less than three months I was down over 10% body weight, and I\'ve continued to lose and tone ever since! If you want real, lasting results definitely see Alvaro!'
          };
          data.result.reviews.unshift(newReview);

          setReviews(data.result.reviews);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      <div className='review-container'>
      {reviews.map((review) => (
        <div className='review-card' key={review.author_name}>
          <BsFillChatQuoteFill style={{fontSize: '50px', color: color}}/>
          <div>
            <p className='mb-2'>{starRating(review.rating)}</p>
            <p className='subtitle mb-2'>{shortenText(review.text, 150)}</p>
            <a className='link' onClick={() => dispatch(openReviewModal(true, review))}>Read More</a>
          </div>
          <p className='title' style={{fontSize: '18px'}}>{review.author_name}</p>
        </div>
      ))}
      </div>
    </>
  );
};