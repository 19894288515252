import React from 'react';
import { connect } from 'react-redux';
import IntegrationsPage from '../../presentational/IntegrationsPage';
import ProfileSettingsPage from '../../presentational/ProfileSettingsPage';
import SubscriptionPage from '../../presentational/SubscriptionPage';
import { getCurrentProfile } from '../../ducks/Profile/actions';
import { getFitbitAccessToken, setUserFitbitAccessToken, setWithingsAccessToken } from '../../ducks/Integrations/actions';
import { HANDLE_PRICING_MODAL, OPEN_DRAWER } from '../../ducks/Global/constants';
import { PricingModal } from '../../components/PricingModal';

const RenderDesiredPage = props => {
  const page = props.location.pathname;
  switch (page) {
    case '/dashboard/settings/account-settings':
      return (props.user && !props.user.isSubscriptionActive) ? <SubscriptionPage {...props}/> : <ProfileSettingsPage {...props} />;
    case '/dashboard/settings/integrations':
      return (props.user && (!props.user.isSubscriptionActive && props.user.createdBy !== "admin")) ? <SubscriptionPage {...props}/> : <IntegrationsPage {...props}/>;
    case '/dashboard/settings/billing':
      return <SubscriptionPage {...props}/>;
  }
}

class Settings extends React.Component {

  componentDidMount(){
    const url = new URL(`http://localhost:3000${this.props.location.hash.replace('#', '?')}`);
    const urlParams = new URLSearchParams(url.search ? url.search : this.props.location.search);
    const fitbitAccessKey = urlParams.get('access_token');
    if (fitbitAccessKey) {
      this.props.setUserFitbitAccessToken(fitbitAccessKey); //makes a post request when user authorizes fitbit first time
    }
    const withingsAccessToken = urlParams.get('withings_access_token');
    const withingsRefreshToken = urlParams.get('withings_refresh_token');
    const userid = urlParams.get('userid');
    if (withingsAccessToken) {
      this.props.setWithingsAccessToken({
        accessToken: withingsAccessToken,
        refreshToken: withingsRefreshToken,
        userid
      });
    }
    this.props.getCurrentProfile();
  }

  render(){
    return(
      <div>
        <PricingModal {...this.props} handlePricingModal={this.props.handlePricingModal} pricingModalOpen={this.props.pricingModalOpen} />
        <RenderDesiredPage {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    profile: state.profile.profile,
    pricingModalOpen: state.global.pricingModalOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getFitbitAccessToken: () => dispatch(getFitbitAccessToken()),
    setUserFitbitAccessToken: (accessKey) => dispatch(setUserFitbitAccessToken(accessKey)),
    setWithingsAccessToken: (accessKeys) => dispatch(setWithingsAccessToken(accessKeys)),
    openDrawer: () => {
      return dispatch({
        type: OPEN_DRAWER
      });
		},
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen
      });
		}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);