import React from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

class ExerciseHistoryChart extends React.Component {
  constructor(){
    super();
    this.canvas = React.createRef();
    this.state = {
      data: [],
      gradientStroke: '',
      gradientFill: '',
      colors: []
    }
  }

  componentDidMount() {
    const { allExerciseHistory } = this.props;
    let ctx = this.canvas.current.chartInstance.ctx;
    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#FF0066"); 
    gradientStroke.addColorStop(1, "#2761f1");
    let gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
    gradientFill.addColorStop(0, "rgba(39, 97, 241, 0.7)");
    gradientFill.addColorStop(1, "rgba(232, 10, 170, 0.7)");
    this.setState({
      gradientStroke: gradientStroke,
      gradientFill: gradientFill,
    }, () => this.getChartData(allExerciseHistory, 'volume'));
  }

  componentDidUpdate(previousProps, previousState) {
    const { allExerciseHistory } = this.props;
    if (previousProps.allExerciseHistory !== allExerciseHistory) {
      this.getChartData(allExerciseHistory, 'volume');
    }
  }

  getChartData = (allExerciseHistory, dateType) => {
    let dataToDisplay = [];
    let dates = allExerciseHistory.history.map(item => moment(item.date).format("MMM D, YYYY"));

    if (dateType === "volume") {
     dataToDisplay = allExerciseHistory.history.map(item => item.volume)
    }

    //if data to display is undefined
    let colors = [];
    dataToDisplay.forEach(d => colors.push(this.state.gradientStroke));

    const data = {
      labels: dates,
      datasets: [{
        options: {
          animation: {
            easing: "easeInOutBack"
          }
        },
        label: 'Volume',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'transparent',
        // borderColor: ['#5400fc'],
        borderWidth: 2,
        borderColor: this.state.gradientStroke,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors,
        pointBackgroundColor: colors,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: colors,
        pointHoverBorderColor: colors,
        pointHoverBorderWidth: 2,
        pointRadius: 1.5,
        pointHitRadius: 4,
        data: dataToDisplay,
        hoverBackgroundColor: this.state.gradientStroke
      }]
    };
    this.setState({ data: data })
  }


  render(){
    const { data } = this.state;
    const options = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
      animation: {
        easing: "easeInOutBack",
      },
      layout: {
        padding: {
          right: 8,
        },
      },
      tooltips: {
        backgroundColor: "#fafafa",
        titleFontFamily: "sans-serif",
        titleFontSize: 14,
        titleFontColor: "black",
        bodyFontColor: "black",
        bodyFontSize: 12,
      },
      responsive: true,
    };

    return(
      // <div className='weight-card'>
        <Line
          id='canvas'
          ref={this.canvas}
          data={data}
          width={'100%'}
          height={320}
          options={options}
          style={{paddingBottom: '30px'}}
        />
      // </div>
    )
  }
}

export default ExerciseHistoryChart;