import React from 'react';
import LearnPage from '../../presentational/LearnPage/index';
import { connect } from 'react-redux'; 
import { getNutrition } from '../../ducks/Profile/actions';
import { Loading } from '../../components/Loading';
import { getTodo, createOrUpdateTodo } from '../../ducks/Todo/actions';
import { getGuides, updateGuides } from '../../ducks/Guide/actions';

class Learn extends React.Component {

  componentDidMount(){
    this.props.getNutrition();
    this.props.getTodo();
    this.props.getGuides()
  }

  render(){
    return(
      <div className='learn-page sub-menu' style={{marginBottom: '50px'}}>
        {this.props.guides ? <LearnPage {...this.props} /> : <Loading />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    nutrition: state.profile.nutrition,
    todo: state.todo.todo,
    guides: state.guides.guides
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNutrition: () => dispatch(getNutrition()),
    getTodo: () => dispatch(getTodo()),
    createOrUpdateTodo: (todo) => dispatch(createOrUpdateTodo(todo)),
    getGuides: () => dispatch(getGuides()),
    updateGuides: (guide) => dispatch(updateGuides(guide)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Learn);