import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Modal, Button, Divider, Badge } from 'antd';
import { GiChefToque } from 'react-icons/gi';
import { IoMdTime, IoIosArrowRoundForward } from "react-icons/io";
import { capitalize } from '../../helpers/utils';
import './index.css';
import '../../presentational/SuggesticMealPlanPage/index.css';

class RecipeModal extends React.Component {
  constructor(){
    super();
    this.state = {
      recipeIngredients: null,
      allSelected: false
    }
  }

  componentDidMount(){
     //removes repeat ingredients from array before setting to state
    const uniqueObjects = [...new Map(this.props.currentRecipe.ingredients.map(item => [item.text, item])).values()];
    this.props.currentRecipe.ingredients = uniqueObjects;
    this.setState({
      recipeIngredients: this.props.currentRecipe.ingredients
    });
  }

  componentWillReceiveProps(nextProps){
     //removes repeat ingredients from array before setting to state
    const uniqueObjects = [...new Map(nextProps.currentRecipe.ingredients.map(item => [item.text, item])).values()];
    nextProps.currentRecipe.ingredients = uniqueObjects;
    this.setState({
      recipeIngredients: nextProps.currentRecipe.ingredients, 
      allSelected: false
    });
  }
  
  handleSaveIngredients = (dish, ingredients, image) => {
    ingredients.forEach(ingredient => ingredient.checked = false);
    this.props.handleCancel();
    this.props.saveGrocery(dish, ingredients, image);
  }

  renderMacrosInfo = (currentRecipe) => {
    const { ENERC_KCAL, PROCNT, CHOCDF, FAT } = currentRecipe.totalNutrients;
    return(
      <>
        <div>
          <Badge
            color={'#21d0b2'}
            text={`${Math.round(ENERC_KCAL.quantity / currentRecipe.yield)} Calories`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${Math.round(PROCNT.quantity.toFixed(1) / currentRecipe.yield)}${PROCNT.unit} Protein`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${Math.round(CHOCDF.quantity.toFixed(1) / currentRecipe.yield)}${CHOCDF.unit} Carbs`}
          />
        </div>
        <div className="flex flex-center-aligned">
          <Badge
            color={'#21d0b2'}
            text={`${Math.round(FAT.quantity.toFixed(1) / currentRecipe.yield)}${FAT.unit} Fat`}
          />
        </div>
      </>
    )
  }
 
  render() {
    const { currentRecipe, recipeModalOpen, handleCancel } = this.props;
    return (
      <>
      <Modal
        className='recipe-modal'
        visible={recipeModalOpen}
        closable
        maskClosable
        onCancel={() => handleCancel()}
        footer={null}
        centered
        width={'500px'}  
      >
        <div className='meal-modal'>
          <div className='recipe-img' style={{backgroundImage: `url(${currentRecipe.image})`}}>
          </div>
          <div className='recipe-content'>
            <div className='grid'>
              <p className='title'>{currentRecipe.label}</p>
              {currentRecipe.source &&
              <p style={{color: '#FF0066'}}>{`by ${currentRecipe.source}`}</p>}
            </div>
            <div style={{margin: '20px 0 20px 0'}} className='flex flex-center-aligned'>
              <div className='details'>
                <GiChefToque style={{fontSize: '40px', color: '#2bffcc', marginBottom: '5px'}}/>
                <p className='home-card-text'>{`Serves ${currentRecipe.yield}`}</p>
              </div>
              <div className='details'>
                <IoMdTime style={{fontSize: '40px', color: '#2bffcc', marginBottom: '5px'}}/>
                <p className='home-card-text'>{currentRecipe.totalTime} min</p>
              </div>
            </div>

            <div className='recipe-nutrients'>
              <p className='meal-card-title'>{`Nutrients Per Serving ${currentRecipe.servingWeight ? `(${currentRecipe.servingWeight.toFixed(2)}g)` : ''}`}</p>
              {this.renderMacrosInfo(currentRecipe)}
            </div>

            <div className='recipe-ingredients'>
              <p className='meal-card-title'>{`Ingredients - ${currentRecipe.ingredientLines.length}`}</p>
              {this.state.recipeIngredients && this.state.recipeIngredients.map(ingredient =>
              <>
                <div key={ingredient.text} className='ingredients grid'>
                  <Badge color={'#2bffcc'}/>
                  <p className='meal-card-text'>{capitalize(ingredient.text)}</p>
                </div>
                <Divider style={{margin: '10px 0px'}}/>
              </>)}
            </div>
            <>
              <p className='meal-card-title meal-type-text'>Instructions</p>
              <Link to={{ pathname: currentRecipe.url }} target="_blank">
                <Button className='recipe-modal-btn'>
                  <div className='flex flex-center-aligned justify-center'>
                    {`View instructions on ${currentRecipe.source}`}
                    <IoIosArrowRoundForward style={{fontSize: '30px', marginLeft: '5px'}}/>
                  </div>
                </Button>
              </Link>
            </>
          </div>
        </div>
      </Modal>
      </>
    );
  }
}

export default withRouter(RecipeModal);