import React from 'react';
import StatSummaryCard from './statSummaryCard';
import './index.css';

class StatSummary extends React.Component {
  constructor(){
    super();
    this.state = {
      firstSecondAndLastData: [],
      currentStat: null,
      difference: {},
      differenceSinceStart: {}
    }
  }

  componentDidMount(){
    this.setStatDataSummary();
  } 

  setStatDataSummary = () => {
    let firstSecondAndLastData = [...this.state.firstSecondAndLastData];
    let isMetric = this.props.profile.isMetric;
    let reversedData = this.props.userBodyFatMeasurements.reverse();
    let unit = isMetric ? "kg" : "lb";

    reversedData.forEach((data, index) => {
      if(index === 0 || index === 1 || index === reversedData.length - 1){
        firstSecondAndLastData.push({
          key: data._id,
          date: data.date,
          bfPercentage: data.bfPercentage,
          weight: data['weight'][unit],
          leanBodyMass: data['leanBodyMass'][unit]
        });
      }
    });

    this.props.userBodyFatMeasurements.length > 1 && this.getStatDifference(reversedData);

    this.setState({
      firstSecondAndLastData,
      currentStat: firstSecondAndLastData[0]
    })
  }

  getStatDifference = (reversedData) => {
    const { profile } = this.props;
    let difference = { ...this.state.difference };
    let differenceSinceStart = { ...this.state.differenceSinceStart };
    let unit = profile.isMetric ? "kg" : "lb";

    differenceSinceStart.weightDiff = (Number(reversedData[0].weight[unit]) - Number(reversedData[reversedData.length - 1].weight[unit])).toFixed(1);
    differenceSinceStart.bfDiff = (Number(reversedData[0].bfPercentage) - Number(reversedData[reversedData.length - 1].bfPercentage)).toFixed(1);
    differenceSinceStart.leanBodyMassDiff = (Number(reversedData[0].leanBodyMass[unit]) - Number(reversedData[reversedData.length - 1].leanBodyMass[unit])).toFixed(1);

    difference.weightDiff = (Number(reversedData[0].weight[unit]) - Number(reversedData[1].weight[unit])).toFixed(1);
    difference.bfDiff = (Number(reversedData[0].bfPercentage) - Number(reversedData[1].bfPercentage)).toFixed(1);
    difference.leanBodyMassDiff = (Number(reversedData[0].leanBodyMass[unit]) - Number(reversedData[1].leanBodyMass[unit])).toFixed(1);

    this.setState({
      difference: difference,
      differenceSinceStart: differenceSinceStart
    });
  };

  render(){
    return(
     <div className='stat-container'>
       {this.state.currentStat && <StatSummaryCard {...this.props} difference={this.state.difference} differenceSinceStart={this.state.differenceSinceStart} data={this.state.currentStat}/>}
     </div>
    )
  }
}

export default StatSummary;