import { takeLatest, call } from 'redux-saga/effects';
import { postData } from "../api";
import { GET_FIT_BIT_ACCESS_TOKEN, SET_USER_FITBIT_ACCESS_TOKEN, SET_USER_WITHINGS_ACCESS_TOKEN, REMOVE_FITBIT_ACCESS_TOKEN } from './constants';

export function* getFitbitAccessToken() {
  try {
    window.open(`https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22C384&redirect_uri=${process.env.REACT_APP_FITBIT_REDIRECT_URL}&scope=profile%20activity%20heartrate%20location%20nutrition%20settings%20sleep%20social%20weight&expires_in=31536000`, "_self")

  } catch (err) {
    console.log(err)
  }
}

//saves token to db
export function* setUserFitbitAccessToken(accessKey) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/fitbit/setUserFitbitAccessToken`;
    const key = accessKey.payload;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    yield call(() => postData(url, config, {key})); 
    window.location.replace('/dashboard/home')
  } catch (err) {
    console.log(err)
  }
}

export function* setUserWithingsAccessToken(accessKeys) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/withings/setToken`;
    const keys = accessKeys.payload;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    yield call(() => postData(url, config, keys)); 
    window.location.replace('/dashboard/home')
  } catch (err) {
    console.log(err)
    alert('An error ocurred. Please try again later')
  }
}

//saves token to db
export function* removeFitbitAccessToken() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/fitbit/removeFitbitAccessToken/me`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token
      }
    };
    yield call(() => postData(url, config)); 
    window.location.replace('/dashboard/home')
  } catch (err) {
    console.log(err)
  }
}

export default function* integrationsSaga() {
  yield takeLatest(GET_FIT_BIT_ACCESS_TOKEN, getFitbitAccessToken);
  yield takeLatest(SET_USER_FITBIT_ACCESS_TOKEN, setUserFitbitAccessToken);
  yield takeLatest(SET_USER_WITHINGS_ACCESS_TOKEN, setUserWithingsAccessToken);
  yield takeLatest(REMOVE_FITBIT_ACCESS_TOKEN, removeFitbitAccessToken);
}