import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export const BlogCard = ({blog}) => {
  return (
    <Link className="blog-card-horizontal" to={{pathname: `/blog/${blog.slug}`, state: blog}}>
      <div className="card-content">
        <div className="image-card" style={{backgroundImage: `url(${blog.feature_image})`}}>
        </div>  
        <div className="text-content">
          {blog.tags.map(tag => <p key={tag.name} className='blog-card-category'>{tag.name}</p>)}
          <h2 className='blog-title'>{blog.title}</h2>
          <p className='blog-card-subtitle'>{blog.excerpt}</p>
        </div>
      </div>
    </Link>
  )
}