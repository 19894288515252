import {
  GET_WORKOUT_PROGRAM_LIBRARY,
  GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS,
  GET_WORKOUT_PROGRAM_LIBRARY_ERROR,
  CREATE_WORKOUT_PROGRAM,
  CREATE_WORKOUT_PROGRAM_SUCCESS,
  CREATE_WORKOUT_PROGRAM_ERROR,
  UPDATE_WORKOUT_PROGRAM,
  UPDATE_WORKOUT_PROGRAM_SUCCESS,
  UPDATE_WORKOUT_PROGRAM_ERROR,
  GET_USER_WORKOUT_PROGRAMS,
  GET_USER_WORKOUT_PROGRAMS_SUCCESS,
  GET_USER_WORKOUT_PROGRAMS_ERROR,
  CREATE_USER_WORKOUT_PROGRAM,
  CREATE_USER_WORKOUT_PROGRAM_SUCCESS,
  CREATE_USER_WORKOUT_PROGRAM_ERROR,
  UPDATE_USER_WORKOUT_PROGRAM,
  UPDATE_USER_WORKOUT_PROGRAM_SUCCESS,
  UPDATE_USER_WORKOUT_PROGRAM_ERROR

} from "./constants";

export function getWorkoutProgramLibrary() {
  return {
    type: GET_WORKOUT_PROGRAM_LIBRARY
  };
}

export function getWorkoutProgramLibrarySuccess(payload) {
  return {
    type: GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS,
    payload
  };
}

export function getWorkoutProgramLibraryError(err) {
  return {
    type: GET_WORKOUT_PROGRAM_LIBRARY_ERROR,
    err
  };
}

export function createWorkoutProgram(data) {
  return {
    type: CREATE_WORKOUT_PROGRAM,
    data
  };
}

export function createWorkoutProgramSuccess(payload) {
  return {
    type: CREATE_WORKOUT_PROGRAM_SUCCESS,
    payload
  };
}

export function createWorkoutProgramError(err) {
  return {
    type: CREATE_WORKOUT_PROGRAM_ERROR,
    err
  };
}

export function updateWorkoutProgram(workoutProgram) {
  return {
    type: UPDATE_WORKOUT_PROGRAM,
    workoutProgram
  };
}

export function updateWorkoutProgramSuccess(payload) {
  return {
    type: UPDATE_WORKOUT_PROGRAM_SUCCESS,
    payload
  };
}

export function updateWorkoutProgramError(err) {
  return {
    type: UPDATE_WORKOUT_PROGRAM_ERROR,
    err
  };
}

export function getUserWorkoutPrograms(clientId) {
  return {
    type: GET_USER_WORKOUT_PROGRAMS,
    clientId
  };
}

export function getUserWorkoutProgramsSuccess(payload) {
  return {
    type: GET_USER_WORKOUT_PROGRAMS_SUCCESS,
    payload
  };
}

export function getUserWorkoutProgramsError(err) {
  return {
    type: GET_USER_WORKOUT_PROGRAMS_ERROR,
    err
  };
}

export function createUserWorkoutProgram(client, workoutProgram, notes) {
  return {
    type: CREATE_USER_WORKOUT_PROGRAM,
    client,
    workoutProgram,
    notes
  };
}

export function createUserWorkoutProgramSuccess(payload) {
  return {
    type: CREATE_USER_WORKOUT_PROGRAM_SUCCESS,
    payload
  };
}

export function createUserWorkoutProgramError(err) {
  return {
    type: CREATE_USER_WORKOUT_PROGRAM_ERROR,
    err
  };
}