import React from "react";
import "../CustomMealPlanPage/index.css";
import debounce from "lodash/debounce";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dish from "../../assets/images/dish-gray.svg";
import {CaretDownOutlined } from '@ant-design/icons';
import { Select, Modal, Spin, Divider } from "antd";
import {LoadingOutlined,} from "@ant-design/icons";
import "./index.css";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class MealElement extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      nutritionVisible: false,
      nutritionLoading: false,
      allNutrients: {},
      caloriesPerServing: 0,
      carbsPerServing: 0,
      proteinsPerServing: 0,
      fatsPerServing: 0,
      type: "",
      isOpenAlternativeFoods: false,
      loadingIndex: null,
      alternativeFoods: null,
    };
  }

  componentDidMount() {
    if (this.props.item.quantity && this.props.item.measure) {
      this.setState({
        caloriesPerServing:
          this.props.item.info.calories / this.props.item.quantity,
        carbsPerServing: this.props.item.info.carbs / this.props.item.quantity,
        proteinsPerServing:
          this.props.item.info.proteins / this.props.item.quantity,
        fatsPerServing: this.props.item.info.fats / this.props.item.quantity,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // const [selectedDay, mealIndex, foodIndex] = this.props.location;
    // if (prevProps.alternativeFoods !== this.props.alternativeFoods && this.props.alternativeFoods 
    //   && this.state.loadingIndex === `${selectedDay} ${mealIndex} ${foodIndex}`) {
    //   this.setState({alternativeFoods: this.props.alternativeFoods, loadingIndex: null})
    // }
    if(this.props.customMealPlan && this.props.customMealPlan !== prevProps.customMealPlan){
      this.setState({isOpenAlternativeFoods: false});
    }
  }  
  
  handleChangeNutrients = debounce(async (modal) => {
    if (modal) {
      this.setState({
        nutritionLoading: true,
      });
    }

    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/mealplan/nutrients`,
      {
        method: "POST",
        body: JSON.stringify({
          _id: this.props.item._id,
          quantity: this.props.item.quantity,
          measureUrl: this.props.item.measureUrl,
          foodId: this.props.item.foodId,
        }),
        headers: {
          "x-auth-token": localStorage.token,
          "content-type": "application/json",
        },
      }
    );
    const info = await req.json();
    this.setState({
      loading: false,
      nutritionLoading: false,
      allNutrients: info.totalNutrients,
    });
  }, 400);

  handleChange = async (value) => {
    try {
      const day = this.props.location[0];
      const index = this.props.location[1];
      const title = value.split("id")[0];
      const id = value.split("id")[1].replace(/ /g, "");
      const isGramsUnitExists = this.state.queryResult
        .find((i) => i.id == id)
        .units.some((i) => i[0] === 147);
      const unit = isGramsUnitExists
        ? 147
        : this.state.queryResult.find((i) => i.id == id).units[0][0];

      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/api/mealplan/details?id=${id}&unitId=${unit}`,
        {
          headers: {
            "x-auth-token": localStorage.token,
          },
        }
      );
      const info = await req.json();

      this.setState({
        query: "",
      });

      this.props.updateMeal(day, index, {
        title,
        info: {
          calories: info.foodLog.nutritionalValues.calories,
          carbs: info.foodLog.nutritionalValues.carbs,
          fats: info.foodLog.nutritionalValues.fat,
        },
      });
    } catch (e) {
      toast.error('An error happened. Please choose another product');
    }
  };

  handleSearch = async (value) => {
    if (value) {
      this.setState({
        query: value,
      });

      const req = await fetch(
        `https://www.fitbit.com/search/solrFood?q=${value}&userOnly=false`
      );
      const listOfProducts = await req.json();

      this.setState({
        queryResult: listOfProducts.result.foods,
      });
    }
  };

  showModal = async () => {
    this.setState({
      nutritionVisible: true,
    });
    if (
      !this.state.nutritionLoading &&
      !(this.state.allNutrients.ENERC_KCAL || this.state.allNutrients.calories)
    ) {
      await this.handleChangeNutrients(true);
    }
  };

  handleCancel = (key) => {
    this.setState({
      [key]: false,
    });
  };

  changeType = (type) => {
    this.setState({
      type: this.state.type === type ? "" : type,
    });
  };

  toggleAlternativeFoods = () => {
    this.setState((prevState) => ({isOpenAlternativeFoods: !prevState.isOpenAlternativeFoods}))
  };

  changeFoodOnAlternative = (value) => {
    const { item } = this.props;
    // const {alternativeFoods} = this.state;
    const [selectedDay, mealIndex, foodIndex] = this.props.location;
    const checkedAltFood = item.alternatives.find(item => item.title === value);
    const remainingAlternativeFoods = item.alternatives.filter(item => item.title !== value);
    // const remainingAlternativeFoodsNames = remainingAlternativeFoods.length !== 0 ? 
    // remainingAlternativeFoods.map(item => item.title) : null;
    const alternatives = remainingAlternativeFoods.length ? [item, ...remainingAlternativeFoods] : [item];
    const newFood = {
      foodId: checkedAltFood.id,
      title: checkedAltFood.title,
      image: checkedAltFood.image,
      measures: checkedAltFood.measures,
      quantity: checkedAltFood.quantity,
      measure: checkedAltFood.measure,
      measureUrl: checkedAltFood.measureUrl,
      alternatives,
      info: {
        calories: checkedAltFood.info ? checkedAltFood.info.calories : checkedAltFood.nutrients.ENERC_KCAL,
        carbs: checkedAltFood.info ? checkedAltFood.info.carbs : checkedAltFood.nutrients.CHOCDF,
        proteins: checkedAltFood.info ? checkedAltFood.info.proteins : checkedAltFood.nutrients.PROCNT,
        fats: checkedAltFood.info ? checkedAltFood.info.fats : checkedAltFood.nutrients.FAT,
      },
    };
    const result = {
      date: selectedDay,
      mealIndex,
      foodIndex,
      newFood
    };
    // this.setState({loadingIndex: `${selectedDay} ${mealIndex} ${foodIndex}`})
    this.props.updateMeal(result);
    // this.props.handleGetAlternativeFoods(alternatives.join(','))
  }

  foodOption = (item, nutrients) => {
    return (
      <div>
        <div>
          <img
            className="option-image-select"
            src={item.image || Dish}
            alt=""
          />
          {item.title.toUpperCase()} {item.brand && `- ${item.brand.toUpperCase()}`}
        </div>
        <div>
          <small className="muted">{`NUTRIENTS PER ${this.props.customMealPlan ? `${item.quantity} ${item.measure.toUpperCase()}` : '100g'}`}</small>
          <div className="flex">
            <div className="mr-2">
              <small className="block">
                Energy
              </small>
              {Math.round(nutrients.calories || nutrients.ENERC_KCAL)}kcal
            </div>
            <div className="mr-2">
              <small className="block">
                Protein
              </small>
              {(+nutrients.proteins || +nutrients.PROCNT || 0).toFixed(1)}g
            </div>
            <div className="mr-2">
              <small className="block">
                Carbs
              </small>
              {(+nutrients.carbs || +nutrients.CHOCDF || 0).toFixed(1)}g
            </div>
            <div>
              <small className="block">
                Fat
              </small>
              {(+nutrients.fats || +nutrients.FAT || 0).toFixed(1)}g
            </div>
          </div>
        </div>
      </div>
      )
  };

  render() {
    const { item, updateFoodLoading, isEated } = this.props;
    const { nutritionVisible, allNutrients, isOpenAlternativeFoods, loadingIndex, alternativeFoods } = this.state;
    return (
      <div className="week-item-client week-item-wrap">
        <div className='meal-item-row flex flex-center-aligned'>
          <div>
            <div>
            {!isEated && isOpenAlternativeFoods ? (
            <div className="input-food mr-1 form">
              <p className="label">Alternative foods</p>
              <div>
                <Select
                  defaultOpen={isOpenAlternativeFoods}
                  disabled={item && item.foodIndex === updateFoodLoading && updateFoodLoading.key && updateFoodLoading.key.foodIndex && updateFoodLoading.loading}
                  loading={item && item.foodIndex === updateFoodLoading && updateFoodLoading.key && updateFoodLoading.key.foodIndex && updateFoodLoading.loading}
                  className="w-100"
                  // className="input-serving-label"
                  value={item.title}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onChange={this.changeFoodOnAlternative}
                  onBlur={() => this.setState({isOpenAlternativeFoods: false})}
                >
                  <Option key={item._id} value={item.title}>
                      {this.foodOption(item, item.info || item.nutrients)}
                  </Option>
                  {item.alternatives.length !== 0 && item.alternatives
                  .map((i, index) => (
                    <Option
                      key={index}
                      value={i.title}
                    >
                      {this.foodOption(i, i.info || i.nutrients)}
                    </Option>
                  ))}
                  </Select>
              </div>
            </div>
          ) :
          <div className='flex flex-center-aligned'>
            <img src={item.image || Dish} className="meal-card-img" alt=""/>
            <div className='flex flex-center-aligned'>
              <div>
                <div className='food-item-wrap'>
                <p className='home-card-text'>
                {item.title
                  .split(/\s+/)
                  .map((word) => word[0].toUpperCase() + word.substring(1))
                  .join(" ") } -  {item.quantity || 0} {item.measure || ""}
                </p>
                {item.alternatives && item.alternatives.length !== 0 && !isOpenAlternativeFoods && !isEated && (
                  <CaretDownOutlined
                    className="food-icon"
                    onClick={() => this.toggleAlternativeFoods()}
                  />
                )}
                </div>
                <p className='food-nutrition-info macro-label-bottom'>
                  {item.info && Number(item.info.calories).toFixed(1) || 0} calories | {item.info && Number(item.info.proteins).toFixed(1) || 0} Protein | {item.info && Number(item.info.carbs).toFixed(1) || 0} Carbs | {item.info && Number(item.info.fats).toFixed(1) || 0} Fat 
                  {/* | <a className='blue-link' onClick={this.showModal}>Show all</a> */}
                </p>
              </div>
            </div>
          </div> 
          } 
          </div>
        </div>     
        </div>
        <div className="flex flex-wrap flex-center-aligned">
          <Modal
              title={`${item.title} nutrition details`}
              visible={nutritionVisible}
              onCancel={() => this.handleCancel("nutritionVisible")}
              footer={null}
              centered={true}
            >
              {this.state.nutritionLoading && <Spin indicator={antIcon} />}
              {!this.state.nutritionLoading &&
              allNutrients && Object.entries(allNutrients).map((i) => (
                <>
                  <div className='home-card-text flex flex-center-aligned space-between' key={i[1].label}>
                    <p>{i[1].label}:</p>
                    <p>{(+i[1].quantity).toFixed(1)} {i[1].unit}</p>
                  </div>
                  <Divider className='nutrition-facts-modal'/>
                </>
                ))}
          </Modal>
        </div>
      </div>
    );
  }
}

export default MealElement;
