export const SEND_CONTACT_TO_ZAPIER_REQUEST = 'global/SEND_CONTACT_TO_ZAPIER_REQUEST';
export const SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS = 'global/SEND_CONTACT_TO_ZAPIER_REQUEST_SUCCESS';
export const SEND_CONTACT_TO_ZAPIER_REQUEST_ERROR = 'global/SEND_CONTACT_TO_ZAPIER_REQUEST_ERROR';
export const CLEAR_CONTACT_SUCCESS = 'global/CLEAR_CONTACT_SUCCESS';
export const HANDLE_PROFILE_SUBMENU = 'global/HANDLE_PROFILE_SUBMENU';
export const SET_CURRENT_PAGE = 'global/SET_CURRENT_PAGE';

export const SET_STAT_VIEW_MODE = 'global/SET_STAT_VIEW_MODE';

export const OPEN_DRAWER = 'global/OPEN_DRAWER';
export const CLOSE_DRAWER = 'global/CLOSE_DRAWER';

export const HANDLE_SIDEBAR = 'global/HANDLE_SIDEBAR';

export const SET_IS_LOGIN = 'global/SET_IS_LOGIN';

export const HANDLE_PRICING_MODAL = 'global/HANDLE_PRICING_MODAL';

export const STORE_IS_LAST_TWO_DAYS = 'global/STORE_IS_LAST_TWO_DAYS';

export const SET_PROGRAM_VISIBILITY = 'global/SET_PROGRAM_VISIBILITY';

export const SCROLL_TO_REF = 'global/SCROLL_TO_REF';

export const SCROLL_TO_TOP = 'global/SCROLL_TO_TOP';

export const HANDLE_LEGAL_MODAL = 'global/HANDLE_LEGAL_MODAL';

export const SET_RECIPE_QUERY_OBJ = 'global/SET_RECIPE_QUERY_OBJ';

export const TOGGLE_CREATE_EXERCISE_MODAL = 'global/TOGGLE_CREATE_EXERCISE_MODAL';

export const TOGGLE_CREATE_USER_MODAL = 'global/TOGGLE_CREATE_USER_MODAL';

export const TOGGLE_CREATE_WORKOUT_MODAL = 'global/TOGGLE_CREATE_WORKOUT_MODAL';

export const TOGGLE_EXERCISE_HISTORY_MODAL = 'global/TOGGLE_EXERCISE_HISTORY_MODAL';

export const TOGGLE_WORKOUT_PROGRAM_MODAL = 'global/TOGGLE_WORKOUT_PROGRAM_MODAL';

export const DELETE_USER_AND_RELATED_DATA = 'global/DELETE_USER_AND_RELATED_DATA';

export const DELETE_USER_AND_RELATED_DATA_SUCCESS = 'global/DELETE_USER_AND_RELATED_DATA_SUCCESS';

export const DELETE_USER_AND_RELATED_DATA_ERROR = 'global/DELETE_USER_AND_RELATED_DATA_ERROR';

export const TOGGLE_CONFIRM_MODAL = 'global/TOGGLE_CONFIRM_MODAL';

export const TOGGLE_WORKOUT_TIMER_RUNNING = 'global/TOGGLE_WORKOUT_TIMER_RUNNING';

export const SET_WORKOUT_IN_PROGRESS = 'global/SET_WORKOUT_IN_PROGRESS';

export const RESET_TIMER = 'global/RESET_TIMER';

export const SET_WORKOUT_TO_GLOBAL_STATE = 'global/SET_WORKOUT_TO_GLOBAL_STATE';

export const GET_SCHEDULED_NOTIFICATIONS = 'global/GET_SCHEDULED_NOTIFICATIONS';
export const GET_SCHEDULED_NOTIFICATIONS_SUCCESS = 'global/GET_SCHEDULED_NOTIFICATIONS_SUCCESS';
export const GET_SCHEDULED_NOTIFICATIONS_ERROR = 'global/GET_SCHEDULED_NOTIFICATIONS_ERROR';

export const OPEN_REVIEW_MODAL = 'global/OPEN_REVIEW_MODAL'

export const SHOW_TOP_HEADER = 'global/SHOW_TOP_HEADER';

export const HANDLE_ACHIEVEMENT_MODAL = 'global/HANDLE_ACHIEVEMENT_MODAL';