import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { Button, Modal, Input, Alert, Select } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createExercise, updateExercise, handleCreateOrEditExercise } from '../../ducks/Exercise/actions';
import { CLEAR_EDIT_EXERCISE } from '../../ducks/Exercise/constants';

const Option = Select.Option;

class CreateExerciseModal extends React.Component {
 
  componentDidUpdate(prevProps) {
    if(prevProps.createExerciseSuccess !== this.props.createExerciseSuccess && this.props.createExerciseSuccess) {
      this.props.toggleCreateExerciseModal(false);
      this.props.clearEditExercise();
      toast.success(`Exercise has been successfully ${this.props.isExerciseEdit ? 'updated' : 'created'}!`);
    }
  }

  renderInputField = (exercise, fieldName, label, type = 'text') => {
    return (
        <div className="form-section">
          <p className="label">*{label}</p>
          {
            <Input
              type={type}
              onChange={(e) => this.props.handleCreateOrEditExercise(null, e, exercise, fieldName)}
              value={
                exercise && exercise[fieldName]
                  ? exercise[fieldName]
                  : ""
              }
            />
          }
        </div>
    );
  }

  // renderSelectValues = (exercise, fieldName) => {
  //   if (exercise && exercise[fieldName]) {
  //     // Map to extract the _id property from each object
  //     const selectedIds = exercise[fieldName].map(category => category._id);
      
  //     return selectedIds;
  //   }
  // }

  renderOptions = (data) => {
    const options = data;
    const renderedOptions = options.map((item) => (
      <Option className='select-option' key={item._id} value={item._id}>
        {item.name || item.mainMuscle}
      </Option>
    ));
    return renderedOptions;
  };

  renderMultipleSelectField = (exercise, fieldName, label, data) => {

    const selectedIds = exercise[fieldName]?.map(item => 
      typeof item === 'object' && item._id ? item._id : item
    ) || [];
    
    return (
        <div className="form-section">
          <p className="label">*{label}</p>
          {
            <Select
              mode="multiple"
              style={{ width: '100%' }} 
              allowClear
              value={selectedIds}
              onChange={(value) => this.props.handleCreateOrEditExercise(data, value, exercise, fieldName)}
            >
              {this.renderOptions(data)}
            </Select>
          }
        </div>
    );
  }

  renderSelectField = (exercise, fieldName, label, options) => {
  
    let selectValue = '';

    // Check if the exercise field exists and has a value
    if (exercise && exercise[fieldName]) {
      if (Array.isArray(exercise[fieldName])) {
        selectValue = typeof exercise[fieldName][0] === 'object' ? exercise[fieldName][0]._id : exercise[fieldName][0];
      } else {
        selectValue = exercise[fieldName];
      }
    }
    
    return (
        <div className="form-section">
          <p className="label">*{label}</p>
          <Select
            style={{ width: '100%' }} 
            allowClear
            value={selectValue}
            onChange={(value) => this.props.handleCreateOrEditExercise(options, value, exercise, fieldName)}
          >
            {options.map((option) => (
              <Option key={option.key || option._id} value={option.value || option._id}>
                {option.text || option.mainMuscle}
              </Option>
            ))}
          </Select>
        </div>
    );
  }

  handleCreateOrUpdateExercise = () => {
    if(this.props.isExerciseEdit){
      this.props.updateExercise({ ...this.props.editExercise  })
    } else if(!this.props.isExerciseEdit){
      this.props.createExercise({ ...this.props.editExercise  })
    }
  }

  getCorrectMuscles = (data, type) => {
    let muscles = [...data];
    
    if (type === 'all') {
      return muscles;
    }

    return muscles.filter(muscle => muscle.muscleType === type);
  }

  render(){
    const { editExercise, createExerciseErr, exerciseCategories, muscles } = this.props;

    const difficultyOptions = [
      {key: 1, value: 'beginner', text: 'Beginner'},
      {key: 2, value: 'intermediate', text: 'Intermediate'},
      {key: 3, value: 'advanced', text: 'Advanced'}
    ];
    
    return (
      <Modal
        title={this.props.isExerciseEdit ? 'Update Exercise' : 'Create Exercise'}
        visible={this.props.createExerciseModalVisible}
        onCancel={() => this.props.toggleCreateExerciseModal(false)}
        footer={null}
        centered={true}
      >
        <div className='modal'>
          {this.renderInputField(editExercise, 'name', 'Name')}
          {this.renderInputField(editExercise, 'instructions', 'Instruction')}
          {this.renderMultipleSelectField(editExercise, 'categories', 'Category', exerciseCategories)}
          {this.renderSelectField(editExercise, 'difficulty', 'Difficulty', difficultyOptions, null)}
          {this.renderSelectField(editExercise, 'primaryMuscles', 'Primary Muscles', this.getCorrectMuscles(muscles, 'primary'))}
          {this.renderMultipleSelectField(editExercise, 'secondaryMuscles', 'Secondary muscles', this.getCorrectMuscles(muscles, 'all'))}
          {/* {this.renderMultipleSelectField(editExercise, 'equipment', 'Equipment')} */}
          {/* {this.renderMultipleSelectField(editExercise, 'variations', 'Variations')} */}
          {this.renderInputField(editExercise, 'videos', 'Videos')}
          {this.renderInputField(editExercise, 'images', 'Images')}
        </div>
        <div className="week-buttons text-right">
          <Button
            onClick={() => this.handleCreateOrUpdateExercise()}
            type="primary"
            className="save-btn-modal"
            loading={this.props.createNewExerciseLoading}
            disabled={this.props.createNewExerciseLoading || (createExerciseErr && createExerciseErr.errors &&createExerciseErr.errors.length)}
          >
            {this.props.isExerciseEdit ? 'Update' : 'Create'}
          </Button>
          <Button type="secondary" className="save-btn-modal" onClick={() => this.props.toggleCreateExerciseModal(false)}>Cancel</Button>
        </div>
        {createExerciseErr && (
          <Alert
            type="error"
            message={
              createExerciseErr.errors &&
              Object.values(createExerciseErr.errors).map((err, key) => (
                <p key={key}>{err.msg}</p>
              ))
            }
          />
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    createExerciseLoading: state.exercises.createExerciseLoading,
    createExerciseSuccess: state.exercises.createExerciseSuccess,
    createExerciseErr: state.exercises.createExerciseErr,
    editExercise: state.exercises.editExercise,
    exercises: state.exercises.exercises,
    exerciseOptions: state.exercises.exerciseOptions,
    createExerciseModalVisible: state.global.createExerciseModalVisible
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createExercise: (exercise) => dispatch(createExercise(exercise)),
    updateExercise: (exercise) => dispatch(updateExercise(exercise)),
    handleCreateOrEditExercise: (exerciseOptions, value, exercise, field) => dispatch(handleCreateOrEditExercise(exerciseOptions, value, exercise, field)),
    clearEditExercise: () => dispatch({
      type: CLEAR_EDIT_EXERCISE
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateExerciseModal);