import { put, takeLatest } from 'redux-saga/effects';
import { 
  SEND_CONTACT_TO_ZAPIER_REQUEST, 
  SCROLL_TO_TOP, SCROLL_TO_REF, 
  DELETE_USER_AND_RELATED_DATA,
  GET_SCHEDULED_NOTIFICATIONS
} from './constants';
import { 
  zapierEmailSuccess, 
  zapierEmailError, 
  getScheduledNotificationsSuccess, 
  getScheduledNotificationsError 
} from './actions';
import { call } from "redux-saga/effects";
import { fetchData } from '../api';

export function* zapierEmail ({data}) {
  try {
    const emailedContact = yield fetch(`https://hooks.zapier.com/hooks/catch/${process.env.REACT_APP_ZAPIER_CONTACT}/oivz648`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
    const zapierGoogleSheet = yield fetch(`https://hooks.zapier.com/hooks/catch/${process.env.REACT_APP_ZAPIER_CONTACT}/osyth89`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
    yield put(zapierEmailSuccess('Thanks for your message, I will get back to you as soon as possible!'));
  } catch (err) {
    yield put(zapierEmailError(err));
  }
}

export function* scrollToTop(){
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

export function* scrollToRef({ref}){
  [ref].current.scrollIntoView({ behavior: 'smooth' });
}

export function* deleteUserAndRelatedData({userId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/users/${userId}`;
    yield call(fetchData, url, config);
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* getScheduledNotifications(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/scheduled-notification/all`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let notifications = yield call(fetchData, url, config);
    yield put(getScheduledNotificationsSuccess(notifications));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getScheduledNotificationsError(error));
  }
}

export default function* globalSaga() {
  yield takeLatest(SEND_CONTACT_TO_ZAPIER_REQUEST, zapierEmail);
  yield takeLatest(SCROLL_TO_TOP, scrollToTop);
  yield takeLatest(SCROLL_TO_REF, scrollToRef);
  yield takeLatest(DELETE_USER_AND_RELATED_DATA, deleteUserAndRelatedData);
  yield takeLatest(GET_SCHEDULED_NOTIFICATIONS, getScheduledNotifications);
}