import {
  CHECK_ONE_SIGNAL_STATUS,
  CHECK_ONE_SIGNAL_STATUS_SUCCESS,
  CREATE_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_SUCCESS, UPDATE_ALL_NOTIFICATION_INFO,
  UPDATE_NOTIFICATION_INFO
} from "./constants";

export const checkOneSignal = (userEmail) => {
  return {
    type: CHECK_ONE_SIGNAL_STATUS,
    userEmail
  }
};

export const initializeOneSignal = (app, email, emailHash) => {
  const OneSignal = window.OneSignal || [];
  OneSignal.push(() => {
    OneSignal.init({
      appId: app && app.appId,
      allowLocalhostAsSecureOrigin: true
    });
    OneSignal.setEmail(email, {
      emailAuthHash: emailHash && emailHash.hash,
    }).then(() => {
      OneSignal.on('customPromptClick', function () {
        setTimeout(() => {
          OneSignal.getUserId(async (deviceId) => {
            if (deviceId) {
              console.log("Device Id", deviceId)
            }
          });
        }, 5000)
      });
    }).catch(err => console.log(err))

  });
  return {
    type: CHECK_ONE_SIGNAL_STATUS_SUCCESS,
  }
};

export const createNotification = (input) => {
  return {
    type: CREATE_NOTIFICATION,
    input
  }
};

export const getAllUserNotifications = (userEmail) => {
  return {
    type: GET_ALL_NOTIFICATIONS,
    userEmail
  }
};

export const getAllNotificationsSuccess = (payload) => {
  return {
    type: GET_ALL_NOTIFICATIONS_SUCCESS,
    payload
  }
};

export const getAllNotificationsError = (error) => {
  return {
    type: GET_ALL_NOTIFICATIONS_ERROR,
    payload: error
  }
};

export const updateNotificationReadStatus = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_INFO,
    payload
  }
};

export const updateAllNotificationReadStatus = (userNotifications) => {
  return {
    type: UPDATE_ALL_NOTIFICATION_INFO,
    userNotifications
  }
};