import React from 'react';
import { notification } from 'antd';

class NotificationAlert extends React.Component {

  returnNotification = (args) => {
    if(args.type === 'success'){
      notification.success(args);
    } else if(args.type === 'error'){
      notification.error(args);
    } else if(args.type === 'info'){
      notification.info(args);
    } else if(args.type === 'warn'){
      notification.warn(args);
    } 
    return null;
  }

  render(){
    const args = {
      type: this.props.type,
      placement: 'topRight',
      message: this.props.message,
      description: this.props.description,
      duration: 3,
      onClose: this.props.clearAlert
    };
    return(
      this.returnNotification(args)
    )
  }
}

export default NotificationAlert;