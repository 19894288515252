import React from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components/Loading'
import { getWorkoutPlanLibrary } from '../../ducks/WorkoutPlan/actions';
import AdminWorkoutPlanLibraryPage from '../../presentational/AdminWorkoutPlanPage';
import { TOGGLE_WORKOUT_PROGRAM_MODAL, TOGGLE_CREATE_WORKOUT_MODAL } from '../../ducks/Global/constants';

class AdminWorkoutPlanLibrary extends React.Component {

  componentDidMount(){
    this.props.workoutPlans.length === 0 && this.props.getWorkoutPlanLibrary();
  }

  render(){
    return(
     <div className='workouts-page'>
      {this.props.workoutPlans.length > 0 ? 
      <AdminWorkoutPlanLibraryPage {...this.props} /> : 
      <Loading />
      }
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    workoutPlans: state.workoutPlan.workoutPlans,
    workoutPlansLoading: state.workoutPlan.workoutPlansLoading,
    workoutPlansError: state.workoutPlan.workoutPlansError,
    workoutProgramModalVisible: state.global.workoutProgramModalVisible,
    createWorkoutModalVisible: state.global.createWorkoutModalVisible,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getWorkoutPlanLibrary: () => dispatch(getWorkoutPlanLibrary()),
    toggleWorkoutProgramModal: (value) => dispatch({
      type: TOGGLE_WORKOUT_PROGRAM_MODAL,
      value
    }),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminWorkoutPlanLibrary);