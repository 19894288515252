import React from 'react';
import { connect } from 'react-redux'; 
import StatsPage from '../../presentational/StatsPage';
import { getUserBfMeasurements } from '../../ducks/Measurements/actions';
import { getCurrentProfile } from "../../ducks/Profile/actions";
import { Loading } from '../../components/Loading';
import { SplashPage } from '../../presentational/SplashPage';
import { HANDLE_PRICING_MODAL } from '../../ducks/Global/constants';

class Stat extends React.Component {

  componentDidMount(){
    !this.props.isAdmin && this.props.getUserBfMeasurements();
    !this.props.profile && this.props.getCurrentProfile();
  }

  render(){
    const { userBodyFatMeasurements, profile, pricingModalOpen } = this.props;
    const notLoading = userBodyFatMeasurements && profile;
    return(
      <div className='home-page'>
        {this.props.user && this.props.user.isPro && notLoading ?
        <StatsPage {...this.props} /> : !notLoading ? 
        <Loading /> :
        <SplashPage 
          page={'progress'} 
          layoutBackground={true} 
          profile={profile} 
          pricingModalOpen={pricingModalOpen} 
          handlePricingModal={this.props.handlePricingModal} 
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userBodyFatMeasurements: state.measurements.userBodyFatMeasurements,
    profile: state.profile.profile,
    tableMode: state.global.tableMode,
    pricingModalOpen: state.global.pricingModalOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen
      });
		},
  };  
}

export default connect(mapStateToProps, mapDispatchToProps)(Stat);