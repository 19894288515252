import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {verifyUser} from "../../ducks/Auth/actions";
import { useHistory } from 'react-router-dom';
import "../../presentational/ProfilePage/index.css";
import {LOGOUT} from "../../ducks/Auth/constants";
import {Button, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Verification = (props) => {
  const [error, setError] = useState();
  const regToken = props.match.params.regToken;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isUserVerified,
    errors,
    loading = false
  } = useSelector(store => store.auth);

  useEffect(() => {
    if(regToken) {
      dispatch(verifyUser(regToken))
    }
  }, [regToken]);

  useEffect(() => {
    if(isUserVerified) {
      props.history.push('/dashboard/home');
    }
  }, [isUserVerified]);

  useEffect(() => {
    if(errors && errors.length > 0) {
      setError(errors.map((error) => error.msg)[0]);
    }
  }, [errors]);

  useEffect(() => {
    if(error) {
      dispatch({
        type: LOGOUT
      });
    }
  }, [error]);

  const handleRedirect = () => {
    history.push('/');
  };

  return (
    <div className='user-verification-page'>
      <div className="user-verification-card">
      {
        loading ? <Spin indicator={antIcon} />
          :
        error ?
          <div>
            <p className='main-page-subtitle'>{error}</p>
            <Button className='btn' type='primary' onClick={handleRedirect}>Back to login</Button>
          </div>
          :
          <p>User is successfully verified. Redirecting to Dashboard ...</p>
      }
      </div>
    </div>
  )
};

export default Verification;