import React from 'react';
import { Progress } from 'antd';
import './index.scss';

export const MacroCard = (props) => {

  const renderLabel = (nutrition, className) => {
    if(typeof(nutrition) === 'number'){
      return <p className={className}>Calories</p>
    } else if(nutrition.displayName === 'Carbohydrates'){
      return <p className={className}>Carbs</p>
    } else {
      return <p className={className}>{nutrition.displayName}</p>
    }
  }

  const renderStat = (nutrition) => {
    return (
      <div>
        <p style={{ fontWeight: "500", marginBottom: '3px', color: '#000000' }}>
          {typeof(nutrition) === "number" ? nutrition : `${nutrition.grams} g`}
        </p>
        {renderLabel(nutrition, 'blog-card-category macro-label')}
      </div>
    );
  };

  const renderPercentageLabel = () => {
    return(
      typeof(props.data.value) !== "number" && <p style={{fontSize: '14px', fontWeight: '500'}}>{Math.round(props.data.value.percentage)}%</p>
    )
  }

  const renderStatNew = (nutrition) => {
    return (
      <>
        <div className='flex baseline'>
          <p className='macro-number'>{typeof(nutrition) === "number" ? nutrition : `${nutrition.grams}`}</p>
          <p className='text-suffix'>{typeof(nutrition) === "number" ? '' : 'g'}</p>
        </div>
        {renderLabel(nutrition, 'macro-label-bottom')}
      </>
    );
  };

  return(
    <>
      <div className={`${ props.profile && !props.profile.fitbitToken || props.history.location.pathname === '/dashboard/coach/meal-plan' ? 'justify-center home-macro-card' : ''} flex card-content flex-center-aligned`}>
        {props.profile && props.profile.fitbitToken && props.history.location.pathname !== '/dashboard/coach/meal-plan' ?
        <div>{renderStatNew(props.data.value)}</div> :
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#2761f1', 
            '100%': '#FF0066',
          }}
          status="active"
          percent={props.data.value && props.data.value.percentage || 100}
          format={() => renderStat(props.data.value)}
        />}
      </div>
    </>
  )
}