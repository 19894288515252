import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  CLEAR_PROFILE,
  SAVE_PROFILE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_ERROR,
  GET_NUTRITION,
  GET_NUTRITION_SUCCESS,
  GET_NUTRITION_ERROR,
  SAVE_NUTRITION,
  SAVE_NUTRITION_SUCCESS,
  SAVE_NUTRITION_ERROR,
  SAVE_NUTRITION_LOCAL,
  SAVE_PROFILE_IGNORE_NUTRITION,
  SET_NUTRITION_FROM_PROFILE_UPDATE,
  GET_ALL_ONSITE_CLIENTS,
  GET_ALL_ONSITE_CLIENTS_SUCCESS,
  GET_ALL_ONSITE_CLIENTS_ERROR,
  GET_FITBIT_NUTRITION,
  GET_FITBIT_NUTRITION_SUCCESS,
  GET_ALL_NUTRITION,
  GET_ALL_NUTRITION_SUCCESS,
  GET_ALL_NUTRITION_ERROR,
  GET_USER_FITBIT_LOGS,
  GET_USER_FITBIT_LOGS_SUCCESS,
  GET_USER_FITBIT_LOGS_ERROR,
  CREATE_USER_MEAL_PLAN,
  CREATE_USER_MEAL_PLAN_SUCCESS,
  CREATE_USER_MEAL_PLAN_ERROR,
  CREATE_WEEKLY_MEAL_PLAN,
  CREATE_WEEKLY_MEAL_PLAN_SUCCESS,
  CREATE_WEEKLY_MEAL_PLAN_ERROR,
  GET_EQUIVALENT_RECIPES,
  GET_EQUIVALENT_RECIPES_SUCCESS,
  GET_EQUIVALENT_RECIPES_ERROR,
  SWAP_MEAL_PLAN_RECIPE,
  SWAP_MEAL_PLAN_RECIPE_SUCCESS,
  SWAP_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_FOOD_IN_MEAL_PLAN,
  UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS,
  UPDATE_FOOD_IN_MEAL_PLAN_ERROR,
  GET_ALTERNATIVE_FOODS,
  GET_ALTERNATIVE_FOODS_SUCCESS,
  GET_ALTERNATIVE_FOODS_ERROR,
  UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_ASSESSMENT_PROFILE,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_ERROR,
} from "./constants";

const initialState = {
  profile: null,
  loading: null,
  error: null,
  nutrition: null,
  successMessage: null,
  onsiteClients: null,
  loadingOnsiteClients: false,
  onsiteClientsError: null,
  fitbitNutrition: null,
  fitbitNutritionLoading: false,
  allUserNutrition: null,
  allUserNutritionLoading: false,
  allUserNutritionError: null,
  fitbitLogs: null,
  fitbitLogsLoading: false,
  fitbitLogsError: null,
  suggesticMealPlan: null,
  suggesticMealPlanLoading: false,
  suggesticMealPlanError: null,
  createNewUser: null,
  createNewUserLoading: false,
  createNewUserError: null,
  createNewUserSuccess: false,
  equivalentRecipesLoading: null,
  equivalentRecipesError: null,
  swapMealPlanLoading: false,
  swapMealPlanError: null,
  alternativeFoods: null,
  updateFoodLoading: {},
};

function profile(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
        loading: false,
        successMessage: null
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        loading: false,
        profile: null,
      };
    case SAVE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_PROFILE_IGNORE_NUTRITION:
      return {
        ...state,
        loading: true
      };
    case SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: state.profile ? payload.profile : payload,
        error: null,
        successMessage: payload.isFromSettings ? null : 'Your macros have been updated.',
        foodRestrictions: state.foodRestrictions
      };
    case SAVE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        successMessage: null
      };
    case SAVE_NUTRITION:
      return {
        ...state,
        loading: true,
      };
    case SAVE_NUTRITION_LOCAL:
      return {
        ...state,
        nutrition: payload,
      };
    case SAVE_NUTRITION_SUCCESS:
      return {
        ...state,
        loading: false,
        nutrition: payload,
        error: null,
      };
    case SAVE_NUTRITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_NUTRITION:
      return {
        ...state,
        loading: true,
      };
    case GET_NUTRITION_SUCCESS:
      return {
        ...state,
        loading: false,
        nutrition: payload,
        error: null,
      };
    case GET_NUTRITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_NUTRITION_FROM_PROFILE_UPDATE:
      return {
        ...state,
        nutrition: payload
      }
    case GET_ALL_ONSITE_CLIENTS:
      return {
        ...state,
        loadingOnsiteClients: true,
        onsiteClients: null,
        error: null,
      };
    case GET_ALL_ONSITE_CLIENTS_SUCCESS:
      return {
        ...state,
        loadingOnsiteClients: false,
        onsiteClients: action.payload
      };
    case GET_ALL_ONSITE_CLIENTS_ERROR:
      return {
        ...state,
        loadingOnsiteClients: false,
        onsiteClientsError: action.payload
      }
    case GET_FITBIT_NUTRITION:
      return {
        ...state,
        fitbitNutrition: null,
        fitbitNutritionLoading: true
      }
    case GET_FITBIT_NUTRITION_SUCCESS:
      return {
        ...state,
        fitbitNutrition: action.payload,
        fitbitNutritionLoading: false
      }
    case GET_ALL_NUTRITION:
      return {
        ...state,
        allUserNutritionLoading: true,
      };
    case GET_ALL_NUTRITION_SUCCESS:
      return {
        ...state,
        allUserNutritionLoading: false,
        allUserNutrition: payload,
        allUserNutritionError: null,
      };
    case GET_ALL_NUTRITION_ERROR:
      return {
        ...state,
        allUserNutritionLoading: false,
        allUserNutritionError: payload,
      };
    case GET_USER_FITBIT_LOGS:
      return {
        ...state,
        fitbitLogsLoading: true
      };
    case GET_USER_FITBIT_LOGS_SUCCESS:
      return {
        ...state,
        fitbitLogsLoading: false,
        fitbitLogs: payload,
        fitbitLogsError: null,
      };
    case GET_USER_FITBIT_LOGS_ERROR:
      return {
        ...state,
        fitbitLogsLoading: false,
        fitbitLogsError: payload,
      };

    case CREATE_USER_MEAL_PLAN:
    case CREATE_WEEKLY_MEAL_PLAN:
      return {
        ...state,
        suggesticMealPlan: null,
        suggesticMealPlanLoading: true,
        suggesticMealPlanError: null
      };

    case CREATE_USER_MEAL_PLAN_SUCCESS:
    case CREATE_WEEKLY_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        suggesticMealPlan: payload,
        suggesticMealPlanLoading: false
      };

    case CREATE_USER_MEAL_PLAN_ERROR:
    case CREATE_WEEKLY_MEAL_PLAN_ERROR:
      return {
        ...state,
        suggesticMealPlanLoading: false,
        suggesticMealPlanError: action.err,
      };

    case GET_EQUIVALENT_RECIPES:
      return {
        ...state,
        equivalentRecipesLoading: { id: action.recipeId, loading: true },
        equivalentRecipesError: null
      };

    case GET_EQUIVALENT_RECIPES_SUCCESS:
      return {
        ...state,
        profile: payload,
        equivalentRecipesLoading: { id: action.recipeId, loading: false }
      };

    case GET_EQUIVALENT_RECIPES_ERROR:
      return {
        ...state,
        equivalentRecipesLoading: { id: action.recipeId, loading: false },
        equivalentRecipesError: action.error
      };

    case SWAP_MEAL_PLAN_RECIPE:
      return {
        ...state,
        swapMealPlanLoading: { id: action.newRecipeId, loading: true },
        swapMealPlanError: null
      };

    case SWAP_MEAL_PLAN_RECIPE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, currentMealPlan: payload },
        swapMealPlanLoading: { id: action.newRecipeId, loading: false }
      };

    case SWAP_MEAL_PLAN_RECIPE_ERROR:
      return {
        ...state,
        swapMealPlanLoading: { id: action.newRecipeId, loading: false },
        swapMealPlanError: action.error
      };

    case UPDATE_FOOD_IN_MEAL_PLAN:
      const index = {
        foodIndex: action.foodData.foodData.foodIndex,
        mealIndex: action.foodData.foodData.mealIndex,
      };

      const isPhoto = action.foodData.foodData.photoIndex === 0 ? 0 : action.foodData.foodData.photoIndex || action.foodData.foodData.photo;
      return {
        ...state,
        updateFoodLoading: { key: index, loading: true, isPhoto: isPhoto != null },
      };

    case UPDATE_FOOD_IN_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        updateFoodLoading: { ...state.updateFoodLoading, loading: false },
        profile: { ...state.profile, customMealPlan: payload.mealPlan },
        error: null,
      };

    case UPDATE_FOOD_IN_MEAL_PLAN_ERROR:
      return {
        ...state,
        updateFoodLoading: { ...state.updateFoodLoading, loading: false },
        error: payload,
      };

    case UPDATE_FOOD_IN_MEAL_PLAN_LOADING_SUCCESS:
      return {
        ...state,
        updateFoodLoading: { ...state.updateFoodLoading, loading: false },
      }

    case GET_ALTERNATIVE_FOODS:
      return {
        ...state,
        loading: true,
        alternativeFoods: null,
      };

    case GET_ALTERNATIVE_FOODS_SUCCESS:
      return {
        ...state,
        loading: false,
        alternativeFoods: payload.foods,
        error: null,
      };

    case GET_ALTERNATIVE_FOODS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_USER_PROFILE:
      return {
        ...state,
        updatingUserInfo: true,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updatingUserInfo: false,
        profile: {
          ...state.profile,
          user: {
            ...(state.profile && state.profile.user || {}),
            ...payload
          }
        },
        error: null,
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updatingUserInfo: false,
        error: payload,
      };
    case UPDATE_ASSESSMENT_PROFILE:
      return {
        ...state,
        loading: true
      };
    case CREATE_NEW_USER:
      return {
        ...state,
        createNewUser: null,
        createNewUserLoading: true,
        createNewUserSuccess: false,
        createNewUserError: null
      };
    case CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createNewUser: payload,
        createNewUserLoading: false,
        createNewUserSuccess: true
      };
    case CREATE_NEW_USER_ERROR:
      return {
        ...state,
        createNewUserLoading: false,
        createNewUserSuccess: false,
        createNewUserError: action.error,
      };  
    default:
      return state;
  }
}

export default profile;