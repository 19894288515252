import {
  GET_CARB_CYCLING_PLAN,
  GET_CARB_CYCLING_PLAN_SUCCESS,
  GET_CARB_CYCLING_PLAN_ERROR,
  CREATE_CARB_CYCLING_PLAN,
  CREATE_CARB_CYCLING_PLAN_SUCCESS,
  CREATE_CARB_CYCLING_PLAN_ERROR,
  HANDLE_CARB_CLYCLING_MODAL_OPEN,
  HANDLE_IS_CARB_CYCLING_PLAN_CREATED
} from "./constants";

const initialState = {
  carbCyclingPlan: null,
  carbCyclingPlanLoading: false,
  carbCyclingPlanErr: false,
  isOpen: false,
  isCarbCyclingPlanCreated: false
};

function carbCycling(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CARB_CYCLING_PLAN:
      return {
        ...state,
        carbCyclingPlanErr: false,
        carbCyclingPlanLoading: true
      };
    case GET_CARB_CYCLING_PLAN_SUCCESS:
      return {
        ...state,
        carbCyclingPlan: payload,
        carbCyclingPlanLoading: false
      };
    case GET_CARB_CYCLING_PLAN_ERROR:
      return {
        ...state,
        carbCyclingPlanLoading: true,
        carbCyclingPlanErr: action.err,
        carbCyclingPlanLoading: false
      };
    case CREATE_CARB_CYCLING_PLAN:
      return {
        ...state,
        carbCyclingPlanLoading: true,
        carbCyclingPlanErr: false
      };
    case CREATE_CARB_CYCLING_PLAN_SUCCESS:
      return {
        ...state,
        carbCyclingPlan: payload,
        carbCyclingPlanLoading: false,
        carbCyclingPlanErr: false,
        isCarbCyclingPlanCreated: true
      };
    case CREATE_CARB_CYCLING_PLAN_ERROR:
      return {
        ...state,
        carbCyclingPlanLoading: false,
        carbCyclingPlanErr: action.err
      };
    case HANDLE_CARB_CLYCLING_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.isOpen
      };
    case HANDLE_IS_CARB_CYCLING_PLAN_CREATED:
      return {
        ...state,
        isCarbCyclingPlanCreated: false
      }
    default:
      return state;
  }
}

export default carbCycling;