export const faqData = {
  programFaq: [
    {
      key: '1',
      header: <p style={{ fontSize: '16px', fontWeight: '500', marginLeft: '15px' }}>How long is the start-up process?</p>,
      text: (
        <p style={{ fontSize: '16px', lineHeight: '30px', color: 'black' }}>
          On average, the start-up time is 2-3 business days.

          After you’ve completed the assessment, accessed your dashboard, and completed the to-do's, I'll start crafting your personalized plan based on your preferences. This process takes about 2 days. When your plan is ready, you will receive an email confirmation.

          You should aim to start your plan on Monday. (Starting on a Monday works best for most people, but isn’t mandatory if you’d like to start on a different day.)
        </p>
      ),
    },
    {
      key: '2',
      header: <p style={{ fontSize: '16px', fontWeight: '500', marginLeft: '15px' }}>Can I make this work on a busy schedule?</p>,
      text: (
        <p style={{ fontSize: '16px', lineHeight: '30px', color: 'black' }}>
         Absolutely. Waiting for the perfect time could be an ever lasting wait. When building your program, I will consider your schedule and preferences.
        </p>
      ),
    },
    {
      key: '3',
      header: <p style={{ fontSize: '16px', fontWeight: '500', marginLeft: '15px' }}>What if I travel regularly for work?</p>,
      text: (
        <p style={{ fontSize: '16px', lineHeight: '30px', color: 'black' }}>
         If you travel regularly, you can add that to your assessment answers. I will be happy to add some options in your plan when you don't have access to equipment.
        </p>
      ),
    },
    {
      key: '4',
      header: <p style={{ fontSize: '16px', fontWeight: '500', marginLeft: '15px' }}>How does your refund process work?</p>,
      text: (
        <p style={{ fontSize: '16px', lineHeight: '30px', color: 'black' }}>
          My refund policy is straight forward. If have logged all your workouts on the app for the first month, and you’re unhappy with your progress, I’ll refund you in full.
          However, I am certain that after following the program for several weeks, you will be thrilled with the results. All I ask is that you give me a chance to make it work.
        </p>
      ),
    },
    {
      key: '5',
      header: <p style={{ fontSize: '16px', fontWeight: '500', marginLeft: '15px' }}>Will there be new features and improvements added to the app?</p>,
      text: (
        <p style={{ fontSize: '16px', lineHeight: '30px', color: 'black' }}>
          Absolutely! We are dedicated to innovation and committed to providing you with the best fitness experience. Our team is continuously working on new features and improvements to keep your fitness journey fresh and exciting. Stay tuned for regular updates!
        </p>
      ),
    },
  ]
}