import {
  UPDATE_NUTRITION_PREFERENCES,
  UPDATE_NUTRITION_PREFERENCES_SUCCESS,
  UPDATE_NUTRITION_PREFERENCES_ERROR,
  GET_NUTRITION_PREFERENCES,
  GET_NUTRITION_PREFERENCES_SUCCESS,
  GET_NUTRITION_PREFERENCES_ERROR
} from "./constants";

export function getNutritionPreferences() {
  return {
    type: GET_NUTRITION_PREFERENCES
  };
}

export function getNutritionPreferencesSuccess(payload) {
  return {
    type: GET_NUTRITION_PREFERENCES_SUCCESS,
    payload
  };
}

export function getNutritionPreferencesError(err) {
  return {
    type: GET_NUTRITION_PREFERENCES_ERROR,
    err
  };
}

export function updateNutritionPreferences(restrictionsArr) {
  return {
    type: UPDATE_NUTRITION_PREFERENCES,
    restrictionsArr
  };
}

export function updateNutritionPreferencesSuccess(payload) {
  return {
    type: UPDATE_NUTRITION_PREFERENCES_SUCCESS,
    payload
  };
}

export function updateNutritionPreferencesError(err) {
  return {
    type: UPDATE_NUTRITION_PREFERENCES_ERROR,
    err
  };
}