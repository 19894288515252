import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentProfile, updateUserProfile } from "../../ducks/Profile/actions";
import { getUserBfMeasurements } from "../../ducks/Measurements/actions";
import { createUserSetting } from '../../ducks/Setting/actions';
import "../../presentational/ProfilePage/index.css";
import AssessmentProfilePage from "../../presentational/AssessmentProfile";
import { Loading } from "../../components/Loading";
import Toast from "../../components/Toast/Toast";
import { GiTrojanHorse } from "react-icons/gi";

class AssessmentProfile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showToast: this.props.successMessage,
        }
    }
    componentDidMount() {
        this.props.getCurrentProfile();
    }
    render() {
        return (
            <div className="macros-page">
                {/* <ToastFlash successMessage={this.state.showToast}/> */}
                {/* {this.props.successMessage && <Toast type={'success'} message={'🎉 Your assessment data have been updated!'} />} */}
                {this.props.profile ? <AssessmentProfilePage assessmentProfile={this.props.profile} {...this.props} /> : <Loading />}
            </div>
        );
    }
}

const ToastFlash = (props) => {
    useEffect(() => {
      console.log("rerender tost flasy");
    }, [props])
    return( <>
        { props.successMessage ? <Toast type={'success'} message={props.successMessage ?  props.successMessage : '🎉 Your assessment data have been updated!'} /> : null}
    </> );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        profile: state.profile.profile,
        error: state.profile.error,
        loading: state.profile.loading,
        updatingUserInfo: state.profile.updatingUserInfo,
        successMessage: state.profile.successMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentProfile: () => dispatch(getCurrentProfile()),
        getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
        createUserSetting: (setting) => dispatch(createUserSetting(setting)),
        updateUserProfile: (userInfo) => dispatch(updateUserProfile(userInfo))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentProfile);