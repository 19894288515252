import React from 'react';
import { connect } from 'react-redux';
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { getUserWorkouts } from '../../ducks/Workout/actions';
import { Popover } from 'antd';
import moment from 'moment';
import './index.css';

class WorkoutStatsPage extends React.Component {
  constructor(){
    super();
    this.state={
      userWorkouts: [],
      days: [
        {day: 'Mon', checked: false},
        {day: 'Tue', checked: false},
        {day: 'Wed', checked: false},
        {day: 'Thu', checked: false},
        {day: 'Fri', checked: false},
        {day: 'Sat', checked: false},
        {day: 'Sun', checked: false}
      ]
    };
  }

  componentDidMount(){
    this.props.getUserWorkouts(this.props.profile.user._id, true, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userWorkouts !== this.props.userWorkouts) {
      const workouts = this.props.userWorkouts.currentWeek;
  
      const updatedDays = this.state.days.map((day, index) => {
        const workoutsForDay = workouts.filter(workout => {
          const workoutDate = new Date(workout.date);
          return workoutDate.getDay() === (index + 1) % 7;
        });
      
        return {
          ...day,
          checked: workoutsForDay.length > 0,
          workout: workoutsForDay
        };
      });
  
      this.setState({ days: updatedDays });
    }
  }

  workoutDays = () => {
    let days = [...this.state.days];
    return(
      <div className='summary-card flex space-around flex-center-aligned'>
        {days.map((day, i) => 
          <div>
            <div key={i} className='grid-justify-center'>
              {day.checked ? 
              <Popover 
                title={day.workout[0].name}
                trigger="click"
                placement="top"
              >
                <CheckCircleFilled style={{color: '#2bffcc', fontSize: '32px', marginBottom: '5px'}}/> 
              </Popover>
              
              : <CheckCircleOutlined style={{color: 'rgb(240, 242, 245)', fontSize: '32px', marginBottom: '5px'}}/>}
              <p className='home-card-text'>{day.day}</p>
            </div>
          </div>
        )}
      </div>
    )
  }

  render(){
    const month = moment().format('MMMM');
    return (
      <>
        <p className='flex home-card-title'>workouts this week</p>
        <div className='summary-section mb-4'>
          {this.workoutDays()}
          <div className='home-card'>
            <p className='blog-card-category home-card-label'>{`Average Weekly Workouts In ${month}`}</p>
            {this.props.userWorkouts.averageMonth > 0 ? 
            <p className='card-main-text' style={{marginTop: '5px'}}>
              {this.props.userWorkouts.averageMonth.toFixed(2)}
            </p> : <p className='card-main-text mt-5' style={{marginTop: '5px'}}>0</p>}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userWorkouts: state.workouts.userWorkouts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserWorkouts: (clientId, currentWeek, averageMonth) => dispatch(getUserWorkouts(clientId, currentWeek, averageMonth))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutStatsPage);