/* global google */
import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

const LocationInput = (props) => {
    const autoCompleteRef = useRef();
    const locationRef = useRef();
    const [address, setAddress] = useState(props.defaultValue ? props.defaultValue : undefined); //add from props
    const options = {
        zoom: 12,
        maxZoom: 16,
        center: { lat: -37.7837, lng: 144.838 },
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        gestureHandling: "none",
    };
    const handleOnchangeLocation = async (e) => {
        let addressTemp = {
            lat: undefined,
            lng: undefined,
            // location: e.target.value,
            location: locationRef.current.input.value,
        }
        setAddress(locationRef.current.input.value); //set address for input box view purpose only.
        // setAddress(addressTemp.location); //set address for input box view purpose only.
        props.onChange(addressTemp); // set object to main response object in parent compnent.
    }
    const setAddressFromRef = async () => {
        const place = await autoCompleteRef.current.getPlace();
        const addressBefore = locationRef.current.input.value;
        let addressTemp = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            // location: place.formatted_address,
            location: addressBefore,
        };
        props.onChange(addressTemp);
        await setAddress(addressBefore);
        // setAddress(addressTemp.location);

    }
    useEffect(() => {
        const setMap = async () => {
            autoCompleteRef.current = await new window.google.maps.places.Autocomplete(
                document.getElementById(props.id),
                options
            );
            autoCompleteRef.current.addListener("place_changed", async function () {
                /* console.log("A=====>\t", autoCompleteRef.current.value)
                console.log("B=====>\t", locationRef.current.value)
                const place = await autoCompleteRef.current.getPlace();
                const test = await autoCompleteRef.current;
                console.log("c---->",place);
                console.log("D----<>",place.name, place.formatted_address); */
                // console.log("----->>>>>----\t",locationRef.current.input, locationRef.current.input.value)
                setAddressFromRef();
            });
        }
        setMap(); //set reference and listeners
        //added commment
    }, []);
    return (
        <>
            <Input
                ref={locationRef}
                id={props.id}
                value={address}
                onChange={(e) => handleOnchangeLocation(e)}
            />
            {/* <br></br>
            {address} */}
        </>
    );
};
export default LocationInput;