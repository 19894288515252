import React from "react";
import { connect } from "react-redux";
import NutritionPreferencesPage from "../../presentational/NutritionPreferencesPage";
import { getCurrentProfile } from "../../ducks/Profile/actions";
import { getUserBfMeasurements } from "../../ducks/Measurements/actions";
import { getRestrictions, getSuggesticProfile, updateRestrictions } from '../../ducks/Suggestic/actions';
import { Loading } from '../../components/Loading';

class NutritionPreferences extends React.Component {
  componentDidMount() {
    this.props.getSuggesticProfile();
    this.props.getCurrentProfile();
    this.props.getRestrictions();
  }

  render() {
    return (
      <div className='nutrition-preferences'>
        {this.props.suggesticProfile && this.props.foodRestrictions ?
        <NutritionPreferencesPage 
          {...this.props}
          foodRestrictions={this.props.foodRestrictions}
        /> :
        <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggesticProfile: state.suggestic.suggesticProfile,
    profile: state.profile.profile,
    error: state.profile.error,
    loading: state.profile.loading,
    successMessage: state.profile.successMessage,
    foodRestrictions: state.suggestic.restrictions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getUserBfMeasurements: (userId) => dispatch(getUserBfMeasurements(userId)),
    getRestrictions: () => dispatch(getRestrictions()),
    getSuggesticProfile: () => dispatch(getSuggesticProfile()),
    updateRestrictions: (restrictionsArr) => dispatch(updateRestrictions(restrictionsArr))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NutritionPreferences);