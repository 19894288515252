import { Input, Button, Radio, DatePicker, Alert, Badge, Popover, Select, Row, Col, Checkbox, Slider } from 'antd';
import { BsQuestionCircleFill } from "react-icons/bs";
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { PieChart } from 'react-minimal-pie-chart';
import { totalQue } from './questionsObj';
import { BulbFilled } from '@ant-design/icons';
import LocationInput from './LocationInput';
import PasswordField from '../../components/AuthModal/PasswordField';

const { Option } = Select;
const { TextArea } = Input;

// const marks = {
//   0: 'Beginner',
//   50: 'Intermediate',
//   100: 'Advanced',
// };

export const AssessmentQuestions = (props) => {
  const [error, setError] = useState(null);
  const [termsOfWeightTraningexprience, setTermsOfWeightTraningexprience] = useState(null);
  let currentDate = new Date();
  const monthsOptions = [
    { value: 0, text: "January" },
    { value: 1, text: "February" },
    { value: 2, text: "March" },
    { value: 3, text: "April" },
    { value: 4, text: "May" },
    { value: 5, text: "June" },
    { value: 6, text: "July" },
    { value: 7, text: "August" },
    { value: 8, text: "September" },
    { value: 9, text: "October" },
    { value: 10, text: "November" },
    { value: 11, text: "December" }
  ];
  const heigthFtOptions = [
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
  ];
  const heigthInOptions = [
    { value: "0" },
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
    { value: "10" },
    { value: "11" },
  ];
  const [selDate, setSelDate] = useState( props.responseObj.birthday ? new Date(props.responseObj.birthday.value) : null);
  const [selMonth, setSelMonth] = useState(-1);
  const [selDay, setSelDay] = useState(-1,);
  const [selYear, setSelYear] = useState(-1);

  // let startYear = currentDate.getFullYear() - 18;
  let startYear = moment(currentDate).subtract(18, 'years').format("YYYY");
  let endYear = currentDate.getFullYear() - 100;
  let yearsOptions = [];
  for (let i = startYear; i >= endYear; i--)  yearsOptions.push(i);

  const daysInMonth = (month, year) => {
    return new Date(year, parseInt(month) + 1, 0).getDate();
  }
  let days = daysInMonth(selMonth, selYear);
  let daysOptions = [];
  for (let i = 1; i <= days; i++)  daysOptions.push(i);

  const handleMonthChange = async(value) => {
    await setSelMonth(value);
  }

  const handleYearChange = async(value) => {
    await setSelYear(parseInt(value));
  }

  const handleDayChange = async(value) => {
    await setSelDay(value);
  }

  const validateBirthDate = () => {
    // return moment().diff(`${selYear}-${selMonth}-${selDay}`, 'years',false);
    let age = moment().diff(`${selYear}-${selMonth + 1}-${selDay+1}`, 'years',false);
    // console.log("validate age\t", age,`${selYear}-${selMonth+1}-${selDay+1}` );
    return age ? age : 1;
  }

  /* useEffect(()=>{
    if(selDay != -1 && selMonth != -1 && selYear != -1) {
      validateBirthDate();
    }
  }, [selDate, selDay, selMonth, selYear]); */

  useEffect(() => {
    setSelMonth(selDate ? selDate.getMonth() : -1);
    setSelDay(selDate ? selDate.getDate() : -1);
    setSelYear(selDate ? selDate.getFullYear() : -1);
  }, [selDate])

  useEffect(() => {
    const handleDateChange = async () => {
      if (selMonth !== -1 && selDay !== -1 && selYear !== -1) {
        const age = await validateBirthDate();
        if(age >= 18 && age <= 100) {
          let newDate = new Date(selYear, selMonth, selDay);
          if (newDate.getTime() > currentDate.getTime()) {
            newDate = currentDate;
          }
          setSelMonth(newDate ? newDate.getMonth() : -1);
          setSelDay(newDate ? newDate.getDate() : -1);
          setSelYear(newDate ? newDate.getFullYear() : -1);
          props.handleChange(newDate, "birthday");
          setError(null);
        }
        else{
          setError('Age should be higher than 18 and lower than 100');
        }
      }
    }
    if (selMonth !== -1 && selDay !== -1 && selYear !== -1) {
      handleDateChange();
    }
  }, [selMonth, selDay, selYear])

  useEffect(() => {
    setError(null);
    const type =
      props.activeQuestion.type === 'height'
        ? 'heightFt'
        : props.activeQuestion.type;
    if (props.activeQuestion.type === 'weightTraningexprience') {
      setError(null);
    } else if (!props.responseObj[type]) {
      setError('All fields are required');
    }
  }, [props.activeQuestion.type]);

  const checkIfDisabled = () => {
    return Boolean(error);
  };

  const renderQuestion = () => {
    if (props.activeQuestion.hasOwnProperty('component')) {
      return props.activeQuestion.component
        .filter((item) => {
          if (item.units) {
            return item.units === props.units;
          }
          return true;
        })
        .map((component) => {
          return component.render.map((render) => {
            if (component.type === 'Input') {
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type}</p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover style={{ display: !render.popover && 'none' }} placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div
                    className="flex flex-center-aligned"
                    style={{ marginBottom: 10 }}
                  >
                    <Input
                      type={render.inputType ? render.inputType : 'text'}
                      className={render.className}
                      key={render.type}
                      autoFocus={render.autoFocus}
                      maxLength={render.maxLength}
                      onChange={(e) => {
                        props.handleChange(e, render.value);
                        setError(
                          render.validate(
                            e.target.value,
                            props.units,
                            render.value === 'goalWeight'
                              ? props.responseObj.byWhen
                              : render.value === 'heightFt'
                                ? props.responseObj.heightIn
                                : props.responseObj.heightFt
                          )
                        );
                      }}
                      defaultValue={
                        props.responseObj[render.value] &&
                        props.responseObj[render.value].value
                      }
                    />
                    <Radio.Group
                      value="large"
                      className="flex"
                      style={{
                        display: render.type === 'Height in.' ? 'none' : ''
                      }}
                    >
                      {component.addOnAfter &&
                        (component.addOnAfter.type !== 'one' ? (
                          component.addOnAfter.keys.map((measure) => {
                            return (
                              <Radio.Button
                                className={measure.className}
                                style={{
                                  background:
                                    measure.units === props.units
                                      ? '#2761f1'
                                      : '#fff',
                                  color:
                                    measure.units === props.units
                                      ? '#fff'
                                      : '#2761f1',
                                  fontWeight: '500'
                                }}
                                onClick={props.handleMeasureValue}
                                key={measure.units}
                                value={measure.units}
                              >
                                {measure.key}
                              </Radio.Button>
                            );
                          })
                        ) : (
                          <Radio.Button
                            className='border-right'
                            style={{
                              background: '#34f5c5',
                              color: '#fff',
                            }}
                          >
                            {
                              component.addOnAfter.keys.find(
                                (el) => el.units === props.units
                              ).key
                            }
                          </Radio.Button>
                        ))}
                    </Radio.Group>
                  </div>
                </div>
              );
            } else if (component.type === 'DatePickerBirthday') {
              // return (
              //   <div style={{ display: !render.active ? 'none' : '' }}>
              //     <div className='flex flex-center-aligned'>
              //       <p className="form-name">{render.type}</p>
              //       {render.popover &&
              //         <div className='assessment-popover'>
              //           <Popover placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
              //             <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} />
              //           </Popover>
              //         </div>}
              //     </div>

              //     <div
              //       className="flex flex-center-aligned"
              //       style={{ marginBottom: '10px' }}
              //     >
              //       <DatePicker
              //         placeholder={render.placeholder}
              //         style={{ width: '100%' }}
              //         key={render.type}
              //         disabledDate={(current) =>
              //           current > moment(new Date().setFullYear(new Date().getFullYear() - 18))
              //         }
              //         defaultPickerValue={moment(new Date().setFullYear(new Date().getFullYear() - 18))}
              //         defaultValue={
              //           props.responseObj[render.value]
              //             ? moment(props.responseObj[render.value].value)
              //             : null
              //         }
              //         format={'MM/DD/YYYY'}
              //         onChange={(e) => {
              //           props.handleChange(
              //             e,
              //             render.value,
              //             props.activeQuestion.addOnAfter &&
              //             Object.values(props.activeQuestion.addOnAfter).find(
              //               (value) => value.active === true
              //             ).key
              //           );
              //           setError(
              //             render.validate(
              //               e,
              //               null,
              //               null
              //             )
              //           );
              //         }}
              //         allowClear={false}
              //         showToday={false}
              //       />
              //     </div>
              //   </div>
              // );
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type}</p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <Row gutter={16}>
                    <Col sm={8} className="gutter-row">
                      <Select
                        style={{ width: '100%', marginBottom: '10px' }}
                        placeholder="Select Month"
                        className="select-input"
                        onChange={(value) => {
                          handleMonthChange(value)
                          setError(render.validate(selDate));
                        }
                        }
                        value={selMonth != -1 ? selMonth : undefined}
                      >
                        {monthsOptions.map((month, i) => <Option className="select-option" key={i} value={month.value}>{month.text}</Option>)}
                      </Select>
                    </Col>
                    <Col sm={8} className="gutter-row">
                      <Select
                        style={{ width: '100%', marginBottom: '10px' }}
                        placeholder="Select Day"
                        className="select-input"
                        onChange={(value) => {
                          handleDayChange(value)
                          setError(render.validate(selDate));
                        }
                        }
                        value={selDay != -1 ? selDay : undefined}
                      >
                        {daysOptions.map((day) => <Option className="select-option" key={day} value={day}>{day}</Option>)}
                      </Select>
                    </Col>
                    <Col sm={8} className="gutter-row">
                      <Select
                        style={{ width: '100%', marginBottom: '10px' }}
                        placeholder="Select Year"
                        className="select-input"
                        onChange={(value) => {
                          handleYearChange(value)
                          setError(render.validate(selDate));
                        }
                        }
                        value={selYear != -1 ? selYear : undefined}
                      >
                        {yearsOptions.map((year) => <Option className="select-option" key={year} value={year}>{year}</Option>)}
                      </Select>
                    </Col>
                  </Row>

                </div >
              )
            } else if (component.type === 'DatePicker') {
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type}</p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div
                    className="flex flex-center-aligned"
                    style={{ marginBottom: '10px' }}
                  >
                    <DatePicker
                      placeholder={''}
                      style={{ width: '100%' }}
                      key={render.type}
                      disabledDate={(current) => {
                        // const minCaloriesDay = props.minCaloriesDay === 1 ? 0 : props.minCaloriesDay;
                        return current && current < moment().endOf("day") // moment().add(minCaloriesDay, 'days')
                      }}
                      value={
                        props.responseObj[render.value]
                          ? moment(props.responseObj[render.value].value)
                          : null
                      }
                      format={'MM/DD/YYYY'}
                      onChange={(e) => {
                        props.handleChange(
                          e,
                          render.value,
                          props.activeQuestion.addOnAfter &&
                          Object.values(props.activeQuestion.addOnAfter).find(
                            (value) => value.active === true
                          ).key
                        );
                        setError(
                          render.validate(
                            e,
                            null,
                            render.value === 'byWhen'
                              ? props.responseObj.goalWeight
                              : null
                          )
                        );
                      }}
                      allowClear={false}
                      showToday={false}
                    />
                  </div>
                </div>
              );
            } else if (component.type === 'Tabs') {
              return (
                <div>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type}</p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div className='flex'>
                    {component.options.map(option =>
                      <div
                        className={`option ${option.active ? 'active' : ''}`}
                        onClick={() => {
                          props.handleChange(option, component.type);
                          setError(
                            render.validate(
                              null,
                              null,
                              props.responseObj.byWhen
                            )
                          );
                        }}
                      >
                        <p className='option-text'>{option.text}</p>
                      </div>)}
                  </div>
                </div>
              )
            } else if (component.type === 'TextArea') {
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover style={{ display: !render.popover && 'none' }} placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div
                    className="flex flex-center-aligned"
                    style={{ marginBottom: 10 }}
                  >
                    <TextArea
                      className={`${render.className ? render.className : ''} ant-textarea`}
                      key={render.type}
                      autoFocus={render.autoFocus}
                      onChange={(e) => {
                        props.handleChange(e, render.value);
                        setError(
                          render.validate(
                            e.target.value,
                            props.units,
                            render.value === 'secondaryGoals'
                              ? props.responseObj.secondaryGoals
                              : render.value === 'trainingActivity'
                                ? props.responseObj.trainingActivity
                                : props.responseObj.secondaryGoals
                          )
                        );
                      }}
                      placeholder={render.type}
                      value={
                        props.responseObj[render.value] &&
                        props.responseObj[render.value].value
                      }
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </div>
                </div>
              );
            } else if (component.type == 'HeightFtAndIc') {
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type}</p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover style={{ display: !render.popover && 'none' }} placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div
                    className="flex flex-center-aligned"
                    style={{ marginBottom: 10 }}
                  >
                    {render.value === 'heightFt' &&
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Height (ft)"
                        className="select-input"
                        onChange={(value) => {
                          props.handleChange(value, render.value)
                          setError(render.validate(
                            value,
                            props.units,
                            props.responseObj.heightIn))
                        }
                        }
                        value={props.responseObj.heightFt ? props.responseObj.heightFt.value : undefined}
                      >
                        {heigthFtOptions.map((height, i) => <Option className="select-option" key={height.value.toString()} value={height.value}>{height.value}</Option>)}
                      </Select>
                    }
                    {render.value === 'heightIn' &&
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Height (In)"
                        className="select-input"
                        onChange={(value) => {
                          props.handleChange(value, render.value)
                          setError(render.validate(
                            value,
                            props.units,
                            props.responseObj.heightFt))
                        }
                        }
                        value={props.responseObj.heightIn ? props.responseObj.heightIn.value : undefined}
                      >
                        {heigthInOptions.map((height, i) => <Option className="select-option" key={height.value.toString()} value={height.value}>{height.value}</Option>)}
                      </Select>
                    }
                  </div>
                </div >)
            } else if (component.type === 'CustomPieChart') {
              const hasChangedOption = props.activeQuestion.options ? props.activeQuestion.options.find((option) => option.active) : false;
              var selectedValue = props.activeQuestion.options;
              if (props.responseObj[props.activeQuestion.type]) {
                selectedValue = props.responseObj[props.activeQuestion.type]
              } else if (props.auth.assessmentProgress && props.auth.assessmentProgress.weightTraningexprience) {
                selectedValue = props.auth.assessmentProgress[props.activeQuestion.type]
              }
              return (
                <React.Fragment>
                  <div className="flex flex-column">
                    {props.activeQuestion.options &&
                      props.activeQuestion.options.map((option) => (
                        <div
                          className={`option flex-column card-piechart ${option.active || (!hasChangedOption && props.auth.assessmentProgress && props.auth.assessmentProgress[props.activeQuestion.type] && props.auth.assessmentProgress[props.activeQuestion.type].text === option.text) ? 'active' : ''}`}
                          onClick={() => {
                            props.handleChange(option, props.activeQuestion.type);
                            setError(props.activeQuestion.validate(option.macros, option.text));
                          }}
                        >
                          <div className="flex flex-center-aligned w-100 custom-radio">
                            {option.macros && (
                              <PieChart
                                style={{ width: '50px', height: '50px', marginRight: '15px' }}
                                data={[
                                  {
                                    title: 'One',
                                    value: option.macros.protein,
                                    color: '#2761f1',
                                  },
                                  {
                                    title: 'Two',
                                    value: option.macros.carb,
                                    color: '#2bffcc',
                                  },
                                  {
                                    title: 'Three',
                                    value: option.macros.fat,
                                    color: '#FF0066',
                                  },
                                ]}
                                lineWidth={100}
                                paddingAngle={5}
                              />
                            )}

                            {option.macros && (
                              <div>
                                <p className="option-text text-left">{option.text}</p>
                                <div className="flex space-between">
                                  <div className="flex flex-center-aligned">
                                    <Badge
                                      className='assessment-badge'
                                      color={'#2761f1'}
                                      text={`${option.macros.protein}% protein`}
                                    />
                                  </div>
                                  <div className="flex flex-center-aligned">
                                    <Badge
                                      className='assessment-badge'
                                      color={'#2bffcc'}
                                      text={`${option.macros.carb}% carb`}
                                    />
                                  </div>
                                  <div className="flex flex-center-aligned">
                                    <Badge
                                      className='assessment-badge'
                                      color={'#FF0066'}
                                      text={`${option.macros.fat}% fat`}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {option.text === 'Custom' && (
                            <div className='custom-chart mt-2'>
                              <p>{option.subtext}</p>
                              <div className="flex space-between w-100">
                                <div className="macros-input">
                                  <small>Protein</small>
                                  <Input
                                    maxLength={2}
                                    onChange={(e) => {
                                      props.handleChange(e, 'Custom', 'protein');
                                      setError(props.activeQuestion.validate({
                                        protein: e.target.value,
                                        carb: option.macros.carb,
                                        fat: option.macros.fat
                                      }, option.text));
                                    }}
                                    defaultValue={option.macros.protein}
                                    value={selectedValue && selectedValue.protein ? selectedValue.macros.protein : option.macros.protein}
                                    onFocus={e => e.target.select()}
                                  />
                                </div>
                                <div className="macros-input">
                                  <small>Carb</small>
                                  <Input
                                    maxLength={2}
                                    onChange={(e) => {
                                      props.handleChange(e, 'Custom', 'carb');
                                      setError(props.activeQuestion.validate({
                                        protein: option.macros.protein,
                                        carb: e.target.value,
                                        fat: option.macros.fat
                                      }, option.text));
                                    }}
                                    defaultValue={option.macros.carb}
                                    value={selectedValue && selectedValue.carb ? selectedValue.macros.carb : option.macros.carb}
                                    onFocus={e => e.target.select()}
                                  />
                                </div>
                                <div className="macros-input">
                                  <small>Fat</small>
                                  <Input
                                    maxLength={2}
                                    onChange={(e) => {
                                      props.handleChange(e, 'Custom', 'fat');
                                      setError(props.activeQuestion.validate({
                                        protein: option.macros.protein,
                                        carb: option.macros.carb,
                                        fat: e.target.value
                                      }, option.text));
                                    }}
                                    defaultValue={option.macros.fat}
                                    value={selectedValue && selectedValue.fat ? selectedValue.macros.fat : option.macros.fat}
                                    onFocus={e => e.target.select()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </React.Fragment>
              );
            } else if (component.type === 'CustomCheckbox') {
              let { selectedOptions = [], otherDescription = null } = props.responseObj.riskFollowing ? props.responseObj.riskFollowing.value : {};
              const onCkChange = (checkedValues) => {
                props.handleChange({ selectedOptions: checkedValues, otherDescription: otherDescription }, render.value)
                setError(render.validate(checkedValues, otherDescription));
              };
              const handleTXChange = (e) => {
                props.handleChange({ selectedOptions: selectedOptions, otherDescription: e.target.value }, render.value)
                setError(render.validate(selectedOptions, e.target.value));
              }
              return (
                <div className="text-left">
                  {(() => {
                    let [none, ...restOptions] = render.options;
                    return (<>
                      <Checkbox.Group style={{ width: '100%' }} onChange={onCkChange} value={selectedOptions}>
                        <Checkbox className="custom-checkbox" key={none.key} value={none.value}>{none.text}</Checkbox>
                      </Checkbox.Group>
                      <hr style={{ borderColor: "#E0E0E0", marginBottom: "15px" }}></hr>
                      {selectedOptions.includes(none.value)
                        ? ""
                        : <>
                          <Checkbox.Group style={{ width: '100%' }} onChange={onCkChange} value={selectedOptions}>
                            {restOptions.map(op => <Checkbox className="custom-checkbox" key={op.key} value={op.value} >{op.text}</Checkbox>)}
                          </Checkbox.Group>
                          {selectedOptions.includes("Other") &&
                            <TextArea rows={3} placeholder="Type here..." className="ant-textarea" onChange={handleTXChange} value={otherDescription} />}
                        </>}
                    </>)
                  })()}
                  {/* {render.options.map(option =>
                    (option.value === "None") ?
                      <>
                        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                          <Checkbox className="custom-checkbox" value="None">None</Checkbox>
                        </Checkbox.Group>
                        <hr style={{ borderColor: "#E0E0E0", marginBottom: "15px" }}></hr>
                      </>
                      :
                      <>
                        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                          <Checkbox className="custom-checkbox" value="High blood pressure">High blood pressure</Checkbox>
                          <Checkbox className="custom-checkbox" value="Diabetes">Diabetes</Checkbox>
                          <Checkbox className="custom-checkbox" value="Testosterone deficiency">Testosterone deficiency</Checkbox>
                          <Checkbox className="custom-checkbox" value="Heart disease or stroke">Heart disease or stroke</Checkbox>
                          <Checkbox className="custom-checkbox" value="Osteoarthritis">Osteoarthritis</Checkbox>
                          <Checkbox className="custom-checkbox" value="Other">Other</Checkbox>
                        </Checkbox.Group>
                        <TextArea rows={3} placeholder="Type here..." maxLength={6} className="ant-textarea" />
                      </>
                  )} */}
                </div>
              );
            } else if (component.type === 'weightTraningexprience') {
              var selectedValue = render.defaultValue;
              if (props.responseObj.weightTraningexprience) {
                selectedValue = props.responseObj.weightTraningexprience.key
              } else if (props.auth.assessmentProgress && props.auth.assessmentProgress.weightTraningexprience) {
                selectedValue = props.auth.assessmentProgress.weightTraningexprience.key
              }
              const onAfterChange = (value) => {
                props.handleChange({ key: value, text: render.marks[value], value: render.marks[value], info: render.tooltips[value] }, props.activeQuestion.type)
                setError(props.activeQuestion.validate());
                setTermsOfWeightTraningexprience(render.tooltips[value])
              };
              return (
                <div className="custom-ant-slider">
                  <p>{termsOfWeightTraningexprience ? termsOfWeightTraningexprience : render.tooltips[render.defaultValue]}</p>
                  <Slider marks={render.marks} min={render.min} max={render.max} step={render.step} defaultValue={selectedValue} tooltipVisible={render.tooltipVisible} onAfterChange={onAfterChange} />
                  {/* <Slider style={{ backgroundImage: "url( " + require("../../assets/images/slider-bar-image.svg") + ")" }} marks={render.marks} min={render.min} max={render.max} step={render.step} defaultValue={selectedValue} tooltipVisible={render.tooltipVisible} onAfterChange={onAfterChange} /> */}
                  {/* <Slider marks={render.marks} min={render.min} max={render.max} step={render.step} defaultValue={render.defaultValue} value={selectedValue} tooltipVisible={render.tooltipVisible} onAfterChange={onAfterChange} />                   */}
                  {/* <img src={require("../../assets/images/slider-bar-image.svg")} style={{width: "100%"}} /> */}
                </div>
              );
            } else if (component.type === 'InputAutoCompleteMap') {
              return (
                <div style={{ display: !render.active ? 'none' : '' }}>
                  <div className='flex flex-center-aligned'>
                    <p className="form-name">{render.type} </p>
                    {render.popover &&
                      <div className='assessment-popover'>
                        <Popover style={{ display: !render.popover && 'none' }} placement="top" title={render.popover.title} content={render.popover.text} trigger="click">
                          {/* <BsQuestionCircleFill style={{ fontSize: '12px', margin: '0 0 1px 3px' }} /> */}
                          <BulbFilled style={{ fontSize: '18px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                        </Popover>
                      </div>}
                  </div>
                  <div
                    className="flex flex-center-aligned"
                    style={{ marginBottom: 10 }}
                  >
                    <LocationInput
                      name="currentLocation"
                      id="location"
                      placeholder="Enter Location"
                      type={render.inputType ? render.inputType : 'text'}
                      className={render.className}
                      key={render.type}
                      autoFocus={render.autoFocus}
                      maxLength={render.maxLength}
                      onChange={(e) => {
                        props.handleChange(e, render.value);
                        setError(
                          render.validate(e)
                        );
                      }}
                      defaultValue={
                        props.responseObj[render.value] &&
                        props.responseObj[render.value].location
                      }
                    // defaultValue={
                    //   props.responseObj[render.value] &&
                    //   props.responseObj[render.value].value
                    // }
                    />
                  </div>
                </div>
              );
            } else if (component.type === 'newPassword') {
              return (
                <div
                  className="flex flex-column"
                  style={{ marginBottom: 10 }}
                > 
                  <p className="form-name">{render.type}</p>
                  <PasswordField
                    key={render.type}
                    onChange={(e) => {
                      props.handleChange(e, render.value);
                      setError(
                        render.validate(e.target.value, render.value === 'newPassword'
                          ? props.responseObj.confirmNewPassword
                          : props.responseObj.newPassword)
                      );
                    }}
                    defaultValue={
                      props.responseObj[render.value] &&
                      props.responseObj[render.value].value
                    }
                  />
                </div>
              );
            } else {
              let { selectedOptions = [], otherDescription = null } = props.responseObj[component.type] ? props.responseObj[component.type].value : {};
              const onCkChange = (checkedValues) => {
                props.handleChange({ selectedOptions: checkedValues, otherDescription: otherDescription }, render.value)
                setError(render.validate(checkedValues, otherDescription));
              };
              const handleTXChange = (e) => {
                props.handleChange({ selectedOptions: selectedOptions, otherDescription: e.target.value }, render.value)
                setError(render.validate(selectedOptions, e.target.value));
              }
              return (
                <div className="text-left">
                  {(() => {
                    return (<>
                      <Checkbox.Group style={{ width: '100%' }} onChange={onCkChange} value={selectedOptions}>
                        {render.options.map(op => <Checkbox className="custom-checkbox" key={op.key} value={op.value} >{op.text}</Checkbox>)}
                      </Checkbox.Group>
                      {selectedOptions.includes("Other") &&
                        <TextArea rows={3} placeholder="Type here..." className="ant-textarea" onChange={handleTXChange} value={otherDescription} />}
                    </>)
                  })()}
                </div>
              );
            }
          });
        });
    } else {
      const hasChangedOption = props.activeQuestion.options.find((option) => option.active);
      return (
        < React.Fragment >
          <div className="flex flex-column">
            {props.activeQuestion.options &&
              props.activeQuestion.options.map((option) => {
                return (
                  <div
                    className={`option ${option.active || (!hasChangedOption && props.auth.assessmentProgress && props.auth.assessmentProgress[props.activeQuestion.type] && props.auth.assessmentProgress[props.activeQuestion.type].key === option.key) ? 'active' : ''} ${option.popover ? 'que-goal' : ''}`}
                    onClick={() => {
                      props.handleChange(option, props.activeQuestion.type);
                      setError(props.activeQuestion.validate(option.macros, option.text));
                    }}
                  >
                    <div>
                      {option.macros && (
                        <PieChart
                          style={{ height: '100px' }}
                          data={[
                            {
                              title: 'One',
                              value: option.macros.protein,
                              color: '#0065ff',
                            },
                            {
                              title: 'Two',
                              value: option.macros.carb,
                              color: '#2bffcc',
                            },
                            {
                              title: 'Three',
                              value: option.macros.fat,
                              color: '#FF0066',
                            },
                          ]}
                          lineWidth={15}
                          paddingAngle={5}
                        />
                      )}

                      <div>
                        <p className="option-text">
                          {option.text}
                          {
                            option.popover &&
                            <div className='custom-tooltip'>
                              <Popover style={{ display: !option.popover && 'none' }} placement="top" title={option.popover.title} content={option.popover.text} trigger="click">
                                <BulbFilled style={{ fontSize: '20px', color: '#BDBDBD', margin: '0 0 1px 3px' }} />
                              </Popover>
                            </div>
                          }
                        </p>
                        {option.info ? (
                          <p className="blog-card-subtitle">{option.info}</p>
                        ) : null
                        }
                      </div>

                      {option.macros && (
                        <div className="flex space-around">
                          <div className="flex flex-center-aligned">
                            <Badge
                              className='assessment-badge'
                              color={'#0065ff'}
                              text={`${option.macros.protein}% protein`}
                            />
                          </div>
                          <div className="flex flex-center-aligned">
                            <Badge
                              className='assessment-badge'
                              color={'#2bffcc'}
                              text={`${option.macros.carb}% carb`}
                            />
                          </div>
                          <div className="flex flex-center-aligned">
                            <Badge
                              className='assessment-badge'
                              color={'#FF0066'}
                              text={`${option.macros.fat}% fat`}
                            />
                          </div>
                        </div>
                      )}
                      <p>{option.subtext}</p>
                      {option.text === 'Custom' && (
                        <div className="flex space-between">
                          <div className="macros-input">
                            <small>Protein</small>
                            <Input
                              maxLength={2}
                              onChange={(e) => {
                                props.handleChange(e, 'Custom', 'protein');
                                setError(props.activeQuestion.validate({
                                  protein: e.target.value,
                                  carb: option.macros.carb,
                                  fat: option.macros.fat
                                }, option.text));
                              }}
                              defaultValue={option.macros.protein}
                              onFocus={e => e.target.select()}
                            />
                          </div>
                          <div className="macros-input">
                            <small>Carb</small>
                            <Input
                              maxLength={2}
                              onChange={(e) => {
                                props.handleChange(e, 'Custom', 'carb');
                                setError(props.activeQuestion.validate({
                                  protein: option.macros.protein,
                                  carb: e.target.value,
                                  fat: option.macros.fat
                                }, option.text));
                              }}
                              defaultValue={option.macros.carb}
                              onFocus={e => e.target.select()}
                            />
                          </div>
                          <div className="macros-input">
                            <small>Fat</small>
                            <Input
                              maxLength={2}
                              onChange={(e) => {
                                props.handleChange(e, 'Custom', 'fat');
                                setError(props.activeQuestion.validate({
                                  protein: option.macros.protein,
                                  carb: option.macros.carb,
                                  fat: e.target.value
                                }, option.text));
                              }}
                              defaultValue={option.macros.fat}
                              onFocus={e => e.target.select()}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </React.Fragment >
      );
    }
  };
  return (
    <React.Fragment>
      {props.activeQuestion && (
        <div className="assessment-questions">
          <p className='assessment-question-title'>{props.activeQuestion.question}</p>
          {props.activeQuestion.subtitle &&
            <div className='subtitle-container'>
              <span className='icon'>{props.activeQuestion.subtitle.icon}</span>
              <p className='text'>{props.activeQuestion.subtitle.text}</p>
            </div>}
          {renderQuestion()}
          <div className="mb-3">
            {Boolean(error) && <Alert message={<p>{error}</p>} type="error" />}
          </div>

          <div className="btn-wrap ">
            <div className='flex space-between next-prev-btn'>
              {props.progress > 4 && props.responseObj.assessmentStep < Object.keys(props.assessmentQuestions).length && (
                <Button
                  className="flex flex-center-aligned assessment-back-btn"
                  onClick={(e) =>
                    props.handleProgress(e, "back", props.activeQuestion)
                  }
                  disabled={props.auth.assessmentSubmitLoader ? 'disabled' : ''}
                >
                  <span className='mr-1'><i class="far fa-arrow-left"></i></span>
                  <p style={{ fontSize: "18px" }}>Back</p>
                </Button>
              )}
              {props.activeQuestion.button && (
                <Button
                  className="assessment-button ml-auto"
                  onClick={(e) => {
                    props.handleProgress(e, null, props.activeQuestion);
                  }}
                  disabled={Boolean(error) || props.auth.assessmentSubmitLoader}
                  loading={props.loadingCalculation && props.errors.length === 0}
                >
                  {(props.activeQuestion.key !== totalQue && props.activeQuestion.key !== 27) ? 'Next ' : 'Submit'}
                  {(props.activeQuestion.key !== totalQue && props.activeQuestion.key !== 27) ? <span className='ml-1'><i className="far fa-arrow-right"></i></span> : null}

                </Button>
              )}
            </div>
          </div>
        </div>
      )
      }
    </React.Fragment >
  );
};
