import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_ERROR,
  FILTER_BLOGS,
  SUBSCRIBE_USER,
  SUBSCRIBE_USER_SUCCESS,
  GET_SINGLE_POST,
  GET_SINGLE_POST_SUCCESS,
  CLEAR_SUBSCRIBE_MESSAGE
} from "./constants";

const initialState = {
  allBlogs: null,
  allBlogsLoading: false,
  allBlogsError: null,
  filteredBlogs: null,
  subscribeStatus: null,
  currentPost: null
};

function blog(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BLOGS:
      return {
        ...state,
        allBlogs: null,
        allBlogsLoading: true
      };
    case GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        allBlogs: action.allBlogs,
        allBlogsLoading: false,
        filteredBlogs: action.allBlogs
      };
    case GET_ALL_BLOGS_ERROR:
      return {
        ...state,
        allBlogs: null,
        allBlogsLoading: false,
        allBlogsError: null
      };
    case FILTER_BLOGS:
      return {
        ...state,
        filteredBlogs: {...state.allBlogs, posts: [...state.allBlogs.posts.filter(blog => blog.categories.some(blog.category === action.category))]}
      };
    case SUBSCRIBE_USER:
      return {
        ...state,
        subscribeStatus: null
      };
    case SUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        subscribeStatus: action.response.status
      };
    case CLEAR_SUBSCRIBE_MESSAGE:
      return {
        ...state,
        subscribeStatus: null
      }
    case GET_SINGLE_POST:
      return {
        ...state,
        currentPost: null
      };
    case GET_SINGLE_POST_SUCCESS:
      return {
        ...state,
       currentPost: action.currentPost.posts[0]
      };
    default:
      return state;
  }
}

export default blog;