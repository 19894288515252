import {
  GET_TABLE_DATA,
  GET_TABLE_DATA_SUCCESS,
  GET_TABLE_DATA_ERROR
} from "./constants";

const initialState = {
  tableData: null,
  tableDataLoading: false,
  tableDataError: null
};

function table(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TABLE_DATA:
      return {
        ...state,
        tableData: null,
        tableDataLoading: true,
        tableDataError: null
      };
    case GET_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: payload,
        tableDataLoading: false
      };
    case GET_TABLE_DATA_ERROR:
      return {
        ...state,
        tableData: payload,
        tableDataLoading: false,
        tableDataError: action.error
      };
    default:
      return state;
  }
}

export default table;