import {
  GET_USER_BF_MEASUREMENTS,
  GET_USER_BF_MEASUREMENTS_SUCCESS,
  GET_USER_BF_MEASUREMENTS_ERROR,
  SAVE_BODY_FAT,
  SAVE_BODY_FAT_SUCCESS,
  SAVE_BODY_FAT_ERROR,
  CLEAR_BODY_FAT_ERRORS
} from "./constants";

const initialState = {
  userBodyFatMeasurements: [],
  bodyFatLoading: false,
  bodyFatError: null,
  saveBodyFatLoading: false,
  saveBodyFatError: false
};

function measurements(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_BF_MEASUREMENTS:
      return {
        ...state,
        userBodyFatMeasurements: null,
        bodyFatLoading: true,
        bodyFatError: null
      };
    case GET_USER_BF_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        userBodyFatMeasurements: payload,
        bodyFatLoading: false
      };
    case GET_USER_BF_MEASUREMENTS_ERROR:
      return {
        ...state,
        bodyFatError: action.err,
        bodyFatLoading: false
      };
    case SAVE_BODY_FAT:
      return {
        ...state,
        saveBodyFatLoading: true,
        saveBodyFatError: false
      }
    case SAVE_BODY_FAT_SUCCESS:
      return {
        ...state,
        userBodyFatMeasurements: [...state.userBodyFatMeasurements, action.response],
        success: true,
        saveBodyFatLoading: false,
        saveBodyFatError: false
      }
    case SAVE_BODY_FAT_ERROR:
      return {
        ...state,
        success: false,
        saveBodyFatLoading: false,
        saveBodyFatError: action.err
      };
    case CLEAR_BODY_FAT_ERRORS:
      return {
        ...state,
        bodyFatError: null,
        success: false
      };
    default:
      return state;
  }
}

export default measurements;