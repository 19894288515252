import React from 'react';
import './index.css';
import debounce from 'lodash/debounce';
import CustomFoodModal from './CustomFoodModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dish from "../../assets/images/dish-gray.svg";
import { Select, Input, Divider, Spin, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class MealElement extends React.Component {
  constructor() {
    super();
    this.state = {
      query: "",
      queryResult: {
        custom: [],
        edamam: [],
      },
      alternativeQueryResult: {
        custom: [],
        edamam: []
      },
      servingSizeWarn: false,
      loading: false,
      nutritionLoading: false,
      allNutrients: {},
      nutritionVisible: false,
      customFoodVisible: false,
      type: "",
      caloriesPerServing: 0,
      carbsPerServing: 0,
      proteinsPerServing: 0,
      fatsPerServing: 0
    };
  }

  componentDidMount() {
    if (this.props.item.quantity && this.props.item.measure) {
      this.setState({
        caloriesPerServing:
          this.props.item.info.calories / this.props.item.quantity,
        carbsPerServing: this.props.item.info.carbs / this.props.item.quantity,
        proteinsPerServing:
          this.props.item.info.proteins / this.props.item.quantity,
        fatsPerServing: this.props.item.info.fats / this.props.item.quantity,
      });
    }
  }

  handleChangeNutrients = debounce(async (modal) => {
    const [day, index, foodIndex, altFoodIndex] = this.props.location
    if (this.props.item.quantity && this.props.item.measure) {
      if ((this.state.caloriesPerServing || this.props.item.brand) && !modal) {
        this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
          ...this.props.item,
          info: {
            ...this.props.item.info,
            calories: this.state.caloriesPerServing * (this.props.item.quantity || 1),
            carbs: this.state.carbsPerServing * (this.props.item.quantity || 1),
            proteins: this.state.proteinsPerServing * (this.props.item.quantity || 1),
            fats: this.state.fatsPerServing * (this.props.item.quantity || 1)
          }
        })
      } else {
        if (modal) {
          this.setState({
            nutritionLoading: true,
          });
        } else {
          this.setState({
            loading: true,
          });
        }
        const req = await fetch(
          `${process.env.REACT_APP_API_URL}/api/mealplan/nutrients`,
          {
            method: "POST",
            body: JSON.stringify({
              _id: this.props.item._id,
              quantity: this.props.item.quantity,
              measureUrl: this.props.item.measureUrl,
              foodId: this.props.item.foodId,
            }),
            headers: {
              "x-auth-token": localStorage.token,
              "content-type": "application/json",
            },
          }
        );
        const info = await req.json();
        if (!modal) {
          this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
            ...this.props.item,
            info: {
              calories: info.totalNutrients.ENERC_KCAL.quantity,
              carbs: info.totalNutrients.CHOCDF.quantity,
              proteins: info.totalNutrients.PROCNT.quantity,
              fats: info.totalNutrients.FAT.quantity
            }
          })
        }
        this.setState({
          loading: false,
          nutritionLoading: false,
          caloriesPerServing:
            this.props.item.info.calories / this.props.item.quantity,
          carbsPerServing:
            this.props.item.info.carbs / this.props.item.quantity,
          proteinsPerServing:
            this.props.item.info.proteins / this.props.item.quantity,
          fatsPerServing: this.props.item.info.fats / this.props.item.quantity,
          allNutrients: info.totalNutrients,
        });
      }
    } else {
      this.setState({
        servingSizeWarn: true,
      });
    }
  }, 400);

  handleChangeMeasure = (key, value) => {
    const [day, index, foodIndex, altFoodIndex] = this.props.location

    if (key === "measure") {
      const measure = value.split("-")[0];
      const measureUrl = value.split("-")[1];
      if (measure !== this.props.item.measure) {
        this.setState({
          caloriesPerServing: 0,
        });
      }
      this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
        ...this.props.item,
        measure,
        measureUrl,
      });
    } else {
      this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
        ...this.props.item,
        [key]: value,
      });
    }

    this.setState({
      allNutrients: {},
    });

    this.handleChangeNutrients();
  };

  handleChange = async (title) => {
    try {
      const [day, index, foodIndex, altFoodIndex] = this.props.location

      if (title.includes('id_')) {
        const id = title.split('id_')[1];
        const desiredFood = this.state.queryResult.custom.find(
          (i) => i._id === id
        );
        if ((desiredFood.measure || '').includes('Gram')) {
          desiredFood.quantity = 100
        } else {
          desiredFood.quantity = 1
        }
        this.props.updateMeal(day, index, foodIndex, altFoodIndex, desiredFood);
        this.setState({
          caloriesPerServing: desiredFood.info.calories,
          carbsPerServing: desiredFood.info.carbs,
          proteinsPerServing: desiredFood.info.proteins,
          fatsPerServing: desiredFood.info.fats
        });
      } else {
        const id = title.split('_title_')[0]
        title = title.split('_title_')[1]
        this.props.updateMeal(day, index, foodIndex, altFoodIndex, { title, info: {}, measures: [], alternatives: this.props.item.alternatives, foodCategory: this.props.item.foodCategory })
        this.setState({
          loading: true
        })
        const req = await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/details?` + new URLSearchParams({
          name: id,
          category: this.props.item.foodCategory || '',
          healthLabels: this.props.healthLabels || ''
        }), {
          headers: {
            'x-auth-token': localStorage.token
          }
        });
        const response = await req.json();
        const info = response.info.food;
        const measures = response.measures;
        let foodMeasure, nutrientsInfo

        if (info.category === 'Packaged foods') {
          const measure = measures.find(i => i.label === 'Serving')
          const grams = measure.weight
          foodMeasure = {
            measure: `Serving (${Math.round(grams)}g)`,
            measureUrl: measure.uri,
            quantity: '1'
          }
          nutrientsInfo = {
            calories: (info.nutrients.ENERC_KCAL / 100) * grams,
            carbs: (info.nutrients.CHOCDF / 100) * grams,
            proteins: (info.nutrients.PROCNT / 100) * grams,
            fats: (info.nutrients.FAT / 100) * grams
          }
        } else {
          foodMeasure = {
            measure: 'Gram (1g)',
            quantity: '100'
          }
          nutrientsInfo = {
            calories: info.nutrients.ENERC_KCAL,
            carbs: info.nutrients.CHOCDF,
            proteins: info.nutrients.PROCNT,
            fats: info.nutrients.FAT
          }
        }

        this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
          ...this.props.item,
          title,
          foodId: info.foodId,
          info: nutrientsInfo,
          image: info.image || '',
          measures,
          ...foodMeasure
        })

        this.setState({
          query: "",
          loading: false,
          caloriesPerServing: nutrientsInfo.calories,
          carbsPerServing: nutrientsInfo.carbs,
          proteinsPerServing: nutrientsInfo.proteins,
          fatsPerServing: nutrientsInfo.fats
        });
      }

      setTimeout(async () => {
        const alternativeProductsRequest = await fetch(`${process.env.REACT_APP_API_URL}/api/mealplan/details?` + new URLSearchParams({
          name: ' ',
          foodId: this.props.item.foodId || this.props.item._id,
          ...this.props.item.info
        }), {
          headers: {
            'x-auth-token': localStorage.token
          }
        });
        const alternativeProductsResponse = await alternativeProductsRequest.json();
  
        this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
          ...this.props.item,
          alternatives: alternativeProductsResponse.alternativeFood
        })
  
        this.setState({
          allNutrients: {},
          alternativeQueryResult: {
            custom: [],
            edamam: alternativeProductsResponse.hints
          }
        });
      }, 50)
    } catch (e) {
      toast.error('An error happened. Please choose another product');
      this.setState({
        loading: false
      })
    }
  };

  handleSearch = async (value, field) => {
    if (value) {
      this.setState({
        query: value
      });

      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/api/mealplan/autocomplete?q=${value}&healthLabels=${this.props.healthLabels || ''}&category=${this.props.item.foodCategory || ''}`,
        {
          headers: {
            "x-auth-token": localStorage.token,
          }
        }
      );
      const queryResult = await req.json();
      if (queryResult.custom && queryResult.edamam) {
        this.setState({ [field]: queryResult });
      }
    }
  };

  showModal = async () => {
    this.setState({
      servingSizeWarn: false,
      nutritionVisible: true,
    });
    if (
      !this.state.nutritionLoading &&
      !(this.state.allNutrients.ENERC_KCAL || this.state.allNutrients.calories)
    ) {
      await this.handleChangeNutrients(true);
    }
  };

  handleCancel = (key) => {
    this.setState({
      [key]: false,
    });
  };

  changeType = (category) => {
    const [day, index, foodIndex, altFoodIndex] = this.props.location

    this.props.updateMeal(day, index, foodIndex, altFoodIndex, {
      ...this.props.item,
      foodCategory: this.props.item.foodCategory === category ? '' : category
    })
  }

  foodOption = (item, nutrients) => (
    <div>
      <div className="flex">
        <img
          className="option-image-select"
          src={item.image || Dish}
          alt=""
        />
        {item.title.toUpperCase()} {item.brand && `- ${item.brand.toUpperCase()}`}
      </div>
      <div>
        <small className="muted">NUTRIENTS PER 100g</small>
        <div className="flex">
          <div className="mr-2">
            <small className="block">
              Energy
            </small>
            {Math.round(nutrients.calories || nutrients.ENERC_KCAL)}kcal
          </div>
          <div className="mr-2">
            <small className="block">
              Protein
            </small>
            {(+nutrients.proteins || +nutrients.PROCNT || 0).toFixed(1)}g
          </div>
          <div className="mr-2">
            <small className="block">
              Carbs
            </small>
            {(+nutrients.carbs || +nutrients.CHOCDF || 0).toFixed(1)}g
          </div>
          <div>
            <small className="block">
              Fat
            </small>
            {(+nutrients.fats || +nutrients.FAT || 0).toFixed(1)}g
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      customFoodVisible,
      loading,
      nutritionVisible,
      servingSizeWarn,
      nutritionLoading,
      allNutrients,
      queryResult
    } = this.state;

    const {
      removeFood,
      location,
      isAlternative,
      item
    } = this.props;
    return (
      <div className="week-item-info">
        <CustomFoodModal
          customFoodVisible={customFoodVisible}
          handleCancel={this.handleCancel}
        />
        <span
          className="cross"
          onClick={() =>
           removeFood(
              location[1],
              location[2],
              location[3]
            )
          }
        >
          &times;
        </span>
        <div className="select-area mr-2 mb-2">
          <div className="input-food mr-1 form">
            <p className="label">{isAlternative ? 'Alternative Food' : 'Food'}</p>
            <div className="flex flex-center-aligned position-relative">
              {item.image && (
                <img src={item.image} className="option-image option-image-absolute" alt="" />
              )}
              <Select
                className={item.image ? "w-100 input-food-image" : "w-100"}
                value={item.title}
                filterOption={false}
                defaultActiveFirstOption={false}
                onChange={this.handleChange}
                onSearch={debounce((item) => this.handleSearch(item, 'queryResult'), 400)}
                showSearch
                dropdownRender={(menu) => (
                  <div>
                    <div className="my-1 flex space-around">
                      <div
                        className={`food-icon ${item.foodCategory === '' ? 'food-icon-active' : ''}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.changeType('')}
                      >
                        All
                      </div>
                      <div
                        className={`food-icon ${item.foodCategory === 'generic-foods' ? 'food-icon-active' : ''}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.changeType('generic-foods')}
                      >
                        Generic
                      </div>
                      <div
                        className={`food-icon ${item.foodCategory === 'packaged-foods' ? 'food-icon-active' : ''}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.changeType('packaged-foods')}
                      >
                        Packaged
                      </div>
                      <div
                        className={`food-icon ${item.foodCategory === 'fast-foods' ? 'food-icon-active' : ''}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.changeType('fast-foods')}
                      >
                        Fast
                      </div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div
                      className="pointer"
                      style={{ padding: "5px" }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => this.setState({ customFoodVisible: true })}
                    >
                      <PlusOutlined className="mr-1" /> Add custom food
                    </div>
                    <Divider style={{ margin: 0 }} />
                    {menu}
                  </div>
                )}
              >
                {queryResult.custom.map((item) => (
                  <Option key={item._id} value={`id_${item._id}`}>
                    {this.foodOption(item, item.info)}
                  </Option>
                ))}
                {queryResult.edamam.map((item) => (
                  <Option key={item.id} value={`${item.id}_title_${item.title}${item.brand ? ` - ${item.brand}` : ''}`}>
                    {this.foodOption(item, item.nutrients)}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="mr-1 form">
            <p className="label">Serving size</p>
            <div style={{ display: "flex" }}>
              <Input
                type="number"
                className="input-serving"
                value={item.quantity}
                min={1}
                onChange={(e) =>
                  this.handleChangeMeasure("quantity", e.target.value)
                }
                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Select
                className="input-serving-label"
                value={item.measure}
                filterOption={false}
                defaultActiveFirstOption={false}
                onChange={(item) => this.handleChangeMeasure("measure", item)}
              >
                {(item.measures || []).map((item, index) =>
                  <Option key={index} value={`${item.label} (${(item.weight || 0).toFixed(0)}g) - ${item.uri}`}>
                    {item.label} ({(item.weight || 0).toFixed(0)}g)
                  </Option>
                )}
              </Select>
              </div>
          </div>
        </div>
        <div className="mt-1">
          {loading && <Spin indicator={antIcon} />}
          {!loading && item.title && (
            <div className="flex flex-wrap flex-center-aligned">
              <Modal
                title={`${item.title} nutrition details`}
                visible={nutritionVisible}
                onCancel={() => this.handleCancel("nutritionVisible")}
                footer={null}
                centered={true}
              >
                {servingSizeWarn && (
                  <div className="text-center">Please choose serving size</div>
                )}
                {nutritionLoading && <Spin indicator={antIcon} />}
                {!nutritionLoading &&
                  Object.entries(allNutrients).map((i) => (
                    <div key={i[1].label}>
                      {i[1].label}: {(+i[1].quantity).toFixed(1)} {i[1].unit}
                    </div>
                  ))}
              </Modal>
              <div className="mr-3">
                <p className="home-card-text">
                  {(item.info && (+item.info.calories || 0).toFixed(1))}kcal
                </p>
                <span className="macro-label-bottom">Calories</span>
              </div>
              <div className="mr-3">
                <p className="home-card-text">
                  {(item.info && (+item.info.proteins || 0).toFixed(1))}g
                </p>
                <span className="macro-label-bottom">Proteins</span>
              </div>
              <div className="mr-3">
                <p className="home-card-text">
                  {(item.info && (+item.info.carbs || 0).toFixed(1))}g
                </p>
                <span className="macro-label-bottom">Carbs</span>
              </div>
              <div className="mr-3">
                <p className="home-card-text">
                  {(item.info && (+item.info.fats || 0).toFixed(1))}g
                </p>
                <span className="macro-label-bottom">Fats</span>
              </div>
              <div className="blue-link" onClick={this.showModal}>
                Show more
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MealElement;