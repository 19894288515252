import React from 'react';
import { connect} from 'react-redux';
import { deleteExercise, getExercises } from '../../ducks/Exercise/actions';
import { SET_EDIT_EXERCISE, SET_IS_EXERCISE_EDIT } from '../../ducks/Exercise/constants';
import { TOGGLE_CREATE_EXERCISE_MODAL } from '../../ducks/Global/constants';
import ExercisesPage from '../../presentational/ExercisesPage';
import { getExerciseCategories, getMuscles } from '../../ducks/Exercise/actions';
import '../../index.css';

class Exercises extends React.Component {

  componentDidMount(){
    this.props.muscles.length === 0 && this.props.getExercises();
    !this.props.exerciseCategories && this.props.getExerciseCategories();
    this.props.muscles.length === 0 && this.props.getMuscles();
  }

  render(){
    return(
       <div className='exercises-page'>
        {this.props.exercises.length > 0 && <ExercisesPage {...this.props} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    exercises: state.exercises.exercises,
    getExercisesLoading: state.exercises.getExercisesLoading,
    getExercisesErr: state.exercises.getExercisesErr,
    editExercise: state.exercises.editExercise,
    isExerciseEdit: state.exercises.isExerciseEdit,
    exerciseCategories: state.exercises.exerciseCategories,
    muscles: state.exercises.muscles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getExercises: () => dispatch(getExercises()),
    deleteExercise: (id) => dispatch(deleteExercise(id)),
    setEditExercise: (exercise) => dispatch({
      type: SET_EDIT_EXERCISE,
      payload: exercise
    }),
    setIsExerciseEdit: (value) => dispatch({
      type: SET_IS_EXERCISE_EDIT,
      value
    }),
    toggleCreateExerciseModal: (value) => dispatch({
      type: TOGGLE_CREATE_EXERCISE_MODAL,
      value
    }),
    getExerciseCategories: () => dispatch(getExerciseCategories()),
    getMuscles: () => dispatch(getMuscles())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exercises);