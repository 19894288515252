import React from 'react';
import CreateWorkoutModal from '../AdminWorkoutLibrary/createWorkoutModal';
import { WorkoutCard } from './workoutCard';
import CreateExerciseModal from '../ExercisesPage/createExerciseModal';
import ExerciseHistoryModal from '../../components/ExerciseHistoryModal';
import './index.css';

class AdminWorkoutLibraryPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkout: {exercises: []},
      exercises: [],
      // exerciseIdsArr: [],
      currentExercise: {}
    };
  }
  
  componentDidMount(){
    this.setState({ exercises: this.props.exercises});
  }

  // componentDidUpdate(prevState){
  //   if(prevState.currentWorkout && prevState.currentWorkout !== this.state.currentWorkout){
  //     this.getExerciseIdsArr();
  //   }
  // }

  setCurrentWorkout = (workout) => {
    this.setState({currentWorkout: workout})
  }

  // getExerciseIdsArr = () => {
  //   const { currentWorkout } = this.state;
  //   const exerciseIdsArr = currentWorkout && currentWorkout.exercises.map(exercise => exercise._id);
  //   this.setState({exerciseIdsArr});
  // }

  setExercise = (exercise) => {
    this.setState({currentExercise: exercise})
  }
  
  render(){
    return (
      <div className="admin-workout">
        <ExerciseHistoryModal 
          toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
          exerciseHistoryModalVisible={this.props.exerciseHistoryModalVisible}
          currentExercise={this.state.currentExercise}
        />
        {this.props.exerciseCategories && this.props.muscles && 
        <CreateExerciseModal
          toggleCreateExerciseModal={this.props.toggleCreateExerciseModal}
          exerciseCategories={this.props.exerciseCategories}
          muscles={this.props.muscles}
        />}
        <div className='exercise-card-container'>
          {this.props.workouts.map(workout=> 
          <WorkoutCard 
            key={workout._id}
            workout={workout} 
            setCurrentWorkout={this.setCurrentWorkout}
            toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
            deleteLibraryWorkout={this.props.deleteLibraryWorkout}
            deleteWorkoutLoading={this.props.deleteWorkoutLoading}
            isUserWorkout={false}
            setIsWorkoutEdit={this.props.setIsWorkoutEdit}
          />)}
        </div>
        <CreateWorkoutModal 
          exercises={this.state.exercises}
          history={this.props.history}
          visible={this.props.createWorkoutModalVisible}
          workout={this.state.currentWorkout}
          toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
          toggleCreateExerciseModal={this.props.toggleCreateExerciseModal}
          toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
          highestLoadSet={this.props.highestLoadSet}
          // exerciseIdsArr={this.state.exerciseIdsArr}
          setExercise={this.setExercise}
        />
      </div>
    )
  }
}

export default AdminWorkoutLibraryPage;