import React from 'react';
import { connect } from 'react-redux';
import { useDispatch } from "react-redux";
import Fitbit from '../assets/logos/Fitbit_logo_RGB.png';
import Withings from '../assets/logos/withings-logo.png';

export const IntegrationsData = (profile) => {
  return (
    [
      {
        name: 'Fitbit',
        // description: 'Have your Fitbit daily food log connected to Nubod.',
        image: {img: Fitbit, styles: {height: '40px'}},
        active: profile.fitbitToken,
        list: ['Get your Fitbit food logs saved directly to you Nubod account', 'Get insights as to how you are measuring against your macronutrient goals', 'Keep track of your compliance day by day']
      },
      {
        name: 'Withings',
        // description: 'Get Withings\' scale measurements saved to your profile. ',
        image: {img: Withings, styles: {height: '60px'}},
        active: profile.withings,
        action: () => window.open(`https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=d41b24e436a59b548b8ad0e4e1cc6cb36e42af5d9fe659a15addfc15801064fd&state=a_random_value&scope=user.info,user.metrics,user.activity&redirect_uri=${process.env.REACT_APP_WITHINGS_REDIRECT_URL}`),
        list: ['Get your Withings\' scale measurements saved directly to your Nubod account', 'Automatically trigger a macronutrient calculation when you step on your scale', 'Keep track of your Withings measurements and see your trends']
      }
    ]
  )
}