import { put, takeLatest, delay, call, select } from "redux-saga/effects";
import { 
  GET_RECIPES, 
  SAVE_GROCERY, 
  GET_GROCERIES, 
  DELETE_OR_UPDATE_GROCERY, 
  SAVE_RECIPE_TO_FAVORITES, 
  FETCH_FAVORITE_RECIPES_FROM_EDEMAM, 
  GET_FAVORITE_RECIPES
} from "./constants";
import {
  getRecipesSuccess,
  getRecipesError,
  saveGrocerySuccess,
  saveGroceryError,
  getGroceriesSuccess,
  getGroceriesError,
  deleteOrUpdateGroceryError,
  updateGrocerySuccess,
  deleteGrocerySuccess,
  getFavoriteRecipesSuccess,
  getFavoriteRecipesError,
  saveRecipeToFavoritesSuccess,
  saveRecipeToFavoritesError,
  fetchFavoriteRecipesFromEdemamSuccess,
  fetchFavoriteRecipesFromEdemamError
} from "./actions";
import { fetchData } from "../api";
import _ from 'lodash';

export function* getRecipes({queryObj, fromMealPlanParams}){
    // const url = `https://api.edamam.com/search?q=${queryObj.searchTerm}&app_id=${process.env.REACT_APP_EDEMAM_ID}&app_key=${process.env.REACT_APP_EDEMAM_KEY}${queryObj.fromTo}${queryObj.calorieFilter}${queryObj.healthFilter}${queryObj.dietFilter}${queryObj.mealTypeFilter}${queryObj.nutrientsRange}`;
    const url = `https://api.edamam.com/search?&app_id=${process.env.REACT_APP_EDEMAM_ID}&app_key=${process.env.REACT_APP_EDEMAM_KEY}${queryObj.fromTo ? queryObj.fromTo : ''}${queryObj.calorieFilter ? queryObj.calorieFilter : '' }${queryObj.healthFilter ? queryObj.healthFilter : ''}${queryObj.dietFilter ? queryObj.dietFilter : ''}${queryObj.mealTypeFilter ? queryObj.mealTypeFilter : ''}${queryObj.nutrientsRange ? queryObj.nutrientsRange : ''}&q=${queryObj.searchTerm}`;

    const config = {     
      headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
      }
    }

  try {
    let payload = yield call(fetchData, url, config);
    yield put(getRecipesSuccess(payload));

    if(fromMealPlanParams && fromMealPlanParams.history){
      fromMealPlanParams.history.push({
        pathname: '/dashboard/nutrition/recipes',
        state: {
          meal: fromMealPlanParams.meal,
          queryObj: queryObj,
        }
      });
    }
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getRecipesError(error));
  }
}

export function* getGroceries(){
  const url = `${process.env.REACT_APP_API_URL}/api/grocery/me`;
  try {
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getGroceriesSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield delay(2000);
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getGroceriesSuccess(payload));
    // yield put(getGroceriesError(error));
  }
}

export function* saveGrocery({dish, ingredients, image}){
  const token = localStorage.token;

  try {
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        dish: dish,
        ingredients: ingredients,
        image: image
      })
    };
    const url = `${process.env.REACT_APP_API_URL}/api/grocery`;
    let payload = yield call(fetchData, url, config);
    yield put(saveGrocerySuccess(payload));

  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(saveGroceryError(error));
  }
}

export function* deleteOrUpdateGrocery({id, action, ingredients}){
  const token = localStorage.token;
  try {
    const config = {
      method: action,
      headers: {
        "x-auth-token": token,
        'Content-Type': 'application/json'
      },
      body: action === 'POST' ? JSON.stringify({
        ingredients: ingredients.ingredients
      }) : null
    };
    const url = `${process.env.REACT_APP_API_URL}/api/grocery/${id}`;
    let payload = yield call(fetchData, url, config);
    if(action === 'DELETE'){
      yield put(deleteGrocerySuccess(id));
    } else {
      yield put(updateGrocerySuccess(payload));
    }
   
  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(deleteOrUpdateGroceryError(error));
  }
}

export function* getFavoriteRecipes(){
  const token = localStorage.token;
  try {
    const config = {
      headers: {
        "x-auth-token": token,
        'Content-Type': 'application/json'
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/recipes/favorite-recipes/me`;
    let payload = yield call(fetchData, url, config);
    yield put(getFavoriteRecipesSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(getFavoriteRecipesError(error));
  }
}

export function* saveRecipeToFavorites({ids}){
  const token = localStorage.token;
  try {
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        favoriteRecipes: ids
      })
    };
    const url = `${process.env.REACT_APP_API_URL}/api/recipes/favorite-recipes`;
    let payload = yield call(fetchData, url, config);
    yield put(saveRecipeToFavoritesSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(saveRecipeToFavoritesError(error));
  }
}

export function* fetchFavoriteRecipesFromEdemam({ids}){
  const token = localStorage.token;
  try {
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        favoriteRecipes: ids
      })
    };
    const url = `${process.env.REACT_APP_API_URL}/api/recipes/fetch-favorite-recipes`;
    let payload = yield call(fetchData, url, config);
    yield put(fetchFavoriteRecipesFromEdemamSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(err);
    yield put(fetchFavoriteRecipesFromEdemamError(error));
  }
}

export default function* recipeSaga() {
  yield takeLatest(GET_RECIPES, getRecipes);
  yield takeLatest(SAVE_GROCERY, saveGrocery);
  yield takeLatest(GET_GROCERIES, getGroceries);
  yield takeLatest(DELETE_OR_UPDATE_GROCERY, deleteOrUpdateGrocery);
  yield takeLatest(SAVE_RECIPE_TO_FAVORITES, saveRecipeToFavorites);
  yield takeLatest(GET_FAVORITE_RECIPES, getFavoriteRecipes);
  yield takeLatest(FETCH_FAVORITE_RECIPES_FROM_EDEMAM, fetchFavoriteRecipesFromEdemam);
}
