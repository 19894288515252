import React from 'react';
import { Button } from 'antd';
import { IoIosArrowForward } from "react-icons/io";
import Crown from '../../assets/images/crown1.svg';

export const UpgradeButton = ({action}) => {
  return (
    <Button type='primary' style={{background: '#0065ff'}} onClick={() => action(true)}>
      <div className='flex flex-center-aligned'>
        <img src={Crown} style={{height: '16px', marginRight: '8px'}}/>
        <p>Upgrade</p>
        <IoIosArrowForward style={{fontSize: '18px', marginLeft: '5px'}}/>
      </div>
    </Button>
  )
}