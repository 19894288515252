import { put, delay, takeLatest, call, all, select } from "redux-saga/effects";
import {
  GET_USER_FITBIT_LOGS_BY_ID,
  GET_USER_NUTRITION_BY_ID,
  GET_USER_NUTRITION_PREFERENCE_BY_ID,
  DELETE_MEALPLAN_TEMPLATE,
  UPDATE_MEALPLAN_BY_ID,
  GET_MEALPLAN_BY_ID,
  GET_COACH_MEALPLANS,
  GET_USER_MEALPLAN_BY_ID,
  GET_CLIENT_PROFILE_BY_ID,
  UPDATE_CLIENT_MACROS,
  SAVE_CLIENT_NUTRITION,
  DELETE_CUSTOM_MEAL_PLAN
  // ADD_CLIENT_WEIGHT_TO_PROFILE
} from "./constants";
import {
  getUserFitbitLogsByIdSuccess,
  getUserFitbitLogsByIdError,
  getUserNutritionByIdSuccess,
  getUserNutritionByIdError,
  getUserNutritionPreferenceByIdSuccess,
  getUserNutritionPreferenceByIdError,
  deleteMealPlanTemplateSuccess,
  updateMealplanByIdSuccess,
  getMealplanByIdSuccess,
  getCoachMealPlansSuccess,
  getUserMealPlanByIdSuccess,
  getUserMealPlanByIdError,
  getClientProfileByIdSuccess,
  getClientProfileByIdError,
  updateClientMacrosSuccess,
  updateClientMacrosError,
  saveClientNutritionSuccess,
  saveClientNutritionError,
  deleteCustomMealPlanSuccess,
  // addClientWeightToProfileSuccess,
  // addClientWeightToProfileError
} from "./actions";
import { fetchData, postData } from "../api";
import moment from "moment";
import { toast } from "react-toastify";

export function* getUserFitbitLogsById({userId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({userId: userId})
    };
    let url = `${process.env.REACT_APP_API_URL}/auth/fitbit/food-logs/${userId}`;
    let payload = yield call(fetchData, url, config);
    yield put(getUserFitbitLogsByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    console.log(err);
    yield put(getUserFitbitLogsByIdError(error));
  }
}

export function* getUserNutritionById({userId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({userId: userId})
    };
    let url = `${process.env.REACT_APP_API_URL}/api/nutrition/user/${userId}`;
    let payload = yield call(fetchData, url, config);
    yield put(getUserNutritionByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getUserNutritionByIdError(error));
  }
}

export function* getUserNutritionPreferencesById({userId}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/nutrition-preference/user/${userId}`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    let nutritionPreferences = yield call(fetchData, url, config);
    yield put(getUserNutritionPreferenceByIdSuccess(nutritionPreferences));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getUserNutritionPreferenceByIdError(error));
  }
}

export function* deleteMealPlanTemplate({id}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/mealplan/template/${id}`;
    yield call(fetchData, url, config);
    yield put(deleteMealPlanTemplateSuccess(id));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* updateMealplanById({id, plan}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'PUT',
      body: JSON.stringify({...plan}),
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/mealplan/template/${id}`;
    const payload = yield call(fetchData, url, config);
    yield put(updateMealplanByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* getMealplanById({id}){
  try {
    let payload
    if (id === 'new') {
      const getFormattedPlan = function(s,e) {for(var a={},d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a[moment(d).format('YYYY-MM-DD')] = []}return a;}
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      const { startDate, endDate, preference } = params
      payload = {
        plan: {
          mealplan: getFormattedPlan(new Date(startDate), new Date(endDate)),
          preference,
          target: {
            calories: 0,
            carb: 0,
            fats: 0,
            protein: 0
          }
        }
      }
      yield delay(1)
    } else {
      const token = window.localStorage.token;
      const config = {
        method: 'GET',
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json"
        }
      };
      const url = `${process.env.REACT_APP_API_URL}/api/mealplan/template/${id}`;
      payload = yield call(fetchData, url, config);
    }
    yield put(getMealplanByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* getCoachMealPlans({drafts}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'GET',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/mealplan/myTemplates${drafts ? '?draft=true' : ''}`;
    const payload = yield call(fetchData, url, config);
    yield put(getCoachMealPlansSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* getUserMealPlanById({userId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'GET',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/profile/user/${userId}`;
    const payload = yield call(fetchData, url, config);
    yield put(getUserMealPlanByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getUserMealPlanByIdError(error))
  }
}

export function* getClientProfileById({userId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'GET',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/profile/user/${userId}`;
    const payload = yield call(fetchData, url, config);
    yield put(getClientProfileByIdSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getClientProfileByIdError(error))
  }
}

const showToasterForProfileUpdate = (msg) => {
  toast.success(msg ? msg : '🎉 Your assessment data have been updated!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function* updateClientMacros(payload) {
  const token = localStorage.token;
  const body = payload.clientProfile;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/profile`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }
    const payload = yield call(fetchData, url, config);
    yield put(updateClientMacrosSuccess(payload)); 
    const nutrition = yield select((state) => state.admin.nutrition);
    yield all([
      call(saveClientNutrition, nutrition),
      // call(addClientWeightToProfile, body),
    ]);
    showToasterForProfileUpdate('🎉 Macronutrients data have been updated!');
    
  } catch (err) {
     const error = yield err.json();
     console.log(error);
     yield put(updateClientMacrosError(error));
  }
}

export function* saveClientNutrition(nutrition) {
  const token = localStorage.token;
  try {
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    };
    const userId = yield select((state) => state.admin.clientProfile.user._id);
    const url = `${process.env.REACT_APP_API_URL}/api/nutrition/${userId}`;
    yield call(() => postData(url, config, nutrition.nutritionResults));
    yield put(saveClientNutritionSuccess(nutrition.nutritionResults));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(saveClientNutritionError(error));
  }
}

export function* deleteCustomMealPlan({mealPlanId}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${process.env.REACT_APP_API_URL}/api/profile/delete-meal-plan/${mealPlanId}`;
    yield call(fetchData, url, config);
    yield put(deleteCustomMealPlanSuccess(mealPlanId));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

// export function* addClientWeightToProfile(clientProfile) {
//   let weightKg = clientProfile.isMetric ? clientProfile.currentWeight : Number(poundsToKgs(clientProfile.currentWeight));
//   let weightLb = !clientProfile.isMetric ? clientProfile.currentWeight : Number(kgsToPounds(clientProfile.currentWeight));
//   let weight = { weightKg: weightKg, weightLb: weightLb };
//   if (!clientProfile.weight.length || ((+weightKg !== clientProfile.weight[0].weightKg) && (weightLb !== clientProfile.weight[0].weightLb))) {
//     const token = localStorage.token;
//     try {
//       const url = `${process.env.REACT_APP_API_URL}/api/profile/weight`;
//       const config = {
//         method: "PUT",
//         headers: {
//           "x-auth-token": token,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(weight)
//       }
//       const payload = yield call(fetchData, url, config);
//       yield put(addClientWeightToProfileSuccess(payload));
  
//     } catch (err) {
//        const error = yield err.json();
//        console.log(error);
//        yield put(addClientWeightToProfileError(error));
//     }
//   }
// }

export default function* adminSaga() {
  yield takeLatest(GET_USER_FITBIT_LOGS_BY_ID, getUserFitbitLogsById);
  yield takeLatest(GET_USER_NUTRITION_BY_ID, getUserNutritionById);
  yield takeLatest(GET_USER_NUTRITION_PREFERENCE_BY_ID, getUserNutritionPreferencesById);
  yield takeLatest(DELETE_MEALPLAN_TEMPLATE, deleteMealPlanTemplate);
  yield takeLatest(UPDATE_MEALPLAN_BY_ID, updateMealplanById);
  yield takeLatest(GET_MEALPLAN_BY_ID, getMealplanById);
  yield takeLatest(GET_COACH_MEALPLANS, getCoachMealPlans);
  yield takeLatest(GET_USER_MEALPLAN_BY_ID, getUserMealPlanById);
  yield takeLatest(GET_CLIENT_PROFILE_BY_ID, getClientProfileById);
  yield takeLatest(UPDATE_CLIENT_MACROS, updateClientMacros);
  yield takeLatest(SAVE_CLIENT_NUTRITION, saveClientNutrition);
  yield takeLatest(DELETE_CUSTOM_MEAL_PLAN, deleteCustomMealPlan);
  // yield takeLatest(ADD_CLIENT_WEIGHT_TO_PROFILE, addClientWeightToProfile);
}