import React from 'react';
import ReactDOM from 'react-dom';
import App, {store} from './App';
import {Provider} from "react-redux";
import HttpsRedirect from "react-https-redirect";
import ErrorBoundary from './components/ErrorBoundary';

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);