import React from 'react';
import './index.css';
import ShoppingItemElement from './ShoppingItemElement'


class CustomShoppingPage extends React.Component {

  deleteFromShoppingList = (id) => {
    this.props.deleteFromShoppingList(id)
  };

  render(){
    const {shoppingList} = this.props;
    return(
      <div className='suggestic-shopping-list'>
      {(shoppingList || []).map((item, itemIndex) => (
        <ShoppingItemElement
          updateCustomShoppingListItem={this.props.updateCustomShoppingListItem}
          handleDeleteFromShoppingList={this.deleteFromShoppingList}
          item={item}
          itemIndex={itemIndex}
          key={itemIndex}
        />
      ))}
      </div>
    )
  }
}

export default CustomShoppingPage;