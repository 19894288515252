import React from 'react';
import { capitalize } from '../../helpers/utils'
import './index.css';
import { Input, Switch, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import Avatar from "../../assets/images/avatar.png";
import { async } from 'q';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class UserProfilePage extends React.Component {

  constructor() {
    super();
    this.state = {
      userSettings: {},
      inputFile: React.createRef()
    }
  }

  componentDidMount() {
    this.setState({
      userSettings: this.props.userSettings,
    })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.userSettings !== this.props.userSettings) {
      this.setState({
        userSettings: this.props.userSettings || {}
      })
    }
  }

  renderHeader = () => {
    return (
      <div className='profile-header-background' style={{ background: '#2bffcc' }}>
      </div>
    )
  };

  handleProfileImageChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.props.updateUserProfile({ file: reader.result });
      }
    }
    e.target.value = "";

  };

  renderProfileImg = () => {
    return (
      <div className='flex flex-center-aligned'>
        <div className='user-profile-wrap'>
          <div
            className='user-profile-image'
            style={{
              background: `url(${this.props.profile.user.avatar || Avatar})`,
              minHeight: '100%',
              backgroundSize: 'cover'
            }}
          />
          {
            this.props.updatingUserInfo ?
              <Spin className="edit-profile-icon" indicator={antIcon} />
              :
              <>
                <i className="fas fa-pencil-alt flex edit-profile-icon"
                  onClick={() => this.state.inputFile.current.click()} />
                <input
                  type='file'
                  id='file'
                  accept="image/*"
                  ref={this.state.inputFile}
                  style={{ display: 'none' }}
                  onChange={this.handleProfileImageChange.bind(this)}
                />
              </>
          }

        </div>
        <p className='user-profile-title'>{this.props.profile.user.name} {this.props.profile.user.lastName}</p>
      </div>
    )
  }

  // setLocation = (location) => {
  //   let profileData = { ...this.state.profileData };
  //   profileData["location"] = location;
  //   this.setState({ profileData: profileData });
  // ;

  renderInputFields = () => {
    return (
      <div className='form user-profile-details'>
        <div className="form-heading profile">
          <p className="form-title">Profile details</p>
          <p className="form-subtitle">If you need to change these fields, please <a href="mailto:coach@getnubod.com" target='_blank'>contact us</a>.</p>
        </div>
        <div className='form-row flex space-between'>
          <div className="form-section">
            <p className="label">Gender</p>
            <Input
              value={capitalize(this.props.profile.gender)}
            />
          </div>
          <div className="form-section">
            <p className="label">Age</p>
            <Input
              value={this.props.profile.age}
            />
          </div>
        </div>

        {this.props.profile.isMetric ?
          <div>
            <div className="form-section">
              <p className="label">Height Cm.</p>
              <Input
                value={this.props.profile.height}
              />
            </div>
          </div> :
          <div className='form-row flex space-between'>
            <div className="form-section">
              <p className="label">Height Ft.</p>
              <Input
                value={this.props.profile.heightFt}
              />
            </div>
            <div className="form-section">
              <p className="label">Height In.</p>
              <Input
                value={this.props.profile.heightIn}
              />
            </div>
          </div>}

        {/* <div className='grid'>
          <div className="form-heading">
            <p className="form-title">Contact</p>
            <p className="form-subtitle">Complete your contact details.</p>
          </div>
          <div>
            <div className="form-row flex space-between">
              <div className="form-section">
                <p className="label">Location</p>
                <LocationSearchInput
                  location={this.props.profile ? this.props.profile.location : ""}
                  setLocation={this.setLocation}
                />
              </div>
              <div className="form-section">
                <p className="label">Phone number</p>
                <Input
                  defaultValue={this.props.profile ? this.props.profile.phoneNumber : ""}
                  onChange={(e) => this.handleChange(e, "phoneNumber")}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
 
  handleChange = async (checked) => {
    let isNotificationEnabled;
    const OneSignal = window.OneSignal || [];
    if(!checked){
     OneSignal.push(async function() {
         await OneSignal.setSubscription(false);
         isNotificationEnabled=false
      });
    }else if(checked) {
       OneSignal.showNativePrompt();
    }
    OneSignal.on('subscriptionChange', (isSubscribed) => {
      // console.log("Push Notification Subscription Status onchange:", isSubscribed);
      isNotificationEnabled=isSubscribed
      this.props.createUserSetting({ ...this.state.userSettings, active: isSubscribed });
    });
    // if (this.state.userSettings) {
    //   this.props.createUserSetting({ ...this.state.userSettings, active: isNotificationEnabled });
    // }
    };

  renderSettings = () => {
    return (
      <div className='flex space-between'>
        <div class="form-heading profile">
          <p class="form-title">Notifications</p>
          <p class="form-subtitle">Receive push notifications about your program and other updates. (Recommended)</p>
        </div>
        <Switch
          onChange={(checked) => this.handleChange(checked)}
          checked={this.state.userSettings && this.state.userSettings.active}
        />
      </div>
    )
  }

  render() {
    return (
      <>
        <div className='user-profile-page'>
          {this.props.profile &&
            <>
              <div className='user-profile-container'>
                {this.renderSettings()}
              </div>
            </>}
        </div>
      </>
    )
  }
}

export default UserProfilePage;