import React from 'react';
import { OneRepMaxTable } from '../../components/ExerciseHistoryModal/OneRepMaxTable';
import '../../presentational/StatsPage/index.css';

export const OneRepMaxTool = () => {
  return (
    <div className='site-layout-background tools-page'>
      <OneRepMaxTable />
    </div>
  )
}