import React from 'react';
import { PricingModal } from '../../components/PricingModal';
import { splashPage } from '../../data/splashPage';
import { UpgradeButton } from '../../components/UpgradeButton';

export const SplashAdvanced = ({page, handlePricingModal, pricingModalOpen, profile}) => 
<>
  <PricingModal 
    pricingModalOpen={pricingModalOpen} 
    handlePricingModal={handlePricingModal} 
    profile={profile}
  /> 
  <div className='advanced-splash'>
    <div className='splash-header flex flex-center-aligned space-between'>
      <div className='text-block'>
        <p className='title mb-2'>A custom fitness program that changes with your body</p>
        <p className='main-page-subtitle'>Fitness doesn't have to be hard. Join Nubod today to get the personalized, adaptable plan you need to succeed.</p>
        <div className='flex flex-center-aligned'>
          <UpgradeButton action={handlePricingModal}/>
          <a className='blue-link' style={{marginLeft: '20px'}} href="https://www.getnubod.com">Learn more</a>
        </div>
        
      </div>
      <img className='img-block' src={splashPage[page].mainImage}/>
    </div>
  </div>
</>