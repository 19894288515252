import React from "react";
import { MetaTags } from "react-meta-tags";

const metaConfig = {
	default: {
		altImg: "Nubod -logo",
		title: "",
		description: "",
		url: "",
	},
	"/": {
		altImg: "Nubod -logo",
		title: "Nubod - Simplified Online Training & Nutrition",
		description:
			"Nubod is a complete online training platform that simplifies and automates your fitness and nutrition.",
	},
	"/assessment": {
		altImg: "Nubod -logo",
		title: "Nubod Assessment",
		description:
			"Take the Nubod assessment and get recommendations based on your results.",
	},
	// "/assessment/results": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "Assessment Results - Alvaro Fitness",
	// 	description: "Your results and personal recommendations.",
	// },
	// "/training/onsite-or-virtual": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "On-Site & Virtual Personal Training - Alvaro Fitness",
	// 	description:
	// 		"Dallas Personal Training. Train on-site or at home and get guaranteed results with the right training and nutrition plan for you.",
	// },
	// "/training/online-coaching": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "Online Personal Training - Alvaro Fitness",
	// 	description:
	// 		"Online Personal Training with Alvaro wherever you are! Lose fat, pack lean muscle, and get in the best shape of your life.",
	// },
	// "/contact": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "Get In Touch - Alvaro Fitness",
	// 	description:
	// 		"Take the fist step towards a happier, better you. I can't wait to meet you.",
	// },
	// "/blog": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "Blog - Alvaro Fitness",
	// 	description:
	// 		"Stay up to date with the latest health, fitness, and lifestyle tips from Alvaro Fitness blogs!",
	// },
	"/disclaimer": {
		altImg: "Nubod -logo",
		title: "Disclaimer - Nubod",
		description: "Read the disclaimer.",
	},
	"/privacy-policy": {
		altImg: "Nubod -logo",
		title: "Privacy Policy - Nubod",
		description: "Read the privacy policy.",
	},
	// "/programs": {
	// 	altImg: "Alvaro Fitness -logo",
	// 	title: "Programs - Alvaro Fitness",
	// 	description:
	// 		"Science-Driven fitness programs that will get you in the best shape of your life.",
	// },
};

export default function MetasForPage({ loc, config }) {
	const { img, altImg, title, description } = config
		? config
		: metaConfig.hasOwnProperty(loc)
		? metaConfig[loc]
		: metaConfig.default;

	return (
		<MetaTags>
			{/* Facebook and LinkedIn related */}
			<meta
				property="fb:app_id"
				content={process.env.REACT_APP_FACEBOOK_APP_ID || "654985024936346"}
			/>
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="Nubod" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* <meta
				property="og:image"
				content="https://www.alvarofitness.com/meta-img.png"
			/> */}
			<meta
				property="og:image"
				content={img ? img : "https://www.getnubod.com/fb-meta.png"}
			/>
			<meta property="og:image:width" content="200" />
			<meta property="og:image:height" content="200" />
			<meta property="og:image:alt" content={altImg} />
			<meta property="og:url" content={window.location.href.split("?")[0]} />
			{/* Twitter related */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:text:title" content={title} />
			<meta
				name="twitter:image"
				content={img ? img : "https://www.getnubod.com/meta-twitter.png"}
			/>
			<meta name="twitter:description" content={description} />
			<meta name="twitter:url" content={window.location.href.split("?")[0]} />
			<meta name="twitter:image:alt" content={altImg} />
			<meta name="twitter:site" content="@getnubod" />
		</MetaTags>
	);
}
