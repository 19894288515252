import React from 'react';
import { connect } from 'react-redux';
import AdminMealPlanPage from '../../presentational/AdminMealPlanPage';
import {getMealplanById, getUserMealPlanById, getUserNutritionById} from '../../ducks/Admin/actions';
import {SET_PROGRAM_VISIBILITY} from "../../ducks/Global/constants";
import {getAllUserNotifications} from "../../ducks/Onesignal/actions";

class AdminCreateMealPlan extends React.Component {

  componentDidMount(){
    if (window.location.search.includes('mealplan')) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const { mealplan:mealPlanId, startDate, endDate } = Object.fromEntries(urlSearchParams.entries())
      this.props.getMealplanById(mealPlanId);
    }  
    const userId = this.props.match.params.id;
    if(userId) {
      this.props.getUserNutritionById(userId);
    }
    this.props.getUserMealPlanById(this.props.match.params.id);
  }

  render(){
    return(
      <>
       {!this.props.userMealplanLoading &&
        <AdminMealPlanPage
          user={this.props.userMealplan.user}
          saveWithoutModal={true}
          mealPlan={this.props.userMealplan.customMealPlan}
          adminMealPlan={(window.location.search.includes('mealplan') && this.props.adminMealPlan.plan) ? this.props.adminMealPlan.plan : null}
          clientNutrition={this.props.allUserNutrition && this.props.allUserNutrition[0]}
          history={this.props.history}
          setProgramVisibility={this.props.setProgramVisibility}
          getAllUserNotifications={this.props.getAllUserNotifications}
        />
       }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userMealplan: state.admin.userMealplan,
    adminMealPlan: state.admin.mealplan,
    userMealplanLoading: state.admin.userMealplanLoading,
    allUserNutrition: state.admin.allUserNutrition,
    allUserNutritionError: state.admin.allUserNutritionError,
    allUserNutritionLoading: state.admin.allUserNutritionLoading
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getUserMealPlanById: (userId) => dispatch(getUserMealPlanById(userId)),
    getMealplanById: (mealPlanId) => dispatch(getMealplanById(mealPlanId)),
    getUserNutritionById: (id) => dispatch(getUserNutritionById(id)),
    setProgramVisibility: () => dispatch({
      type: SET_PROGRAM_VISIBILITY,
      payload: true
    }),
    getAllUserNotifications: (email) => dispatch(getAllUserNotifications(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreateMealPlan);