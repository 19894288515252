  
import React, { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Input } from 'antd';


export default function PasswordField(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const IconComponent = passwordVisible ? EyeOutlined : EyeInvisibleOutlined;
  return (
    <Input
      type={passwordVisible ? 'text' : 'password'}
      style={{ position: "relative" }}
      suffix={
        <IconComponent
          onClick={() => setPasswordVisible(!passwordVisible)}
          style={{ 
            color: 'rgba(0,0,0,.45)', 
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translateY(-50%)" 
          }} 
          />
      }
      {...props}
    />
  );
}

PasswordField.defaultProps = {
  autoComplete: 'current-password',
};
