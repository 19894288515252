import {
  CREATE_USER_SETTING,
  CREATE_USER_SETTING_SUCCESS,
  CREATE_USER_SETTING_ERROR,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_ERROR
} from "./constants";

const initialState = {
  userSettings: [],
  loading: null,
  error: null
};

function settings(state = initialState, action) {
  const { type, payload, err} = action;
  switch (type) {
    case CREATE_USER_SETTING:
      return {
        ...state,
        userSettings: state.userSettings,
        loading: true,
        error: null
      };
    case CREATE_USER_SETTING_SUCCESS:
      return {
        ...state,
        userSettings: payload,
        loading: false,
        error: null
      };
    case CREATE_USER_SETTING_ERROR:
      return {
        ...state,
        userSettings: state.userSettings,
        loading: false,
        error: err
      };
    case GET_USER_SETTINGS:
      return {
        ...state,
        userSettings: state.userSettings,
        loading: true,
        error: null
      };
    case GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        userSettings: payload,
        loading: false,
        error: null
      };
    case GET_USER_SETTINGS_ERROR:
      return {
        ...state,
        userSettings: state.userSettings,
        loading: false,
        error: err
      };
    default:
      return state;
  }
}

export default settings;