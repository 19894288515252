import React from 'react';
import moment from 'moment';
import { Popover, Button, Dropdown, Menu} from 'antd';
import { renderClientNameEmail, formatUserStatus } from '../../helpers/utils';
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import { EllipsisOutlined } from '@ant-design/icons';
import './index.css';

export const ClientCard = ({history, client, setUserToDelete}) => {

  const calculatePlanDaysLeft = () => {
    if(client.user && client.user.startDate &&  client.user.expiryDate){
      let startDate = moment(client.user.startDate);
      let expiryDate = moment(client.user.expiryDate);
      let diff = expiryDate.diff(startDate, 'days');
      return diff;
    } else {
      return '-';
    }
  }

  const menu = (client) => (
    <Menu>
      <Menu.Item onClick={() => setUserToDelete(client)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  
  return(
    <>        
      <div className='home-card'>
        <div className='flex flex-center-aligned space-between'>
          <div>
            {renderClientNameEmail(client, history)}
            {client.user && client.user.duration ?
            <div className='pro-label' style={{marginLeft: '0px', marginTop: '20px', width: '80px'}}>
              {client.user.duration} months
            </div> : ''}
          </div>
          <Dropdown overlay={menu(client)} trigger={['click']} className="pointer">
            <EllipsisOutlined style={{fontSize: '24px'}}/>
          </Dropdown>
        </div>

        <div style={{paddingTop: '20px'}} className='flex flex-center-aligned space-between'>
          <div>
            <p className='blog-card-category mb-1'>Started on</p>
            <p className='home-card-text'>{client.user && client.user.startDate ? moment(client.user.startDate).format('ll') : client.user && client.user.date && moment(client.user.date).format('ll')}</p>
          </div>
        
          <div>
            <p className='blog-card-category mb-1'>Expires on</p>
            <p className='home-card-text'>{client.user && client.user.expiryDate ? moment(client.user.expiryDate).format('ll') : '-'}</p>
          </div>
        </div>

        <div style={{paddingTop: '20px'}} className='flex flex-center-aligned space-between'>
          <div>
            <p className='blog-card-category mb-1'>Days remaining</p>
            <p className='home-card-text'>{calculatePlanDaysLeft()}</p>
          </div>
        
          <div>
            <p className='blog-card-category mb-1'>Status</p>
            {client.user && client.user.isSubscriptionActive ?
            <MdThumbUp style={{fontSize: '20px', color: '#2bffcc'}}/> 
            :
            <MdThumbDown style={{fontSize: '20px', color: 'orange'}}/>}
          </div>
        </div>
      </div>
    </>
  )
}