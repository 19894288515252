import React, {useState} from 'react';
import { Button, Divider} from 'antd';
import Webcam from "react-webcam";
import { UploadOutlined } from '@ant-design/icons';
import './index.css';

const WebcamCapture = (props) => {

  const webcamRef = React.useRef(null);

  const [isPreview, setIsPreview] = useState(false);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const picturesInProps = props.photos;
      picturesInProps.push(imageSrc);
      setIsPreview(current => !current);
      props.handler(picturesInProps);
    },
    [webcamRef]
  );

  const declinePhoto = () => {
    setIsPreview(current => !current);
    const picturesInProps = props.photos;
    picturesInProps.pop();
    props.handler(picturesInProps);
  };

  return (
    <div>
      { !isPreview &&
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
          <Button onClick={capture}>Capture photo</Button>
        </div>
      }
      { isPreview &&
        <div>
          <img className="user-photo" src={props.photos[props.photos.length - 1]} alt="Photo" />
          <Button onClick={props.closeWebcam} type="primary">Accept</Button>
          <Button onClick={declinePhoto} className="decline-btn">Decline</Button>
        </div>
      }
    </div>
  )
}

class ProgressUpload extends React.Component {
  state = {
    isWebcamActive: false
  };

  closeWebcamWindow = () => {
    this.setState({
      isWebcamActive: false
    })
  };

  removePicture = (index) => {
    if (!this.state.isWebcamActive) {
      const filteredPictures = this.props.photos.filter((item, itemIndex) => index !== itemIndex);
      this.props.handler(filteredPictures);
      document.getElementById(this.props.location).value = "";
    }
  };

  render() {
    return(
      <div>
        <div>
          <input 
            type="file" 
            name="photo" 
            accept=".jpg, .jpeg, .png" 
            className="upload-photo" 
            id={this.props.location}    
            multiple
            onChange={(event) => {
              const photos = [...event.target.files];
              const readedPhotos = [];

              const addPhotos = () => {
                if ((this.props.photos.length + readedPhotos.length) <= 3) {
                  this.props.handler([...this.props.photos, ...readedPhotos]);
                } else {
                  alert('Photo limit exceeded')
                }
              }

              for (const [index, photo] of photos.entries()) {
                const reader = new FileReader();
                reader.readAsDataURL(photo);
                reader.onload = () => {
                  let image = new Image();
                  image.src = reader.result;
                  image.onload = () => {
                    if (image.width > image.height) {
                      alert('Please upload a vertical image (3:4)')
                    }
                    readedPhotos[index] = reader.result;
                    if (!readedPhotos.includes(undefined) && photos.length === readedPhotos.length) {
                      addPhotos();
                    }
                  };
                };
              }
              event.target.value = "";
            }}
          />
           <Divider />
          <label 
            className="progress-upload-label" 
            onClick={() => this.setState({isWebcamActive: false})} htmlFor={this.props.location}
            >
              <UploadOutlined style={{marginRight: '3px'}}/>
              Upload Photos 
          </label>
          {/* <Button className="toggle-webcam" onClick={() => this.setState({isWebcamActive: !this.state.isWebcamActive})}>Toggle Webcam</Button> */}
        </div>
        <div className="selected-preview">
          <div>
            <span>
              Selected {this.props.photos.length} photo(s):
            </span>
          </div>
          <div className="selected-preview-images">
            <div className="preview-outer-box">
              <div className="preview-inner-box">
                {this.props.photos[0] && (
                  <span className="times" onClick={() => this.removePicture(0)}>&#x2715;</span>
                )}
                <img src={this.props.photos[0] ? this.props.photos[0] : 'https://www.genius100visions.com/wp-content/uploads/2017/09/placeholder-vertical.jpg'} />
              </div>
              <h6>
                Front
              </h6>
            </div>
            <div className="arrow">
              &rarr;
            </div>
            <div className="preview-outer-box">
              <div className="preview-inner-box">
                {this.props.photos[1] && (
                  <span className="times" onClick={() => this.removePicture(1)}>&#x2715;</span>
                )}
                <img src={this.props.photos[1] ? this.props.photos[1] : 'https://www.genius100visions.com/wp-content/uploads/2017/09/placeholder-vertical.jpg'} />
              </div>
              <h6>
                Side
              </h6>
            </div>
            <div className="arrow">
              &rarr;
            </div>
            <div className="preview-outer-box">
              <div className="preview-inner-box">
                {this.props.photos[2] && (
                  <span className="times" onClick={() => this.removePicture(2)}>&#x2715;</span>
                )}
                <img src={this.props.photos[2] ? this.props.photos[2] : 'https://www.genius100visions.com/wp-content/uploads/2017/09/placeholder-vertical.jpg'} />
              </div>
              <h6>
                Back
              </h6>
            </div>
          </div>
          {/* <div className="selected-preview-images">
            {
              this.props.photos.map((photo, index) => {
                return (
                  <div key={index}>
                    <span className="times" onClick={() => this.removePicture(index)}>&#x2715;</span>
                    <img src={photo} />
                  </div>
                )
              })
            }
          </div> */}
        </div>
        {this.state.isWebcamActive === true &&
          <WebcamCapture handler = {this.props.handler} closeWebcam = {this.closeWebcamWindow} photos = {this.props.photos} />
        }
      </div>
    )
  }
}

export default ProgressUpload;