import {
  GET_LIBRARY_WORKOUTS,
  GET_LIBRARY_WORKOUTS_SUCCESS,
  GET_LIBRARY_WORKOUTS_ERROR,
  CREATE_WORKOUT,
  CREATE_WORKOUT_SUCCESS,
  CREATE_WORKOUT_ERROR,
  UPDATE_LIBRARY_WORKOUT,
  UPDATE_LIBRARY_WORKOUT_SUCCESS,
  UPDATE_LIBRARY_WORKOUT_ERROR,
  DELETE_LIBRARY_WORKOUT,
  DELETE_LIBRARY_WORKOUT_SUCCESS,
  GET_USER_WORKOUTS,
  GET_USER_WORKOUTS_SUCCESS,
  GET_USER_WORKOUTS_ERROR,
  SET_IS_WORKOUT_EDIT,
  CLEAR_EDIT_WORKOUT,
  CREATE_USER_GENERATED_WORKOUT,
  CREATE_USER_GENERATED_WORKOUT_SUCCESS,
  CREATE_USER_GENERATED_WORKOUT_ERROR,
  SHARE_MY_WORKOUT,
  SHARE_MY_WORKOUT_SUCCESS,
  SHARE_MY_WORKOUT_ERROR
} from "./constants";

const initialState = {
  workouts: [],
  getWorkoutsLoading: false,
  getWorkoutsErr: null,
  createWorkoutLoading: false,
  createWorkoutErr: null,
  createWorkoutSuccess: false,
  isExerciseEdit: false,
  userWorkouts: [],
  deleteWorkoutLoading: {workoutId: '', loading: false},
  isWorkoutEdit: false,
  isWorkoutDraft: true,
  workoutToShare: null
};

function workouts(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LIBRARY_WORKOUTS:
      return {
        ...state,
        getWorkoutsErr: false,
        getWorkoutsLoading: true
      };
    case GET_LIBRARY_WORKOUTS_SUCCESS:
      return {
        ...state,
        workouts: payload,
        getWorkoutsLoading: false
      };
    case GET_LIBRARY_WORKOUTS_ERROR:
      return {
        ...state,
        getWorkoutsLoading: false,
        getWorkoutsErr: action.err
      };
    case CREATE_WORKOUT:
      return {
        ...state,
        createWorkoutLoading: true,
        createWorkoutErr: null,
        createWorkoutSuccess: false
      };
    case CREATE_WORKOUT_SUCCESS:
      return {
        ...state,
        workouts: action.payload.hasOwnProperty('workoutLibrary')
        ? [action.payload.workoutLibrary, ...state.workouts.filter(workout => workout._id !== action.payload.workoutIdToRemove)]
        : state.workouts,
        userWorkouts: action.payload.hasOwnProperty('workout') ? [payload.workout, ...state.userWorkouts] : state.userWorkouts,
        createWorkoutErr: null,
        createWorkoutSuccess: true,
        createWorkoutLoading: false,
        isWorkoutDraft: action.payload.workout ? action.payload.workout.draft : true
      };
    case CREATE_WORKOUT_ERROR:
      return {
        ...state,
        createWorkoutLoading: false,
        createWorkoutErr: action.err,
        createWorkoutSuccess: false
      };
    case UPDATE_LIBRARY_WORKOUT:
      return {
        ...state,
        createWorkoutLoading: true,
        createWorkoutErr: null,
        createWorkoutSuccess: false
      };
    case UPDATE_LIBRARY_WORKOUT_SUCCESS:
      return {
        ...state, 
        workouts: state.workouts.map(workout => (workout._id === payload._id) ? payload : workout),
        createWorkoutErr: null,
        createWorkoutSuccess: true,
        createWorkoutLoading: false
      };
    case UPDATE_LIBRARY_WORKOUT_ERROR:
      return {
        ...state,
        createWorkoutLoading: false,
        createWorkoutErr: action.err,
        createWorkoutSuccess: false
      }
    case DELETE_LIBRARY_WORKOUT:
      return {
        ...state,
        deleteWorkoutLoading: {id: action.id, loading: true}
      };
    case DELETE_LIBRARY_WORKOUT_SUCCESS:
      return {
        ...state, 
        workouts: state.workouts.filter(workout => (workout._id !== payload)),
        deleteWorkoutLoading: {id: action.payload, loading: false}
      };
    case GET_USER_WORKOUTS:
      return {
        ...state,
        getWorkoutsErr: false,
        getWorkoutsLoading: true
      };
    case GET_USER_WORKOUTS_SUCCESS:
      return {
        ...state,
        userWorkouts: payload,
        getWorkoutsLoading: false
      };
    case GET_USER_WORKOUTS_ERROR:
      return {
        ...state,
        getWorkoutsLoading: false,
        getWorkoutsErr: action.err
      };
    case SET_IS_WORKOUT_EDIT:
      return {
        ...state,
        isWorkoutEdit: action.value
      };
    case CLEAR_EDIT_WORKOUT:
      return {
        ...state,
        isWorkoutEdit: false
      };
    case CREATE_USER_GENERATED_WORKOUT:
      return {
        ...state,
        createWorkoutLoading: true,
        createWorkoutErr: null,
        createWorkoutSuccess: false
      };
    case CREATE_USER_GENERATED_WORKOUT_SUCCESS:
      return {
        ...state,
        userWorkouts: [payload, ...state.userWorkouts],
        createWorkoutErr: null,
        createWorkoutSuccess: true,
        createWorkoutLoading: false,
        isWorkoutDraft: false
      };
    case CREATE_USER_GENERATED_WORKOUT_ERROR:
      return {
        ...state,
        createWorkoutLoading: false,
        createWorkoutErr: action.err,
        createWorkoutSuccess: false
      };
    case SHARE_MY_WORKOUT:
      return {
        ...state,
        userWorkouts: state.userWorkouts,
        workoutToShare: null,
        getWorkoutsLoading: true
      };
    case SHARE_MY_WORKOUT_SUCCESS:
      return {
        ...state,
        workoutToShare: payload,
        getWorkoutsLoading: false
      };
    case SHARE_MY_WORKOUT_ERROR:
      return {
        ...state,
        workoutToShare: null,
        getWorkoutsLoading: false,
        getWorkoutsErr: action.err
      };
    default:
      return state;
  }
}

export default workouts;