import {
  GET_LIBRARY_WORKOUTS,
  GET_LIBRARY_WORKOUTS_SUCCESS,
  GET_LIBRARY_WORKOUTS_ERROR,
  CREATE_WORKOUT,
  CREATE_WORKOUT_SUCCESS,
  CREATE_WORKOUT_ERROR,
  UPDATE_LIBRARY_WORKOUT,
  UPDATE_LIBRARY_WORKOUT_SUCCESS,
  UPDATE_LIBRARY_WORKOUT_ERROR,
  GET_USER_WORKOUTS,
  GET_USER_WORKOUTS_SUCCESS,
  GET_USER_WORKOUTS_ERROR,
  DELETE_LIBRARY_WORKOUT,
  DELETE_LIBRARY_WORKOUT_SUCCESS,
  CREATE_USER_GENERATED_WORKOUT,
  CREATE_USER_GENERATED_WORKOUT_SUCCESS,
  CREATE_USER_GENERATED_WORKOUT_ERROR,
  SHARE_MY_WORKOUT,
  SHARE_MY_WORKOUT_SUCCESS,
  SHARE_MY_WORKOUT_ERROR
} from "./constants";

export function getLibraryWorkouts() {
  return {
    type: GET_LIBRARY_WORKOUTS
  };
}

export function getLibraryWorkoutsSuccess(payload) {
  return {
    type: GET_LIBRARY_WORKOUTS_SUCCESS,
    payload
  };
}

export function getLibraryWorkoutsError(err) {
  return {
    type: GET_LIBRARY_WORKOUTS_ERROR,
    err
  };
}

export function createWorkout(data) {
  return {
    type: CREATE_WORKOUT,
    data
  };
}

export function createWorkoutSuccess(payload) {
  return {
    type: CREATE_WORKOUT_SUCCESS,
    payload
  };
}

export function createWorkoutError(err) {
  return {
    type: CREATE_WORKOUT_ERROR,
    err
  };
}

export function updateLibraryWorkout(workout) {
  return {
    type: UPDATE_LIBRARY_WORKOUT,
    workout
  };
}

export function updateLibraryWorkoutSuccess(payload) {
  return {
    type: UPDATE_LIBRARY_WORKOUT_SUCCESS,
    payload
  };
}

export function updateLibraryWorkoutError(err) {
  return {
    type: UPDATE_LIBRARY_WORKOUT_ERROR,
    err
  };
}

export function deleteLibraryWorkout(id) {
  return {
    type: DELETE_LIBRARY_WORKOUT,
    id
  };
}

export function deleteLibraryWorkoutSuccess(payload) {
  return {
    type: DELETE_LIBRARY_WORKOUT_SUCCESS,
    payload
  };
}

export function getUserWorkouts(clientId, currentWeek, averageMonth) {
  return {
    type: GET_USER_WORKOUTS,
    clientId,
    currentWeek,
    averageMonth
  };
}

export function getUserWorkoutsSuccess(payload) {
  return {
    type: GET_USER_WORKOUTS_SUCCESS,
    payload
  };
}

export function getUserWorkoutsError(err) {
  return {
    type: GET_USER_WORKOUTS_ERROR,
    err
  };
}

export function createUserGeneratedWorkout(data) {
  return {
    type: CREATE_USER_GENERATED_WORKOUT,
    data
  };
}

export function createUserGeneratedWorkoutSuccess(payload) {
  return {
    type: CREATE_USER_GENERATED_WORKOUT_SUCCESS,
    payload
  };
}

export function createUserGeneratedWorkoutError(err) {
  return {
    type: CREATE_USER_GENERATED_WORKOUT_ERROR,
    err
  };
}

export function shareMyWorkout(workoutToken){
  return {
    type: SHARE_MY_WORKOUT,
    workoutToken
  };
}

export function shareMyWorkoutSuccess(payload) {
  return {
    type: SHARE_MY_WORKOUT_SUCCESS,
    payload
  };
}

export function shareMyWorkoutError(err) {
  return {
    type: SHARE_MY_WORKOUT_ERROR,
    err
  };
}


// export function deleteWorkout(id) {
//   return {
//     type: DELETE_WORKOUT,
//     id
//   };
// }

// export function deleteWorkoutSuccess(payload) {
//   return {
//     type: DELETE_WORKOUT_SUCCESS,
//     payload
//   };
// }

// export function deleteWorkoutError(err) {
//   return {
//     type: DELETE_WORKOUT_ERROR,
//     err
//   };
// }