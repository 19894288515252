import React from 'react';
import { getAllNutritionForUser } from '../../ducks/Profile/actions';
import { connect } from 'react-redux'; 
import { MacroHistoryCard } from '../../presentational/MacroHistory';
import { Loading } from '../../components/Loading';
import '../../presentational/MacroHistory/index.css';

class MacroHistory extends React.Component {

  componentDidMount(){
    this.props.getAllNutritionForUser();
  }

  render(){
    return(
      <div className='macros-page sub-menu'>
        {this.props.allUserNutrition ?
        <div className='macro-history-container'>
           {this.props.allUserNutrition.map(nutrition => <MacroHistoryCard nutrition={nutrition} />)}
        </div> :
        <Loading />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    allUserNutrition: state.profile.allUserNutrition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllNutritionForUser: () => dispatch(getAllNutritionForUser())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MacroHistory);