import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {  Divider, Checkbox, Alert, Button, Modal} from 'antd';
import { toast } from 'react-toastify';
import { HANDLE_IS_CARB_CYCLING_PLAN_CREATED } from '../../ducks/CarbCycling/constants';
import './index.css';

export const CarbCyclingToolPage = ({
  profile, 
  nutrition, 
  distribution, 
  createCarbCyclingPlan, 
  carbCyclingPlanLoading,
  handleCarbCyclingModalOpen,
  isCarbCyclingPlanCreated,
  isOpen
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('moderate')
  const [dayCards, setDayCards] = useState([]);
  const [selectedDays, setSelectedDays] = useState({
    moreFoodDays: [],
    restDays: []
  });
  const [warningMessage, setWarningMessage] = useState({});

  let customMealPlan = profile.customMealPlan.plan;
  console.log(customMealPlan[Object.keys(customMealPlan)[0]])

  useEffect(() => {
    if(isCarbCyclingPlanCreated){
      toast.success('Plan created successfully!')
      dispatch(handleCarbCyclingModalOpen(false))
      dispatch({type: HANDLE_IS_CARB_CYCLING_PLAN_CREATED, isCarbCyclingPlanCreated: false})
    }
  },[isCarbCyclingPlanCreated, dispatch])

  useEffect(() => {
    if(distribution){
      generatePlan();
      if (distribution.selectedDays) {
        setSelectedDays(distribution.selectedDays);
      }
      if (distribution.carbSensitivity) {
        setSelectedValue(distribution.carbSensitivity);
      }
    }
  }, [distribution])

  const carbSensitivity = {
    "high": {
      percentages: { highDay: 33, mediumDay: 23, lowDay: 13 },
      distribution: ['Low', 'Low', 'Medium', 'Low', 'Low', 'Medium', 'High']
    },
    "moderate": {
      percentages: { highDay: 43, mediumDay: 33, lowDay: 23 },
      distribution: ['Medium', 'Low', 'High', 'Medium', 'Low', 'High', 'Low']
    },
    "not-sensitive": {
      percentages: { highDay: 53, mediumDay: 43, lowDay: 33 },
      distribution: ['High', 'Medium', 'Low', 'High', 'Medium', 'Low', 'High']
    },
    "not-sure": {
      percentages: { highDay: 43, mediumDay: 33, lowDay: 23 },
      distribution: ['Medium', 'Low', 'High', 'Low', 'Medium', 'High', 'Low']
    }
  };

  const proteinPercentages = { highDay: 30, mediumDay: 35, lowDay: 40 };
  
  const calculateMacrosForEachDay = (dayType) => {
    let goalCalories = nutrition?.goalCalories;
    let dayKey = dayType.toLowerCase() + 'Day';
   
    let carbPercentage = carbSensitivity[selectedValue].percentages[dayKey];
    let proteinPercentage = proteinPercentages[dayKey];
    let fatPercentage = 100 - carbPercentage - proteinPercentage;

    return {
      protein: (goalCalories * (proteinPercentage / 100)) / 4,
      carbs: (goalCalories * (carbPercentage / 100)) / 4,
      fats: (goalCalories * (fatPercentage / 100)) / 9,
    };
  };

  const renderSelectCards = () => {

    const options = [
      {key: 1, value: 'high', text: 'Highly Sensitive', description: 'I often experience negative symptoms (like bloating, fatigue, or blood sugar spikes) after eating carbs.'},
      {key: 2, value: 'moderate', text: 'Moderately Sensitive', description: 'I sometimes have mild symptoms after consuming a lot of carbs.'},
      {key: 3, value: 'not-sensitive', text: 'Not Sensitive', description: 'I rarely or never experience discomfort or energy fluctuations from eating carbs.'},
      {key: 4, value: 'not-sure', text: 'Not Sure / Prefer Not to Say', description: 'If you\'re unsure about your carb sensitivity or prefer not to disclose this information.'},
    ]

    return (
      <div className='flex carb-tabs'>
        {options.map(option => 
          <div key={option.key} onClick={() => { setSelectedValue(option.value) }} className={`option ${selectedValue === option.value ? 'active' : ''}`}>
            <p className='home-card-text mb-2' style={{color: selectedValue === option.value && 'white'}}>{option.text}</p>
            <p className='form-subtitle'>{option.description}</p>
          </div>
        )}
      </div>
    );
  }

  const handleDaysChange = (question, newSelectedDay) => {
    setSelectedDays(prevState => {
      const updatedState = { ...prevState, [question]: newSelectedDay }
      checkAndSetMessage(updatedState, profile.goal.goal)
      return updatedState;
    });
  };

  const numberOfOverLappingDays = (daysSelected) => {
    return daysSelected['moreFoodDays'].filter(day => 
      daysSelected['restDays'].includes(day)
    ).length
  }

  const checkAndSetMessage = (updatedSelectedDays, goal) => {

    const overlappingDays =  numberOfOverLappingDays(updatedSelectedDays);
  
    if ((goal === 'lose body fat' || goal === 'body recomposition') && overlappingDays >= 2) {
        // Set a specific message for this condition
        setWarningMessage({title: "Optimizing Your Plan for Fat Loss", description: "We've noticed multiple high-calorie days overlapping with rest days. For fat loss or body recomposition, it's generally more effective to align high-calorie days with active days. This helps maximize fat burning and energy utilization. We recommend limiting this overlap to 1 day to support your goals."});
    } else if((goal !== 'lose body fat' && goal !== 'body recomposition') && overlappingDays >= 2){
        setWarningMessage({title: "Balancing Calories and Recovery", description: "You've chosen several overlapping high-calorie and rest days. While this can support recovery and muscle growth, it's typically beneficial to align higher calorie intake with active days for overall balance. Feel free to adjust based on your personal recovery needs and dietary preferences or keep it if it suits your schedule better!"});
    } else {
        setWarningMessage({})
    }
  };

  const renderSelectWeekDays = (question) => {

    const weekDays = [
      {key: 1, day: 'Monday'}, 
      {key: 2, day: 'Tuesday'}, 
      {key: 3, day: 'Wednesday'}, 
      {key: 4, day: 'Thursday'}, 
      {key: 5, day: 'Friday'}, 
      {key: 6, day: 'Saturday'}, 
      {key: 7, day: 'Sunday'}
    ];

    return(
      <div style={{padding: '20px 0 30px'}}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={(value) => handleDaysChange(question, value)}
          value={selectedDays[question] ?? []}
        >
          {weekDays.map(value => 
          <Checkbox
              className="custom-checkbox"
              key={value.key}
              value={value.day}
              disabled={selectedDays[question].length >= 3 && !selectedDays[question].includes(value.day)}
            >
            {value.day}
          </Checkbox>
          )}
        </Checkbox.Group>
        {/* {selectedDays[question].length >= 3 && (
          <div className='icon-text-grid mt-1' style={{ color: '#2761f1' }}>
            <BiCheck style={{fontSize: '24px'}}/>
            <p>Maximum of 3 days selected. To choose a different day, first deselect one of the currently selected days.</p>
          </div>
        )} */}
      </div>
    )
  }

  const generateDynamicDistribution = (restDays, highCalorieDays, userGoal) => {
    const baseDistribution = carbSensitivity[selectedValue].distribution;
  
    let dynamicDistribution = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      .map((day, index) => ({ day, type: baseDistribution[index % baseDistribution.length] }));
  
    //set rest days to low
    restDays.forEach(day => {
      let dayIndex = dynamicDistribution.findIndex(d => d.day === day);
      if (dayIndex !== -1) dynamicDistribution[dayIndex].type = 'Low';
    });
  
    highCalorieDays.forEach(day => {
      let dayIndex = dynamicDistribution.findIndex(d => d.day === day);
      if (dayIndex !== -1) {
        // Check if the day is in restDays (a rest day)
        const isRestDay = restDays.includes(day);
        // If it's a rest day and the goal is fat loss/body recomposition, keep as 'Low'
        if (isRestDay && (userGoal === 'lose body fat' || userGoal === 'body recomposition')) {
          return;
        }
        dynamicDistribution[dayIndex].type = 'High';
      }
    });
    dispatch(createCarbCyclingPlan(selectedValue, dynamicDistribution, selectedDays))
  }

  const generatePlan = () => {
    if (profile && nutrition) {
      // Create cards based on dynamic distribution
      const cards = distribution && distribution.dayDistribution.map((dayInfo, i) => {
        const macros = calculateMacrosForEachDay(dayInfo.type);
        return (
          <div key={i} className='global-card'>
            <p className='blog-card-category' style={{ textTransform: 'uppercase' }}>
              {dayInfo.day} - {dayInfo.type} Carb Day
            </p>
            <p className='macro-label-bottom'>
              Protein: {Math.round(macros.protein)}g | Carbs: {Math.round(macros.carbs)}g | Fats: {Math.round(macros.fats)}g
            </p>
          </div>
        );
      });
      // Update the state with the new cards and end loading
      setDayCards(cards);
    }
  };
  
  return (
    <>
    {/* <Button type='primary' onClick={() => setShowForm(!showForm)}>Create New Plan</Button> */}
    <Modal
      className='dashboard-content'
      visible={isOpen}
      width={800}
      centered
      footer={null}
      onCancel={() => dispatch(handleCarbCyclingModalOpen(false))}
    >
      <div className='form'>
        <div className='mb-2'>
          <p className='form-title'>Instructions:</p>
          <p className='mt-1'>
            To ensure an accurate and optimal carb cycling plan, please update your macro calculator form with your current weight, goal, and activity level before creating a plan. <span onClick={() => history.push('/dashboard/tools/macro-calculator', { from: 'carb-cycling-update'})} className='blue-link'>Update.</span>
          </p>
        </div>
        <Divider />
        <div>
          <div>
            <p className='form-title'>Are you sensitive to carbohydrates?</p>
            <p className='mt-1'>Carbohydrate sensitivity refers to how your body reacts to consuming carbs. People who are sensitive to carbs may experience symptoms like fatigue, bloating, or rapid changes in blood sugar levels after eating carbohydrate-rich foods. Understanding your carb sensitivity can help us tailor your carb cycling plan more effectively.</p>
          </div>
          {renderSelectCards()}
        </div>
        <div>
          <div>
            <p className='form-title'>On which days do you usually engage in more social eating or consume more calories?</p>
            <p className='mt-1'>Select the days you typically eat more, often due to social events or personal habits. We'll use this to align high-carb days with your higher calorie days, helping you manage your diet more effectively without compromising on social occasions.</p>
          </div>
          {renderSelectWeekDays('moreFoodDays')}
        </div>
        <div>
          <div>
            <p className='form-title'>Which day(s) of the week are typically your rest days?</p>
            <p className='mt-1'>Choose your typical rest days when you're less physically active. This helps us plan low-carb days to align with your lower activity levels, aiding in effective weight management and ensuring your diet complements your lifestyle.</p>
          </div>
          {renderSelectWeekDays('restDays')}
        </div>
        {(profile?.goal.goal === 'lose body fat' || profile?.goal.goal === 'body recomposition') && numberOfOverLappingDays(selectedDays) > 1 ? 
        null : 
        <div className='form-section button-section'>
          <div></div>
          <Button 
            onClick={() => generateDynamicDistribution(selectedDays.restDays, selectedDays.moreFoodDays, profile && profile.goal.goal)}
            type='primary' 
            style={{position: 'right'}} 
            disabled={(profile?.goal.goal === 'lose body fat' || profile?.goal.goal === 'body recomposition') &&  numberOfOverLappingDays(selectedDays) > 1}
            loading={carbCyclingPlanLoading}
          >
            Generate Weekly Plan
          </Button>
        </div>}
        {warningMessage.title && warningMessage.description && <Alert className='mb-3' style={{backgroundColor: 'rgb(240, 242, 255)', border: 'none', borderRadius: '10px'}} message={warningMessage.title} description={warningMessage.description} type="info" showIcon/>}
      </div>
    </Modal>
    {distribution &&
    <div className='day-cycling-container'>
      {dayCards}
    </div> }
    </>
  )
}