export const GET_WORKOUT_PROGRAM_LIBRARY = 'workoutProgram/GET_WORKOUT_PROGRAM_LIBRARY';
export const GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS = 'workoutProgram/GET_WORKOUT_PROGRAM_LIBRARY_SUCCESS';
export const GET_WORKOUT_PROGRAM_LIBRARY_ERROR = 'workoutProgram/GET_WORKOUT_PROGRAM_LIBRARY_ERROR';

export const CREATE_WORKOUT_PROGRAM = 'workoutProgram/CREATE_WORKOUT_PROGRAM';
export const CREATE_WORKOUT_PROGRAM_SUCCESS = 'workoutProgram/CREATE_WORKOUT_PROGRAM_SUCCESS';
export const CREATE_WORKOUT_PROGRAM_ERROR = 'workoutProgram/CREATE_WORKOUT_PROGRAM_ERROR';

export const UPDATE_WORKOUT_PROGRAM = 'workoutProgram/UPDATE_WORKOUT_PROGRAM';
export const UPDATE_WORKOUT_PROGRAM_SUCCESS = 'workoutProgram/UPDATE_WORKOUT_PROGRAM_SUCCESS';
export const UPDATE_WORKOUT_PROGRAM_ERROR = 'workoutProgram/UPDATE_WORKOUT_PROGRAM_ERROR';

export const GET_USER_WORKOUT_PROGRAMS = 'workoutProgram/GET_USER_WORKOUT_PROGRAMS';
export const GET_USER_WORKOUT_PROGRAMS_SUCCESS = 'workoutProgram/GET_USER_WORKOUT_PROGRAMS_SUCCESS';
export const GET_USER_WORKOUT_PROGRAMS_ERROR = 'workoutProgram/GET_USER_WORKOUT_PROGRAMS_ERROR';

export const CREATE_USER_WORKOUT_PROGRAM = 'workoutProgram/CREATE_USER_WORKOUT_PROGRAM';
export const CREATE_USER_WORKOUT_PROGRAM_SUCCESS = 'workoutProgram/CREATE_USER_WORKOUT_PROGRAM_SUCCESS';
export const CREATE_USER_WORKOUT_PROGRAM_ERROR = 'workoutProgram/CRETE_USER_WORKOUT_PROGRAM_ERROR';

export const UPDATE_USER_WORKOUT_PROGRAM = 'workoutProgram/GET_USER_WORKOUT_PROGRAMS';
export const UPDATE_USER_WORKOUT_PROGRAM_SUCCESS = 'workoutProgram/UPDATE_USER_WORKOUT_PROGRAM_SUCCESS';
export const UPDATE_USER_WORKOUT_PROGRAM_ERROR = 'workoutProgram/UPDATE_USER_WORKOUT_PROGRAM_ERROR';