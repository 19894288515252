import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import {clearCustomCheckedFoodItems, clearCustomShoppingList, clearShoppingList} from '../../ducks/Suggestic/actions';
import './index.css';

class ActionFooter extends React.Component {
  render(){
    const isSuggesticShoppingListPage = window.location.pathname.split('/').includes('coach');
    const isShoppingListPage = window.location.pathname.split('/').includes('shopping-list');
    const {
      clearShoppingListLoading,
      clearCustomShoppingListLoading,
      clearShoppingList,
      clearCustomShoppingList,
      clearCustomCheckedFoodItems,
      clearCustomCheckedFoodsLoading,
      shoppingList
    } = this.props;
    return(
    
      // isShoppingListPage && this.props.shoppingListAggregate && this.props.shoppingListAggregate.edges.length > 0 ?
      isShoppingListPage && (!shoppingList || shoppingList && shoppingList.length !== 0) ?
      <div className='action-footer'>
        <div className='buttons'>
          <Button
            className='previous-week-badge'
            style={{borderRadius: '20px', margin: '0 5px 0 5px', height: '46px', padding: '0px 10px'}}
            loading={!isSuggesticShoppingListPage && clearCustomCheckedFoodsLoading}
            onClick={isSuggesticShoppingListPage ? () => {} : clearCustomCheckedFoodItems}
          >
            Clear checked items
          </Button>
          <Button
            className='previous-week-badge'
            loading={isSuggesticShoppingListPage ? clearShoppingListLoading : clearCustomShoppingListLoading}
            onClick={isSuggesticShoppingListPage ? () => clearShoppingList() : clearCustomShoppingList}
            style={{borderRadius: '20px', margin: '0 5px 0 5px', height: '46px', padding: '0px 10px'}}
          >
            Clear shopping list
          </Button>
        </div>
      </div> : null
    )
  }
}

const mapStateToProps = state => {
  return {
    clearShoppingListLoading: state.suggestic.clearShoppingListLoading,
    clearShoppingListError: state.suggestic.clearShoppingListLoading,
    shoppingListAggregate: state.suggestic.shoppingListAggregate,
    shoppingList: state.suggestic.shoppingList,
    clearCustomShoppingListLoading: state.suggestic.clearCustomShoppingListLoading,
    clearCustomShoppingListError: state.suggestic.clearCustomShoppingListError,
    clearCustomCheckedFoodsError: state.suggestic.clearCustomCheckedFoodsError,
    clearCustomCheckedFoodsLoading: state.suggestic.clearCustomCheckedFoodsLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearShoppingList: () => dispatch(clearShoppingList()),
    clearCustomShoppingList: () => dispatch(clearCustomShoppingList()),
    clearCustomCheckedFoodItems: () => dispatch(clearCustomCheckedFoodItems())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActionFooter));