import React from 'react';
import CustomMealPlan from '../../pages/dashboard/customMealPlan';

class AdminClientProgram extends React.Component {
  render(){
    const { customMealPlan } = this.props.clientProfile;
    return(
      <div className='site-layout-no-background admin'>
        {customMealPlan ? <CustomMealPlan {...this.props} /> : 
        <div style={{backgroundColor: '#e4e7eb', borderRadius: '10px'}}>
          <p className='home-card-text' style={{padding: '24px'}}>This client doesn't have a meal plan.</p>
        </div>}
      </div>
    )
  }
}

export default AdminClientProgram;

