import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumberWithCommas } from '../../helpers/utils';
import { ExerciseCard } from '../ExercisesPage/exerciseCard';
import ExerciseHistoryModal from '../../components/ExerciseHistoryModal';
import { TOGGLE_EXERCISE_HISTORY_MODAL } from '../../ducks/Global/constants';
import { SET_SINGLE_USER_EXERCISE_HISTORY } from '../../ducks/Exercise/constants';
import './index.css';

const { Search } = Input;

export const UserExerciseHistoryPage = ({exercisesHistory}) => {
  const dispatch = useDispatch();
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [totalSetsAndReps, setTotalSetsAndReps] = useState(null);
  const exerciseHistoryModalVisible = useSelector(state => state.global.exerciseHistoryModalVisible);
  const singleUserExerciseHistory = useSelector(state => state.exercises.singleUserExerciseHistory);

  useEffect(() => {
    console.log('Exercises History:', exercisesHistory);
    calculateTotalRepsAndSets(exercisesHistory);
    setFilteredExercises(exercisesHistory);
  }, [exercisesHistory]);
  
  const filterExercises = (searchTerm) => {
    let exercisesCopy = [...exercisesHistory];
    let filteredExercises = exercisesCopy.filter(exercise => 
      exercise.exerciseId?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
   setFilteredExercises(filteredExercises);
  }

  const calculateTotalRepsAndSets = (exerciseData) => {
    let totalReps = 0;
    let totalSets = 0;
  
    exerciseData.forEach(exercise => {
      exercise.history.forEach(session => {
        session.sets.forEach(set => {
          totalReps += set.reps;
          totalSets += 1;
        });
      });
    });
    setTotalSetsAndReps({ totalReps, totalSets });
  }

  const toggleExerciseHistoryModal = (value) => ({
    type: TOGGLE_EXERCISE_HISTORY_MODAL,
    value
  });

  const setSingleUserExerciseHistory = (payload) => ({
    type: SET_SINGLE_USER_EXERCISE_HISTORY,
    payload
  })

  return (
    <div className='stat-page'>
      <p className='home-card-title'>Summary</p>
      <div className='stat-section mb-4'>
        <div className='flex space-around stat-card'>
          <div className='grid grid-justify-center'>
            <p className='main-text'>{formatNumberWithCommas(exercisesHistory.length)}</p>
            <p className='blog-card-category stats'>Total Exercises</p>
          </div>
        </div>
        <div className='flex space-around stat-card'>
          <div className='grid grid-justify-center'>
            <p className='main-text'>{formatNumberWithCommas(totalSetsAndReps?.totalSets)}</p>
            <p className='blog-card-category stats'>Total Sets</p>
          </div>
        </div>
        <div className='flex space-around stat-card'>
          <div className='grid grid-justify-center'>
            <p className='main-text'>{formatNumberWithCommas(totalSetsAndReps?.totalReps)}</p>
            <p className='blog-card-category stats'>Total Reps</p>
          </div>
        </div>
      </div>
      <>
        <p className='home-card-title'>Exercise search</p>
        <Search 
          className='search'
          style={{marginBottom: '10px'}}
          onChange={(e) => filterExercises(e.target.value)}
          placeholder="Search exercise..."
          allowClear
        />
      </>
      <div className='exercise-card-container'>
        {filteredExercises.map(exercise => 
        <ExerciseCard 
          key={exercise._id}
          exercise={exercise}
          isExerciseEdit={false}
          isUserExercisePage={true}
          toggleExerciseHistoryModal={(value) => dispatch(toggleExerciseHistoryModal(value))}
          setSingleUserExerciseHistory={(exercise) => dispatch(setSingleUserExerciseHistory(exercise))}
        />)}
      </div>
      <ExerciseHistoryModal
        toggleExerciseHistoryModal={(value) => dispatch(toggleExerciseHistoryModal(value))}
        exerciseHistoryModalVisible={exerciseHistoryModalVisible}
        currentExercise={singleUserExerciseHistory}
      />
    </div>
  )
}