import {
  SEND_RESULTS_TO_EMAIL,
  SEND_RESULTS_TO_EMAIL_SUCCESS,
  SEND_RESULTS_TO_EMAIL_ERROR,
  SAVE_RESULTS_TO_GOOGLE_SHEET
} from './constants';

export function sendResultsToEmail(macros, goalCalorie, responses, email) {
  return {
    type: SEND_RESULTS_TO_EMAIL,
    macros,
    goalCalorie,
    responses,
    email
  };
}

export function sendResultsToEmailSuccess(response) {
  return {
    type: SEND_RESULTS_TO_EMAIL_SUCCESS,
    response
  };
}

export function sendResultsToEmailError(err) {
  return {
    type: SEND_RESULTS_TO_EMAIL_ERROR,
    err
  };
}

export function saveResultsToGoogleSheet(nutrition, responseObj, unit) {
  return {
    type: SAVE_RESULTS_TO_GOOGLE_SHEET,
    nutrition, 
    responseObj,
    unit
  };
}