import { GET_FIT_BIT_ACCESS_TOKEN, SET_USER_FITBIT_ACCESS_TOKEN, SET_USER_WITHINGS_ACCESS_TOKEN } from "./constants";

export function getFitbitAccessToken() {
  return {
    type: GET_FIT_BIT_ACCESS_TOKEN,
  };
}

export function setUserFitbitAccessToken(payload) {
  return {
    type: SET_USER_FITBIT_ACCESS_TOKEN,
    payload
  };
}

export function setWithingsAccessToken(payload) {
  return {
    type: SET_USER_WITHINGS_ACCESS_TOKEN,
    payload
  }
}