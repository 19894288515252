import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CarbCyclingToolPage } from '../../presentational/CarbCyclingToolPage';
import { getCurrentProfile, getNutrition } from '../../ducks/Profile/actions';
import { getCarbCyclingPlan, createCarbCyclingPlan, handleCarbCyclingModalOpen } from '../../ducks/CarbCycling/actions';
import { SplashPage } from '../../presentational/SplashPage';
import '../../presentational/StatsPage/index.css';

export const CarbCyclingTool = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile.profile);
  const nutrition = useSelector(state => state.profile.nutrition);
  const carbCyclingPlan = useSelector(state => state.carbCycling.carbCyclingPlan);
  const carbCyclingPlanLoading = useSelector(state => state.carbCycling.carbCyclingPlanLoading);
  const carbCyclingPlanError = useSelector(state => state.carbCycling.carbCyclingPlanErr);
  const isOpen = useSelector(state => state.carbCycling.isOpen);
  const isCarbCyclingPlanCreated = useSelector(state => state.carbCycling.isCarbCyclingPlanCreated);

  useEffect(() => {
    if(!profile){
      dispatch(getCurrentProfile());
    }
    if(!nutrition){
      dispatch(getNutrition())
    }
    if(!carbCyclingPlan){
      dispatch(getCarbCyclingPlan())
    }
  }, [])

  return (
    <div className='site-layout-no-background tools-page'>
      {profile && nutrition &&
      <CarbCyclingToolPage 
        profile={profile} 
        nutrition={nutrition} 
        distribution={carbCyclingPlan} 
        createCarbCyclingPlan={createCarbCyclingPlan}
        carbCyclingPlanLoading={carbCyclingPlanLoading}
        carbCyclingPlanError={carbCyclingPlanError}
        handleCarbCyclingModalOpen={handleCarbCyclingModalOpen}
        isOpen={isOpen}
        isCarbCyclingPlanCreated={isCarbCyclingPlanCreated}
      /> }
      {!carbCyclingPlan && <SplashPage page={'carbCycling'} layoutBackground={true}/>}
    </div>
  )
}