import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_GUIDES,
  UPDATE_GUIDES
} from "./constants";
import {
  getGuidesSuccess,
  getGuidesError,
  updateGuidesSuccess,
  updateGuidesError
} from "./actions";
import { fetchData } from "../api";

export function* getGuides(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/guide/me`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let guides = yield call(fetchData, url, config);
    yield put(getGuidesSuccess(guides));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getGuidesError(error));
  }
}

export function* updateGuides({guide}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/guide`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(guide.nutrition)
    }
   
    const guides = yield call(fetchData, url, config);
    yield put(updateGuidesSuccess(guides)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(updateGuidesError(error));
  }
}


export default function* guidesSaga() {
  yield takeLatest(UPDATE_GUIDES, updateGuides);
  yield takeLatest(GET_GUIDES, getGuides);
}