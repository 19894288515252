import {put, takeLatest, call, all} from 'redux-saga/effects';
import { SAVE_BODY_FAT, GET_USER_BF_MEASUREMENTS } from './constants';
import { saveBodyFatSuccess, saveBodyFatError, getUserBfMeasurementsSuccess, getUserBfMeasurementsError } from './actions';
import { fetchData } from "../api";
import {getAllUserNotifications} from "../Onesignal/actions";
import {store} from "../../App";
import { getTodo } from '../Todo/actions';

export function* saveBodyFat({obj}){
  const token = localStorage.token;
  const email = store.getState().auth.user && store.getState().auth.user.email;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/measurement/body-fat-measurement`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj)
    }
    const payload = yield call(fetchData, url, config);
    yield all (
      [
        put(saveBodyFatSuccess(payload)),
        put(getAllUserNotifications(email)),
        put(getTodo()),//added prynk for get latest todo after submission
      ]
    );
  } catch(err){
    const error = yield err.json();
    yield put(saveBodyFatError(error));
  }
}

export function* getUserBfMeasurements({userId}){
  const token = localStorage.token;
  try {
    const url = userId ? `${process.env.REACT_APP_API_URL}/api/measurement/body-fat-measurement/${userId}` : `${process.env.REACT_APP_API_URL}/api/measurement/body-fat-measurement/me`;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    const payload = yield call(fetchData, url, config);
    yield put(getUserBfMeasurementsSuccess(payload));
  } catch(err){
    const error = yield err.json();
    console.log(err);
    console.log(error)
    yield put(getUserBfMeasurementsError(error));
  }
}

export default function* measurementsSaga() {
  yield takeLatest(SAVE_BODY_FAT, saveBodyFat);
  yield takeLatest(GET_USER_BF_MEASUREMENTS, getUserBfMeasurements);
}