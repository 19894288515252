import React from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import htmlToDraft from "html-to-draftjs"

export default class WysiwygField extends React.Component {
  constructor(props) {
    super(props)
    const { fieldValue } = props
    const html = (props.adminMealPlan && props.adminMealPlan.notes) ? props.adminMealPlan.notes : (this.props.mealPlan && this.props.mealPlan.notes) ? this.props.mealPlan.notes : ' '
    const contentBlock = htmlToDraft(html)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    this.state = {
      editorState: EditorState.createWithContent(contentState)
    }
  }

  onEditorStateChange = async editorState => {
    this.setState({ editorState })
    const { fieldName } = this.props
    const fieldValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.props.onEditorChange(fieldName, fieldValue)
  }

  render() {
    return (
      <div className="custom-editor">
        <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          editorState={this.state.editorState}
          editorStyle={{ minHeight: "300px", maxHeight: "300px"}}
          toolbarClassName="toolbar-class"
          toolbar={{
            options: ['inline', 'fontSize', 'link'],
            inline: { 
              inDropdown: false, 
              className: undefined,
              component: undefined,
              dropdownClassName: undefined, 
              options: ['bold', 'italic', 'underline'],
            },
            fontSize: {
              options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link'],
              linkCallback: undefined
            }
          }}
        />
      </div>
    )
  }
}
