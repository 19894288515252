import React from 'react';
import { connect } from 'react-redux';
import FoodLogHistoryPage from '../../presentational/FoodLogHistoryPage';
import { getUserFitbitLogsById, getUserNutritionById } from '../../ducks/Admin/actions';

class AdminFoodLog extends React.Component {

  componentDidMount(){
    this.props.getUserFitbitLogsById(this.props.location.state.userId);
    this.props.getUserNutritionById(this.props.location.state.userId);
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.state.userId !== nextProps.location.state.userId){
      this.props.getUserFitbitLogsById(nextProps.location.state.userId);
      this.props.getUserNutritionById(nextProps.location.state.userId);
    }
  }

  render(){
    return(
      <>
       {this.props.fitbitLogs && this.props.allUserNutrition && 
        <FoodLogHistoryPage 
          fitbitLogs={this.props.fitbitLogs} 
          allUserNutrition={this.props.allUserNutrition} 
        />}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    fitbitLogs: state.admin.fitbitLogs,
    allUserNutrition: state.admin.allUserNutrition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserFitbitLogsById: (userId) => dispatch(getUserFitbitLogsById(userId)),
    getUserNutritionById: (userId) => dispatch(getUserNutritionById(userId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFoodLog);