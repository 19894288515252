import React from 'react';
import CreateWorkoutModal from '../AdminWorkoutLibrary/createWorkoutModal';
import { WorkoutCard } from '../AdminWorkoutLibrary/workoutCard';
import ExerciseHistoryModal from '../../components/ExerciseHistoryModal';

class UserWorkoutsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkout: {exercises: []},
      isView: true,
      currentExercise: {}
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    if (pathname.startsWith("/dashboard/workout/workouts/")) {
      const pathMatch = pathname.match(/^\/dashboard\/workout\/workouts\/(\w+)$/);
    
      if (pathMatch) {
        const workoutId = pathMatch[1];
        const workout = this.findWorkoutById(workoutId);
       
        if (workout) {
          this.props.toggleCreateWorkoutModal(true);
          this.setCurrentWorkout(workout);
        }
      }
    } else if (pathname.startsWith("/dashboard/admin/clients/")) {
      const pathMatch = pathname.match(/^\/dashboard\/admin\/clients\/\w+\/profile\/workouts\/(\w+)$/);
      if (pathMatch) {
        const workoutId = pathMatch[1];
        const workout = this.findWorkoutById(workoutId);
        if (workout) {
          this.props.toggleCreateWorkoutModal(true);
          this.setCurrentWorkout(workout);
        } else {
          this.props.history.push("/dashboard/admin/clients"); // Redirect to a default page if workout not found
        }
      }
    }else{
      this.props.history.push(pathname)
    }
  }

  // componentDidMount() {
  //   const { pathname } = this.props.location;
  //   let pathMatch, workoutId;
  //   if(pathname.startsWith("/dashboard/program/workouts")){
  //     pathMatch = pathname.match(/^\/dashboard\/program\/workouts\/(\w+)$/);
  //     if (pathMatch) {
  //       workoutId = pathMatch[1];
  //     }
  //   } else if (pathname.startsWith("/dashboard/admin/clients")) {
  //     pathMatch = pathname.match(/^\/dashboard\/admin\/clients\/\w+\/profile\/workouts$/);
  //     if (pathMatch) {
  //       workoutId = this.props.match.params.id; // Assuming the workoutId is passed as a prop
  //     }
  //   }
  
  //   if (workoutId) {
  //     const workout = this.findWorkoutById(workoutId);
  //     if (workout) {
  //       this.props.toggleCreateWorkoutModal(true);
  //       this.setCurrentWorkout(workout);
  //     } else {
  //       this.props.history.push("/dashboard/program/workouts");
  //     }
  //   }
  // }
  
  findWorkoutById = (workoutId) => {
    const workout = this.props.userWorkouts.find(workout => workout._id === workoutId);
    return workout;
  }
  
  setCurrentWorkout = (workout) => {
    const { pathname } = this.props.location;
    if (pathname.startsWith("/dashboard/admin/clients")) {
     const pathMatch = pathname.match(/^\/dashboard\/admin\/clients\/(\w+)\/profile\/workouts$/) || pathname.match(/^\/dashboard\/admin\/clients\/(\w+)\/profile\/workouts\/(\w+)$/);

     if (pathMatch && pathMatch[1]) {
      console.log(pathMatch)
       const clientId = pathMatch[1];
      this.props.history.replace(`/dashboard/admin/clients/${clientId}/profile/workouts/${workout._id}`);
     }
    }else if(pathname.startsWith("/dashboard/workout/workouts")){
    this.props.history.replace(`/dashboard/workout/workouts/${workout._id}`);
    }
    this.setState({currentWorkout: workout})
  }

  setExercise = (exercise) => {
    this.setState({currentExercise: exercise})
  }

  render(){
    return(
      <div className="admin-workout">
        <ExerciseHistoryModal
          toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
          exerciseHistoryModalVisible={this.props.exerciseHistoryModalVisible}
          currentExercise={this.state.currentExercise}
        />
        <CreateWorkoutModal 
          history={this.props.history}
          visible={this.props.createWorkoutModalVisible}
          workout={this.state.currentWorkout}
          toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
          toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
          isView={this.state.isView}
          setExercise={this.setExercise}
        />
        <div className='exercise-card-container'>
          {Array.isArray(this.props.userWorkouts)
            ? this.props.userWorkouts.map(workout => 
                <WorkoutCard 
                  key={workout._id}
                  isUserWorkout={true}
                  workout={workout} 
                  setCurrentWorkout={this.setCurrentWorkout}
                  toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
                  user={this.props.user}
                />
              )
            : this.props.userWorkouts.currentWeek.map(workout => 
                <WorkoutCard 
                  key={workout._id}
                  isUserWorkout={true}
                  workout={workout} 
                  setCurrentWorkout={this.setCurrentWorkout}
                  toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
                  user={this.props.user}
                />
              )
          }
        </div>
      </div>
    )
  }
}

export default UserWorkoutsPage;