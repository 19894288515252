import {
  GET_GUIDES,
  GET_GUIDES_SUCCESS,
  GET_GUIDES_ERROR,
  UPDATE_GUIDES,
  UPDATE_GUIDES_SUCCESS,
  UPDATE_GUIDES_ERROR
} from "./constants";

export function getGuides() {
  return {
    type: GET_GUIDES
  };
}

export function getGuidesSuccess(payload) {
  return {
    type: GET_GUIDES_SUCCESS,
    payload
  };
}

export function getGuidesError(err) {
  return {
    type: GET_GUIDES_ERROR,
    err
  };
}

export function updateGuides(guide) {
  return {
    type: UPDATE_GUIDES,
    guide
  };
}

export function updateGuidesSuccess(payload) {
  return {
    type: UPDATE_GUIDES_SUCCESS,
    payload
  };
}

export function updateGuidesError(err) {
  return {
    type: UPDATE_GUIDES_ERROR,
    err
  };
}