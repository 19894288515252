import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt} from 'react-router-dom';
import { TOGGLE_CREATE_WORKOUT_MODAL, TOGGLE_WORKOUT_PROGRAM_MODAL, TOGGLE_EXERCISE_HISTORY_MODAL, TOGGLE_CONFIRM_MODAL, TOGGLE_WORKOUT_TIMER_RUNNING, SET_WORKOUT_IN_PROGRESS, RESET_TIMER  } from '../../ducks/Global/constants';
import { getUserWorkoutPrograms } from '../../ducks/WorkoutProgram/actions';
import { createUserGeneratedWorkout, getUserWorkouts } from '../../ducks/Workout/actions';
import UserWorkoutProgramsPage from '../../presentational/UserWorkoutProgramsPage';
import { Loading } from '../../components/Loading';

class UserWorkoutPrograms extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkoutProgram: {},
      currentWorkout: {exercises: []},
      currentExercise: {},
      isStopWorkout: false,
      timerValue: 0,
    };
  }

  componentDidMount(){
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    !this.props.userWorkoutPrograms.length && this.props.getUserWorkoutPrograms(this.props.user._id);
    !this.props.userWorkouts.length && this.props.getUserWorkouts(this.props.user._id);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  setCurrentWorkoutProgram = (workoutProgram) => {
    this.setState({ currentWorkoutProgram: workoutProgram });
  }

  setCurrentWorkout = (workout) => {
    this.setState({ currentWorkout: workout });
  }

  showWorkoutPreview = (workout) => {
    this.setState({ currentWorkout: workout });
    this.props.toggleCreateWorkoutModal(true);
  }

  setExercise = (exercise) => {
    this.setState({currentExercise: exercise})
  }

  updateTimerValue = (value) => {
    this.setState({ timerValue: value });
  };

  handleConfirmModalAction = () => {
    let isEndWorkout = ((this.props.timerRunning && this.state.isStopWorkout) || this.state.isStopWorkout);
    if (!isEndWorkout) {
      this.props.toggleWorkoutTimerRunning(true);
      this.props.setWorkoutInProgress(true);
      this.props.toggleConfirmModal(false);
    } else if(isEndWorkout){
      this.props.toggleWorkoutTimerRunning(false);
      this.props.toggleConfirmModal(false);
      this.props.setWorkoutInProgress(false);
      this.props.toggleWorkoutProgramModal(false);
      // this.props.createUserGeneratedWorkout({ ...this.props.userGeneratedWorkout, duration: this.state.timerValue });
      this.props.createUserGeneratedWorkout(this.props.userGeneratedWorkout);
      this.props.resetTimer(true)
    }
  }

  stopWorkout = (isStop) => {
    this.setState({isStopWorkout: isStop})
  }

  abortWorkout = () => {
    this.props.toggleWorkoutTimerRunning(false);
    this.props.setWorkoutInProgress(false);
    this.props.toggleConfirmModal(false);
    this.props.toggleCreateWorkoutModal(false);
    this.props.resetTimer(true);
    this.setState({isStopWorkout: false, currentWorkout: {exercises: []}})
  }

  handleBeforeUnload = (event) => {
    const workoutInProgress = this.props.workoutInProgress; //condition that checks if there is unsaved data

    if (workoutInProgress) {
      event.preventDefault();
      event.returnValue = 'Your workout is in progress. If you leave, your changes will be lost.';
    }
  };

  render(){
    const { workoutInProgress } = this.props;
  
    return(
     <div className='workouts-page'>
      <Prompt
        when={workoutInProgress}
        message="Your workout is in progress. If you leave, your workout data will be lost."
      />
      {this.props.workoutProgramsLoading ? (
        <Loading />
      ) : this.props.userWorkoutPrograms.length > 0 ? (
        <UserWorkoutProgramsPage 
          {...this.props} 
          {...this.state}
          setCurrentWorkoutProgram={this.setCurrentWorkoutProgram}
          setCurrentWorkout={this.setCurrentWorkout}
          showWorkoutPreview={this.showWorkoutPreview}
          setExercise={this.setExercise}
          updateTimerValue={this.updateTimerValue}
          handleConfirmModalAction={this.handleConfirmModalAction}
          stopWorkout={this.stopWorkout}
          abortWorkout={this.abortWorkout}
          resetTimer={this.props.resetTimer}
          shouldResetTimer={this.props.shouldResetTimer}
        />
      ) : (
        <div style={{ backgroundColor: '#e4e7eb', borderRadius: '10px' }}>
          <p className='home-card-text' style={{ padding: '22px' }}>
            Your workout program will be here when ready.
          </p>
        </div>
      )}
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userWorkouts: state.workouts.userWorkouts,
    userWorkoutPrograms: state.workoutProgram.userWorkoutPrograms,
    exerciseHistoryModalVisible: state.global.exerciseHistoryModalVisible,
    workoutProgramsLoading: state.workoutProgram.workoutProgramsLoading,
    workoutProgramsError: state.workoutProgram.workoutProgramsError,
    workoutProgramModalVisible: state.global.workoutProgramModalVisible,
    createWorkoutModalVisible: state.global.createWorkoutModalVisible,
    confirmModalVisible: state.global.confirmModalVisible,
    timerRunning: state.global.timerRunning,
    workoutInProgress: state.global.workoutInProgress,
    shouldResetTimer: state.global.shouldResetTimer,
    userGeneratedWorkout: state.global.userGeneratedWorkout,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserWorkoutPrograms: (clientId) => dispatch(getUserWorkoutPrograms(clientId)),
    getUserWorkouts: (clientId, currentWeek, averageMonth) => dispatch(getUserWorkouts(clientId, currentWeek, averageMonth)),
    createUserGeneratedWorkout: (data) => dispatch(createUserGeneratedWorkout(data)),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleWorkoutProgramModal: (value) => dispatch({
      type: TOGGLE_WORKOUT_PROGRAM_MODAL,
      value
    }),
    toggleExerciseHistoryModal: (value) => dispatch({
      type: TOGGLE_EXERCISE_HISTORY_MODAL,
      value
    }),
    toggleConfirmModal: (value) => dispatch({
      type: TOGGLE_CONFIRM_MODAL,
      value
    }),
    toggleWorkoutTimerRunning: (value) => dispatch({
      type: TOGGLE_WORKOUT_TIMER_RUNNING,
      value
    }),
    setWorkoutInProgress: (value) => dispatch({
      type: SET_WORKOUT_IN_PROGRESS,
      value
    }),
    resetTimer: (value) => dispatch({
      type: RESET_TIMER,
      value
    }),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserWorkoutPrograms));