import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_CARB_CYCLING_PLAN,
  CREATE_CARB_CYCLING_PLAN
} from "./constants";
import {
  getCarbCyclingPlanSuccess,
  getCarbCyclingPlanErr,
  createCarbCyclingPlanSuccess,
  createCarbCyclingPlanErr
} from "./actions";
import { fetchData } from "../api";

export function* getCarbCyclingPlan(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/carb-cycling-plan`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let carbCyclingPlan = yield call(fetchData, url, config);
    yield put(getCarbCyclingPlanSuccess(carbCyclingPlan));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getCarbCyclingPlanErr(error));
  }
}

export function* createCarbCyclingPlan({carbSensitivity, dayDistribution, selectedDays}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/carb-cycling-plan`;
    const config = {
      method: "PUT",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({carbSensitivity, dayDistribution, selectedDays})
    }
   
    const carbCyclingPlan = yield call(fetchData, url, config);
    yield put(createCarbCyclingPlanSuccess(carbCyclingPlan)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createCarbCyclingPlanErr(error));
  }
}

export default function* carbCyclingSaga() {
  yield takeLatest(GET_CARB_CYCLING_PLAN, getCarbCyclingPlan);
  yield takeLatest(CREATE_CARB_CYCLING_PLAN, createCarbCyclingPlan);
}