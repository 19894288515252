import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_LIBRARY_WORKOUTS,
  CREATE_WORKOUT,
  UPDATE_LIBRARY_WORKOUT,
  GET_USER_WORKOUTS,
  DELETE_LIBRARY_WORKOUT,
  CREATE_USER_GENERATED_WORKOUT,
  SHARE_MY_WORKOUT
} from "./constants";
import {
  getLibraryWorkoutsSuccess,
  getLibraryWorkoutsError,
  createWorkoutSuccess,
  createWorkoutError,
  updateLibraryWorkoutSuccess,
  updateLibraryWorkoutError,
  deleteLibraryWorkoutSuccess,
  getUserWorkoutsSuccess,
  getUserWorkoutsError,
  createUserGeneratedWorkoutSuccess,
  createUserGeneratedWorkoutError,
  shareMyWorkoutSuccess,
  shareMyWorkoutError
} from "./actions";
import { fetchData } from "../api";

export function* getLibraryWorkouts(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout/library`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let libraryWorkouts = yield call(fetchData, url, config);
    yield put(getLibraryWorkoutsSuccess(libraryWorkouts));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getLibraryWorkoutsError(error));
  }
}

export function* createWorkout({data}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }
    const workout = yield call(fetchData, url, config);
    yield put(createWorkoutSuccess(workout)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createWorkoutError(error));
  }
}

export function* updateLibraryWorkout({workout}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout/library/${workout._id}`;
    const config = {
      method: "PUT",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(workout)
    }
   
    const createdExercise = yield call(fetchData, url, config);
    yield put(updateLibraryWorkoutSuccess(createdExercise)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(updateLibraryWorkoutError(error));
  }
}

export function* deleteLibraryWorkout({id}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout/library/${id}`;
    const config = {
      method: "DELETE",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      }
    }
    yield call(fetchData, url, config);
    yield put(deleteLibraryWorkoutSuccess(id)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
  }
}

export function* getUserWorkouts({clientId, currentWeek, averageMonth}){
  try {

    const url = `${process.env.REACT_APP_API_URL}/api/workout/user/${clientId}${currentWeek ? '?currentWeek=true' : ''}${averageMonth ? '&averageMonth=true' : ''}`;

    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let userWorkouts = yield call(fetchData, url, config);
    yield put(getUserWorkoutsSuccess(userWorkouts));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getUserWorkoutsError(error));
  }
}

export function* createUserGeneratedWorkout({data}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout/user-generated-workout/${data.client}`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }
    const userWorkout = yield call(fetchData, url, config);
    yield put(createUserGeneratedWorkoutSuccess(userWorkout)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createUserGeneratedWorkoutError(error));
  }
}

export function* shareMyWorkout({workoutToken}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout/share/${workoutToken}`;
    let workoutToShare = yield call(fetchData, url);
    yield put(shareMyWorkoutSuccess(workoutToShare));
   
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(shareMyWorkoutError(error));
  }
}

export default function* workoutsSaga() {
  yield takeLatest(GET_LIBRARY_WORKOUTS, getLibraryWorkouts);
  yield takeLatest(CREATE_WORKOUT, createWorkout);
  yield takeLatest(UPDATE_LIBRARY_WORKOUT, updateLibraryWorkout);
  yield takeLatest(DELETE_LIBRARY_WORKOUT, deleteLibraryWorkout);
  yield takeLatest(GET_USER_WORKOUTS, getUserWorkouts);
  yield takeLatest(CREATE_USER_GENERATED_WORKOUT, createUserGeneratedWorkout);
  yield takeLatest(SHARE_MY_WORKOUT, shareMyWorkout);
}