import { put, takeLatest, call, delay } from "redux-saga/effects";
import { REGISTER_USER, USER_LOADED, LOGIN_USER, HANDLE_TOKEN, VERIFY_USER, ASSESSMENT_USER, GET_ASSESSMENT_PROGRESS_USER } from "./constants";
import {
  registerUserSuccess,
  registerUserError,
  loadUserSuccess,
  loadUserError,
  loginUserSuccess,
  loginUserError,
  handleTokenSuccess,
  handleTokenError, handleVerifyUserSuccess, registerUserWithGoogleSuccess, assessmentUserError, getAssessmentProgressUserSuccess, getAssessmentProgressUserError, assessmentUserSuccess
} from "./actions";
import { fetchData } from "../api";
import { getValidationError } from "../../helpers/utils";

export function* registerUser({ newUserObj, callback }) {
  const body = JSON.stringify({
    ...newUserObj
  });

  const handleError = function* (error) {
    if (callback) {
      callback(error);
    }
    yield put(registerUserError(error));
  };

  try {
    const payload = yield fetch(
      newUserObj && newUserObj.isGoogleSignUp ?
        `${process.env.REACT_APP_API_URL}/api/users/registerUserWithGoogle`
        :
        `${process.env.REACT_APP_API_URL}/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    let data = yield payload.json();
    if (payload.ok) {
      if (newUserObj && newUserObj.isGoogleSignUp) {
        yield put(registerUserWithGoogleSuccess(data));
      } else {
        yield put(registerUserSuccess(data));
      }

      yield call(loadUser);
      if (callback) {
        callback(null, data);
      }
    } else {
      yield handleError(getValidationError(data));
    }
  } catch (err) {
    console.log("error", err);
    yield handleError(err);
  }
}

export function* loginUser({ userObj, callback }) {
  console.log("Saga log user in");
  const body = JSON.stringify({
    token: userObj.token,
  });

  const handleError = function* (error) {
    if (callback) {
      callback(error);
    }
    yield put(loginUserError(error));
  };

  try {
    const payload = yield fetch(`${process.env.REACT_APP_API_URL}/api/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body
    });
    let data = yield payload.json();
    if (payload.ok) {
      if (callback) {
        callback(null, data);
      }
      yield put(loginUserSuccess(data));
      yield call(loadUser);
    } else {
      yield handleError(getValidationError(data));
    }
  } catch (err) {
    yield handleError(err);
  }
}

export function* loadUser() {
  const token = localStorage.token;
  try {
    const payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/auth`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    yield put(loadUserSuccess(payload));
  } catch (err) {
    yield put(loadUserError(err));
  }
}

export function* handleToken({ token }) {
  const userToken = localStorage.token;
  try {
    const payload = yield fetch(`${process.env.REACT_APP_API_URL}/api/stripe`, {
      method: 'POST',
      headers: {
        "x-auth-token": userToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session: token
      })
    });

    let user = yield payload.json();
    yield put(handleTokenSuccess(user));

  } catch (err) {
    yield put(handleTokenError(err));
  }
}

export function* verifyUser({ regToken }) {
  const userToken = localStorage.token;
  try {
    const payload = yield fetch(`${process.env.REACT_APP_API_URL}/api/users/verifyUser`, {
      method: 'POST',
      headers: {
        "x-auth-token": userToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        regToken
      })
    });

    let response = yield payload.json();

    if (response && response.isVerified) {
      yield put(handleVerifyUserSuccess({
        isVerified: response.isVerified,
        user: response.user
      }));
    } else {
      yield put(handleTokenError(response.errors));
    }
  } catch (err) {
    console.log(err)
    yield put(handleTokenError(err));
  }
}

export function* assessmentUser({ newUserObj, callback }) {
  const userToken = localStorage.token;
  const body = JSON.stringify({
    ...newUserObj
  });

  const handleError = function* (error) {
    if (callback) {
      callback(error);
    }
    yield put(assessmentUserError(error));
  };

  try {
    const payload = yield fetch(
      `${process.env.REACT_APP_API_URL}/api/users/assessment`, {
      method: "POST",
      headers: {
        "x-auth-token": userToken,
        "Content-Type": "application/json",
      },
      body: body,
    });
    let data = yield payload.json();
    if (payload.ok) {
      yield put(assessmentUserSuccess(data));

      yield call(loadUser);
      if (callback) {
        callback(null, data);
      }
    } else {
      yield handleError(getValidationError(data));
    }
  } catch (err) {
    console.log("error", err);
    yield handleError(err);
  }
}

export function* getAssessmentProgressUser() {
  const token = localStorage.token;

  try {
    let payload = yield call(
      fetchData,
      `${process.env.REACT_APP_API_URL}/api/users/assessmentProgress`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    yield put(getAssessmentProgressUserSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    yield put(getAssessmentProgressUserError(error));
  }
}

export default function* authSaga() {
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(USER_LOADED, loadUser);
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(HANDLE_TOKEN, handleToken);
  yield takeLatest(VERIFY_USER, verifyUser);
  yield takeLatest(ASSESSMENT_USER, assessmentUser);
  yield takeLatest(GET_ASSESSMENT_PROGRESS_USER, getAssessmentProgressUser);
}
