import React from 'react';
import CreateExerciseModal from './createExerciseModal';
import { ExerciseCard } from './exerciseCard';
import { Input } from 'antd';
import './index.css';

const { Search } = Input;

class ExercisesPage extends React.Component {  
  state={
    exercises:[],
    filteredExercises: []
  }

  componentDidMount(){
    this.setState({
      exercises: this.props.exercises, 
      filteredExercises: this.props.exercises
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.exercises !== prevProps.exercises){
      this.setState({
        exercises: this.props.exercises,
        filteredExercises: this.props.exercises
      })
    }
  }
  
  filterExercises = (searchTerm) => {
    let exercises = [...this.state.exercises];
    let filteredExercises = exercises.filter(exercise => 
      exercise.name && exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({filteredExercises: filteredExercises});
  }
  
  render(){
    return(
      <div>
        <div>
          <p className='home-card-title'>Exercise search</p>
          <Search 
            className='search'
            style={{marginBottom: '10px'}}
            onChange={(e) => this.filterExercises(e.target.value)}
            placeholder="Search exercise..."
            allowClear
          />
        </div>
        {this.props.exerciseCategories && this.props.muscles && 
        <CreateExerciseModal
          history={this.props.history}
          visible={this.state.createExerciseModalVisible}
          exercise={this.props.editExercise}
          isExerciseEdit={this.props.isExerciseEdit}
          toggleCreateExerciseModal={this.props.toggleCreateExerciseModal}
          exerciseCategories={this.props.exerciseCategories}
          muscles={this.props.muscles}
        />}
        <div className='exercise-card-container'>
          {this.state.filteredExercises.map(exercise => 
          <ExerciseCard 
            key={exercise._id}
            toggleCreateExerciseModal={this.props.toggleCreateExerciseModal}
            exercise={exercise}
            setEditExercise={this.props.setEditExercise}
            setIsExerciseEdit={this.props.setIsExerciseEdit}
            isExerciseEdit={this.props.isExerciseEdit}
          />)}
        </div>
      </div>
    )
  }
} 

export default ExercisesPage;