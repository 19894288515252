import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading, user }, ...rest }) => {
  return (
    !loading ?
      !isAuthenticated ?
        <Redirect to="/" />
        : !isAuthenticated ?
          <Redirect to="/" /> :
          (user && user.isAssessmentCompleted) ?
            (user && (!user.isSubscriptionActive && user.createdBy !== "admin")) ?
              <Redirect to="/dashboard/settings/billing" />
            :
              <Route
                {...rest}
                render={props => <Component {...props} />}
              />
            :
              <Redirect to="/assessment" /> 
            : 
              null
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(PrivateRoute);