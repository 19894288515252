import React from "react";
import { connect } from "react-redux";
import { Switch, Button, Menu, Dropdown } from "antd";
import { MdArrowDropDown } from 'react-icons/md';
import { SET_STAT_VIEW_MODE, TOGGLE_CREATE_EXERCISE_MODAL, TOGGLE_CREATE_USER_MODAL, TOGGLE_CREATE_WORKOUT_MODAL, TOGGLE_WORKOUT_PROGRAM_MODAL } from "../../ducks/Global/constants";
import { SET_IS_EXERCISE_EDIT, CLEAR_EDIT_EXERCISE  } from "../../ducks/Exercise/constants";
import { CLEAR_EDIT_WORKOUT, SET_IS_WORKOUT_EDIT } from "../../ducks/Workout/constants";
import { handleCarbCyclingModalOpen } from "../../ducks/CarbCycling/actions";
import "./index.css";

class TitleSection extends React.Component {

  renderTitleSection = () => {
    const page = this.props.location.pathname;
    const pathMatch = page.match(/^\/dashboard\/workout\/workouts(\/\w+)?$/);
    switch (page) {
      case "/dashboard/tools/macro-calculator":
        return {
          title: "Macronutrient Calculator",
          subtitle:
            "Calculate your macros and keep them up to date based on weight fluctuations, activity level, nutrition, and other preferences.",
        };
      case "/dashboard/tools/one-rep-max":
        return {
          title: "One Rep Max Calculator",
          subtitle:
            "Maximize Your Strength Potential: Discover Your Ultimate Lift.",
        };
      case "/dashboard/tools/carb-cycling":
        return {
          title: "Carb Cycling - Weekly Builder",
          subtitle:
            "Optimize Your Nutrition: Personalized Carb Cycling for Peak Performance.",
          actions: [
            { text: 'Actions', type:'dropdown', menuItems: [{key: 1, text: 'Export to PDF'}], call: () => {this.props.toggleCreateExerciseModal(true) }},
            { text: 'Create New', type: 'primary', call: () => {this.props.handleCarbCyclingModalOpen(true) }}
          ]
        };
      case "/dashboard/tools/macro-history":
        return {
          title: "Macronutrient History",
          subtitle:
            "All your calculations in one place.",
        };

      case "/dashboard/tools/compliance":
        return {
          title: "Macronutrient Compliance",
          subtitle:
            "Keep track of your macro compliance by logging your food in the Fitbit app.",
        };
      case "/dashboard/coach/meal-plan":
        return {
          title: "Nubod Coach",
          subtitle:
            "Smart meal plans that adapt to your progress week by week.",
        };
      case "/dashboard/coach/food-preferences":
        return {
          title: "Food Preferences",
          subtitle:
            "After changing your food preferences, a notify coach button will enable. Make sure to notify your coach so changes can be made to your"
        };
      case "/dashboard/coach/shopping-list":
        return {
          title: "Shopping List",
          subtitle: "Sort your shopping list by aisle or recipe.",
        };
      case "/dashboard/nutrition/meal-plan":
        return {
          title: "Meal Plan",
          // subtitle: "Your meal plan will be updated based on your progress every 2 to 4 weeks."
        }
      case "/dashboard/workout/workout-plans":
        return {
          title: "Workout Plan",
          // subtitle: "Your plan includes your workouts and instructions."
        }
      case "/dashboard/workout/workout-program":
        return {
          title: "Workout Program",
          // subtitle: "Your workout programs are assigned by your coach based on your specific needs and goals."
        }
      case "/dashboard/nutrition/food-preferences":
        return {
          title: "Food Preferences",
          subtitle: "Select the foods you'd prefer to avoid in your meal plan."
        };
      case "/dashboard/nutrition/shopping-list":
        return {
          title: "Shopping List",
          subtitle: "Your shopping will be here whenever you need it.",
        };
      case "/dashboard/learn/nutrition":
        return {
          title: "Nutrition",
          subtitle:
            "Your simple, all-in-one guide on everything you need to know to set you up for success at the start of your program.",
        };
      case "/dashboard/guides/diet":
        return {
          title: "Diets",
          subtitle:
            "Your will find everything you need to know about the diet type you selected right here.",
        };
      case "/dashboard/guides/eating-methods":
        return {
          title: "Eating Methods",
          subtitle:
            "Intermittent fasting is a popular eating pattern that can give you amazing results when done properly. Learn how to do it based on your macronutrient results.",
        };
      case '/dashboard/nutrition/recipes':
        return { title: 'Recipes', subtitle: 'Search through a large database of recipes and apply up to 30 filters based on your preferences. Don\'t forget to save your favorite ones!' }
      case "/dashboard/nutrition/shopping-list":
        return {
          title: "Shopping List",
          subtitle:
            "Find the shopping lists added from recipes you selected and create custom shopping lists with only foods that are allowed on your diet plan.",
        };
      case "/dashboard/program":
        return {
          title: "Program",
          subtitle:
            "Always up to date with personalized recommendations based on your current macronutrient needs and goals.",
        };
      case "/dashboard/stats/progress":
        return {
          title: "Progress",
          subtitle:
            "A summary and history of your weight, body fat, and lean body mass.",
        };

      case "/dashboard/stats/exercise-history":
      return {
        title: "Exercise History",
        subtitle:
          "Track and analyze your history for each exercise and keep track of your performance.",
      };
      case "/dashboard/admin/library/meal-plan":
        return {
          title: "Meal Plan Library",
          subtitle: "Assign or edit saved meal plans.",
        };
      case "/dashboard/admin/library/workout-plans":
        return {
          title: "Workout Plans",
          // subtitle: "Assign saved workout programs or create new ones.",
          // actions: [
          //   {text: 'Create New', call: () => {this.props.toggleWorkoutProgramModal(true)}}
          // ]
        };
      case "/dashboard/admin/library/workout-program":
        return {
          title: "Workout Programs",
          subtitle: "Assign saved workout programs or create new ones.",
          actions: [
            {text: 'Create New', type: 'primary',call: () => {this.props.toggleWorkoutProgramModal(true)}}
          ]
        };
      case `/dashboard/admin/library/meal-plan/${page.split("/meal-plan/")[1]
        }`:
        return {
          title: `${this.props.location.search.includes("mode=create")
            ? "Create"
            : "Edit"
            } Meal Plan Template`,
          subtitle: "",
        };
      case `/dashboard/admin/clients/${page.split("/")[4]}/edit-meal-plan`:
        return {
          title: 'Client mealplan',
          subtitle: this.props.userMealplan && <p>Viewing meal plan for <span style={{ fontWeight: '600' }}>{this.props.userMealplan.user.name} {this.props.userMealplan.user.lastName}</span>.</p>
        };
      case `/dashboard/admin/clients/${page.split("/")[4]}/profile`:
        return {
          title: ''
        };
      case "/dashboard/settings/account":
      case "/dashboard/settings/integrations":
      case "/dashboard/settings/billing":
        return {
          title: "Settings"
        };
      case "/dashboard/admin/clients":
        return { title: "Clients", subtitle: "",  actions: [
          {text: 'Create New', type: 'primary', call: () => {this.props.toggleCreateUserModal(true)}}
        ]};
      case "/dashboard/profile":
        return { title: "Profile", subtitle: "" };
      case "/dashboard/admin/library/workouts":
        return { title: "Workouts", subtitle: "Assign saved workouts or create new ones.", actions: [
          {text: 'Create New', type: 'primary', call: () => {this.props.toggleCreateWorkoutModal(true); this.props.setIsWorkoutEdit(false); this.props.clearEditWorkout()}}
        ]};
      case "/dashboard/admin/library/exercises":
        return { title: "Exercises", subtitle: "Create new exercices to be added to the exercises library.", actions: [
          {text: 'Create New', type: 'primary', call: () => {this.props.toggleCreateExerciseModal(true); this.props.setIsExerciseEdit(false); this.props.clearEditExercise()}}
        ]};
      default: {
        if (pathMatch) {
          return {
            title: "Workouts Completed",
            subtitle: "These are the workouts you already logged. You can view them and access the history for each exercise you performed.",
          };
        }
        return true;
      }
    }
  };
 
  renderComponent = () => {
    const page = this.props.location.pathname;
    if (page === "/dashboard/stats/progress") {
      return (
        <Switch
          className="table-mode-switch"
          onChange={() => this.props.setStatMode(!this.props.tableMode)}
          checked={this.props.tableMode}
          checkedChildren="Graph"
          unCheckedChildren="Table"
        />
      );
    }
  };

  renderMenuItems = (menuItems) => {
    return (
      <Menu
        className="nav-bar-menu"
        onClick={(e) => this.handleMenuClick(e)}
      >
        {menuItems.map(item => <Menu.Item key={item.key}>{item.text}</Menu.Item>)}
      </Menu>
    );
  };

  renderActions = () => {
    return this.renderTitleSection().actions && this.renderTitleSection().actions.map((action, i) => {
      if(action.type === 'primary' || action.type === 'secondary'){
        return <Button className={`item-${i}`} key={i} type={action.type} onClick={() => action.call() }>{action.text}</Button>
      } else if (action.type === 'dropdown'){
        return (
          <Dropdown
            className={`item-${i}`}
            overlay={this.renderMenuItems(action.menuItems)}
            trigger={["click"]}
          >
            <Button 
              className='flex flex-center-aligned justify-center' 
              type='secondary'
            >
              <p className='mr-05'>Actions</p>
            </Button>
          </Dropdown>
        )
      }
    })
  }

  render() {
    const page = this.props.location.pathname;
    return (
      <div
        className={`main-page-title-container flex space-between ${page.includes("/dashboard/home") ? "no-margin" : ""} ${page.includes("/dashboard/admin/clients") ? "admin" : ""}`}
      >
        <div style={{ width: "100%" }}>
          <div className="title-section-content">
            <div>
              <p className="main-page-title">{this.renderTitleSection().title}</p>
              <p className="main-page-subtitle">{this.renderTitleSection().subtitle}</p>
            </div>
            <div className='title-header-btn-container'>
              {this.renderActions()}
            </div>
           
            {/* {this.renderComponent()} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableMode: state.global.tableMode,
    userMealplan: state.admin.userMealplan,
    profile: state.profile.profile,
    clientProfile: state.admin.clientProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStatMode: (tableMode) => {
      return dispatch({
        type: SET_STAT_VIEW_MODE,
        tableMode,
      });
    },
    handleCarbCyclingModalOpen: (isOpen) => dispatch(handleCarbCyclingModalOpen(isOpen)),
    toggleCreateExerciseModal: (value) => dispatch({
      type: TOGGLE_CREATE_EXERCISE_MODAL,
      value
    }),
    toggleCreateUserModal: (value) => dispatch({
      type: TOGGLE_CREATE_USER_MODAL,
      value
    }),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleWorkoutProgramModal: (value) => dispatch({
      type: TOGGLE_WORKOUT_PROGRAM_MODAL,
      value
    }),
    setIsExerciseEdit: (value) => dispatch({
      type: SET_IS_EXERCISE_EDIT,
      value
    }),
    clearEditExercise: () => dispatch({
      type: CLEAR_EDIT_EXERCISE
    }),
    setIsWorkoutEdit: (value) => dispatch({
      type: SET_IS_WORKOUT_EDIT,
      value
    }),
    clearEditWorkout: () => dispatch({
      type: CLEAR_EDIT_WORKOUT
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleSection);