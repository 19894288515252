
export const GET_SUGGESTIC_PROFILE = "suggestic/GET_SUGGESTIC_PROFILE";
export const GET_SUGGESTIC_PROFILE_SUCCESS = "suggestic/GET_SUGGESTIC_PROFILE_SUCCESS";
export const GET_SUGGESTIC_PROFILE_ERROR = "suggestic/GET_SUGGESTIC_PROFILE_ERROR";

export const GET_RESTRICTIONS = "suggestic/GET_RESTRICTIONS";
export const GET_RESTRICTIONS_SUCCESS = "suggestic/GET_RESTRICTIONS_SUCCESS";
export const GET_RESTRICTIONS_ERROR = "suggestic/GET_RESTRICTIONS_ERROR";

export const UPDATE_RESTRICTIONS = "suggestic/UPDATE_RESTRICTIONS";
export const UPDATE_RESTRICTIONS_SUCCESS = "suggestic/UPDATE_RESTRICTIONS_SUCCESS";
export const UPDATE_RESTRICTIONS_ERROR = "suggestic/UPDATE_RESTRICTIONS_ERROR";

export const UPDATE_SUGGESTIC_PROFILE = "suggestic/UPDATE_SUGGESTIC_PROFILE";
export const UPDATE_SUGGESTIC_PROFILE_SUCCESS = "suggestic/UPDATE_SUGGESTIC_PROFILE_SUCCESS";
export const UPDATE_SUGGESTIC_PROFILE_ERROR = "suggestic/UPDATE_SUGGESTIC_PROFILE_ERROR";

export const GET_SHOPPING_LIST = "suggestic/GET_SHOPPING_LIST";
export const GET_SHOPPING_LIST_SUCCESS = "suggestic/GET_SHOPPING_LIST_SUCCESS";
export const GET_SHOPPING_LIST_ERROR = "suggestic/GET_SHOPPING_LIST_ERROR";

export const GET_SHOPPING_LIST_SUG = "suggestic/GET_SHOPPING_LIST_SUG";
export const GET_SHOPPING_LIST_SUG_SUCCESS = "suggestic/GET_SHOPPING_LIST_SUG_SUCCESS";
export const GET_SHOPPING_LIST_SUG_ERROR = "suggestic/GET_SHOPPING_LIST_SUG_ERROR";

export const GET_USER_SHOPPING_LIST_AGGREGATE = "suggestic/GET_USER_SHOPPING_LIST_AGGREGATE";
export const GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS = "suggestic/GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS";
export const GET_USER_SHOPPING_LIST_AGGREGATE_ERROR = "suggestic/GET_USER_SHOPPING_LIST_AGGREGATE_ERROR";

export const ADD_TO_SHOPPING_LIST = "suggestic/ADD_TO_SHOPPING_LIST";
export const ADD_TO_SHOPPING_LIST_SUCCESS = "suggestic/ADD_TO_SHOPPING_LIST_SUCCESS";
export const ADD_TO_SHOPPING_LIST_ERROR = "suggestic/ADD_TO_SHOPPING_LIST_ERROR";

export const ADD_TO_SHOPPING_LIST_SUG = "suggestic/ADD_TO_SHOPPING_LIST_SUG";
export const ADD_TO_SHOPPING_LIST_SUG_SUCCESS = "suggestic/ADD_TO_SHOPPING_LIST_SUG_SUCCESS";
export const ADD_TO_SHOPPING_LIST_SUG_ERROR = "suggestic/ADD_TO_SHOPPING_LIST_SUG_ERROR";

export const CLEAR_ADD_TO_SHOPPING_LIST_ERROR = "suggestic/CLEAR_ADD_TO_SHOPPING_LIST_ERROR";

export const DELETE_FROM_SHOPPING_LIST = "suggestic/DELETE_FROM_SHOPPING_LIST";
export const DELETE_FROM_SHOPPING_LIST_SUCCESS = "suggestic/DELETE_FROM_SHOPPING_LIST_SUCCESS";
export const DELETE_FROM_SHOPPING_LIST_ERROR = "suggestic/DELETE_FROM_SHOPPING_LIST_ERROR";

export const TOGGLE_SHOPPING_LIST_ITEM = "suggestic/TOGGLE_SHOPPING_LIST_ITEM";
export const TOGGLE_SHOPPING_LIST_ITEM_SUCCESS = "suggestic/TOGGLE_SHOPPING_LIST_ITEM_SUCCESS";
export const TOGGLE_SHOPPING_LIST_ITEM_ERROR = "suggestic/TOGGLE_SHOPPING_LIST_ITEM_ERROR";

export const CLEAR_ALL_CHECKED_ITEMS = "suggestic/CLEAR_ALL_CHECKED_ITEMS";
export const CLEAR_ALL_CHECKED_ITEMS_SUCCESS = "suggestic/CLEAR_ALL_CHECKED_ITEMS_SUCCESS";
export const CLEAR_ALL_CHECKED_ITEMS_ERROR = "suggestic/CLEAR_ALL_CHECKED_ITEMS_ERROR";

export const CLEAR_SHOPPING_LIST = "suggestic/CLEAR_SHOPPING_LIST";
export const CLEAR_SHOPPING_LIST_SUCCESS = "suggestic/CLEAR_SHOPPING_LIST_SUCCESS";
export const CLEAR_SHOPPING_LIST_ERROR = "suggestic/CLEAR_SHOPPING_LIST_ERROR";

export const UPDATE_CUSTOM_SHOPPING_LIST_ITEM = "UPDATE_CUSTOM_SHOPPING_LIST_ITEM";
export const UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS = "UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS";
export const UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR = "UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR";

export const CLEAR_CUSTOM_SHOPPING_LIST = "CLEAR_CUSTOM_SHOPPING_LIST";
export const CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS = "CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS";
export const CLEAR_CUSTOM_SHOPPING_LIST_ERROR = "CLEAR_CUSTOM_SHOPPING_LIST_ERROR";

export const CLEAR_CUSTOM_CHECKED_FOOD_ITEMS = "CLEAR_CUSTOM_CHECKED_FOOD_ITEMS";
export const CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS = "CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS";
export const CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR = "CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR";