import React from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { deleteMealPlanTemplate } from '../../ducks/Admin/actions';
import { MealPlanCard } from './MealPlanCard';
import SaveMealPlanModal from "../AdminMealPlanPage/SaveMealPlanModal";
import AssignModal from './AssignModal';

class AdminMealPlanLibraryPage extends React.Component {
  constructor(){
    super();
    this.state={
      saveMealPlanModalVisible: false,
      modalOpen: false,
      currentId: '',
    }
  }

  toggleModal = (status, id) => {
    this.setState({ modalOpen: status, currentId: id || ''  })
  }

  toggleSaveMealPlanModal = (status) => {
    this.setState({ saveMealPlanModalVisible: status })
  }

  render(){
    return (
      <div className="w-100 pb-4">
        <div className="mb-4">
          <Button type="primary" onClick={() => this.toggleSaveMealPlanModal(true)}>
            Create New
          </Button>
        </div>
        <SaveMealPlanModal
          history={this.props.history}
          toggleSaveMealPlanModal={this.toggleSaveMealPlanModal}
          visible={this.state.saveMealPlanModalVisible}
          redirect={true}
        />
        <AssignModal visible={this.state.modalOpen} id={this.state.currentId} toggleModal={this.toggleModal} />
        <Row gutter={[12, 12]}>
          {this.props.templates.map((item) => (
            <Col key={item._id} lg={12}>
              {/* <MealPlanCard item={item} deleteMealPlanTemplate={this.props.deleteMealPlanTemplate} toggleModal={this.toggleModal} /> */}
              <MealPlanCard item={item} deleteMealPlanTemplate={this.props.deleteMealPlanTemplate} toggleModal={this.toggleModal} />
            </Col>
          ))}
        </Row>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteMealPlanTemplate: (id) => dispatch(deleteMealPlanTemplate(id))
  }
}

export default connect(null, mapDispatchToProps)(AdminMealPlanLibraryPage);