import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_WORKOUT_PROGRAM_LIBRARY,
  CREATE_WORKOUT_PROGRAM,
  UPDATE_WORKOUT_PROGRAM,
  GET_USER_WORKOUT_PROGRAMS,
  CREATE_USER_WORKOUT_PROGRAM
} from "./constants";
import {
  getWorkoutProgramLibrarySuccess,
  getWorkoutProgramLibraryError,
  createWorkoutProgramSuccess,
  createWorkoutProgramError,
  updateWorkoutProgramSuccess,
  updateWorkoutProgramError,
  getUserWorkoutProgramsSuccess,
  getUserWorkoutProgramsError,
  createUserWorkoutProgramSuccess,
  createUserWorkoutProgramError
} from "./actions";
import { fetchData } from "../api";

export function* getWorkoutProgramLibrary(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-program/library`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let workoutPrograms = yield call(fetchData, url, config);
    yield put(getWorkoutProgramLibrarySuccess(workoutPrograms));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getWorkoutProgramLibraryError(error));
  }
}

export function* createWorkoutProgram({data}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-program/library`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }
    const workoutProgram = yield call(fetchData, url, config);
    yield put(createWorkoutProgramSuccess(workoutProgram)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createWorkoutProgramError(error));
  }
}

export function* updateWorkoutProgram({workoutProgram}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-program/library/${workoutProgram._id}`;
    const config = {
      method: "PUT",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(workoutProgram)
    }
    
    const updatedWorkoutProgram = yield call(fetchData, url, config);
    yield put(updateWorkoutProgramSuccess(updatedWorkoutProgram)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(updateWorkoutProgramError(error));
  }
}

export function* getUserWorkoutPrograms({clientId}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-program/user/${clientId}`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
  
    let userWorkoutPrograms = yield call(fetchData, url, config);
    yield put(getUserWorkoutProgramsSuccess(userWorkoutPrograms));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(getUserWorkoutProgramsError(error));
  }
}

export function* createUserWorkoutProgram({client, workoutProgram, notes}) {
  const token = localStorage.token;
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/workout-program/user-program`;
    const config = {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({client: client, workoutProgram: workoutProgram, notes: notes})
    }
    const userWorkoutProgram = yield call(fetchData, url, config);
    yield put(createUserWorkoutProgramSuccess(userWorkoutProgram)); 
  } catch (err) {
     console.log(err)
     const error = yield err.json();
     console.log(error);
     yield put(createUserWorkoutProgramError(error));
  }
}

export default function* workoutProgramSaga() {
  yield takeLatest(GET_WORKOUT_PROGRAM_LIBRARY, getWorkoutProgramLibrary);
  yield takeLatest(CREATE_WORKOUT_PROGRAM, createWorkoutProgram);
  yield takeLatest(UPDATE_WORKOUT_PROGRAM, updateWorkoutProgram);
  yield takeLatest(GET_USER_WORKOUT_PROGRAMS, getUserWorkoutPrograms);
  yield takeLatest(CREATE_USER_WORKOUT_PROGRAM, createUserWorkoutProgram)
}