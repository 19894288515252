import {
  GET_EXERCISES,
  GET_EXERCISES_SUCCESS,
  GET_EXERCISES_ERROR,
  CREATE_EXERCISE,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_ERROR,
  UPDATE_EXERCISE,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_ERROR,
  DELETE_EXERCISE,
  DELETE_EXERCISE_SUCCESS,
  DELETE_EXERCISE_ERROR,
  SET_EDIT_EXERCISE,
  HANDLE_CREATE_OR_EDIT_EXERCISE,
  CLEAR_EDIT_EXERCISE,
  SET_IS_EXERCISE_EDIT,
  SAVE_EXERCISE_HISTORY,
  SAVE_EXERCISE_HISTORY_SUCCESS,
  SAVE_EXERCISE_HISTORY_ERROR,
  GET_EXERCISE_HISTORY_BY_IDS,
  GET_EXERCISE_HISTORY_BY_IDS_SUCCESS,
  GET_EXERCISE_HISTORY_BY_IDS_ERROR,
  GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS,
  GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR,
  GET_EXERCISES_HISTORY_BY_IDS_ARR,
  GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS,
  GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR,
  GET_EXERCISE_CATEGORIES,
  GET_EXERCISE_CATEGORIES_SUCCESS,
  GET_EXERCISE_CATEGORIES_ERROR,
  GET_MUSCLES,
  GET_MUSCLES_SUCCESS,
  GET_MUSCLES_ERROR,
  SET_SINGLE_USER_EXERCISE_HISTORY
} from "./constants";

const initialState = {
  exercises: [],
  getExercisesLoading: false,
  getExercisesErr: null,
  createExerciseLoading: false,
  createExerciseErr: null,
  editExercise: {name: '', instruction: '', category: [], muscles: [], musclesSecondary: [], equipment: [], variations: [], videos: [], images: []},
  createExerciseSuccess: false,
  isExerciseEdit: false,
  exerciseOptions: null,
  exerciseHistory: {},
  exerciseHistoryErr: null,
  exerciseHistoryLoading: {exerciseId: null, loading: false},
  highestLoadSet: null,
  exercisesHistory: {},
  exercisesHistoryErr: null,
  exercisesHistoryLoading: false,
  exercisesHistorySuccess: false,
  allExerciseHistory: null,
  createExerciseHistoryLoading: false,
  exerciseCategories: null,
  muscles: [],
  singleUserExerciseHistory: null
};

function exercises(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_EXERCISES:
      return {
        ...state,
        getExercisesErr: false,
        getExercisesLoading: true
      };
    case GET_EXERCISES_SUCCESS:
      return {
        ...state,
        exercises: payload,
        getExercisesLoading: false
      };
    case GET_EXERCISES_ERROR:
      return {
        ...state,
        getExercisesLoading: true,
        getExercisesErr: action.err
      };
    case CREATE_EXERCISE:
      return {
        ...state,
        createExerciseLoading: true,
        createExerciseErr: null,
        createExerciseSuccess: false
      };
    case CREATE_EXERCISE_SUCCESS:
      return {
        ...state,
        exercises: [...state.exercises, payload],
        createExerciseErr: null,
        createExerciseSuccess: true,
        createExerciseLoading: false
      };
    case CREATE_EXERCISE_ERROR:
      return {
        ...state,
        createExerciseLoading: false,
        createExerciseErr: action.err,
        createExerciseSuccess: false
      };
    case UPDATE_EXERCISE:
      return {
        ...state,
        createExerciseLoading: true,
        createExerciseErr: null,
        createExerciseSuccess: false
      };
    case UPDATE_EXERCISE_SUCCESS:
      return {
        ...state, 
        exercises: state.exercises.map(exercise => (exercise._id === payload._id) ? payload : exercise),
        createExerciseErr: null,
        createExerciseSuccess: true,
        createExerciseLoading: false
      };
    case UPDATE_EXERCISE_ERROR:
      return {
        ...state,
        createExerciseLoading: false,
        createExerciseErr: action.err,
        createExerciseSuccess: false
      };
    case DELETE_EXERCISE:
      return {
        ...state,
        createExerciseLoading: true,
        createExerciseErr: null
      };
    case DELETE_EXERCISE_SUCCESS:
      return {
        ...state,
        exercises: state.exercises.filter(exercise => exercise.id !== payload),
        createExerciseErr: null
      };
    case DELETE_EXERCISE_ERROR:
      return {
        ...state,
        createExerciseLoading: false,
        createExerciseErr: action.err
      };
    case SET_EDIT_EXERCISE:
      return {
        ...state,
        editExercise: payload
      };
    case HANDLE_CREATE_OR_EDIT_EXERCISE:
      return {
        ...state,
        editExercise: {...state.editExercise, ...action.editExercise},
        createExerciseErr: action.err,
        exerciseOptions: action.exerciseOptions
      };
    case CLEAR_EDIT_EXERCISE:
      return {
        ...state,
        editExercise: {name: '', instruction: '', category: [], muscles: [], musclesSecondary: [], equipment: [], variations: [], videos: [], images: []}
      };
    case SET_IS_EXERCISE_EDIT:
      return {
        ...state,
        isExerciseEdit: action.value
      };
    case SAVE_EXERCISE_HISTORY:
      return {
        ...state,
        exercisesHistorySuccess: false,
        createExerciseHistoryLoading: true
      };
    case SAVE_EXERCISE_HISTORY_SUCCESS:
      return {
        ...state,
      exercisesHistorySuccess: true,
      createExerciseHistoryLoading: false
      };
    case SAVE_EXERCISE_HISTORY_ERROR:
      return {  
        ...state,
        exercisesHistorySuccess: false,
        createExerciseHistoryLoading: false
      };
    case GET_EXERCISE_HISTORY_BY_IDS:
      return {
        ...state,
        exerciseHistory: {},
        exerciseHistoryErr: false,
        exerciseHistoryLoading: action.allHistory ? true : {exerciseId: action.exerciseId, loading: true},
      };
    case GET_EXERCISE_HISTORY_BY_IDS_SUCCESS:
      return {
        ...state,
        exerciseHistory: payload.lastHistory,
        exerciseHistoryLoading: {exerciseId: null, loading: false},
        highestLoadSet: payload.highestLoadSet || state.highestLoadSet,
      };
    case GET_EXERCISE_HISTORY_BY_IDS_ERROR:
      return {
        ...state,
        exerciseHistory: {},
        exerciseHistoryLoading: false,
        exerciseHistoryErr: action.err,
        highestLoadSet: null,
      };
    case GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS:
    case SET_SINGLE_USER_EXERCISE_HISTORY:
      return {
        ...state,
        exerciseHistoryLoading: false,
        allExerciseHistory: payload,
        singleUserExerciseHistory: payload
      };
    case GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR:
      return {
        ...state,
        allExerciseHistory: null,
        exerciseHistoryLoading: false,
        exerciseHistoryErr: action.err
      };
    case GET_EXERCISES_HISTORY_BY_IDS_ARR:
      return {
        ...state,
        exercisesHistoryErr: false,
        exercisesHistoryLoading: true
      };
    case GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS:
      return {
        ...state,
        exercisesHistory: payload,
        exercisesHistoryLoading: false
      };
    case GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR:
      return {
        ...state,
        exercisesHistory: {},
        exercisesHistoryLoading: false,
        exercisesHistoryErr: action.err
      };
    case GET_EXERCISE_CATEGORIES:
      return {
        ...state,
        exerciseCategories: state.exerciseCategories
      };
    case GET_EXERCISE_CATEGORIES_SUCCESS:
      return {
        ...state,
        exerciseCategories: action.payload
      };
    case GET_EXERCISE_CATEGORIES_ERROR:
      return {
        ...state,
        exerciseCategories: null
      };
    case GET_MUSCLES:
      return {
        ...state,
        muscles: state.muscles
      };
    case GET_MUSCLES_SUCCESS:
      return {
        ...state,
        muscles: action.payload
      };
    case GET_MUSCLES_ERROR:
      return {
        ...state,
        muscles: []
      };
    default:
      return state;
  }
}

export default exercises;