import React from "react";
import { Modal } from 'antd';
import ReactCanvasConfetti from "react-canvas-confetti";
import { useHistory } from 'react-router-dom';
import './index.css';
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { HANDLE_ACHIEVEMENT_MODAL } from "../../ducks/Global/constants";

export const AchievementModal = ({achievementProps}) => {

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      {/* <ReactCanvasConfetti
        refConfetti={this.getInstance}
        style={canvasStyles}
        fire={true}
      /> */}
      <Modal
        visible={achievementProps.isOpen}
        centered
        footer={null}
        onCancel={() => dispatch({type: HANDLE_ACHIEVEMENT_MODAL, achievementProps: { isOpen: false }})}
        className="timer-modal"
        closable={false}
      >
        <div className='achievement-modal'>
          <div className='modal-content'>
            <img src={achievementProps.img} className='img'/>
            <p className='title'>{achievementProps.title}</p>
            <p className='subtitle'>{achievementProps.subtitle}</p>
            <div 
              className='flex flex-center-aligned blue-link' 
              onClick={() => {
                history.push(achievementProps.path);
                dispatch({
                  type: HANDLE_ACHIEVEMENT_MODAL, 
                  achievementProps: { isOpen: false }
                })
              }}
            >
              <a className='mr-05'>{achievementProps.actionText}</a>
              <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}