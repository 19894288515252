import {
  GET_EXERCISES,
  GET_EXERCISES_SUCCESS,
  GET_EXERCISES_ERROR,
  CREATE_EXERCISE,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_ERROR,
  UPDATE_EXERCISE,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_ERROR,
  DELETE_EXERCISE,
  DELETE_EXERCISE_SUCCESS,
  DELETE_EXERCISE_ERROR,
  HANDLE_CREATE_OR_EDIT_EXERCISE,
  SAVE_EXERCISE_HISTORY,
  SAVE_EXERCISE_HISTORY_SUCCESS,
  SAVE_EXERCISE_HISTORY_ERROR,
  GET_EXERCISE_HISTORY_BY_IDS,
  GET_EXERCISE_HISTORY_BY_IDS_SUCCESS,
  GET_EXERCISE_HISTORY_BY_IDS_ERROR,
  GET_EXERCISES_HISTORY_BY_IDS_ARR,
  GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS,
  GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR,
  GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS,
  GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR,
  GET_EXERCISE_CATEGORIES,
  GET_EXERCISE_CATEGORIES_SUCCESS,
  GET_EXERCISE_CATEGORIES_ERROR,
  GET_MUSCLES,
  GET_MUSCLES_SUCCESS,
  GET_MUSCLES_ERROR
} from "./constants";

export function getExercises() {
  return {
    type: GET_EXERCISES
  };
}

export function getExercisesSuccess(payload) {
  return {
    type: GET_EXERCISES_SUCCESS,
    payload
  };
}

export function getExercisesError(err) {
  return {
    type: GET_EXERCISES_ERROR,
    err
  };
}

export function createExercise(exercise) {
  return {
    type: CREATE_EXERCISE,
    exercise
  };
}

export function createExerciseSuccess(payload) {
  return {
    type: CREATE_EXERCISE_SUCCESS,
    payload
  };
}

export function createExerciseError(err) {
  return {
    type: CREATE_EXERCISE_ERROR,
    err
  };
}

export function updateExercise(exercise) {
  return {
    type: UPDATE_EXERCISE,
    exercise
  };
}

export function updateExerciseSuccess(payload) {
  return {
    type: UPDATE_EXERCISE_SUCCESS,
    payload
  };
}

export function updateExerciseError(err) {
  return {
    type: UPDATE_EXERCISE_ERROR,
    err
  };
}

export function deleteExercise(id) {
  return {
    type: DELETE_EXERCISE,
    id
  };
}

export function deleteExerciseSuccess(payload) {
  return {
    type: DELETE_EXERCISE_SUCCESS,
    payload
  };
}

export function deleteExerciseError(err) {
  return {
    type: DELETE_EXERCISE_ERROR,
    err
  };
}

export function handleCreateOrEditExercise(options, value, exercise, field) {

  let editExercise = { ...exercise };

  let exerciseOptions = options && options;
   
  if (
    field === 'secondaryMuscles' ||
    field === 'equipment' ||
    field === 'variations' ||
    field === 'categories'
  ) {

    if (!Array.isArray(editExercise[field])) {
      editExercise[field] = [];
    }

    // Extract IDs from the current field values (if they are objects)
    const currentIds = editExercise[field].map(item => 
      typeof item === 'object' && item._id ? item._id : item
    );

    // Extract IDs from the new value (if they are objects)
    const newIds = Array.isArray(value)
      ? value.map(item => 
          typeof item === 'object' && item._id ? item._id : item
        )
      : [typeof value === 'object' && value._id ? value._id : value];

    // Combine and deduplicate IDs
    editExercise[field] = [...new Set([...currentIds, ...newIds])];

  } else if(field === 'difficulty'){
    
    editExercise[field] = value;
    
  } else if(field === 'primaryMuscles'){
    const selectedOption = options.find(option => option._id === value);
    editExercise[field] = selectedOption ? [selectedOption] : [];

  } else {
    editExercise[field] = value.target.value;
  }

  const errors = [];
  if (!editExercise.name) {
    errors.push('Please fill the required field')
  } 

  return {
    type: HANDLE_CREATE_OR_EDIT_EXERCISE,
    field,
    editExercise,
    exerciseOptions,
    errors
  };
}

export function saveExerciseHistory(dataArr, workoutId) {
  return {
    type: SAVE_EXERCISE_HISTORY,
    dataArr,
    workoutId
  };
}

export function saveExerciseHistorySuccess(payload) {
  return {
    type: SAVE_EXERCISE_HISTORY_SUCCESS,
    payload
  };
}

export function saveExerciseHistoryError(err) {
  return {
    type: SAVE_EXERCISE_HISTORY_ERROR,
    err
  };
}

export function getExerciseHistoryByIds(clientId, exerciseId, allHistory, callback) {
  return {
    type: GET_EXERCISE_HISTORY_BY_IDS,
    clientId,
    exerciseId,
    allHistory,
    callback
  };
}

export function getExerciseHistoryByIdsSuccess(payload) {
  return {
    type: GET_EXERCISE_HISTORY_BY_IDS_SUCCESS,
    payload
  };
}

export function getExerciseHistoryByIdsError(err) {
  return {
    type: GET_EXERCISE_HISTORY_BY_IDS_ERROR,
    err
  };
}

export function getAllExerciseHistoryByIdsSuccess(payload) {
  return {
    type: GET_ALL_EXERCISE_HISTORY_BY_IDS_SUCCESS,
    payload
  };
}

export function getAllExerciseHistoryByIdsError(err) {
  return {
    type: GET_ALL_EXERCISE_HISTORY_BY_IDS_ERROR,
    err
  };
}

export function getExercisesHistoryByIdsArr(clientId, exerciseIdsArr) {
  return {
    type: GET_EXERCISES_HISTORY_BY_IDS_ARR,
    clientId,
    exerciseIdsArr
  };
}

export function getExercisesHistoryByIdsArrSuccess(payload) {
  return {
    type: GET_EXERCISES_HISTORY_BY_IDS_ARR_SUCCESS,
    payload
  };
}

export function getExercisesHistoryByIdsArrError(err) {
  return {
    type: GET_EXERCISES_HISTORY_BY_IDS_ARR_ERROR,
    err
  };
}

export function getExerciseCategories() {
  return {
    type: GET_EXERCISE_CATEGORIES
  };
}

export function getExerciseCategoriesSuccess(payload) {
  return {
    type: GET_EXERCISE_CATEGORIES_SUCCESS,
    payload
  };
}

export function getExerciseCategoriesError(err) {
  return {
    type: GET_EXERCISE_CATEGORIES_ERROR,
    err
  };
}

export function getMuscles() {
  return {
    type: GET_MUSCLES
  };
}

export function getMusclesSuccess(payload) {
  return {
    type: GET_MUSCLES_SUCCESS,
    payload
  };
}

export function getMusclesError(err) {
  return {
    type: GET_MUSCLES_ERROR,
    err
  };
}
 
// export function getExerciseHistory(dataObj) {
//   return {
//     type: GET_EXERCISE_HISTORY,
//     dataObj
//   };
// }

// export function getExerciseHistorySuccess(payload) {
//   return {
//     type: GET_EXERCISE_HISTORY_SUCCESS,
//     payload
//   };
// }

// export function getExerciseHistoryError(err) {
//   return {
//     type: GET_EXERCISE_HISTORY_ERROR,
//     err
//   };
// }