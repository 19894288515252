import React from 'react';
import SuggesticMealPlanPage from '../../presentational/SuggesticMealPlanPage';
import { connect } from 'react-redux'; 
import { getNutrition, getCurrentProfile, getEquivalentRecipes, swapMealPlanRecipe, createWeeklyMealPlan} from '../../ducks/Profile/actions';
import { addToShoppingListSug, getShoppingListSug, clearAddToShoppingListError} from '../../ducks/Suggestic/actions';
import { Loading } from '../../components/Loading';

class SuggesticMealPlan extends React.Component {
  
  componentDidMount(){
    this.props.getNutrition();
    this.props.getCurrentProfile();
    this.props.getShoppingList();
  }

  componentWillUnmount(){
    this.props.clearAddToShoppingListError(null)
  }

  render(){
    const notLoading = this.props.profile && this.props.nutrition;
    return(
      <div className='home-page sub-menu'>
       {notLoading ? 
        <SuggesticMealPlanPage {...this.props} /> :
        <Loading />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    nutrition: state.profile.nutrition,
    profile: state.profile.profile,
    equivalentRecipesLoading: state.profile.equivalentRecipesLoading,
    swapMealPlanLoading: state.profile.swapMealPlanLoading,
    suggesticMealPlanLoading: state.profile.suggesticMealPlanLoading,
    addToShoppingListLoading: state.suggestic.addToShoppingListLoadingSug,
    addToShoppingListError: state.suggestic.addToShoppingListErrorSug,
    shoppingList: state.suggestic.shoppingListSug,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNutrition: () => dispatch(getNutrition()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getEquivalentRecipes: (recipeId, status) => dispatch(getEquivalentRecipes(recipeId, status)),
    swapMealPlanRecipe: (mealId, newRecipeId, status) => dispatch(swapMealPlanRecipe(mealId, newRecipeId, status)),
    addToShoppingList: (ids, allRecipes) => dispatch(addToShoppingListSug(ids, allRecipes)),
    getShoppingList: () => dispatch(getShoppingListSug()),
    clearAddToShoppingListError: (val) => dispatch(clearAddToShoppingListError(val)),
    createWeeklyMealPlan: (mealPlanData) => dispatch(createWeeklyMealPlan(mealPlanData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggesticMealPlan);