import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserExerciseHistoryPage } from '../../presentational/UserExerciseHistoryPage';
import { getExercisesHistoryByIdsArr } from '../../ducks/Exercise/actions';
import { Loading } from '../../components/Loading';
import '../../presentational/StatsPage/index.css';

export const UserExerciseHistory = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const exercisesHistory = useSelector((state) => state.exercises.exercisesHistory);

  useEffect(() => {
    if (user && user._id) {
      dispatch(getExercisesHistoryByIdsArr(user._id, 'all'));
    }
  },[])
  
  return (
    <div className='home-page'>
      {exercisesHistory.length > 0 ? 
      <UserExerciseHistoryPage exercisesHistory={exercisesHistory}/> : <Loading />}
    </div>
   
  )
}