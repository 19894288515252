import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, Alert } from 'antd';
import { filterBlogs, subscribeUser } from '../../ducks/Blog/actions';
import { validateEmail } from '../../helpers/utils';
import { CLEAR_SUBSCRIBE_MESSAGE } from '../../ducks/Blog/constants';
import './index.css';

var _ = require('lodash');

class BlogSideBar extends React.Component {
  state = { validEmail: false, emailError: '', formData: null }

  getCategories = () => {
    let categories = [];
    this.props.allBlogs && this.props.allBlogs.data.map(blog => categories.push(blog.categories[0]));
    return  _.uniqBy(categories, 'name').map((category, i) => 
      <ul>
        <li key={i} className='blog-category' onClick={() => this.props.filterBlogs(category)}>{category.name}</li>
      </ul>
    )
  }

  handleSubscribe = () => {
    if(this.state.validEmail){
      this.props.subscribeUser(this.state.formData);
      this.setState({formData: null, emailError: ''});
    } else {
      this.setState({emailError: 'Please enter a valid email'});
    }
  }

  render(){
    return(
      <div className='blog-sidebar'>
        <div className='sidebar-card'>
          <div className='quiz-card-content'>
            <h2>Join the newsletter!</h2>
            <p className='quiz-card-subtitle'>Stay up to date with our latest posts, news, and deals.</p>
            <Input 
              placeholder="Email.."
              onChange={(e) => this.setState({formData: {email: e.target.value}, validEmail: validateEmail(e.target.value)})}
              value={this.state.formData && this.state.formData.email}
            />
            <p className='input-error'>{this.state.emailError}</p>
            {this.props.subscribeStatus && 
             <Alert 
              message="You've been subscribed!" 
              type="success" 
              showIcon 
              closable
              onClose={() => this.props.clearSubscribeMessage()}
            />} 
            <Button 
              style={{marginTop: '10px'}}
              size='large'
              type='primary'
              onClick={() => this.handleSubscribe()}
            >
              Subscribe
            </Button>
            
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    subscribeStatus: state.blog.subscribeStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterBlogs: (category) => dispatch(filterBlogs(category)),
    subscribeUser: (formData) => dispatch(subscribeUser(formData)),
    clearSubscribeMessage: () => {
			return dispatch({
					type: CLEAR_SUBSCRIBE_MESSAGE
			 });
		},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogSideBar);