import React from 'react';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import AdminClientsProfilePage from '../../presentational/AdminClientsProfilePage';
import {getClientProfileById, getUserNutritionById, getUserNutritionPreferenceById} from '../../ducks/Admin/actions';
import { Loading } from '../../components/Loading';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { capitalize } from '../../helpers/utils';
import { OPEN_DRAWER } from '../../ducks/Global/constants';
import SideDrawer from '../../components/SideDrawer';
import { saveProfileIgnoreNutrition } from '../../ducks/Profile/actions';

class AdminClientProfile extends React.Component {

  componentDidMount(){
    this.props.getClientProfileById(this.props.match.params.id);
    this.props.getUserNutritionById(this.props.match.params.id);
    this.props.getUserNutritionPreferencesBgId(this.props.match.params.id);
  }

  renderUserCard = () => {
    const { clientProfile } = this.props;
    return (
      <>
        {clientProfile ? 
        <div className='global-card'>
          <div className='flex flex-center-aligned'>
            <img className='global-avatar mr-2'src={clientProfile && clientProfile.user.avatar} />
            <div>
              <>
                <div className='flex flex-center-aligned'>
                  <p className='main-page-title-small'>{`${clientProfile && clientProfile.user.name} ${clientProfile && clientProfile.user.lastName || ''}`}</p>
                  {clientProfile && clientProfile.user.isPro && <span className='pro-label'>PRO</span>}
                </div>
                <p className='blue-link'>{clientProfile && clientProfile.user.email}</p>
              </>
              {this.renderActionButtons()}
            </div>
          </div>
        </div> :
        <Loading />}
      </>
    )
  }

  renderBreadCrum = () => {
    let { clientProfile, history } = this.props;
    return (
      <Breadcrumb className='mb-2'>
        <Breadcrumb.Item style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/dashboard/admin/clients')}>Clients</Breadcrumb.Item>
        <Breadcrumb.Item>{`${clientProfile && clientProfile.user.name || ''} ${clientProfile && clientProfile.user.lastName || ''}`}</Breadcrumb.Item>
        <Breadcrumb.Item>{capitalize(history.location.pathname.split('/').pop())}</Breadcrumb.Item>
      </Breadcrumb>
    )
  }

  renderSubMenu = () => {
    let subMenuItems = [
      { name: "Macronutrients", path: `/dashboard/admin/clients/${ this.props.clientProfile && this.props.clientProfile.user._id}/profile/macros`},
      { name: "Program", path: `/dashboard/admin/clients/${this.props.clientProfile && this.props.clientProfile.user._id}/profile/program`},
      { name: "Workouts", path: `/dashboard/admin/clients/${this.props.clientProfile && this.props.clientProfile.user._id}/profile/workouts`},
      { name: "Progress", path: `/dashboard/admin/clients/${this.props.clientProfile && this.props.clientProfile.user._id}/profile/progress`},
      { name: "Preferences", path: `/dashboard/admin/clients/${this.props.clientProfile && this.props.clientProfile.user._id}/profile/preferences`}
    ];
    return (
      <div className='profile-menu' style={{marginTop: '20px'}}>
      {subMenuItems.map(item => 
        <Link key={item.path} to={item.path} className={`menu-link ${`${this.props.history.location.pathname}${this.props.history.location.search}` === item.path ? 'active' : ''}`}>
        <p>{item.name}</p>
      </Link>
      )}
    </div>
    )
  }

  renderActionButtons = () => {
    let actionButtons = [
      { key: 1, name: "New Measurement", action: this.props.openDrawer}
    ]
    return (
      <div className='flex' style={{marginTop: '10px'}}>
        {actionButtons.map(button => 
         <Button key={button.key} onClick={() => button.action()} type='primary' className='small'>
           {button.name}
         </Button>
        )}
      </div>
    )
  }

  render(){
    const { clientProfile } = this.props;
    return(
      <>
      {clientProfile &&
        <SideDrawer 
          role={clientProfile.user.role} 
          data={clientProfile} 
          isMetric={clientProfile.isMetric}
          currentWeight={clientProfile.currentWeight}
        />}
        <div className='site-layout-no-background admin'>
          {this.renderBreadCrum()}
          {this.renderUserCard()}
          {this.renderSubMenu()}
          {clientProfile ? <AdminClientsProfilePage {...this.props}/> : <Loading />}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    clientProfile: state.admin.clientProfile,
    allUserNutrition: state.admin.allUserNutrition,
    foodRestrictions: state.admin.foodRestrictions,
    nutritionPreferences: state.admin.userNutritionPreference,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getClientProfileById: (userId) => dispatch(getClientProfileById(userId)),
    getUserNutritionById: (userId) => dispatch(getUserNutritionById(userId)),
    getUserNutritionPreferencesBgId: (userId) => dispatch(getUserNutritionPreferenceById(userId)),
    saveProfileIgnoreNutrition: (payload) => dispatch(saveProfileIgnoreNutrition(payload)),
    openDrawer: () => {
      return dispatch({
        type: OPEN_DRAWER
      });
		},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminClientProfile));