import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  USER_LOADED,
  USER_LOADED_SUCCESS,
  USER_LOADED_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  SHOW_AUTH_MODAL,
  HIDE_AUTH_MODAL,
  HANDLE_TOKEN,
  HANDLE_TOKEN_SUCCESS,
  HANDLE_TOKEN_ERROR, VERIFY_USER, VERIFY_USER_SUCCESS, REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP, ASSESSMENT_USER, ASSESSMENT_USER_SUCCESS, ASSESSMENT_USER_SUCCESS_WITH_GOOGLE_SIGNUP, ASSESSMENT_USER_FAIL, GET_ASSESSMENT_PROGRESS_USER, GET_ASSESSMENT_PROGRESS_USER_SUCCESS, GET_ASSESSMENT_PROGRESS_USER_ERROR
} from "./constants";

export function registerUser(newUserObj, callback) {
  return {
    type: REGISTER_USER,
    newUserObj,
    callback,
  };
}

export function registerUserSuccess(payload) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload,
  };
}

export function registerUserWithGoogleSuccess(payload) {
  return {
    type: REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP,
    payload,
  };
}

export function registerUserError() {
  return {
    type: REGISTER_USER_FAIL,
  };
}

export function loadUser() {
  return {
    type: USER_LOADED,
  };
}

export function loadUserSuccess(user) {
  return {
    type: USER_LOADED_SUCCESS,
    user,
  };
}

export function loadUserError(err) {
  return {
    type: USER_LOADED_ERROR,
    err,
  };
}

export function loginUser(userObj, callback) {
  return {
    type: LOGIN_USER,
    userObj,
    callback,
  };
}

export function loginUserSuccess(payload) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
}

export function loginUserError(err) {
  return {
    type: LOGIN_USER_ERROR,
    err,
  };
}

export function logOut() {
  return {
    type: LOGOUT,
  };
}

export function showAuthModal(payload) {
  return {
    type: SHOW_AUTH_MODAL,
    payload,
  };
}

export function hideAuthModal(payload) {
  return {
    type: HIDE_AUTH_MODAL,
    payload,
  };
}

export function handleToken(token) {
  return {
    type: HANDLE_TOKEN,
    token
  }
}

export function handleTokenSuccess(payload) {
  return {
    type: HANDLE_TOKEN_SUCCESS,
    payload
  }
}

export function handleTokenError(error) {
  return {
    type: HANDLE_TOKEN_ERROR,
    error
  }
}

export function verifyUser(regToken) {
  return {
    type: VERIFY_USER,
    regToken
  }
}

export function handleVerifyUserSuccess(payload) {
  return {
    type: VERIFY_USER_SUCCESS,
    payload
  }
}

export function assessmentUser(newUserObj, callback) {
  return {
    type: ASSESSMENT_USER,
    newUserObj,
    callback,
  };
}

export function assessmentUserSuccess(payload) {
  return {
    type: ASSESSMENT_USER_SUCCESS,
    payload,
  };
}

export function assessmentUserError() {
  return {
    type: ASSESSMENT_USER_FAIL,
  };
}

export function assessmentUserWithGoogleSuccess(payload) {
  return {
    type: ASSESSMENT_USER_SUCCESS_WITH_GOOGLE_SIGNUP,
    payload,
  };
}

export function getAssessmentProgressUser(payload) {
  return {
    type: GET_ASSESSMENT_PROGRESS_USER,
    payload
  };
}

export function getAssessmentProgressUserSuccess(payload) {
  return {
    type: GET_ASSESSMENT_PROGRESS_USER_SUCCESS,
    payload,
  };
}

export function getAssessmentProgressUserError(payload) {
  return {
    type: GET_ASSESSMENT_PROGRESS_USER_ERROR,
    payload,
  };
}