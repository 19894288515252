import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { DatePicker, Button, Modal } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { RangePicker } = DatePicker;

class DatePickerModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dateStart: '',
      dateEnd: '',
    }
  }

  save = () => {
    try {
      const { dateStart, dateEnd } = this.state;
      this.props.handleAddToShoppingList(dateStart, dateEnd);
    } catch (err) {
      toast.error(err)
    }
  }

  disableDates = (current, planStartDate, planEndDate) => {
    let planStart = moment(planStartDate);
    let planEnd = moment(planEndDate);
    let currentDate = moment(moment(current).format('YYYY-MM-DD'));
    return !(currentDate >= planStart && currentDate <= planEnd);
  }

  render(){
    const {visible, toggleDatePickerModal, planStartDate, planEndDate} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={toggleDatePickerModal}
        footer={null}
        centered={true}
        className="custom-food-modal"
      >
        <p className='ant-modal-title mb-1'>Select a date range</p>
        <p className='text' style={{lineHeight: '18px'}}>The items from the days you select will be calculated and added to your shopping list.</p>
        <div className="mt-4">
          <RangePicker
            disabledDate={(current) => this.disableDates(current, planStartDate, planEndDate)}
            format={'MMM DD, YYYY'}
            onChange={(date, dateString) => {
            this.setState({
              dateStart: dateString[0],
              dateEnd: dateString[1]
            })
          }} />
        </div>
        <div className="week-buttons text-right">
          <Button
            type="secondary"
            className="save-btn-modal"
            onClick={toggleDatePickerModal}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="save-btn-modal"
            onClick={this.save}
          >
            Add
          </Button>
        </div>
      </Modal>
    )
  }
}

export default connect(null, null)(DatePickerModal);