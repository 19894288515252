import React from 'react';
import { Button } from 'antd';
import { MacroCard } from '../Home/MacroCard';
import SmartCoach from '../../assets/images/SmartCoach.svg';
import MealPlan from './MealPlan';
import './index.css';

class SuggesticMealPlanPage extends React.Component {

  state={nutritionHomeData: []};

  componentDidMount(){
    const { nutrition } = this.props;
    let finalNutrition = nutrition;
    if (nutrition.nutritionResults) {
      finalNutrition = nutrition.nutritionResults
    }
    let nutritionHomeData = [
      {key: 1, value: finalNutrition.goalCalories}, 
      {key: 2, value: finalNutrition.protein},
      {key: 3, value: finalNutrition.carbs}, 
      {key: 4, value: finalNutrition.fats}
    ];
    this.setState({ nutritionHomeData: nutritionHomeData });
  }

  render(){
    return(
      <>
        {this.props.profile.user.isPro && this.props.profile.currentMealPlan.active ? 
        <MealPlan {...this.props} currentMealPlan={this.props.profile.currentMealPlan}/> :
        <div className='site-layout-background meal-plan-page home-page flex flex-center-aligned flex-column'>
          <div className='no-data'>
            <img src={SmartCoach} style={{height: '220px'}} />
            <div className='meal-plan-macros'>
              {this.state.nutritionHomeData.map(data => <MacroCard {...this.props} key={data.key} data={data} />)}
            </div>
            <p className='title' style={{padding: '20px 0 20px 0'}}>Smart 7-day meal plans that adapt to you progress week by week</p>
            <Button type='primary'>Coming soon</Button>
          </div>
        </div>}
      </>
    )
  }
}

export default SuggesticMealPlanPage;