import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import NutritionPlan from '../../assets/images/NutritionPlan.png';
import WorkoutPlan from '../../assets/images/WorkoutPlan.png';
import SmartShoppingList from '../../assets/images/SmartShoppingList.png';
import AlternativeFoods from '../../assets/images/AlternativeFoods.png';
import Recipes from '../../assets/images/Recipes.png';
import Mockup from '../../assets/images/Mockup-min.png';
import ExerciseHistory from '../../assets/images/ExerciseHistory.png'
import { faqData } from '../../data/faq';
import { renderFaq } from '../../components/Faq';
import { GoogleReviews } from '../../components/GoogleReviews/index';
import JSConfetti from 'js-confetti';
import { useDispatch, useSelector } from 'react-redux';
import { openReviewModal } from '../../ducks/Global/actions';
import { Button } from 'antd';
import { AiOutlineCheck } from "react-icons/ai";
import { CountdownTimer } from '../../components/LandingCountdownTimer';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { SHOW_TOP_HEADER } from '../../ducks/Global/constants';
import { ReviewModal } from '../../components/GoogleReviews/reviewModal';
import { ArrowRightOutlined } from "@ant-design/icons";
import './index.css';

const isProduction = process.env.NODE_ENV === 'production';

function ProgramResultsPage(){
  const sectionToScroll = useRef(null);
  const history = useHistory();
  const [isTimerExpired, setTimerExpired] = useState(false);
  const dispatch = useDispatch();
  const jsConfetti = new JSConfetti();
  const { selectedRecommendation, numberOfWorkouts } = history.location.state;
  const reviewModalOpen = useSelector((state) => state.global.reviewModalOpen);
  const review = useSelector((state) => state.global.review);

  let expiryTimestamp = localStorage.getItem('expiryTimestamp');

  if (!expiryTimestamp) {
    const currentTime = Date.now();
    expiryTimestamp = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    localStorage.setItem('expiryTimestamp', expiryTimestamp.toString());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isProduction) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: SHOW_TOP_HEADER, 
      showTopHeader: true, 
      content: (
        `<p><span font-style: italic !important;">🚀 The Last Fitness Program You'll Ever Need: Join Our Beta! 🚀</p>`
      )
    })
    triggerConfetti();
  },[]);

  useEffect(() => {
    const currentTime = Date.now();
    if (expiryTimestamp <= currentTime) {
      setTimerExpired(true);
    }
  }, []);

  const scrollToMyRef = () => {
    const ref = sectionToScroll.current;
    if (ref) {
      window.scrollTo({
        top: ref.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  const triggerConfetti = () => {
    jsConfetti.addConfetti({
      emojis: ['💪', '🔥', '🎉'],
      emojiSize: 110,
      confettiNumber: 40
   })
  };

  const handleStripeCheckoutClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Stripe Checkout Button',
    });

    const productUrl = isTimerExpired ? 'https://billing.alvarofitness.com/b/00g9AOcjr4AeaNG009' : 'https://billing.alvarofitness.com/b/9AQ5ky3MVfeS3le008';

    window.open(productUrl, '_blank')
  };

  const renderNumberOfWorkouts = () => {
    return (
      <div className='section' style={{background: colorBasedOnGender}}>
        <div className='number-of-workouts-container'>
          <p style={{fontSize: '100px'}}>💪</p>
          <div className='text-section'>
            <p className='title mb-2'>{numberOfWorkouts} Workouts Completed</p>
            <p className='paragraph subtitle'>{`Join the movement. Our community has already completed a total of ${numberOfWorkouts} and counting!`}</p>
            {/* <div className='link-container'>
              <p style={{cursor: 'pointer'}}>Check out the last completed workout</p>
              <HiOutlineArrowNarrowRight style={{marginLeft: '5px'}}/>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  const whatYouGet = [
    {key: 1, img: WorkoutPlan, title: 'Science Backed Workouts & Logging ', subtitle: 'Get detailed strength and cardio workouts for your goals, abilities, and preferences. Log your sets, reps, and get real time feedback on exercises compared to previous history.'},
    {key: 2, img: NutritionPlan, title: 'Personalized Nutrition', subtitle: 'No one-size-fits-all plans here. Your program is completely personalized based on your specific macro and calorie needs, your age, gender, weight, lifestyle, and more.'},
    {key: 3, img: AlternativeFoods, title: 'Alternative Foods', subtitle: 'Out of an ingredient on your plan? I\'ve got you covered! The alternative foods feature allows you to switch foods based on your preferences and what you have available.'},
    {key: 4, img: SmartShoppingList, title: 'Smart Shopping Lists', subtitle: 'Just select the days you’d like to shop for and items and quantities will be calculated and added to your list automatically! Instantly know exactly what and how much to shop for.'},
    {key: 5, img: Recipes, title: 'Delicious Recipes', subtitle: 'Access thousands of recipes and apply advanced filters such as calorie range, diet type, allergies, and more.'},
    {key: 6, img: ExerciseHistory, title: 'Exercise History Tracking', subtitle: 'Check your history for each exercise and get valuable information regarding your performance for each exercise.'}
  ];

  const howItWorks = [
    {key: 1, icon: WorkoutPlan, title: 'Sign Up & Complete Assessment', subtitle: 'After signing up for a program, you\'ll receive an email to complete your assessment. This will allow me to create the perfect plan for you based on your goals.'},
    {key: 2, icon: AlternativeFoods, title: 'Dashboard Access & Food Preferences', subtitle: 'In your dashboard, you\'ll have the option to select your food preferences and set what foods you prefer to avoid in your plan.'},
    {key: 3, icon: AlternativeFoods, title: 'Prepare for Your Custom Program', subtitle: 'While I craft your personalized fitness program, take some time to explore the Learn section on your dashboard for valuable insights and tips on optimizing your fitness journey.'},
    {key: 4, icon: AlternativeFoods, title: 'Start Your Transformation Journey', subtitle: 'Once your personalized fitness program is ready, you can start your transformation journey. Access your workout plans, nutrition guides, and track your progress in your dashboard.'}
  ];

  const pricingCardList = [
    'Personalized Nutrition Plan',
    'Science Based Workout Plan',
    'Access To 1000\'s Of Recipes',
    'Smart Shopping List Generator',
    'Workout Logging',
    'Real Time Feedback On Exercises',
    'Exercise History Data',
    'Lifetime Access, One Time Payment',
    'Always Evolving Features',
    'Exercise Tutorial Videos (Coming soon)',
    'Ab Workout Routines',
    'Alternative Foods Funcionality',
    'Advanced Macronutrient Calculator',
    'Integrate Your Fitbit Food Log',
    'Integrate Your Withings Scale',
    'Private Support Community',
    '30-Day Money Back Guaranteed',
    'And more!'
  ]

  const benefits = [
    {
      icon: "💪",
      title: "Tailored Workouts and Nutrition",
      subtitle: "Upon sign up, our coaches will tailor a personalized workout and nutrition plan based on your goals and preferences. It's not one-size-fits-all; it's customized for you."
    },
    {
      icon: "📈",
      title: "Exercise History Tracking",
      subtitle: "Beyond simply monitoring your progress, this tool empowers you to hold yourself accountable and celebrate every achievement."
    },
    {
      icon: "🍎",
      title: "Dynamic Macronutrient Calculator",
      subtitle: "Your nutritional needs recalculated automatically whenever you update your weight, ensuring your numbers are evolving with your body."
    },
    {
      icon: "🔄",
      title: "Carb Cycling Week Builder",
      subtitle: "Tailored to adapt to your fitness goals, this tool strategically creates a weekly high, medium and low carb plan, enhancing fat loss and muscle gain."
    },
    {
      icon: "🛒",
      title: "Smart Shopping List",
      subtitle: "Our smart shopping list generator calculates items and quantities in your plan, saving you time and effort. No more guesswork."
    },
    {
      icon: "🍽️",
      title: "Alternative Foods",
      subtitle: "Flexibly adjust as needed. Every food item in your plan includes alternative options, enabling you to seamlessly swap for your preferred choice."
    },
    {
      icon: "🥘",
      title: "Thousands of Recipes",
      subtitle: "Craving a recipe over your scheduled meal? Easily get recommended recipes matching your meal's nutritional profile with just a simple click."
    },
    {
      icon: "🌟",
      title: "Share Your Success",
      subtitle: "It's not just about personal gains. Share your workouts, inspire others, and take pride in your achievements."
    },
    {
      icon: "📸",
      title: "Progress Picture Comparison",
      subtitle: "Track and compare your physical transformation with our easy-to-use progress picture feature."
    },
    {
      icon: "📚",
      title: "Access To Workout, Nutrition, and Lifestyle Guides",
      subtitle: "Continuously expand your knowledge and skills with our ever-growing collection of comprehensive workout, nutrition, and lifestyle guides."
    },
    {
      icon: "🔗",
      title: "Integrations",
      subtitle: "Seamlessly sync with Fitbit for advanced food tracking and Withings for accurate body composition data. Get of automatic updates for your weight, body fat, and nutritional needs as your body changes."
    },
    {
      icon: "🚀",
      title: "New Feature Releases",
      subtitle: "We're dedicated to innovation. Get ready for constant releases of new features and improvements, ensuring your fitness journey stays fresh and exciting."
    }
  ];

  const colorBasedOnGender = selectedRecommendation.gender === 'male' ? '#f0f2ff' : '#FFD9D9';
  const ctaColor = 'rgb(6, 101, 255)';
  const labelColor = '#FF0066';

  const buttonStyle = {
    background: selectedRecommendation.gender === 'male' ? '#2761f1' : '#ff809f',
    height: '50px',
    // width: '200px',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'all 0.5s ease',
  };

  const stepNumberStyle = {
    background: colorBasedOnGender,
    borderRadius: '10px',
    width: '60px',   
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const footerStyle = {
    borderTop: `5px solid ${selectedRecommendation.gender === 'male' ? '#2761f1' : '#ff809f'}`,
  }

  return (
    <>
      <ReviewModal 
        reviewModalOpen={reviewModalOpen} 
        openReviewModal={openReviewModal} 
        colorBasedOnGender={colorBasedOnGender}
        review={review}
      />
      <div className='landing-page'>
        <div className='header' style={{background: colorBasedOnGender}}>
          <div className='header-content'>
            <div className='text'>
              <p className='title mb-2'>{selectedRecommendation.program}</p>
              <p className='subtitle'>{selectedRecommendation.description}</p>
              <Button onClick={scrollToMyRef} style={buttonStyle} type='primary'>
                Learn More
                <ArrowRightOutlined />
                </Button>
            </div>
            <div className='img'>
              <img src={Mockup} />
            </div>
          </div>
        </div>
        <div ref={sectionToScroll} className='section gray'>
          <div className='content' style={{paddingTop: '0px'}}>
            <div className='grid grid-justify-center mb-5'>
              <p className='title mb-3' style={{textAlign: 'center'}}>A Fitness Platform at <span className='text-gradient underlined-title'>Another Level.</span></p>
              <p className='big-subtitle' style={{width: '100%', maxWidth: '600px', textAlign: 'center'}}>Seamless · Enjoyable · Transformative</p>
            </div>
            <div className='container'>
              <div className='benefits'>
                {benefits.map(benefit => 
                <div className='global-card flex flex-center-aligned'>
                  <p style={{fontSize: '50px', marginRight: '20px'}}>{benefit.icon}</p>
                  <div className='benefits-info'>
                    <h3 className='small-title'>{benefit.title}</h3>
                    <p className='subtitle'>{benefit.subtitle}</p>
                  </div>
                </div>)}
              </div>
              <Button className='landing-button' onClick={() => handleStripeCheckoutClick()} style={buttonStyle} type='primary'>Get Lifetime Access Now!</Button>
            </div>
          </div>
        </div>
        <div className='section white'>
          <div className='content'>
            <div className='grid grid-justify-center mb-5'>
              <p className='title mb-3' style={{textAlign: 'center'}}>About Your <span className='text-gradient underlined-title'>Program</span></p>
              <p className='big-subtitle' style={{width: '100%', maxWidth: '600px', textAlign: 'center'}}>Upon signing up, your coach will review your assessment and begin crafting a personalized workout and nutrition plan tailored just for you.</p>
            </div>
            <div className='container'>
            {whatYouGet.map(item => 
              <div className="what-you-get-item">
              <img className='mb-3' src={item.img} alt="Program Feature"/>
              <div className='text mt-2' style={{textAlign: 'center'}}>
                <p className="small-title mb-2">{item.title}</p>
                <p className="subtitle">{item.subtitle}</p>
              </div>
            </div>
            )}
            </div>
          </div>
        </div>
        {renderNumberOfWorkouts()}
        <div className='section gray'>
          <div className='text-content'>
            <p className='title mb-3' style={{textAlign: 'center'}}>100% Money-Back <span className='text-gradient underlined-title'>Guarantee</span></p>
            <p className='subtitle mb-3' style={{textAlign: 'center'}}>My commitment to you is crystal clear:</p>
            <p className='subtitle mb-3' style={{textAlign: 'center'}}>If you don't absolutely love the program and see significant results, I'll gladly refund your entire investment.</p>
            <p className='subtitle mb-3' style={{textAlign: 'center'}}>I have unwavering confidence in my ability to help you achieve your fitness goals. Your satisfaction and success are my top priorities.</p>
            <Button className='landing-button' onClick={() => handleStripeCheckoutClick()} style={buttonStyle} type='primary'>Get Lifetime Access Now!</Button>
          </div>
        </div>
        <div className='section white'>
          <div className='content'>
            <p className='title mb-5' style={{textAlign: 'center'}}>How It <span className='text-gradient underlined-title'>Works</span></p>
            <div className='container'>
              {howItWorks.map((step, i) => 
              <div className='how-it-works-card'>
                <div style={stepNumberStyle} className='title mb-2'>{i + 1}</div>
                <h3 className='small-title mb-1'>{step.title}</h3>
                <p className='subtitle'>{step.subtitle}</p>
              </div>
              )}
            </div>
          </div>
        </div>
        <div className='section white'>
          <div className='content'>
            <p className='title mb-5' style={{textAlign: 'center'}}>What Clients <span className='text-gradient underlined-title'>Think</span></p>
            <div className='container'>
              <GoogleReviews color={colorBasedOnGender}/>
            </div>
          </div>
        </div>
        <div className='section gray'>
          <div className='content'>
            <div>
            
              <div style={{textAlign: 'center'}}>
              {!isTimerExpired ? 
                <>
                  <p className='title mb-1' style={{textAlign: 'center'}}>Limited Time Offer!</p>
                  <CountdownTimer expiryTimestamp={parseInt(expiryTimestamp)} />
                </>
                :
                <div className='grid grid-justify-center mb-5'>
                  <p className='title mb-3' style={{textAlign: 'center'}}>Start Your <span className='text-gradient underlined-title'>Transformation, Today.</span></p>
                  <p className='big-subtitle'>All your fitness needs, in one place - Forever</p>
                </div>
                }
              </div>
              
              <div className='pricing-container mt-3' style={{background: colorBasedOnGender}}>
                <img src={Mockup} />
                <div className='list-container mb-3'>
                  <div className='pricing-items mb-3'>
                    <p className='discounted-price' style={{color: isTimerExpired ? 'black' : 'black'}}>{isTimerExpired ? '$349.00' : '$249.00'}</p>
                    {!isTimerExpired &&
                    <>
                      <div className='button'>Save $100</div>
                      <p className='actual-price'>$349.00</p>
                    </>}
                  </div>
                  <div className='mb-3'>
                    {pricingCardList.map(listItem => 
                      <div className='subtitle flex align-center mb-1' style={{fontWeight: '500'}}>
                        <AiOutlineCheck className='mr-10' style={{fontSize: '24px', color: '#0665ff', marginRight: '5px'}} />
                        <p>{listItem}</p>
                      </div>
                    )}
                  </div>
                   <Button onClick={() => handleStripeCheckoutClick()} className='landing-button full-width-button' style={buttonStyle} type='primary'>Get Lifetime Access Now!</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section white'>
          {/* <div className='grid-justify-center' style={{background: colorBasedOnGender, borderRadius: '20px', width: '100%', height: '300px', padding: '24px'}} >
          
          </div> */}
        <div className="offer-card" >
          <div className="offer-badge" style={{ background: labelColor }}>Beta Access</div>
          <div className="offer-content">
            <h1 className="title mb-3">🎉 Limited Time Offer: One-Time Payment for <span className='text-gradient underlined-title'>Lifetime Access!</span></h1>
            <p className="subtitle" style={{maxWidth: '600px', margin: '0 auto', marginBottom: '20px'}}>
              Grab this extraordinary opportunity to access your personalized program and our beta app for life with a single payment.
            </p>
            <p className="offer-warning">Prices will change soon!</p>
            {!isTimerExpired && 
            <div className='mb-4'>
              <CountdownTimer expiryTimestamp={parseInt(expiryTimestamp)} />
            </div>}
            <Button onClick={() => handleStripeCheckoutClick()} type='primary' style={buttonStyle} className='landing-button'>Get Lifetime Access Now!</Button>
          </div>
        </div>

        </div>
        <div className='centered-container'>
          <div className='max-width-container'>
            {renderFaq(faqData.programFaq, null, true)}
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(ProgramResultsPage);