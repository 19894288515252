import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { Divider, Modal, Input, Button, Alert, AutoComplete, Select } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WorkoutCard } from '../AdminWorkoutLibrary/workoutCard';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Loading } from '../../components/Loading';

const Option = Select.Option;

class WorkoutProgramModal extends React.Component {
  constructor(){
    super();
    this.state = {
      workoutProgram: {workouts: []},
      isCreateOrEdit: false,
      clients: [],
      filteredClients: [],
      notFound: [{key:'not found', name: "No data found"}],
      selectedClientId: '',
      isAssign: false
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.createWorkoutProgramSuccess !== this.props.createWorkoutProgramSuccess && this.props.createWorkoutProgramSuccess && this.state.selectedClientId) {
      toast.success(`Successfully Assigned!`);
    }
    //set workout program to state when it is passed in as a prop
    if (prevProps.workoutProgram !== this.props.workoutProgram){
      this.setState({workoutProgram: this.props.workoutProgram})
    }
    if(prevProps.clients !== this.props.clients){
      this.setState({clients: this.props.clients});
    }
    const isUpdate = this.state.workoutProgram._id;
    if (prevProps.createWorkoutProgramSuccess !== this.props.createWorkoutProgramSuccess && this.props.createWorkoutProgramSuccess && !this.state.isAssign) {
      this.setState({isCreateOrEdit: false});
      toast.success(`Workout program has been ${isUpdate ? 'updated' : 'created'}!`);
    }
  }

  onDragEnd = (result) => {
    const { destination, source } = result;
  
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    // Create a new array with the reordered workout cards
    const newWorkouts = Array.from(this.state.workoutProgram.workouts);
    const [removed] = newWorkouts.splice(source.index, 1);
    newWorkouts.splice(destination.index, 0, removed);
  
    // Update the workout program with the new workout order
    const updatedWorkoutProgram = {
      ...this.state.workoutProgram,
      workouts: newWorkouts,
    };

    // Create a workoutProgram object with an array of workout IDs
    const updatedWorkoutProgramWithIds = {
      ...updatedWorkoutProgram,
      workouts: updatedWorkoutProgram.workouts.map(w => typeof w === 'string' ? w : w._id),
    };

    this.props.updateWorkoutProgram(updatedWorkoutProgramWithIds);
    // Update the local state with the new workout order
    this.setState({ workoutProgram: updatedWorkoutProgram });
  };

  renderInputField = (workoutProgram, fieldName, label, type = 'text') => {
    return (
      <div className="form-section">
       <p className="label">{`${fieldName === 'name' ? '*' : ''}${label}`}</p>
        {
          <Input
            type={type}
            onChange={(e) => this.setState({workoutProgram: {...this.state.workoutProgram, [fieldName]: e.target.value}})}
            value={
              workoutProgram && workoutProgram[fieldName]
                ? workoutProgram[fieldName]
                : ""
            }
          />
        }
      </div>
    );
  }

  renderAddWorkoutsSection = () => {
    const { workoutProgram } = this.state;
    const { workouts } = this.props;
    const availableWorkouts = workouts.filter(
      (workout) => !workoutProgram.workouts.includes(workout._id)
    );
  
    return (
      <div style={{ marginTop: '10px' }}>
        <div className="two-columns-text">
          <p className='modal-subtitle'>All Library Workouts</p>
          <p className='modal-subtitle'>Workouts In Program</p>
        </div>
        <div className="two-columns">
          <div className="column-container">
            <div className="exercise-card-container">
              {availableWorkouts.map((workout) => (
                <WorkoutCard
                  workout={workout}
                  isCreateEditView={true}
                  onSelected={this.toggleSelectedWorkout}
                  showWorkoutPreview={this.props.showWorkoutPreview}
                />
              ))}
            </div>
          </div>
          <div className="column-container">
            <div className="exercise-card-container">
            {workoutProgram.workouts.map((workoutId, i) => {
              // workoutId is a string if it is a workout that is not in the database yet and if I am editing a workout with already existing workouts in the program, workoutId is an object
              const workout = typeof(workoutId) === 'string' ? 
              workouts.find((w) => w._id === workoutId) : 
              workouts.find((w) => w._id === workoutId._id)
              return (
                <WorkoutCard
                  index={i + 1}
                  toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
                  workout={workout}
                  isCreateEditView={true}
                  workoutInProgramColumn={true}
                  workoutProgramDisplay={true}
                  showWorkoutPreview={this.props.showWorkoutPreview}
                  onSelected={this.toggleSelectedWorkout}
                />
              );
            })}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  toggleSelectedWorkout = (selectedWorkout, updateDatabase) => {
    this.setState(
      prevState => {
        const { workouts } = prevState.workoutProgram;
        let updatedWorkouts;
  
        if (workouts.some(w => w._id === selectedWorkout._id)) {
          updatedWorkouts = workouts.filter(w => w._id !== selectedWorkout._id);
        } else {
          updatedWorkouts = [...workouts, selectedWorkout];
        }
        return {
          workoutProgram: {
            ...prevState.workoutProgram,
            workouts: updatedWorkouts,
          },
        };
      },
      () => {
        if (updateDatabase) {
          const workoutProgramWithIds = {
            ...this.state.workoutProgram,
            workouts: this.state.workoutProgram.workouts.map(w => typeof w === 'string' ? w : w._id),
          };
          this.props.updateWorkoutProgram(workoutProgramWithIds);
        }
      }
    );
  };

  renderClientField = (label) => {
    const { clients } = this.props;
    const { isAssign, selectedClientId, workoutProgram } = this.state;
   
    return (
      clients && isAssign ?
      <>
        <div className="form-section">
          <p className="label">*{label}</p>
          <div className='flex flex-center-aligned'>
            <AutoComplete 
              dataSource={this.state.filteredClients && this.state.filteredClients.length >= 1 ? this.state.filteredClients.map(this.renderOption) : this.state.notFound.map(this.renderNotFound)}
              style={{ width: '100%', marginRight: '5px' }} 
              onSearch={(e) => this.filterClients(e)} 
              onSelect={(value) => this.setState({selectedClientId: value})}
              onChange={() =>  this.setState({selectedClientId: ''})}
              optionLabelProp="name"
              placeholder='Search client...'
              allowClear={true}
            />
            {this.state.selectedClientId &&
              <Button 
                type='primary' 
                style={{marginRight: '5px'}}
                onClick={() => this.props.createUserWorkoutProgram(selectedClientId, workoutProgram._id, workoutProgram.description)}
                loading={this.props.workoutProgramsLoading}
              >
                Assign
              </Button>
            }
            
            <Button 
              type='secondary' 
              onClick={() => this.setState({isAssign: false})}
            >
              Cancel
            </Button>
            
          </div>
        </div>
      </>
      : 

      <Button
        onClick={() => {
        if(!this.state.isAssign && !this.props.clients) {
          this.props.getAllOnsiteClients();
        }
        this.setState({ isAssign: !this.state.isAssign });
        }}
        type='primary'
        className="save-btn-modal mt-1"
      >
        Assign Workout To User
      </Button>
    )
  }

  renderNotFound = (item) => {
    return (
      <Option key={item.key}>
        {item.name}
      </Option>
    );
  }

  filterClients = (e) => {
    const { clients } = this.props;
    let filtered;
    e === "" 
    ? filtered = clients 
    : filtered = clients.filter(client => client.user && client.user.name.toLowerCase().includes(e.toLowerCase()) || client.user && client.user.lastName && client.user.lastName.toLowerCase().includes(e.toLowerCase()));
    this.setState({filteredClients: filtered});
  }

  renderOption = (client) => {
    return (
      <Option key={client.user && client.user._id} name={`${client.user && client.user.name} ${client.user && client.user.lastName  ? client.user.lastName : ''}`}>
        {client.user && client.user.name} {client.user && client.user.lastName ? client.user.lastName : ''} - {client.user && client.user.email}
      </Option>
    );
  }

  render(){
    const { workoutProgram } = this.state;
    const { isView, workoutProgramsError, workoutProgramsLoading, isUserWorkoutProgram } = this.props;
  
    return (
      <Modal
        title={!isView && !isUserWorkoutProgram ? 'Create Workout Program' : ''}
        width={'800px'}
        visible={this.props.visible}
        onCancel={() => {
          this.props.toggleWorkoutProgramModal(false); 
          // this.setState({isCreateOrEdit: false, workoutProgram: {workouts: []}})
        }}
        footer={null}
        centered={true}
        className='create-workout-modal'
      >
        <div className='modal'>
          {!isView && !isUserWorkoutProgram ?
          <>
            {this.renderInputField(workoutProgram, 'name', 'Name')}
            {this.renderInputField(workoutProgram, 'description', 'Description')}
            {this.props.clientsLoading ? <Loading /> : this.renderClientField('Assign to')}
            <Divider />
          </> :
          <>
            <div className='workout-details-card'>
              <p className='home-card-text'>{workoutProgram && workoutProgram.name}</p>
              <p style={{color: 'black'}}>{workoutProgram && workoutProgram.description}</p>
            </div>
            <Divider />
          </>}
          {!this.state.isCreateOrEdit ?
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="workoutCards">
              {(provided) => (
                <div 
                  {...provided.droppableProps}
                  ref={provided.innerRef} 
                 >
                  {this.state.workoutProgram.workouts.map((workout, index) => (
                      <Draggable 
                        key={workout._id} 
                        draggableId={workout._id} 
                        index={index}
                        isDragDisabled={isUserWorkoutProgram}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <WorkoutCard
                              index={index + 1}
                              workout={workout}
                              workoutProgramDisplay={true}
                              isUserWorkout={this.props.isUserWorkout || false}
                              showWorkoutPreview={this.props.showWorkoutPreview}
                              toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
                              setCurrentWorkout={this.props.setCurrentWorkout}
                              workoutInProgress={this.props.workoutInProgress}
                            />
                            <Divider />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext> :
          this.renderAddWorkoutsSection()}
        </div>
        {!isUserWorkoutProgram &&
        <div className="week-buttons text-right">
          <Button
            onClick={() => this.setState({isCreateOrEdit: !this.state.isCreateOrEdit})}
            type= {this.state.isCreateOrEdit ? 'secondary' : 'primary'}
            // className="save-btn-modal"
          >
            {this.state.isCreateOrEdit ? 'Done' : 'Manage Workouts'}
          </Button>
          {/* this.props.createUserWorkoutProgram(client, this.state.workoutProgram._id, notes) */}
          {/* {this.state.isCreateOrEdit && */}
          <Button
            onClick={() => {
              const workoutProgramWithIds = {
                ...this.state.workoutProgram,
                workouts: this.state.workoutProgram.workouts.map(w => typeof w === 'string' ? w : w._id),
              };
              if (this.state.workoutProgram._id) {
                this.props.updateWorkoutProgram(workoutProgramWithIds);
              } else {
                this.props.createWorkoutProgram(workoutProgramWithIds);
              }
            }}
            type="primary"
            className="save-btn-modal"
            loading={workoutProgramsLoading}
          >
          {this.state.workoutProgram._id ? 'Update' : 'Save'}
        </Button>
        {/* // } */}
        </div>}
        {workoutProgramsError && (
          <Alert
            type="error"
            message={
              workoutProgramsError.errors &&
              Object.values(workoutProgramsError.errors).map((err, key) => (
                <p key={key}>{err.msg}</p>
              ))
            }
          />
        )}
      </Modal>
    )
  }
}

export default WorkoutProgramModal;