import React from 'react';
import './index.scss';

export const RemainingMacros = (props) => {
  return(
    <>
      <div className="fitbit-macros-card">
        <p className={`macro-number ${Math.sign(props.nutritionHomeData[0].value - props.fitbitNutrition.summary.calories) === 1 ? 'green' : 'red'}`}>
          {`${props.nutritionHomeData[0].value - props.fitbitNutrition.summary.calories}`}
        </p>
        <p className='macro-label-bottom'>Calories</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className={`macro-number ${Math.sign(props.nutritionHomeData[1].value.grams - props.fitbitNutrition.summary.protein) === 1 ? 'green' : 'red'}`}>
            {`${(props.nutritionHomeData[1].value.grams - props.fitbitNutrition.summary.protein).toFixed(1)}`}
          </p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>Protein</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className={`macro-number ${Math.sign(props.nutritionHomeData[2].value.grams - props.fitbitNutrition.summary.carbs) === 1 ? 'green' : 'red'}`}>
            {`${(props.nutritionHomeData[2].value.grams - props.fitbitNutrition.summary.carbs).toFixed(1)}`}
          </p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>Carbs</p>
      </div>

      <div className="fitbit-macros-card">
        <div className='flex baseline'>
          <p className={`macro-number ${Math.sign(props.nutritionHomeData[3].value.grams - props.fitbitNutrition.summary.fat) === 1 ? 'green' : 'red'}`}>
          {`${(props.nutritionHomeData[3].value.grams - props.fitbitNutrition.summary.fat).toFixed(1)}`}
          </p>
          <p className='text-suffix'>g</p>
        </div>
        <p className='macro-label-bottom'>Fats</p>
      </div>
    </>
  )
}