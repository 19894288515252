import React from 'react';
import { connect } from 'react-redux';
import TablePage from '../../components/TablePage';
import { formatUserRole, formatUserStatus, renderClientNameEmail, formatDaysLeftForPlan } from '../../helpers/utils';
import { Menu, Dropdown, Input, Button, Switch, Modal, Checkbox, Alert} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { OPEN_DRAWER } from '../../ducks/Global/constants';
import SideDrawer from '../../components/SideDrawer';
import moment from 'moment';
import { ClientCard } from './clientCard';
import './index.css';
import CreateUserModal from './CreateUserModal';

const { Search } = Input;

class AdminClientsPage extends React.Component {
  constructor(){
    super();
    this.state={
      userSelected: null,
      role: null,
      onsiteClients: [],
      filteredOnsiteClients: [],
      createUserModalVisible: false,
      deleteUserChecked: false,
      confirmDeleteUserModalVisible: false,
      deleteUserError: true,
      userToDelete: null,
    }
  }

  componentDidMount(){
    this.setState({
      role: this.props.profile.user.role,
      onsiteClients: this.props.onsiteClients, 
      filteredOnsiteClients: this.props.onsiteClients
    });
  }

  filterClients = (searchTerm) => {
    let clients = [...this.state.onsiteClients];
    let filteredClients = clients.filter(client => client.user && client.user.name && client.user.name.toLowerCase().includes(searchTerm.toLowerCase()) || client.user && client.user.lastName && client.user.lastName.toLowerCase().includes(searchTerm));
    this.setState({filteredOnsiteClients: filteredClients});
  }

  renderMenuIcon = (profile) => {
    return (
      <Dropdown 
        overlay={() => this.menu(profile)} 
        placement="bottomLeft" 
        arrow
      >
       <EllipsisOutlined onClick={e => e.stopPropagation()} style={{fontSize: '20px'}}/>
      </Dropdown>
    )
  }

   ////work on stopping propagation///
  menu = (profile) => {
    return (
      <Menu onClick={(e) => this.handleDropdown(e, profile)}>
        <Menu.Item key="measurement">
          <p className='measurement'>Add measurement</p>
        </Menu.Item>
        {/* { */}
          {/* // profile.isCustomMealExist &&  */}
          <Menu.Item key="viewMealPlan">
            <p className='measurement'>
              View Meal Plan
              {/* {profile.isCustomMealExist && 'View Meal Plan'} */}
            </p>
          </Menu.Item>
        {/* } */}
        <Menu.Item key="mealPlan">
          <p className='measurement'>
            Edit Meal Plan
            {/* {profile.isCustomMealExist ? 'Edit Meal Plan' : 'Create Meal Plan'} */}
          </p>
        </Menu.Item>
        {profile.fitbitToken &&
        <Menu.Item key="foodLog">
          <p className='measurement'>View Food Log</p>
        </Menu.Item>}
      </Menu>
    )
  }

  handleDropdown = (e, record) => {
    if(e.key === 'measurement'){
      this.setState({userSelected: record})
      this.props.openDrawer(e.key === 'measurement');
    } else if(e.key === 'viewMealPlan') {
      if(record && record.user) {
        this.props.history.push({
          pathname: `/dashboard/admin/clients/${record.user._id}/edit-meal-plan`,
          state: { userId: record.user._id }
        })
      }
    } else if (e.key === 'mealPlan') {
      this.props.history.push({
        pathname: `/dashboard/admin/clients/${record.user._id}/meal-plan`,
        state: { userId: record.user._id }
      })
    } else if(e.key === 'foodLog'){
      this.props.history.push({
        pathname: `/dashboard/admin/clients/${record.user._id}/food-log`,
        state: { userId: record.user._id }
      })
    }
  }

  columnsClients = () => {
    return [
      {title: 'Client', dataIndex: "user", key: 'user', width: '300px', render: (e, record) => (
        renderClientNameEmail(record, this.props.history)
      )},
      {title: 'Age', dataIndex: 'age', key: 'age', width: '85px', sorter: (a, b) => a.age - b.age,},
      {title: 'Role', dataIndex: "user['role']", key: 'clientType', width: '120px', sorter: (a, b) => a.user.role.length - b.user.role.length, render: role => formatUserRole(role)},
      {title: 'Days Left', dataIndex: 'daysLeftForPlan', key: 'daysLeftForPlan', width: '120px', sorter: (a, b) => a.daysLeftForPlan - b.daysLeftForPlan, render: daysLeftForPlan => formatDaysLeftForPlan(daysLeftForPlan)},
      {title: 'Type', dataIndex: "user['isPro']", key: 'status', width: '85px', render: status => formatUserStatus(status)},
      {title: 'Joined On', dataIndex: "date", key: 'date', width: '120px', render: date => moment(date).format("MMM D, YY")},
      {title: '', width: '20px', render: (e, record) => this.renderMenuIcon(e, record)}
    ];
  }

  tableHeader = () => {
    return(
      <p className='table-count'>Showing {this.state.filteredOnsiteClients && this.state.filteredOnsiteClients.length} clients</p>
    )
  }

  toggleCreateUserModal = (status) => {
    this.setState({ createUserModalVisible: status })
  }

  toggleConfirmDeleteUserModal = (status) => {
    this.setState({ confirmDeleteUserModalVisible: status })
  }

  deleteUserAndData = () => {
    if(!this.state.deleteUserChecked){
      this.setState({error: true});
    } else {
      this.setState({error: false});
      this.props.deleteUserAndRelatedData(this.state.userToDelete.user._id);
      this.toggleConfirmDeleteUserModal(false);
    }
  }

  setUserToDelete = (client) => {
    this.setState({userToDelete: client});
    this.toggleConfirmDeleteUserModal(true);
  }
  
  render(){
    return(
      <div className='clients-table'>
        <Modal
          title="Are you sure you want to delete this user?"
          visible={this.state.confirmDeleteUserModalVisible}
          centered={true}
          onCancel={() => this.toggleConfirmDeleteUserModal(false)}
          footer={null}
          width={500}
        >
          <div className='text mb-2'>
            <p className='mb-2'>WARNING: Deleting this user will also delete all the data associated with this user.</p>
            <Checkbox
              checked={this.state.deleteUserChecked}
              onChange={(e) => this.setState({deleteUserChecked: e.target.checked})}
            >
              I understand deleting this user will also delete all it's associated data.
            </Checkbox>
          </div>
          <div>
            <Button type="ghost" className='mr-05' onClick={() => this.toggleConfirmDeleteUserModal(false)}>Cancel</Button>
            <Button type="danger" onClick={() => this.deleteUserAndData()}>Delete</Button>
          </div>
          {this.state.error &&
          <Alert
            message={'Please check the checkbox to proceed.'}
            type="error"
          />}
        </Modal>
        <div className="mb-4 text-right">
          <Button type="primary" onClick={() => this.toggleCreateUserModal(true)}>
            Create New
          </Button>
        </div>
        <CreateUserModal
          history={this.props.history}
          toggleCreateUserModal={this.toggleCreateUserModal}
          visible={this.state.createUserModalVisible}
          redirect={true}
        />
        <SideDrawer 
          role={this.state.role} 
          data={this.state.userSelected} 
          isMetric={this.props.profile.isMetric}
          currentWeight={this.props.profile.currentWeight}
        />
        <div className='flex flex-center-aligned space-between'>
          <p className='home-card-title'>Client search</p>
          <Switch
            className='mb-2'
            onChange={() => this.setState({tableView: !this.state.tableView})}
            checked={!this.state.tableView}
            checkedChildren="Cards"
            unCheckedChildren="Table"
          />
        </div>
        <Search 
          className='search'
          style={{marginBottom: '10px'}}
          onChange={(e) => this.filterClients(e.target.value)}
          placeholder="Search client..."
          allowClear
          // onSearch={() => this.props.getFiltersBeforeSearch()}
        />
        {this.state.tableView ? 
        <TablePage 
          {...this.props}
          tableDataLoading={!this.props.onsiteClients}
          columns={this.columnsClients()}
          tableData={this.state.filteredOnsiteClients}
          isDisabledRowHover
          tableName="clients"
          // tableUrl={`${process.env.APP_API_URL}accounts/${this.props.group.id}/campaigns`}
          scroll={{x: true}}
          tableHeader={this.tableHeader()}
        /> :
        <div className='client-card-container'>
          {this.state.filteredOnsiteClients.map(client => 
          <ClientCard 
            key={client._id}
            history={this.props.history} 
            client={client} 
            setUserToDelete={this.setUserToDelete}
          />)}
        </div>}
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    openDrawer: () => {
      return dispatch({
        type: OPEN_DRAWER
      });
		},
  }
}

export default connect(null, mapDispatchToProps)(AdminClientsPage);
