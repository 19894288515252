import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthModal from '../../components/AuthModal';
import { saveProfile } from "../../ducks/Profile/actions";
import { setIsLogin } from '../../ducks/Global/actions';
import { loginUser } from "../../ducks/Auth/actions";
import welcomeBack from '../../assets/images/welcome-back.svg'
import '../../components/AuthModal/index.css';

class Login extends React.Component {

  render() {
    let hasCompletedAssessment = false;
    if (this.props.auth.isAuthenticated && this.props.auth.user) {
      if (this.props.auth.user.isAssessmentCompleted) {
        hasCompletedAssessment = true;
        return <Redirect to='dashboard/home' />
      } else {
        return <Redirect to='assessment' />
      }
    }
    return (
      <div className='login-page'>
        <div className='login-container'>
          <div className='form-section'>
            <AuthModal
              {...this.props}
              isAssessment={hasCompletedAssessment}
              authRedirectRoute={hasCompletedAssessment ? "dashboard/home" : "/assessment"}
              isLogin={this.props.isLogin}
              setIsLogin={this.props.setIsLogin(true)}
              saveProfile={this.props.saveProfile}
              loginUser={this.props.loginUser}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authRedirectRoute: state.auth.authRedirectRoute,
    isLogin: state.global.isLogin,
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveProfile: (payload) => dispatch(saveProfile(payload)),
    setIsLogin: (value) => dispatch(setIsLogin(value)),
    loginUser: (formData, cb) => dispatch(loginUser(formData, cb)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);