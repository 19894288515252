import {
  GET_TABLE_DATA,
  GET_TABLE_DATA_SUCCESS,
  GET_TABLE_DATA_ERROR
} from "./constants";

export function getTableData(path) {
  return {
    type: GET_TABLE_DATA,
    path
  };
}

export function getTableDataSuccess(payload) {
  return {
    type: GET_TABLE_DATA_SUCCESS,
    payload
  };
}

export function getTableDataError(error) {
  return {
    type: GET_TABLE_DATA_ERROR,
    error
  };
}