import React from "react";
import { Link } from 'react-router-dom';
import { Input, Button, Alert } from "antd";
import { connect } from "react-redux";
import {
  getGoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getIdToken,
  extractUserName,
} from "../../helpers/auth";
import "./index.css";
import PasswordField from "./PasswordField";
import PasswordResetForm from "./PasswordResetForm";
import { fetchAPI } from "../../ducks/api";
import firebase from 'firebase/compat/app';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class AuthModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loginObj: {},
      passwordError: null,
      loading: false
    };
  }

  handleLoginForm = (e, field) => {
    let loginObj = { ...this.state.loginObj };
    loginObj[field] = e.target.value;
    this.setState({
      loginObj: loginObj,
    });
  };

  handleLoginProcess = (token, type) => {
    const { loginUser, history } = this.props;
    const messageKey = type === "password" ? "passwordError" : "connectError";
    this.setState({ [messageKey]: null, loading: true });
    loginUser({ token }, async (error, result) => {
      if (!error) {
        this.setState({ [messageKey]: null, loading: false });
        if (!this.props.isAssessment) {
          await sleep(200);
          return history.push("/dashboard/home");
        } else {
          await sleep(200);
          return history.push("/assessment");
        }
      }
      return this.setState({ [messageKey]: error.message, loading: false });
    });
  };

  handleRegistrationProcessForGoogle = (registrationData, type) => {
    const { registerUser, history } = this.props;
    const messageKey = type === "password" ? "passwordError" : "connectError";
    this.setState({ [messageKey]: null, loading: true });
    console.log("handling user registration on server");
    //calls registerUser and saves to DB
    registerUser({ ...registrationData, isGoogleSignUp: true }, async (error, result) => {
      if (!error) {
        this.setState({ [messageKey]: null, loading: false });
        if (!this.props.isAssessment) {
          await sleep(200);
          return history.push("/dashboard/home");
        }
        if (this.props.authRedirectRoute.length > 0) {
          // Save nutrition
          // Save profile
          // call save profile which calls save nutrition which will redirect here
          const payload = {
            profileData: { ...this.props.assessmentProfile },
            nutritionResults: { ...this.props.assessmentResults[0] },
            callback: () =>
              this.props.history.push(this.props.authRedirectRoute),
          };
          this.props.saveProfile(payload);
        }
        return null;
      }
      return this.setState({ [messageKey]: error.message, loading: false });
    });
  };

  handleRegistrationProcess = async (registrationData, type) => {
    const { registerUser, history } = this.props;
    const messageKey = type === "password" ? "passwordError" : "connectError";
    this.setState({ [messageKey]: null, loading: true });
    console.log("handling user registration on server");
    if (!this.props.isAssessment) {
      await sleep(200);
      return history.push("/dashboard/home");
    }

    let payload;
    if (this.props.authRedirectRoute.length > 0) {
      payload = {
        profileData: { ...this.props.assessmentProfile },
        nutritionResults: { ...this.props.assessmentResults[0] },
        callback: () =>
          this.props.history.push(this.props.authRedirectRoute),
      };
    }

    delete registrationData.password;
    registerUser(
      {
        ...registrationData,
        profile: { ...(payload && payload.profileData || {}) },
        nutrition: payload && payload.nutritionResults,
      }, async (error, result) => {
        if (error) {
          const user = firebase.auth().currentUser;
          user && await user.delete();
          return this.setState({ [messageKey]: error.message, loading: false });
        }
        this.setState({ [messageKey]: null, loading: false });
        this.props.history.push('/verify-email')

      }
    )
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { loginObj } = this.state;
    const { email, password } = loginObj;
    this.setState({ loading: true });
    if (this.props.isLogin) {
      try {
        await signInWithEmailAndPassword(email, password);
        const token = await getIdToken();
        this.handleLoginProcess(token, "password");
      } catch (error) {
        this.setState({ passwordError: error.message, loading: false });
      }
    } else {
      if (!loginObj.name || !loginObj.lastName) {
        return this.setState({
          passwordError: "Please fill the required fields",
          loading: false,
        });
      }
      try {
        //Creates user with email and password in firebase
        const result = await createUserWithEmailAndPassword(email, password);
        const { uid } = result.user;
        const token = await getIdToken();
        this.handleRegistrationProcess({ ...loginObj, token, uid }, "password");
      } catch (error) {
        this.setState({ passwordError: error.message, loading: false });
      }
    }
  };

  registerIfUserDoesNotExist = async (user, token) => {
    window.localStorage.setItem("token", token);
    try {
      await fetchAPI("/auth/");
      this.handleLoginProcess(token, "connect");
    } catch (error) {
      // registering as the user does not exist
      // allow this function only from assessment page
      if (window.location.pathname === '/signup') {
        this.handleRegistrationProcessForGoogle(
          {
            email: user.email,
            uid: user.uid,
            ...extractUserName(user.displayName, user.email),
            avatar: user.photoURL
          },
          "connect"
        );
      } else {
        this.setState({
          connectError: 'There isn\'t an account associated with this email. Please sign up from the assessment page.',
        });
      }
    }
  };

  handleGoogleAuth = async () => {
    const provider = getGoogleAuthProvider();
    try {
      const result = await getAuth().signInWithPopup(provider);
      const token = await getIdToken();
      const user = result.user;
      this.registerIfUserDoesNotExist(user, token);
    } catch (error) {
      this.setState({
        connectError: error.message,
      });
    }
  };

  render() {
    const { connectError, passwordError, passwordReset } = this.state;
    return (
      <div
        className='sign-up-modal'
      >
        {passwordReset ? (
          <div className="login-form">
            <div className="login-content">
              <PasswordResetForm
                onCancel={() => this.setState({ passwordReset: false })}
              />
            </div>
          </div>
        ) : (
          <div className="login-form">
            <div className="login-content">
              {!this.props.isAssessment && <p className='auth-modal-title'>Welcome</p>}
              {this.props.isAssessment && (
                <p className='auth-modal-title'>
                  Sign up
                </p>
              )}
              {connectError && (
                <Alert
                  style={{ marginBottom: 15 }}
                  message={connectError}
                  type="error"
                />
              )}
              <form className="form">
                {this.props.isAssessment && (
                  <div className='mb-2'>
                    <p className='label'>Name</p>
                    <Input
                      onChange={(e) => this.handleLoginForm(e, "name")}
                    />
                  </div>
                )}
                {window.location.pathname === '/signup' && (
                  <div className='mb-2'>
                    <p className='label'>Last name</p>
                    <Input
                      onChange={(e) => this.handleLoginForm(e, "lastName")}
                    />
                  </div>
                )}
                <div className='mb-2'>
                  <p className='label'>Email</p>
                  <Input
                    onChange={(e) => this.handleLoginForm(e, "email")}
                    placeholder='Email'
                  />
                </div>
                <div className='mb-2'>
                  <p className='label'>Password (at least 6 characters)</p>
                  <PasswordField
                    onChange={(e) => this.handleLoginForm(e, "password")}
                    placeholder='Password'
                  />
                </div>
                {window.location.pathname === '/login' && (
                  <a
                    href={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        passwordReset: true,
                        connectError: null
                      });
                    }}
                    className='text'
                    style={{ float: "right", marginBottom: 10, marginTop: 5, fontWeight: 600 }}
                  >
                    Forgot?
                  </a>
                )}
                {passwordError && (
                  <Alert
                    style={{ marginBottom: 15 }}
                    message={passwordError}
                    type="error"
                  />
                )}
                <Button
                  block
                  htmlType={"submit"}
                  loading={this.state.loading}
                  onClick={(e) => this.handleSubmit(e)}
                  type="primary"
                  style={{ height: "48px", marginBottom: "10px", marginTop: "10px" }}
                >
                  {window.location.pathname === '/signup' ? "I Agree" : "Log In"}
                </Button>
              </form>
            </div>
            <div className="flex-column" style={{ textAlign: "center" }}>
              {this.props.isAssessment && <p className='policy-notice'>By clicking {this.props.isAssessment ? 'I Agree' : 'Log in'}, you agree to our <Link to={'/disclaimer'}>Disclaimer</Link> and <Link to={'/privacy-policy'}>Privacy Policy</Link> In addition, you also agree to receive email messages from us about your experience with our service, and features you can use.</p>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assessmentResults: state.assessment.assessmentResults,
    assessmentProfile: state.assessment.profile,
    isMailSent: state.auth.isMailSent,
  };
};

export default connect(mapStateToProps)(AuthModal);