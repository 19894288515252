import React from 'react';
// import { Tooltip, Button } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
import { FaArrowRight } from "react-icons/fa";
import './index.scss';

export const HomeProgram = ({profile, history}) => {

  const isFixedPlan = profile && profile.user && profile.user.userType === 'fixed';
  const workoutRoute = isFixedPlan ? '/dashboard/workout/workout-plans' : '/dashboard/workout/workouts';

  return(
    <>
      <div className='summary-section mb-4'>
        <div>
          <div className='home-card-title flex flex-center-aligned'>
            <p style={{marginRight: '3px'}}>your program</p>
            {/* <Tooltip title='Your meal plan will be updated as your body changes.'>
              <InfoCircleOutlined/>
            </Tooltip> */}
          </div>
          <div className='half-card-container'>
            <div style={{padding: '0px'}} className='diet-card card-2' onClick={() => history.push('/dashboard/nutrition/meal-plan')}>
              <div className='overlay'>
                <div className='meal-plan-text flex flex-center-aligned'>
                  <p style={{marginRight: '5px'}}>Meal Plan</p>
                  <FaArrowRight />
                </div>
              </div>
            </div>
            <div style={{padding: '0px'}} className='diet-card card-2 workout' onClick={() => history.push(workoutRoute)}>
              <div className='overlay'>
                <div className='meal-plan-text flex flex-center-aligned'>
                  <p style={{marginRight: '5px'}}>{ isFixedPlan ? 'Workout Plan' : 'Workouts' }</p>
                  <FaArrowRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}