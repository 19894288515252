import React from 'react';
import { connect } from 'react-redux';
import BlogPost from '../../components/BlogPost';
import BlogSideBar from '../../components/BlogSideBar';
import { getAllBlogs } from '../../ducks/Blog/actions';
import './index.css';

class BlogPostPage extends React.Component {
  
  componentDidMount(){
    this.props.getAllBlogs();
  }

  render(){
    return (
      <div className="content blog-page">
        <div className="blog-grid">
          <BlogPost {...this.props}/>
        </div>
        <BlogSideBar {...this.props}/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllBlogs: () => dispatch(getAllBlogs())
  }
}

const mapStateToProps = state => {
  return {
    allBlogs: state.blog.allBlogs
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostPage);