import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Divider, Modal } from 'antd';
import './index.css';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import AdminMealPlanTemplate from '../../pages/adminDashboard/mealPlanTemplate';

export const MealPlanCard = ({item, toggleModal, deleteMealPlanTemplate}) => {

  const [visible, setVisible] = useState(false);

  const menu = (id) => (
    <Menu>
      <Menu.Item onClick={() => toggleModal(true, id)}>
        Assign
      </Menu.Item>
      <Menu.Item>
        <Link to={`/dashboard/admin/library/meal-plan/${id}`}>
          Edit
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`/dashboard/admin/library/meal-plan/${id}?mode=create`}>
          Create new from template
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => deleteMealPlanTemplate(id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="meal-plan-card">
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        width={'1050px'}
        footer={false}
      >
       <AdminMealPlanTemplate item={item}/>
      </Modal>
      <div key={item._id}>
        <div className='flex space-between flex-center-aligned'>
          <p className='home-card-text' onClick={() => setVisible(false)}>{moment(item.updatedAt || item.createdAt).format('lll')}</p>
          <Dropdown overlay={menu(item._id)} trigger={['click']} className="pointer">
            <EllipsisOutlined style={{fontSize: '24px'}}/>
          </Dropdown>
        </div>
        <Divider />
        <div className='flex space-between flex-center-aligned'>
          <p className="food-log-card blog-card-category">Length</p>
          <span className='home-card-text'>{ item.mealplanLength } days</span>
        </div>
        <Divider />
        <div className='flex space-between flex-center-aligned'>
          <p className="food-log-card blog-card-category">Diet type</p>
          <p className='home-card-text'>{ item.preference }</p>
        </div>
        <Divider />
        <div className='flex space-between flex-center-aligned'>
          <p className="food-log-card blog-card-category">Meals</p>
          <p className='home-card-text'>{ item.mealLength }</p>
        </div>
        <Divider />
        <div className='flex space-between flex-center-aligned'>
          <p className="food-log-card blog-card-category">Macros</p>
          <div>
            <div className="target-values">                
              <div>
                <p className='home-card-text'>{item.target.calories}g</p>
                <span className='macro-label-bottom'>Kcal</span>                  
              </div>
              <div>
                <p className='home-card-text'>{ item.target.protein }g</p>
                <span className='macro-label-bottom'>Pro</span>                  
              </div>
              <div>
                <p className='home-card-text'>{ item.target.carb }g</p>
                <span className='macro-label-bottom'>Carb</span>                  
              </div>
              <div>
                <p className='home-card-text'>{ item.target.fats }g</p>
                <span className='macro-label-bottom'>Fat</span>                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}