import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Progress, Button } from "antd";
import { AssessmentQuestions } from "./AssessmentQuestions";
import moment from "moment";
import { SET_ASSESSMENT_RESULTS } from "../../ducks/Assessment/constants";
import Lifestyle from "../../assets/images/lifestyle.svg";
import "./index.css";
import questionsObj, { totalQue, Units } from "./questionsObj";
import { saveResultsToGoogleSheet } from "../../ducks/Assessment/actions";
import { assessmentUser, getAssessmentProgressUser, showAuthModal, logOut } from "../../ducks/Auth/actions";
import { clearProfile, saveProfile } from "../../ducks/Profile/actions";
import { AssessmentLoader } from "./AssessmentLoader";
import { signOut } from "../../helpers/auth";
import { clearRecipes } from "../../ducks/Recipe/actions";

class ShortAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 100 / (Object.keys(questionsObj).length - 1),
      responseObj: {
        weightTraningexprience: {
          key: 1,
          text: "Beginner",
          value: "Beginner",
          info: "I've never lifted weights, or just recently started.",
        },
      },
      loadingCalculation: false,
      assessmentQuestions: questionsObj,
      errors: [],
      units: Units.imperial,
      activeQuestion: 1,
      minCaloriesDay: 0,
      confirmModalVisible: false,
      loading: true,
      assessmentSubmitLoader: true,
    };
  }
  componentDidMount(prevProps, prevState) {
    this.props.getAssessmentProgressUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
    if (
      prevProps.assessmentSubmitLoader !== this.props.assessmentSubmitLoader &&
      this.props.assessmentSubmitLoader
    ) {
      this.setState({ loadingCalculation: this.props.assessmentSubmitLoader });
    }
    // }
    // if (!this.props.auth.assessmentProgress) {
    //   this.setState({
    //     loading: false
    //   })
    // }
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user &&
      this.props.auth.user.isAssessmentCompleted
    ) {
      this.props.history.push({
        pathname: "/dashboard/home",
        state: { isAssessment: true },
      });
    }

    if (!this.props.auth.isAuthenticated && !this.props.loading) {
      this.props.history.push({
        pathname: "/",
      });
    }
    if (prevState.activeQuestion !== this.state.activeQuestion) {
      window.scrollTo(0, 0);
    }
    if (
      this.props.auth.assessmentProgress &&
      Object.keys(this.props.auth.assessmentProgress).length &&
      prevProps.responseObj !== this.props.auth.assessmentProgress
    ) {
      this.setState({
        responseObj: {
          ...this.state.responseObj,
          ...this.props.auth.assessmentProgress,
        },
        activeQuestion: this.props.auth.assessmentProgress.assessmentStep,
        progress:
          (100 / (Object.keys(questionsObj).length - 1)) *
          this.props.auth.assessmentProgress.assessmentStep,
        loading: false,
      });
    }
    if (
      this.state.loadingCalculation === false &&
      prevState.loadingCalculation !== this.state.loadingCalculation &&
      this.state.errors.length === 0
    ) {
      // if (this.props.auth && !this.props.auth.isAuthenticated) {
      //   this.props.setAuthVisible(true);
      // }
      // The line below is commented to prevent redirection to the results page when the user completes the form.
      // this.props.history.push("/assessment/results");

      // Dispatch a show modal action
      this.props.history.push({
        // pathname: '/assessment-completed'
        pathname: "/dashboard/home",
      });
      // this.props.showAuthModal({
      //   authRedirectRoute: "/dashboard/home",
      // });
    }
    if (
      this.state.responseObj.foodPreference !==
      prevState.responseObj.foodPreference
    ) {
      //if food preference changes, macros are called
      this.macrosOptions();
    }
  }

  componentWillUnmount() {
    this.setState({ responseObj: {} });
  }
  // MM rework it. You dont need activeQuestion in state, you are using only "key" out of it
  handleProgress = (e, key, type) => {
    let assessmentQuestions = { ...this.state.assessmentQuestions };
    const { activeQuestion } = this.state;
    // also you dont need "e" as first argument in this function as you can decide
    // this part on activeQuestion.key here to calculateResults
    if (activeQuestion === Object.keys(assessmentQuestions).length) {
      // if (e.target.innerText === "Submit") {
      if (this.state.errors.length === 0) {
        this.handleLoading();
        this.calculateResults(this.state.responseObj);
      }
    } else {
      let progress;
      let newActive;
      if (key === "back") {
        newActive = this.state.activeQuestion - 1;
      } else {
        newActive = this.state.activeQuestion + 1;
      }
      this.setState({
        responseObj: { ...this.state.responseObj, assessmentStep: newActive },
      });
      progress = (100 / (Object.keys(questionsObj).length - 1)) * newActive;
      // if (this.state.errors.length === 0 && newActive !== Object.keys(questionsObj).length) {
      if (this.state.errors.length === 0) {
        // this.handleLoading();
        // this.calculateResults(this.state.responseObj);
        this.props.assessmentUser({
          ...this.state.responseObj,
          assessmentStep: newActive,
        });
      }
      this.setState({
        progress,
        activeQuestion: newActive,
      });
    }
  };

  handleChange = (e, type, measure) => {
    let responseObj = { ...this.state.responseObj };
    let assessmentQuestions = { ...this.state.assessmentQuestions };
    let date;
    let value;
    let measureSystem =
      (measure && measure === "kg") || measure === "cm" ? "metric" : "imperial";
    if (type === "heightCm") {
      responseObj.measureForTabs = "metric";
    } else if (type === "heightIn") {
      responseObj.measureForTabs = "imperial";
    }
    responseObj.assessmentStep = this.state.activeQuestion;
    // set default value
    if (!responseObj.Tabs) {
      responseObj.Tabs = {
        key: 2,
        text: "Moderate",
        value: "moderate",
        info: Units.imperial ? "~1lb" : "~.5kg",
        active: true,
      };
    }

    if (type === "birthday") {
      date = moment(e);
      let formattedDate = date.format("MM/DD/YYYY");
      value = { value: formattedDate };
      console.log(formattedDate)
    } else if (type === "Custom") {
      if (!isNaN(+e.target.value)) {
        responseObj.macros.macros[measure] = +e.target.value;
      }
    } else if (type === "byWhen") {
      let date = moment(e).toDate();
      let formattedDate = moment(date).format("MM/DD/YYYY");
      value = { value: formattedDate };
    } else if (e.hasOwnProperty("target") && measure) {
      value = {
        value: e.target.value,
        length: e.target.value.length,
        measure: measureSystem,
      };
    } else if (e.hasOwnProperty("target")) {
      value = { value: e.target.value, length: e.target.value.length };
    } else if (type === "Tabs") {
      assessmentQuestions.goalWeight.component[1].options.forEach(
        (option) => delete option.active
      );
      e.active = true;
      value = e;
      responseObj[type] = value;
      delete responseObj.byWhen;
      this.setState({
        minCaloriesDay: this.calculateResults(responseObj, true),
      });
    } else if (type == "heightFt" || type == "heightIn") {
      value = { value: e, length: e.length };
    } else if (
      type == "riskFollowing" ||
      type == "cardioType" ||
      type == "equipment"
    ) {
      let { selectedOptions = [], otherDescription = null } = e;
      selectedOptions = selectedOptions.filter((v) => v != "");
      if (!selectedOptions.includes("Other")) {
        otherDescription = null;
      }
      value = {
        value: { selectedOptions, otherDescription },
        length: selectedOptions.length,
      };
    } else if (type == "weightTraningexprience") {
      value = e;
    } else if (type == "currentLocation") {
      value = e; // set e to value, here e has object{lat, lng, address}
    } else {
      assessmentQuestions[type].options.forEach(
        (option) => delete option.active
      );
      e.active = true;
      value = e;
    }
    responseObj[type] = value;
    if (type === "birthday") {
      const age = moment().diff(date, "years");
      responseObj.age = {
        value: age,
        length: String(age).length,
      };
    } else if (type === "goalWeight") {
      delete responseObj.byWhen;
      this.setState({
        minCaloriesDay: this.calculateResults(responseObj, true),
      });
    }
    this.setState({
      responseObj,
      assessmentQuestions,
    });
  };

  handleLoading = () => {
    this.setState({ loadingCalculation: true });
    // setTimeout(() => {
    //   this.setState({ loadingCalculation: false });
    // }, 2000);
  };

  macrosOptions = () => {
    let responseObj = { ...this.state.responseObj };
    let assessmentQuestions = { ...this.state.assessmentQuestions };
    if (responseObj.hasOwnProperty("foodPreference")) {
      if (
        responseObj.foodPreference.value === "anything" ||
        responseObj.foodPreference.value === "vegetarian"
      ) {
        assessmentQuestions.macros.options = [
          { text: "Recommended", macros: { protein: 30, carb: 35, fat: 35 } },
          { text: "Low fat", macros: { protein: 30, carb: 50, fat: 20 } },
          { text: "Low carb", macros: { protein: 30, carb: 20, fat: 50 } },
          { text: "Custom", macros: { protein: 25, carb: 25, fat: 50 } },
        ];
      } else if (responseObj.foodPreference.value === "paleo") {
        assessmentQuestions.macros.options = [
          { text: "Recommended", macros: { protein: 30, carb: 35, fat: 35 } },
          { text: "Low carb", macros: { protein: 30, carb: 20, fat: 50 } },
          { text: "Custom", macros: { protein: 25, carb: 25, fat: 50 } },
        ];
      } else if (responseObj.foodPreference.value === "keto") {
        assessmentQuestions.macros.options = [
          { text: "Recommended", macros: { protein: 20, carb: 10, fat: 70 } },
          { text: "Custom", macros: { protein: 25, carb: 25, fat: 50 } },
        ];
      } else if (responseObj.foodPreference.value === "vegan") {
        assessmentQuestions.macros.options = [
          { text: "Recommended", macros: { protein: 30, carb: 50, fat: 20 } },
          { text: "Custom", macros: { protein: 40, carb: 30, fat: 30 } },
        ];
      }
    }
    this.setState({ assessmentQuestions });
  };

  handleMeasureValue = (e) => {
    this.setState({
      units: e.target.value,
    });
  };

  calculateResults = (responseObj, onlyGetCalories) => {
    let nutrition = [];
    ///Only do this if it's the first time calculating
    let cm = responseObj.hasOwnProperty("heightCm")
      ? Number(responseObj.heightCm.value)
      : Number(responseObj.heightFt.value) * 30.48 +
        Number(responseObj.heightIn.value) * 2.54;
    let kg =
      this.state.units === Units.imperial
        ? (Number(responseObj.weight.value) / 2.205).toFixed(2)
        : Number(responseObj.weight.value).toFixed(2);

    let bmr = Math.round(
      responseObj.gender.value === "male"
        ? 10 * Number(kg) + 6.25 * cm - 5 * Number(responseObj.age.value) + 5
        : 10 * Number(kg) + 6.25 * cm - 5 * Number(responseObj.age.value) - 161
    );
    ///

    let maintenanceCal = Math.round(
      bmr * Number(responseObj.activityLevel.value)
    );
    let calculatedGoalCalories = 0;
    let days = 0;

    //when goal is to lose weight or gain muscle
    // if (responseObj.hasOwnProperty("byWhen") || onlyGetCalories) {
    //   if (onlyGetCalories) {
    //     while (calculatedGoalCalories < 1200) {
    //       let currentWeight = Number(responseObj.weight.value);
    //       let goalWeight = Number(responseObj.goalWeight.value);
    //       if (goalWeight && (currentWeight > goalWeight)) {
    //         responseObj.goal = {
    //           goal: "lose",
    //           info: "If you are trying to lose 10 lbs or more.",
    //           key: 2,
    //           text: "Lose weight",
    //           value: "Lose weight"
    //         }
    //       } else if (goalWeight && (currentWeight < goalWeight)) {
    //         responseObj.goal = {
    //           goal: "gain",
    //           info: "Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.",
    //           key: 1,
    //           text: "Build muscle",
    //           value: "Build muscle"
    //         }
    //       }
    //       let goalPoundsOrKg =
    //         responseObj.goal.value === "Lose weight"
    //           ? Number(currentWeight - goalWeight)
    //           : Number(goalWeight - currentWeight);
    //       let goalPoundsOrKgCalories;
    //       switch (responseObj.Tabs.value) {
    //         case 'slow':
    //           goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //             ? goalPoundsOrKg * 1750 //~0.5lb per week
    //             : goalPoundsOrKg * 1925; //~0.25 kg per week
    //           break;
    //         case 'moderate':
    //           goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //             ? goalPoundsOrKg * 3500 //~1 lb per week
    //             : goalPoundsOrKg * 3850; //~0.5 kg per week
    //           break;
    //         case 'aggressive':
    //           goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //             ? goalPoundsOrKg * 7000 //~2lb per week
    //             : goalPoundsOrKg * 7700; //~1kg per week
    //           break;
    //       }
    //       // let goalPoundsOrKgCalories = responseObj.hasOwnProperty("heightCm")
    //       //   ? goalPoundsOrKg * 7000 //making 7000 the calorie needed to gain or lose 1kg
    //       //   : goalPoundsOrKg * 2500; //making 2500 the calorie needed to gain or lose 1lb
    //       if (responseObj.goal.value === "Lose weight") {
    //         let deficitPerDay = Math.round(goalPoundsOrKgCalories / days);
    //         calculatedGoalCalories = maintenanceCal - deficitPerDay;
    //       } else if (responseObj.goal.value === "Build muscle") {
    //         let surplusPerDay = Math.round(goalPoundsOrKgCalories / days);
    //         calculatedGoalCalories = maintenanceCal + surplusPerDay;
    //       }
    //       days++
    //     }
    //     return days
    //   }

    //   if (responseObj.hasOwnProperty("byWhen")) {
    //     let goalDate = responseObj.byWhen.value;
    //     let today = moment(new Date()).toDate();
    //     let formattedToday = moment(today).format("MM/DD/YYYY");
    //     days = Math.abs(
    //       moment(formattedToday, "MM/DD/YYYY")
    //         .startOf("day")
    //         .diff(moment(goalDate, "MM/DD/YYYY").startOf("day"), "days")
    //     );
    //   }

    //   let currentWeight = Number(responseObj.weight.value);
    //   let goalWeight = Number(responseObj.goalWeight.value);
    //   if (goalWeight && (currentWeight > goalWeight)) {
    //     responseObj.goal = {
    //       goal: "lose",
    //       info: "If you are trying to lose 10 lbs or more.",
    //       key: 2,
    //       text: "Lose weight",
    //       value: "Lose weight"
    //     }
    //   } else if (goalWeight && (currentWeight < goalWeight)) {
    //     responseObj.goal = {
    //       goal: "gain",
    //       info: "Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.",
    //       key: 1,
    //       text: "Build muscle",
    //       value: "Build muscle"
    //     }
    //   }
    //   let goalPoundsOrKg =
    //     responseObj.goal.value === "Lose weight"
    //       ? Number(currentWeight - goalWeight)
    //       : Number(goalWeight - currentWeight);
    //   let goalPoundsOrKgCalories;
    //   switch (responseObj.Tabs.value) {
    //     case 'slow':
    //       goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //         ? goalPoundsOrKg * 1750 //~0.5lb per week
    //         : goalPoundsOrKg * 1925; //~0.25 kg per week
    //       break;
    //     case 'moderate':
    //       goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //         ? goalPoundsOrKg * 3500 //~1 lb per week
    //         : goalPoundsOrKg * 3850; //~0.5 kg per week
    //       break;
    //     case 'aggressive':
    //       goalPoundsOrKgCalories = responseObj.measureForTabs === 'imperial'
    //         ? goalPoundsOrKg * 7000 //~2lb per week
    //         : goalPoundsOrKg * 7700; //~1kg per week
    //       break;
    //   }
    //   // let goalPoundsOrKgCalories = responseObj.hasOwnProperty("heightCm")
    //   //   ? goalPoundsOrKg * 7000 //making 7000 the calorie needed to gain or lose 1kg
    //   //   : goalPoundsOrKg * 2500; //making 2500 the calorie needed to gain or lose 1lb
    //   if (responseObj.goal.value === "Lose weight") {
    //     let deficitPerDay = Math.round(goalPoundsOrKgCalories / days);
    //     calculatedGoalCalories = maintenanceCal - deficitPerDay;
    //   } else if (responseObj.goal.value === "Build muscle") {
    //     let surplusPerDay = Math.round(goalPoundsOrKgCalories / days);
    //     calculatedGoalCalories = maintenanceCal + surplusPerDay;
    //   }
    // }

    //When goal is other than losing weight or gaining muscle
    // if (responseObj.goal.value === "Athletic performance") {
    //   let surplusPerDay = maintenanceCal * 0.1; //10% extra from maintenance
    //   calculatedGoalCalories = maintenanceCal + surplusPerDay;
    // } else
    if (responseObj.goal.value === "Lose body fat") {
      let deficitPerDay = maintenanceCal * 0.1;
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (responseObj.goal.value === "Gain muscle") {
      let deficitPerDay = maintenanceCal * 0.1;
      calculatedGoalCalories = maintenanceCal + deficitPerDay;
    } else if (responseObj.goal.value === "Body recomposition") {
      let deficitPerDay = maintenanceCal * 0.1; //10% below maintenance
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (responseObj.goal.value === "Improve overall health") {
      calculatedGoalCalories = maintenanceCal;
    }

    delete responseObj.activityLevel.key;
    let profile = {
      age: responseObj.age.value,
      birthday: responseObj.birthday.value,
      gender: responseObj.gender.value,
      height: cm,
      currentWeight: Number(responseObj.weight.value),
      startWeight: Number(responseObj.weight.value),
      activityLevel: responseObj.activityLevel,
      goal: responseObj.goal,
      foodPreference: responseObj.foodPreference.value,
      // mealsPerDay: responseObj.mealsPerDay.value,
      // workoutLocation: responseObj.workoutLocation.value,
      macros: responseObj.macros.macros,
      isMetric: responseObj.hasOwnProperty("heightCm") ? true : false,
      secondaryGoals: responseObj.secondaryGoals.value,
      explainReachGoals: responseObj.explainReachGoals.value,
      trainingActivity: responseObj.trainingActivity.value,
      supplements: responseObj.supplements.value,
      obstacleReachingFitnessGoals:
        responseObj.obstacleReachingFitnessGoals.value,
      travel: responseObj.travel,
      mealsPrefer: responseObj.mealsPrefer,
      happyEatingFoods: responseObj.happyEatingFoods,
      riskFollowing: responseObj.riskFollowing.value,
      averageStepCount: responseObj.averageStepCount.value,
      doStrengthTraining: responseObj.doStrengthTraining,
      weightTraningexprience: responseObj.weightTraningexprience,
      significantInjuries: responseObj.significantInjuries,
      cardioType: responseObj.cardioType.value,
      equipment: responseObj.equipment.value,
      timeForWorkout: responseObj.timeForWorkout,
      workoutFrequency: responseObj.workoutFrequency,
      currentLocation: responseObj.currentLocation,
      // currentLocation: responseObj.currentLocation.value,
      newPassword: responseObj.newPassword.value,
    };

    // ToDo: make sure all values are converted before saving to DB. All values will be in metric and imperial conversion made on the fly
    if (responseObj.hasOwnProperty("byWhen")) {
      profile.byWhen = responseObj.byWhen.value;
      profile.rate = responseObj.Tabs;
      profile.goalWeight = Number(responseObj.goalWeight.value);
    }

    if (profile.isMetric) {
      profile.height = Math.floor(cm);
    } else {
      profile.heightFt = Number(responseObj.heightFt.value);
      profile.heightIn = Number(responseObj.heightIn.value);
    }

    let nutritionObj = {
      goal: responseObj.goal.goal,
      days: days,
      goalCalories: Math.round(calculatedGoalCalories),
      maintenanceCalories: Math.round(maintenanceCal),
      protein: {
        grams: Math.round(
          (calculatedGoalCalories * (responseObj.macros.macros.protein / 100)) /
            4
        ),
        displayName: "Protein",
      },
      carbs: {
        grams: Math.round(
          (calculatedGoalCalories * (responseObj.macros.macros.carb / 100)) / 4
        ),
        displayName: "Carbohydrates",
      },
      fats: {
        grams: Math.round(
          (calculatedGoalCalories * (responseObj.macros.macros.fat / 100)) / 9
        ),
        displayName: "Fats",
      },
    };
    const totalIntake =
      nutritionObj.carbs.grams +
      nutritionObj.protein.grams +
      nutritionObj.fats.grams;
    nutritionObj.carbs.percentage =
      (nutritionObj.carbs.grams / totalIntake) * 100;
    nutritionObj.protein.percentage =
      (nutritionObj.protein.grams / totalIntake) * 100;
    nutritionObj.fats.percentage =
      (nutritionObj.fats.grams / totalIntake) * 100;
    nutritionObj.totalIntake = totalIntake;
    nutrition.push(nutritionObj);
    this.props.setAssessmentResults(nutrition, this.state.responseObj, profile);
    this.props.saveResultsToGoogleSheet(
      nutrition,
      this.state.responseObj,
      this.state.units
    );
    if (
      this.state.responseObj.assessmentStep === Object.keys(questionsObj).length
    ) {
      this.props.assessmentUser({
        nutrition: nutritionObj,
        profile,
      });
    }
  };

  handleLogout = () => {
    signOut();
    this.props.clearRecipes();
    this.props.clearProfile();
    this.props.logOut();
    this.props.history.push("/");
  };

  renderCalculationCard = () => {
    return (
      <div className="content assessment">
        <div className="start-calculation-card">
          <div className="calculation-card-content">
            <h1 className="title">Personalized assessment</h1>
            <img className="img" src={Lifestyle} />
            <p className="blog-card-subtitle assessment-card-subtitle">
              Not sure where to get started? You came to the right place! Get
              personalized recommendations based on your goal.
            </p>
            <Button
              size="large"
              type="primary"
              // onClick={() => this.setState({ calculationCard: false })}
            >
              Coming soon!
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="content short-assessment">
          {this.state.loading ? (
            <AssessmentLoader />
          ) : (
            <>
              <div className="fixed-progress-bar">
                <div className="back flex flex-center-aligned space-between">
                  <div className="que-num">
                    {this.state.activeQuestion !==
                    Object.keys(questionsObj).length ? (
                      <>
                        Question
                        <b>
                          {String(this.state.activeQuestion).padStart(2, 0)}
                        </b>
                        /{totalQue}
                      </>
                    ) : null}
                  </div>
                  <span className="que-num" onClick={() => this.handleLogout()}>
                    Logout
                  </span>
                </div>
                {this.state.activeQuestion !==
                Object.keys(questionsObj).length ? (
                  <Progress
                  strokeColor={{
                    '0%': '#2761f1', 
                    '100%': '#FF0066',
                  }}
                    percent={this.state.progress}
                    showInfo={false}
                  />
                ) : null}
              </div>
              {this.state.assessmentQuestions && (
                <AssessmentQuestions
                  {...this.props}
                  {...this.state}
                  activeQuestion={Object.values(
                    this.state.assessmentQuestions
                  ).find((val) => val.key === this.state.activeQuestion)}
                  handleMeasureValue={this.handleMeasureValue}
                  handleProgress={this.handleProgress}
                  handleChange={this.handleChange}
                  calculateResults={this.calculateResults}
                  handleLoading={this.handleLoading}
                />
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    auth: state.auth,
    responseObj: state.auth.assessmentProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (responseObj) => {
      dispatch(saveProfile(responseObj));
    },
    setAssessmentResults: (nutrition, responseObj, profile) => {
      return dispatch({
        type: SET_ASSESSMENT_RESULTS,
        assessmentResults: nutrition,
        responseObj,
        profile,
      });
    },
    saveResultsToGoogleSheet: (nutrition, responseObj, unit) =>
      dispatch(saveResultsToGoogleSheet(nutrition, responseObj, unit)),
    showAuthModal: (payload) => dispatch(showAuthModal(payload)),
    assessmentUser: (formData, cb) => dispatch(assessmentUser(formData, cb)),
    getAssessmentProgressUser: () => dispatch(getAssessmentProgressUser()),
    logOut: () => dispatch(logOut()),
    clearProfile: () => dispatch(clearProfile()),
    clearRecipes: () => dispatch(clearRecipes()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShortAssessment));