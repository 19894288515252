import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';
import { MacroCard } from '../Home/MacroCard';
import AdminClientProgram from '../../pages/adminDashboard/clientProgram';
import ProfileForm from '../../components/ProfileForm';
import AdminClientProgress from './Progress';
import AdminClientPreferences from '../../presentational/AdminClientsPreferences';
import AdminClientWorkouts from '../../pages/adminDashboard/clientWorkouts';

class AdminClientsProfilePage extends React.Component {
  constructor(){
    super();
    this.state={
      nutritionCardData: []
    }
  }

  componentDidMount(){
    let cardNutrition = this.props.allUserNutrition && this.props.allUserNutrition[0];
    if(cardNutrition){
      let nutritionCardData = [
        {key: 1, value: cardNutrition.goalCalories}, 
        {key: 2, value: cardNutrition.protein},
        {key: 3, value: cardNutrition.carbs}, 
        {key: 4, value: cardNutrition.fats}
      ];
      this.setState({ nutritionCardData: nutritionCardData });
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.allUserNutrition && this.props.allUserNutrition !== nextProps.allUserNutrition){
      let cardNutrition = nextProps.allUserNutrition && nextProps.allUserNutrition[0];
      let nutritionCardData = [
        {key: 1, value: cardNutrition.goalCalories}, 
        {key: 2, value: cardNutrition.protein},
        {key: 3, value: cardNutrition.carbs}, 
        {key: 4, value: cardNutrition.fats}
      ];
      this.setState({ nutritionCardData: nutritionCardData });
    }
  }

  renderSelectedTab = () => {
    if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/macros`){
      return (
        <>
          <div style={{marginTop: '30px'}}>
            <p className='home-card-title'>Macronutrients</p>
            <div className='global-card macros flex space-around'>
              {this.state.nutritionCardData.map(data => <MacroCard {...this.props} key={data.key} data={data} />)}
            </div>
          </div>
          {this.props.clientProfile &&
          <div className='site-layout-background'>
            <ProfileForm 
              profile={this.props.clientProfile} 
              isAdminUpdate={true}
            />
          </div>}
        </>
      )
    } else if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/program`) {
      return <AdminClientProgram {...this.props} />
    } else if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/workouts`){
      return <AdminClientWorkouts {...this.props}/>
    } else if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/workouts/${this.props.match.params.workoutId}`){
      return <AdminClientWorkouts {...this.props}/>
    } else if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/preferences`){
      return <AdminClientPreferences {...this.props}/>
    } else if(this.props.history.location.pathname === `/dashboard/admin/clients/${this.props.match.params.id}/profile/progress`) {
      return <AdminClientProgress {...this.props}/>
    } 
  }

  render(){
    return(
      this.renderSelectedTab()
    )
  }
};

export default withRouter(AdminClientsProfilePage);