import { Layout } from "antd";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/Routing";
import AdminRoute from "./components/Routing/AdminRoute";
import SubMenu from "./components/ProfileMenu";
import NotFound from "./components/NotFoundPage";
import Blog from "./presentational/Blog";
import BlogPostPage from "./presentational/BlogPostPage";
// import HomePage from "./presentational/HomePage";
import Login from './pages/dashboard/login';
import AssessmentCompleted from './pages/dashboard/assessmentCompleted';
import "react-toastify/dist/ReactToastify.css";
import { LandingPage } from './presentational/LandingPage';
// import Programs from './presentational/Programs';
import ShortAssessment from "./presentational/ShortAssessment";
import AssessmentResults from "./presentational/ShortAssessment/AssessmentResults";
import Profile from "./pages/dashboard/profile";
import MacroHistory from "./pages/dashboard/macroHistory";
import UserProfile from "./pages/dashboard/userProfile";
import MealPlan from "./pages/dashboard/customMealPlan";
import SuggesticMealPlan from "./pages/dashboard/suggesticMealPlan";
import SuggesticShopping from "./pages/dashboard/suggesticShopping";
// import Nutrition from "./pages/dashboard/nutrition";
// import Diet from "./pages/dashboard/diet";
// import Methods from "./pages/dashboard/eatingMethod";
// import Exercise from "./pages/dashboard/workout";
// import Lifestyle from "./pages/dashboard/lifestyle";
// import Workout from "./pages/dashboard/workout";
import Recipes from "./pages/dashboard/recipes";
import Home from "./pages/dashboard/home";
// import Shopping from "./pages/dashboard/shopping";
import Settings from "./pages/dashboard/settings";
import Stats from "./pages/dashboard/stat";
import Learn from "./pages/dashboard/learn";
import Programs from "./pages/dashboard/program";
import TitleSection from "./components/TitleSection";
import FoodLogHistory from "./pages/dashboard/foodLogHistory";
import NutritionRestrictions from "./pages/dashboard/nutritionRestrictions";
import CustomShopping from "./pages/dashboard/customShopping";
import CustomFoodPreferences from './pages/dashboard/customFoodPreferences';
import { ToastContainer } from "react-toastify";
import UserWorkouts from "./pages/dashboard/userWorkouts";
import UserWorkoutPrograms from './pages/dashboard/userWorkoutPrograms';
import UserWorkoutPlans from './pages/dashboard/userWorkoutPlans';
//Admin Routes
import AdminClients from "./pages/adminDashboard/clients";
// import MeasurementForm from "./presentational/MeasurementForm";
import AdminFoodLog from "./pages/adminDashboard/foodLog";
import AdminCreateMealPlan from "./pages/adminDashboard/createMealplan";
import AdminMealPlanLibrary from "./pages/adminDashboard/mealPlanLibrary";
import AdminMealPlanTemplate from "./pages/adminDashboard/mealPlanTemplate";
import AdminWorkoutLibrary from "./pages/adminDashboard/workoutLibrary";
import AdminClientProfile from "./pages/adminDashboard/clientProfile";
import AdminWorkoutProgramLibrary from "./pages/adminDashboard/workoutProgramLibrary";
import AdminWorkoutPlanLibrary from "./pages/adminDashboard/workoutPlanLibrary";
// import AdminClientProgram from "./pages/adminDashboard/clientProgram";
import ActionFooter from "./components/ActionFooter";
import Verification from "./pages/dashboard/verification";
import VerifyEmailMessage from "./pages/dashboard/verifyEmailMessage";
// import AssessmentRoute from "./components/Routing/AssessmentRoute";
// import Questions from "./pages/dashboard/assessmentProfile";
import AssessmentProfile from "./pages/dashboard/assessmentProfile";
import Exercises from "./pages/adminDashboard/exercises";
import { SharedWorkout } from "./components/SharableComponents/sharedWorkout";
import { ProgramRecommendation } from "./pages/landing/programRecommendation";
import ProgramResultsPage from "./presentational/ProgramResultsPage";
import { AchievementModal } from './components/AchievementModal/index';
import { UserExerciseHistory } from "./pages/dashboard/UserExerciseHistory";
import { OneRepMaxTool } from './pages/dashboard/oneRepMaxTool';
import { CarbCyclingTool } from './pages/dashboard/carbCyclingTool';

const { Content } = Layout;

export default class IndexRoute extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            path="/achievement-modal"
            exact
            component={AchievementModal}
          />
          <Route
            path="/"
            exact
            component={LandingPage}
          />
          <Route
            path="/program-recommendation"
            exact
            component={ProgramRecommendation}
          />
          <Route
            path="/program-recommendation/results"
            exact
            component={ProgramResultsPage}
          />
          <Route
            path="/userVerification/:regToken"
            exact
            component={Verification}
          />
          <Route
            path="/verify-email"
            exact
            component={VerifyEmailMessage}
          />
          <Route
            path="/login"
            exact
            render={(props) => (
              <Login {...props} />
            )}
          />
          <Route
            path="/assessment"
            exact
            render={(props) => <ShortAssessment {...props} />}
          />
          <Route
            path="/assessment-completed"
            exact
            render={(props) => (
              <AssessmentCompleted {...props} />
            )}
          />
          <Route
            path="/assessment/results"
            render={(props) => <AssessmentResults {...props} />}
          />
          <Route path="/programs" exact component={Programs} />
          <Route path="/blog" exact component={Blog} />
          <Route
            path="/blog/:slug"
            render={(props) => <BlogPostPage {...props} />}
          />
          <Route
            exact
            path="/share/my-workout/:token"
            component={SharedWorkout}
          />
          <Route path="/dashboard">
            <Layout className="site-layout">
              <Content className="dashboard-content">
                <ToastContainer />
                <TitleSection {...this.props} />
                <SubMenu />
                <Switch>
                  <PrivateRoute path="/dashboard/home" component={Home} />
                  <PrivateRoute
                    path="/dashboard/settings/account"
                    component={UserProfile}
                  />
                  <PrivateRoute
                    path="/dashboard/tools/macro-calculator"
                    component={Profile}
                  />
                   <PrivateRoute
                    path="/dashboard/tools/one-rep-max"
                    component={OneRepMaxTool}
                  />
                  <PrivateRoute
                    path="/dashboard/tools/carb-cycling"
                    component={CarbCyclingTool}
                  />
                  <PrivateRoute
                    path="/dashboard/tools/macro-history"
                    component={MacroHistory}
                  />
                  <PrivateRoute
                    path="/dashboard/tools/compliance"
                    component={FoodLogHistory}
                  />
                  <PrivateRoute
                    path="/dashboard/coach/meal-plan"
                    component={SuggesticMealPlan}
                    exact
                  />
                  <PrivateRoute
                    path="/dashboard/coach/food-preferences"
                    component={NutritionRestrictions}
                  />
                  <PrivateRoute
                    path="/dashboard/coach/shopping-list"
                    component={SuggesticShopping}
                  />
                  <PrivateRoute
                    path="/dashboard/nutrition/meal-plan"
                    component={MealPlan}
                    exact
                  />
                   <PrivateRoute
                    path="/dashboard/workout/workout-program"
                    component={UserWorkoutPrograms}
                    exact
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/workout/workouts/:workoutId?"
                    component={UserWorkouts}
                  />
                  <PrivateRoute
                    path="/dashboard/workout/workout-plans"
                    component={UserWorkoutPlans}
                    exact
                  />
                  <PrivateRoute
                    path="/dashboard/nutrition/food-preferences"
                    component={CustomFoodPreferences}
                  />
                  <PrivateRoute
                    path="/dashboard/nutrition/shopping-list"
                    component={CustomShopping}
                  />
                  {/* <PrivateRoute
                    path="/dashboard/program"
                    component={Programs}
                  />  */}
                  {/* <PrivateRoute
                    path="/dashboard/guides/macronutrients"
                    component={Nutrition}
                  /> */}
                  {/* <PrivateRoute
                    path="/dashboard/guides/diet"
                    component={Diet}
                  />
                  <PrivateRoute
                    path="/dashboard/guides/eating-methods"
                    component={Methods}
                  />
                  <PrivateRoute
                    path="/dashboard/profile/exercise"
                    component={Exercise}
                  /> */}
                  {/* <PrivateRoute
                    path="/dashboard/lifestyle"
                    component={Lifestyle}
                  /> */}
                  {/* <PrivateRoute
                    path="/dashboard/workouts"
                    component={Workout}
                  /> */}
                  <PrivateRoute
                    path="/dashboard/learn/nutrition"
                    component={Learn}
                  />
                  <PrivateRoute 
                    path="/dashboard/stats/progress" 
                    component={Stats}  
                    exact
                  />
                  <PrivateRoute 
                    path="/dashboard/stats/exercise-history" 
                    component={UserExerciseHistory}  
                    exact
                  />
                  <PrivateRoute
                    path="/dashboard/nutrition/recipes"
                    component={Recipes}
                    exact
                  />
                  {/* <PrivateRoute
                    path="/dashboard/nutrition/shopping-list"
                    component={Shopping}
                  /> */}
                  <Route
                    path="/dashboard/settings"
                    component={Settings}
                  />
                  <PrivateRoute
                    path="/dashboard/profile"
                    component={AssessmentProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients"
                    component={AdminClients}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/food-log"
                    component={AdminFoodLog}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/meal-plan"
                    component={AdminCreateMealPlan}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/edit-meal-plan"
                    component={MealPlan}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/meal-plan"
                    component={AdminMealPlanLibrary}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/meal-plan/:id"
                    component={AdminMealPlanTemplate}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/workout"
                    component={AdminWorkoutLibrary}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/profile/macros"
                    component={AdminClientProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/profile/program"
                    component={AdminClientProfile}
                  />
                   <AdminRoute
                   exact
                    path="/dashboard/admin/clients/:id/profile/workouts"
                    component={AdminClientProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/profile/workouts/:workoutId"
                    component={AdminClientProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/profile/progress"
                    component={AdminClientProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/clients/:id/profile/preferences"
                    component={AdminClientProfile}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/workouts"
                    component={AdminWorkoutLibrary}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/exercises"
                    component={Exercises}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/workout-program"
                    component={AdminWorkoutProgramLibrary}
                  />
                  <AdminRoute
                    exact
                    path="/dashboard/admin/library/workout-plans"
                    component={AdminWorkoutPlanLibrary}
                  />
                  <Route component={NotFound} />
                </Switch>
              </Content>
              <ActionFooter />
            </Layout>
          </Route>
          <Route component={NotFound} />
        </Switch>
      </>
    );
  }
}