import React from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components/Loading'
import { TOGGLE_WORKOUT_PROGRAM_MODAL, TOGGLE_CREATE_WORKOUT_MODAL } from '../../ducks/Global/constants';
import { getWorkoutProgramLibrary, updateWorkoutProgram, createWorkoutProgram, createUserWorkoutProgram} from '../../ducks/WorkoutProgram/actions';
import { getLibraryWorkouts } from '../../ducks/Workout/actions';
import AdminWorkoutProgramLibraryPage from '../../presentational/AdminWorkoutProgramLibraryPage';
import { getAllOnsiteClients } from '../../ducks/Profile/actions';

class AdminWorkoutProgramLibrary extends React.Component {

  componentDidMount(){
    this.props.workoutPrograms.length === 0 && this.props.getWorkoutProgramLibrary();
    this.props.workouts.length === 0 && this.props.getLibraryWorkouts();
  }

  // componentDidUpdate(prevProps){
  //   if(prevProps.workoutPrograms !== this.props.workoutPrograms){
  //     this.props.getWorkoutProgramLibrary();
  //   }
  // }

  render(){
    return(
     <div className='workouts-page'>
      {this.props.workoutPrograms.length > 0 ? <AdminWorkoutProgramLibraryPage {...this.props} /> : <Loading />}
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    workoutPrograms: state.workoutProgram.workoutPrograms,
    workouts: state.workouts.workouts,
    workoutProgramModalVisible: state.global.workoutProgramModalVisible,
    createWorkoutModalVisible: state.global.createWorkoutModalVisible,
    workoutProgramsLoading: state.workoutProgram.workoutProgramsLoading,
    workoutProgramsError: state.workoutProgram.workoutProgramsError,
    createWorkoutProgramSuccess: state.workoutProgram.createWorkoutProgramSuccess,
    clients: state.profile.onsiteClients,
    clientsLoading: state.profile.loadingOnsiteClients,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getWorkoutProgramLibrary: () => dispatch(getWorkoutProgramLibrary()),
    getLibraryWorkouts: () => dispatch(getLibraryWorkouts()),
    updateWorkoutProgram: (workoutProgram) => dispatch(updateWorkoutProgram(workoutProgram)),
    toggleWorkoutProgramModal: (value) => dispatch({
      type: TOGGLE_WORKOUT_PROGRAM_MODAL,
      value
    }),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    createWorkoutProgram: (data) => dispatch(createWorkoutProgram(data)),
    createUserWorkoutProgram: (client, workoutProgram, notes) => dispatch(createUserWorkoutProgram(client, workoutProgram, notes)),
    getAllOnsiteClients: () => dispatch(getAllOnsiteClients()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminWorkoutProgramLibrary);