import React from 'react';
import { connect } from 'react-redux';
import {Input, Button, Alert, DatePicker} from 'antd';
import { poundsToKgs, kgsToPounds} from '../../helpers/utils';
import { saveBodyFat, clearBodyFatErrors } from '../../ducks/Measurements/actions';
import { CLOSE_DRAWER } from '../../ducks/Global/constants';
import ProgressUpload from '../../components/ProgressUpload/index';
import { getNutrition } from '../../ducks/Profile/actions';
import './index.css';
import moment from 'moment';
import { getTodo } from '../../ducks/Todo/actions';
import { toast } from 'react-toastify';

const dateFormat = 'MM/DD/YYYY';

class ManualForm extends React.Component {
  constructor(){
    super();
    this.state={
      bodyFatObj: {},
      progressPictures: []
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userBodyFatMeasurements &&
      prevProps.userBodyFatMeasurements &&
      this.props.userBodyFatMeasurements.length !== prevProps.userBodyFatMeasurements.length
    ) {
      this.props.getNutrition();
      this.props.closeDrawer();
      this.setState({ bodyFatObj: {} });
      toast.success('Measurement saved successfully!')
    }
  }

  //add lean body mass to manualMeasurement
  addLeanBodyMassAndWeight = () => {
    const { data } = this.props;
    let bodyFatObj = {...this.state.bodyFatObj};

    if(bodyFatObj.bfPercentage === "" || bodyFatObj.bfPercentage === "e"){
      //checks if body fat is empty or e. If it's empty, weight and lean body mass save the same values and bf saves as 0 to match withings
      bodyFatObj.bfPercentage = 0;
      bodyFatObj.leanBodyMass = {kg: data.isMetric ? bodyFatObj.weight : poundsToKgs(bodyFatObj.weight), lb: !data.isMetric ? bodyFatObj.weight : kgsToPounds(bodyFatObj.weight)}
    } else {
      let leanBodyMass = (bodyFatObj.weight - ((bodyFatObj.bfPercentage / 100) * bodyFatObj.weight)).toFixed(2);
      bodyFatObj.leanBodyMass = data.isMetric ? {kg: leanBodyMass, lb: kgsToPounds(leanBodyMass)} : {kg: poundsToKgs(leanBodyMass), lb: leanBodyMass};
    }
    bodyFatObj.weight = {kg: data.isMetric ? bodyFatObj.weight : poundsToKgs(bodyFatObj.weight), lb: !data.isMetric ? bodyFatObj.weight : kgsToPounds(bodyFatObj.weight)}

    this.setState({bodyFatObj: bodyFatObj});

    
    this.props.saveBodyFat({bodyFatObj, progressPictures: this.state.progressPictures});
    // this.props.getTodo(); // get todo after submission
    this.setState({progressPictures: []})
  }

  handleInputChange = (value, key) => {
    let bodyFatObj = {...this.state.bodyFatObj};
    bodyFatObj.userId = this.props.data.user._id;
    bodyFatObj[key] = value;
    bodyFatObj.bfPercentage = "";
    bodyFatObj.source = this.props.user.role === 'admin' ? 'Coach' : 'Manual Measurement';
    bodyFatObj.type = 'Manual';
    bodyFatObj.pictures = [];
    bodyFatObj.measurements = {};
    bodyFatObj[key] = value;
    this.setState({bodyFatObj: bodyFatObj});
  }

  handleImageInputChange = (value) => {
    this.setState({
      progressPictures: value
    });
  }

  onDateChange = (date, dateString) => {
    let bodyFatObj = {...this.state.bodyFatObj};
    bodyFatObj.date = new Date(date);
    this.setState({bodyFatObj: bodyFatObj});
  };

  render(){
    const { bodyFatObj, progressPictures } = this.state;
    const { data, user, saveBodyFatError, saveBodyFatLoading } = this.props;

    return(
      <div className='measurement-form-container'>
        <div className='measurement-form'>
          <div className='form body-fat-form'>
            <p className='label'>Weight*</p>
            <Input 
              suffix={data.isMetric ? 'KG' : 'LB'}
              onChange={(e) => this.handleInputChange(e.target.value, 'weight')}
              value={bodyFatObj['weight']}
              type='number'
            />
          </div>
          <div className='form body-fat-form'>
            <p className='label'>Body Fat</p>
            <Input 
              suffix={'%'}
              onChange={(e) => this.handleInputChange(e.target.value, 'bfPercentage')}
              value={bodyFatObj['bfPercentage']}
              type='number'
            />
          </div>
          <div className='uploader'>
            <ProgressUpload handler = {this.handleImageInputChange} location = "manual" photos = {progressPictures} />
          </div>
          {
            user
            && user.role === 'admin'
            && <div className='form body-fat-form'>
              <p className='label'>Date Taken</p>
              <DatePicker
                defaultValue={moment()}
                format={dateFormat}
                onChange={this.onDateChange}
              />
            </div>
          }
          <Button 
            onClick={() => this.addLeanBodyMassAndWeight()} 
            type="primary"
            loading={saveBodyFatLoading}
            disabled={!bodyFatObj.weight}
          >
            Save
          </Button>
          {(saveBodyFatError && saveBodyFatError.type === 'Manual') && (
            <Alert
              message={
                saveBodyFatError.errors.length > 0 &&
                Object.values(saveBodyFatError.errors).map((err) => (
                  <p>{err.msg}</p>
                ))
              }
              type="error"
            />
          )}
        </div>
      </div>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveBodyFat: (obj) => dispatch(saveBodyFat(obj)),
    clearBodyFatErrors: () => dispatch(clearBodyFatErrors()),
    getNutrition: () => dispatch(getNutrition()),
    closeDrawer: () => {
			return dispatch({
					type: CLOSE_DRAWER
			 });
		},
    getTodo: () => dispatch(getTodo()),
  }
}

const mapStateToProps = (state) => {
  return {
    bodyFatError: state.measurements.bodyFatError,
    saveBodyFatLoading: state.measurements.saveBodyFatLoading,
    userBodyFatMeasurements: state.measurements.userBodyFatMeasurements,
    saveBodyFatError: state.measurements.saveBodyFatError
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);