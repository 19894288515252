import { put, takeLatest, call } from "redux-saga/effects";
import { CREATE_USER_SETTING, GET_USER_SETTINGS } from "./constants";
import {
  createUserSettingSuccess,
  createUserSettingError,
  getUserSettingsSuccess,
  getUserSettingsError
} from "./actions";
import { fetchData } from "../api";

export function* createUserSetting({setting}){
    const url = `${process.env.REACT_APP_API_URL}/api/setting`;
    const config = {     
      method: "POST",
      headers: {
        "x-auth-token": window.localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({name: setting.name, code: setting.code, active: setting.active, showNotificationPrompt:setting.showNotificationPrompt})
    };
  try {
    let payload = yield call(fetchData, url, config);
    yield put(createUserSettingSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(createUserSettingError(error));
  }
}

export function* getUserSettings() {
  const url = `${process.env.REACT_APP_API_URL}/api/setting/me`;
  try {
    const config = {
      headers: {
        "x-auth-token": window.localStorage.token
      },
    };
    let payload = yield call(fetchData, url, config);
    yield put(getUserSettingsSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getUserSettingsError(error));
  }
}

export default function* settingSaga() {
  yield takeLatest(CREATE_USER_SETTING, createUserSetting);
  yield takeLatest(GET_USER_SETTINGS, getUserSettings);
}