import {
  GET_GUIDES,
  GET_GUIDES_SUCCESS,
  GET_GUIDES_ERROR,
  UPDATE_GUIDES,
  UPDATE_GUIDES_SUCCESS,
  UPDATE_GUIDES_ERROR
} from "./constants";

const initialState = {
  guides: null,
  guidesLoading: false,
  guidesError: null,
};

function guides(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_GUIDES:
      return {
        ...state,
        guidesError: false,
        guidesLoading: true
      };
    case GET_GUIDES_SUCCESS:
      return {
        ...state,
        guides: payload,
        guidesLoading: false
      };
    case GET_GUIDES_ERROR:
      return {
        ...state,
        guidesLoading: false,
        guidesError: action.err
      };
    case UPDATE_GUIDES:
      return {
        ...state,
        guidesLoading: true,
        guidesError: false
      };
    case UPDATE_GUIDES_SUCCESS:
      return {
        ...state,
        guides: payload,
        guidesError: false
      };
    case UPDATE_GUIDES_ERROR:
      return {
        ...state,
        guidesLoading: false,
        guidesError: action.err
      };
    default:
      return state;
  }
}

export default guides;