import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import NubodWhite from '../../assets/logos/Nubod-cropped.png';
import {scrollToTop} from "../../ducks/Global/actions";
import { HANDLE_LEGAL_MODAL } from '../../ducks/Global/constants';
import { Legal } from '../../components/LegalModal';
import { legal } from '../../data/legal';
import './index.css';
const { Footer } = Layout;

class PageFooter extends React.Component {

  renderFooter = () => {
    if(
      window.location.pathname !== '/assessment'
      && window.location.pathname !== '/signup'
      && window.location.pathname !== '/assessment/results'
      && !window.location.pathname.split('/').includes('dashboard')
      && window.location.pathname !== '/contact'
      && window.location.pathname !== '/blog'
      && window.location.pathname !== '/verify-email'
      && !window.location.pathname.split('/').includes('userVerification')
      && !window.location.pathname.includes('share')
    ){
      return(
        <>
          <Legal 
            data={legal[this.props.legalPage]}
            isLegalModalVisible={this.props.isLegalModalVisible} 
            handleLegalModal={this.props.handleLegalModal}
          />
          <Footer className='footer'>
            <div className='footer-content'>
              <div className='footer-links'>
                <img
                  onClick={() => this.props.scrollToTop()}
                  src={NubodWhite}
                  className="alvaro-fitness-logo footer-logo"
                  alt={"Nubod"}
                />
                <div className='links-footer'>
                  <div className='footer-legal'>
                    <p className='link' onClick={() => this.props.handleLegalModal(true, 'privacyPolicy')}>Privacy policy</p>
                    <p className='separator'>|</p>
                    <p className='link' onClick={() => this.props.handleLegalModal(true, 'disclaimer')}>Disclaimer</p>
                  </div>
                </div>
              </div>
              <div className='footer-bottom'>
                <p className='link' style={{marginTop: '0px'}}>© Nubod. All rights reserved</p>
              </div>
            </div>
          </Footer>
        </>
      )
    }
    return null;
  }

  render(){
    return(
     this.renderFooter()
    )
  }
}

const mapStateToProps = state => {
  return { 
    isLegalModalVisible: state.global.isLegalModalVisible,
    legalPage: state.global.legalPage
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    scrollToTop: () => dispatch(scrollToTop()),
    handleLegalModal: (visible, legalPage) => {
      return dispatch({
        type: HANDLE_LEGAL_MODAL,
        visible,
        legalPage
      });
		},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageFooter));