import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt} from 'react-router-dom';
import { getUserWorkoutPlans } from '../../ducks/WorkoutPlan/actions';
import UserWorkoutPlansPage from '../../presentational/UserWorkoutPlansPage';
import { createUserGeneratedWorkout, getUserWorkouts } from '../../ducks/Workout/actions';
import { handleAchievementModal } from '../../ducks/Global/actions';
import { Loading } from '../../components/Loading';
import FirstWorkoutImg from '../../assets/images/crown.svg';
import { TOGGLE_WORKOUT_PROGRAM_MODAL, TOGGLE_CREATE_WORKOUT_MODAL, TOGGLE_EXERCISE_HISTORY_MODAL, TOGGLE_CONFIRM_MODAL, TOGGLE_WORKOUT_TIMER_RUNNING, SET_WORKOUT_IN_PROGRESS, RESET_TIMER } from '../../ducks/Global/constants';

class UserWorkoutPlans extends React.Component {
  constructor() {
    super();
    this.state = {
      currentWorkoutProgram: {},
      currentWorkout: {exercises: []},
      currentExercise: {},
      isStopWorkout: false,
      timerValue: 0
    };
  }

  componentDidMount(){
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    !this.props.userWorkoutPlans.length && this.props.getUserWorkoutPlans(this.props.user._id);
    //temporary solution due to userWorkouts being an object when trying to create a user generated workout. Remove and refactor when possible to avoid unnecessary calls. (Also in workout program page)
    !this.props.userWorkouts.length && this.props.getUserWorkouts(this.props.user._id);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidUpdate(prevProps){
    const achievementProps = {
      isOpen: true,
      img: FirstWorkoutImg,
      title : 'Congrats!',
      subtitle: 'You have completed your first workout! This is just the beginning of a rewarding journey. All the future workouts you complete will appear on the workouts tab.',
      actionText: 'Go to workouts completed',
      path: '/dashboard/workout/workouts'
    }
    if(prevProps.userWorkouts.length === 0 && this.props.userWorkouts.length === 1){
      this.props.handleAchievementModal(achievementProps)
    }
  }

  setCurrentWorkoutProgram = (workoutProgram) => {
    this.setState({ currentWorkoutProgram: workoutProgram });
  }

  setCurrentWorkout = (workout) => {
    this.setState({ currentWorkout: workout });
  }

  showWorkoutPreview = (workout) => {
    this.setState({ currentWorkout: workout });
  }

  setExercise = (exercise) => {
    this.setState({currentExercise: exercise})
  }

  updateTimerValue = (value) => {
    this.setState({ timerValue: value });
  };

  handleConfirmModalAction = () => {
    let isEndWorkout = ((this.props.timerRunning && this.state.isStopWorkout) || this.state.isStopWorkout);
    if (!isEndWorkout) {
      this.props.toggleWorkoutTimerRunning(true);
      this.props.setWorkoutInProgress(true);
      this.props.toggleConfirmModal(false);
    } else if(isEndWorkout){
      this.props.toggleWorkoutTimerRunning(false);
      this.props.toggleConfirmModal(false);
      this.props.setWorkoutInProgress(false);
      this.props.toggleWorkoutProgramModal(false);
      // this.props.createUserGeneratedWorkout({ 
      //   ...this.props.userGeneratedWorkout,
      //   duration: this.state.timerValue 
      // });
      this.props.createUserGeneratedWorkout(this.props.userGeneratedWorkout);
      this.props.resetTimer(true);
    }
  }

  stopWorkout = (isStop) => {
    this.setState({isStopWorkout: isStop})
  }

  abortWorkout = () => {
    this.props.toggleWorkoutTimerRunning(false);
    this.props.setWorkoutInProgress(false);
    this.props.toggleConfirmModal(false);
    this.props.toggleCreateWorkoutModal(false);
    this.props.resetTimer(true);
    this.setState({isStopWorkout: false, currentWorkout: {exercises: []}})
  }

  handleBeforeUnload = (event) => {
    const workoutInProgress = this.props.workoutInProgress; //condition that checks if there is unsaved data

    if (workoutInProgress) {
      event.preventDefault();
      event.returnValue = 'Your workout is in progress. If you leave, your changes will be lost.';
    }
  };

  render(){
    const { workoutInProgress } = this.props;
    return(
     <div className='workouts-page'>
      <Prompt
        when={workoutInProgress}
        message='Your workout is in progress. If you leave, your changes will be lost.'
      />
        {this.props.workoutPlansLoading ? (
        <Loading />
      ) : this.props.userWorkoutPlans.length > 0 ? (
        <UserWorkoutPlansPage 
          {...this.props} 
          {...this.state} 
          setCurrentWorkoutProgram={this.setCurrentWorkoutProgram}
          setCurrentWorkout={this.setCurrentWorkout}
          showWorkoutPreview={this.showWorkoutPreview}
          setExercise={this.setExercise}
          updateTimerValue={this.updateTimerValue}
          handleConfirmModalAction={this.handleConfirmModalAction}
          abortWorkout={this.abortWorkout}
          resetTimer={this.props.resetTimer}
          stopWorkout={this.stopWorkout}
          shouldResetTimer={this.props.shouldResetTimer}
        />
      ) : (
        <div style={{ backgroundColor: '#e4e7eb', borderRadius: '10px' }}>
          <p className='home-card-text' style={{ padding: '22px' }}>
            Your workout plan will be here when ready.
          </p>
        </div>
      )}
     </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userWorkoutPlans: state.workoutPlan.userWorkoutPlans,
    userWorkouts: state.workouts.userWorkouts,
    exerciseHistoryModalVisible: state.global.exerciseHistoryModalVisible,
    workoutPlansLoading: state.workoutPlan.workoutPlansLoading,
    workoutPlansError: state.workoutPlan.workoutPlansError,
    workoutProgramModalVisible: state.global.workoutProgramModalVisible,
    createWorkoutModalVisible: state.global.createWorkoutModalVisible,
    confirmModalVisible: state.global.confirmModalVisible,
    timerRunning: state.global.timerRunning,
    workoutInProgress: state.global.workoutInProgress,
    shouldResetTimer: state.global.shouldResetTimer,
    userGeneratedWorkout: state.global.userGeneratedWorkout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserWorkoutPlans: (clientId) => dispatch(getUserWorkoutPlans(clientId)),
    getUserWorkouts: (clientId, currentWeek, averageMonth) => dispatch(getUserWorkouts(clientId, currentWeek, averageMonth)),
    createUserGeneratedWorkout: (data) => dispatch(createUserGeneratedWorkout(data)),
    toggleWorkoutProgramModal: (value) => dispatch({
      type: TOGGLE_WORKOUT_PROGRAM_MODAL,
      value
    }),
    toggleCreateWorkoutModal: (value) => dispatch({
      type: TOGGLE_CREATE_WORKOUT_MODAL,
      value
    }),
    toggleExerciseHistoryModal: (value) => dispatch({
      type: TOGGLE_EXERCISE_HISTORY_MODAL,
      value
    }),
    toggleConfirmModal: (value) => dispatch({
      type: TOGGLE_CONFIRM_MODAL,
      value
    }),
    toggleWorkoutTimerRunning: (value) => dispatch({
      type: TOGGLE_WORKOUT_TIMER_RUNNING,
      value
    }),
    setWorkoutInProgress: (value) => dispatch({
      type: SET_WORKOUT_IN_PROGRESS,
      value
    }),
    resetTimer: (value) => dispatch({
      type: RESET_TIMER,
      value
    }),
    handleAchievementModal: (achievementProps) => dispatch(handleAchievementModal(achievementProps))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserWorkoutPlans));