import React from 'react';
// import { Menu, Dropdown, Popover } from 'antd';
// import { BiEditAlt } from "react-icons/bi";
// import { MdDeleteOutline, MdAdd } from "react-icons/md";
// import { AiOutlineFileAdd, AiOutlineUserAdd, AiFillStar } from "react-icons/ai";
// import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import './index.css';

export const WorkoutProgramCard = ({
  workoutProgram, 
  toggleWorkoutProgramModal, 
  setCurrentWorkoutProgram,
  isUserWorkoutProgram,
  style,
  isUserWorkoutPlan
  // deleteLibraryWorkout, 
  // deleteWorkoutLoading,
  // setIsWorkoutEdit,

}) => {
  return (
    <div 
      key={workoutProgram._id}
      className='workout-program-card flex space-between exercise-card flex-center-aligned pointer' 
      onClick={() => {toggleWorkoutProgramModal(true); setCurrentWorkoutProgram(workoutProgram)}}
      style={{background: style && style.background || null}}
    >
      <div>
        {/* <p className='card-date'>{isUserWorkoutProgram ? `Assigned on ${moment(workoutProgram.date).format("MMM, DD YYYY")}` : `Created on ${moment(workoutProgram.date).format("MMM, DD YYYY")}`}</p> */}
        <p className='blog-card-category'>{!isUserWorkoutProgram && `Created on ${moment(workoutProgram.date).format("MMM, DD YYYY")}`}</p>
        <h2 className='home-card-text'>{workoutProgram.name}</h2>
      </div>
      {/* {!isUserWorkout && */}
      {/* // <Dropdown overlay={menu()} trigger={['click']} className="pointer">
      //   <EllipsisOutlined style={{fontSize: '24px'}}/>
      // </Dropdown>} */}
    </div>
  )
}