import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './index.css';

export default () => {
  return(
    <div className="not-found-height">
      <div className="not-found-center">
        <h4>Page not found</h4>
        <Link className="mt-3" to="/dashboard/home">
          <Button type='primary'>
            Go Home
          </Button>
        </Link>
      </div>
    </div>
  )
}