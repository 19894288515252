import React from 'react';
import { useSelector } from 'react-redux';
import { BsArrowRight } from 'react-icons/bs';
import './index.css';

export const ScheduleNotification = (props) => {
  const scheduledNotifications = useSelector((state) => state.global.scheduledNotifications);

  const renderScheduleNotifications = () => {
    return scheduledNotifications && scheduledNotifications.map(notification => {
      return notification.isActive && (
      <>
        <p className='home-card-title'>Reminders</p>
        <div className='schedule-notification'>
          <div className='notification-content'>
            <p>{notification.content}</p>
            <div className='flex flex-center-aligned blue-link ml-2' onClick={() => props.openDrawer()}>
              <p>Go</p>
              <BsArrowRight />
            </div>
          </div>
        </div>
      </>
      )
    })
  }

  return(
    renderScheduleNotifications()
  )
}


