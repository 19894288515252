import {
  UPDATE_NUTRITION_PREFERENCES,
  UPDATE_NUTRITION_PREFERENCES_SUCCESS,
  UPDATE_NUTRITION_PREFERENCES_ERROR,
  GET_NUTRITION_PREFERENCES,
  GET_NUTRITION_PREFERENCES_SUCCESS,
  GET_NUTRITION_PREFERENCES_ERROR
} from "./constants";
import { restrictions } from '../../data/restrictions';

const initialState = {
  foodRestrictions: restrictions,
  nutritionPreferences: null,
  nutritionPreferencesLoading: false,
  nutritionPreferencesErr: null
};

function nutritionPreference(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NUTRITION_PREFERENCES:
      return {
        ...state,
        nutritionPreferencesErr: false,
        nutritionPreferencesLoading: true
      };
    case GET_NUTRITION_PREFERENCES_SUCCESS:
      return {
        ...state,
        nutritionPreferences: payload,
        nutritionPreferencesLoading: false
      };
    case GET_NUTRITION_PREFERENCES_ERROR:
      return {
        ...state,
        nutritionPreferencesLoading: false,
        nutritionPreferencesErr: action.err
      };
    case UPDATE_NUTRITION_PREFERENCES:
      return {
        ...state,
        nutritionPreferencesLoading: true,
        nutritionPreferencesErr: false
      };
    case UPDATE_NUTRITION_PREFERENCES_SUCCESS:
      return {
        ...state,
        nutritionPreferences: action.payload,
        nutritionPreferencesErr: false
      };
    case UPDATE_NUTRITION_PREFERENCES_ERROR:
      return {
        ...state,
        nutritionPreferencesLoading: false,
        nutritionPreferencesErr: action.err
      };
    default:
      return state;
  }
}

export default nutritionPreference;