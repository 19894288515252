
export const Units = {
  metric: 'metric',
  imperial: 'imperial',
};
export const totalQue = 26;

export default {
  age: {
    key: 1,
    question: 'What\'s your date of birth?',
    type: 'age',
    component: [
      // {
      //   type: 'Input',
      //   render: [
      //     {
      //       maxLength: 2,
      //       type: 'Age',
      //       value: 'age',
      //       autoFocus: true,
      //       active: true,
      //       validate: (value, units, connectedValue) => {
      //         if (
      //           !connectedValue ||
      //           (connectedValue && connectedValue.length === 0)
      //         ) {
      //           return 'All fields are required';
      //         }
      //         if (isNaN(Number(value))) {
      //           return 'Only numbers accepted';
      //         }
      //         if (!value.length || Number(value) < 18 || Number(value) > 120) {
      //           return 'Age should be between 18 and 99';
      //         }
      //         return null;
      //       },
      //     },
      //   ],
      // },
      // Comment Nandini
      {
        type: 'DatePickerBirthday',
        render: [
          {
            type: 'Date of birth',
            // placeholder: '05/23/1983',
            popover: { title: 'Why?', text: 'Your age is calculated based on your date of birth. This is crucial to accurately calculate your needed calories based on your age.' },
            active: true,
            value: 'birthday',
            validate: function (value) {
              if (!value) {
                return 'All fields are required';
              }
              return null;
            },
          },
        ]
      },

    ],
    button: true,
  },
  weight: {
    key: 2,
    type: 'weight',
    question: 'What\'s your current weight?',
    component: [
      {
        type: 'Input',
        addOnAfter: {
          keys: [
            { key: 'lb', units: Units.imperial },
            { key: 'kg', units: Units.metric },
          ],
        },
        render: [
          {
            maxLength: 5,
            inputType: 'number',
            type: 'Weight',
            value: 'weight',
            active: true,
            className: 'no-border-radius',
            validate: (value, units) => {
              if (isNaN(Number(value))) {
                return 'Only numbers accepted';
              }
              if (value.split('.')[1] && value.split('.')[1].length > 1) {
                return 'Only 1 decimal number allowed'
              }
              if (units === Units.imperial) {
                if (Number(value) < 50 || Number(value) > 500) {
                  return 'Weight range should be 50lb - 500lb';
                }
              } else {
                if (
                  !value.length ||
                  Number(value) < 25 ||
                  Number(value) > 225
                ) {
                  return 'Goal weight range should be 25kg - 225kg';
                }
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  height: {
    key: 3,
    type: 'height',
    question: 'Enter your height',
    component: [
      {
        type: 'HeightFtAndIc',
        units: Units.imperial,
        render: [
          {
            addOnAfter: {
              keys: [{ key: 'lbs', units: Units.imperial }],
            },
            maxLength: 1,
            type: 'Height ft.',
            value: 'heightFt',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              if (
                Number(connectedValue.value) < 0 ||
                Number(connectedValue.value) > 11
              ) {
                return 'Height in. range should be 0 - 11';
              }
              if (isNaN(Number(value))) {
                return 'Only numbers accepted';
              }
              if (!value.length || Number(value) < 2 || Number(value) > 9) {
                return 'Height ft. range should be 2 - 9';
              }
              return null;
            },
          },
          {
            maxLength: 2,
            type: 'Height in.',
            value: 'heightIn',
            active: true,
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              if (
                Number(connectedValue.value) < 2 ||
                Number(connectedValue.value) > 9
              ) {
                return 'Height ft. range should be 2 - 9';
              }
              if (isNaN(Number(value))) {
                return 'Only numbers accepted';
              }
              if (!value.length || Number(value) < 0 || Number(value) > 11) {
                return 'Height in. range should be 0 - 11';
              }
              return null;
            },
          },
        ],
      },
      {
        type: 'Input',
        addOnAfter: {
          keys: [{ key: 'cm', units: Units.metric, className: 'border-right' }],
        },
        units: Units.metric,
        render: [
          {
            maxLength: 3,
            type: 'Height cm.',
            value: 'heightCm',
            active: true,
            className: 'no-border-radius',
            validate: (value) => {
              if (isNaN(Number(value))) {
                return 'Only numbers accepted';
              }
              if (!value.length || Number(value) < 70 || Number(value) > 300) {
                return 'Height cm. range should be 70 - 300';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  gender: {
    key: 4,
    type: 'gender',
    question: "What's your biological sex?",
    options: [
      { key: 'male', text: 'Male', value: 'male' },
      { key: 'female', text: 'Female', value: 'female' },
    ],
    validate: () => null,
    button: true,
  },
  activityLevel: {
    key: 5,
    type: 'activityLevel',
    question: 'Whats your current activity level?',
    //subtitle: { icon: <TiWarning />, text: 'Most people overestimate their activity level. Only count the days that you exercise at a moderate or higher intensity.' },
    options: [
      {
        key: '1.2',
        text: 'Sedentary',
        value: '1.2',
        info: 'Little or no exercise'
      },
      {
        key: '1.375',
        text: 'Lightly active',
        value: '1.375',
        info: 'Light exercise/sports 1-3 days per week'
      },
      {
        key: '1.55',
        text: 'Moderately active',
        value: '1.55',
        info: 'Moderate exercise/sports 3-5 days per week'
      },
      {
        key: '1.725',
        text: 'Very active',
        value: '1.725',
        info: 'Hard exercise/sports 6-7 days per week'
      },
      {
        key: '1.9',
        text: 'Extra active',
        value: '1.9',
        info: 'Very hard exercise/sports and physical job or 2x training'
      },
    ],
    validate: () => null,
    button: true,
  },
  goal: {
    key: 6,
    type: 'goal',
    question: 'What best describes your current goal?',
    options: [
      {
        key: 1,
        text: 'Lose body fat',
        goal: 'lose body fat',
        value: 'Lose body fat',
        popover: { text: 'If you are trying to lose 10 lbs or more.' },
      },
      {
        key: 2,
        text: 'Gain muscle',
        goal: 'gain',
        value: 'Gain muscle',
        popover: { text: 'Building muscle will require a surplus of calories. Note that you might add some body fat while building muscle.' },
      },
      // {
      //   key: 3,
      //   text: 'Athletic performance',
      //   value: 'Athletic performance',
      //   goal: 'athletic performance',
      //   popover: { text: 'A surplus of calories that will help you sustain the energy needed for strenuous athletic training.' },
      // },
      {
        key: 3,
        text: 'Body recomposition',
        value: 'Body recomposition',
        goal: 'body recomposition',
        popover: { text: 'A small deficit of calories that will allow you to build muscle while burning fat. Choose this option if you need to lose less than 10 lbs.' },
      },
      {
        key: 4,
        text: 'Improve overall health',
        value: 'Improve overall health',
        goal: 'improving overall health',
        popover: { text: 'You will get the number of calories needed to maintain your current weight.' },
      },
    ],
    validate: () => null,
    button: true,
  },
  // goalWeight: {
  //   key: 7,
  //   type: 'goalWeight',
  //   question: 'How much do you want to weigh?',
  //   component: [
  //     {
  //       type: 'Input',
  //       addOnAfter: {
  //         keys: [
  //           { key: 'lb', units: Units.imperial },
  //           { key: 'kg', units: Units.metric },
  //         ],
  //         type: 'one',
  //       },
  //       render: [
  //         {
  //           maxLength: 5,
  //           inputType: 'number',
  //           type: `Goal weight`,
  //           active: true,
  //           style: { marginBottom: 10 },
  //           value: 'goalWeight',
  //           className: 'no-border-radius',
  //           validate: (value, units, connectedValue) => {
  //             if (isNaN(Number(value))) {
  //               return 'Only numbers accepted';
  //             }
  //             if (
  //               !connectedValue ||
  //               (connectedValue && connectedValue.length === 0)
  //             ) {
  //               return 'All fields are required';
  //             }
  //             if (value.split('.')[1] && value.split('.')[1].length > 1) {
  //               return 'Only 1 number after dot allowed'
  //             }
  //             if (units === Units.imperial) {
  //               if (Number(value) < 50 || Number(value) > 500) {
  //                 return 'Goal weight range should be 50lb - 500lb';
  //               }
  //             } else {
  //               if (
  //                 !value.length ||
  //                 Number(value) < 25 ||
  //                 Number(value) > 225
  //               ) {
  //                 return 'Goal weight range should be 25kg - 225kg';
  //               }
  //             }
  //             return null;
  //           },
  //         },
  //       ],
  //     },
  //     // {
  //     //   type: 'Tabs',
  //     //   question: 'At what rate?',
  //     //   options: [
  //     //     {
  //     //       key: 1,
  //     //       text: 'Slow',
  //     //       value: 'slow',
  //     //       infoMetric: '~.2kg',
  //     //       infoImperial: '~.5lb',
  //     //       // info: Units.imperial ? '~.5lb' : '~.2kg',
  //     //     },
  //     //     {
  //     //       key: 2,
  //     //       text: 'Moderate',
  //     //       value: 'moderate',
  //     //       infoMetric: '~.5kg',
  //     //       infoImperial: '~1lb',
  //     //       // info: Units.imperial ? '~1lb' : '~.5kg',
  //     //       active: true
  //     //     },
  //     //     {
  //     //       key: 3,
  //     //       text: 'Aggressive',
  //     //       value: 'aggressive',
  //     //       infoMetric: '~0.5kg',
  //     //       infoImperial: '~2lb',
  //     //       // info: Units.imperial ? '~2lb' : '~0.5kg',
  //     //     },
  //     //   ],
  //     //   render: [
  //     //     {
  //     //       type: 'Rate?',
  //     //       value: 'rateToLoseOrGain',
  //     //       validate: () => function (value, units, connectedValue) {
  //     //         if (!connectedValue) {
  //     //           return 'All fields are required'
  //     //         }
  //     //       }
  //     //     },
  //     //   ],
  //     // },
  //     {
  //       type: 'DatePicker',
  //       question: 'By when?',
  //       render: [
  //         {
  //           type: 'By when?',
  //           active: true,
  //           value: 'byWhen',
  //           validate: function (value, units, connectedValue) {
  //             if (
  //               !connectedValue ||
  //               (connectedValue && connectedValue.length === 0)
  //             ) {
  //               return 'All fields are required';
  //             }
  //             if (isNaN(Number(connectedValue.value))) {
  //               return 'Only numbers accepted';
  //             }
  //             if (!value || moment(value).isBefore()) {
  //               return 'Date should be in the future';
  //             }
  //             return null;
  //           },
  //         },
  //       ],
  //     }
  //   ],
  //   measurementSystem: [{ key: 'feet' }, { key: 'cm' }],
  //   button: true,
  // },
  foodPreference: {
    key: 7,
    type: 'foodPreference',
    question: "What's your food preference?",
    options: [
      {
        key: 1,
        text: 'Anything',
        value: 'anything',
        info:
          'No major preferences or restrictions. Will eat practically anything.',
      },
      {
        key: 2,
        text: 'Keto',
        value: 'keto',
        info: 'A high-fat, very-low carbohydrate diet.',
      },
      {
        key: 3,
        text: 'Paleo',
        value: 'paleo',
        info: 'Emphasizes meats, vegetables, and healthy fats.',
      },
      {
        key: 4,
        text: 'Vegetarian',
        value: 'vegetarian',
        info:
          'A plant-based diet, plus small amounts of eggs and dairy.',
      },
      {
        key: 5,
        text: 'Vegan',
        value: 'vegan',
        info: 'All plant-based foods. No animal products of any kind.',
      },
    ],
    validate: () => null,
    button: true,
  },
  macros: {
    key: 8,
    type: 'macros',
    question: 'Select your macronutrient ratio',
    options: null,
    component: [
      {
        type: 'CustomPieChart',
        render: [
          {

          },
        ],
      },
    ],
    validate: (value, type) => {
      if (type === 'Custom') {
        if (Object.entries(value).some(i => isNaN(i[1]))) {
          return 'Only numbers accepted';
        }
        if (Object.entries(value).reduce((acc, curr) => acc + +curr[1], 0) !== 100) {
          return 'Sum of percents must be 100'
        }
      }
      return null;
    },
    button: true,
  },
  secondaryGoals: {
    key: 9,
    type: 'secondaryGoals',
    question: 'What other secondary goals would you like to accomplish?',
    component: [
      {
        type: 'TextArea',
        render: [
          {
            type: 'Type here...',
            value: 'secondaryGoals',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              return null;
            },
          }
        ],
      },
    ],
    button: true,
  },
  trainingActivity: {
    key: 10,
    type: 'trainingActivity',
    question: 'Is there any activity outside of the training you would like to keep or add-in alongside the program? (Please explain why)',
    component: [
      {
        type: 'TextArea',
        render: [
          {
            type: 'Type here...',
            value: 'trainingActivity',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              return null;
            },
          }
        ],
      },
    ],
    button: true,
  },
  supplements: {
    key: 11,
    type: 'supplements',
    question: 'Do you currently take any supplements? If so, please list them.',
    component: [
      {
        type: 'TextArea',
        render: [
          {
            type: 'Type here...',
            value: 'supplements',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              return null;
            },
          }
        ],
      },
    ],
    button: true,
  },
  explainReachGoals: {
    key: 12,
    type: 'explainReachGoals',
    question: 'If you had to think of the deep underlying reason as to why you want to reach these goals, how would you explain it to me?',
    component: [
      {
        type: 'TextArea',
        render: [
          {
            type: 'Type here...',
            value: 'explainReachGoals',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              return null;
            },
          }
        ],
      },
    ],
    button: true,
  },
  obstacleReachingFitnessGoals: {
    key: 13,
    type: 'obstacleReachingFitnessGoals',
    question: 'What would you say is the biggest obstacle currently holding you back from reaching your fitness goals? This is very important, please give a detailed answer about what it is that you feel is holding you back.',
    component: [
      {
        type: 'TextArea',
        render: [
          {
            type: 'Type here...',
            value: 'obstacleReachingFitnessGoals',
            active: true,
            style: { marginBottom: 10 },
            validate: (value, units, connectedValue) => {
              if (
                !connectedValue ||
                (connectedValue && connectedValue.length === 0)
              ) {
                return 'All fields are required';
              }
              return null;
            },
          }
        ],
      },
    ],
    button: true,
  },
  travel: {
    key: 14,
    type: 'travel',
    question: "How much do you travel, either for work or pleasure?",
    options: [
      { key: '1', text: 'Rarely', value: 'Rarely' },
      { key: '2', text: '1 - 2 times a year', value: '1 - 2 times a year' },
      { key: '3', text: '3 - 4 times a year', value: '3 - 4 times a year' },
      { key: '4', text: 'Every month', value: 'Every month' },
      { key: '5', text: 'Every week', value: 'Every week' },
    ],
    validate: () => null,
    button: true,
  },
  mealsPrefer: {
    key: 15,
    type: 'mealsPrefer',
    question: "Do you prefer eating fewer, larger meals or more frequent, smaller meals?",
    options: [
      { key: '1', text: 'Fewer, larger meals', value: 'Fewer, larger meals' },
      { key: '2', text: 'More frequent, smaller meals', value: 'More frequent, smaller meals' },
      { key: '3', text: 'No preference', value: 'No preference' },
    ],
    validate: () => null,
    button: true,
  },
  happyEatingFoods: {
    key: 16,
    type: 'happyEatingFoods',
    question: "Are you happy eating the same foods frequently or do you like a lot of variety?",
    options: [
      { key: '1', text: 'I am happy eating the same foods frequently', value: 'I am happy eating the same foods frequently' },
      { key: '2', text: 'I like a lot of variety', value: 'I like a lot of variety' },
    ],
    validate: () => null,
    button: true,
  },
  riskFollowing: {
    key: 17,
    type: 'riskFollowing',
    question: 'Are you at risk of any of the following?',
    component: [
      {
        type: 'CustomCheckbox',
        render: [
          {
            options: [
              { key: '1', text: 'None', value: 'None' },
              { key: '2', text: 'Testosterone deficiency', value: 'Testosterone deficiency' },
              { key: '3', text: 'Heart disease or stroke', value: 'Heart disease or stroke' },
              { key: '4', text: 'High blood pressure', value: 'High blood pressure' },
              { key: '5', text: 'Diabetes', value: 'Diabetes' },
              { key: '6', text: 'Osteoarthritis', value: 'Osteoarthritis' },
              { key: '7', text: 'Other', value: 'Other' },
            ],
            value: 'riskFollowing',
            validate: (selectedOptions, otherDescription) => {
              if (selectedOptions.length == 0) {
                return 'All fields are required';
              }
              if (selectedOptions.includes("None") && selectedOptions.length > 1) {
                return 'Select either None or others options';
              }
              if (selectedOptions.includes("Other") && !otherDescription) {
                return 'Please describe Other';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  averageStepCount: {
    key: 18,
    type: 'averageStepCount',
    question: 'What\'s your average daily step count?',
    component: [
      {
        type: 'Input',
        render: [
          {
            //maxLength: 5,
            type: '',
            inputType: 'number',
            value: 'averageStepCount',
            active: true,
            className: 'no-border-radius',
            validate: (value) => {
              if (!value) {
                return 'All fields are required';
              }
              if (isNaN(Number(value)) || Number(value) !== parseInt(value, 10)) {
                return 'Only numbers accepted';
              }
              if (value < 0) {
                return 'Please enter valid step count';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  doStrengthTraining: {
    key: 19,
    type: 'doStrengthTraining',
    question: "Do you currently do any type of strength training?",
    options: [
      { key: '1', text: 'Yes', value: 'Yes' },
      { key: '2', text: 'No', value: 'No' },
    ],
    validate: () => null,
    button: true,
  },
  weightTraningexprience: {
    key: 20,
    type: 'weightTraningexprience',
    question: 'How would you classify yourself in terms of weight training experience?',
    component: [
      {
        type: 'weightTraningexprience',
        render: [
          {
            min: 1,
            max: 3,
            step: null,
            defaultValue: 1,
            tooltipVisible: false,
            marks: {
              1: 'Beginner',
              2: 'Intermediate',
              3: 'Advanced',
            },
            tooltips: {
              1: 'I\'ve never lifted weights, or just recently started.',
              2: 'I have some experience with common strength training exercises',
              3: 'I’ve been training for years but want to take it to the next level'
            },
          },
        ],
      },
    ],
    validate: () => null,
    button: true,
  },
  significantInjuries: {
    key: 21,
    type: 'significantInjuries',
    question: "Do you have any significant injuries?",
    options: [
      { key: '1', text: 'Yes', value: 'Yes' },
      { key: '2', text: 'No', value: 'No' },
    ],
    validate: () => null,
    button: true,
  },
  cardioType: {
    key: 22,
    type: 'cardioType',
    question: 'Do you currently do any of the following types of cardio?',
    component: [
      {
        type: 'cardioType',
        render: [
          {
            options: [
              { key: '1', text: 'Running', value: 'Running' },
              { key: '2', text: 'Walking', value: 'Walking' },
              { key: '3', text: 'Elliptical', value: 'Elliptical' },
              { key: '4', text: 'Cycling', value: 'Cycling' },
              { key: '5', text: 'Rowing', value: 'Rowing' },
              { key: '6', text: 'Swimming', value: 'Swimming' },
              { key: '7', text: 'Other', value: 'Other' },
            ],
            value: 'cardioType',
            validate: (selectedOptions, otherDescription) => {
              if (selectedOptions.length == 0) {
                return 'All fields are required';
              }
              if (selectedOptions.includes("None") && selectedOptions.length > 1) {
                return 'Select either None or others options';
              }
              if (selectedOptions.includes("Other") && !otherDescription) {
                return 'Please describe Other';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  equipment: {
    key: 23,
    type: 'equipment',
    question: "What kind of equipment will you have access to? If you don’t have any equipment, that’s OK. We can create customized training programs based solely on body weight. Just select ‘No equipment’.",
    component: [
      {
        type: 'equipment',
        render: [
          {
            options: [
              { key: '1', text: 'Barbells', value: 'Barbells' },
              { key: '2', text: 'Dumbbells', value: 'Dumbbells' },
              { key: '3', text: 'Gym machines', value: 'Gym machines' },
              { key: '4', text: 'Cable weights', value: 'Cable weights' },
              { key: '5', text: 'Resistance bands', value: 'Resistance bands' },
              { key: '6', text: 'Kettlebells', value: 'Kettlebells' },
              { key: '7', text: 'TRX bands', value: 'TRX bands' },
              { key: '8', text: 'No equipment', value: 'No equipment' },
              { key: '9', text: 'Other', value: 'Other' },
            ],
            value: 'equipment',
            validate: (selectedOptions, otherDescription) => {
              if (selectedOptions.length == 0) {
                return 'All fields are required';
              }
              if (selectedOptions.includes("None") && selectedOptions.length > 1) {
                return 'Select either None or others options';
              }
              if (selectedOptions.includes("Other") && !otherDescription) {
                return 'Please describe Other';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
  timeForWorkout: {
    key: 24,
    type: 'timeForWorkout',
    question: "How much time do you have for each workout?",
    options: [
      { key: '1', text: 'Less than 30 minutes', value: 'Less than 30 minutes' },
      { key: '2', text: '30 - 60 minutes', value: '30 - 60 minutes' },
      { key: '3', text: '60 - 90 minutes', value: '60 - 90 minutes' },
    ],
    validate: () => null,
    button: true,
  },
  workoutFrequency: {
    key: 25,
    type: 'workoutFrequency',
    question: "How frequently are you able to workout out?",
    options: [
      { key: '1', text: '2 - 3 times a week', value: '2 - 3 times a week' },
      { key: '2', text: '4 - 5 times a week', value: '4 - 5 times a week' },
      { key: '3', text: '6 - 7 times a week', value: '6 - 7 times a week' },
    ],
    validate: () => null,
    button: true,
  },
  currentLocation: {
    key: 26,
    type: 'currentLocation',
    question: 'What location are you currently in?',
    component: [
      {
        type: 'InputAutoCompleteMap',
        render: [
          {
            type: '',
            value: 'currentLocation',
            active: true,
            className: 'no-border-radius',
            validate: (value) => {
              return value && value.location && value.lat && value.lng ? null : 'Please select your current location.';
            },
          },
        ],
      },
    ],
    button: true,
  },
  restPassword: {
    key: 27,
    type: 'newPassword',
    question: 'Reset Your Password',
    component: [
      {
        type: 'newPassword',
        render: [
          {
            type: 'New Password',
            inputType: 'password',
            value: 'newPassword',
            active: true,
            className: 'no-border-radius',
            validate: (value, connectedValue) => {
              if (!value || (value && value.length === 0)) {
                return 'All fields are required';
              } else if (!connectedValue || (connectedValue.value && connectedValue.value.length === 0)) {
                return 'All fields are required';
              } else if (value && value.length < 6) {
                return 'Password length must be greater than or equal to 6';
              } else if (connectedValue.value && connectedValue.value.length < 6) {
                return 'Confirm password length must be greater than or equal to 6';
              } else if (value !== connectedValue.value) {
                return 'Password and Confirm password must match';
              }
              return null;
            },
          },
          {
            type: 'Confirm New Password',
            inputType: 'password',
            value: 'confirmNewPassword',
            active: true,
            className: 'no-border-radius',
            validate: (value, connectedValue) => {
              if (!value || (value && value.length === 0)) {
                return 'All fields are required';
              } else if (!connectedValue || (connectedValue.value && connectedValue.value.length === 0)) {
                return 'All fields are required';
              } else if (value && value.length < 6) {
                return 'Confirm password length must be greater than or equal to 6';
              } else if (connectedValue.value && connectedValue.value.length < 6) {
                return 'Password length must be greater than or equal to 6';
              } else if (value !== connectedValue.value) {
                return 'Password and Confirm password must match';
              }
              return null;
            },
          },
        ],
      },
    ],
    button: true,
  },
};
