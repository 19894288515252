import {
  GET_USER_FITBIT_LOGS_BY_ID,
  GET_USER_FITBIT_LOGS_BY_ID_SUCCESS,
  GET_USER_FITBIT_LOGS_BY_ID_ERROR,
  GET_USER_NUTRITION_BY_ID,
  GET_USER_NUTRITION_BY_ID_SUCCESS,
  GET_USER_NUTRITION_BY_ID_ERROR,
  GET_USER_NUTRITION_PREFERENCE_BY_ID,
  GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS,
  GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR,
  DELETE_MEALPLAN_TEMPLATE,
  DELETE_MEALPLAN_TEMPLATE_SUCCESS,
  UPDATE_MEALPLAN_BY_ID,
  UPDATE_MEALPLAN_BY_ID_SUCCESS,
  GET_MEALPLAN_BY_ID,
  GET_MEALPLAN_BY_ID_SUCCESS,
  GET_COACH_MEALPLANS,
  GET_COACH_MEALPLANS_SUCCESS,
  GET_USER_MEALPLAN_BY_ID,
  GET_USER_MEALPLAN_BY_ID_SUCCESS,
  GET_USER_MEALPLAN_BY_ID_ERROR,
  GET_CLIENT_PROFILE_BY_ID,
  GET_CLIENT_PROFILE_BY_ID_SUCCESS,
  GET_CLIENT_PROFILE_BY_ID_ERROR,
  UPDATE_CLIENT_MACROS,
  UPDATE_CLIENT_MACROS_SUCCESS,
  UPDATE_CLIENT_MACROS_ERROR,
  SAVE_CLIENT_NUTRITION,
  SAVE_CLIENT_NUTRITION_SUCCESS,
  SAVE_CLIENT_NUTRITION_ERROR,
  DELETE_CUSTOM_MEAL_PLAN,
  DELETE_CUSTOM_MEAL_PLAN_SUCCESS
  // ADD_CLIENT_WEIGHT_TO_PROFILE,
  // ADD_CLIENT_WEIGHT_TO_PROFILE_SUCCESS,
  // ADD_CLIENT_WEIGHT_TO_PROFILE_ERROR
} from './constants';

export function getUserFitbitLogsById(userId) {
  return {
    type: GET_USER_FITBIT_LOGS_BY_ID,
    userId
  };
}

export function getUserFitbitLogsByIdSuccess(payload) {
  return {
    type: GET_USER_FITBIT_LOGS_BY_ID_SUCCESS,
    payload
  };
}

export function getUserFitbitLogsByIdError(err) {
  return {
    type: GET_USER_FITBIT_LOGS_BY_ID_ERROR,
    err
  };
}

export function getUserNutritionById(userId) {
  return {
    type: GET_USER_NUTRITION_BY_ID,
    userId
  };
}

export function getUserNutritionByIdSuccess(payload) {
  return {
    type: GET_USER_NUTRITION_BY_ID_SUCCESS,
    payload
  };
}

export function getUserNutritionByIdError(err) {
  return {
    type: GET_USER_NUTRITION_BY_ID_ERROR,
    err
  };
}

export function getUserNutritionPreferenceById(userId) {
  return {
    type: GET_USER_NUTRITION_PREFERENCE_BY_ID,
    userId
  };
}

export function getUserNutritionPreferenceByIdSuccess(payload) {
  return {
    type: GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS,
    payload
  };
}

export function getUserNutritionPreferenceByIdError(err) {
  return {
    type: GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR,
    err
  };
}

export function getCoachMealPlans(drafts) {
  return {
    type: GET_COACH_MEALPLANS,
    drafts
  }
}

export function getCoachMealPlansSuccess(payload) {
  return {
    type: GET_COACH_MEALPLANS_SUCCESS,
    payload
  }
}

export function deleteMealPlanTemplate(id) {
  return {
    type: DELETE_MEALPLAN_TEMPLATE,
    id
  }
}

export function deleteMealPlanTemplateSuccess(payload) {
  return {
    type: DELETE_MEALPLAN_TEMPLATE_SUCCESS,
    payload
  }
}

export function getMealplanById(id) {
  return {
    type: GET_MEALPLAN_BY_ID,
    id
  }
}

export function getMealplanByIdSuccess(payload) {
  return {
    type: GET_MEALPLAN_BY_ID_SUCCESS,
    payload
  }
}

export function updateMealplanById(id, plan) {
  return {
    type: UPDATE_MEALPLAN_BY_ID,
    id,
    plan
  }
}

export function updateMealplanByIdSuccess(payload) {
  return {
    type: UPDATE_MEALPLAN_BY_ID_SUCCESS,
    payload
  }
}

export function getUserMealPlanById(userId) {
  return {
    type: GET_USER_MEALPLAN_BY_ID,
    userId
  }
}

export function getUserMealPlanByIdSuccess(payload) {
  return {
    type: GET_USER_MEALPLAN_BY_ID_SUCCESS,
    payload
  }
}

export function getUserMealPlanByIdError(err) {
  return {
    type: GET_USER_MEALPLAN_BY_ID_ERROR,
    err
  }
}

export function getClientProfileById(userId) {
  return {
    type: GET_CLIENT_PROFILE_BY_ID,
    userId
  }
}

export function getClientProfileByIdSuccess(payload) {
  return {
    type: GET_CLIENT_PROFILE_BY_ID_SUCCESS,
    payload
  }
}

export function getClientProfileByIdError(err) {
  return {
    type: GET_CLIENT_PROFILE_BY_ID_ERROR,
    err
  }
}

export function updateClientMacros(clientProfile) {
  return {
    type: UPDATE_CLIENT_MACROS,
    clientProfile
  }
}

export function updateClientMacrosSuccess(payload) {
  return {
    type: UPDATE_CLIENT_MACROS_SUCCESS,
    payload
  }
}

export function updateClientMacrosError(err) {
  return {
    type: UPDATE_CLIENT_MACROS_ERROR,
    err
  }
}

export function saveClientNutrition(nutrition) {
  return {
    type: SAVE_CLIENT_NUTRITION,
    nutrition
  }
}

export function saveClientNutritionSuccess(payload) {
  return {
    type: SAVE_CLIENT_NUTRITION_SUCCESS,
    payload
  }
}

export function saveClientNutritionError(err) {
  return {
    type: SAVE_CLIENT_NUTRITION_ERROR,
    err
  }
}

export function deleteCustomMealPlan(mealPlanId) {
  return {
    type: DELETE_CUSTOM_MEAL_PLAN,
    mealPlanId
  }
}

export function deleteCustomMealPlanSuccess(payload) {
  return {
    type: DELETE_CUSTOM_MEAL_PLAN_SUCCESS,
    payload
  }
}