import React from 'react';
import { Input, Modal, Button, Checkbox } from 'antd';
import NotificationAlert from '../../components/NotificationAlert';
import { BiCheck } from "react-icons/bi";
import { BiFilterAlt } from "react-icons/bi";
import './index.css';
const { Search } = Input;

class RecipeSearch extends React.Component {
  state={
    recipeFilterVisible: false,
    loading: ""
  }

  getButtonTitle = () => {
    const { loading, error } = this.props;
    if(this.state.loading === ""){
      return "Filter";
    } else if (loading === false && error === null) {
      return <BiCheck style={{fontSize: '20px'}}/>;
    } else if (loading === false && error !== null) {
      return "Filter";
    } else if(loading === true){
      setTimeout(() => {
        this.setState({ loading: "" });
      }, 2000);
    } 
  };

  menu = () => {
    return (
      <div className='filter-menu'>
        <div className='filter-container'>
          <div className='filter-section'>
            <p>Calories</p>
            <div className='calorie-filter'>
              <Input type='number' value={this.props.calorieFilter.from} onChange={(e) => this.props.handleCalorieInput(e, 'from')} placeholder='From'/>
              <Input type='number' value={this.props.calorieFilter.to} onChange={(e) => this.props.handleCalorieInput(e, 'to')} placeholder='To'/>
            </div>
          </div>
          <div className='checkbox-filters'>
            <div className='filter-section'>
              <p>Meal</p>
              <div className='check-box-filters'>
                {this.props.mealTypes.map(mealType => <Checkbox checked={mealType.checked} key={mealType.key} onChange={() => this.props.handleCheckbox('mealType', mealType)}>{mealType.label}</Checkbox>)}
              </div>
            </div>
            <div className='filter-section'>
              <p>Diets</p>
              <div className='check-box-filters'>
                {this.props.dietTypes.map(diet => <Checkbox checked={diet.checked} key={diet.key} onChange={() => this.props.handleCheckbox('diet', diet)}>{diet.label}</Checkbox>)}
              </div>
            </div>
            <div className='filter-section'>
              <p>Allergies</p>
              <div className='check-box-filters'>
                {this.props.allergies.map(allergy => <Checkbox checked={allergy.checked} key={allergy.key} onChange={() => this.props.handleCheckbox('allergy', allergy)}>{allergy.label}</Checkbox>)}
              </div>
            </div>
          </div>
        </div>
          
        <div className='filter-btn-container'>
          <Button 
            style={{marginRight: '5px'}} 
            type='ghost' 
            onClick={() => this.props.clearAllFilters()}
          >
            Clear filters
          </Button>
          <Button loading={this.props.loading} type='primary' onClick={() => {this.props.getFiltersBeforeSearch(); this.setState({loading: true})}}>{this.getButtonTitle()}</Button>
        </div>
      </div>
    )
  }
    
  render(){
    return(
      <>
        <Modal
          visible={this.state.recipeFilterVisible}
          onCancel={() => this.setState({recipeFilterVisible: false})}
          footer={false}
          centered
        >
          {this.menu()}
        </Modal>
        <div className='flex flex-wrap'>
          <div className='recipe-search-bar'>
            <div className='recipe-search-section flex search flex-center-aligned'>
              <Search 
                className='recipe-search'
                onChange={(e) => this.props.handleRecipeSearchTerm(e.target.value)}
                placeholder="Search recipe..." 
                enterButton="Search" 
                loading={this.props.loading}
                onSearch={() => this.props.getFiltersBeforeSearch()}
                disabled={this.props.error || this.props.profile && !this.props.profile.user.isPro}
              />
              <Button disabled={this.props.profile && !this.props.profile.user.isPro} type='ghost' onClick={() => this.setState({recipeFilterVisible: true})}><BiFilterAlt/></Button>
            </div>
            {this.props.error && <NotificationAlert type={'error'} message={this.props.error} clearAlert={this.props.clearAlert}/>}
            {this.props.recipes && this.props.recipes.hits.length === 0 && 
            <p style={{fontWeight: '600', margin: '15px 0px 0px 15px'}}>No results found</p>}
          </div>
        </div>
      </>
    )
  }
}

export default RecipeSearch;