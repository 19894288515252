import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { capitalize } from '../../helpers/utils';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import './index.scss';

export const HomeSummary = ({profile}) => {
  let history = useHistory();
  
  const renderGoal = () => {
    const measureSystem = profile.isMetric ? 'kg' : 'lb';
    let goalDate = moment(profile.byWhen).format("MM/DD/YYYY");
    let today = moment(new Date()).toDate();
    let formattedToday = moment(today).format("MM/DD/YYYY");
    let days = Math.abs(
      moment(formattedToday, "MM/DD/YYYY")
        .startOf("day")
        .diff(moment(goalDate, "MM/DD/YYYY").startOf("day"), "days")
    );

    if(profile.goal.text === 'Lose weight' || profile.goal.text === 'Build muscle'){
      return (
        <div>
          <p className='home-card-text' style={{marginTop: '5px'}}>{`${capitalize(profile.goal.goal)} ${Math.abs(profile.currentWeight - profile.goalWeight).toFixed(1)} ${measureSystem} by ${moment(profile.byWhen).format('MMM DD, YY')}  - ${days} days`}</p>
        </div>
      )
    } else {
      return(
        <div>
          <p className='home-card-text' style={{marginTop: '5px'}}>{capitalize(profile.goal.goal)}</p>
        </div>
      )
    }
  }

  const menu = (
    <Menu onClick={(e) => handleChange(e)}>
      <Menu.Item key="0">
        <p>Edit</p>
      </Menu.Item>
    </Menu>
  );

  const handleChange = (e) => {
    if(e.key === '0') {
      history.push('/dashboard/tools/macro-calculator');
    }
  }
  
  return(
    <>
      <div className='flex flex-center-aligned space-between'>
        <p className='home-card-title'>Summary</p>
        <Dropdown overlay={menu} trigger={['click']}>
          <EllipsisOutlined style={{fontSize:'24px', marginBottom: '10px'}}/>
        </Dropdown>
      </div>
      
      <div className='summary-section mb-4'>
        <div className='flex summary-card'>
          <div>
            <p className='blog-card-category home-card-label'>Goal</p>
            <div className='flex'>
              {renderGoal()}
            </div>
          </div>
        </div>

        <div className='flex summary-card'>
          <div>
            <p className='blog-card-category home-card-label'>Food Preference</p>
            <div className='flex'>
              <p className='home-card-text' style={{marginTop: '5px'}}>{capitalize(profile.foodPreference)}</p>
            </div>
          </div>
        </div>

        <div className='flex summary-card'>
          <div>
            <p className='blog-card-category home-card-label'>Training Experience</p>
            <div className='flex'>
              <p className='home-card-text' style={{marginTop: '5px'}}>{capitalize(profile.weightTraningexprience ? profile.weightTraningexprience.text : '')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}