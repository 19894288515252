import {call, put, takeLatest} from "@redux-saga/core/effects";
import {fetchData} from "../api";
import {
  getAllNotificationsError,
  getAllNotificationsSuccess,
  getAllUserNotifications,
  initializeOneSignal
} from "./actions";
import {
  CHECK_ONE_SIGNAL_STATUS,
  CREATE_NOTIFICATION,
  GET_ALL_NOTIFICATIONS, UPDATE_ALL_NOTIFICATION_INFO, UPDATE_NOTIFICATION_INFO
} from "./constants";
import {store} from "../../App";

export function* checkOneSignal({userEmail}){
  try {
    const token = window.localStorage.token;
    const hashConfig = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({email: userEmail})
    };
    const apiConfig = {
      method: 'GET',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    let hashURL = `${process.env.REACT_APP_API_URL}/api/one-signal/getEmailAuthHash`;
    let appIdURL = `${process.env.REACT_APP_API_URL}/api/one-signal/getAppId`;
    let authHash = yield call(fetchData, hashURL, hashConfig);
    let appId = yield call(fetchData, appIdURL, apiConfig);
    yield put(initializeOneSignal(appId, userEmail, authHash));
  } catch (err) {
    console.log(err);
    const error = yield err.json();
    console.log(error);
  }
}

export function* createNotification({input}){
  try {
    const token = window.localStorage.token;
    const email = store.getState().auth.user && store.getState().auth.user.email ;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({input})
    };
    let url = `${process.env.REACT_APP_API_URL}/api/one-signal/createNotification`;
    yield call(fetchData, url, config);
    yield put(getAllUserNotifications(email))
  } catch (err) {
    console.log(err);
    const error = yield err.json();
    console.log(error);
  }
}

export function* getAllNotifications({userEmail}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({userEmail})
    };
    let url = `${process.env.REACT_APP_API_URL}/api/one-signal/viewNotifications`;
    let payload = yield call(fetchData, url, config);
    yield put(getAllNotificationsSuccess(payload))
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getAllNotificationsError(error));
  }
}

// export function* deleteNotification({payload}){
//   const { notificationId, userId, userEmail } = payload;
//   try {
//     const token = window.localStorage.token;
//     const config = {
//       method: 'DELETE',
//       headers: {
//         "x-auth-token": token,
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify({notificationId, userId})
//     };
//     let url = `${process.env.REACT_APP_API_URL}/api/one-signal/deleteNotification`;
//     yield call(fetchData, url, config);
//     yield put(getAllUserNotifications(userEmail))
//   } catch (err) {
//     const error = yield err.json();
//     console.log(error);
//   }
// }

export function* updateNotificationReadStatus({payload}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({...(payload || {})})
    };
    let url = `${process.env.REACT_APP_API_URL}/api/one-signal/updateNotification`;
    yield call(fetchData, url, config);
    yield put(getAllUserNotifications(payload && payload.userEmail))
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export function* updateAllNotificationReadStatus({userNotifications}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({userNotifications})
    };
    let url = `${process.env.REACT_APP_API_URL}/api/one-signal/updateAllNotifications`;
    yield call(fetchData, url, config);
    yield put(getAllUserNotifications(userNotifications && userNotifications[0].userEmail))
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export default function* onesignalSaga() {
  yield takeLatest(CHECK_ONE_SIGNAL_STATUS, checkOneSignal);
  yield takeLatest(CREATE_NOTIFICATION, createNotification);
  yield takeLatest(GET_ALL_NOTIFICATIONS, getAllNotifications);
  yield takeLatest(UPDATE_NOTIFICATION_INFO, updateNotificationReadStatus);
  yield takeLatest(UPDATE_ALL_NOTIFICATION_INFO, updateAllNotificationReadStatus);
}