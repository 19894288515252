import {
  SAVE_BODY_FAT,
  SAVE_BODY_FAT_SUCCESS,
  SAVE_BODY_FAT_ERROR,
  CLEAR_BODY_FAT_ERRORS,
  GET_USER_BF_MEASUREMENTS,
  GET_USER_BF_MEASUREMENTS_SUCCESS,
  GET_USER_BF_MEASUREMENTS_ERROR
} from './constants';

export function saveBodyFat(obj) {
  return {
    type: SAVE_BODY_FAT,
    obj
  };
}

export function saveBodyFatSuccess(response) {
  return {
    type: SAVE_BODY_FAT_SUCCESS,
    response
  };
}

export function saveBodyFatError(err) {
  return {
    type: SAVE_BODY_FAT_ERROR,
    err
  };
}

export function clearBodyFatErrors() {
  return {
    type: CLEAR_BODY_FAT_ERRORS
  }
}

export function getUserBfMeasurements(userId) {
  return {
    type: GET_USER_BF_MEASUREMENTS,
    userId
  };
}

export function getUserBfMeasurementsSuccess(payload) { 
  return {
    type: GET_USER_BF_MEASUREMENTS_SUCCESS,
    payload
  };
}

export function getUserBfMeasurementsError(err) {
  return {
    type: GET_USER_BF_MEASUREMENTS_ERROR,
    err
  };
}