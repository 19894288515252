export const GET_USER_FITBIT_LOGS_BY_ID = 'admin/GET_USER_FITBIT_LOGS_BY_ID';
export const GET_USER_FITBIT_LOGS_BY_ID_SUCCESS = 'admin/GET_USER_FITBIT_LOGS_BY_ID_SUCCESS';
export const GET_USER_FITBIT_LOGS_BY_ID_ERROR = 'admin/GET_USER_FITBIT_LOGS_BY_ID_ERROR';

export const GET_USER_NUTRITION_BY_ID = 'admin/GET_USER_NUTRITION_BY_ID';
export const GET_USER_NUTRITION_BY_ID_SUCCESS = 'admin/GET_USER_NUTRITION_BY_ID_SUCCESS';
export const GET_USER_NUTRITION_BY_ID_ERROR = 'admin/GET_USER_NUTRITION_BY_ID_ERROR';

export const GET_USER_NUTRITION_PREFERENCE_BY_ID = 'admin/GET_USER_NUTRITION_PREFERENCE_BY_ID';
export const GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS = 'admin/GET_USER_NUTRITION_PREFERENCE_BY_ID_SUCCESS';
export const GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR = 'admin/GET_USER_NUTRITION_PREFERENCE_BY_ID_ERROR';

export const DELETE_MEALPLAN_TEMPLATE = 'admin/DELETE_MEALPLAN_TEMPLATE';
export const DELETE_MEALPLAN_TEMPLATE_SUCCESS = 'admin/DELETE_MEALPLAN_TEMPLATE_SUCCESS';

export const UPDATE_MEALPLAN_BY_ID = 'admin/UPDATE_MEALPLAN_BY_ID';
export const UPDATE_MEALPLAN_BY_ID_SUCCESS = 'admin/UPDATE_MEALPLAN_BY_ID_SUCCESS';

export const GET_MEALPLAN_BY_ID = 'admin/GET_MEALPLAN_BY_ID';
export const GET_MEALPLAN_BY_ID_SUCCESS = 'admin/GET_MEALPLAN_BY_ID_SUCCESS';

export const GET_COACH_MEALPLANS = 'admin/GET_COACH_MEALPLANS';
export const GET_COACH_MEALPLANS_SUCCESS = 'admin/GET_COACH_MEALPLANS_SUCCESS';

export const GET_USER_MEALPLAN_BY_ID = 'admin/GET_USER_MEALPLAN_BY_ID';
export const GET_USER_MEALPLAN_BY_ID_SUCCESS = 'admin/GET_USER_MEALPLAN_BY_ID_SUCCESS';
export const GET_USER_MEALPLAN_BY_ID_ERROR = 'admin/GET_USER_MEALPLAN_BY_ID_ERROR';

export const GET_CLIENT_PROFILE_BY_ID = 'admin/GET_CLIENT_PROFILE_BY_ID';
export const GET_CLIENT_PROFILE_BY_ID_SUCCESS = 'admin/GET_CLIENT_PROFILE_BY_ID_SUCCESS';
export const GET_CLIENT_PROFILE_BY_ID_ERROR = 'admin/GET_CLIENT_PROFILE_BY_ID_ERROR';

export const UPDATE_CLIENT_MACROS = 'admin/UPDATE_CLIENT_MACROS';
export const UPDATE_CLIENT_MACROS_SUCCESS = 'admin/UPDATE_CLIENT_MACROS_SUCCESS';
export const UPDATE_CLIENT_MACROS_ERROR = 'admin/UPDATE_CLIENT_MACROS_ERROR';

export const SAVE_CLIENT_NUTRITION = 'admin/SAVE_CLIENT_NUTRITION';
export const SAVE_CLIENT_NUTRITION_SUCCESS = 'admin/SAVE_CLIENT_NUTRITION_SUCCESS';
export const SAVE_CLIENT_NUTRITION_ERROR = 'admin/SAVE_CLIENT_NUTRITION_ERROR';

export const ADD_CLIENT_WEIGHT_TO_PROFILE = 'admin/ADD_CLIENT_WEIGHT_TO_PROFILE';
export const ADD_CLIENT_WEIGHT_TO_PROFILE_SUCCESS = 'admin/ADD_CLIENT_WEIGHT_TO_PROFILE_SUCCESS';
export const ADD_CLIENT_WEIGHT_TO_PROFILE_ERROR = 'admin/ADD_CLIENT_WEIGHT_TO_PROFILE_ERROR';

export const SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE = 'admin/SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE';

export const DELETE_CUSTOM_MEAL_PLAN = "admin/DELETE_CUSTOM_MEAL_PLAN";
export const DELETE_CUSTOM_MEAL_PLAN_SUCCESS = "admin/DELETE_CUSTOM_MEAL_PLAN_SUCCESS";