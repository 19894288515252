import React from 'react';
import FoodLogCard from './FoodLogCard';
import moment from 'moment';
import './index.css';

class FoodLogHistoryPage extends React.Component {
  state={
    filteredUserNutrition: null, fitbitLogs: null
  }

  componentDidMount(){
    this.getNutritionMatchingLog();
  }

  componentDidUpdate (previousProps) {
    if (this.props.allUserNutrition.length !== previousProps.allUserNutrition.length) {
      this.getNutritionMatchingLog();
    }
  }

  getNutritionMatchingLog = () => {
    //Adds nutritions that have same date to food log obj
    const { fitbitLogs, allUserNutrition } = this.props;

    allUserNutrition.sort((a, b) => moment(b.date).diff(a.date));

    const getCurrentDateInTimezone = (date, timezone) => {
      const dateInTimezone = new Date(date).toLocaleString("en-US", {timeZone: timezone});
      return moment(new Date(dateInTimezone)).format('yyyy-MM-DD');
    }

    // let lastUserNutritionSaved = allUserNutrition[0];
    // let newFitbitLogs = fitbitLogs.map(log => ({...log, userNutrition: []}));
    // for(const nutrition of allUserNutrition) {
    for(const [index, log] of fitbitLogs.entries()){
      fitbitLogs[index].userNutrition = []
      if (log.timezone) {
        const nutritionOccurence = allUserNutrition.find(i => log.date.slice(0, 10) === getCurrentDateInTimezone(i.date, log.timezone))
        if (nutritionOccurence) {
          fitbitLogs[index].userNutrition.unshift(nutritionOccurence)
        } else {
          //Adds the latest nutrition saved if there isn't a log and nutrition with same date
          const lastMatchedNutritions = allUserNutrition.filter(i => new Date(getCurrentDateInTimezone(i.date, log.timezone)) <= new Date(fitbitLogs[index].date))
          fitbitLogs[index].userNutrition.unshift(lastMatchedNutritions[0])
        }
      } else {
        const nutritionOccurence = allUserNutrition.find(i => log.date.slice(0, 10) === i.date.slice(0, 10))
        if (nutritionOccurence) {
          fitbitLogs[index].userNutrition.unshift(nutritionOccurence)
        } else {
          //Adds the latest nutrition saved if there isn't a log and nutrition with same date
          const lastMatchedNutritions = allUserNutrition.filter(i => new Date(i.date.slice(0, 10)) <= new Date(fitbitLogs[index].date))
          fitbitLogs[index].userNutrition.unshift(lastMatchedNutritions[0])
        }
      }
    }
    // }
    this.setState({fitbitLogs: fitbitLogs.sort((a, b) => moment(b.date).diff(a.date))})
  }

  render(){
    return(
      <div className='food-log-page flex flex-wrap'>
        {this.state.fitbitLogs && this.state.fitbitLogs.map(log => <FoodLogCard key={log._id} data={log}/> )}
      </div>
    )
  }
}

export default FoodLogHistoryPage;