import React from 'react';
import RecipeSearch from './RecipeSearch';
import { RecipeCard } from './RecipeCard';
import { Pagination } from 'antd';
import { dietTypes, allergies, mealTypes } from '../../data/recipeFilter';
import RecipeModal from '../../components/RecipeModal';
import { capitalize } from '../../helpers/utils';
import { Loading } from '../../components/Loading';
import { toast } from 'react-toastify';
import './index.css';

class RecipesPage extends React.Component {
  constructor(){
    super();
    this.recipeSectionRef = React.createRef();
    this.state={
      dietTypes: dietTypes,
      allergies: allergies,
      mealTypes: mealTypes,
      filters: null,
      calorieFilter: {},
      fromTo: '&from=0&to=30',
      paginationPageNumber: 1,
      recipeModalOpen: false,
      currentRecipe: null,
      favoriteRecipes: []
    }
  }

  componentDidMount(){
    if(this.props.location.state && this.props.location.state.meal){
      const meals = [
        { key: 1, type: 'mealType', label: 'Breakfast', value: 'Breakfast'},
        { key: 2, type: 'mealType', label: 'Lunch', value: 'Lunch'},
        { key: 3, type: 'mealType', label: 'Dinner', value: 'Dinner'},
        { key: 4, type: 'mealType', label: 'Snack', value: 'Snack'}
      ];
      let mealType = capitalize(this.props.location.state.meal.mealTitle);
      meals.map(meal => {
        if(meal.label ===  mealType){
          this.handleCheckbox('mealType', meal);
        }
      })
    }
    this.setState({favoriteRecipes: this.props.favoriteRecipes})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favoriteRecipes.length > 0) {
      if (this.props.favoriteRecipes.length > prevProps.favoriteRecipes.length) {
        this.showToastNotification('Recipe added to favorites!');
      } else if (this.props.favoriteRecipes.length < prevProps.favoriteRecipes.length) {
        this.showToastNotification('Recipe removed from favorites!');
      }
    }
    if (this.props.recipes !== prevProps.recipes) {
      this.recipeSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  showToastNotification = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  handleAddOrRemoveFavoriteRecipe = (recipe, action) => {
    const { favoriteRecipes } = this.state;
  
    // Check if the recipe is already in favoriteRecipes
    if (action === 'add' && favoriteRecipes.some(favoriteRecipe => favoriteRecipe === recipe)) {
      toast.warn('This recipe is already in your favorites!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  
    // Add or remove the recipe to favoriteRecipes array
    const updatedFavoriteRecipes = action === 'remove'
      ? favoriteRecipes.filter(favoriteRecipe => favoriteRecipe !== recipe)
      : [...favoriteRecipes, recipe];
  
    this.setState({ favoriteRecipes: updatedFavoriteRecipes });
    this.props.saveRecipeToFavorites(updatedFavoriteRecipes);
  }

  handleCheckbox = (group, value) => {
    let dietTypes= [...this.state.dietTypes];
    let allergies=[...this.state.allergies];
    let mealTypes=[...this.state.mealTypes];

    if(group === 'diet'){
      let chosen = dietTypes.find(diet => diet.key === value.key);
      chosen.checked = !value.checked;
      this.setState({dietTypes: dietTypes});
    } else if(group === 'allergy'){
      let chosen = allergies.find(allergy => allergy.key === value.key);
      chosen.checked = !value.checked;
      this.setState({allergies: allergies});
    } else {
      let chosen = mealTypes.find(mealType => mealType.key === value.key);
      chosen.checked = !value.checked;
      this.setState({mealTypes: mealTypes});
    }
  }

  clearAllFilters = () => {
    let dietTypes= [...this.state.dietTypes];
    let allergies=[...this.state.allergies];
    let mealTypes=[...this.state.mealTypes];

    dietTypes.forEach(diet => diet.checked = false);
    allergies.forEach(allergy => allergy.checked = false);
    mealTypes.forEach(mealType => mealType.checked = false);

    this.setState({
      dietTypes: dietTypes,
      allergies: allergies,
      mealTypes: mealTypes,
      calorieFilter: {}
    })
  }

  handleCalorieInput = (e, type) => {
    let calorieFilter = {...this.state.calorieFilter};
    calorieFilter[type] = e.target.value !== "" ? Math.abs(e.target.value) : "";
    this.setState({
      calorieFilter: calorieFilter
    });
  } 

  getFiltersBeforeSearch = () => {
    let calorieFilter = {...this.state.calorieFilter};
    let urlFilterForCalories;
    if(calorieFilter.hasOwnProperty('from') && calorieFilter.from !== "" && calorieFilter.hasOwnProperty('to') && calorieFilter.to !== ""){
      //if there is from and to and values are not empty
      urlFilterForCalories = `&calories=${calorieFilter.from}-${calorieFilter.to}`
    } else if(calorieFilter.hasOwnProperty('from') && calorieFilter.from !== ""){
      urlFilterForCalories = `&calories=${calorieFilter.from}%2B`
    } else if(calorieFilter.hasOwnProperty('to') && calorieFilter.to !== ""){
      urlFilterForCalories = `&calories=${calorieFilter.to}`
    } else {
      urlFilterForCalories = null
    }

    let dietFilters = this.state.dietTypes.filter(diet => diet.checked === true);
    let mealTypeFilters = this.state.mealTypes.filter(mealType => mealType.checked === true);
    let allergiesFilters = this.state.allergies.filter(allergy => allergy.checked === true);
    let filters = [...dietFilters, ...allergiesFilters, ...mealTypeFilters];
    let urlFilterForDietObj = filters.filter(filter => filter.type === 'diet');
    let urlFilterForDietStr =  urlFilterForDietObj.length > 0 ? `&diet=${urlFilterForDietObj.map(filter => filter.value).join('&')}` : null;
    let urlFilterForHealthObj = filters.filter(filter => filter.type === 'health');
    let urlFilterForHealthStr = urlFilterForHealthObj.length > 0 ? `&health=${urlFilterForHealthObj.map(filter => filter.value).join('&')}` : null;
    let urlFilterForMealTypeObj = filters.filter(filter => filter.type === 'mealType');
    let urlFilterForMealTypeStr = urlFilterForMealTypeObj.length > 0 ? `&mealType=${urlFilterForMealTypeObj.map(filter => filter.value).join('&')}` : null;
    this.setState({
      urlFilterForDietStr: urlFilterForDietStr,
      urlFilterForHealthStr: urlFilterForHealthStr,
      urlFilterForMealTypeStr: urlFilterForMealTypeStr,
      urlFilterForCalories: urlFilterForCalories,
      paginationPageNumber: 1
    });
    let queryObj = {
      searchTerm: this.props.recipeSearchTerm || '',
      nutrientsRange: this.props.recipeQueryObj && this.props.recipeQueryObj.nutrientsRange || null,
      fromTo: this.state.fromTo,
      healthFilter: urlFilterForHealthStr,
      dietFilter: urlFilterForDietStr,
      calorieFilter: urlFilterForCalories,
      mealTypeFilter: urlFilterForMealTypeStr
    }
    this.props.getRecipes(queryObj, null);
  }

  handlePagination = (page, pageSize) => {
    let fromTo = `&from=${pageSize * page - 30}&to=${pageSize * page}`;
    this.setState({ paginationPageNumber: page, fromTo: fromTo});
    let queryObj = {
      searchTerm: this.props.recipeSearchTerm || '',
      nutrientsRange: this.props.recipeQueryObj && this.props.recipeQueryObj.nutrientsRange || null,
      fromTo: fromTo,
      healthFilter: this.state.urlFilterForHealthStr,
      dietFilter: this.state.urlFilterForDietStr,
      calorieFilter: this.state.urlFilterForCalories,
      mealTypeFilter: this.state.urlFilterForMealTypeStr
    }
    this.props.getRecipes(queryObj, null);
  }

  openRecipeModal = (recipe) => {
    this.setState({
      recipeModalOpen: true,
      currentRecipe: recipe
    });
  }

  handleCancel = () => {
    this.setState({
      recipeModalOpen: false,
    });
  };

  clearIngredientsOnModalClose = () => {
    this.setState({currentRecipe: null})
  } 

  render(){
    return(
      <>
        {this.state.currentRecipe &&
        <RecipeModal 
          currentRecipe={this.state.currentRecipe}
          recipeModalOpen={this.state.recipeModalOpen} 
          handleCancel={this.handleCancel}
          saveGrocery={this.props.saveGrocery}
          // clearIngredientsOnModalClose={this.clearIngredientsOnModalClose}
        />}
        <>
          <div className='recipe-search-card'>
            <p className='home-card-title'>Search</p>
            <div>
              <RecipeSearch 
                {...this.props} 
                {...this.state}
                handleCalorieInput={this.handleCalorieInput}
                handleCheckbox={this.handleCheckbox}
                clearAllFilters={this.clearAllFilters}
                getFiltersBeforeSearch={this.getFiltersBeforeSearch}
              />
            </div>
          </div>
          <div>
            {this.props.favoriteRecipes.length > 0 && <p className='home-card-title'>Your Favorite Recipes</p>}
            <div className='meal-plan favorite-recipes'>
              {this.props.favoriteRecipes.length > 0 &&
                this.props.favoriteRecipes.map((recipeId) => {
                  const recipe = this.props.favoriteRecipesFromEdemam.find((result) => result.uri === recipeId);
                  return (
                    recipe && (
                      <RecipeCard
                        favoriteRecipe={true}
                        openRecipeModal={this.openRecipeModal}
                        key={recipe.uri}
                        recipe={recipe}
                        handleAddOrRemoveFavoriteRecipe={this.handleAddOrRemoveFavoriteRecipe}
                      />
                    )
                  );
                })}
            </div>
          </div>
          <div ref={this.recipeSectionRef} className='meal-plan'>
            {this.props.recipes && this.props.recipes.hits.length > 0 && <p className='home-card-title'>Recipes</p>}
            {!this.props.loading ?
            this.props.recipes && this.props.recipes.hits.map(result => 
              <RecipeCard 
                openRecipeModal={this.openRecipeModal}
                key={result.label} 
                recipe={result.recipe}
                handleAddOrRemoveFavoriteRecipe={this.handleAddOrRemoveFavoriteRecipe}
              />) : <Loading />}
          </div>
          { this.props.recipes && this.props.recipes.hits.length > 0 &&
            <div className='recipe-pagination flex justify-center'>
              <Pagination 
                onChange={(page, pageSize) => this.handlePagination(page, pageSize)}
                defaultCurrent={this.state.paginationPageNumber} 
                total={ this.props.recipes.count < 100 && this.props.recipes.count || 100} 
                defaultPageSize={30}
              /> 
            </div> }
        </>
      </>
    )
  }
}

export default RecipesPage;