import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Tabs } from 'antd';
import { CLOSE_DRAWER, HANDLE_PRICING_MODAL } from '../../ducks/Global/constants';
import MeasurementForm from '../../presentational/MeasurementForm';
import ManualForm from '../../presentational/MeasurementForm/ManualForm';
import moment from 'moment';
import PersonalGoal from '../../assets/images/personal-goals.svg';
import { UpgradeButton } from '../UpgradeButton';

const { TabPane } = Tabs;

class SideDrawer extends React.Component {
  constructor(){
    super();
    this.state={
      tab: '1'
    }
  }

  renderTitle = () => {
    const { data, isMetric, weightObj } = this.props;
    if(this.props.data && this.props.role === 'admin'){
      return (
        <div>
          <p>{`${data.user.name} ${data.user.lastName}`}</p>
          <p>{`Last ${data.currentWeight} ${isMetric ? 'Kg' : 'Lb'}`}</p>
        </div>
      )
    } else {
      return (
        <div>
          <p>Add a new measurement</p>
          {weightObj && <p className='home-card-text' style={{fontWeight: '400'}}>{`Last updated on ${moment(weightObj.date).format('lll')}`}</p>}
        </div>
      )
    }
  }

  renderTabs = () => {
    return (
      <Tabs animated={false} defaultActiveKey="1" onChange={(e) => this.setState({tab: e})}>
        <TabPane tab="Manual" key="1">
         <ManualForm createOrUpdateTodo={this.props.createOrUpdateTodo} todo={this.props.todo} data={this.props.data} user={this.props.user}/>
        </TabPane>
        {this.props.user && this.props.user.role === 'admin' &&
        <TabPane tab="3-site" key="2">
          <MeasurementForm data={this.props.data} user={this.props.user}/>
        </TabPane>}
      </Tabs>
    ); 
  }

  render(){
    return (
      <Drawer
        title={this.renderTitle()}
        placement="right"
        closable={true}
        onClose={() => this.props.closeDrawer()}
        visible={this.props.showDrawer}
        maskClosable
        width={'350px'}
      >
        {this.props.user && this.props.user.isPro 
        ? this.renderTabs() 
        : 
        <div className='flex flex-center-aligned' style={{height: '400px'}}>
          <div style={{textAlign: 'center'}}>
            <img src={PersonalGoal} style={{height: '230px', marginBottom: '40px'}}/>
            <UpgradeButton action={this.props.handlePricingModal}/>
          </div>
        </div>
       
        }
      </Drawer>
    );
  }
};

const mapStateToProps = state => {
  return {
    showDrawer: state.global.showDrawer,
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeDrawer: () => {
			return dispatch({
					type: CLOSE_DRAWER
			 });
    },
    handlePricingModal: (pricingModalOpen) => {
      return dispatch({
        type: HANDLE_PRICING_MODAL,
        pricingModalOpen
      });
		},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);