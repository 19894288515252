import React from "react";
import { connect } from "react-redux";
import NutritionPreferencesPage from "../../presentational/NutritionPreferencesPage";
import { getNutritionPreferences, updateNutritionPreferences } from "../../ducks/NutritionPreference/actions";
import { Loading } from '../../components/Loading';
import { createNotification } from "../../ducks/Onesignal/actions";
import { getTodo, createOrUpdateTodo } from '../../ducks/Todo/actions';

class CustomFoodPreferences extends React.Component {

  componentDidMount() {
    this.props.getNutritionPreferences();
    this.props.getTodo();
  }

  componentWillReceiveProps(nextProps){
    if(this.props.nutritionPreferences && nextProps.nutritionPreferences && this.props.nutritionPreferences.hasBeenUpdated !== nextProps.nutritionPreferences.hasBeenUpdated){
      let todo = this.props.todo;
      todo.todo[0].completed = true;
      //call create or update todo
      this.props.createOrUpdateTodo(todo);
    }
  }

  render() {
    return (
      <div className='nutrition-preferences' style={{marginBottom: '50px'}}>
        {this.props.foodRestrictions && (this.props.nutritionPreferences || this.props.nutritionPreferences && !this.props.nutritionPreferences.hasBeenUpdated) ?
          <NutritionPreferencesPage 
            {...this.props}
            isCustomPlan={true}
            foodRestrictions={this.props.foodRestrictions}
          /> 
        : <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    successMessage: state.profile.successMessage,
    foodRestrictions: state.nutritionPreference.foodRestrictions,
    nutritionPreferences: state.nutritionPreference.nutritionPreferences,
    todo: state.todo.todo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNutritionPreferences: (restrictionsArr) => dispatch(updateNutritionPreferences(restrictionsArr)),
    getNutritionPreferences: () => dispatch(getNutritionPreferences()),
    createNotification: (input) => dispatch(createNotification(input)),
    getTodo: () => dispatch(getTodo()),
    createOrUpdateTodo: (todo) => dispatch(createOrUpdateTodo(todo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFoodPreferences);