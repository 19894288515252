import {
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_ERROR,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  SHOW_AUTH_MODAL,
  HIDE_AUTH_MODAL,
  HANDLE_TOKEN_SUCCESS,
  HANDLE_TOKEN_ERROR,
  VERIFY_USER_SUCCESS,
  VERIFY_USER, REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP, RESET_USER_VERIFIED, GET_ASSESSMENT_PROGRESS_USER_SUCCESS, GET_ASSESSMENT_PROGRESS_USER_ERROR, GET_ASSESSMENT_PROGRESS_USER, ASSESSMENT_USER, ASSESSMENT_USER_SUCCESS, ASSESSMENT_USER_FAIL

} from "./constants";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  errors: null,
  showAuthModal: false,
  authRedirectRoute: "",
  isRegistration: true,
  isUserVerified: false,
  assessmentProgress: {},
  assessmentSubmitLoader: false,
  assessmentSubmitError: null
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        // isAuthenticated: true,
        // isRegistration: false,
        isMailSent: payload.isMailSent,
        loading: false,
      };
    case REGISTER_USER_SUCCESS_WITH_GOOGLE_SIGNUP:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isRegistration: false,
        // isMailSent: payload.isMailSent,
        loading: false,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        loading: true,
      };
    case REGISTER_USER_FAIL:
    case LOGIN_USER_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...initialState,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.user,
      };
    case USER_LOADED_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
        loading: false
      };
    case SHOW_AUTH_MODAL:
      return {
        ...state,
        ...payload,
        showAuthModal: true,
      };
    case HIDE_AUTH_MODAL:
      return {
        ...state,
        showAuthModal: false,
      };
    case HANDLE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case VERIFY_USER:
      return {
        ...state,
        loading: true
      };

    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        user: action.payload && action.payload.user,
        isUserVerified: action.payload && action.payload.isVerified,
        isAuthenticated: true,
        isRegistration: false,
        loading: false,
        isMailSent: false
      };

    case HANDLE_TOKEN_ERROR:
      return {
        ...state,
        errors: action.error,
        isUserVerified: false,
        isAuthenticated: false,
      };

    case RESET_USER_VERIFIED:
      return {
        ...state,
        isUserVerified: false,
      };
    case GET_ASSESSMENT_PROGRESS_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ASSESSMENT_PROGRESS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        assessmentProgress: payload.assessment.assessment,
        error: null,
      };
    case GET_ASSESSMENT_PROGRESS_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ASSESSMENT_USER:
      return {
        ...state,
        assessmentSubmitLoader: true,
        assessmentSubmitError: null
      };
    case ASSESSMENT_USER_SUCCESS:
      return {
        ...state,
        assessmentSubmitLoader: false,
        assessmentSubmitError: null
      };
    case ASSESSMENT_USER_FAIL:
      return {
        ...state,
        assessmentSubmitLoader: false,
        assessmentSubmitError: payload
      };


    default:
      return state;
  }
}

export default auth;
