import React from 'react';
import { WorkoutProgramCard } from '../AdminWorkoutProgramLibraryPage/workoutProgramCard';
import WorkoutProgramModal from '../AdminWorkoutProgramLibraryPage/workoutProgramModal';
import CreateWorkoutModal from '../AdminWorkoutLibrary/createWorkoutModal';
import ExerciseHistoryModal from '../../components/ExerciseHistoryModal';
import { ConfirmModal } from '../../components/Modal/ConfirmModal';
import './index.css';

class UserWorkoutProgramsPage extends React.Component {

  render(){
    let isEndWorkout = ((this.props.timerRunning && this.props.isStopWorkout) || this.props.isStopWorkout);
    return (
    <div className="admin-workout">
      <ConfirmModal 
        isMaskCloseable={false}
        closable={false}
        isEndWorkout={isEndWorkout}
        title={isEndWorkout ? 'End Workout' : 'Start Workout'}
        subtitle={isEndWorkout ? 
        <div style={{textAlign: 'center', color: 'black'}}>
          <p>If you end the workout, it means you have completed all the exercises and sets, and the workout will be saved.</p>
          <br/>
          <p>If you abort this workout, your inputs will not be saved, and the workout information will be lost.</p>
        </div>
        : 'Would you like to start your workout now?'} 
        btn={isEndWorkout ? {confirm: 'End', cancel: 'Cancel', abort: 'Abort'} : {confirm: 'Start', cancel: 'Cancel'}}
        btnStyle={isEndWorkout ? {width: '100%'} : {width: '50%'}}
        isVisible={this.props.confirmModalVisible}
        action={this.props.handleConfirmModalAction}
        abort={this.props.abortWorkout} //Should reset everything and not save anything
        cancel={this.props.toggleConfirmModal}
      />
      <ExerciseHistoryModal
        toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
        exerciseHistoryModalVisible={this.props.exerciseHistoryModalVisible}
        currentExercise={this.props.currentExercise}
      />
      <CreateWorkoutModal 
        visible={this.props.createWorkoutModalVisible}
        workout={this.props.currentWorkout}
        user={this.props.user}
        toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
        toggleExerciseHistoryModal={this.props.toggleExerciseHistoryModal}
        isView={true}
        setExercise={this.props.setExercise}
        isUserGenerated={true}
        toggleConfirmModal={this.props.toggleConfirmModal}
        toggleWorkoutTimerRunning={this.props.toggleWorkoutTimerRunning}
        timerRunning={this.props.timerRunning}
        stopWorkout={this.props.stopWorkout}
        workoutInProgress={this.props.workoutInProgress}
        shouldResetTimer={this.props.shouldResetTimer}
        onTimerReset={this.props.resetTimer}
        updateTimerValue={this.props.updateTimerValue}
      />
      <WorkoutProgramModal 
        visible={this.props.workoutProgramModalVisible}
        workoutProgram={this.props.currentWorkoutProgram}
        toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
        toggleCreateWorkoutModal={this.props.toggleCreateWorkoutModal}
        showWorkoutPreview={this.props.showWorkoutPreview}
        isView={true}
        isUserWorkout={true}
        isUserWorkoutProgram={true}
        setCurrentWorkout={this.props.setCurrentWorkout}
      />
      <div style={{cursor: 'pointer'}} className='exercise-card-container'>
        {this.props.userWorkoutPrograms.map(workoutProgram => 
        <WorkoutProgramCard 
          key={workoutProgram._id}
          isUserWorkoutProgram={true}
          toggleWorkoutProgramModal={this.props.toggleWorkoutProgramModal}
          workoutProgram={workoutProgram.workoutProgram} 
          setCurrentWorkoutProgram={this.props.setCurrentWorkoutProgram}
        />)}
      </div>
    </div>
    )
  }
}

export default UserWorkoutProgramsPage;