import Diet from '../assets/images/diet.svg';
import EmptyCart from '../assets/images/empty-cart.svg';
import Progress from '../assets/images/progress-splash.svg';
import ProgramImage from '../assets/images/program-image.png';
import Hamburger from '../assets/images/hamburger.svg';

const splashPage = 
{
  diets: {
    title: 'Diets',
    pro: true,
    description: 'Understand the diet type you selected',
    benefits: ['Keto', 'Standard Western', 'Paleo', 'Vegan', 'Vegetarian'],
    img: Diet,
    list: ['Absolutely everything you need to know about the diet type you selected']
    // background: 'linear-gradient(to right, rgb(178, 36, 190), rgb(133, 55, 206))'
  },  
  methods: {
    title: 'Eating Methods',
    description: 'Learn and effortlessly implement two of the most popular eating methods based on your macronutrient results.',
    benefits: ['Never plateau again', 'Learn how to carb cycle for fat loss', 'Learn intermittent fasting', 'Clear instructions for each method', 'Personalized for you'],
    img: Diet,
    list: ['Intermittent fasting', 'Carb cycling']
    // background: 'linear-gradient(to right, rgb(178, 36, 190), rgb(133, 55, 206))'
  },
  lifestyle: {
    title: 'Lifestyle Guides',
    description: 'Learn and effortlessly implement two of the most popular eating methods based on your macronutrient results.',
    benefits: ['Daily motivation', 'Informative guides', 'Case studies', 'Tips & Tricks'],
    img: Diet,
    list: ['Sleep', 'Social', 'Stress Management', 'Anxiety', 'Relationships']
    // background: 'linear-gradient(to right, rgb(178, 36, 190), rgb(133, 55, 206))'
  },
  workout: {
    title: 'Workouts',
    description: 'Access to my LIVE workouts and let\'s sweat together!',
    benefits: ['Live workouts for all levels', 'Home and Gym workouts', 'New workouts every week'],
    img: Diet,
    list: ['Home & Gym workouts', 'Strength training & Fat burning workouts']
  },
  macros: {
    title: 'Macronutrient Report',
    description: 'Access your full macronutrient report. An easy guide to learn and implement your results.',
    benefits: ['Detailed explanation about your results', 'Portion control guide', 'Guidance on how to implement your numbers'],
    img: Diet,
    list: ['Learn everything you need to know about macronutrients', 'Get automatic updates based on your profile']
  },
  shoppingList: {
    title: 'Shopping List',
    buttonText: 'Search recipes',
    path: '/dashboard/nutrition',
    description: 'Add recipes to your shopping list and easily access and manage the ingredients for each.',
    img: EmptyCart,
    list: ['Create a list from a recipe you like', 'Create a custom shopping list from friendly foods', 'Save your lists and open them when you shop']
  },
  nubodSmartCoach: {
    title: 'Nubod Coach',
    description: 'Add recipes to your shopping list and easily access and manage the ingredients for each.',
    img: EmptyCart,
    list: ['Create a list from a recipe you like', 'Create a custom shopping list from friendly foods', 'Save your lists and open them when you shop']
  },
  progress: {
    title: 'Progress',
    description: 'Keep track of your progress and update your nutrition needs automatically every time you record a new weight.',
    img: Progress,
    list: ['Keep track of your weight and body fat', 'Add progress pictures and compare with past dates', 'Update your macronutrient needs automatically when updating your weight', 'Keep a list of all your logs']
  },
  recipes: {
    title: 'Recipes',
    description: 'With a large database of recipes, and up to 30 filters, your options are limitless.',
    img: Hamburger,
    list: ['Get recipe recommendations that match your macronutrients needs', 'Apply over 30 filters including meal type, diet preference, and allergies', 'Search for recipes based on calorie range']
  },
  programLanding: {
    title: 'A custom fitness program that changes with your body',
    subtitle: 'Fitness doesn\'t have to be hard. Join Nubod today to get the personalized, adaptable plan you need to succeed.',
    mainImage: ProgramImage,
    benefits: [
      {icon: '👍', title: '100% personalized', text: 'A coach builds your personalized program based on your own personal needs and goals. No one-fits-all programs here.'},
      {icon: '🗓', title: 'Updated every 2 weeks', text: 'Your program is adjusted every two weeks or when necessary so you never plateau.'},
      {icon: '🪄', title: 'Alternative foods', text: 'Nubod\'s alternative foods feature allows you to switch foods based on what you have available and/or your preferences.'},
      {icon: '🛒', title: 'Smart shopping list', text: 'Select the days you\'d like to shop for and done! Items and quantities will be calculated and added to your list so you know exactly what and how much to shop for.'},
      {icon: '🍲', title: 'Delicious recipes', text: 'Access over 2+ million recipes and apply advanced filters such as calorie range, diet type, and allergies.'},
      {icon: '📉', title: 'Progress tracking', text: 'Keep track of your progress in one place, upload progress pictures, and easily compare them with any previous date.'},
      {icon: '🔔', title: 'Notifications', text: 'Get useful notifications and reminders to keep you motivated and accountable.'},
      {icon: '🥇', title: 'Top-notch coaching & support', text: 'Need help? We\'re a message away from getting personalized help when you need it.'},
      {icon: '⭐️', title: 'Guides', text: 'Long lasting results is what we work for. Our detailed guides will help you get there.'},
      {icon: '', title: 'Integrations', text: 'Integrate with apps such as Fitbit and Withings to track your macro compliance and log your weight.'},
      {icon: '📱', title: 'Weekly check-ins', text: 'Catch up with your coach for 30 minutes every week to go over your program, progress or anything else you\'d like to discuss.'},
      {icon: '💯', title: 'Nubod private community', text: 'Join Nubod\'s supportive community and interact with like-minded people.'}
    ],
    howItWorks: [
      {icon: '1.', title: 'Select a plan and sign up', text: 'If you have any questions about which plan might be best for you, just send us a message and we\'ll help you choose.'},
      {icon: '2.', title: 'Read the guides', text: 'Set yourself up for success by reading the nutrition and meal preparation guides while your coach builds your custom program.'},
      {icon: '3.', title: 'Take some pics', text: 'Take some progress pictures so you can see how your body changes over time.'},
      {icon: '4.', title: 'Receive your plan and start your transformation', text: 'Your plan will be ready within 24 hours from the date you sign up.'}
    ]
  },
  carbCycling: {
    title: 'Carb Cycling',
    description: 'Discover the Power of Carb Cycling.',
    img: ProgramImage,
    list: ['Tailored to Your Macros: Plans crafted to perfectly align with your individual macronutrient requirements', 'Flexible Scheduling: Create and adjust your plan anytime, ensuring it always fits your changing lifestyle and goals', 'Customizable Carb Cycling: Easily modify rest days and high-carb days to optimize your diet for maximum efficiency', 'Adaptable Sensitivity Settings: Adjust for carb sensitivity, personalizing the plan to suit your body\'s unique response to carbohydrates']
  }
}
 
export { splashPage };