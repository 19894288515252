import axios from "axios";

export const fetchData = async (url, config, body) => {
  const response = await fetch(url, config, body);
  if (response.status >= 200 && response.status < 300) {
    return response.text().then(text => {
      try {
        //if response is not json, will throw and error and return text
        const data = JSON.parse(text);
        return data;
      } catch (err) {
        return text;
      }
    })
    // const data = await response.isJson() ? response.json() : response;
    // return data;
  } else {
    throw response;
  }
};

export const fetchAPI = (path, config, body) =>
  fetchData(
    `${process.env.REACT_APP_API_URL}/api${path}`,
    {
      ...config,
      headers: {
        "x-auth-token": window.localStorage.getItem("token"),
        ...(config || {}).headers,
      },
    },
    body
  );

export const postData = (url, config, body) => {
  return axios.post(url, body, config);
};
