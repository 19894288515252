import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { clearAddToShoppingListError } from '../../ducks/Suggestic/actions';
import './index.css';

class Toast extends React.Component {

  renderToast = () => {
    if(this.props.type === 'success'){
      toast.success(this.props.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if(this.props.type === 'warning'){
      toast.warning(this.props.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => this.props.clearAddToShoppingListError(null)
      });
    }
  }

  render(){
    return(
      <>
      {this.renderToast()}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearAddToShoppingListError: (val) => dispatch(clearAddToShoppingListError(val))
  }
}

export default connect(null, mapDispatchToProps)(Toast);