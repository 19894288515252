import { put, takeLatest, call, take, select } from "redux-saga/effects";
import {
  GET_SUGGESTIC_PROFILE,
  GET_RESTRICTIONS,
  UPDATE_SUGGESTIC_PROFILE,
  UPDATE_RESTRICTIONS,
  GET_SHOPPING_LIST,
  GET_SHOPPING_LIST_SUG,
  TOGGLE_SHOPPING_LIST_ITEM,
  ADD_TO_SHOPPING_LIST,
  ADD_TO_SHOPPING_LIST_SUG,
  DELETE_FROM_SHOPPING_LIST,
  CLEAR_SHOPPING_LIST,
  GET_USER_SHOPPING_LIST_AGGREGATE,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM,
  CLEAR_CUSTOM_SHOPPING_LIST,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS
} from "./constants";
import {
  getSuggesticProfileSuccess,
  getSuggesticProfileError,
  getRestrictionsSuccess,
  getRestrictionsError,
  updateSuggesticProfileSuccess,
  updateSuggesticProfileError,
  updateRestrictionsSuccess,
  updateRestrictionsError,
  getShoppingListSuccess,
  getShoppingListError,
  getShoppingListSugSuccess,
  getShoppingListSugError,
  toggleShoppingListItemSuccess,
  toggleShoppingListItemError,
  addToShoppingListSuccess,
  addToShoppingListError,
  addToShoppingListSugSuccess,
  addToShoppingListSugError,
  clearShoppingListSuccess,
  clearShoppingListError,
  getUserShoppingListAggregateSuccess,
  getUserShoppingListAggregateError,
  deleteFromShoppingListSuccess,
  deleteFromShoppingListError,
  updateCustomShoppingListItemSuccess,
  updateCustomShoppingListItemError,
  clearCustomShoppingListSuccess,
  clearCustomShoppingListError,
  clearCustomCheckedFoodItemsSuccess, clearCustomCheckedFoodItemsError
} from "./actions";
import { fetchData } from "../api";

export function* getSuggesticProfile(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/me`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getSuggesticProfileSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getSuggesticProfileError(error));
  }
}

export function* updateSuggesticProfile(){
  try {
    const url = '';
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(updateSuggesticProfileSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(updateSuggesticProfileError(error));
  }
}

export function* getRestrictions(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/getFoodRestrictionsList`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getRestrictionsSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getRestrictionsError(error));
  }
}

export function* updateRestrictions({restrictionsArr}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/updateFoodRestrictionsList`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({restrictions: restrictionsArr})
    };
    let payload = yield call(fetchData, url, config);
    yield put(updateRestrictionsSuccess(payload));
  } catch (err) {
    console.log(err)
    const error = yield err.json();
    console.log(error);
    yield put(updateRestrictionsError(error));
  }
}

export function* getShoppingList(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getShoppingListSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getShoppingListError(error));
  }
}

export function* getShoppingListSug(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/getUserShoppingList`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getShoppingListSugSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getShoppingListSugError(error));
  }
}

export function* getUserShoppingListAggregate(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/getUserShoppingListAggregate`;
    const token = window.localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token
      }
    };
    let payload = yield call(fetchData, url, config);
    yield put(getUserShoppingListAggregateSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(getUserShoppingListAggregateError(error));
  }
}

export function* toggleShoppingListItem({itemId, isAggregate}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/toggleShoppingListItem`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({itemId: itemId, isAggregate: isAggregate})
    };

    if(isAggregate){
      let shoppingListAggregate = yield select((state) => state.suggestic.shoppingListAggregate);
      let isDone = !shoppingListAggregate.edges.find(item => item.node.databaseId === itemId).node.isDone;
      shoppingListAggregate.edges.find(node => node.node.databaseId === itemId).node.isDone = isDone;
      yield call(fetchData, url, config);
      yield put(toggleShoppingListItemSuccess(shoppingListAggregate, isDone, itemId, isAggregate));

    } else {
      let shoppingList = yield select((state) => state.suggestic.shoppingList);
      let isDone = !shoppingList.edges.find(item => item.node.databaseId === itemId).node.isDone;
      shoppingList.edges.find(node => node.node.databaseId === itemId).node.isDone = isDone;
      yield call(fetchData, url, config);
      yield put(toggleShoppingListItemSuccess(shoppingList, isDone, itemId, isAggregate));
    }

  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(toggleShoppingListItemError(error));
  }
}

export function* updateCustomShoppingListItem({item}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList/${item._id}`;
    const token = window.localStorage.token;
    const config = {
      method: 'PUT',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(item)
    };
    let payload = yield call(fetchData, url, config);
    yield put(updateCustomShoppingListItemSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(updateCustomShoppingListItemError(error));
  }
}

export function* addToShoppingListSug({ids, allRecipes}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/addToShoppingList`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({recipeDatabaseIds: ids})
    };
    yield call(fetchData, url, config);
    yield put(addToShoppingListSugSuccess(allRecipes));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(addToShoppingListSugError(error));
  }
}


export function* addToShoppingList({foodItem}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(foodItem)
    };
    const payload = yield call(fetchData, url, config);
    yield put(addToShoppingListSuccess(payload));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(addToShoppingListError(error));
  }
}

export function* deleteFromShoppingList({id}){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList/${id}`;
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    yield call(fetchData, url, config);
    yield put(deleteFromShoppingListSuccess(id));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(deleteFromShoppingListError(error));
  }
}

export function* clearShoppingList(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/suggestic/clearShoppingList`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token
      }
    };
    yield call(fetchData, url, config);
    yield put(clearShoppingListSuccess());
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(clearShoppingListError(error));
  }
}

export function* clearCustomShoppingList(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList/clearShoppingList`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token
      }
    };
    yield call(fetchData, url, config);
    yield put(clearCustomShoppingListSuccess());
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(clearCustomShoppingListError(error));
  }
}

export function* clearCustomCheckedFoodItems(){
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/shoppingList/clearCheckedFoodItems`;
    const token = window.localStorage.token;
    const config = {
      method: 'POST',
      headers: {
        "x-auth-token": token
      }
    };
    yield call(fetchData, url, config);
    yield put(clearCustomCheckedFoodItemsSuccess());
  } catch (err) {
    const error = yield err.json();
    console.log(error);
    yield put(clearCustomCheckedFoodItemsError(error));
  }
}

export default function* suggesticSaga() {
  yield takeLatest(GET_SUGGESTIC_PROFILE, getSuggesticProfile);
  yield takeLatest(UPDATE_SUGGESTIC_PROFILE, updateSuggesticProfile);
  yield takeLatest(GET_RESTRICTIONS, getRestrictions);
  yield takeLatest(UPDATE_RESTRICTIONS, updateRestrictions);
  yield takeLatest(GET_SHOPPING_LIST, getShoppingList);
  yield takeLatest(GET_USER_SHOPPING_LIST_AGGREGATE, getUserShoppingListAggregate);
  yield takeLatest(TOGGLE_SHOPPING_LIST_ITEM, toggleShoppingListItem);
  yield takeLatest(ADD_TO_SHOPPING_LIST, addToShoppingList);
  yield takeLatest(CLEAR_SHOPPING_LIST, clearShoppingList);
  yield takeLatest(DELETE_FROM_SHOPPING_LIST, deleteFromShoppingList);
  yield takeLatest(ADD_TO_SHOPPING_LIST_SUG, addToShoppingListSug);
  yield takeLatest(GET_SHOPPING_LIST_SUG, getShoppingListSug); 
  yield takeLatest(UPDATE_CUSTOM_SHOPPING_LIST_ITEM, updateCustomShoppingListItem); 
  yield takeLatest(CLEAR_CUSTOM_SHOPPING_LIST, clearCustomShoppingList);
  yield takeLatest(CLEAR_CUSTOM_CHECKED_FOOD_ITEMS, clearCustomCheckedFoodItems);
}