import React from "react";
import { connect } from "react-redux";
import ProgramsPage from '../../presentational/ProgramsPage';
import { getCurrentProfile, getNutrition } from "../../ducks/Profile/actions";

class Programs extends React.Component {

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getNutrition();
  }

  render() {
    return (
      <div className="site-layout-background program-page">
        <ProgramsPage {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    nutrition: state.profile.nutrition
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    getNutrition: () => dispatch(getNutrition())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Programs);