import React from 'react';
import moment from 'moment';
import Avatar from '../assets/images/avatar.png';
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

export const feetToCm = (feet, inches) => {
  let totalInches = Number(feet * 12) + inches;
  let cm = Number(totalInches * 2.54);
  return cm.toFixed(2);
};

export const cmToFeet = (cm) => {
  let realFeet = (cm * 0.3937) / 12;
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  return {
    feet,
    inches,
  };
};

export const poundsToKgs = (pounds) => (pounds / 2.205).toFixed(2);

export const kgsToPounds = (kgs) => (kgs * 2.205).toFixed(2);

export const getValidationError = (response) => {
  if (response.msg) {
    return new Error(response.msg);
  }
  if (response.errors && response.errors[0]) {
    return new Error(response.errors[0].msg);
  }
  return new Error("Server Error");
};

export const getMacroLabelFromMacroDivision = (
  foodPreference,
  macroDivision
) => {
  const macroOptions = getMacroOptions(foodPreference);
  const filteredOption = macroOptions.filter((option) => {
    if (
      option.macros.protein &&
      option.macros.carb &&
      option.macros.fat &&
      macroDivision.protein &&
      macroDivision.carb &&
      macroDivision.fat
    ) {
      if (
        option.macros.protein === macroDivision.protein &&
        option.macros.carb === macroDivision.carb &&
        option.macros.fat === macroDivision.fat
      ) {
        return true;
      }
    }
  });
  return filteredOption.length > 0 ? filteredOption[0].label : "Custom";
};

export const getFoodPreferenceOptions = () => {
  return [
    {
      key: 1,
      text: "Anything",
      value: "anything",
      description:
        "No major preferences or restrictions. Will eat practically anything.",
    },
    {
      key: 2,
      text: "Keto",
      value: "keto",
      description: "A high-fat, very-low carbohydrate diet.",
    },
    {
      key: 3,
      text: "Paleo",
      value: "paleo",
      description: "Emphasizes meats, vegetables, and healthy fats.",
    },
    {
      key: 4,
      text: "Vegetarian",
      value: "vegetarian",
      description: "A plant-based diet, plus small amounts of eggs and dairy.",
    },
    {
      key: 5,
      text: "Vegan",
      value: "vegan",
      description: "All plant-based foods. No animal products of any kind.",
    },
  ];
};

export const getMacrosWithPreferenceAndOptionsName = (
  foodPreference,
  optionsName
) => {
  const options = getMacroOptions(foodPreference);
  const filteredOption = options.filter((option) => {
    if (optionsName === option.value) {
      return true;
    }
  });
  return filteredOption.length > 0 && filteredOption[0].macros
    ? filteredOption[0].macros
    : { protein: 30, carb: 35, fat: 35 };
};

export const getMealsPerDayOptions = () => {
  return [
    { key: 1, text: "One", value: 1 },
    { key: 2, text: "Two", value: 2 },
    { key: 3, text: "Three", value: 3 },
    { key: 4, text: "Four", value: 4 },
    { key: 5, text: "Five", value: 5 },
    { key: 6, text: "Six", value: 6 },
    { key: 7, text: "Seven", value: 7 },
    { key: 8, text: "Eight", value: 8 },
  ];
};

export const getSelectedOption = (
  optionsName,
  fieldName
) => {
  const options = getSelectOptions(fieldName);
  const filteredOption = options.find((option) => {
    if (optionsName === option.value) {
      return true;
    }
  });
  return filteredOption;
};

export const getSelectOptions = (fieldName) => {
  const options = {
    travel: [
      { key: '1', text: 'Rarely', value: 'Rarely' },
      { key: '2', text: '1 - 2 times a year', value: '1 - 2 times a year' },
      { key: '3', text: '3 - 4 times a year', value: '3 - 4 times a year' },
      { key: '4', text: 'Every month', value: 'Every month' },
      { key: '5', text: 'Every week', value: 'Every week' },
    ],
    mealsPrefer: [
      { key: '1', text: 'Fewer, larger meals', value: 'Fewer, larger meals' },
      { key: '2', text: 'More frequent, smaller meals', value: 'More frequent, smaller meals' },
      { key: '3', text: 'No preference', value: 'No preference' },
    ],
    happyEatingFoods: [
      { key: '1', text: 'I am happy eating the same foods frequently', value: 'I am happy eating the same foods frequently' },
      { key: '2', text: 'I like a lot of variety', value: 'I like a lot of variety' },
    ],
    riskFollowing: [
      { key: '1', text: 'None', value: 'None' },
      { key: '2', text: 'Testosterone deficiency', value: 'Testosterone deficiency' },
      { key: '3', text: 'Heart disease or stroke', value: 'Heart disease or stroke' },
      { key: '4', text: 'High blood pressure', value: 'High blood pressure' },
      { key: '5', text: 'Diabetes', value: 'Diabetes' },
      { key: '6', text: 'Osteoarthritis', value: 'Osteoarthritis' },
      { key: '7', text: 'Other', value: 'Other' },
    ],
    doStrengthTraining: [
      { key: '1', text: 'Yes', value: 'Yes' },
      { key: '2', text: 'No', value: 'No' },
    ],
    weightTraningexprience: [
      { key: 1, text: 'Beginner', value: 'Beginner', info: 'I\'ve never lifted weights, or just recently started.' },
      { key: 2, text: 'Intermediate', value: 'Intermediate', info: 'I have some experience with common strength training exercises' },
      { key: 3, text: 'Advanced', value: 'Advanced', info: 'I’ve been training for years but want to take it to the next level' }
    ],
    significantInjuries: [
      { key: '1', text: 'Yes', value: 'Yes' },
      { key: '2', text: 'No', value: 'No' },
    ],
    cardioType: [
      { key: '1', text: 'Running', value: 'Running' },
      { key: '2', text: 'Walking', value: 'Walking' },
      { key: '3', text: 'Elliptical', value: 'Elliptical' },
      { key: '4', text: 'Cycling', value: 'Cycling' },
      { key: '5', text: 'Rowing', value: 'Rowing' },
      { key: '6', text: 'Swimming', value: 'Swimming' },
      { key: '7', text: 'Other', value: 'Other' },
    ],
    equipment: [
      { key: '1', text: 'Barbells', value: 'Barbells' },
      { key: '2', text: 'Dumbbells', value: 'Dumbbells' },
      { key: '3', text: 'Gym machines', value: 'Gym machines' },
      { key: '4', text: 'Cable weights', value: 'Cable weights' },
      { key: '5', text: 'Resistance bands', value: 'Resistance bands' },
      { key: '6', text: 'Kettlebells', value: 'Kettlebells' },
      { key: '7', text: 'TRX bands', value: 'TRX bands' },
      { key: '8', text: 'No equipment', value: 'No equipment' },
      { key: '9', text: 'Other', value: 'Other' },
    ],
    timeForWorkout: [
      { key: '1', text: 'Less than 30 minutes', value: 'Less than 30 minutes' },
      { key: '2', text: '30 - 60 minutes', value: '30 - 60 minutes' },
      { key: '3', text: '60 - 90 minutes', value: '60 - 90 minutes' },
    ],
    workoutFrequency: [
      { key: '1', text: '2 - 3 times a week', value: '2 - 3 times a week' },
      { key: '2', text: '4 - 5 times a week', value: '4 - 5 times a week' },
      { key: '3', text: '6 - 7 times a week', value: '6 - 7 times a week' },
    ],
  }
  return options[fieldName];
}

export const getMacroOptions = (foodPreference) => {
  switch (foodPreference) {
    case "anything":
    case "vegetarian":
      return [
        {
          label: "Custom",
          macros: { custom: true },
          value: "custom"
        },
        {
          label: "Recommended",
          macros: { protein: 30, carb: 35, fat: 35 },
          value: "Recommended"
        },
        {
          label: "Low fat",
          macros: { protein: 30, carb: 50, fat: 20 },
          value: "Low fat"
        },
        {
          label: "Low carb",
          macros: { protein: 30, carb: 20, fat: 50 },
          value: "Low carb"
        }
      ];
    case "paleo":
      return [
        {
          label: "Custom",
          macros: { custom: true },
          value: "custom"
        },
        {
          label: "Recommended",
          macros: { protein: 30, carb: 35, fat: 35 },
          value: "Recommended"
        },
        {
          label: "Low carb",
          macros: { protein: 30, carb: 20, fat: 50 },
          value: "Low carb"
        }
      ];
    case "keto":
      return [
        {
          label: "Custom",
          macros: { custom: true },
          value: "custom"
        },
        {
          label: "Recommended",
          macros: { protein: 20, carb: 10, fat: 70 },
          value: "Recommended"
        }
      ];
    case "vegan":
      return [
        {
          label: "Custom",
          macros: { custom: true },
          value: "custom"
        },
        {
          label: "Recommended",
          macros: { protein: 30, carb: 50, fat: 20 },
          value: "Recommended"
        }
      ];
    default:
      return [];
  }
};

export const getWorkoutLocationOptions = () => {
  return [
    { key: "gym", text: "Gym", value: "gym" },
    { key: "home", text: "Home or outdoors", value: "home" },
    { key: "hybrid", text: "Hybrid", value: "hybrid" },
  ];
};

export const getDayDifference = (date1, date2) => {
  return Math.abs(moment(date1, "YYYY-MM-DD").diff(moment(date2, "YYYY-MM-DD"), 'days')) + 1
}

export const calculateMacrosForEachMeal = (nutrition, profile) => {
  if (nutrition && profile) {
    let calories = nutrition.goalCalories / profile.mealsPerDay
    let protein = nutrition.protein.grams;
    let carbs = nutrition.carbs.grams;
    let fats = nutrition.fats.grams;
  }
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const formatUserRole = (role) => {
  if (role === 'onsiteClient') {
    return "Onsite"
  } else if (role === 'admin') {
    return capitalize(role)
  } else {
    return "Online"
  }
}

export const formatUserStatus = (status) => {
  if (status === false) {
    return 'Free'
  } else {
    return 'Pro'
  }
}

export const renderClientNameEmail = (record, history) => {
  if (record.user && record.user.name) {
    return (
      <div
        className='flex flex-center-aligned space between'
        style={{ cursor: 'pointer' }}
        onClick={() => history.push({
          pathname: `/dashboard/admin/clients/${record.user._id}/profile/macros`,
          state: { userId: record.user._id }
        })}>
        <img style={{ height: '50px', borderRadius: '50%', marginRight: '10px' }} src={record.user.avatar || Avatar} />
        <div className='mt-1'>
          <p className='home-card-text'>{`${record.user.name} ${record.user.lastName || ''}`}</p>
          <a className='blue-link' href={`mailto:${record.user.email}`}>{record.user.email}</a>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export const formatDaysLeftForPlan = (daysLeft) => {
  if (!daysLeft) {
    return "-";
  } else if (daysLeft <= 2) {
    return (
      <div className='days-left capsule-red'>
        {`${daysLeft} days`}
      </div>
    )
  } else if (daysLeft > 2) {
    return (
      <div className='days-left capsule-green'>
        {`${daysLeft} days`}
      </div>
    )
  }
}

export const getEmptyPlanInRange = (s, e) => {
  for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a.push(moment(d).utc().format('YYYY-MM-DD'))
  }
  return a;
}

export const getRandomizeFood = (plan) => {
  let allDaysPlan = { ...plan };

  const dateArray = Object.keys(allDaysPlan);

  let firstDateArray = allDaysPlan[dateArray[0]];

  //Initializing Meal Title
  firstDateArray = firstDateArray.map((mealObj, mealIndex) => {
    if (mealObj.mealTitle === '') {
      return {
        ...mealObj,
        mealTitle: `Meal ${mealIndex + 1}`
      }
    }
    return mealObj;
  })

  dateArray.map((date) => {
    allDaysPlan[date] = firstDateArray;
  });

  const newPlan = { ...allDaysPlan };

  dateArray.forEach((dayObj, index) => {
    // ignore for first date
    if (index === 0) {
      return;
    }

    const newdayPlan = newPlan[dateArray[index - 1]].map((mealObj, mealIndex) => {
      const newFoodArr = mealObj.food.map((foodObj) => {
        const mainFood = foodObj && JSON.parse(JSON.stringify(foodObj));
        if (!mainFood && !foodObj) return;

        if (!foodObj.alternatives || foodObj.alternatives.length === 0) {
          return foodObj;
        }

        mainFood.alternatives.push({ ...foodObj, alternatives: null });
        const newMainFood = mainFood.alternatives.shift();
        newMainFood.alternatives = mainFood.alternatives;

        return newMainFood;
      });
      if (mealObj.mealTitle === '') {
        return {
          ...mealObj,
          food: newFoodArr,
          mealTitle: `Meal ${mealIndex + 1}`
        }
      } else {
        return {
          ...mealObj,
          food: newFoodArr
        };
      }
    })
    newPlan[dayObj] = newdayPlan;
  });

  return newPlan;
}

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const secondsToDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const shortenText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  // Subtract 3 from maxLength to make room for the ellipsis (...)
  const truncatedText = text.substring(0, maxLength - 3);

  // Add the ellipsis (...) at the end
  return truncatedText + '...';
}


export const starRating = (rating) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        // Render a filled star if the current star is less than or equal to the rating
        stars.push(<AiFillStar key={i} className="star" style={{color: 'gold'}}/>);
      }else {
        // Render an empty star if the current star is greater than the rating
        stars.push(<AiOutlineStar key={i} className="star" />);
      }
    }
    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export const formatNumberWithCommas = (number) => {
  return number !== null && number !== undefined ? number.toLocaleString() : '';
}

export const formatNumber = (number) => {
  return number % 1 === 0 ? number : number.toFixed(1);
}