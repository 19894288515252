import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  capitalize,
  getSelectedOption,
  getSelectOptions,
} from "../../helpers/utils";
import "./index.css";
import { Input, Divider, Spin, Alert, Button, Select, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineEdit } from "react-icons/ai";
import Avatar from "../../assets/images/avatar.png";
import { updateAssessmentProfile } from '../../ducks/Profile/actions';
import { updateClientMacros } from '../../ducks/Admin/actions';
import LocationInput from '../ShortAssessment/LocationInput';
const { Option } = Select;
const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class AssessmentProfilePage extends React.Component {
  constructor() {
    super();
    this.state = {
      assessmentData: null,
      inputFile: React.createRef(),
      errors: [],
    };
  }

  componentDidMount() {
    this.setState({
      assessmentData: this.props.assessmentProfile || {},
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.setState({
        assessmentData: this.props.profile || {},
      });
    }
  }

  renderHeader = () => {
    return (
      <div
        className="profile-header-background"
        style={{ background: "#2bffcc" }}
      ></div>
    );
  };

  handleProfileImageChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.props.updateUserProfile({ file: reader.result });
      };
    }
    e.target.value = "";
  };

  renderProfileImg = () => {
    return (
      <div className="flex flex-center-aligned">
        <div className="user-profile-wrap">
          <div
            className="user-profile-image"
            style={{
              background: `url(${this.props.profile.user.avatar || Avatar})`,
              minHeight: "100%",
              backgroundSize: "cover",
            }}
          />
          {this.props.updatingUserInfo ? (
            <Spin className="edit-profile-icon" indicator={antIcon} />
          ) : (
            <>
              <AiOutlineEdit
                className="edit-profile-icon"
                onClick={() => this.state.inputFile.current.click()}
              />
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={this.state.inputFile}
                style={{ display: "none" }}
                onChange={this.handleProfileImageChange.bind(this)}
              />
            </>
          )}
        </div>
        <p className="user-profile-title">
          {this.props.profile.user.name} {this.props.profile.user.lastName}
        </p>
      </div>
    );
  };

  renderInputFields = () => {
    return (
      <div className="form user-profile-details">
        <div className="form-heading profile">
          <p className="form-title">Profile details</p>
          {/* <p className="form-subtitle">
            If you need to change these fields, please{" "}
            <a href="mailto:coach@getnubod.com" target="_blank">
              contact us
            </a>
            .
          </p> */}
        </div>
        <div className="form-row flex space-between">
          <div className="form-section">
            <p className="label">Gender</p>
            <Input value={capitalize(this.props.profile.gender)} />
          </div>
          <div className="form-section">
            <p className="label">Age</p>
            <Input value={this.props.profile.age} />
          </div>
        </div>

        {this.props.profile.isMetric ? (
          <div>
            <div className="form-section">
              <p className="label">Height Cm.</p>
              <Input value={this.props.profile.height} />
            </div>
          </div>
        ) : (
          <div className="form-row flex space-between">
            <div className="form-section">
              <p className="label">Height Ft.</p>
              <Input value={this.props.profile.heightFt} />
            </div>
            <div className="form-section">
              <p className="label">Height In.</p>
              <Input value={this.props.profile.heightIn} />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderOptions = (fieldName) => {
    const options = getSelectOptions(fieldName);
    if (options) {
      const renderedOptions = options.map((item, index) => (
        // <Option className='select-option' key={index} value={JSON.stringify(item)}>
        <Option className="select-option" key={index} value={item.value}>
          {item.text}
        </Option>
      ));
      return renderedOptions;
    }
  };

  onCkChange = (checkedValues, fieldName) => {
    this.handleChange(
      {
        selectedOptions: checkedValues,
        otherDescription: this.state.assessmentData[fieldName]
          ? this.state.assessmentData[fieldName].otherDescription
          : null,
      },
      fieldName
    );
  };
  handleTXChange = (e, fieldName) => {
    this.handleChange(
      {
        selectedOptions: this.state.assessmentData[fieldName].selectedOptions,
        otherDescription: e.target.value,
      },
      fieldName
    );
  };

  renderCustomCheckbox = (assessmentData, fieldName) => {
    const options = getSelectOptions(fieldName);
    let [none, ...restOptions] = options;
    const checkbox =
      fieldName === "riskFollowing" ? (
        <>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(e) => this.onCkChange(e, fieldName)}
            value={
              this.state.assessmentData[fieldName]
                ? this.state.assessmentData[fieldName].selectedOptions
                : ""
            }
          >
            <Checkbox
              className="custom-checkbox"
              key={none.key}
              value={none.value}
            >
              {none.text}
            </Checkbox>
          </Checkbox.Group>
          <hr style={{ borderColor: "#E0E0E0", marginBottom: "15px" }}></hr>
          {this.state.assessmentData[fieldName] &&
          this.state.assessmentData[fieldName].selectedOptions.includes(
            none.value
          ) ? (
            ""
          ) : (
            <>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(e) => this.onCkChange(e, fieldName)}
                value={
                  this.state.assessmentData[fieldName] &&
                  this.state.assessmentData[fieldName].selectedOptions
                }
              >
                {restOptions.map((op) => (
                  <Checkbox
                    className="custom-checkbox"
                    key={op.key}
                    value={op.value}
                  >
                    {op.text}
                  </Checkbox>
                ))}
              </Checkbox.Group>
              {this.state.assessmentData[fieldName] &&
                this.state.assessmentData[fieldName].selectedOptions.includes(
                  "Other"
                ) && (
                  <TextArea
                    rows={3}
                    placeholder="Type here..."
                    className="ant-textarea"
                    onChange={(e) => this.handleTXChange(e, fieldName)}
                    value={
                      this.state.assessmentData[fieldName].otherDescription
                    }
                  />
                )}
            </>
          )}
        </>
      ) : (
        <>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(e) => this.onCkChange(e, fieldName)}
            value={
              this.state.assessmentData[fieldName] &&
              this.state.assessmentData[fieldName].selectedOptions
            }
          >
            {options.map((op) => (
              <Checkbox
                className="custom-checkbox"
                key={op.key}
                value={op.value}
              >
                {op.text}
              </Checkbox>
            ))}
          </Checkbox.Group>
          {this.state.assessmentData[fieldName] &&
            this.state.assessmentData[fieldName].selectedOptions.includes(
              "Other"
            ) && (
              <TextArea
                rows={3}
                placeholder="Type here..."
                className="ant-textarea"
                onChange={(e) => this.handleTXChange(e, fieldName)}
                value={this.state.assessmentData[fieldName].otherDescription}
              />
            )}
        </>
      );
    return checkbox;
  };

  renderInputField = (assessmentData, fieldName, label, type = "select") => {
    return assessmentData ? (
      <div className="form-section">
        <p className="assessment-question-text">*{label}</p>
        {fieldName === "currentLocation" ? (
          <LocationInput
            name="currentLocation"
            id="location"
            placeholder="Enter Location"
            type={type}
            onChange={(e) => this.handleChange(e, fieldName)}
            defaultValue={
              assessmentData[fieldName] && assessmentData[fieldName].location
            }
          />
        ) : type === "text" ? (
          <Input
            type={type}
            onChange={(e) => this.handleChange(e, fieldName)}
            value={
              assessmentData && assessmentData[fieldName]
                ? assessmentData[fieldName]
                : ""
            }
          />
        ) : type === "checkbox" ? (
          this.renderCustomCheckbox(assessmentData, fieldName)
        ) : (
          <Select
            onChange={(e) => this.handleChange(e, fieldName)}
            defaultValue={
              assessmentData && assessmentData[fieldName]
                ? assessmentData[fieldName].value
                : ""
            }
            className="select-input"
          >
            {this.renderOptions(fieldName)}
          </Select>
        )}
      </div>
    ) : null;
  };

  renderTextAreaField = (assessmentData, fieldName, label, type = "text") => {
    return assessmentData ? (
      <div className="form-section" style={{ width: "100%" }}>
        <p className="assessment-question-text">*{label}</p>
        <TextArea
          style={{ borderRadius: "10px" }}
          autoSize
          onChange={(e) => this.handleChange(e, fieldName)}
          value={
            assessmentData && assessmentData[fieldName]
              ? assessmentData[fieldName]
              : ""
          }
        />
      </div>
    ) : null;
  };

  renderAssessmentQuestions = () => {
    const { assessmentData } = this.state;
    return (
      <div className="edit-profile">
        <div className="form">
          <div className="grid1">
            <div className="form-heading">
              <p className="main-page-title">Assessment Questions</p>
              {/* <p className="form-subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
            </div>
            <div>
              <div className="form-row flex space-between">
                {/* {this.renderInputField(assessmentData, 'secondaryGoals', 'Secondary goals')} */}
                {this.renderTextAreaField(
                  assessmentData,
                  "secondaryGoals",
                  "What other secondary goals would you like to accomplish?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  assessmentData,
                  "explainReachGoals",
                  "If you had to think of the deep underlying reason as to why you want to reach these goals, how would you explain it to me?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(assessmentData, 'trainingActivity', 'Is there any activity outside of the training you would like to keep or add-in alongside the program? (Please explain why)')}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  assessmentData,
                  "supplements",
                  "Do you currently take any supplements? If so, please list them."
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderTextAreaField(
                  assessmentData,
                  "obstacleReachingFitnessGoals",
                  "What would you say is the biggest obstacle currently holding you back from reaching your fitness goals? This is very important, please give a detailed answer about what it is that you feel is holding you back."
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "travel",
                  "How much do you travel, either for work or pleasure?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "mealsPrefer",
                  "Do you prefer eating fewer, larger meals or more frequent, smaller meals?"
                )}
                {this.renderInputField(
                  assessmentData,
                  "happyEatingFoods",
                  "Are you happy eating the same foods frequently or do you like a lot of variety?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "riskFollowing",
                  "Are you at risk of any of the following?",
                  "checkbox"
                )}
                {this.renderInputField(
                  assessmentData,
                  "averageStepCount",
                  "What's your average daily step count?",
                  "text"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "doStrengthTraining",
                  "Do you currently do any type of strength training?"
                )}
                {this.renderInputField(
                  assessmentData,
                  "weightTraningexprience",
                  "How would you classify yourself in terms of weight training experience?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "significantInjuries",
                  "Do you have any significant injuries?"
                )}
                {this.renderInputField(
                  assessmentData,
                  "cardioType",
                  "Do you currently do any of the following types of cardio?",
                  "checkbox"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "equipment",
                  "What kind of equipment will you have access to? If you don’t have any equipment, that’s OK. We can create customized training programs based solely on body weight. Just select ‘No equipment’.",
                  "checkbox"
                )}
                {this.renderInputField(
                  assessmentData,
                  "timeForWorkout",
                  "How much time do you have for each workout?"
                )}
              </div>
              <div className="form-row flex space-between">
                {this.renderInputField(
                  assessmentData,
                  "workoutFrequency",
                  "How frequently are you able to workout out?"
                )}
                {this.renderInputField(
                  assessmentData,
                  "currentLocation",
                  "What location are you currently in?"
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className="form-section button-section">
            <div></div>
            <Button
              // onClick={() => this.calculateResultsFromProfile({ ...this.state.assessmentData })}
              onClick={() =>
                this.handleSaveBtnClick({ ...this.state.assessmentData })
              }
              type="primary"
              className="button"
              loading={this.props.loading}
              disabled={this.props.loading || this.state.errors.length}
            >
              Save
            </Button>
          </div>
          {!this.props.loading ? (
            <>
              {this.state.errors.length > 0 && (
                <Alert
                  type="error"
                  message={this.state.errors.map((err) => (
                    <p>{err}</p>
                  ))}
                />
              )}
              {this.props.error &&
                this.props.error.length &&
                !this.state.errors.length && (
                  <Alert
                    type="error"
                    message={
                      this.props.error.errors &&
                      Object.values(this.props.error.errors).map((err) => (
                        <p>{err.msg}</p>
                      ))
                    }
                  />
                )}
              {/* {this.props.successMessage && (
                <Alert
                  type="success"
                  message={this.props.successMessage}
                />
              )} */}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  handleChange = (e, field) => {
    let assessmentData = { ...this.state.assessmentData };
    switch (field) {
      case "riskFollowing":
      case "cardioType":
      case "equipment":
        let { selectedOptions = [], otherDescription = null } = e;
        selectedOptions = selectedOptions.filter((v) => v != "");
        if (!selectedOptions.includes("Other")) {
          otherDescription = null;
        }
        assessmentData[field] = { selectedOptions, otherDescription };
        break;
      case "travel":
      case "mealsPrefer":
      case "happyEatingFoods":
      case "doStrengthTraining":
      case "weightTraningexprience":
      case "significantInjuries":
      case "timeForWorkout":
      case "workoutFrequency":
        assessmentData[field] = getSelectedOption(e, field);
        break;
      case "currentLocation":
        assessmentData[field] = e;
        break;
      default:
        assessmentData[field] = e.target.value;
    }
    const errors = [];
    if (
      String(assessmentData.currentWeight).split(".")[1] &&
      String(assessmentData.currentWeight).split(".")[1].length > 1
    ) {
      errors.push("Current weight: only 1 number after dot allowed");
    }
    if (!assessmentData.secondaryGoals) {
      errors.push("Please enter secondary goals");
    }
    if (!assessmentData.explainReachGoals) {
      errors.push("Please enter why you want to reach these goals");
    }
    if (!assessmentData.trainingActivity) {
      errors.push("Please enter any activity outside of the training");
    }
    if (!assessmentData.supplements) {
      errors.push("Please enter supplements if you take any");
    }
    if (!assessmentData.obstacleReachingFitnessGoals) {
      errors.push(
        "Please enter obstacle holding you back from reaching your goals"
      );
    }
    if (
      assessmentData &&
      assessmentData.riskFollowing &&
      !assessmentData.riskFollowing.selectedOptions.length
    ) {
      errors.push("Please select option for risk following");
    } else if (
      assessmentData &&
      assessmentData.riskFollowing &&
      assessmentData.riskFollowing.selectedOptions &&
      assessmentData.riskFollowing.selectedOptions.includes("Other") &&
      (!assessmentData.riskFollowing.otherDescription ||
        (assessmentData.riskFollowing.otherDescription &&
          !assessmentData.riskFollowing.otherDescription.trim()))
    ) {
      errors.push("Please enter other description for risk following");
    }
    if (!assessmentData.averageStepCount) {
      errors.push("Please enter your average daily step count");
    }
    if (
      assessmentData.cardioType &&
      !assessmentData.cardioType.selectedOptions.length
    ) {
      errors.push("Please select cardio type");
    } else if (
      assessmentData.cardioType &&
      assessmentData.cardioType.selectedOptions &&
      assessmentData.cardioType.selectedOptions.includes("Other") &&
      (!assessmentData.cardioType.otherDescription ||
        (assessmentData.cardioType.otherDescription &&
          !assessmentData.cardioType.otherDescription.trim()))
    ) {
      errors.push("Please enter other description for cardio type");
    }
    if (
      assessmentData.equipment &&
      !assessmentData.equipment.selectedOptions.length
    ) {
      errors.push("Please select equipment type");
    } else if (
      assessmentData.equipment &&
      assessmentData.equipment.selectedOptions &&
      assessmentData.equipment.selectedOptions.includes("Other") &&
      (!assessmentData.equipment.otherDescription ||
        (assessmentData.equipment.otherDescription &&
          !assessmentData.equipment.otherDescription.trim()))
    ) {
      errors.push("Please enter other description for equipment");
    }
    if (!assessmentData.currentLocation) {
      errors.push("Please enter your current location");
    }
    this.setState({
      assessmentData: assessmentData,
      minCaloriesDay: this.calculateResultsFromProfile(assessmentData, true),
      errors,
    });
  };

  calculateResultsFromProfile = (profileData, onlyGetCalories) => {
    let nutrition = [];

    let cm = profileData.isMetric
      ? profileData.height
      : Number(profileData.heightFt) * 30.48 +
        Number(profileData.heightIn) * 2.54;
    let kg = !profileData.isMetric
      ? (Number(profileData.currentWeight) / 2.205).toFixed(2)
      : Number(profileData.currentWeight);

    let bmr = Math.round(
      profileData.gender === "male"
        ? 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) + 5
        : 10 * Number(kg) + 6.25 * cm - 5 * Number(profileData.age) - 161
    );

    let maintenanceCal = Math.round(
      bmr * Number(profileData.activityLevel.value)
    );
    let calculatedGoalCalories = 0;
    let days = 0;

    if (onlyGetCalories && !profileData.byWhen) {
      return days;
    }

    //When goal is other than losing weight or gaining muscle
    let deficitPercentage = 0.1;
    if (profileData.deficit) {
      deficitPercentage = profileData.deficit / 100;
    }
    // if (profileData.goal.value === "Athletic performance") {
    //   let surplusPerDay = maintenanceCal * 0.1; //10% extra from maintenance
    //   calculatedGoalCalories = maintenanceCal + surplusPerDay;
    // } else
    if (profileData.goal.value === "Lose body fat") {
      let deficitPerDay = maintenanceCal * deficitPercentage;
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (profileData.goal.value === "Gain muscle") {
      let deficitPerDay = maintenanceCal * deficitPercentage;
      calculatedGoalCalories = maintenanceCal + deficitPerDay;
    } else if (profileData.goal.value === "Body recomposition") {
      let deficitPerDay = maintenanceCal * 0.1; //10% below maintenance
      calculatedGoalCalories = maintenanceCal - deficitPerDay;
    } else if (profileData.goal.value === "Improve overall health") {
      calculatedGoalCalories = maintenanceCal;
    }

    // let nutritionResults = {
    //   goalCalories: Math.round(calculatedGoalCalories),
    //   maintenanceCalories: Math.round(maintenanceCal),
    //   protein: {
    //     grams: Math.round((calculatedGoalCalories * (profileData.macros.protein / 100)) / 4),
    //     displayName: "Protein",
    //   },
    //   carbs: {
    //     grams: Math.round((calculatedGoalCalories * (profileData.macros.carb / 100)) / 4),
    //     displayName: "Carbohydrates",
    //   },
    //   fats: {
    //     grams: Math.round((calculatedGoalCalories * (profileData.macros.fat / 100)) / 9),
    //     displayName: "Fats"
    //   },
    // };
    // const totalIntake = nutritionResults.carbs.grams + nutritionResults.protein.grams + nutritionResults.fats.grams;
    // nutritionResults.carbs.percentage = (nutritionResults.carbs.grams * 4 / nutritionResults.goalCalories) * 100;
    // nutritionResults.protein.percentage = (nutritionResults.protein.grams * 4 / nutritionResults.goalCalories) * 100;
    // nutritionResults.fats.percentage = (nutritionResults.fats.grams * 9 / nutritionResults.goalCalories) * 100;
    // nutrition.push({ nutritionResults: nutritionResults });
    // !this.props.isAdminUpdate ? this.props.setNutritionFromProfileUpdate(nutrition[0]) : this.props.setClientNutritionFromProfileUpdate(nutrition[0]);
    /* !this.props.isAdminUpdate
      ? this.props.updateAssessmentProfile({ ...profileData })
      : this.props.updateClientMacros({ ...profileData }) */
    // Commented this for prevent auto save on change of every input.
  };

  handleSaveBtnClick = async (profileData) => {
    !this.props.isAdminUpdate
      ? this.props.updateAssessmentProfile({ ...profileData })
      : this.props.updateClientMacros({ ...profileData });
  };

  render() {
    return (
      <>
        <div className="user-profile-page">
          {this.props.profile && (
            <>
              {this.renderHeader()}
              <div className="site-layout-background user-profile-container" style={{marginBottom: '10px'}}>
                {this.renderProfileImg()}
                {this.renderInputFields()}
                {/* <Divider />
                {this.renderAssessmentQuestions()} */}
              </div>
              <div className="site-layout-background user-profile-container">{this.renderAssessmentQuestions()}</div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssessmentProfile: (payload) =>
      dispatch(updateAssessmentProfile(payload)),
    updateClientMacros: (clientProfile) =>
      dispatch(updateClientMacros(clientProfile)),
    // setNutritionFromProfileUpdate: (payload) => {
    //   return dispatch({
    //     type: SET_NUTRITION_FROM_PROFILE_UPDATE,
    //     payload
    //   });
    // },
    // setClientNutritionFromProfileUpdate: (payload) => {
    //   return dispatch({
    //     type: SET_CLIENT_NUTRITION_FROM_PROFILE_UPDATE,
    //     payload
    //   });
    // }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssessmentProfilePage));