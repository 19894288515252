import React from 'react';
import { connect } from 'react-redux';
import { capitalize } from '../../helpers/utils';
import { Button, Modal, Input, Alert, Select, AutoComplete, Divider, Checkbox, Popconfirm, Popover, Dropdown, Menu, Switch} from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import { createWorkout, updateLibraryWorkout, shareMyWorkout} from '../../ducks/Workout/actions';
import { getAllOnsiteClients } from '../../ducks/Profile/actions';
import { getExercises, saveExerciseHistory, getExerciseHistoryByIds, getExercisesHistoryByIdsArr} from '../../ducks/Exercise/actions';
import { IoIosArrowDown, IoIosArrowUp, IoIosInformationCircle, IoIosRemoveCircleOutline, IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineLineChart } from "react-icons/ai";
import { TbArrowsJoin2 } from "react-icons/tb";
import { RxDragHandleDots1 } from "react-icons/rx";
import { EllipsisOutlined } from '@ant-design/icons';
import { Loading } from '../../components/Loading';
import { CLEAR_EDIT_WORKOUT } from '../../ducks/Workout/constants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CountdownTimer } from '../../components/CountdownTimer';
import { WorkoutTimer } from '../../components/Timer/WorkoutTimer';
import { SET_WORKOUT_TO_GLOBAL_STATE } from '../../ducks/Global/constants';
import { SocialMediaShare } from '../../components/SocialMediaShare';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const Option = Select.Option;

class CreateWorkoutModal extends React.Component {
  constructor(){
    super();
    this.state = {
      filteredExercises: [],
      exercises: [],
      clients: [],
      filteredClients: [],
      notFound: [{key:'not found', name: "No data found"}],
      workout: {exercises: [], client:''},
      initialWorkoutExercise: [],
      showWorkoutDetails: true,
      createSuperset: false,
      exercisesSelectedForSuperset: [],
      supersetCodeArr: ['A'],
      currentSupersetCode: 'A',
      addNotesVisible: null,
      selectedExercise: null,
      autoCompleteAddExerciseValue: '',
      exerciseIdsArr: null,
      clientTemp: ''
    }
  }

  componentDidMount(){
    const { workout } = this.state;
    // if (workout && workout.exercises.length > 0) { //either a library workout or a workout assigned to the user
    //   workout.exercises.forEach((exercise) => {
    //     this.props.getExerciseHistoryByIds( //get the exercise history by user id and exercise id
    //       workout.client || this.props.user._id,
    //       exercise._id,
    //       true
    //     );
    //   });
    // }
   
    !this.props.clients && !this.props.isView && this.props.getAllOnsiteClients();
    this.setState({
      exercises: this.props.exercises,
      clients: this.props.clients || [],
      exerciseIdsArr: this.props.exerciseIdsArr || [],
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // debugger
    // if(prevProps.workout !== this.props.workout && !this.props.isWorkoutDraft) {
    //   const { workout } = this.props;
    //   let ids = []
    //   if (workout && workout.exercises.length > 0) {
    //     workout.exercises.forEach((exercise) => {
    //       ids.push(exercise._id)
    //       if(workout.client || this.props.user){
    //         this.props.getExerciseHistoryByIds(
    //           workout.client || this.props.user._id,
    //           exercise._id,
    //           true
    //         );
    //       }
    //     });
    //   this.props.getExercisesHistoryByIdsArr(workout.client, ids);
    // }}

   
    if(prevProps.createWorkoutSuccess !== this.props.createWorkoutSuccess && this.props.createWorkoutSuccess) {
      this.props.toggleCreateWorkoutModal(false);
      this.props.clearEditWorkout();
      // Check if the workout has a client and draft is set to false
      if (
        !this.props.isWorkoutDraft &&
        this.state.workout &&
        ((this.state.workout.hasOwnProperty("client") && this.state.workout.client) || 
        (this.props.userGeneratedWorkout && this.props.userGeneratedWorkout.hasOwnProperty('client')))
      ) {
        this.createHistoryObject((this.props.userGeneratedWorkout && this.props.userGeneratedWorkout.client) || null);
      }
      toast.success(`Workout has been created!`);
      this.setState({workout: {exercises: [], client: ''}, clientTemp: '', exercises: this.props.exercises, filteredExercises: this.props.exercises});
    }
    if(prevProps.exercisesHistorySuccess !== this.props.exercisesHistorySuccess && this.props.exercisesHistorySuccess) {
      toast.success(`Exercise History has been created!`);
    }
    if(prevState.workout.exercises !== this.state.workout.exercises) {
      this.state.workout.exercises.forEach(exercise => {
        this.calculateVolume(exercise);
      });
    }
    if(prevProps.workout !== this.props.workout){
      this.setState({workout: this.props.workout, initialWorkoutExercise: this.props.workout.exercises});
    }
    if(prevProps.exercises !== this.props.exercises){
      this.setState({exercises: this.props.exercises, filteredExercises: this.props.exercises});
    }
    if(prevProps.exercises.length > 0 && prevProps.exercises.length !== this.props.exercises.length){
      this.manageSetsForSelectedExercise(this.props.exercises[this.props.exercises.length - 1]);
    }
    if(prevState.selectedExercise !== this.state.selectedExercise) {
      this.handleExerciseSelected(this.state.selectedExercise);
    }
    // if(!this.props.isWorkoutDraft){
      if(prevProps.exercisesHistory !== this.props.exercisesHistory){
        this.setExerciseHistoriesInWorkout();
      }
    // }
    //If the workout is a user generated workout, load the exercise data
    if(prevProps.workout !== this.props.workout && this.props.isUserGenerated) {
      this.getExerciseHistoryForMultipleExercises(this.props.user._id)
    }
    //If the workout is being created by the admin, get the history as the exercises are added. The issue is that the data loads and new sets or data is erased
    // if(prevState.workout.exercises.length !== this.state.workout.exercises.length && !this.props.isUserGenerated && this.state.workout.client){
    //   this.getExerciseHistoryForMultipleExercises(this.state.workout.client)
    // }
    if (JSON.stringify(this.state.workout.exercises) !== JSON.stringify(prevState.workout.exercises)) {
      this.updateVolume(prevState);
    }
  }

  updateVolume = (prevState) => { 
    let updatedExercises = false;

    // Create a copy of the current exercises
    const newExercises = this.state.workout.exercises.map(currentExercise => {
      // Find the corresponding exercise in the previous state by a unique identifier
      const prevExercise = prevState.workout.exercises.find(ex => ex._id === currentExercise._id);

      // If the exercise exists in the previous state and its data has changed
      if (prevExercise && JSON.stringify(currentExercise.data) !== JSON.stringify(prevExercise.data)) {
        // The data for this exercise has changed, calculate the new volume
        this.calculateVolume(currentExercise);
        updatedExercises = true;
      }
      return currentExercise;
    });

    // If any exercise was updated, update the state
    if (updatedExercises) {
      this.setState({
        workout: {
          ...this.state.workout,
          exercises: newExercises
        }
      });
    }
  }


  //RenderAutocomplete to search exercise
  renderExerciseSearchAutoComplete = (exercise) => {
    return (
      <div className="flex flex-center-aligned" style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <AutoComplete
            dataSource={
              this.state.filteredExercises
                ? this.state.filteredExercises.map(this.renderExerciseOptions)
                : this.state.notFound.map(this.renderNotFound)
            }
            style={{ width: "100%" }}
            onSearch={this.handleSearch}
            onSelect={(value, option) => {
              const selectedExercise = option.props.option;
              this.setState({ selectedExercise }, () => {
                this.handleExerciseSelected(selectedExercise);
              });
            }}
            optionLabelProp="name"
            disabled={this.props.getExercisesLoading}
            value={exercise.name || ""}
          />
        </div>
        {this.state.workout?.client &&
        <div onClick={() => this.handleExerciseDropdown({key: 'viewExerciseHistory'}, exercise)} className="add-exercise-button">
          <AiOutlineLineChart className="icon" />
        </div>}
        <Dropdown
          trigger={["click"]}
          overlay={() => this.exerciseMenu(exercise)}
          placement="bottomLeft"
          arrow
        >
          <div className="add-exercise-button">
            <EllipsisOutlined className="icon" />
          </div>
        </Dropdown>
        {/* {this.renderCountDownComponent(exercise)} */}
      </div>
    )
  }

  renderAddDeleteSet = (exercise, set) => {
    return (
      <div className="flex">
      {exercise.data[exercise.data.length - 1] === set && 
      (!this.props.isView || this.props.isUserGenerated) && (
        <>
          <div className="set-action-buttons flex flex-center-aligned">
            <div
              onClick={() => this.handleSet(exercise._id)}
              className="set-button flex"
            >
              <IoIosAddCircleOutline
                style={{ fontSize: "28px"}}
              />
            </div>
            <Popconfirm
              title="Delete this set?"
              onConfirm={() =>
                this.handleSet(exercise._id, true, set.set)
              }
              okText="Yes"
              cancelText="No"
            >
              <div className="set-button flex flex-center-aligned">
                <IoIosRemoveCircleOutline
                  style={{ fontSize: "28px", color: "red" }}
                />
              </div>
            </Popconfirm>
          </div>
        </>
      )}
      </div>
    )
  }

  renderExerciseNameOnView = (exercise) => {
    return (
      <div>
        <h4 className="exercise-name-isview ">{exercise.name}</h4>
        <div
          className="flex gradient-text"
          onClick={() => {
            this.props.toggleExerciseHistoryModal(true);
            this.props.getExerciseHistoryByIds(
              //this.props.user._id, is used for user generated workout
              this.state.workout.client || this.props.user._id,
              exercise._id,
              true
            );
            this.props.setExercise(exercise);
          }}
        >
          <AiOutlineLineChart className="icon" />
          <p className="mb-1">View History</p>
        </div>
      </div>
    )
  }

  renderExerciseAndSets = () => {
    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      // Reorder Items
      const items = Array.from(this.state.workout.exercises);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      //Reorder Initial Data
      const initialItems =JSON.parse(JSON.stringify(this.state.initialWorkoutExercise));
      const [reorderedInitialItem] = initialItems.splice(result.source.index, 1);
      initialItems.splice(result.destination.index, 0, reorderedInitialItem);


      this.setState({
        workout: {
          ...this.state.workout,
          exercises: items,
        },
        initialWorkoutExercise: initialItems
      });
    };

    const initData = this.state.initialWorkoutExercise
    const showInputValues = this.props.isView && !this.props.isUserGenerated

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="exercises">
          {(provided) => (
            <div 
              {...provided.droppableProps} 
              ref={provided.innerRef}
            >
              {this.state.workout.exercises.map((exercise, exerciseIndex) => (
                <Draggable
                  key={exercise.hasOwnProperty("_id") && exercise._id}
                  draggableId={exercise.hasOwnProperty("_id") && exercise._id}
                  index={exerciseIndex}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="flex flex-center-aligned form-section">
                        {this.state.createSuperset && (
                          <Checkbox
                            style={{ margin: "0 auto", marginRight: "5px"}}
                            onChange={() =>
                              this.setState({
                                exercisesSelectedForSuperset: [
                                  ...this.state.exercisesSelectedForSuperset,
                                  exercise,
                                ],
                              })
                            }
                          />
                        )}
          
                    <div className={`drag-icon ${this.props.isView ? 'hidden' : ''}`} {...provided.dragHandleProps}>
                      <RxDragHandleDots1 />
                    </div>
                  
                  {exercise.hasOwnProperty("supersetCode") && (
                    <Popconfirm
                      title="Unpair superset?"
                      onConfirm={() => this.manageSuperSetCode(exercise.supersetCode)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="superset-code mr-05">
                        <p>{exercise.supersetCode}</p>
                      </div>
                    </Popconfirm>
                  )}

                  {!this.props.isView ? 
                  this.renderExerciseSearchAutoComplete(exercise) : 
                  this.renderExerciseNameOnView(exercise)}
                </div>

                {this.renderExerciseNotes(exercise)}

                {this.props.exerciseHistoryLoading.exerciseId &&
                this.props.exerciseHistoryLoading.exerciseId === exercise._id &&
                this.props.exerciseHistoryLoading.loading ? (
                  <Loading />
                ) : (
                  exercise.data.map((set, setIndex) => (
                    <div>
                      <div className="flex flex-center-aligned space-between mb-1">
                        {Object.keys(set).map((key, index) => {
                          if (key !== "_id") {
                            return (
                              <div className="sets flex">
                                <Input
                                  className={`set-input ${key === "set" && "set"}`}
                                  key={index}
                                  // fall back to the next value in the chain if the current value is falsy, which includes null, undefined, empty strings, 0, NaN, and false.
                                  placeholder={initData[exerciseIndex]?.data?.[setIndex]?.[key] || set[key] || key}
                                  type="number"
                                  onChange={(e) => {
                                    this.handleExerciseData(
                                      exercise,
                                      e.target.value,
                                      key,
                                      set
                                    );
                                    this.calculateVolume(exercise);
                                  }}
                                  value={set[key] || ""}
                                  disabled={key === "set" || this.props.isUserGenerated && !this.props.workoutInProgress}
                                  addonBefore={key !== "set" && capitalize(key)}
                                />
                              </div>
                            );
                          }
                          return null;
                        })} 
                      </div>
                      {this.renderAddDeleteSet(exercise, set)}
                    </div>
                  ))
                )}
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    )}
      </Droppable>
    </DragDropContext>
  );
}; 

  calculateVolume = (exercise) => {
    let volume = 0;
    exercise.data.forEach(set => {
      if (set.load && set.reps) {
        volume += set.load * set.reps;
      }
    });
    exercise.notes.volume = volume;
  }
    
  handleExerciseSelected = async (selectedExercise) => {
    if (!selectedExercise) {
      return;
    }
  
    // Clear the error message
    this.setState({ error: '' });

    // Check if the exercise is already in the workout
    const exerciseExists = this.state.workout.exercises.some(
      (exercise) => exercise._id === selectedExercise._id
    );
   
  // If the exercise is already in the workout, don't add it again
    if (exerciseExists) {
      this.setState({ error: "Exercise already exists in the workout" }, () => {
      });
      return;
    }

    if (this.state.workout.client) {
      // If you have a function to fetch exerciseHistory by exercise ID and client ID, call it here and await the result
      await this.props.getExerciseHistoryByIds(
        this.state.workout.client, 
        selectedExercise._id, 
        false, 
        (exerciseHistory) => {
          // This callback function will be called after the exercise history is fetched and updated in the Redux store
          this.manageSetsForSelectedExercise(null, exerciseHistory);
        }
      );
    } else {
      this.manageSetsForSelectedExercise(selectedExercise);
    }
  }

  getExerciseHistoryForMultipleExercises = (clientId) => {
    const workout = this.props.isUserGenerated ? this.props.workout : this.state.workout;
    let ids;

    if(workout.exercises.length > 0){
      ids = workout.exercises.map(exercise => exercise._id);
      this.props.getExercisesHistoryByIdsArr(clientId, ids);
    } 
  }

  setExerciseHistoriesInWorkout = () => {
    const { workout } = this.state;
    const { exercisesHistory } = this.props;
    workout.exercises.forEach((exercise) => {
      const history = exercisesHistory[exercise._id];
      if (history) {
        exercise.data = history.lastHistory.sets;
        exercise.notes = history.lastHistory.notes;
        exercise.notes.pr = history.highestLoadSet;
      } else {
        exercise.data = [
          { set: 1, reps: "", load: "", rir: "" },
          { set: 2, reps: "", load: "", rir: "" },
          { set: 3, reps: "", load: "", rir: "" },
        ];
        exercise.notes = {
          notes: "",
          sets: "",
          reps: "",
          rest: "1:30 min",
          tempo: "3011",
          volume: "",
        };
      }
    });
    this.setState({ workout });
  };

  renderNotFound = (item) => {
    return (
      <Option key={item.key}>
        {item.name}
      </Option>
    );
  }

  renderInputField = (workout, fieldName, label, type = 'text') => {
    return (
      <div className="form-section">
       <p className="label">{`${fieldName === 'name' ? '*' : ''}${label}`}</p>
        {
          <Input
            type={type}
            onChange={(e) => this.setState({workout: {...this.state.workout, [fieldName]: e.target.value}})}
            value={
              workout && workout[fieldName]
                ? workout[fieldName]
                : ""
            }
          />
        }
      </div>
    );
  }

  renderSwitchField = (workout, fieldName, label) => {
    return (
      <div className="form-section">
        <p className="label">*{label}</p>
        <Switch
          className="table-mode-switch"
          onChange={(e) => this.setState({workout: {...this.state.workout, [fieldName]: e}})}
          checked={workout && workout[fieldName] ? workout[fieldName] : false}
          checkedChildren="Yes" 
          unCheckedChildren="No"
        />
      </div>
    );
  }

  handleSearch = (e) => {
    const exercises = [...this.state.exercises];
    let filtered;
    e === "" 
    ? filtered = [] 
    : filtered = exercises.filter(exercise => exercise.name.toLowerCase().includes(e.toLowerCase()));
    this.setState({filteredExercises: filtered})
  }

  filterClients = (e) => {
    const { clients } = this.props;
    let filtered;
    e === "" 
    ? filtered = clients 
    : filtered = clients.filter(client => (client.user && client.user.name.toLowerCase().includes(e.toLowerCase())) || (client.user && client.user.lastName && client.user.lastName.toLowerCase().includes(e.toLowerCase())));
    this.setState({filteredClients: filtered, clientTemp: e });
  }

  renderOption = (client) => {
    return (
      <Option key={client.user && client.user._id} name={`${client.user && client.user.name} ${client.user && client.user.lastName  ? client.user.lastName : ''}`}>
        {client.user && client.user.name} {client.user && client.user.lastName ? client.user.lastName : ''} - {client.user && client.user.email}
      </Option>
    );
  }

  renderClientField = (workout, label) => {
    const { clients } = this.props;
    const selectedClient = this.state.workout.client ? clients.filter(ele=>ele?.user?._id === this.state.workout.client): null
    const clientName = selectedClient ? `${selectedClient[0]?.user?.name} ${selectedClient[0]?.user?.lastName}` : null

    return (
      clients &&
      <div className="form-section">
        <div className='flex flex-center-aligned'>
          <p className="label">*{label}</p>
          {this.state.workout.client && <p className="label">-{this.state.workout.client}</p>}
        </div>
      
        <AutoComplete 
          dataSource={this.state.filteredClients && this.state.filteredClients.length >= 1 ? this.state.filteredClients.map(this.renderOption) : this.state.notFound.map(this.renderNotFound)}
          style={{ width: '100%' }}
          onSearch={(e) => this.filterClients(e)}
          onSelect={(value) => {this.setState({workout: {...this.state.workout, client: value}}); this.getExerciseHistoryForMultipleExercises(value)}}
          onChange={() =>  this.setState({workout: {...this.state.workout, client: ''}})}
          optionLabelProp="name"
          placeholder='Search client...'
          allowClear={true}
          value={clientName || this.state.clientTemp}
        />
      </div>
    )
  }
  
  manageSetsForSelectedExercise = (newExerciseAdded, exerciseHistory) => {
    let exercises = JSON.parse(JSON.stringify(this.state.exercises));
    let exercise = newExerciseAdded ? newExerciseAdded : exercises.find(exercise => exercise._id === this.state.selectedExercise._id);
    // if(!newExerciseAdded && Object.keys(this.props.exerciseHistory).length !== 0){
    if (!newExerciseAdded && exerciseHistory && Object.keys(exerciseHistory).length > 0) {
      let history = exerciseHistory.lastHistory;
      exercise.data = history.sets;
      exercise.notes = {
        ...history.notes,
        pr: exerciseHistory.highestLoadSet ? exerciseHistory.highestLoadSet : ''
      };
    } else {
      exercise.data = [{set: 1, reps: '', load: '', rir: ''}, {set: 2, reps: '', load: '', rir: ''}, {set: 3, reps: '', load: '', rir: ''}];
      exercise.notes = {
        notes: '',
        sets: Number(exercise.data.length),
        reps: '',
        rest: '1:30 min',
        tempo: '3011',
        volume: ''
      };
    }
    const workout = JSON.parse(JSON.stringify({...this.state.workout}))
    const initialWorkoutExercise = JSON.parse(JSON.stringify([...this.state.initialWorkoutExercise]))
    this.setState({workout: {...workout, exercises: [...workout.exercises, exercise]}});
    this.setState({initialWorkoutExercise: [...initialWorkoutExercise, exercise]});
  }

  handleSet = (exerciseId, isDelete, setNumber) => {
    let workout = JSON.parse(JSON.stringify(this.state.workout));
    let initialWorkoutExercise = JSON.parse(JSON.stringify(this.state.initialWorkoutExercise));
    let exerciseIndex = workout.exercises.findIndex(exercise => exercise._id === exerciseId);
    let tempExerciseIndex = initialWorkoutExercise.findIndex(exercise => exercise._id === exerciseId);
    const exercise = workout.exercises[exerciseIndex]
    const tempExercise = initialWorkoutExercise[tempExerciseIndex]

    if(isDelete){
      exercise.data.pop();
      // tempExercise.data.pop();
      exercise.notes.sets =  Number(exercise.data.length);
    } else {
      exercise.data.push({set: exercise.data.length + 1, reps: '', load: '', rir: '' });
      tempExercise.data.push({set: exercise.data.length + 1, reps: '', load: '', rir: '' });
      exercise.notes.sets =  Number(exercise.data.length);
    }

    workout.exercises[exerciseIndex] = exercise
    initialWorkoutExercise[exerciseIndex] = tempExercise
    this.setState({workout, initialWorkoutExercise});
  }

  renderExerciseOptions = (exercise) => {
    return (
      <Option option={exercise} key={exercise._id} name={`${exercise.name}`}>
        {exercise.name}
      </Option>
    );
  }

  handleExerciseData = (exercise, value, key, set) => {
    let workout = JSON.parse(JSON.stringify(this.state.workout));
    let currentExercise = workout.exercises.find(ex => ex._id === exercise._id);
    currentExercise.data.find(s => s.set === set.set)[key] = Number(value);
    this.setState({workout: workout})
  }

  handleSupersetCreation = () => {
    let supersetCodes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    let exercisesSelected = [...this.state.exercisesSelectedForSuperset];
    let workout = {...this.state.workout};
    let supersetCodeArr = [...this.state.supersetCodeArr];
    let currentSupersetCode = this.state.currentSupersetCode;

    //find current super sets
    workout.exercises.map(exercise => {
      // This will get an array with the superset codes that already exist. Need to get the code after the ones that exist
      if(exercise.hasOwnProperty('supersetCode')){
        supersetCodeArr.push(exercise.supersetCode)
        let current = supersetCodeArr[supersetCodeArr.length - 1];
        const currentIndex = supersetCodeArr.indexOf(current);
        const nextIndex = (currentIndex + 1) % supersetCodes.length;
        currentSupersetCode = supersetCodes[nextIndex];
        supersetCodeArr = [...new Set(supersetCodeArr)];
      }
    })

    //loop and assign superset code to exercises selected
    workout.exercises.map(exercise => {
      exercisesSelected.map(exerciseSelected => {
        if(exercise._id === exerciseSelected._id){
          exercise.supersetCode = currentSupersetCode;
        }
      })
    })

    this.setState({
      workout: workout, 
      createSuperset: false, 
      exercisesSelectedForSuperset: [], 
      currentSupersetCode: currentSupersetCode,
      supersetCodeArr: supersetCodeArr
    })
  }

  handleDeleteExercise = (exerciseId) => {
    let workout = {...this.state.workout};
    workout.exercises = workout.exercises.filter(exercise => exercise._id !== exerciseId);
    this.setState({workout: workout, selectedExercise: null});
  }

  handleAddExerciseNotes = (exerciseId, field, value) => {
    let workout = {...this.state.workout};
    let exercise = workout.exercises.find(exercise => exercise._id === exerciseId);
    if(field === 'sets'){
      if(exercise.data.length < value){
        for (let i = exercise.data.length; i < value; i++) {
          exercise.data.push({set: i + 1,  reps: '', load: '', rir: ''})
        }  
        exercise.notes[field] = Number(value);
      } else if(exercise.data.length > value){
        for (let i = exercise.data.length; i > value; i--) {
          exercise.data.pop({set: i - 1, reps: '', load: '', rir: ''})
        } 
        exercise.notes[field] = Number(value);
      }
      this.setState({workout: workout});
    } else if(field === 'reps'){
      exercise.notes[field] = Number(value);
      exercise.data.forEach(s => s.reps = Number(value));
      this.setState({workout: workout});
    } else {
      exercise.notes[field] = field === 'notes' ? value : Number(value);
      this.setState({workout: workout});
    }
  }

  handleNotesCard = (e, exerciseId) => {
    if(e.target.type === 'number' || e.target.type === 'text'){
      e.stopPropagation();
    } else if(exerciseId === this.state.addNotesVisible){
      this.setState({addNotesVisible: null});
    } else {
      this.setState({addNotesVisible: exerciseId});
    }
  }

  returnNoteInfo = (field, value) => {
    if(field === 'tempo'){
      let digits = value.toString().split('');
      let realDigits = digits.map(Number)
      return `Perform a ${realDigits[0]}-second muscle lengthening phase (eccentric contraction), rest for ${realDigits[1]} seconds at the bottom, complete a ${realDigits[2]}-second muscle shortening phase (concentric contraction), and hold the position for ${realDigits[3]} second at the top (isometric hold).`
    } 
    // if(field === 'rir'){
    //   return 'RIR stands for Repetitions in Reserve and is a tool used to assess the difficulty of an exercise set. It involves estimating the number of reps you could do before reaching total muscle failure. For instance, if you did 10 chest presses and rated the set with an RIR of 2, it means you believed you could only do 2 more reps with that weight.'
    // }
    if(field === 'pr'){
      return 'PR stands for Personal Record and is the highest weight you have lifted for a given exercise.'
    }
    if(field === 'volume'){
      return 'Volume is the total amount of work performed in a workout. It is calculated by multiplying the number of sets by the number of reps by the weight lifted. For example, if you did 10 sets of 10 reps with 100 pounds, your volume would be 10,000 pounds.'
    }
  }

  renderAddBeforeForNotes = (field) => {
    if(field === 'volume'){
      return  Object.keys(this.props.exerciseHistory).length > 0 ? `Last: ${this.props.exerciseHistory.volume}` : null
    } 
    // else if(field === 'pr'){
    //   return this.props.highestLoadSet ? `${moment(this.props.highestLoadSet.date).format('MM/DD/YY')}` : null
    // }
  }

  returnHighestLoadSetDate = (exerciseId) => {
    const { exercisesHistory , exerciseHistory } = this.props; 
    const highestLoadSet = exercisesHistory[exerciseId]?.highestLoadSet || exerciseHistory?.notes?.pr;

    if (highestLoadSet) {
      const date = moment(highestLoadSet.date).format('MM/DD/YY');
      const load = highestLoadSet.load || 0;
      return (
        <p>{`PR (${date})`} <span style={{color: '#FF0066'}}>{`${load} lbs`}</span></p>
      )
    }
    return 'PR -';
  }

  getLastVolume = (exerciseId) => {
    const { exercisesHistory, exerciseHistory } = this.props;
    const volume = exercisesHistory[exerciseId]?.lastHistory?.volume || exerciseHistory?.volume;
    return (
      volume !== undefined ?
      <p>Last vol <span style={{color: '#FF0066'}}>{volume}</span></p> : 'Last vol -'
    )
  }

  renderExerciseNotes = (exercise) => {

    // const calculateVolume = () => {
    //   let volume = 0;
    //   exercise.data.forEach(set => {
    //     if (set.reps && set.load) {
    //       volume += (set.reps * set.load);
    //     }
    //   });
    //   return volume;
    // };

   const { exercisesHistory, isUserGenerated, timerRunning, workoutInProgress } = this.props;
 
   return (
      <div 
        className='workout-details-card notes flex flex-center-aligned mb-1'
        style={{cursor: 'pointer', padding: '10px'}} 
        onClick={(e) => this.handleNotesCard(e, exercise._id)} 
      >
        <div className='flex flex-center-aligned space-between'>
        {isUserGenerated && !timerRunning && !workoutInProgress && <p className='exercise-notes-title text'>Exercise Info:</p>}
        {this.props.workoutInProgress || !this.props.isUserGenerated && !this.props.isView ? (
          <div className='workout-pr-volume'>
            <div className='text'>
              {exercisesHistory !== null ? this.returnHighestLoadSetDate(exercise._id) : ''}
            </div>
            <div className='text'>
             {exercisesHistory !== null ? this.getLastVolume(exercise._id) : ''}
            </div>
            {exercise.notes.volume ?
            <p className='text'>Current vol <span style={{color: '#FF0066'}}>{exercise.notes.volume}</span></p> : 
            <p className='text'>Current vol -</p>}
          </div>) : null
          }
          {this.state.addNotesVisible === exercise._id ?  <IoIosArrowUp /> :  <IoIosArrowDown />}
        </div>
       
        {this.state.addNotesVisible === exercise._id &&
        <div className='exercise-notes-columns mt-1'>
          {Object.entries(exercise.notes).map(([field, value]) => 
          field === 'rest' && !this.props.isView ? 
            <div>
              <p className="label">{capitalize(field)}</p>
              <Select
                defaultValue={value}
                onChange={(newValue, e) => {this.handleAddExerciseNotes(exercise._id, field, newValue); e.stopPropagation()}}
                style={{ width: '100%' }}
                onClick={(e) => e.stopPropagation()}
              >
                <Option value={30}>00:30 sec</Option>
                <Option value={45}>00:45 sec</Option>
                <Option value={60}>1:00 min</Option>
                <Option value={90}>1:30 min</Option>
                <Option value={120}>2:00 min</Option>
                <Option value={180}>3:00 min</Option>
              </Select> 
            </div> :
            <div>

              <div className='flex flex-center-aligned'>
                <p className="label">{capitalize(field)} {field === 'pr' && `- ${moment(value.date).format("MM/DD/YY")}`}</p>
                {field === 'volume' || field === 'tempo' || field === 'pr' ?
                <Popover content={this.returnNoteInfo(field, value)}>
                 <IoIosInformationCircle style={{margin: '0 0 3px 3px'}}/>
               </Popover> : null}
              </div>

              {!this.props.isView ?
              <Input 
                type={field === 'sets' || field === 'reps' || field === 'volume' || field === 'rest' ? 'number' : 'text'}
                onChange={(e) => this.handleAddExerciseNotes(exercise._id, field, e.target.value)}
                value={field === 'pr' ? value.load : value || ''}
                // value={field === 'pr' ? value.load : (field === 'volume' ? calculateVolume() : value) || ''}
                addonBefore={this.renderAddBeforeForNotes(field)}
              /> :
              <div>
                <p className='home-card-text'>{field === 'pr' ? value.load : value || '-'}</p>
              </div>}

            </div> 
          )}
          <div>
          </div>
        </div>}
     </div>
    )
  }

  exerciseMenu = (exercise) => {
    const { workout } = this.state;
    return (
      <Menu onClick={(e) => this.handleExerciseDropdown(e, exercise)}>
        {workout && workout.client &&
        <Menu.Item className='flex flex-center-aligned' key="viewExerciseHistory">
          <AiOutlineLineChart style={{marginRight: '3px', fontSize: '20px'}} />
          <p>View history</p>
        </Menu.Item>}
        <Menu.Item className='flex flex-center-aligned' key="deleteExercise">
         <MdDeleteOutline style={{marginRight: '3px', fontSize: '20px', color: 'red'}}/>
          <p>Delete Exercise</p>
        </Menu.Item>
      </Menu>
    )
  }

  handleExerciseDropdown = (e, exercise) => {
    if(e.key === 'viewExerciseHistory'){
      this.props.setExercise(exercise);
      this.props.getExerciseHistoryByIds(this.state.workout.client, exercise._id, true)
      this.props.toggleExerciseHistoryModal(true)
    } else if (e.key === 'deleteExercise') {
      this.handleDeleteExercise(exercise._id)
    }
  }

  manageSuperSetCode = (supersetCode) => {
    //remove superset codes from exercises that match the superset code of the exercise being passed in
    const { workout } = this.state;
    const exercises = workout.exercises.map(exercise => {
      if(exercise.supersetCode === supersetCode){
        delete exercise.supersetCode
      }
      return exercise;
    })
    this.setState({workout: {...workout, exercises: exercises}})
  }

  createHistoryObject = (client) => {
    let workout = {...this.state.workout};
    let exercises = [];

    //only saves if it's an assigned workout and not a library workout
    if((workout.hasOwnProperty('client') && workout.client) || client){
      workout.exercises.forEach(exercise => {
        let exerciseHistory = {
          exerciseId: exercise._id,
          clientId: workout.client || client,
          history: {
            volume: exercise.data.reduce((total,set) => total + (set.reps * set.load),0),
            sets: exercise.data,
            notes: exercise.notes
          }
        }
        exercises.push(exerciseHistory);
      });
      this.props.saveExerciseHistory(exercises, workout._id);
    }
  }

  handleCreateOrUpdateWorkout = (isDraft) => {
    let workout = {...this.state.workout};
    if(this.props.isWorkoutEdit && !workout.client){
      this.props.updateLibraryWorkout({
        ...this.state.workout,
        draft: isDraft
      });
    } else {
      //If the workout is a assigned to a client, calculate the volume, otherwise, don't calculate it
      if(workout.hasOwnProperty('client') && workout.client){
        workout.exercises.forEach(exercise => {
          exercise.notes.volume = exercise.data.reduce((total,set) => total + (set.reps * set.load),0);
        });
      }
      //This saves the workout either with a userId or as a template
      this.props.createWorkout({
        ...this.state.workout,
        draft: isDraft
      });
    }
    //Need to clear exercise from state when editing 2 workout drafts. Ex. 2 clients Training Together.
    this.setState({selectedExercise: null})
  }
  
  renderAddExercise = () => {
    return(
      !this.props.isView &&
      <div className='flex flex-center-aligned'>
      <AutoComplete 
        dataSource={this.state.filteredExercises ? this.state.filteredExercises.map(this.renderExerciseOptions) : this.state.notFound.map(this.renderNotFound)}
        style={{ width: '100%'}} 
        onSearch={value => {
          this.handleSearch(value)
          this.setState({autoCompleteAddExerciseValue: value})
        }}
        onSelect={(value, option) => {
          this.setState({selectedExercise: option.props.option, autoCompleteAddExerciseValue: ''})}}
        optionLabelProp="name"
        placeholder='Search exercise...'
        allowClear={true}
        value={this.state.autoCompleteAddExerciseValue}
      />
      <Popover content='New Exercise'>
        <div onClick={() => this.props.toggleCreateExerciseModal(true)} className='add-exercise-button'>
          <IoIosAddCircleOutline className='icon'/>
        </div>
      </Popover>
      </div>
    )
  }

  renderCountDownComponent = (exercise) => {
    return(
      <CountdownTimer duration={exercise.notes.rest}/>
    )
  }

  renderActionButtons = () => {
    return (
      !this.props.isView &&
      <div className='floating-btn-container'>
        <div className='flex flex-center-aligned floating-btn'>
          {this.state.workout.exercises.length >= 2 &&
          <Button 
            onClick={() => {this.state.createSuperset ? this.handleSupersetCreation() : this.setState({createSuperset: true})}} 
            className='circular-btn mb-2 mr-05' 
            style={{background: this.state.exercisesSelectedForSuperset.length > 1 ? '#ff0066' : '#2761f1'}}
          >
            <TbArrowsJoin2 style={{fontSize: '18px'}} />
          </Button>
          }
          {this.state.workout.exercises.length >= 2 && this.state.createSuperset === true && 
          <Button onClick={() => this.setState({createSuperset: false})} className='button mb-2'>X</Button>}
        </div>
      </div>
    )
  }

  handleShareWorkoutClick = async (e, preview) => {
    e.stopPropagation();
    try {
      if(preview){
        const previewUrl = `${process.env.REACT_APP_APP_URL}/share/my-workout/${this.state.workout.token}`;
        window.open(previewUrl, '_blank');
      } else {
        const shareUrl = `${process.env.REACT_APP_APP_URL}/share/my-workout/${this.state.workout.token}`;
        const tempInput = document.createElement('input');
        tempInput.value = shareUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        toast.success('Share link copied successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error copying share URL:', error);
    }
  };

  render(){
    const { createWorkoutErr, isView } = this.props;
    const { workout } = this.state;

    const channels = [
      {channel: 'Email'},
      {channel: 'Facebook'},
      {channel: 'Linkedin'},
      {channel: 'Twitter'},
      {channel: 'Whatsapp'},
      {channel: 'Copy',}
    ];

    const style={
      direction: 'flex',
      marginTop: 'mt-3',
      marginRight: 'mr-05'
    };

    const shareUrl = `${process.env.REACT_APP_APP_URL}/share/my-workout/${this.state.workout.token}`;

    return (
      <Modal
        title={!isView && !this.props.isWorkoutEdit ? 'Create Workout' : this.props.isWorkoutEdit ? 'Update Workout' : ''}
        width={'800px'}
        visible={this.props.createWorkoutModalVisible}
        onCancel={() => {this.props.toggleCreateWorkoutModal(false); }}
        footer={null}
        centered={true}
        className='create-workout-modal'
      >
        <div className='modal'>
          <div className='workout-details-card'>
            <div className='flex space-between flex-center-aligned' onClick={() => this.setState({showWorkoutDetails: !this.state.showWorkoutDetails})}>
              {!this.props.isView && <p className='home-card-text'>Workout Details</p>}
              {!this.props.isView && (this.state.showWorkoutDetails ? <IoIosArrowUp className='icon'/> : <IoIosArrowDown className='icon'/> )}
            </div>
            {/* {!this.props.isView && <Timer />} */}
            {this.state.showWorkoutDetails && 
            (!isView ?
            <>
              {this.renderClientField(workout, 'Client')}
              {this.renderInputField(workout, 'name', 'Workout name')}
              {this.renderInputField(workout, 'instruction', 'Instruction')}
              {this.renderSwitchField(workout,'isMasterTemplate', 'Master Template')}
            </>
            : 
            <div className='workout-details flex flex-center-aligned space-between'>
              <div>
                <h2 className='text'>
                  {workout.name}
                </h2>
                <p className='text'>{workout.instruction}</p>
              </div>
              {this.props.isUserGenerated && 
              <WorkoutTimer 
                toggleWorkoutTimerRunning={this.props.toggleWorkoutTimerRunning} 
                toggleConfirmModal={this.props.toggleConfirmModal}
                timerRunning={this.props.timerRunning}
                stopWorkout={this.props.stopWorkout}
                setWorkoutToState={() => this.props.setWorkoutToGlobalState({ ...this.state.workout, client: this.props.user._id })}
                shouldResetTimer={this.props.shouldResetTimer}
                onTimerReset={this.props.onTimerReset}
                updateTime={this.props.updateTimerValue}
              />}
            </div>)}
          </div>
          {this.state.workout.token ?
          <SocialMediaShare 
            channels={channels}
            style={style}
            shareUrl={shareUrl}
            workout={this.state.workout}
          /> : null}
          {this.renderActionButtons()}
          <div>
            {this.renderExerciseAndSets()}
            {this.renderAddExercise()}
          </div>
        </div>
        <Divider/>
        {!this.props.isView &&
        <div className="week-buttons text-right">
          <Button
            onClick={() => this.handleCreateOrUpdateWorkout(false)}
            type="primary"
            className="save-btn-modal"
            loading={this.props.createWorkoutLoading}
            // disabled={this.props.createNewExerciseLoading || (createWorkoutErr && createWorkoutErr.errors &&createWorkoutErr.errors.length)}
          >
            {((this.props.isWorkoutEdit && this.state.workout.client) || !this.props.isWorkoutEdit) && (this.state.workout.client ? 'Save & Assign' : this.props.isWorkoutEdit && !this.state.workout.client ? 'Update' : 'Save')}
          </Button>
          <Button
            onClick={() => this.handleCreateOrUpdateWorkout(true)}
            type="primary"
            className="save-btn-modal"
            loading={this.props.createWorkoutLoading}
            // disabled={this.props.createNewExerciseLoading || (createWorkoutErr && createWorkoutErr.errors &&createWorkoutErr.errors.length)}
          >
           {this.props.isWorkoutEdit ? 'Update Draft' : 'Save Draft'}
          </Button>
          <Button type="secondary" className="save-btn-modal" onClick={() => this.props.toggleCreateWorkoutModal(false)}>Cancel</Button>
        </div>}

        {createWorkoutErr && (
          <Alert
            type="error"
            message={
              createWorkoutErr.errors &&
              Object.values(createWorkoutErr.errors).map((err, key) => (
                <p key={key}>{err.msg}</p>
              ))
            }
          />
        )}
        {this.state.error &&
          <Alert
            type="error"
            message={this.state.error}
          />}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    allExerciseHistory: state.exercises.allExerciseHistory,
    createWorkoutLoading: state.workouts.createWorkoutLoading,
    createWorkoutSuccess: state.workouts.createWorkoutSuccess,
    createWorkoutErr: state.workouts.createWorkoutErr,
    createWorkoutModalVisible: state.global.createWorkoutModalVisible,
    clients: state.profile.onsiteClients,
    getExercisesLoading: state.exercises.getExercisesLoading,
    exercises: state.exercises.exercises,
    exerciseHistory: state.exercises.exerciseHistory,
    exerciseHistoryLoading: state.exercises.exerciseHistoryLoading,
    exerciseHistoryErr: state.exercises.exerciseHistoryErr,
    exercisesHistory: state.exercises.exercisesHistory,
    isWorkoutEdit: state.workouts.isWorkoutEdit,
    isWorkoutDraft: state.workouts.isWorkoutDraft,
    exercisesHistorySuccess: state.exercises.exercisesHistorySuccess,
    userGeneratedWorkout: state.global.userGeneratedWorkout,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createWorkout: (data) => dispatch(createWorkout(data)),
    updateLibraryWorkout: (workout) => dispatch(updateLibraryWorkout(workout)),
    getAllOnsiteClients: () => dispatch(getAllOnsiteClients()),
    getExercises: () => dispatch(getExercises()),
    saveExerciseHistory: (dataArr, workoutId) => dispatch(saveExerciseHistory(dataArr, workoutId)),
    getExerciseHistoryByIds: (clientId, exerciseId, allHistory, callback) => dispatch(getExerciseHistoryByIds(clientId, exerciseId, allHistory, callback)),
    getExercisesHistoryByIdsArr: (clientId, exerciseIdsArr) => dispatch(getExercisesHistoryByIdsArr(clientId, exerciseIdsArr)),
    // handleCreateOrEditExercise: (exerciseOptions, value, exercise, field) => dispatch(handleCreateOrEditExercise(exerciseOptions, value, exercise, field)),
    clearEditWorkout: () => dispatch({
      type: CLEAR_EDIT_WORKOUT
    }),
    setWorkoutToGlobalState: (workout) => dispatch({
      type: SET_WORKOUT_TO_GLOBAL_STATE,
      workout
    }),
    shareMyWorkout: (workoutToken) => dispatch(shareMyWorkout(workoutToken))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkoutModal);

                            // return (
                            //   <div className="sets flex">
                            //     {
                            //       showInputValues ?
                            //         <Input
                            //           className={`set-input ${key === "set" && "set"}`}
                            //           key={index}
                            //           type="number"
                            //           value={set[key]}
                            //           disabled
                            //           addonBefore={key !== "set" && capitalize(key)}
                            //         />
                            //         :
                            //         <Input
                            //           className={`set-input ${key === "set" && "set"}`}
                            //           key={index}
                            //           {...((this.props.isWorkoutDraft && this.props.isWorkoutEdit)
                            //             ? { value: set[key] || '' }
                            //             : '')}
                            //           placeholder={initData[exerciseIndex]?.data[setIndex][key] || set[key] || ""}
                            //           type="number"
                            //           onChange={(e) => {
                            //             this.handleExerciseData(
                            //               exercise,
                            //               e.target.value,
                            //               key,
                            //               set
                            //             );
                            //             this.calculateVolume(exercise);
                            //           }}
                            //           disabled={key === "set" || (this.props.isUserGenerated && !this.props.workoutInProgress)}
                            //           addonBefore={key !== "set" && capitalize(key)}
                            //         />
                            //     }
                            //   </div>
                            // );