import {
  GET_SUGGESTIC_PROFILE,
  GET_SUGGESTIC_PROFILE_SUCCESS,
  GET_SUGGESTIC_PROFILE_ERROR,
  GET_RESTRICTIONS,
  GET_RESTRICTIONS_SUCCESS,
  GET_RESTRICTIONS_ERROR,
  UPDATE_SUGGESTIC_PROFILE,
  UPDATE_SUGGESTIC_PROFILE_SUCCESS,
  UPDATE_SUGGESTIC_PROFILE_ERROR,
  GET_SHOPPING_LIST,
  GET_SHOPPING_LIST_SUCCESS,
  GET_SHOPPING_LIST_ERROR,
  GET_SHOPPING_LIST_SUG,
  GET_SHOPPING_LIST_SUG_SUCCESS,
  GET_SHOPPING_LIST_SUG_ERROR,
  TOGGLE_SHOPPING_LIST_ITEM,
  TOGGLE_SHOPPING_LIST_ITEM_SUCCESS,
  TOGGLE_SHOPPING_LIST_ITEM_ERROR,
  ADD_TO_SHOPPING_LIST,
  ADD_TO_SHOPPING_LIST_SUCCESS,
  ADD_TO_SHOPPING_LIST_ERROR,
  ADD_TO_SHOPPING_LIST_SUG,
  ADD_TO_SHOPPING_LIST_SUG_SUCCESS,
  ADD_TO_SHOPPING_LIST_SUG_ERROR,
  CLEAR_ADD_TO_SHOPPING_LIST_ERROR,
  DELETE_FROM_SHOPPING_LIST,
  DELETE_FROM_SHOPPING_LIST_SUCCESS,
  DELETE_FROM_SHOPPING_LIST_ERROR,
  CLEAR_SHOPPING_LIST,
  CLEAR_SHOPPING_LIST_SUCCESS,
  CLEAR_SHOPPING_LIST_ERROR,
  GET_USER_SHOPPING_LIST_AGGREGATE,
  GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS,
  GET_USER_SHOPPING_LIST_AGGREGATE_ERROR,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS,
  UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR,
  CLEAR_CUSTOM_SHOPPING_LIST,
  CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS,
  CLEAR_CUSTOM_SHOPPING_LIST_ERROR,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS,
  CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR
} from "./constants";

const initialState = {
  suggesticProfile: null,
  suggesticProfileLoading: false,
  suggesticProfileError: false,
  restrictions: null,
  restrictionsLoading: false,
  restrictionsError: null,
  shoppingList: null,
  shoppingListSug: null,
  shoppingListAggregate: null,
  shoppingListAggregateLoading: false,
  shoppingListAggregateErr: null,
  shoppingListErr: null,
  shoppingListLoading: false,
  shoppingListErrSug: null,
  shoppingListLoadingSug: false,
  addToShoppingListLoading: {},
  addToShoppingListError: null,
  addToShoppingListSuccess: false,
  addToShoppingListLoadingSug: {},
  addToShoppingListErrorSug: null,
  deleteFromShoppingListError: null,
  clearShoppingListError: null,
  clearShoppingListLoading: false,
  clearCustomShoppingListLoading: false,
  clearCustomShoppingListError: false,
  clearCustomCheckedFoodsError: false,
  clearCustomCheckedFoodsLoading: false
}

function suggestic(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SUGGESTIC_PROFILE:
      return {
        ...state,
        suggesticProfile: null,
        suggesticProfileLoading: true,
        suggesticProfileError: null
      };
    case GET_SUGGESTIC_PROFILE_SUCCESS:
      return {
        ...state,
        suggesticProfile: payload,
        suggesticProfileLoading: false
      };
    case GET_SUGGESTIC_PROFILE_ERROR:
      return {
        ...state,
        suggesticProfileLoading: false,
        suggesticProfileError: action.error
      };
    case UPDATE_SUGGESTIC_PROFILE:
      return {
        ...state,
        suggesticProfile: null,
        suggesticProfileLoading: true,
        suggesticProfileError: null
      };
    case UPDATE_SUGGESTIC_PROFILE_SUCCESS:
      return {
        ...state,
        suggesticProfile: payload,
        suggesticProfileLoading: false
      };
    case UPDATE_SUGGESTIC_PROFILE_ERROR:
      return {
        ...state,
        suggesticProfileLoading: false,
        suggesticProfileError: action.error
      };
    case GET_RESTRICTIONS:
      return {
        ...state,
        restrictions: null,
        restrictionsLoading: true,
        restrictionsError: null
      };
    case GET_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        restrictions: payload,
        restrictionsLoading: false
      };
    case GET_RESTRICTIONS_ERROR:
      return {
        ...state,
        restrictionsLoading: false,
        restrictionsError: action.error
      };
    case GET_SHOPPING_LIST:
      return {
        ...state,
        shoppingList: null,
        shoppingListLoading: true,
        shoppingListErr: null
      };
    case GET_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        shoppingList: payload,
        shoppingListLoading: false
      };
    case GET_SHOPPING_LIST_ERROR:
      return {
        ...state,
        shoppingListLoading: false,
        shoppingListErr: action.error
      };
    case GET_SHOPPING_LIST_SUG:
    return {
      ...state,
      shoppingListSug: null,
      shoppingListLoadingSug: true,
      shoppingListErrSug: null
    };
    case GET_SHOPPING_LIST_SUG_SUCCESS:
      return {
        ...state,
        shoppingListSug: payload,
        shoppingListLoadingSug: false
      };
    case GET_SHOPPING_LIST_SUG_ERROR:
      return {
        ...state,
        shoppingListLoadingSug: false,
        shoppingListErrSug: action.error
      };
    case GET_USER_SHOPPING_LIST_AGGREGATE:
      return {
        ...state,
        shoppingListAggregate: null,
        shoppingListAggregateLoading: true,
        shoppingListAggregateErr: null
      };
    case GET_USER_SHOPPING_LIST_AGGREGATE_SUCCESS:
      return {
        ...state,
        shoppingListAggregate: payload,
        shoppingListAggregateLoading: false
      };
    case GET_USER_SHOPPING_LIST_AGGREGATE_ERROR:
      return {
        ...state,
        shoppingListAggregateLoading: false,
        shoppingListAggregateErr: action.error
      };
    case TOGGLE_SHOPPING_LIST_ITEM:
      return {
        ...state,
        shoppingListSug: state.shoppingListSug
      };
    case TOGGLE_SHOPPING_LIST_ITEM_SUCCESS:
      return {
        ...state,
        [action.isAggregate ? 'shoppingListAggregate' : 'shoppingList']: {...action.shoppingList, edges: action.shoppingList.edges}
      }
    case ADD_TO_SHOPPING_LIST:
      return {
        ...state,
        addToShoppingListLoading: { loading: true},
        addToShoppingListSuccess: false,
        addToShoppingListError: null        
      };
    case ADD_TO_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        addToShoppingListLoading: {loading: false},
        addToShoppingListSuccess: true,
        shoppingList: state.shoppingList && state.shoppingList.length > 0 ? [...state.shoppingList, payload] : [...payload],
      };
    case ADD_TO_SHOPPING_LIST_ERROR:
      return {
        ...state,
        addToShoppingListLoading: {loading: false},
        addToShoppingListError: action.error
      }
    case ADD_TO_SHOPPING_LIST_SUG:
      return {
        ...state,
        addToShoppingListLoadingSug: {allRecipes: action.allRecipes, loading: true},
        addToShoppingListErrorSug: null
      };
    case ADD_TO_SHOPPING_LIST_SUG_SUCCESS:
      return {
        ...state,
        addToShoppingListLoadingSug: {allRecipes: action.allRecipes, loading: false}
      };
    case ADD_TO_SHOPPING_LIST_SUG_ERROR:
      return {
        ...state,
        addToShoppingListLoadingSug: {allRecipes: action.allRecipes, loading: false},
        addToShoppingListErrorSug: action.error
      }
    case DELETE_FROM_SHOPPING_LIST:
      return {
        ...state,
        deleteFromShoppingListError: null
      };
    case DELETE_FROM_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        shoppingList: state.shoppingList.filter(item => item._id !== action.id),
      };
    case DELETE_FROM_SHOPPING_LIST_ERROR:
      return {
        ...state,
        deleteFromShoppingListError: action.error
      }
    case CLEAR_ADD_TO_SHOPPING_LIST_ERROR:
      return {
        ...state,
        addToShoppingListError: action.val
      }
    case CLEAR_SHOPPING_LIST:
      return {
        ...state,
        clearShoppingListLoading: true
      };
    case CLEAR_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        shoppingListSug: null,
        clearShoppingListLoading: false
      };
    case CLEAR_SHOPPING_LIST_ERROR:
      return {
        ...state,
        clearShoppingListError: action.error,
        clearShoppingListLoading: false
      }
    case UPDATE_CUSTOM_SHOPPING_LIST_ITEM_SUCCESS:
      // [...state.shoppingList.map(item => (item._id === action.payload._id) ? action.payload : item)]
      const index = state.shoppingList.findIndex((item) => item._id === action.payload._id);
      if(index > -1) {
        state.shoppingList.splice(index, 1, action.payload);
        return {
          ...state,
          shoppingList: [...state.shoppingList]
        };
      } else {
        return state;
      }

    case UPDATE_CUSTOM_SHOPPING_LIST_ITEM_ERROR:
      return {
        ...state,
        shoppingListLoading: false
      }

    case CLEAR_CUSTOM_SHOPPING_LIST:
      return {
        ...state,
        clearCustomShoppingListLoading: true
      };

    case CLEAR_CUSTOM_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        shoppingList: [],
        clearCustomShoppingListLoading: false
      };
    case CLEAR_CUSTOM_SHOPPING_LIST_ERROR:
      return {
        ...state,
        clearCustomShoppingListError: action.error,
        clearCustomShoppingListLoading: false
      }

    case CLEAR_CUSTOM_CHECKED_FOOD_ITEMS:
      return {
        ...state,
        clearCustomCheckedFoodsLoading: true
      };

    case CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_SUCCESS:
      return {
        ...state,
        shoppingList: state.shoppingList && state.shoppingList.filter((foodObj) => ((foodObj.food && foodObj.isDone) !== true)),
        clearCustomCheckedFoodsLoading: false
      };
    case CLEAR_CUSTOM_CHECKED_FOOD_ITEMS_ERROR:
      return {
        ...state,
        clearCustomCheckedFoodsError: action.error,
        clearCustomCheckedFoodsLoading: false
      }

    default:
      return state;
  }
}

export default suggestic;
