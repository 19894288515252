import React from 'react';
import { connect } from 'react-redux';
import { getCoachMealPlans } from '../../ducks/Admin/actions';
import AdminMealPlanLibraryPage from '../../presentational/AdminMealPlanLibrary/index';
import { Loading } from '../../components/Loading';

class AdminMealPlanLibrary extends React.Component {
  componentDidMount() {
    this.props.getCoachMealPlans(this.props.location.search === '?drafts=true');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.props.getCoachMealPlans(this.props.location.search === '?drafts=true');
    }
  }

  render(){
    const { mealPlansLoading } = this.props;
    return(
      <div className='meal-plan-library'>
        <>
          {mealPlansLoading ?
          <Loading /> :
          <AdminMealPlanLibraryPage history={this.props.history} templates={this.props.mealPlans.templates || []} />}
        </>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    mealPlans: state.admin.coachMealplans,
    mealPlansLoading: state.admin.coachMealplansLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCoachMealPlans: (drafts) => dispatch(getCoachMealPlans(drafts))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMealPlanLibrary);