import { combineReducers } from 'redux';
import blog from './ducks/Blog/reducer';
import assessment from './ducks/Assessment/reducer';
import global from './ducks/Global/reducer';
import auth from './ducks/Auth/reducer';
import profile from './ducks/Profile/reducer';
import recipes from './ducks/Recipe/reducer';
import measurements from './ducks/Measurements/reducer';
import table from './ducks/Table/reducer';
import admin from './ducks/Admin/reducer';
import suggestic from './ducks/Suggestic/reducer';
import settings from './ducks/Setting/reducer';
import onesignal from "./ducks/Onesignal/reducer";
import todo from "./ducks/Todo/reducer";
import nutritionPreference from "./ducks/NutritionPreference/reducer";
import guides from "./ducks/Guide/reducer";
import exercises from "./ducks/Exercise/reducer";
import workouts from "./ducks/Workout/reducer";
import workoutProgram from "./ducks/WorkoutProgram/reducer";
import workoutPlan from "./ducks/WorkoutPlan/reducer";
import carbCycling from "./ducks/CarbCycling/reducer";

const reducers = combineReducers({
  blog,
  assessment,
  global,
  auth,
  profile,
  recipes,
  measurements,
  table,
  admin,
  suggestic,
  settings,
  onesignal,
  todo,
  nutritionPreference,
  guides,
  exercises,
  workouts,
  workoutProgram,
  workoutPlan,
  carbCycling
});


export default reducers;