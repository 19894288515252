import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Helmet } from 'react-helmet';
import { BiCopy } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import './index.css';

export const SocialMediaShare = ({ channels, style, shareUrl, workout }) => {
  const IconsMap = {
    Email: { Component: Button, Icon: EmailIcon },
    Facebook: { Component: Button, Icon: FacebookIcon },
    FacebookMessenger: { Component: Button, Icon: FacebookMessengerIcon },
    Linkedin: { Component: Button, Icon: LinkedinIcon },
    Twitter: { Component: Button, Icon: TwitterIcon },
    Whatsapp: { Component: Button, Icon: WhatsappIcon },
    Copy: BiCopy
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl)
    .then(() => {
      toast.success('Link copied to clipboard');
    })
    .catch((error) => {
      toast.error(error);
    });
  };

  const handleShare = async (channel, shareUrl) => {
    const encodedShareUrl = encodeURIComponent(shareUrl);
    const text = encodeURIComponent(`Check out my ${workout.name} workout!`);

    if (!navigator.share) {
      switch (channel.channel) {
        case 'Facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
          break;
        case 'FacebookMessenger':
          window.open(`https://www.facebook.com/dialog/send?app_id=123456789&link=${shareUrl}&redirect_uri=${shareUrl}`);
          break;
        case 'Twitter':
          // const via = encodeURIComponent('yourTwitterHandle'); // Replace with your Twitter handle
          const hashtags = encodeURIComponent('fitness,workout,alvarofitness'); // Replace with your desired hashtags
          window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${text}&hashtags=${hashtags}`);
          break;
          case 'Linkedin':
            const title = encodeURIComponent(`Check out my ${workout.name} workout`);
            const summary = encodeURIComponent('Trained by Alvaro Fitness');
            const source = encodeURIComponent('Alvaro Fitness'); 
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareUrl}&title=${title}&summary=${summary}&source=${source}`);
            break;
        case 'Whatsapp':
          const whatsappText = encodeURIComponent(`Check out my ${workout.name} workout: ${shareUrl}`);
          window.open(`https://api.whatsapp.com/send?text=${whatsappText}`);
          break;
        // case 'Pinterest':
        //   // const media = encodeURIComponent(imageUrl); // Replace with your image URL
        //   const description = encodeURIComponent('Check out this amazing workout!');
        //   window.open(`https://pinterest.com/pin/create/button/?url=${encodedShareUrl}&description=${description}`);
        //   break;
        case 'Email':
          const emailSubject = encodeURIComponent(`Check out my ${workout.name} workout!`);
          const emailBody = encodeURIComponent(`Hi there,\n\nI wanted to share my workout with you. Check it out here: ${shareUrl}`);
          window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
          break;
        default:
          console.error('Unsupported sharing channel:', channel);
      }
    } else {
      try {
        await navigator.share({
          title: text,
          url: shareUrl,
        });
        console.log('Shared successfully via native sharing.');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  return (
    <div className={`${style.direction} ${style.marginTop}`}>
      {channels.map((channel, index) => {
        const { Component, Icon } = IconsMap[channel.channel];
        return (
          <div key={index} className={style.marginRight}>
            {Component && Icon && (
              <Component className='social-media-btn' onClick={() => handleShare(channel, shareUrl)}>
                <Helmet>
                  <meta property="og:title" content={`Check out my ${workout.name} workout`} />
                  <meta property="og:description" content="Trained by Alvaro Fitness" />
                  {/* <meta property="og:image" content={imageUrl} /> */}
                </Helmet>
                <Icon size={32} round />
              </Component>
            )}
            {channel.channel === 'Copy' && (
              <div 
                style={{
                  width: 32, 
                  height: 32,
                  borderRadius: '50%',
                  border: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <BiCopy 
                  onClick={handleCopyLink} 
                  size={18} 
                />
              </div>
              )}
          </div>
        );
      })}
    </div>
  );
};